import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Trishula`

// BODY
const bodyTrishulaImage = `${imagePath}/Chassis.png`;

// HEADS
const headAngularImage = `${imagePath}/heads/Trishula_Heads_Angular.png`;
const headBriarImage = `${imagePath}/heads/Trishula_Heads_Briar.png`;
const headDungamImage = `${imagePath}/heads/Trishula_Heads_Dungam.png`;
const headFirstFormImage = `${imagePath}/heads/Trishula_Heads_First Form.png`;
const headFinalFormImage = `${imagePath}/heads/Trishula_Heads_Final Form.png`;
const headLudonautImage = `${imagePath}/heads/Trishula_Heads_Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Trishula_Heads_Malice.png`;
const headMecha1Image = `${imagePath}/heads/Trishula_Heads_Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Trishula_Heads_Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Trishula_Heads_Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Trishula_Heads_Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Trishula_Heads_Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Trishula_Heads_Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Trishula_Heads_Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Trishula_Heads_Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Trishula_Heads_Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Trishula_Heads_Natural Law Hair.png`;
const headPaladinImage = `${imagePath}/heads/Trishula_Heads_Paladin.png`;
const headPerfectionImage = `${imagePath}/heads/Trishula_Heads_Perfection.png`;
const headPollarisImage = `${imagePath}/heads/Trishula_Heads_Pollaris.png`;
const headPredatorImage = `${imagePath}/heads/Trishula_Heads_Predator.png`;
const headRainImage = `${imagePath}/heads/Trishula_Heads_Rain Transformed.png`;
const headRainHairImage = `${imagePath}/heads/Trishula_Heads_Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Trishula_Heads_Scarab.png`;
const headStalwartImage = `${imagePath}/heads/Trishula_Heads_Stalwart.png`;
const headTitaniumImage = `${imagePath}/heads/Trishula_Heads_Titanium.png`;

// WEAPONS
// LEFT
const weaponsLeftDeathBeamImage = `${imagePath}/weapons/left/Trishula_Left Arm_Death Beam.png`;
const weaponsLeftPropulsorDownImage = `${imagePath}/weapons/left/Trishula_Left Arm_Propulsor Down.png`;
const weaponsLeftPropulsorUpImage = `${imagePath}/weapons/left/Trishula_Left Arm_Propulsor Up.png`;
const weaponsLeftTechPadImage = `${imagePath}/weapons/left/Trishula_Left Arm_tech pad.png`;
const weaponsLeftTelekineticGraspImage = `${imagePath}/weapons/left/Trishula_Left Arm_Telekinetic Grasp.png`;
const weaponsLeftUshabtiImage = `${imagePath}/weapons/left/Trishula_Left Arm_ushabti.png`;

// RIGHT
const weaponsRightBehindBackImage = `${imagePath}/weapons/right/Trishula_Right Arm_Behind the Back.png`;
const weaponsRightPropulsorDownImage = `${imagePath}/weapons/right/Trishula_Right Arm_Propulsor down.png`;
const weaponsRightPropulsorUpImage = `${imagePath}/weapons/right/Trishula_Right Arm_Propulsor Up.png`;
const weaponsRightArmShieldImage = `${imagePath}/weapons/right/Trishula_Right Arm_Shield.png`;
const weaponsRightTelekineticGraspImage = `${imagePath}/weapons/right/Trishula_Right Arm_Telekinetic Grasp.png`;
const weaponsRightUshabtiImage = `${imagePath}/weapons/right/Trishula_Right Arm_Ushabti.png`;

// REAR MOUNT
const rearMountShoulderPauldronsImage = `${imagePath}/rear mounts/Trishula_Accessories middle_Shoulder Pauldrons.png`;
const rearMountCapeImage = `${imagePath}/rear mounts/Trishula_Accessories middle_cape.png`;

// GLOWS
const glowBodyImage = `${imagePath}/glows/Trishula_Accessories lower_Body Glow.png`;
const glowFirstFormHeadImage = `${imagePath}/glows/Trishula_Accessories front_Head First Form.png`;
const glowFinalFormHeadImage = `${imagePath}/glows/Trishula_Accessories front_Head Final Form.png`;
const glowPerfectionHeadImage = `${imagePath}/glows/Trishula_Accessories front_Head Perfection.png`;
const glowPollarisHeadImage = `${imagePath}/glows/Trishula_Accessories front_Head Pollaris.png`;
const glowTitaniumHeadImage = `${imagePath}/glows/Trishula_Accessories front_Head Titanium.png`;
const glowNexusArrayImage = `${imagePath}/glows/Trishula_Accessories lower_Nexus Glow.png`;
const glowLeftDeathBeamImage = `${imagePath}/glows/Trishula_Accessories front_Death Beam.png`;
const glowLeftPropulsorDownImage = `${imagePath}/glows/Trishula_Accessories front_Left Propulsor down.png`;
const glowLeftPropulsorUpImage = `${imagePath}/glows/Trishula_Accessories front_Left Propulsor Up.png`;
const glowLeftTechPadImage = `${imagePath}/glows/Trishula_Accessories front_Tech Pad.png`;
const glowLeftTelekineticGraspImage = `${imagePath}/glows/Trishula_Accessories front_LEft Telekinetic Grasp.png`;
const glowLeftUshabtiImage = `${imagePath}/glows/Trishula_Accessories front_Left Ushabti.png`;
const glowRightBehindTheBackImage = `${imagePath}/glows/Trishula_Accessories front_Behind the Back.png`;
const glowRightPropulsorDownImage = `${imagePath}/glows/Trishula_Accessories front_Right Propulsor down.png`;
const glowRightPropulsorUpImage = `${imagePath}/glows/Trishula_Accessories front_Right Propulsor Up.png`;
const glowRightTelekineticGraspImage = `${imagePath}/glows/Trishula_Accessories front_Right Telekinetic Grasp.png`;
const glowRightUshabtiImage = `${imagePath}/glows/Trishula_Accessories front_Right Ushabti.png`;
const glowRightShieldImage = `${imagePath}/glows/Trishula_Accessories front_Shield.png`;

// ACCESSORIES
const accessoriesNexusArrayImage = `${imagePath}/accessories/Trishula_Accessories back_Nexus Array.png`;
const accessoriesTailImage = `${imagePath}/accessories/Trishula_Accessories middle_Tail.png`;
const accessoriesWingsImage = `${imagePath}/accessories/Trishula_Accessories back_Wings.png`;
const accessoriesCapeImage = `${imagePath}/accessories/Trishula_Accessories_back_Cape.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "29px",
    right: "8px",
};

export default {
    previewImg: previewImage,
    removeNone: {
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "25",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "2",
        "accessory1Value": "NONE",
        "accessory2Value": "9",
        "accessory3Value": "26",
        "accessory4Value": "4",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 1,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 2,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 3,
            label: "FIRST FORM",
            src: headFirstFormImage,
        },
        {
            value: 4,
            label: "FINAL FORM",
            src: headFinalFormImage,
        },
        {
            value: 5,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 6,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 7,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 8,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 9,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 10,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 11,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 12,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 13,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 14,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 15,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 16,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 17,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 18,
            label: "PERFECTION",
            src: headPerfectionImage,
        },
        {
            value: 19,
            label: "POLLARIS",
            src: headPollarisImage,
        },
        {
            value: 20,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 21,
            label: "RAIN TRANSFORMED",
            src: headRainImage,
        },
        {
            value: 22,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainHairImage,
        },
        {
            value: 23,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 24,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 25,
            label: "TITANIUM",
            src: headTitaniumImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TRISHULA",
            backSrc: bodyTrishulaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.PINKHYENA,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "DEATH BEAM",
                src: weaponsLeftDeathBeamImage,
            },
            {
                value: 1,
                label: "PROPULSOR - DOWN",
                src: weaponsLeftPropulsorDownImage,
            },
            {
                value: 2,
                label: "PROPULSOR - UP",
                src: weaponsLeftPropulsorUpImage,
            },
            {
                value: 3,
                label: "TECH PAD",
                src: weaponsLeftTechPadImage,
            },
            {
                value: 4,
                label: "TELEKINETIC GRASP",
                src: weaponsLeftTelekineticGraspImage,
            },
            {
                value: 5,
                label: "USHABTI",
                src: weaponsLeftUshabtiImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARM SHIELD",
            src: weaponsRightArmShieldImage,
        },
        {
            value: 1,
            label: "BEHIND THE BACK",
            src: weaponsRightBehindBackImage,
        },
        {
            value: 2,
            label: "PROPULSOR - DOWN",
            src: weaponsRightPropulsorDownImage,
        },
        {
            value: 3,
            label: "PROPULSOR - UP",
            src: weaponsRightPropulsorUpImage,
        },
        {
            value: 4,
            label: "TELEKINETIC GRASP",
            src: weaponsRightTelekineticGraspImage,
        },
        {
            value: 5,
            label: "USHABTI",
            src: weaponsRightUshabtiImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "PAULDRONS",
            src: rearMountShoulderPauldronsImage,
        },
        {
            value: 1,
            label: "CAPE",
            src: rearMountCapeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CAPE",
            backmostSrc: accessoriesCapeImage,
        },
        {
            value: 1,
            label: "NEXUS ARRAY",
            backSrc: accessoriesNexusArrayImage,
        },
        {
            value: 2,
            label: "TAIL",
            src: accessoriesTailImage,
        },
        {
            value: 3,
            label: "WINGS",
            backSrc: accessoriesWingsImage,
        },
        {
            value: 4,
            label: "BODY - GLOW",
            backSrc: glowBodyImage,
        },
        {
            value: 5,
            label: "FIRST FORM HEAD - GLOW",
            frontSrc: glowFirstFormHeadImage,
        },
        {
            value: 6,
            label: "FINAL FORM HEAD - GLOW",
            frontSrc: glowFinalFormHeadImage,
        },
        {
            value: 7,
            label: "PERFECTION HEAD - GLOW",
            frontSrc: glowPerfectionHeadImage,
        },
        {
            value: 8,
            label: "POLLARIS HEAD - GLOW",
            frontSrc: glowPollarisHeadImage,
        },
        {
            value: 9,
            label: "TITANIUM HEAD - GLOW",
            frontSrc: glowTitaniumHeadImage,
        },
        {
            value: 10,
            label: "NEXUS ARRAY - GLOW",
            src: glowNexusArrayImage,
        },
        {
            value: 11,
            label: "DEATH BEAM - LEFT - GLOW",
            frontSrc: glowLeftDeathBeamImage,
        },
        {
            value: 12,
            label: "PROPULSOR DOWN - LEFT - GLOW",
            frontSrc: glowLeftPropulsorDownImage,
        },
        {
            value: 13,
            label: "PROPULSOR UP - LEFT - GLOW",
            frontSrc: glowLeftPropulsorUpImage,
        },
        {
            value: 14,
            label: "TECH PAD - LEFT - GLOW",
            frontSrc: glowLeftTechPadImage,
        },
        {
            value: 15,
            label: "TELEKINETIC GRASP - LEFT - GLOW",
            frontSrc: glowLeftTelekineticGraspImage,
        },
        {
            value: 16,
            label: "USHABTI - LEFT - GLOW",
            frontSrc: glowLeftUshabtiImage,
        },
        {
            value: 17,
            label: "BEHIND THE BACK - RIGHT - GLOW",
            frontSrc: glowRightBehindTheBackImage,
        },
        {
            value: 18,
            label: "PROPULSOR DOWN - RIGHT - GLOW",
            frontSrc: glowRightPropulsorDownImage,
        },
        {
            value: 19,
            label: "PROPULSOR UP - RIGHT - GLOW",
            frontSrc: glowRightPropulsorUpImage,
        },
        {
            value: 20,
            label: "TELEKINETIC GRASP - RIGHT - GLOW",
            frontSrc: glowRightTelekineticGraspImage,
        },
        {
            value: 21,
            label: "USHABTI - RIGHT - GLOW",
            frontSrc: glowRightUshabtiImage,
        },
        {
            value: 22,
            label: "SHIELD - RIGHT - GLOW",
            foremostSrc: glowRightShieldImage,
        },
        {
            value: 23,
            label: "DEATH BEAM + BEHIND THE BACK - GLOW",
            foremostSrc: glowRightBehindTheBackImage,
            frontSrc: glowLeftDeathBeamImage,
        },
        {
            value: 24,
            label: "PROPULSOR DOWN - BOTH - GLOW",
            foremostSrc: glowRightPropulsorDownImage,
            frontSrc: glowLeftPropulsorDownImage,
        },
        {
            value: 25,
            label: "PROPULSOR UP - BOTH - GLOW",
            foremostSrc: glowRightPropulsorUpImage,
            frontSrc: glowLeftPropulsorUpImage,
        },
        {
            value: 26,
            label: "PROPULSOR UP - DOWN - GLOW",
            foremostSrc: glowRightPropulsorDownImage,
            frontSrc: glowLeftPropulsorUpImage,
        },
        {
            value: 27,
            label: "PROPULSOR DOWN - UP - GLOW",
            foremostSrc: glowRightPropulsorUpImage,
            frontSrc: glowLeftPropulsorDownImage,
        },
        {
            value: 28,
            label: "TELEKINETIC GRASP - BOTH - GLOW",
            foremostSrc: glowRightTelekineticGraspImage,
            frontSrc: glowLeftTelekineticGraspImage,
        },
        {
            value: 29,
            label: "USHABTI - BOTH - GLOW",
            foremostSrc: glowRightUshabtiImage,
            frontSrc: glowLeftUshabtiImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORY / GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "SHOULDERS",
            value: "rearMount",
        },
        {
            label: "ACCESSORY",
            value: "accessory1",
        },
        {
            label: "GLOW 1",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "SHOULDERS",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "ACCESSORY",
        "accessory2": "GLOW 1",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
