import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/HA-Hapsburg`;

// BODY
const bodyHapsburgImage = `${imagePath}/Chassis - Hapsburg.png`;
const bodyPylonImage = `${imagePath}/Chassis - E-R-Pylon Deployed.png`;

// HEADS
const headHapsburgImage = `${imagePath}/heads/Head - Hapsburg.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackbeardImage = `${imagePath}/heads/Head - Blackbeard.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headOgreImage = `${imagePath}/heads/Head - Ogre.png`;
const headPaladinImage = `${imagePath}/heads/Head - Paladin.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headScopedogImage = `${imagePath}/heads/Head - Scopedog.png`;
const headVanguardImage = `${imagePath}/heads/Head - Vanguard.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponLeftAssaultRifleImage = `${imagePath}/weapons/left/Left Arm - Assault Rifle.png`;
const weaponLeftDD288Image = `${imagePath}/weapons/left/Left Arm - DD288.png`;
const weaponLeftEnergyRifleImage = `${imagePath}/weapons/left/Left Arm - Energy Rifle.png`;
const weaponLeftFistImage = `${imagePath}/weapons/left/Left Arm - Fist.png`;
const weaponLeftGatlingAmmoImage = `${imagePath}/weapons/left/Left Arm - Gatling Ammo.png`;
const weaponLeftGatlingImage = `${imagePath}/weapons/left/Left Arm - Gatling.png`;
const weaponLeftGrenadeLauncherImage = `${imagePath}/weapons/left/Left Arm - Grenade Launcher.png`;
const weaponLeftPistolImage = `${imagePath}/weapons/left/Left Arm - Pistol.png`;

// RIGHT
const weaponRightDD288Image = `${imagePath}/weapons/right/Right Arm - DD288.png`;
const weaponRightERPylonImage = `${imagePath}/weapons/right/Right Arm - E-R-Pylon.png`;
const weaponRightERPylonPackImage = `${imagePath}/weapons/right/Right Arm - E-R-Pylon Pack.png`;
const weaponRightShieldImage = `${imagePath}/weapons/right/Right Arm - Shield.png`;

// ACCESSORIES
const accessoryERPylonPackImage = `${imagePath}/glows/Back - E-R-Pylon Pack.png`;
const accessoryGatlingAmmoImage = `${imagePath}/glows/Back - Gatling Ammo.png`;

// GLOWS
const glowEnergyRifleImage = `${imagePath}/glows/Glow - Energy Rifle.png`;
const glowERPylonDeployedImage = `${imagePath}/glows/Glow - E-R-Pylon Deployed.png`;
const glowERPylonShieldImage = `${imagePath}/glows/Glow - E-R-Pylon Shield.png`;
const glowERPylonandShieldImage = `${imagePath}/glows/Glow - E-R-Pylon and Shield.png`;
const glowRightArmERPylonImage = `${imagePath}/glows/Glow - Right Arm E-R-Pylon.png`;
const glowRightArmERPylonPackImage = `${imagePath}/glows/Glow - Right Arm E-R-Pylon Pack.png`;
const glowShieldDomeImage = `${imagePath}/glows/Glow - Shield Dome.png`;
const glowShieldDomeImpactsImage = `${imagePath}/glows/Glow - Shield Dome Impacts.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/Book_Habsburg-KRohlyPoly.png`;
const core2Image = `${imagePath}/Book_ER_Pylon-KRohlyPoly.png`;
const core3Image = `${imagePath}/Habsburg_Full-Vivivision.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "69px",
    right: "39px",
};

const originalArtCredit = {
    prefixText: "Commissioned by Ralf Ziegler. Original design by ",
    title: "Franceso Silva",
    url: "https://twitter.com/francescolobo?lang=en",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "KRohlyPoly",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "KRohlyPoly",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core3Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Vivivision",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "7",
        "secondaryWeaponValue": "1",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "8",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HABSBURG",
            src: headHapsburgImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 2,
            label: "BLACKBEARD",
            src: headBlackbeardImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 8,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 9,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 10,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 11,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 12,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 13,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 14,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 15,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 16,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 17,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 18,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 19,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 20,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 21,
            label: "OGRE",
            src: headOgreImage,
        },
        {
            value: 22,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 23,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 24,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 25,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 26,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 27,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 28,
            label: "VANGUARD",
            src: headVanguardImage,
        },
        {
            value: 29,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "HA HABSBURG",
            src: bodyHapsburgImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "E-R-PYLON DEPLOYED",
            src: bodyPylonImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE",
                src: weaponLeftAssaultRifleImage,
            },
            {
                value: 1,
                label: "DD288",
                src: weaponLeftDD288Image,
            },
            {
                value: 2,
                label: "ENERGY RIFLE",
                src: weaponLeftEnergyRifleImage,
            },
            {
                value: 3,
                label: "FIST",
                src: weaponLeftFistImage,
            },
            {
                value: 4,
                label: "GATLING",
                src: weaponLeftGatlingImage,
            },
            {
                value: 5,
                label: "GATLING + AMMO",
                src: weaponLeftGatlingAmmoImage,
            },
            {
                value: 6,
                label: "GRENADE LAUNCHER",
                src: weaponLeftGrenadeLauncherImage,
            },
            {
                value: 7,
                label: "PISTOL",
                src: weaponLeftPistolImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DD288",
            src: weaponRightDD288Image,
        },
        {
            value: 1,
            label: "E-R-PYLON",
            src: weaponRightERPylonImage,
        },
        {
            value: 2,
            label: "E-R-PYLON + PACK",
            src: weaponRightERPylonPackImage,
        },
        {
            value: 3,
            label: "SHIELD",
            src: weaponRightShieldImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ENERGY RIFLE",
            frontSrc: glowEnergyRifleImage,
        },
        {
            value: 1,
            label: "E-R-PYLON DEPLOYED",
            frontSrc: glowERPylonDeployedImage,
        },
        {
            value: 2,
            label: "E-R-PYLON SHIELD",
            frontSrc: glowERPylonShieldImage,
        },
        {
            value: 3,
            label: "E-R-PYLON + SHIELD",
            frontSrc: glowERPylonandShieldImage,
        },
        {
            value: 4,
            label: "E-R-PYLON - RIGHT ARM",
            frontSrc: glowRightArmERPylonImage,
        },
        {
            value: 5,
            label: "E-R-PYLON PACK - RIGHT ARM",
            frontSrc: glowRightArmERPylonPackImage,
        },
        {
            value: 6,
            label: "SHIELD DOME",
            frontSrc: glowShieldDomeImage,
        },
        {
            value: 7,
            label: "SHIELD DOME + IMPACTS",
            frontSrc: glowShieldDomeImpactsImage,
        },
        {
            value: 8,
            label: "ACCESSORY - E-R-PYLON PACK",
            src: accessoryERPylonPackImage,
        },
        {
            value: 9,
            label: "ACCESSORY - GATLING AMMO",
            src: accessoryGatlingAmmoImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "ACCESSORY",
    },
};
