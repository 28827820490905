import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Kaiju/Rugam`;

// BODY
const bodyRugamImage = `${imagePath}/Kaiju 1 - Body.png`;

// HEADS
const headRugamImage = `${imagePath}/heads/Kaiju 1 - Head.png`;

// SPINES
const shoulderDorsalSpinesImage = `${imagePath}/accessories/Kaiju 1 - Dorsal Spines.png`;

// PLATES
// ARMS
const armPlatesImage = `${imagePath}/plates/Kaiju 1 - Arm Plates.png`;

// LEGS
const legPlatesImage = `${imagePath}/plates/Kaiju 1 - Knee Plates.png`;

// GLOWS
const headGlowImage = `${imagePath}/glows/Kaiju 1 - Glow - Head.png`;
const bodyGlowImage = `${imagePath}/glows/Kaiju 1 - Glow - Body.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "10px",
    right: "-10px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "RUGAM",
            src: headRugamImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "RUGAM",
            src: bodyRugamImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ARM PLATES",
                frontSrc: armPlatesImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "LEG PLATES",
            frontSrc: legPlatesImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "DORSAL SPINES",
            frontSrc: shoulderDorsalSpinesImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "HEAD",
            frontSrc: headGlowImage,
        },
        {
            value: 1,
            label: "BODY",
            src: bodyGlowImage,
        },
        {
            value: 2,
            label: "ALL",
            frontSrc: headGlowImage,
            src: bodyGlowImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "PLATES",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "BODY",
            value: "chassis",
        },
        {
            label: "SPINES",
            value: "rearMount",
        },
        {
            label: "ARM PLATES",
            value: "weapon",
        },
        {
            label: "LEG PLATES",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "BODY",
        "rearMount": "SPINES",
        "weapon": "ARM PLATES",
        "secondaryWeapon": "LEG PLATES",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
