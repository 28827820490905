import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// LEGS
const legsBipedalImage = `${BaseURL}/Mechs/Custom/Sirocco/Legs - Bipedal.png`;
const legsQuadImage = `${BaseURL}/Mechs/Custom/Sirocco/Legs - Quad.png`;

// HULLS
const hullClosedImage = `${BaseURL}/Mechs/Custom/Sirocco/heads/Chassis - Closed.png`;
const hullCannonExposedImage = `${BaseURL}/Mechs/Custom/Sirocco/heads/Chassis - Cannon Exposed.png`;
const hullCarrierImage = `${BaseURL}/Mechs/Custom/Sirocco/heads/Chassis - Carrier.png`;
const hullCarrierDronesImage = `${BaseURL}/Mechs/Custom/Sirocco/heads/Chassis - Carrier Drones.png`;

// ARMS
// LEFT
const armsLeftDualCannonImage = `${BaseURL}/Mechs/Custom/Sirocco/weapons/left/Left Arm - Dual Cannon.png`;
const armsLeftSiegeCannonImage = `${BaseURL}/Mechs/Custom/Sirocco/weapons/left/Left Arm - Siege Cannon.png`;

// RIGHT
const armsRightDualCannonImage = `${BaseURL}/Mechs/Custom/Sirocco/weapons/right/Right Arm - Dual Cannon.png`;
const armsRightSiegeCannonImage = `${BaseURL}/Mechs/Custom/Sirocco/weapons/right/Right Arm - Siege Cannon.png`;

// REAR MOUNT
const rearMountCannons2Image = `${BaseURL}/Mechs/Custom/Sirocco/rear mounts/Shoulders - Cannons x2.png`;
const rearMountCannons4Image = `${BaseURL}/Mechs/Custom/Sirocco/rear mounts/Shoulders - Cannons x4.png`;
const rearMountCannons6Image = `${BaseURL}/Mechs/Custom/Sirocco/rear mounts/Shoulders - Cannons x6.png`;
const rearMountMissilesImage = `${BaseURL}/Mechs/Custom/Sirocco/rear mounts/Shoulders - Missiles.png`;
const rearMountCannons2MissilesImage = `${BaseURL}/Mechs/Custom/Sirocco/rear mounts/Shoulders - Cannons x2  Missiles.png`;
const rearMountShieldGeneratorsImage = `${BaseURL}/Mechs/Custom/Sirocco/rear mounts/Shoulders - Shield Generators.png`;

// GLOWS
const glowCentralGeneratorImage = `${BaseURL}/Mechs/Custom/Sirocco/glows/Glow - Central Generator.png`;
const glowShieldGeneratorsImage = `${BaseURL}/Mechs/Custom/Sirocco/glows/Glow - Shield Generators.png`;
const glowSpoolCannonImage = `${BaseURL}/Mechs/Custom/Sirocco/glows/Glow - Spool Cannon.png`;
const glowSpoolCannonArcsImage = `${BaseURL}/Mechs/Custom/Sirocco/glows/Glow - Spool Cannon Arcs.png`;
const glowDronesImage = `${BaseURL}/Mechs/Custom/Sirocco/glows/Glow - Carrier Drones.png`;
const accessoryDronesImage = `${BaseURL}/Mechs/Custom/Sirocco/glows/Accessory - Drones.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Sirocco/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Sirocco/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "28px",
    right: "16px",
};

const quadLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "55px",
    right: "1px",
};

const commissionCredit = {
    prefixText: "Commissioned by ",
    title: "Horza Bora",
    url: "https://discord.gg/JUF47G5",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    isMegadeusPlus: true,
    accessory2IsChassis: true,
    removeNone: {
        head: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "5",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CLOSED",
            src: hullClosedImage,
        },
        {
            value: 1,
            label: "CANNON EXPOSED",
            src: hullCannonExposedImage,
        },
        {
            value: 2,
            label: "CARRIER",
            src: hullCarrierImage,
        },
        {
            value: 3,
            label: "CARRIER + DRONES",
            src: hullCarrierDronesImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BIPEDAL",
            src: legsBipedalImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: commissionCredit,
        },
        {
            value: 1,
            label: "QUAD",
            src: legsQuadImage,
            shadowDetails: quadLegsShadow,
            originalArtCredit: commissionCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "DUAL CANNONS",
                backSrc: armsLeftDualCannonImage,
            },
            {
                value: 1,
                label: "SIEGE CANNON",
                backSrc: armsLeftSiegeCannonImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DUAL CANNONS",
            foremostSrc: armsRightDualCannonImage,
        },
        {
            value: 1,
            label: "SIEGE CANNON",
            foremostSrc: armsRightSiegeCannonImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CANNONS X2",
            foremostSrc: rearMountCannons2Image,
        },
        {
            value: 1,
            label: "CANNONS X4",
            foremostSrc: rearMountCannons4Image,
        },
        {
            value: 2,
            label: "CANNONS X6",
            foremostSrc: rearMountCannons6Image,
        },
        {
            value: 3,
            label: "MISSILES",
            foremostSrc: rearMountMissilesImage,
        },
        {
            value: 4,
            label: "CANNONS X2 + MISSILES",
            foremostSrc: rearMountCannons2MissilesImage,
        },
        {
            value: 5,
            label: "SHIELD GENERATOR",
            foremostSrc: rearMountShieldGeneratorsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CENTRAL GENERATOR",
            foremostSrc: glowCentralGeneratorImage,
        },
        {
            value: 1,
            label: "SHIELD GENERATORS",
            foremostSrc: glowShieldGeneratorsImage,
        },
        {
            value: 2,
            label: "SPOOL CANNON",
            frontSrc: glowSpoolCannonImage,
        },
        {
            value: 3,
            label: "SPOOL CANNON ARCS",
            frontSrc: glowSpoolCannonArcsImage,
        },
        {
            value: 4,
            label: "DRONES",
            foremostSrc: glowDronesImage,
        },
        {
            value: 5,
            label: "ACCESSORY - DRONES",
            frontSrc: accessoryDronesImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HULL",
            value: "head",
        },
        {
            label: "LEGS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "DECK MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HULL",
        "chassis": "LEGS",
        "rearMount": "DECK MOUNT",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
