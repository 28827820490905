import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyGorgonImage = `${BaseURL}/Mechs/Horus/Gorgon/Chassis.png`;

// HEADS
const headGorgonImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/Head - Gorgon.png`;
const headKazuImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/Head - Kazu.png`;
// PREMIUM
const headAngularImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Angular.png`;
const headGrinImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Grin.png`;
const headHiImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Hi.png`;
const headHydraImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Hydra.png`;
const headNelsonImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Nelson.png`;
const headOgreImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Ogre.png`;
const headRogueImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Rogue.png`;
const headSkullImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Skull.png`;
const headSnekImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Snek.png`;
const headTalonImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Talon.png`;
const headScopehoundImage = `${BaseURL}/Mechs/Horus/Gorgon/heads/premium/Head - Scopehound.png`;

// WEAPONS
// LEFT
const weaponsLeftAssaultCannonImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Assault Cannon.png`;
const weaponsLeftAssaultCannonPlasmaGauntletImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Assault Cannon _ Plasma Gauntlet.png`;
const weaponsLeftAutoshotgunPlasmaGauntletImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Autoshotgun _ Plasma Gauntlet.png`;
const weaponsLeftAutoshotgunImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Autoshotgun.png`;
const weaponsLeftDualAssaultCannonsImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Dual Assault Cannon.png`;
const weaponsLeftDualAutoshotgunImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Dual Autoshotgun.png`;
const weaponsLeftHandImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Hand.png`;
const weaponsLeftPlasmaGauntletImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/left/Main Weapon Left - Plasma Gauntlet.png`;

// RIGHT
const weaponsRightAssaultCannonImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/right/Main Weapon Right - Assault Cannon.png`;
const weaponsRightAutoshotgunImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/right/Main Weapon Right - Autoshotgun.png`;
const weaponsRightHandImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/right/Main Weapon Right - Hand.png`;
const weaponsRightPlasmaGauntletImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/right/Main Weapon Right - Plasma Gauntlet.png`;
const weaponsRightShieldImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/right/Main Weapon Right - Shield.png`;

// SECONDARY WEAPONS
const secondaryWeaponApertureImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/Top - Aperture.png`;
const secondaryWeaponBasiliskImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/Top - Basilisk Projector.png`;
const secondaryWeaponVorpalGunImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/Top - Vorpal Gun.png`;
// LEGENDARY
const secondaryWeaponMedusaImage = `${BaseURL}/Mechs/Horus/Gorgon/weapons/legendary/Top - Medusa.png`;

// REAR MOUNT
const rearMountSwordsImage = `${BaseURL}/Mechs/Horus/Gorgon/rear mount/Rear - Swords.png`;

// ACCESSORIES
const accessoryApertureImage = `${BaseURL}/Mechs/Horus/Gorgon/accessories/Glow - Aperture.png`;
const accessoryBasiliskImage = `${BaseURL}/Mechs/Horus/Gorgon/accessories/Glow - Basilisk Projector.png`;
const accessoryVorpalGunImage = `${BaseURL}/Mechs/Horus/Gorgon/accessories/Glow - Vorpal Gun.png`;
// LEGENDARY
const accessoryMedusaImage = `${BaseURL}/Mechs/Horus/Gorgon/accessories/legendary/Glow - Medusa.png`;

const coreImage = `${BaseURL}/Mechs/Horus/Gorgon/Gorgon (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/Horus/Gorgon/preview.png`;

const quadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "36px",
    right: "59px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weaponSpecial: "secondaryWeaponValue",
    removeNone: {
        accessory1: true,
        accessory2: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "1",
        "accessory1Value": "0",
        "accessory2Value": "8",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "GORGON",
            foremostSrc: headGorgonImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "ROGUE",
            foremostSrc: headRogueImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "NELSON",
            foremostSrc: headNelsonImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ANGULAR",
            foremostSrc: headAngularImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "GRIN",
            foremostSrc: headGrinImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "SKULL",
            foremostSrc: headSkullImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "HI",
            foremostSrc: headHiImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HYDRA",
            foremostSrc: headHydraImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SNEK",
            foremostSrc: headSnekImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "TALON",
            foremostSrc: headTalonImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "OGRE",
            foremostSrc: headOgreImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GORGON",
            src: bodyGorgonImage,
            shadowDetails: quadLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "APERTURE",
                src: secondaryWeaponApertureImage,
            },
            {
                value: 1,
                label: "BASILISK PROJECTOR",
                src: secondaryWeaponBasiliskImage,
            },
            {
                value: 2,
                label: "VORPAL GUN",
                src: secondaryWeaponVorpalGunImage,
            },
            {
                value: 3,
                label: "MEDUSA",
                src: secondaryWeaponMedusaImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "APERTURE",
            frontSrc: accessoryApertureImage,
        },
        {
            value: 1,
            label: "BASILISK PROJECTOR",
            frontSrc: accessoryBasiliskImage,
        },
        {
            value: 2,
            label: "VORPAL GUN",
            frontSrc: accessoryVorpalGunImage,
        },
        {
            value: 3,
            label: "MEDUSA",
            frontSrc: accessoryMedusaImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SWORDS",
            src: rearMountSwordsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "LEFT - HAND",
            src: weaponsLeftHandImage,
        },
        {
            value: 1,
            label: "LEFT - AUTOSHOTGUN",
            src: weaponsLeftAutoshotgunImage,
        },
        {
            value: 2,
            label: "LEFT - ASSAULT CANNON",
            src: weaponsLeftAssaultCannonImage,
        },
        {
            value: 3,
            label: "LEFT - PLASMA GAUNTLET",
            src: weaponsLeftPlasmaGauntletImage,
        },
        {
            value: 4,
            label: "LEFT - DUAL AUTOSHOTGUN",
            src: weaponsLeftDualAutoshotgunImage,
        },
        {
            value: 5,
            label: "LEFT - DUAL ASSAULT CANNONS",
            src: weaponsLeftDualAssaultCannonsImage,
        },
        {
            value: 6,
            label: "LEFT - ASSAULT CANNON + PLASMA GAUNTLET",
            src: weaponsLeftAssaultCannonPlasmaGauntletImage,
        },
        {
            value: 7,
            label: "LEFT - AUTOSHOTGUN + PLASMA GAUNTLET",
            src: weaponsLeftAutoshotgunPlasmaGauntletImage,
        },
        {
            value: 8,
            label: "RIGHT - HAND",
            src: weaponsRightHandImage,
        },
        {
            value: 9,
            label: "RIGHT - AUTOSHOTGUN",
            src: weaponsRightAutoshotgunImage,
        },
        {
            value: 10,
            label: "RIGHT - ASSAULT CANNON",
            src: weaponsRightAssaultCannonImage,
        },
        {
            value: 11,
            label: "RIGHT - PLASMA GAUNTLET",
            src: weaponsRightPlasmaGauntletImage,
        },
        {
            value: 12,
            label: "RIGHT - SHIELD",
            src: weaponsRightShieldImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "MAIN WEAPONS",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "TOP",
            value: "weapon",
        },
        {
            label: "TOP GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "MAIN WEAPON 1",
            value: "accessory1",
        },
        {
            label: "MAIN WEAPON 2",
            value: "accessory2",
        },
        {
            label: "MAIN WEAPON 3",
            value: "accessory3",
        },
        {
            label: "MAIN WEAPON 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "TOP",
        "secondaryWeapon": "TOP GLOW",
        "accessory1": "MAIN WEAPON 1",
        "accessory2": "MAIN WEAPON 2",
        "accessory3": "MAIN WEAPON 3",
        "accessory4": "MAIN WEAPON 4",
    },
};
