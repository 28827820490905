import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI-Medea`;

// BODY
const bodyQueenImage = `${imagePath}/Chassis - Queen.png`;
const bodyRingImage = `${imagePath}/Chassis - Ring.png`;

// HEADS
const headMedeaImage = `${imagePath}/heads/Head - Medea.png`;
const headMedeaCrownImage = `${imagePath}/heads/Head - Medea Crown.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - FT Nelson.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornsDownImage = `${imagePath}/heads/Head - Horns Down.png`;
const headHornsUpImage = `${imagePath}/heads/Head - Horns Up.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
const weaponCrookImage = `${imagePath}/weapons/Weapon - Crook.png`;
const weaponJasonsLoveImage = `${imagePath}/weapons/Weapon - Jason_s Love.png`;
const weaponJasonsLoveSheepImage = `${imagePath}/weapons/Weapon - Jason_s Love Sheep.png`;
const weaponStaffUnderImage = `${imagePath}/weapons/Weapon - Staffs Under.png`;

// GLOWS
const glowCrookImage = `${imagePath}/glows/Glow - Crook.png`;
const glowHornsdownImage = `${imagePath}/glows/Glow - Horns down.png`;
const glowHornsupImage = `${imagePath}/glows/Glow - Horns up.png`;
const glowJasonsLove1Image = `${imagePath}/glows/Glow - Jason_s Love 1.png`;
const glowJasonsLove2Image = `${imagePath}/glows/Glow - Jason_s Love 2.png`;
const glowQueenChassisImage = `${imagePath}/glows/Glow - Queen Chassis.png`;
const glowRingChassisImage = `${imagePath}/glows/Glow - Ring Chassis.png`;
const glowRoyaltyUnderImage = `${imagePath}/glows/Glow - Royalty Under.png`;
const glowRoyaltyImage = `${imagePath}/glows/Glow - Royalty.png`;
const glowSpartoiNexusImage = `${imagePath}/glows/Glow - Spartoi Nexus.png`;
const glowSpartoiNexusUnderImage = `${imagePath}/glows/Glow - Spartoi Nexus Under.png`;

const glowDroneAriesDrones1Image = `${imagePath}/drones/Glow - Aries Drones 1.png`;
const glowDroneAriesDrones2Image = `${imagePath}/drones/Glow - Aries Drones 2.png`;
const glowDroneAriesDrones3Image = `${imagePath}/drones/Glow - Aries Drones 3.png`;
const glowDroneAriesDrones4Image = `${imagePath}/drones/Glow - Aries Drones 4.png`;
const glowDroneAriesDronesSoloImage = `${imagePath}/drones/Glow - Aries Drones Solo.png`;
const glowDroneJasonsLoveAriesDroneImage = `${imagePath}/drones/Glow - Jason_s Love Aries Drone.png`;
const glowDroneSheepCrookImage = `${imagePath}/drones/Glow - Sheep Crook.png`;

// DRONES
const droneAriesDroneSoloImage = `${imagePath}/drones/Chassis - Aries Drone Solo.png`;
const droneAriesCrookImage = `${imagePath}/drones/Drones - Aries Crook.png`;
const droneAriesDrones1Image = `${imagePath}/drones/Drones - Aries Drones 1.png`;
const droneAriesDrones2Image = `${imagePath}/drones/Drones - Aries Drones 2.png`;
const droneAriesDrones3Image = `${imagePath}/drones/Drones - Aries Drones 3.png`;
const droneAriesDrones4Image = `${imagePath}/drones/Drones - Aries Drones 4.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "74px",
    right: "7px",
};

export default {
    previewImg: previewImage,
    isMegadeus: true,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "1",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "4",
        "accessory1Value": "9",
        "accessory2Value": "0",
        "accessory3Value": "6",
        "accessory4Value": "14",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "MEDEA",
            src: headMedeaImage,
        },
        {
            value: 1,
            label: "MEDEA CROWN",
            src: headMedeaCrownImage,
        },
        {
            value: 2,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 6,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 7,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 8,
            label: "HOOD",
            src: headHoodImage,
        },
        {
            value: 9,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 10,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 11,
            label: "HORNS DOWN",
            src: headHornsDownImage,
        },
        {
            value: 12,
            label: "HORNS UP",
            src: headHornsUpImage,
        },
        {
            value: 13,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 14,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 15,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 16,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 17,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 18,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 19,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 20,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 21,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 22,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 23,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 24,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 25,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 26,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 27,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 28,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 29,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 30,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 31,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 32,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "QUEEN",
            backSrc: bodyQueenImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
        {
            value: 1,
            label: "RING",
            backSrc: bodyRingImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CROOK",
                src: weaponCrookImage,
                backSrc: weaponStaffUnderImage,
            },
            {
                value: 1,
                label: "JASON'S LOVE",
                src: weaponJasonsLoveImage,
                backSrc: weaponStaffUnderImage,
            },
            {
                value: 2,
                label: "JASON'S LOVE - SHEEP",
                src: weaponJasonsLoveSheepImage,
                backSrc: weaponStaffUnderImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARIES - CROOK",
            src: droneAriesCrookImage,
        },
        {
            value: 1,
            label: "ARIES DRONE SOLO",
            src: droneAriesDroneSoloImage,
        },
        {
            value: 2,
            label: "ARIES DRONES 1",
            src: droneAriesDrones1Image,
        },
        {
            value: 3,
            label: "ARIES DRONES 2",
            src: droneAriesDrones2Image,
        },
        {
            value: 4,
            label: "ARIES DRONES 3",
            src: droneAriesDrones3Image,
        },
        {
            value: 5,
            label: "ARIES DRONES 4",
            src: droneAriesDrones4Image,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CHASSIS - QUEEN",
            src: glowQueenChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS - RING",
            src: glowRingChassisImage,
        },
        {
            value: 2,
            label: "CROOK",
            frontSrc: glowCrookImage,
        },
        {
            value: 3,
            label: "CROOK - SHEEP",
            frontSrc: glowDroneSheepCrookImage,
        },
        {
            value: 4,
            label: "HORNS DOWN",
            frontSrc: glowHornsdownImage,
        },
        {
            value: 5,
            label: "HORNS UP",
            frontSrc: glowHornsupImage,
        },
        {
            value: 6,
            label: "JASON'S LOVE 1",
            frontSrc: glowJasonsLove1Image,
        },
        {
            value: 7,
            label: "JASON'S LOVE 2",
            frontSrc: glowJasonsLove2Image,
        },
        {
            value: 8,
            label: "JASON'S LOVE - ARIES DRONE",
            frontSrc: glowDroneJasonsLoveAriesDroneImage,
        },
        {
            value: 9,
            label: "ROYALTY",
            frontSrc: glowRoyaltyImage,
            backSrc: glowRoyaltyUnderImage,
        },
        {
            value: 10,
            label: "SPARTOI NEXUS",
            frontSrc: glowSpartoiNexusImage,
            backSrc: glowSpartoiNexusUnderImage,
        },
        {
            value: 11,
            label: "ARIES DRONES SOLO",
            frontSrc: glowDroneAriesDronesSoloImage,
        },
        {
            value: 12,
            label: "ARIES DRONES 1",
            frontSrc: glowDroneAriesDrones1Image,
        },
        {
            value: 13,
            label: "ARIES DRONES 2",
            frontSrc: glowDroneAriesDrones2Image,
        },
        {
            value: 14,
            label: "ARIES DRONES 3",
            frontSrc: glowDroneAriesDrones3Image,
        },
        {
            value: 15,
            label: "ARIES DRONES 4",
            frontSrc: glowDroneAriesDrones4Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "DRONES",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "DRONES",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
