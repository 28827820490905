import ace from "./Mechs-Corpro-Ace";
import aegis from "./Mechs-Corpro-Aegis";
import archer from "./Mechs-Corpro-Archer";
import archerII from "./Mechs-Corpro-Archer-Mk-II";
import assassin from "./Mechs-Corpro-Assassin";
import assault from "./Mechs-Corpro-Assault";
import avenger from "./Mechs-Corpro-Avenger";
import barricade from "./Mechs-Corpro-Barricade";
import bastion from "./Mechs-Corpro-Bastion";
import berserker from "./Mechs-Corpro-Berserker";
import bombard from "./Mechs-Corpro-Bombard";
import breacher from "./Mechs-Corpro-Breacher";
import cataphract from "./Mechs-Corpro-Cataphract";
import demolisher from "./Mechs-Corpro-Demolisher";
import eliteRonin from "./Mechs-Corpro-Elite-Ronin";
import engineer from "./Mechs-Corpro-Engineer";
import goliath from "./Mechs-Corpro-Goliath";
import hive from "./Mechs-Corpro-Hive";
import hornet from "./Mechs-Corpro-Hornet";
import MechsCorproLeech from "./Mechs-Corpro-Leech";
import lurker from "./Mechs-Corpro-Lurker";
import mirage from "./Mechs-Corpro-Mirage";
import operator from "./Mechs-Corpro-Operator";
import priest from "./Mechs-Corpro-Priest";
import pyro from "./Mechs-Corpro-Pyro";
import rainmaker from "./Mechs-Corpro-Rainmaker";
import ronin from "./Mechs-Corpro-Ronin";
import scourer from "./Mechs-Corpro-Scourer";
import scout from "./Mechs-Corpro-Scout";
import seeder from "./Mechs-Corpro-Seeder";
import sentinel from "./Mechs-Corpro-Sentinel";
import sniper from "./Mechs-Corpro-Sniper";
import specter from "./Mechs-Corpro-Specter";
import spite from "./Mechs-Corpro-Spite";
import strider from "./Mechs-Corpro-Strider";
import support from "./Mechs-Corpro-Support";
import tempest from "./Mechs-Corpro-Tempest";
import witch from "./Mechs-Corpro-Witch";

export default [
    Object.assign({ value: 0, label: "ACE" }, ace),
    Object.assign({ value: 1, label: "AEGIS" }, aegis),
    Object.assign({ value: 2, label: "ARCHER" }, archer),
    Object.assign({ value: 3, label: "ARCHER MK II" }, archerII),
    Object.assign({ value: 4, label: "ASSASSIN" }, assassin),
    Object.assign({ value: 5, label: "ASSAULT" }, assault),
    Object.assign({ value: 6, label: "AVENGER" }, avenger),
    Object.assign({ value: 7, label: "BARRICADE" }, barricade),
    Object.assign({ value: 8, label: "BASTION" }, bastion),
    Object.assign({ value: 9, label: "BERSERKER" }, berserker),
    Object.assign({ value: 10, label: "BOMBARD" }, bombard),
    Object.assign({ value: 11, label: "BREACHER" }, breacher),
    Object.assign({ value: 12, label: "CATAPHRACT" }, cataphract),
    Object.assign({ value: 13, label: "DEMOLISHER" }, demolisher),
    Object.assign({ value: 14, label: "ENGINEER" }, engineer),
    Object.assign({ value: 15, label: "GOLIATH" }, goliath),
    Object.assign({ value: 16, label: "HIVE" }, hive),
    Object.assign({ value: 17, label: "HORNET" }, hornet),
    Object.assign({ value: 18, label: "LEECH" }, MechsCorproLeech),
    Object.assign({ value: 19, label: "LURKER" }, lurker),
    Object.assign({ value: 20, label: "MIRAGE" }, mirage),
    Object.assign({ value: 21, label: "OPERATOR" }, operator),
    Object.assign({ value: 22, label: "PRIEST" }, priest),
    Object.assign({ value: 23, label: "PYRO" }, pyro),
    Object.assign({ value: 24, label: "RAINMAKER" }, rainmaker),
    Object.assign({ value: 25, label: "RONIN" }, ronin),
    Object.assign({ value: 26, label: "RONIN ELITE" }, eliteRonin),
    Object.assign({ value: 27, label: "SCOURER" }, scourer),
    Object.assign({ value: 28, label: "SCOUT" }, scout),
    Object.assign({ value: 29, label: "SEEDER" }, seeder),
    Object.assign({ value: 30, label: "SENTINEL" }, sentinel),
    Object.assign({ value: 31, label: "SNIPER" }, sniper),
    Object.assign({ value: 32, label: "SPECTER" }, specter),
    Object.assign({ value: 33, label: "SPITE" }, spite),
    Object.assign({ value: 34, label: "STRIDER" }, strider),
    Object.assign({ value: 35, label: "SUPPORT" }, support),
    Object.assign({ value: 36, label: "TEMPEST" }, tempest),
    Object.assign({ value: 37, label: "WITCH" }, witch),
];

export const corproTemplateList = [
    {
        label: "DEFAULT",
        value: {"camo1Value":"NONE","camo2Value":"NONE","camo3Value":"NONE","camo4Value":"NONE","camo1TintValue":"none","camo2TintValue":"none","camo3TintValue":"none","camo4TintValue":"none","chassisTintValue":"none","weaponTintValue":"none","secondaryWeaponTintValue":"none"},
    },
    {
        label: "BUBBLEGUM CRISIS",
        value: {"camo1Value":"5","camo2Value":"0","camo3Value":"3","camo4Value":"8","camo1TintValue":"sepia(100%) saturate(300%) brightness(172%) hue-rotate(300deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(65%) saturate(312%) brightness(200%) hue-rotate(150deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(65%) saturate(263%) brightness(130%) hue-rotate(150deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(151%) brightness(97%) hue-rotate(261deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(66%) saturate(160%) brightness(98%) hue-rotate(189deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(65%) saturate(424%) brightness(200%) hue-rotate(286deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(300%) brightness(181%) hue-rotate(332deg) invert(0%) contrast(100%)"},
    },
    {
        label: "TUNDRA SLATE",
        value: {"camo1Value":"0","camo2Value":"3","camo3Value":"6","camo4Value":"4","camo1TintValue":"sepia(100%) saturate(69%) brightness(200%) hue-rotate(55deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(62%) brightness(43%) hue-rotate(141deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(62%) brightness(111%) hue-rotate(141deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(103%) brightness(43%) hue-rotate(141deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(103%) brightness(81%) hue-rotate(141deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(68%) saturate(89%) brightness(142%) hue-rotate(104deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(68%) saturate(366%) brightness(130%) hue-rotate(116deg) invert(0%) contrast(100%)"},
    },
    {
        label: "CHARCOAL SHERBET",
        value: {"camo1Value":"NONE","camo2Value":"2","camo3Value":"5","camo4Value":"6","camo1TintValue":"none","camo2TintValue":"sepia(100%) saturate(448%) brightness(160%) hue-rotate(318deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(0%) brightness(40%) hue-rotate(345deg) invert(0%) contrast(100%)","camo4TintValue":"none","chassisTintValue":"sepia(100%) saturate(0%) brightness(44%) hue-rotate(300deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(38%) brightness(160%) hue-rotate(347deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(108%) hue-rotate(320deg) invert(0%) contrast(100%)"},
    },
    {
        label: "FERROUS OXIDE",
        value: {"camo1Value":"3","camo2Value":"0","camo3Value":"6","camo4Value":"9","camo1TintValue":"sepia(100%) saturate(364%) brightness(66%) hue-rotate(316deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(500%) brightness(54%) hue-rotate(305deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(260%) brightness(85%) hue-rotate(337deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(72%) saturate(265%) brightness(97%) hue-rotate(336deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(33%) saturate(239%) brightness(74%) hue-rotate(308deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(69%) brightness(129%) hue-rotate(337deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(118%) hue-rotate(325deg) invert(0%) contrast(100%)"},
    },
    {
        label: "HEDGE KNIGHT",
        value: {"camo1Value":"0","camo2Value":"4","camo3Value":"3","camo4Value":"9","camo1TintValue":"sepia(48%) saturate(0%) brightness(187%) hue-rotate(318deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(40%) saturate(352%) brightness(60%) hue-rotate(357deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(48%) saturate(308%) brightness(104%) hue-rotate(360deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(48%) saturate(373%) brightness(44%) hue-rotate(95deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(64%) saturate(282%) brightness(84%) hue-rotate(43deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(48%) saturate(185%) brightness(63%) hue-rotate(74deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(241%) brightness(137%) hue-rotate(328deg) invert(0%) contrast(100%)"},
    },
];
