import React, { createContext, useContext, useEffect, useState } from "react";
import url from "url";
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable } from "firebase/functions";

import { AnalyticsContext } from "../Analytics/AnalyticsContextWrapper";

const firebaseConfig = {
    apiKey: "AIzaSyB8mPfjjWbOHDa7WxLoT7Dqa_hmMHTqZ0g",
    authDomain: "retrograde-minis.firebaseapp.com",
    databaseURL: "https://retrograde-minis.firebaseio.com",
    projectId: "retrograde-minis",
    storageBucket: "retrograde-minis.appspot.com",
    messagingSenderId: "344461260124",
    appId: "1:344461260124:web:7833e73b0014b222447d99",
    measurementId: "G-8CGHCG49SX"
  };
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const functions = getFunctions(app);

export const UserAuthContext = createContext(null);

function setRefreshTokenCookie(refreshToken) {
    const now = new Date();
    const twoWeeksFromNow = new Date(now.setDate(now.getDate() + 14));

    document.cookie = `refreshToken=${window.encodeURIComponent(refreshToken)}; Secure; expires=${twoWeeksFromNow};`;
}

function UserAuthContextWrapper ({ ...props }) {
    const [ user, setUser ] = useState(
        (window.location.hostname === "localhost" || window.location.host.includes("rgm-test")) && process.env.NODE_ENV !== "test" ?
            {user: { email: "RGM-Test", }, isLegendaryUser: true, isPremiumUser: true } : null
    );

    const ReactGA = useContext(AnalyticsContext);

    useEffect(() => {
        const oauthGrantCode = url.parse(window.location.href, true).query.code;
        const hasRefreshToken = document.cookie.split(";").filter((item) => item.trim().startsWith("refreshToken=")).length;

        async function handleOauth(oauthCode) {
            const oauthConfirm = httpsCallable(functions, "handleOAuthRedirectRequest");

            const oauthConfirmation = await oauthConfirm({code: oauthCode});


            if (typeof oauthConfirmation.data !== "string") {
                setRefreshTokenCookie(oauthConfirmation.data.tokens);
                ReactGA.set({ userId: oauthConfirmation.data.user.email });

                ReactGA.event({
                    category: "Login",
                    action: "New Login",
                    label: `{
                        isPremiumUser: ${oauthConfirmation.data.isPremiumUser},
                        isLegendaryUser: ${oauthConfirmation.data.isLegendaryUser},
                    }`,
                });

                return setUser(JSON.parse(JSON.stringify(oauthConfirmation.data)));
            }
            console.error(oauthConfirmation.data);

            return setUser(null);
        }

        async function refreshUser() {
            const refreshToken = window.decodeURIComponent(document.cookie.split(";").filter((item) => item.trim().startsWith("refreshToken="))[0].split("refreshToken=")[1]);

            if (refreshToken !== "false") {
                const oauthConfirm = httpsCallable(functions, "handleOAuthRefreshRequest");

                const oauthConfirmation = await oauthConfirm({code: refreshToken});

                if (typeof oauthConfirmation.data !== "string") {
                    setRefreshTokenCookie(oauthConfirmation.data.tokens.refresh_token);
                    ReactGA.set({ userId: oauthConfirmation.data.user.email });

                    ReactGA.event({
                        category: "Login",
                        action: "Refresh Login",
                        label: `{
                            isPremiumUser: ${oauthConfirmation.data.isPremiumUser},
                            isLegendaryUser: ${oauthConfirmation.data.isLegendaryUser},
                        }`,
                    });

                    return setUser(JSON.parse(JSON.stringify(oauthConfirmation.data)));
                }

                document.cookie = "refreshToken=false; Secure; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

                return setUser(null);
            }
        }

        if (hasRefreshToken && !user) {
            refreshUser();
        } else if (oauthGrantCode && !user) {
            handleOauth(oauthGrantCode);
        }
    }, [user, ReactGA]);

    return (
        <UserAuthContext.Provider value={{ user, setUser }}>
            {props.children}
        </UserAuthContext.Provider>
    );
}

export default UserAuthContextWrapper;
