import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Coromuel`;

// BODY
const bodyChassisImage = `${imagePath}/Coromeul_Chassis.png`;

// HEADS
const headCoromuelImage = `${imagePath}/heads/coromuel.png`;
const headAtomsKImage = `${imagePath}/heads/Coromeul_Head_Atoms K.png`;
const headBlackSpotImage = `${imagePath}/heads/Coromeul_Head_Black Spot.png`;
const headBriarImage = `${imagePath}/heads/Coromeul_Head_Briar.png`;
const headCheddahImage = `${imagePath}/heads/Coromeul_Head_Cheddah.png`;
const headCyclopsImage = `${imagePath}/heads/Coromeul_Head_Cyclops.png`;
const headDungamWingImage = `${imagePath}/heads/Coromeul_Head_Dungam Wing.png`;
const headHornedImage = `${imagePath}/heads/Coromeul_Head_Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Coromeul_Head_Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/Coromeul_Head_Laborer.png`;
const headMaliceImage = `${imagePath}/heads/Coromeul_Head_Malice.png`;
const headNaturalLawImage = `${imagePath}/heads/Coromeul_Head_Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Coromeul_Head_Natural Law Hair.png`;
const headOgreImage = `${imagePath}/heads/Coromeul_Head_Ogre.png`;
const headRainTransformedImage = `${imagePath}/heads/Coromeul_Head_Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Coromeul_Head_Rain Transformed Hair.png`;
const headRamshackleImage = `${imagePath}/heads/Coromeul_Head_Ramshackle.png`;
const headScarabImage = `${imagePath}/heads/Coromeul_Head_Scarab.png`;
const headStalwartImage = `${imagePath}/heads/Coromeul_Head_Stalwart.png`;
const headVanguardImage = `${imagePath}/heads/Coromeul_Head_Vanguard.png`;
const headVanquisherImage = `${imagePath}/heads/Coromeul_Head_Vanquisher.png`;
const headVisionaryImage = `${imagePath}/heads/Coromeul_Head_Visionary.png`;

// WEAPONS
// LEFT
const leftArmEmptyImage = `${imagePath}/weapons/left/Coromeul_Left Arm_Empty.png`;
const leftArmStaveImage = `${imagePath}/weapons/left/Coromeul_Left Arm_Stave.png`;
const leftArmTwinPointBladeImage = `${imagePath}/weapons/left/Coromeul_Left Arm_Twin-Point Blade.png`;

// RIGHT
const rightArmLanceImage = `${imagePath}/weapons/right/Coromeul_Right Arm_Lance.png`;
const rightArmPileBunkerImage = `${imagePath}/weapons/right/Coromeul_Right Arm_Pile Bunker.png`;

// 2H
const bothArmDecksweeperImage = `${imagePath}/weapons/2h/Coromeul_Right Arm 2h_Decksweeper.png`;
const bothArmFlamethrowerImage = `${imagePath}/weapons/2h/Coromeul_Right Arm 2h_Flamethrower.png`;

// REAR MOUNT
const rearMountWingsImage = `${imagePath}/accessories/Coromeul_Wings Rear_Coromeul.png`;
const rearMountWingsFrontImage = `${imagePath}/accessories/Coromeul_Wings Front_Coromeul.png`;

// GLOWS
const glowBoosterImage = `${imagePath}/accessories/Coromeul_Rear Accessories_Booster Glow.png`;
const glowCoromeulHaloImage = `${imagePath}/accessories/Coromeul_Accessories Front_ Coromeul Halo.png`;
const glowLanceFlamesImage = `${imagePath}/accessories/Coromeul_Accessories Front_Lance Flames.png`;
const glowLanceSparksImage = `${imagePath}/accessories/Coromeul_Accessories Front_Lance Sparks.png`;
const glowPilebunkerImage = `${imagePath}/accessories/Coromeul_Accessories Front_ Glow-Pilebunker.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "-43px",
    right: "-15px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "1",
        "secondaryWeaponValue": "0",
        "accessory1Value": "1",
        "accessory2Value": "0",
        "accessory3Value": "3",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "COROMUEL",
            src: headCoromuelImage,
        },
        {
            value: 1,
            label: "ATOMS K",
            src: headAtomsKImage,
        },
        {
            value: 2,
            label: "BLACK SPOT",
            src: headBlackSpotImage,
        },
        {
            value: 3,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 6,
            label: "DUNGAM WING",
            src: headDungamWingImage,
        },
        {
            value: 7,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 8,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 9,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 10,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 11,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 12,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 13,
            label: "OGRE",
            src: headOgreImage,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 15,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 16,
            label: "RAMSHACKLE",
            src: headRamshackleImage,
        },
        {
            value: 17,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 18,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 19,
            label: "VANGUARD",
            src: headVanguardImage,
        },
        {
            value: 20,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 21,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "IMI COROMUEL",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Created by ${contributorList.PINKHYENA.title}. Commissioned by Kiyo. Original art by `,
                title: "logan_arts",
                url: "https://www.instagram.com/oldmanlogan/",
            },
            isPlusTwo: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "EMPTY",
                src: leftArmEmptyImage,
            },
            {
                value: 1,
                label: "STAVE",
                src: leftArmStaveImage,
            },
            {
                value: 2,
                label: "TWIN-POINT BLADE",
                src: leftArmTwinPointBladeImage,
            },
            {
                value: 3,
                label: "2 HANDED - DECKSWEEPER",
                src: bothArmDecksweeperImage,
            },
            {
                value: 4,
                label: "2 HANDED - FLAMETHROWER",
                src: bothArmFlamethrowerImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "LANCE",
            src: rightArmLanceImage,
        },
        {
            value: 1,
            label: "PILE BUNKER",
            src: rightArmPileBunkerImage,
        },
        {
            value: 2,
            label: "2 HANDED - DECKSWEEPER",
            src: bothArmDecksweeperImage,
        },
        {
            value: 3,
            label: "2 HANDED - FLAMETHROWER",
            src: bothArmFlamethrowerImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "WINGS",
            foremostSrc: rearMountWingsFrontImage,
            src: rearMountWingsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BOOSTER",
            backSrc: glowBoosterImage,
        },
        {
            value: 1,
            label: "COROMUEL HALO",
            frontSrc: glowCoromeulHaloImage,
        },
        {
            value: 2,
            label: "LANCE FLAMES",
            frontSrc: glowLanceFlamesImage,
        },
        {
            value: 3,
            label: "LANCE SPARKS",
            frontSrc: glowLanceSparksImage,
        },
        {
            value: 4,
            label: "PILEBUNKER",
            frontSrc: glowPilebunkerImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L ARM",
            value: "weapon",
        },
        {
            label: "R ARM",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
