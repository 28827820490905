import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyHazardImage = `${BaseURL}/Mechs/Custom/Hazard/Chassis.png`;

// HEADS
const headHazardImage = `${BaseURL}/Mechs/Custom/Hazard/heads/Head - Hazard.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Hazard/heads/Head - Kazu.png`;
// PREMIUM
const headCalibanImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Chimera.png`;
const headCockpitImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Cockpit.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Cyclops.png`;
const headEnkiduImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Enkidu.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Malice.png`;
const headMourningCloakImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Mourning Cloak.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Nelson.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Rain.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Rain Hair.png`;
const headScopedogImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Scopedog.png`;
const headTokugawaImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Tokugawa.png`;
const headVanquisherImage = `${BaseURL}/Mechs/Custom/Hazard/heads/premium/Head - Vanquisher.png`;

// SHOULDER
const shoulderFinsImage = `${BaseURL}/Mechs/Custom/Hazard/shoulder/Shoulder - Fins.png`;
const shoulderHeavyCannonImage = `${BaseURL}/Mechs/Custom/Hazard/shoulder/Shoulder - Heavy Cannon.png`;
const shoulderRotaryCannonImage = `${BaseURL}/Mechs/Custom/Hazard/shoulder/Shoulder - Rotary Cannon.png`;
const shoulderMissilesImage = `${BaseURL}/Mechs/Custom/Hazard/shoulder/Shoulder - Missiles.png`;

// WEAPONS
// LEFT
const leftWeaponHandImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/left/Left Weapon - Hand.png`;
const leftWeaponSMGImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/left/Left Weapon - SMG.png`;
const leftWeaponBladeImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/left/Left Weapon - Blade.png`;
const leftWeaponSpearImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/left/Left Weapon - Spear.png`;

// RIGHT
const rightWeaponHandImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/right/Right Weapon - Hand.png`;
const rightWeaponSMGImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/right/Right Weapon - SMG.png`;
const rightWeaponBladeImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/right/Right Weapon - Blade.png`;
const rightWeaponSpearImage = `${BaseURL}/Mechs/Custom/Hazard/weapons/right/Right Weapon - Spear.png`;

// ACCESSORY
const tiresBlackImage = `${BaseURL}/Mechs/Custom/Hazard/accessories/Tires - Black.png`;
const tiresLinedImage = `${BaseURL}/Mechs/Custom/Hazard/accessories/Tires - Lined.png`;
const tiresOffroadImage = `${BaseURL}/Mechs/Custom/Hazard/accessories/Tires - Offroad.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Hazard/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "69px",
    right: "-7px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HAZARD",
            src: headHazardImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ENKIDU",
            src: headEnkiduImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MOURNING CLOAK",
            src: headMourningCloakImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "SCOPEDOG",
            src: headScopedogImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "TOKUGAWA",
            src: headTokugawaImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "VANQUISHER",
            src: headVanquisherImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "HAZARD",
            src: bodyHazardImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                src: leftWeaponHandImage,
            },
            {
                value: 1,
                label: "SMG",
                src: leftWeaponSMGImage,
            },
            {
                value: 2,
                label: "BLADE",
                src: leftWeaponBladeImage,
            },
            {
                value: 3,
                label: "SPEAR",
                src: leftWeaponSpearImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            src: rightWeaponHandImage,
        },
        {
            value: 1,
            label: "SMG",
            src: rightWeaponSMGImage,
        },
        {
            value: 2,
            label: "BLADE",
            src: rightWeaponBladeImage,
        },
        {
            value: 3,
            label: "SPEAR",
            src: rightWeaponSpearImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "FINS",
            src: shoulderFinsImage,
        },
        {
            value: 1,
            label: "HEAVY CANNON",
            src: shoulderHeavyCannonImage,
        },
        {
            value: 2,
            label: "ROTARY CANNON",
            src: shoulderRotaryCannonImage,
        },
        {
            value: 3,
            label: "MISSILES",
            src: shoulderMissilesImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BLACK TIRES",
            src: tiresBlackImage,
        },
        {
            value: 1,
            label: "LINED TIRES",
            src: tiresLinedImage,
        },
        {
            value: 2,
            label: "OFFROAD TIRES",
            src: tiresOffroadImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "SHOULDER",
            value: "rearMount",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "SHOULDER",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
