import React, { createContext, useState } from "react";
import { ThemeProvider } from "styled-components";

const SEASONS = {
    spring: "spring",
    summer: "summer",
    autumn: "autumn",
    winter: "winter",
};

const theme = {
    landingTransitionDuration: 4,
    landingBackground: "#FFF",
    categoryPrimaryColor: "#3E6082",
    categorySecondaryColor: "#001E40",
    parentCategoryPrimaryColor: "#234363",
    parentCategorySecondaryColor: "#000F21",
    subCategoryPrimaryColor: "#546A82",
    subCategorySecondaryColor: "#1A222B",
    mechCategoryPrimaryColor: "#666",
    mechCategorySecondaryColor: "#444",
    width: "1165px",
    tabletCutoffWidth: 1164,
    mobileCutoffWidth: 767,
};

const springTheme = Object.assign({}, theme, {
    mainBackground: "#1C404C",
    unitShadowColor: "#81809D",
});

const summerTheme = Object.assign({}, theme, {
    mainBackground: "#1C404C",
    unitShadowColor: "#578B94",
});

const autumnTheme = Object.assign({}, theme, {
    mainBackground: "#340005",
    unitShadowColor: "#787870",
});

const winterTheme = Object.assign({}, theme, {
    mainBackground: "#2F4644",
    unitShadowColor: "#7B7764",
});

const mechTheme = Object.assign({}, theme, {
    mainBackground: "#170911",
    unitShadowColor: "#441B30",
    landingBackground: "#000",
});

const lvoidTheme = Object.assign({}, theme, {
    mainBackground: "#F4F4F4",
    unitShadowColor: "#666",
    landingBackground: "#F4F4F4",
});

const dvoidTheme = Object.assign({}, theme, {
    mainBackground: "#111",
    unitShadowColor: "#666",
    landingBackground: "#111",
});

const currentSeasonTheme = SEASONS.summer;

const themesMap = {
    spring: {
        theme: springTheme,
        nextTheme: SEASONS.summer,
        value: SEASONS.spring,
        label: "Spring Ruins",
    },
    summer: {
        theme: summerTheme,
        nextTheme: SEASONS.autumn,
        value: SEASONS.summer,
        label: "Summer Ruins",
    },
    autumn: {
        theme: autumnTheme,
        nextTheme: SEASONS.winter,
        value: SEASONS.autumn,
        label: "Autumn Ruins",
    },
    winter: {
        theme: winterTheme,
        nextTheme: "mech",
        value: SEASONS.winter,
        label: "Winter Ruins",
    },
    mech: {
        theme: mechTheme,
        nextTheme: "dvoid",
        value: "mech",
        label: "Metrograde City",
    },
    dvoid: {
        theme: dvoidTheme,
        nextTheme: "lvoid",
        value: "dvoid",
        label: "Dark Void",
    },
    lvoid: {
        theme: lvoidTheme,
        nextTheme: SEASONS.spring,
        value: "lvoid",
        label: "Light Void",
    },
};

const themeOptions = Object.keys(themesMap).map((theme) => (
    <option
        key={`${themesMap[theme].value}-${themesMap[theme].label}`}
        value={themesMap[theme].value}
    >
        {themesMap[theme].label}
    </option>
));

export const CurrentThemeContext = createContext(null);

function ThemeContextWrapper({ ...props }) {
    const currentSideCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`currentSide=`));
    const currentThemeCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`currentTheme=`));
    const displayThemeCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`terminalTheme=`));

    const [currentTheme, setCurrentTheme] = useState(currentThemeCookie.length ? (currentThemeCookie[0].split("=")[1]) : currentSeasonTheme);
    const [currentSide, setCurrentSide] = useState(currentSideCookie.length ? (currentSideCookie[0].split("=")[1]) : "fantasy");
    const [displayTheme, setDisplayTheme] = useState(displayThemeCookie.length && (displayThemeCookie[0].split("=")[1]) === "true" ? true : false);

    const selectTheme = (selectedTheme) => {
        document.cookie = `currentTheme=${selectedTheme}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        setCurrentTheme(selectedTheme);
    };

    const toggleSide = () => {
        document.cookie = `currentSide=${currentSide === "fantasy" ? "mech" : "fantasy"}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        setCurrentSide(currentSide === "fantasy" ? "mech" : "fantasy");
    };

    const toggleDisplayTheme = () => {
        document.cookie = `terminalTheme=${!displayTheme}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        setDisplayTheme(!displayTheme);
    };

    return (
        <CurrentThemeContext.Provider
            value={{
                currentTheme,
                selectTheme,
                themeOptions,
                currentSide,
                toggleSide,
                displayTheme,
                toggleDisplayTheme,
                themeDetails: theme,
            }}
        >
            <ThemeProvider theme={themesMap[currentTheme].theme}>
                {props.children}
            </ThemeProvider>
        </CurrentThemeContext.Provider>
    );
}

export default ThemeContextWrapper;
