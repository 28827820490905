import React, { useContext } from "react";

import styled, { keyframes } from "styled-components";

import {CurrentThemeContext} from "../../../common/ThemeContextWrapper";

import {
    defaultColor as mechDefaultColor,
    retroTerminalColor,
} from "../Mechs/components/MechCategoryStyles";

const grid = keyframes`
    0%, 100% {
        opacity: 1;
    }
    40% {
        opacity: 0.5;
    }
`;

const firstPosition = 25;
const secondPosition = 40;
const thirdPosition = 55;

const LoaderDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
`;

LoaderDiv.displayName = "LoaderDiv";

const LoaderNode = styled.div`
    position: absolute;
    width: 10%;
    height: 10%;
    background: ${props => props.pixelColor};
    /* clip-path: polygon(0% 2px,2px 2px,2px 0%,calc(100% - 2px) 0%,calc(100% - 2px) 2px,100% 2px,100% calc(100% - 2px),calc(100% - 2px) calc(100% - 2px),calc(100% - 2px) 100%,2px 100%,2px calc(100% - 2px),0% calc(100% - 2px)); */
    animation: ${grid} 1.2s linear infinite;

    top: ${props => props.top}%;
    left: ${props => props.left}%;
    animation-delay: ${props => props.delay}s;
`;

LoaderNode.displayName = "LoaderNode";

const nodes = [
    {
        top: firstPosition,
        left: firstPosition,
        delay: 0,
    },
    {
        top: firstPosition,
        left: secondPosition,
        delay: -0.4,
    },
    {
        top: firstPosition,
        left: thirdPosition,
        delay: -0.8,
    },
    {
        top: secondPosition,
        left: firstPosition,
        delay: -0.4,
    },
    {
        top: secondPosition,
        left: secondPosition,
        delay: -0.8,
    },
    {
        top: secondPosition,
        left: thirdPosition,
        delay: -1.2,
    },
    {
        top: thirdPosition,
        left: firstPosition,
        delay: -0.8,
    },
    {
        top: thirdPosition,
        left: secondPosition,
        delay: -1.2,
    },
    {
        top: thirdPosition,
        left: thirdPosition,
        delay: -1.6,
    },
];

// TODO Account for terminal styling
function Loader({ pixelColor = mechDefaultColor }) {
    const { displayTheme } = useContext(CurrentThemeContext);

    return (
        <LoaderDiv>
            {nodes.map((node) => (
                <LoaderNode
                    key={`${node.top}-${node.left}-${new Date().getTime()}`}
                    pixelColor={displayTheme ? retroTerminalColor : pixelColor}
                    top={node.top}
                    left={node.left}
                    delay={node.delay}
                />
            ))}
        </LoaderDiv>
    );
}

export default Loader;
