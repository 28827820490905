import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const body2Image =  `${BaseURL}/Mechs/Misc/Egregorian 2/Egregorian 2.png`;

// HEADS
const head1Image = `${BaseURL}/Mechs/Misc/Egregorian 2/heads/Head - Head 1.png`;
const head2Image = `${BaseURL}/Mechs/Misc/Egregorian 2/heads/Head - Head 2.png`;
const head3Image = `${BaseURL}/Mechs/Misc/Egregorian 2/heads/Head - Head 3.png`;
const head4Image = `${BaseURL}/Mechs/Misc/Egregorian 2/heads/Head - Head 4.png`;
const head5Image = `${BaseURL}/Mechs/Misc/Egregorian 2/heads/Head - Head 5.png`;
const head6Image = `${BaseURL}/Mechs/Misc/Egregorian 2/heads/Head - Head 6.png`;

// TORSO
const torsoMantle1Image = `${BaseURL}/Mechs/Misc/Egregorian 2/torso/Torso - Mantle 1.png`;
const torsoMantle2Image = `${BaseURL}/Mechs/Misc/Egregorian 2/torso/Torso - Mantle 2.png`;

// ACCESSORIES
const accessoryScarfImage = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory - Scarf.png`;
const accessorySpareMagsImage = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory - Spare Mags.png`;
const accessoryStrapsImage = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory - Straps.png`;
const accessoryStraps2Image = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory - Straps 2.png`;
const accessoryAntennaeGlowImage = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory 2 - Antennae Glow.png`;
const accessoryEyeGlowImage = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory 2 - Eye Glow.png`;
const accessoryHelmet1Image = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory 2 - Helmet 1.png`;
const accessoryHelmet2Image = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory 2 - Helmet 2.png`;
const accessoryHelmet3Image = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory 2 - Helmet 3.png`;
const accessoryMusicalNotesImage = `${BaseURL}/Mechs/Misc/Egregorian 2/accessories/Accessory 2 - Musical Notes.png`;

const previewImage = `${BaseURL}/Mechs/Misc/Egregorian 2/preview.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    defaultValues: `{
    "chassisValue": "0",
    "headValue": "0",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HEAD 1",
            foremostSrc: head1Image,
        },
        {
            value: 1,
            label: "HEAD 2",
            foremostSrc: head2Image,
        },
        {
            value: 2,
            label: "HEAD 3",
            foremostSrc: head3Image,
        },
        {
            value: 3,
            label: "HEAD 4",
            foremostSrc: head4Image,
        },
        {
            value: 4,
            label: "HEAD 5",
            foremostSrc: head5Image,
        },
        {
            value: 5,
            label: "HEAD 6",
            foremostSrc: head6Image,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "EGREGORIAN 2",
            src: body2Image,
            shadowDetails: shadowDetails,
            originalArtCredit: {
                title: "Steve_From_Accounting",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MANTLE 1",
            frontSrc: torsoMantle1Image,
        },
        {
            value: 1,
            label: "MANTLE 2",
            frontSrc: torsoMantle2Image,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "SCARF",
            frontSrc: accessoryScarfImage,
        },
        {
            value: 1,
            label: "SPARE MAGS",
            frontSrc: accessorySpareMagsImage,
        },
        {
            value: 2,
            label: "STRAPS",
            frontSrc: accessoryStrapsImage,
        },
        {
            value: 3,
            label: "STRAPS 2",
            frontSrc: accessoryStraps2Image,
        },
        {
            value: 4,
            label: "ANTENNAE GLOW",
            foremostSrc: accessoryAntennaeGlowImage,
        },
        {
            value: 5,
            label: "EYE GLOW",
            foremostSrc: accessoryEyeGlowImage,
        },
        {
            value: 6,
            label: "HELMET 1",
            foremostSrc: accessoryHelmet1Image,
        },
        {
            value: 7,
            label: "HELMET 2",
            foremostSrc: accessoryHelmet2Image,
        },
        {
            value: 8,
            label: "HELMET 3",
            foremostSrc: accessoryHelmet3Image,
        },
        {
            value: 9,
            label: "MUSICAL NOTES",
            src: accessoryMusicalNotesImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "BODY",
            value: "chassis",
        },
        {
            label: "TORSO",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "BODY",
        "rearMount": "WINGS",
        "weapon": "ARMS",
        "secondaryWeapon": "TORSO",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
