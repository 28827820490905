import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/HA/Arthur`;

// BODY
const bodyImage = `${imagePath}/Arthur_Chassis.png`;
const bodyFrontImage = `${imagePath}/Arthur_Chassis_Front.png`;

// HEADS
const headArthurImage = `${imagePath}/heads/Arthur_Head_Arthur.png`;

// WEAPON
const weaponEmptyImage = `${imagePath}/weapons/Arthur_Weapons Right_Empty.png`;
const weaponClarentImage = `${imagePath}/weapons/Arthur_Weapons Right_Clarent.png`;
const weaponPlasmaCoreImage = `${imagePath}/weapons/Arthur_Weapons Right_Plasma Core.png`;
const weaponClaiomhSolaisImage = `${imagePath}/weapons/Arthur_Weapons Right_Claiomh Solais.png`;
const weaponNanocarbonBladeImage = `${imagePath}/weapons/Arthur_Weapons Right_Nanocarbon Blade.png`;
const weaponTerishimaImage = `${imagePath}/weapons/Arthur_Weapons Right_Terishima.png`;

// SHIELDS
const shieldImage = `${imagePath}/weapons/left/Arthur_Weapons Left_Shield.png`;
const shieldBannerImage = `${imagePath}/weapons/left/Arthur_Weapons Left_Shield Banner.png`;
const leftRevolverImage = `${imagePath}/weapons/left/Arthur_Weapons_Left_Revolver.png`;


// REAR MOUNT
const rearSmokeImage = `${imagePath}/accessories/Arthur_Accessories Front_Smoke.png`;
const rearSmokeBackImage = `${imagePath}/accessories/Arthur_Accessories Back_Smoke.png`;

// GLOW
const glowHeadImage = `${imagePath}/accessories/Arthur_Accessories Front_Head Glow.png`;
const glowBodyImage = `${imagePath}/accessories/Arthur_Accessories Mid_Body Glow.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "49px",
    right: "-40px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ARTHUR",
            src: headArthurImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ARTHUR",
            src: bodyImage,
            frontSrc: bodyFrontImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Created by ${contributorList.PINKHYENA.title}. Commissioned by Kiyo. Original art by `,
                title: "TerkMC",
                url: "https://x.com/Terk_MC?t=VJs8WNLd2jx-hBTMQnH-GA&s=09",
            },
            isPlusTwo: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "EMPTY",
                src: weaponEmptyImage,
            },
            {
                value: 1,
                label: "CLARENT",
                src: weaponClarentImage,
            },
            {
                value: 2,
                label: "PLASMA CORE",
                backSrc: weaponPlasmaCoreImage,
            },
            {
                value: 3,
                label: "CLAIMH SOLAIS",
                src: weaponClaiomhSolaisImage,
            },
            {
                value: 4,
                label: "NANOCARBON BLADE",
                src: weaponNanocarbonBladeImage,
            },
            {
                value: 5,
                label: "TERISHIMA",
                src: weaponTerishimaImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SHIELD",
            src: shieldImage,
        },
        {
            value: 1,
            label: "SHIELD + BANNER",
            src: shieldBannerImage,
        },
        {
            value: 2,
            label: "REVOLVER",
            src: leftRevolverImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SMOKE",
            src: rearSmokeImage,
            backSrc: rearSmokeBackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "HEAD - ARTHUR",
            frontSrc: glowHeadImage,
        },
        {
            value: 1,
            label: "BODY",
            src: glowBodyImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
