import React, { useContext } from "react";
import styled from "styled-components";

import { UserAuthContext } from "./UserAuthContextWrapper";
import { ShowLandingContext } from "./../Landing/ShowLandingContextWrapper";
import { CurrentThemeContext } from "./../common/ThemeContextWrapper";
import SpriteImage, { SPRITE_KEYS } from "../common/styles/SpriteImage";

const ButtonContainer = styled.div`
    align-content: space-around;
    border-width: 4px 0 4px 4px;
    color: #FFF;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-weight: bold;
    gap: 10px;
    padding: 5px 0;
    justify-content: space-evenly;
    width: 235px;

    margin-top: ${props => props.showLanding ? "-310px" : "6px"};
    transition-delay: ${props => props.theme.landingTransitionDuration}s;
    transition-duration: 0.75s;
    transition-property: margin-top;
    transition-timing-function: linear;

    &.fantasy {
        background: linear-gradient(to bottom right, #666 0%, #333 100%);
        box-shadow: 0 0 2px 1px #000;
        border-color: #5A5A5A;
        border-style: outset;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }

    &.mech {
        background: #666;
        border-color: transparent;
        border-style: solid;
        // Clip Bottom Left Corner
        clip-path: polygon(0% 0%,100% 0%,100% 100%,20px 100%,0% calc(100% - 20px));
    }

    h3 {
        margin: 0;
    }
`;

ButtonContainer.displayName = "ButtonContainer";

const UserEmail = styled.h3`
    overflow-wrap: break-all;
    word-wrap: break-all;
    word-break: break-all;
    hyphens: auto;
`;

UserEmail.displayName = "UserEmail";

const LogoutButton = styled.div`
    cursor: pointer;
    display: inline-block;
    height: 28px;
    width: 190px;
    padding-top: 5px;

    border-color: ${props => props.theme.categoryPrimaryColor};
    border-width: 4px;
    border-style: outset;
    border-radius: 10px;

    :active {
        border-style: inset;
    }

    &.fantasy {
        background-image: ${props => `linear-gradient(to bottom right, ${props.theme.categoryPrimaryColor} 0%, ${props.theme.categorySecondaryColor} 100%)`};
    }

    &.mech {
        background-image: ${props => `linear-gradient(to bottom right, ${props.theme.categoryPrimaryColor} 0%, ${props.theme.categoryPrimaryColor} 100%)`};
        border-color: ${props => props.theme.categoryPrimaryColor};
        border-style: solid;
        border-radius: 0;
        //Clip Top Left & Bottom Right Corner
        clip-path: polygon(10px 0%,100% 0%,100% calc(100% - 10px),calc(100% - 10px) 100%,0% 100%,0% 10px);
    }

    > div {
        margin: auto;
    }
`;

LogoutButton.displayName = "LogoutButton";

function UserMenu() {
    const { user, setUser } = useContext(UserAuthContext);
    const { showLanding } = useContext(ShowLandingContext);
    const { currentSide } = useContext(CurrentThemeContext);

    function logout() {
        document.cookie = "refreshToken=false; Secure; expires=Thu, 01 Jan 1970 00:00:01 GMT;";

        return setUser(null);
    }

    return (
        <ButtonContainer className={currentSide} showLanding={showLanding}>
            <UserEmail>{user.user.email}</UserEmail>
            <LogoutButton className={currentSide} onClick={logout}>
                <SpriteImage className={SPRITE_KEYS.patreonLogoutWhite} />
            </LogoutButton>
        </ButtonContainer>
    );
}

export default UserMenu;
