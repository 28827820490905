import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Dawnbreaker`;

// CHASSIS
const chassisDawnbreakerImage = `${imagePath}/Chassis.png`;

// BAYS
const bayMainClosedImage = `${imagePath}/bays/Main Bay Closed.png`;
const bayMainOpenImage = `${imagePath}/bays/Main Bay Open.png`;
const bayWeaponClosedImage = `${imagePath}/bays/Weapon Bays Closed.png`;
const bayWeaponOpenImage = `${imagePath}/bays/Weapon Bays Open.png`;

// ACCESSORIES
const accessoryDronesImage = `${imagePath}/accessory/Drone Fighters.png`;
const weaponMissilesImage = `${imagePath}/weapons/Weapons - Missiles.png`;

// GLOWS
const glowIntakeImage = `${imagePath}/glows/Glow - Intake.png`;
const glowDronesImage = `${imagePath}/glows/Glow - Drones.png`;
const glowLaserVolleyChargeImage = `${imagePath}/weapons/Weapons - Laser Volley Charging.png`;
const glowLaserVolleyFiringImage = `${imagePath}/weapons/Weapons - Laser Volley Firing.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const shadowDetails = {
    size: shadowTypes.NONE,
    bottom: "0px",
    right: "0px",
};

const originalArtCredit = {
    prefixText: "Commissioned by ",
    title: "CaptainAnonymous",
    url: "https://discord.gg/JUF47G5",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    isMegadeusPlus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "1",
        "rearMountValue": "1",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CLOSED",
            src: bayMainClosedImage,
        },
        {
            value: 1,
            label: "OPEN",
            src: bayMainOpenImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DAWNBREAKER",
            src: chassisDawnbreakerImage,
            shadowDetails,
            originalArtCredit,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "MISSILES",
                src: weaponMissilesImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DRONE FIGHTERS",
            src: accessoryDronesImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CLOSED",
            frontSrc: bayWeaponClosedImage,
        },
        {
            value: 1,
            label: "OPEN",
            frontSrc: bayWeaponOpenImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "INTAKE",
            frontSrc: glowIntakeImage,
        },
        {
            value: 1,
            label: "DRONE FIGHTERS",
            frontSrc: glowDronesImage,
        },
        {
            value: 2,
            label: "LASER VOLLEY - CHARGE",
            src: glowLaserVolleyChargeImage,
        },
        {
            value: 3,
            label: "LASER VOLLEY - FIRING",
            src: glowLaserVolleyFiringImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "MAIN BAY",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON BAY",
            value: "rearMount",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "GUN GLOW",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "MAIN BAY",
        "chassis": "CHASSIS",
        "rearMount": "WEAPON BAY",
        "weapon": "MISSILES",
        "secondaryWeapon": "DRONES",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
