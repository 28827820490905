import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Marquis`;

// BODY
const bodyImage = `${imagePath}/Chassis.png`;

// HEADS
const headMarquisImage = `${imagePath}/heads/Head - Marquis.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackSpotImage = `${imagePath}/heads/Head - Black Spot.png`;
const headBlackbeardImage = `${imagePath}/heads/Head - Blackbeard.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headFactoryStandardImage = `${imagePath}/heads/Head - Factory Standard.png`;
const headFrontlineImage = `${imagePath}/heads/Head - Frontline.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headPaladinImage = `${imagePath}/heads/Head - Paladin.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headScopedogImage = `${imagePath}/heads/Head - Scopedog.png`;
const headStampedeImage = `${imagePath}/heads/Head - Stampede.png`;
const headStortebekerImage = `${imagePath}/heads/Head - Stortebeker.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
const weaponsATRifleImage = `${imagePath}/weapons/Weapons - AT Rifle.png`;
const weaponsBladePistolsImage = `${imagePath}/weapons/Weapons - Blade Pistols.png`;
const weaponsBowImage = `${imagePath}/weapons/Weapons - Bow.png`;
const weaponsDaggersImage = `${imagePath}/weapons/Weapons - Daggers.png`;
const weaponsPistolsImage = `${imagePath}/weapons/Weapons - Pistols.png`;
const weaponsSpearImage = `${imagePath}/weapons/Weapons - Spear.png`;
const weaponsSwordsImage = `${imagePath}/weapons/Weapons - Swords.png`;

// WEAPON GLOWS
const weaponGlowBowImage = `${imagePath}/glows/Glows - Bow.png`;
const weaponGlowSpearImage = `${imagePath}/glows/Glows - Spear.png`;
const weaponGlowSpearArcsImage = `${imagePath}/glows/Glows - Spear Arcs.png`;
const weaponGlowSwordsImage = `${imagePath}/glows/Glows - Swords.png`;

// REAR MOUNT
const rearJetpackImage = `${imagePath}/accessories/Accessories Under - Jetpack.png`;

// ACCESSORIES
const accessoryOverCloakImage = `${imagePath}/accessories/Accessories Over - Cloak.png`;
const accessoryUnderCloakImage = `${imagePath}/accessories/Accessories Under - Cloak.png`;
const accessoryOverKatanaSheathsImage = `${imagePath}/accessories/Accessories Over - Katana Sheaths.png`;
const accessoryUnderKatanaSheathImage = `${imagePath}/accessories/Accessories Under - Katana Sheath.png`;
const accessoryOverPistolHolstersImage = `${imagePath}/accessories/Accessories Over - Pistol Holsters.png`;
const accessoryUnderPistolHolstersImage = `${imagePath}/accessories/Accessories Under - Pistol Holsters.png`;
const accessoryOverQuiversImage = `${imagePath}/accessories/Accessories Over - Quivers.png`;
const accessoryUnderQuiversImage = `${imagePath}/accessories/Accessories Under - Quivers.png`;
const accessoryOverScarfImage = `${imagePath}/accessories/Accessories Over - Scarf.png`;
const accessoryOverPilotImage = `${imagePath}/accessories/Accessories Over - Pilot.png`;


// GLOWS
const glowUnderJetpackImage = `${imagePath}/glows/Accessories Under - Jetpack Glow.png`;
const glowOverJetpackImage = `${imagePath}/glows/Glows - Jetpack Over.png`;
const glowOverEyeImage = `${imagePath}/glows/Glows - Eye.png`;

const previewImg = `${imagePath}/preview.png`;
const restrictedPreviewImg = `${imagePath}/preview-restricted.png`;


const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "9px",
    right: "-17px",
};

export default {
    previewImg,
    restrictedPreviewImg,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "0",
        "accessory1Value": "4",
        "accessory2Value": "5",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "MARQUIS",
            src: headMarquisImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackSpotImage,
        },
        {
            value: 3,
            label: "BLACKBEARD",
            src: headBlackbeardImage,
        },
        {
            value: 4,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
        },
        {
            value: 7,
            label: "FRONTLINE",
            src: headFrontlineImage,
        },
        {
            value: 8,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 9,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 10,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 11,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 12,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 13,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 14,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 15,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 16,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 17,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 18,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 19,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 20,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 21,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 22,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 23,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 24,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 25,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 26,
            label: "STAMPEDE",
            src: headStampedeImage,
        },
        {
            value: 27,
            label: "STORTEBEKER",
            src: headStortebekerImage,
        },
        {
            value: 28,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MARQUIS",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Commissioned by Battle Pope. Original art by `,
                title: "@EMFields_Art",
                url: "https://twitter.com/EMFields_Art/status/1500614356769419264",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AT RIFLE",
                src: weaponsATRifleImage,
            },
            {
                value: 1,
                label: "BLADE PISTOLS",
                src: weaponsBladePistolsImage,
            },
            {
                value: 2,
                label: "BOW",
                src: weaponsBowImage,
            },
            {
                value: 3,
                label: "DAGGERS",
                src: weaponsDaggersImage,
            },
            {
                value: 4,
                label: "PISTOLS",
                src: weaponsPistolsImage,
            },
            {
                value: 5,
                label: "SPEAR",
                src: weaponsSpearImage,
            },
            {
                value: 6,
                label: "SWORDS",
                src: weaponsSwordsImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BOW",
            frontSrc: weaponGlowBowImage,
        },
        {
            value: 1,
            label: "SPEAR",
            frontSrc: weaponGlowSpearImage,
        },
        {
            value: 2,
            label: "SPEAR + ARCS",
            frontSrc: weaponGlowSpearArcsImage,
        },
        {
            value: 3,
            label: "SWORDS",
            frontSrc: weaponGlowSwordsImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "JETPACK",
            src: rearJetpackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CLOAK",
            src: accessoryOverCloakImage,
            backSrc: accessoryUnderCloakImage,
        },
        {
            value: 1,
            label: "KATANA SHEATHS",
            src: accessoryOverKatanaSheathsImage,
            backSrc: accessoryUnderKatanaSheathImage,
        },
        {
            value: 2,
            label: "PILOT",
            foremostSrc: accessoryOverPilotImage,
        },
        {
            value: 3,
            label: "PISTOL HOLSTERS",
            src: accessoryOverPistolHolstersImage,
            backSrc: accessoryUnderPistolHolstersImage,
        },
        {
            value: 4,
            label: "QUIVERS",
            src: accessoryOverQuiversImage,
            backSrc: accessoryUnderQuiversImage,
        },
        {
            value: 5,
            label: "SCARF",
            src: accessoryOverScarfImage,
        },
        {
            value: 6,
            label: "GLOW - EYE",
            frontSrc: glowOverEyeImage,
        },
        {
            value: 7,
            label: "GLOW - JETPACK",
            src: glowOverJetpackImage,
            backSrc: glowUnderJetpackImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
