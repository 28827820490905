import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { ShowLandingContext } from "../Landing/ShowLandingContextWrapper";
import { CurrentThemeContext } from "../common/ThemeContextWrapper";
import { HalfWrapper, WholeWrapper } from "./../common/ToggleHideUIContextWrapper";
import { UITextButton } from "./../common/styles/UITextButton";
import { getRandomIntInclusive } from "../Unit/Category/Mechs/MechCustomCategory";
import BaseURL from "./../Unit/data/images/Hosting-BaseURL";

const introMusicFile = `${BaseURL}/music/TFX - Stinger.mp3`;
const introMusicMechFile = `${BaseURL}/music/TFX - Stinger Mech.mp3`;

export const MusicWrapper = styled.div`
    /* width: 100px; */
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-content: flex-start;
    align-items: center;
    /* display: ${props => props.isMinimized ? "none" : "inherit"}; */
`;

MusicWrapper.displayName = "MusicWrapper";

export const ToggleMusicButton = styled.img`
    cursor: pointer;
    /* right: 40px;
    bottom: 25px;
    position: fixed; */
    /* transition-property: right;
    transition-delay: ${props => props.theme.landingTransitionDuration}s;
    transition-duration: 0.75s;
    transition-timing-function: linear; */

    z-index: 10;

    :active {
        transform: translateY(2px);
    }

    @media print {
        display: none;
    }
`;

ToggleMusicButton.displayName = "ToggleMusicButton";

export const InputRange = styled.input`
    cursor: pointer;
    /* right: 30px;
    bottom: 80px;
    position: fixed; */
    /* display: ${props => props.isMuted ? "hidden" : "inherit"}; */

    z-index: 10;

    @media print {
        display: none;
    }

    /* RESET STYLING */
    &[type=range] {
        width: 80px; /* Specific width is required for Firefox. */
        background: transparent; /* Otherwise white in Chrome */
    }

    &[type=range]::-webkit-slider-thumb {
        /* -webkit-appearance: none; */
    }

    &[type=range]:focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }
    /* RESET STYLING */
`;

InputRange.displayName = "InputRange";

export const MusicInfo = styled.a`
    cursor: pointer;
    /* right: 0;
    bottom: 45px;
    position: fixed;
    width: 140px; */
    z-index: 10;
    margin-left: 10px;

    font-weight: bold;
    text-align: right;
    color: #FFF;
    -webkit-text-stroke: 0.5px #000;

    @media print {
        display: none;
    }
`;

MusicInfo.displayName = "MusicInfo";

const musicIsMutedCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`musicIsMuted=`));
const musicVolumeCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`musicVolume=`));

const defaultVolume = musicVolumeCookie.length ? (musicVolumeCookie[0].split("=")[1] / 100) : 0.2;

const introMusicDetails = {
    url: "http://tfx.seawavescollective.net/",
    title: "tfx - Stinger",
};

const mechThemeIndex = getRandomIntInclusive(7, 10);

const bgmArray = [
    {
        url: "http://tfx.seawavescollective.net/",
        title: "tfx - Spinner",
        file: `${BaseURL}/music/TFX - Spinner (192kbps) - final.mp3`,
    },
    {
        url: "http://tfx.seawavescollective.net/",
        title: "tfx - Pirouette",
        file: `${BaseURL}/music/TFX - Rotate.mp3`,
    },
    {
        url: "http://tfx.seawavescollective.net/",
        title: "tfx - Rotate",
        file: `${BaseURL}/music/TFX - Quaternion.mp3`,
    },
    {
        url: "http://tfx.seawavescollective.net/",
        title: "tfx - Quaternion",
        file: `${BaseURL}/music/Saad - Parabellum.mp3`,
    },
    {
        url: "https://soundcloud.com/saadali",
        title: "Saad - Parabellum",
        file: `${BaseURL}/music/Xtract - We Build.mp3`,
    },
    {
        url: "https://xtractmusic.bandcamp.com/music",
        title: "Xtract - We Build",
        file: `${BaseURL}/music/TFX - Pirouette.mp3`,
    },
    {
        url: "http://tfx.seawavescollective.net/",
        title: "tfx - Eulerskank",
        file: `${BaseURL}/music/TFX - Eulerskank.mp3`,
    },
    {
        url: "http://tfx.seawavescollective.net/",
        title: "tfx - Whirl",
        file: `${BaseURL}/music/TFX - Whirl.mp3`,
    },
    {
        url: "http://www.magicksquares.com/gate/",
        title: "alka - the circle of eyes",
        file: `${BaseURL}/music/alka - the circle of eyes.wav`,
    },
    {
        url: "http://www.magicksquares.com/gate/",
        title: "alka - ガブリエル (gabriel)",
        file: `${BaseURL}/music/alka - ガブリエル (gabriel).wav`,
    },
    {
        url: "http://www.magicksquares.com/gate/",
        title: "alka - 天使",
        file: `${BaseURL}/music/alka - 天使.wav`,
    },
];

function getThemeMusicTrackIndex(themeValue) {
    let returnTrackIndex;

    switch (themeValue) {
        case "spring":
            returnTrackIndex = 7;
            break;

        case "summer":
            returnTrackIndex = 0;
            break;

        case "autumn":
            returnTrackIndex = 1;
            break;

        case "winter":
            returnTrackIndex = 2;
            break;

        case "mech":
            returnTrackIndex = mechThemeIndex;
            break;

        default:
            returnTrackIndex = getRandomIntInclusive(0, bgmArray.length - 1);
            break;
    }

    return returnTrackIndex;
}

function MusicController({ className }) {
    const [isMuted, setIsMuted] = useState(musicIsMutedCookie.length && musicIsMutedCookie[0].split("=")[1] === "true" ? true : false);

    const [backgroundMusic] = useState(bgmArray);
    const { currentTheme } = useContext(CurrentThemeContext);
    const { showLanding, startWithLanding } = useContext(ShowLandingContext);
    const [introMusicHasPlayed, setIntroMusicHasPlayed] = useState(false);
    const [volumeValue, setVolumeValue] = useState(defaultVolume * 100);
    const [introMusic] = useState(new Audio(currentTheme === "mech" || currentTheme === "lvoid" || currentTheme === "dvoid" ? introMusicMechFile : introMusicFile));
    const [backgroundAudioRef] = useState(new Audio());

    const musicStartIndex = getThemeMusicTrackIndex(currentTheme);

    const [currentTrackIndex, setCurrentTrackIndex] = useState(musicStartIndex);

    const playIntroMusic = useCallback(() => {
        if (introMusic.readyState !== 0) {
            introMusic.pause();
            introMusic.currentTime = 0;
        }

        introMusic.play();
    }, [introMusic]);

    const playBackgroundMusic = useCallback((trackIndex) => {
        backgroundAudioRef.pause();

        if (backgroundAudioRef.readyState !== 0) {
            backgroundAudioRef.currentTime = 0;
        }

        backgroundAudioRef.src = backgroundMusic[trackIndex].file;
        backgroundAudioRef.load();
        backgroundAudioRef.play();

        return setCurrentTrackIndex(trackIndex);
    }, [backgroundMusic, currentTrackIndex]);

    useEffect(() => {
        introMusic.volume = volumeValue / 100;
        introMusic.loop = false;
        introMusic.muted = false;

        introMusic.addEventListener("ended", () => {
            setIntroMusicHasPlayed(true);
            playBackgroundMusic(musicStartIndex);
        });

        backgroundAudioRef.volume = volumeValue / 100;
        backgroundAudioRef.loop = true;
    }, [introMusic, backgroundMusic, playBackgroundMusic, volumeValue]);

    useEffect(() => {
        backgroundAudioRef.pause();
        backgroundAudioRef.currentTime = 0;
        playBackgroundMusic(getThemeMusicTrackIndex(currentTheme));
    }, [currentTheme]);

    useEffect(() => {
        if (!showLanding && !introMusicHasPlayed && startWithLanding) {
            playIntroMusic();
        } else if (!startWithLanding) {
            setIntroMusicHasPlayed(true);
        }
    }, [showLanding]);

    useEffect(() => {
        introMusic.muted = isMuted;
        backgroundAudioRef.muted = isMuted;
    }, [isMuted, backgroundMusic, introMusic]);

    function handleVolumeChange(event) {
        setVolumeValue(event.target.value);
        document.cookie = `musicVolume=${event.target.value}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;

        introMusic.volume = event.target.value / 100;
        backgroundAudioRef.volume = event.target.value / 100;
    }

    const musicOptions = bgmArray.map((musicOption, index) => (
        <option
            key={musicOption.title}
            value={index}
        >
            {musicOption.title}
        </option>
    ));

    return (
        <MusicWrapper>
            <HalfWrapper>
                <UITextButton
                    className={className}
                    onClick={() => {
                        setIsMuted(!isMuted);

                        document.cookie = `musicIsMuted=${!isMuted}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
                    }}
                    title="Toggle Music"
                >
                    BGM: {isMuted ? "OFF" : "ON"}
                </UITextButton>
            </HalfWrapper>

            <HalfWrapper>
                <InputRange
                    id="musicVolume"
                    type="range"
                    min="0"
                    max="40"
                    value={volumeValue}
                    onChange={handleVolumeChange}
                    isMuted={isMuted}
                />
            </HalfWrapper>

            <br/>

            <WholeWrapper>
                <select
                    id="music-select"
                    name="music"
                    onChange={(event) => {
                        return playBackgroundMusic(event.target.value)
                    }}
                    value={currentTrackIndex}
                >
                    {musicOptions}
                </select>

                <MusicInfo
                    href={introMusicHasPlayed ? backgroundMusic[currentTrackIndex].url : introMusicDetails.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {"🔗"}
                </MusicInfo>
            </WholeWrapper>
        </MusicWrapper>
    );
}

export default React.memo(MusicController);
