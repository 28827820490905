import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/Fourtuga`;

// BODY
const bodyFourtugaImage = `${imagePath}/Chassis.png`;

// LEGS
const legsFeetImage = `${imagePath}/legs/Legs - Feet.png`;
const legsWheelsImage = `${imagePath}/legs/Legs - Wheels.png`;
const legsArmoredWheelsImage = `${imagePath}/legs/Legs - Armored Wheels.png`;

// WEAPONS
// LEFT
const weaponLeftAutoshotgunImage = `${imagePath}/weapons/left/Left Weapon - Autoshotgun.png`;
const weaponLeftHackingSuiteImage = `${imagePath}/weapons/left/Left Weapon - Hacking Suite.png`;
const weaponLeftMinigunImage = `${imagePath}/weapons/left/Left Weapon - Minigun.png`;
const weaponLeftMissilesImage = `${imagePath}/weapons/left/Left Weapon - Missiles.png`;

// RIGHT
const weaponRightAutoshotgunImage = `${imagePath}/weapons/right/Right Weapons - Autoshotun.png`;
const weaponRightHackingSuiteImage = `${imagePath}/weapons/right/Right Weapons - Hacking Suite.png`;
const weaponRightMinigunImage = `${imagePath}/weapons/right/Right Weapons - Minigun.png`;
const weaponRightMissilesImage = `${imagePath}/weapons/right/Right Weapons - Missiles.png`;

// CENTER
const weaponCenterDaisyCutterImage = `${imagePath}/weapons/center/Central Weapon - Daisy Cutter.png`;
const weaponCenterMediumCannonImage = `${imagePath}/weapons/center/Central Weapon - Medium Cannon.png`;
const weaponCenterHeavyCannonImage = `${imagePath}/weapons/center/Central Weapon - Heavy Cannon Cannon.png`;

// ACCESSORIES
const accessoryWeaponShieldingImage = `${imagePath}/accessories/Accessories - Weapon Shielding.png`;
const accessoryWeaponShieldingBackImage = `${imagePath}/accessories/Accessories - Weapon Shielding back.png`;
const accessoryArmsImage = `${imagePath}/accessories/Accessories - Arms.png`;
const accessoryHackingDishImage = `${imagePath}/accessories/Accessory - Central hacking dish.png`;

// GLOWS
const glowEyesImage = `${imagePath}/glows/Top Glow - Eyes.png`;
const glowHackingLeftImage = `${imagePath}/glows/Top Glow - Left Hack.png`;
const glowHackingRightImage = `${imagePath}/glows/Top Glow - Right Hack.png`;
const glowHyperdenseHalfImage = `${imagePath}/glows/Mid Glow - Hyperdense Half.png`;
const glowHyperdenseFullImage = `${imagePath}/glows/Mid Glow - Hyperdense Full.png`;


const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const quadLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "69px",
    right: "5px",
};

const wheelLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "62px",
    right: "12px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    rearMountsAreLegs: true,
    removeNone: {
        rearMount: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "7",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DAISY CUTTER",
            src: weaponCenterDaisyCutterImage,
        },
        {
            value: 1,
            label: "MEDIUM CANNON",
            src: weaponCenterMediumCannonImage,
        },
        {
            value: 2,
            label: "HEAVY CANNON",
            src: weaponCenterHeavyCannonImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "FOURTUGA",
            src: bodyFourtugaImage,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "XTopherVersion2",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AUTOSHOTGUN",
                backSrc: weaponLeftAutoshotgunImage,
            },
            {
                value: 1,
                label: "HACKING SUITE",
                backSrc: weaponLeftHackingSuiteImage,
            },
            {
                value: 2,
                label: "MINIGUN",
                backSrc: weaponLeftMinigunImage,
            },
            {
                value: 3,
                label: "MISSILES",
                backSrc: weaponLeftMissilesImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "AUTOSHOTGUN",
            src: weaponRightAutoshotgunImage,
        },
        {
            value: 1,
            label: "HACKING SUITE",
            src: weaponRightHackingSuiteImage,
        },
        {
            value: 2,
            label: "MINIGUN",
            src: weaponRightMinigunImage,
        },
        {
            value: 3,
            label: "MISSILES",
            src: weaponRightMissilesImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "FEET",
            frontSrc: legsFeetImage,
            shadowDetails: quadLegsShadow,
        },
        {
            value: 1,
            label: "WHEELS",
            frontSrc: legsWheelsImage,
            shadowDetails: wheelLegsShadow,
        },
        {
            value: 2,
            label: "ARMORED WHEELS",
            frontSrc: legsArmoredWheelsImage,
            shadowDetails: wheelLegsShadow,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "EYES - GLOW",
            src: glowEyesImage,
        },
        {
            value: 1,
            label: "HACKING - LEFT - GLOW",
            src: glowHackingLeftImage,
        },
        {
            value: 2,
            label: "HACKING - RIGHT - GLOW",
            frontSrc: glowHackingRightImage,
        },
        {
            value: 3,
            label: "HACKING - ALL - GLOW",
            src: glowHackingLeftImage,
            frontSrc: glowHackingRightImage,
        },
        {
            value: 4,
            label: "HYPERDENSE - HALF - GLOW",
            src: glowHyperdenseHalfImage,
        },
        {
            value: 5,
            label: "HYPERDENSE - FULL - GLOW",
            src: glowHyperdenseFullImage,
        },
        {
            value: 6,
            label: "ARMS",
            frontSrc: accessoryArmsImage,
        },
        {
            value: 7,
            label: "WEAPON SHIELDING",
            frontSrc: accessoryWeaponShieldingImage,
            backSrc: accessoryWeaponShieldingBackImage,
        },
        {
            value: 8,
            label: "HACKING DISH",
            frontSrc: accessoryHackingDishImage,
        },

    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEGS",
            value: "rearMount",
        },
        {
            label: "CENTER WEP",
            value: "head",
        },
        {
            label: "LEFT WEP",
            value: "weapon",
        },
        {
            label: "RIGHT WEP",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "CENTER WEP",
        "chassis": "CHASSIS",
        "rearMount": "LEGS",
        "weapon": "LEFT WEP",
        "secondaryWeapon": "RIGHT WEP",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
