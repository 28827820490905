import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Kidd`;

// LEGS
const legsKiddImage = `${imagePath}/legs/Legs - Kidd.png`;
// PREMIUM
const legsTranquilStrollImage = `${imagePath}/legs/premium/Legs - Tranquil Stroll.png`;
const legsLankyWheelsImage = `${imagePath}/legs/premium/Legs - Lanky Wheels.png`;
const legsOffroadWheelsImage = `${imagePath}/legs/premium/Legs - Offroad Wheels.png`;
const legsHoverImage = `${imagePath}/legs/premium/Legs - Hover.png`;

// FRONT
const frontKiddImage = `${imagePath}/heads/Front - Kidd.png`;
// PREMIUM
const frontLancasterImage = `${imagePath}/heads/premium/Front - Lancaster.png`;
const frontTranquilStrollImage = `${imagePath}/heads/premium/Front - Tranquil Stroll.png`;
const frontCalicoImage = `${imagePath}/heads/premium/Front - Calico.png`;

// REAR
const rearKiddSubalternsImage = `${imagePath}/rear mounts/Rear - Kidd Subalterns.png`;
const rearSmokestacksImage = `${imagePath}/rear mounts/Rear - Smokestacks.png`;
const rearSmokestacksSubaltsDeployImage = `${imagePath}/rear mounts/Rear - Smokestacks Subalts.png`;
// LEGENDARY
const rearContainerImage = `${imagePath}/rear mounts/legendary/Rear - Container.png`;
const rearContainerDeployImage = `${imagePath}/rear mounts/legendary/Rear - Container Deploying.png`;
const rearFlatbedImage = `${imagePath}/rear mounts/legendary/Rear - Flatbed.png`;
const rearFlatbedLoadedImage = `${imagePath}/rear mounts/legendary/Rear - Flatbed Loaded.png`;
const rearRoundSubaltsImage = `${imagePath}/rear mounts/legendary/Rear - Round Subalts.png`;
const rearRoundSubaltsDeployImage = `${imagePath}/rear mounts/legendary/Rear - Round Subalts Deployed.png`;
const rearRepairbotSubaltsImage = `${imagePath}/rear mounts/legendary/Rear - Repairbot Subalts.png`;
const rearRepairbotSubaltsDeployImage = `${imagePath}/rear mounts/legendary/Rear - Repairbot Subalts Deployed.png`;

// WEAPONS
const weaponLeftARImage = `${imagePath}/weapons/Weapons Left - Assault Rifle.png`;
const weaponLeftBlackspotImage = `${imagePath}/weapons/Weapons Left - Blackspot.png`;
const weaponLeftNexusImage = `${imagePath}/weapons/Weapons Left - Nexus.png`;
const weaponLeftShieldImage = `${imagePath}/weapons/Weapons Left - Shield.png`;
const weaponLeftShieldBlackspotImage = `${imagePath}/weapons/Weapons Left - Shield Black Spot.png`;
const weaponRightARImage = `${imagePath}/weapons/Weapons Right - Assault Rifle.png`;
const weaponRightNexusImage = `${imagePath}/weapons/Weapons Right - Nexus.png`;
const weaponRightShieldImage = `${imagePath}/weapons/Weapons Right - Shield.png`;

// GLOW
const glowBlackSpotImage = `${imagePath}/glows/Glow - Black Spot.png`;
const glowSmokestacksImage = `${imagePath}/glows/Glow - Smokestacks.png`;
// PREMIUM
const glowHoverImage = `${imagePath}/glows/premium/Glow - Hover Rings.png`;

// ACCESSORY
const accChassisDetailFrontImage = `${imagePath}/accessories/chassis-KIDD-mid6.png`;
const accChassisDetailLegsImage = `${imagePath}/accessories/chassis-KIDD-mid7.png`;
const accChassisDetailRearSImage = `${imagePath}/accessories/chassis-KIDD-S.png`;
const accChassisDetailRearSSWithSImage = `${imagePath}/accessories/chassis-KIDD-SSwithS.png`;
const accChassisDetailRearSSNoSImage = `${imagePath}/accessories/chassis-KIDD-SSnoS.png`;

const coreImage = `${imagePath}/Kidd (by Seamless).png`;
const core2Image = `${imagePath}/Kidd (by Corvon).png`;

const previewImage = `${imagePath}/preview.png`;

const kiddLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "78px",
    right: "68px",
};

const tranquilLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "78px",
    right: "62px",
};

const wheelLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "92px",
    right: "51px",
};

const offroadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "88px",
    right: "57px",
};

const hoverLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "44px",
    right: "16px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    accessory2IsChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "4",
        "secondaryWeaponValue": "2",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "KIDD",
            frontSrc: frontKiddImage,
        },
        {
            value: 1,
            label: "LANCASTER",
            foremostSrc: frontLancasterImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "TRANQUIL STROLL",
            foremostSrc: frontTranquilStrollImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CALICO",
            foremostSrc: frontCalicoImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "KIDD",
            src: legsKiddImage,
            shadowDetails: kiddLegsShadow,
        },
        {
            value: 1,
            label: "TRANQUIL STROLL",
            src: legsTranquilStrollImage,
            shadowDetails: tranquilLegsShadow,
            isPremium: true,
        },
        {
            value: 2,
            label: "LANKY WHEELS",
            src: legsLankyWheelsImage,
            shadowDetails: wheelLegsShadow,
            isPremium: true,
        },
        {
            value: 3,
            label: "OFFROAD WHEELS",
            src: legsOffroadWheelsImage,
            shadowDetails: offroadLegsShadow,
            isPremium: true,
        },
        {
            value: 4,
            label: "HOVER",
            src: legsHoverImage,
            shadowDetails: hoverLegsShadow,
            isPremium: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AR",
                backSrc: weaponLeftARImage,
            },
            {
                value: 1,
                label: "NEXUS",
                backSrc: weaponLeftNexusImage,
            },
            {
                value: 2,
                label: "SHIELD",
                backSrc: weaponLeftShieldImage,
            },
            {
                value: 3,
                label: "BLACKSPOT",
                backSrc: weaponLeftBlackspotImage,
            },
            {
                value: 4,
                label: "SHIELD + BLACKSPOT",
                backSrc: weaponLeftShieldBlackspotImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "AR",
            foremostSrc: weaponRightARImage,
        },
        {
            value: 1,
            label: "NEXUS",
            foremostSrc: weaponRightNexusImage,
        },
        {
            value: 2,
            label: "SHIELD",
            foremostSrc: weaponRightShieldImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "KIDD WARCHILD",
            frontSrc: rearKiddSubalternsImage,
        },
        {
            value: 1,
            label: "SMOKESTACKS",
            frontSrc: rearSmokestacksImage,
        },
        {
            value: 2,
            label: "SMOKESTACKS + WARCHILD",
            frontSrc: rearSmokestacksSubaltsDeployImage,
        },
        {
            value: 3,
            label: "MARAUDER",
            frontSrc: rearContainerImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "MARAUDER DEPLOYING",
            frontSrc: rearContainerDeployImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "GRAPESHOT SUBALTERNS",
            frontSrc: rearRoundSubaltsImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "GRAPESHOT SUBALTERNS DEPLOYED",
            frontSrc: rearRoundSubaltsDeployImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "REPAIRBOT SUBALTERNS",
            frontSrc: rearRepairbotSubaltsImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "REPAIRBOT SUBALTERNS DEPLOYED",
            frontSrc: rearRepairbotSubaltsDeployImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 9,
            label: "FLATBED",
            frontSrc: rearFlatbedImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 10,
            label: "FLATBED LOADED",
            frontSrc: rearFlatbedLoadedImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BLACK SPOT",
            frontSrc: glowBlackSpotImage,
        },
        {
            value: 1,
            label: "SMOKESTACKS",
            frontSrc: glowSmokestacksImage,
        },
        {
            value: 2,
            label: "SMOKESTACKS + BLACK SPOT",
            frontSrc: glowSmokestacksImage,
            foremostSrc: glowBlackSpotImage,
        },
        {
            value: 3,
            label: "COLOR - CHASSIS DETAILS - KIDD FRONT",
            frontSrc: accChassisDetailFrontImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "COLOR - CHASSIS DETAILS - KIDD LEGS",
            src: accChassisDetailLegsImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "COLOR - CHASSIS DETAILS - REAR - WARCHILD",
            src: accChassisDetailRearSImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 6,
            label: "COLOR - CHASSIS DETAILS - REAR - SMOKESTACKS",
            src: accChassisDetailRearSSNoSImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 7,
            label: "COLOR - CHASSIS DETAILS - REAR - SMOKESTACKS + WARCHILD",
            src: accChassisDetailRearSSWithSImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 8,
            label: "HOVER RINGS",
            src: glowHoverImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HOVER RINGS + SMOKESTACKS",
            frontSrc: glowSmokestacksImage,
            src: glowHoverImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "HOVER RINGS + BLACK SPOT",
            frontSrc: glowBlackSpotImage,
            src: glowHoverImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "HOVER RINGS + SMOKESTACKS + BLACK SPOT",
            frontSrc: glowSmokestacksImage,
            foremostSrc: glowBlackSpotImage,
            src: glowHoverImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "FRONT",
            value: "head",
        },
        {
            label: "REAR",
            value: "rearMount",
        },
        {
            label: "LEGS",
            value: "chassis",
        },
        {
            label: "WEAPON LEFT",
            value: "weapon",
        },
        {
            label: "WEAPON RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "FRONT",
        "chassis": "LEGS",
        "rearMount": "REAR",
        "weapon": "WEAPON LEFT",
        "secondaryWeapon": "WEAPON RIGHT",
        "accessory1": "GLOW 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
