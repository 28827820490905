import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/DFG Gluttony`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis.png`;

// HEADS
const headOpenImage = `${imagePath}/heads/Mouth - Open.png`;
const headClosedImage = `${imagePath}/heads/Mouth - Closed.png`;
const headTaroImage = `${imagePath}/heads/Mouth - Taro.png`;

// WEAPONS
// MID
const armsMidHandsImage = `${imagePath}/arms/mid/Mid Arms - Hands.png`;
const armsMidGatlingCannonsImage = `${imagePath}/arms/mid/Mid Arms - Gatling Cannons.png`;
const armsMidTenderizersImage = `${imagePath}/arms/mid/Mid Arms - Tenderizers.png`;
const armsMidTenderizerLeftImage = `${imagePath}/arms/mid/Mid Arms - L Tenderizer.png`;
const armsMidTenderizerRightImage = `${imagePath}/arms/mid/Mid Arms - R Tenderizer.png`;
const armsMidForkKnifeImage = `${imagePath}/arms/mid/Mid Arms - Fork _ Knife.png`;

// TOP
// LEFT
const armsTopLeftHandImage = `${imagePath}/arms/top/Top Left Arm - Hand.png`;
const armsTopLeftAnnihilatorCannonImage = `${imagePath}/arms/top/Top Left Arm - Annihilator Cannon.png`;

// RIGHT
const armsTopRightHandImage = `${imagePath}/arms/top/Top Right Arm - Hand.png`;
const armsTopRightTenderizerImage = `${imagePath}/arms/top/Top Right Arm - Tenderizer.png`;

// GLOWS
const glowMawImage = `${imagePath}/glows/Glow - Maw.png`;
const glowMawEnergyImage = `${imagePath}/glows/Glow - Maw + Energy.png`;
const glowMawTongueImage = `${imagePath}/glows/Glow - Maw + Tongue.png`;
const glowMawTongueOverImage = `${imagePath}/glows/Glow - Maw + Tongue Over.png`;
const glowAnnihilatorImage = `${imagePath}/glows/Glow - Annihilator.png`;
const glowAnnihilatorArcsImage = `${imagePath}/glows/Glow - Annihilator + Arcs.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/DFG_GLUTTONY_core_AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "27px",
    right: "-16px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CLOSED",
            src: headClosedImage,
        },
        {
            value: 1,
            label: "OPEN",
            src: headOpenImage,
        },
        {
            value: 2,
            label: "TARO",
            src: headTaroImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GLUTTONY",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                src: armsTopLeftHandImage,
            },
            {
                value: 1,
                label: "ANNIHILATOR",
                src: armsTopLeftAnnihilatorCannonImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            frontSrc: armsTopRightHandImage,
        },
        {
            value: 1,
            label: "TENDERIZER",
            frontSrc: armsTopRightTenderizerImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: 'HANDS',
            foremostSrc: armsMidHandsImage,
        },
        {
            value: 1,
            label: 'GATLING CANNONS',
            foremostSrc: armsMidGatlingCannonsImage,
        },
        {
            value: 2,
            label: 'LEFT TENDERIZER',
            foremostSrc: armsMidTenderizerLeftImage,
        },
        {
            value: 3,
            label: 'RIGHT TENDERIZER',
            foremostSrc: armsMidTenderizerRightImage,
        },
        {
            value: 4,
            label: 'DUAL TENDERIZERS',
            foremostSrc: armsMidTenderizersImage,
        },
        {
            value: 5,
            label: 'FORK + KNIFE',
            foremostSrc: armsMidForkKnifeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "MAW",
            src: glowMawImage,
        },
        {
            value: 1,
            label: "MAW + ENERGY",
            src: glowMawEnergyImage,
        },
        {
            value: 2,
            label: "MAW + TONGUE",
            src: glowMawTongueImage,
            frontSrc: glowMawTongueOverImage,
        },
        {
            value: 3,
            label: "ANNIHILATOR",
            frontSrc: glowAnnihilatorImage,
        },
        {
            value: 4,
            label: "ANNIHILATOR + ARCS",
            frontSrc: glowAnnihilatorArcsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "MAW",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMS MID",
            value: "rearMount",
        },
        {
            label: "L ARMS TOP",
            value: "weapon",
        },
        {
            label: "R ARMS TOP",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "MAW",
        "chassis": "CHASSIS",
        "rearMount": "ARMS MID",
        "weapon": "L ARMS TOP",
        "secondaryWeapon": "R ARMS TOP",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
