import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// CENTER
const centerAMRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Anti-Materiel Rifle A.png`;
const centerAMRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Anti-Materiel Rifle B.png`;
const centerEnergyLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Energy Launcher A.png`;
const centerEnergyLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Energy Launcher B.png`;
const centerGrenadierImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Grenadier.png`;
const centerLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Launcher A.png`;
const centerLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Launcher B.png`;
const centerLMGImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - LMG.png`;
const centerRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Rifleman A.png`;
const centerRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Rifleman B.png`;
const centerRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Rifleman C.png`;
const centerRifleDImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/Center - Rifleman D.png`;

// EAST
const eastAMRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Anti-Materiel Rifle A.png`;
const eastAMRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Anti-Materiel Rifle B.png`;
const eastEnergyLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Energy Launcher A.png`;
const eastEnergyLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Energy Launcher B.png`;
const eastGrenadierImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Grenadier.png`;
const eastLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Launcher A.png`;
const eastLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Launcher B.png`;
const eastLMGImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - LMG.png`;
const eastRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Rifleman A.png`;
const eastRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Rifleman B.png`;
const eastRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Rifleman C.png`;
const eastRifleDImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/East - Rifleman D.png`;

// WEST
const westAMRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Anti-Materiel Rifle A.png`;
const westAMRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Anti-Materiel Rifle B.png`;
const westEnergyLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Energy Launcher A.png`;
const westEnergyLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Energy Launcher B.png`;
const westGrenadierImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Grenadier.png`;
const westLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Launcher A.png`;
const westLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Launcher B.png`;
const westLMGImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - LMG.png`;
const westRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Rifleman A.png`;
const westRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Rifleman B.png`;
const westRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Rifleman C.png`;
const westRifleDImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/West - Rifleman D.png`;

// NORTH
const northAMRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Anti-Materiel Rifle A.png`;
const northAMRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Anti-Materiel Rifle B.png`;
const northEnergyLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Energy Launcher A.png`;
const northEnergyLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Energy Launcher B.png`;
const northGrenadierImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Grenadier.png`;
const northLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Launcher A.png`;
const northLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Launcher B.png`;
const northLMGImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - LMG.png`;
const northRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Rifleman A.png`;
const northRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Rifleman B.png`;
const northRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Rifleman C.png`;
const northRifleDImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/North - Rifleman D.png`;

// SOUTH
const southAMRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Anti-Materiel Rifle A.png`;
const southAMRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Anti-Materiel Rifle B.png`;
const southEnergyLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Energy Launcher A.png`;
const southEnergyLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Energy Launcher B.png`;
const southGrenadierImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Grenadier.png`;
const southLauncherAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Launcher A.png`;
const southLauncherBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Launcher B.png`;
const southLMGImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - LMG.png`;
const southRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Rifleman A.png`;
const southRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Rifleman B.png`;
const southRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Rifleman C.png`;
const southRifleDImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/South - Rifleman D.png`;

const previewImage = `${BaseURL}/Mechs/Misc/Infantry/5 Man Squad/preview.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    accessory2IsChassis: true,
    chassisHasNone: true,
    defaultValues: `{
    "chassisValue": "3",
    "headValue": "8",
    "rearMountValue": "7",
    "weaponValue": "2",
    "secondaryWeaponValue": "10",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "RIFLE A",
            src: northRifleAImage,
        },
        {
            value: 1,
            label: "RIFLE B",
            src: northRifleBImage,
        },
        {
            value: 2,
            label: "RIFLE C",
            src: northRifleCImage,
        },
        {
            value: 3,
            label: "RIFLE D",
            src: northRifleDImage,
        },
        {
            value: 4,
            label: "LMG",
            src: northLMGImage,
        },
        {
            value: 5,
            label: "ANTI-MATERIEL RIFLE A",
            src: northAMRifleAImage,
        },
        {
            value: 6,
            label: "ANTI-MATERIEL RIFLE B",
            src: northAMRifleBImage,
        },
        {
            value: 7,
            label: "GRENADIER",
            src: northGrenadierImage,
        },
        {
            value: 8,
            label: "LAUNCHER A",
            src: northLauncherAImage,
        },
        {
            value: 9,
            label: "LAUNCHER B",
            src: northLauncherBImage,
        },
        {
            value: 10,
            label: "ENERGY LAUNCHER A",
            src: northEnergyLauncherAImage,
        },
        {
            value: 11,
            label: "ENERGY LAUNCHER B",
            src: northEnergyLauncherBImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "RIFLE A",
            src: southRifleAImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 1,
            label: "RIFLE B",
            src: southRifleBImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 2,
            label: "RIFLE C",
            src: southRifleCImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 3,
            label: "RIFLE D",
            src: southRifleDImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 4,
            label: "LMG",
            src: southLMGImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 5,
            label: "ANTI-MATERIEL RIFLE A",
            src: southAMRifleAImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 6,
            label: "ANTI-MATERIEL RIFLE B",
            src: southAMRifleBImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 7,
            label: "GRENADIER",
            src: southGrenadierImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 8,
            label: "LAUNCHER A",
            src: southLauncherAImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 9,
            label: "LAUNCHER B",
            src: southLauncherBImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 10,
            label: "ENERGY LAUNCHER A",
            src: southEnergyLauncherAImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 11,
            label: "ENERGY LAUNCHER B",
            src: southEnergyLauncherBImage,
            shadowDetails: shadowDetails,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "RIFLE A",
                src: westRifleAImage,
            },
            {
                value: 1,
                label: "RIFLE B",
                src: westRifleBImage,
            },
            {
                value: 2,
                label: "RIFLE C",
                src: westRifleCImage,
            },
            {
                value: 3,
                label: "RIFLE D",
                src: westRifleDImage,
            },
            {
                value: 4,
                label: "LMG",
                src: westLMGImage,
            },
            {
                value: 5,
                label: "ANTI-MATERIEL RIFLE A",
                src: westAMRifleAImage,
            },
            {
                value: 6,
                label: "ANTI-MATERIEL RIFLE B",
                src: westAMRifleBImage,
            },
            {
                value: 7,
                label: "GRENADIER",
                src: westGrenadierImage,
            },
            {
                value: 8,
                label: "LAUNCHER A",
                src: westLauncherAImage,
            },
            {
                value: 9,
                label: "LAUNCHER B",
                src: westLauncherBImage,
            },
            {
                value: 10,
                label: "ENERGY LAUNCHER A",
                src: westEnergyLauncherAImage,
            },
            {
                value: 11,
                label: "ENERGY LAUNCHER B",
                src: westEnergyLauncherBImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "RIFLE A",
            src: centerRifleAImage,
        },
        {
            value: 1,
            label: "RIFLE B",
            src: centerRifleBImage,
        },
        {
            value: 2,
            label: "RIFLE C",
            src: centerRifleCImage,
        },
        {
            value: 3,
            label: "RIFLE D",
            src: centerRifleDImage,
        },
        {
            value: 4,
            label: "LMG",
            src: centerLMGImage,
        },
        {
            value: 5,
            label: "ANTI-MATERIEL RIFLE A",
            src: centerAMRifleAImage,
        },
        {
            value: 6,
            label: "ANTI-MATERIEL RIFLE B",
            src: centerAMRifleBImage,
        },
        {
            value: 7,
            label: "GRENADIER",
            src: centerGrenadierImage,
        },
        {
            value: 8,
            label: "LAUNCHER A",
            src: centerLauncherAImage,
        },
        {
            value: 9,
            label: "LAUNCHER B",
            src: centerLauncherBImage,
        },
        {
            value: 10,
            label: "ENERGY LAUNCHER A",
            src: centerEnergyLauncherAImage,
        },
        {
            value: 11,
            label: "ENERGY LAUNCHER B",
            src: centerEnergyLauncherBImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "RIFLE A",
            src: eastRifleAImage,
        },
        {
            value: 1,
            label: "RIFLE B",
            src: eastRifleBImage,
        },
        {
            value: 2,
            label: "RIFLE C",
            src: eastRifleCImage,
        },
        {
            value: 3,
            label: "RIFLE D",
            src: eastRifleDImage,
        },
        {
            value: 4,
            label: "LMG",
            src: eastLMGImage,
        },
        {
            value: 5,
            label: "ANTI-MATERIEL RIFLE A",
            src: eastAMRifleAImage,
        },
        {
            value: 6,
            label: "ANTI-MATERIEL RIFLE B",
            src: eastAMRifleBImage,
        },
        {
            value: 7,
            label: "GRENADIER",
            src: eastGrenadierImage,
        },
        {
            value: 8,
            label: "LAUNCHER A",
            src: eastLauncherAImage,
        },
        {
            value: 9,
            label: "LAUNCHER B",
            src: eastLauncherBImage,
        },
        {
            value: 10,
            label: "ENERGY LAUNCHER A",
            src: eastEnergyLauncherAImage,
        },
        {
            value: 11,
            label: "ENERGY LAUNCHER B",
            src: eastEnergyLauncherBImage,
        },
    ],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "NORTH",
            value: "head",
        },
        {
            label: "EAST",
            value: "rearMount",
        },
        {
            label: "CENTER",
            value: "secondaryWeapon",
        },
        {
            label: "WEST",
            value: "weapon",
        },
        {
            label: "SOUTH",
            value: "chassis",
        },
    ],
    labels: {
        "head": "NORTH",
        "chassis": "SOUTH",
        "rearMount": "EAST",
        "weapon": "WEST",
        "secondaryWeapon": "CENTER",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
