import BaseURL from "./images/Hosting-BaseURL";
import { shadowDetailsNone } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI-Nimue`;

// BODY
const bodyNimueImage = `${imagePath}/Chassis.png`;

// HEADS
const headNimueImage = `${imagePath}/heads/Head - Nimue.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlacklightImage = `${imagePath}/heads/Head - Blacklight.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headFrontlineImage = `${imagePath}/heads/Head - Frontline.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headStalwartImage = `${imagePath}/heads/Head - Stalwart.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const lWeaponEstocImage = `${imagePath}/weapons/left/Left Weapon - Estoc.png`;
const lWeaponKatanaImage = `${imagePath}/weapons/left/Left Weapon - Katana.png`;
const lWeaponLongswordImage = `${imagePath}/weapons/left/Left Weapon - Longsword.png`;
const lWeaponPistolImage = `${imagePath}/weapons/left/Left Weapon - Pistol.png`;
const lWeaponSaberImage = `${imagePath}/weapons/left/Left Weapon - Saber.png`;
const lWeaponScimitarImage = `${imagePath}/weapons/left/Left Weapon - Scimitar.png`;
const lWeaponSpearImage = `${imagePath}/weapons/left/Left Weapon - Spear.png`;

// RIGHT
const rWeaponSpearImage = `${imagePath}/weapons/right/Right Weapon - Spear.png`;
const rWeaponClaiomhSolaisImage = `${imagePath}/weapons/right/Right Weapon - Claiomh Solais.png`; // Claíomh Solais
const rWeaponHandImage = `${imagePath}/weapons/right/Right Weapon - Hand.png`;
const rWeaponHackingHandImage = `${imagePath}/weapons/right/Right Weapon - Hacking Hand.png`;
const rWeaponPistolImage = `${imagePath}/weapons/right/Right Weapon - Pistol.png`;

// CLOAK
const cloakImage = `${imagePath}/rear/Rear - Feth Fiada Cloak.png`;
const cloakFrontImage = `${imagePath}/rear/Rear - Feth Fiada Cloak Over.png`;

// GLOWS
const glowReflectivePoolImage = `${imagePath}/glows/Reflective Pool.png`;
const glowCalibanImage = `${imagePath}/glows/Glow - Caliban.png`;
const glowClaiomhSolaisImage = `${imagePath}/glows/Glow - Claiomh Solais.png`;
const glowDualSummonedBladesImage = `${imagePath}/glows/Glow - Dual Summoned Blades.png`;
const glowHackSlashImage = `${imagePath}/glows/Glow - Hack Slash.png`;
const glowLeftSummonedBladeImage = `${imagePath}/glows/Glow - Left Summoned Blade.png`;
const glowNimueHeadImage = `${imagePath}/glows/Glow - Nimue Head.png`;
const glowSpearImage = `${imagePath}/glows/Glow - Spear.png`;
const glowSummonedBladeImage = `${imagePath}/glows/Glow - Summoned Blade.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/MOI_Nimue_by_AzMiLion.png`;

const bipedLegsShadow = shadowDetailsNone;

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "3",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "NIMUE",
            src: headNimueImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 2,
            label: "BLACKLIGHT",
            src: headBlacklightImage,
        },
        {
            value: 3,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "FRONTLINE",
            src: headFrontlineImage,
        },
        {
            value: 6,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 8,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 9,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 10,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 11,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 12,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 13,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 14,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 15,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 16,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 17,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 18,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 19,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 20,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 21,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 22,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 23,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 24,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 25,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 26,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 27,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 28,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MOI NIMUE",
            src: bodyNimueImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ESTOC",
                src: lWeaponEstocImage,
            },
            {
                value: 1,
                label: "KATANA",
                src: lWeaponKatanaImage,
            },
            {
                value: 2,
                label: "LONGSWORD",
                src: lWeaponLongswordImage,
            },
            {
                value: 3,
                label: "PISTOL",
                src: lWeaponPistolImage,
            },
            {
                value: 4,
                label: "SABER",
                src: lWeaponSaberImage,
            },
            {
                value: 5,
                label: "SCIMITAR",
                src: lWeaponScimitarImage,
            },
            {
                value: 6,
                label: "SPEAR",
                src: lWeaponSpearImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CLAIOMH SOLAIS",
            src: rWeaponClaiomhSolaisImage,
        },
        {
            value: 1,
            label: "HAND",
            src: rWeaponHandImage,
        },
        {
            value: 2,
            label: "HACKING HAND",
            src: rWeaponHackingHandImage,
        },
        {
            value: 3,
            label: "PISTOL",
            src: rWeaponPistolImage,
        },
        {
            value: 4,
            label: "SPEAR",
            src: rWeaponSpearImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "FETH FIADA CLOAK",
            src: cloakImage,
            frontSrc: cloakFrontImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "REFLECTIVE POOL",
            backmostSrc: glowReflectivePoolImage,
        },
        {
            value: 1,
            label: "NIMUE HEAD",
            frontSrc: glowNimueHeadImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            frontSrc: glowCalibanImage,
        },
        {
            value: 3,
            label: "CLAIOMH SOLAIS",
            frontSrc: glowClaiomhSolaisImage,
        },
        {
            value: 4,
            label: "HACK & SLASH",
            frontSrc: glowHackSlashImage,
        },
        {
            value: 5,
            label: "SUMMONED BLADE - LEFT",
            frontSrc: glowLeftSummonedBladeImage,
        },
        {
            value: 6,
            label: "SUMMONED BLADE - RIGHT",
            frontSrc: glowSummonedBladeImage,
        },
        {
            value: 7,
            label: "SUMMONED BLADES - DUAL",
            frontSrc: glowDualSummonedBladesImage,
        },
        {
            value: 8,
            label: "SPEAR",
            frontSrc: glowSpearImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CLOAK",
            value: "rearMount",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "CLOAK",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
