import wolfKnight from "./Mechs-Wolf-Knight";
import grayGlint from "./Mechs-Gray-Glint";
import scorpio from "./Mechs-Scorpi-O";
import matapacos from "./Mechs-Matapacos";
import genosAran from "./Mechs-Genos-Aran";
import daelus from "./Mechs-Daelus";
import hazard from "./Mechs-Hazard";
import gnoll from "./Mechs-Gnoll";
import alka from "./Mechs-Alka";
import viceroy from "./Mechs-Viceroy";
import zahhak from "./Mechs-Zahhak";
import dreadnought from "./Mechs-Dreadnought";
import balorina from "./Mechs-Balorina";
import cambiareBiped from "./Mechs-Cambiare-Biped";
import cambiareQuad from "./Mechs-Cambiare-Quad";
import bellamy from "./Mechs-Bellamy";
import niddhoggr from "./Mechs-Niddhoggr";
import asura from "./Mechs-Asura";
import sirocco from "./Mechs-Sirocco";
import epialos from "./Mechs-Epialos";
import fourtuga from "./Mechs-Fourtuga";
import galiban from "./Mechs-Galiban";
import ishtara from "./Mechs-Ishtara";
import simurgh from "./Mechs-Simurgh";
import trishula from "./Mechs-Trishula";
import auroran from "./Mechs-Auroran";
import auroranDrone from "./Mechs-Auroran-Drone";
import huginn from "./Mechs-Huginn";
import notredame from "./Mechs-Notredame";
import pinkWitch from "./Mechs-Pink-Witch";
import mariana from "./Mechs-Mariana";
import MechsDarkGlint from "./Mechs-Dark-Glint";
import MechsJason from "./Mechs-Jason";
import MechsToa from "./Mechs-Toa";
import MechsThunderhog from "./Mechs-Thunderhog";
import MechsMarquis from "./Mechs-Marquis";
import MechsRemus from "./Mechs-Remus";
import MechsIbis from "./Mechs-Ibis";
import MechsFiresOfRetribution from "./Mechs-Fires-of-Retribution";
import MechsTyphoon from "./Mechs-Typhoon";

export default [
    Object.assign({ value: 0, label: "ALKA" }, alka),
    Object.assign({ value: 1, label: "ASURA", isPremium: true, isLegendary: true }, asura),
    Object.assign({ value: 2, label: "AURORAN *" }, auroran),
    Object.assign({ value: 3, label: "AURORAN DRONE *" }, auroranDrone),
    Object.assign({ value: 4, label: "BALORINA", isPremium: true, isLegendary: true }, balorina),
    Object.assign({ value: 5, label: "BELLAMY", isPremium: true, isLegendary: true }, bellamy),
    Object.assign({ value: 6, label: "CAMBIARE - BIPED", isPremium: true, isLegendary: true }, cambiareBiped),
    Object.assign({ value: 7, label: "CAMBIARE - QUAD", isPremium: true, isLegendary: true }, cambiareQuad),
    Object.assign({ value: 8, label: "DAELUS" }, daelus),
    Object.assign({ value: 9, label: "DARK GLINT *" }, MechsDarkGlint),
    Object.assign({ value: 10, label: "DREADNOUGHT", isPremium: true, isLegendary: true }, dreadnought),
    Object.assign({ value: 11, label: "EPIALOS" }, epialos),
    Object.assign({ value: 12, label: "FIRES OF RETRIBUTION" }, MechsFiresOfRetribution),
    Object.assign({ value: 13, label: "FOURTUGA", isPremium: true, isLegendary: true }, fourtuga),
    Object.assign({ value: 14, label: "GALIBAN *" }, galiban),
    Object.assign({ value: 15, label: "GENOS ARAN" }, genosAran),
    Object.assign({ value: 16, label: "GNOLL" }, gnoll),
    Object.assign({ value: 17, label: "GRAY GLINT" }, grayGlint),
    Object.assign({ value: 18, label: "HA REMUS *" }, MechsRemus),
    Object.assign({ value: 19, label: "HAZARD" }, hazard),
    Object.assign({ value: 20, label: "HUGINN *" }, huginn),
    Object.assign({ value: 21, label: "IBIS *" }, MechsIbis),
    Object.assign({ value: 22, label: "ISHTAR", isPremium: true, isLegendary: true }, ishtara),
    Object.assign({ value: 23, label: "JASON *" }, MechsJason),
    Object.assign({ value: 24, label: "MARIANA *" }, mariana),
    Object.assign({ value: 25, label: "MARQUIS", isPremium: true, isLegendary: true }, MechsMarquis),
    Object.assign({ value: 26, label: "MATAPACOS" }, matapacos),
    Object.assign({ value: 27, label: "MODULAR TOA *" }, MechsToa),
    Object.assign({ value: 28, label: "NIDDHOGGR", isPremium: true, isLegendary: true }, niddhoggr),
    Object.assign({ value: 29, label: "NOTREDAME" }, notredame),
    Object.assign({ value: 30, label: "PINK WITCH *" }, pinkWitch),
    Object.assign({ value: 31, label: "SCORPI-O" }, scorpio),
    Object.assign({ value: 32, label: "SIMURGH *" }, simurgh),
    Object.assign({ value: 33, label: "SIROCCO", isPremium: true, isLegendary: true }, sirocco),
    Object.assign({ value: 34, label: "THUNDERHOG", isPremium: true, isLegendary: true }, MechsThunderhog),
    Object.assign({ value: 35, label: "TRISHULA *" }, trishula),
    Object.assign({ value: 36, label: "TYPHOON", isPremium: true, isLegendary: true }, MechsTyphoon),
    Object.assign({ value: 37, label: "VICEROY", isPremium: true, isLegendary: true }, viceroy),
    Object.assign({ value: 38, label: "WOLF KNIGHT" }, wolfKnight),
    Object.assign({ value: 39, label: "ZAHHAK", isPremium: true, isLegendary: true }, zahhak),
];
