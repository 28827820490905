import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyGoblinImage = `${BaseURL}/Mechs/Horus/Goblin/Goblin Body.png`;

// 1H
const weaponAutopodImage = `${BaseURL}/Mechs/Horus/Goblin/Goblin Autopod.png`;

// SECONDARY WEAPON
const secondaryWeaponNexusImage = `${BaseURL}/Mechs/Horus/Goblin/Goblin Nexus.png`;

// ACCESSORY
const accessoryGlowImage = `${BaseURL}/Mechs/Horus/Goblin/Goblin Glow.png`;
const accessoryPilotImage = `${BaseURL}/Mechs/Horus/Goblin/Goblin Pilot.png`;

const coreImage = `${BaseURL}/Mechs/Horus/Goblin/Goblin (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/Horus/Goblin/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "82px",
    right: "72px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "PILOT",
            src: accessoryPilotImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GOBLIN",
            src: bodyGoblinImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AUTOPOD",
                src: weaponAutopodImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "NEXUS",
            backSrc: secondaryWeaponNexusImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "GLOW",
            src: accessoryGlowImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
