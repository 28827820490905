import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOS-Phoenix`;

// BODY
const bodyIntactImage = `${imagePath}/Chassis - Intact.png`;
const bodyReactorOverchargeImage = `${imagePath}/Chassis - Reactor Overcharge.png`;

// WEAPONS=
const weaponsClawsImage = `${imagePath}/weapons/Weapons - Claws.png`;
const weaponsGaussCannonImage = `${imagePath}/weapons/Weapons - Gauss Cannon.png`;
const weaponsHeavyMeleeImage = `${imagePath}/weapons/Weapon - Heavy Melee.png`;
const weaponsArmCannonsImage = `${imagePath}/weapons/Weapon - Arm Cannons.png`;

const weaponsCentralCannonImage = `${imagePath}/weapons/Weapon - Central Cannon.png`;

// REAR MOUNT
const rearMountRailCannonLeftImage = `${imagePath}/weapons/Weapons - L Rail Cannon.png`;
const rearMountRailCannonRightImage = `${imagePath}/weapons/Weapons - R Rail Cannon.png`;
const rearMountRailCannonDualImage = `${imagePath}/weapons/Weapons - Dual Rail Cannons.png`;

// GLOWS
const glowGaussCannonImage = `${imagePath}/glows/Glow - Gauss Cannon.png`;
const glowRailCannonLImage = `${imagePath}/glows/Glow - L Rail Cannon.png`;
const glowRailCannonRImage = `${imagePath}/glows/Glow - R Rail Cannon.png`;
const glowRailCannonsChargedImage = `${imagePath}/glows/Glow - Rail Cannons Charged.png`;
const glowRailCannonsImage = `${imagePath}/glows/Glow - Rail Cannons.png`;
const glowReactorOverchargeImage = `${imagePath}/glows/Glow - Reactor Overcharge.png`;
const glowReactorOverchargeUnderImage = `${imagePath}/glows/Glow Under - Reactor Overcharge.png`;
const glowHeavyMeleeImage = `${imagePath}/glows/Glow - Heavy Melee.png`;
const glowArmCannonsImage = `${imagePath}/glows/Glow - Arm Cannons.png`;
const glowCentralCannonImage = `${imagePath}/glows/Glow - Central Cannon.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/PHOENIX_COREBOOK_by_AzMiLion.png`;


const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "39px",
    right: "61px",
};

const originalArtCredit = {
    prefixText: "Commissioned by Vox ",
    title: "Link in News",
    url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CENTRAL CANNON",
            src: weaponsCentralCannonImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "INTACT",
            src: bodyIntactImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "REACTOR OVERCHARGE",
            src: bodyReactorOverchargeImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLAWS",
                src: weaponsClawsImage,
            },
            {
                value: 1,
                label: "GAUSS CANNON",
                src: weaponsGaussCannonImage,
            },
            {
                value: 2,
                label: "ARM CANNONS",
                src: weaponsArmCannonsImage,
            },
            {
                value: 3,
                label: "HEAVY MELEE",
                src: weaponsHeavyMeleeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "RAIL CANNON - LEFT",
            src: rearMountRailCannonLeftImage,
        },
        {
            value: 1,
            label: "RAIL CANNON - RIGHT",
            src: rearMountRailCannonRightImage,
        },
        {
            value: 2,
            label: "RAIL CANNON - DUAL",
            src: rearMountRailCannonDualImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "REACTOR OVERCHARGE",
            src: glowReactorOverchargeImage,
            backSrc: glowReactorOverchargeUnderImage,
        },
        {
            value: 1,
            label: "GAUSS CANNON",
            frontSrc: glowGaussCannonImage,
        },
        {
            value: 2,
            label: "RAIL CANNON - LEFT",
            frontSrc: glowRailCannonLImage,
        },
        {
            value: 3,
            label: "RAIL CANNON - RIGHT",
            frontSrc: glowRailCannonRImage,
        },
        {
            value: 4,
            label: "RAIL CANNON - DUAL",
            frontSrc: glowRailCannonsImage,
        },
        {
            value: 5,
            label: "CHARGED RAIL CANNON - DUAL",
            frontSrc: glowRailCannonsChargedImage,
        },
        {
            value: 6,
            label: "HEAVY MELEE",
            frontSrc: glowHeavyMeleeImage,
        },
        {
            value: 7,
            label: "ARM CANNONS",
            frontSrc: glowArmCannonsImage,
        },
        {
            value: 8,
            label: "CENTRAL CANNON",
            frontSrc: glowCentralCannonImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "CENTER",
            value: "head",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "REAR MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "CENTER",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "REAR MOUNT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
