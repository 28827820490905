import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Nelson-2`

// BODY
const bodyNelsonImage = `${imagePath}/Chassis.png`;
const bodyFlightDownImage = `${imagePath}/Chassis - Flight Mode - Wings Down.png`;
const bodyFlightUpImage = `${imagePath}/Chassis - Flight Mode - Wings Up.png`;

// HEADS
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_nelson 2.png`;
// PREMIUM
const headChimeraImage = `${imagePath}/heads/premium/Head - Chimera.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head - Cockpit.png`;
const headCyclopsImage = `${imagePath}/heads/premium/Head - Cyclops.png`;
const headFlightTypeImage = `${imagePath}/heads/premium/Head - Flight Type.png`;
const headHornedImage = `${imagePath}/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/premium/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head - Malice.png`;
const headMourningCloakImage = `${imagePath}/heads/premium/Head - Mourning Cloak.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${imagePath}/heads/premium/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/premium/Head - Rain Hair.png`;
const headTokugawaImage = `${imagePath}/heads/premium/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/premium/Head - Vanquisher.png`;

// WEAPONS
const weaponsDaggersImage = `${imagePath}/weapons/Weapon - Daggers.png`;
const weaponsSwordImage = `${imagePath}/weapons/Weapon - Sword.png`;
const weaponsSwordDaggerImage = `${imagePath}/weapons/Weapon - Sword Dagger.png`;
const weaponsKatanaImage = `${imagePath}/weapons/Weapon - Katana.png`;
const weaponsZweihanderImage = `${imagePath}/weapons/Weapon - Zweihander.png`;
const weaponsFlambergeImage = `${imagePath}/weapons/Weapon - Flamberge.png`;
const weaponsHalberdImage = `${imagePath}/weapons/Weapon - Halberd.png`;
const weaponsSpearImage = `${imagePath}/weapons/Weapon - Spear.png`;
const weaponsExplosiveLanceImage = `${imagePath}/weapons/Weapon - Explosive Lance.png`;
const weaponsMessiacideImage = `${imagePath}/weapons/Weapon - Messiacide.png`;
const weaponsRifleBayonetImage = `${imagePath}/weapons/Weapon - Rifle Bayonet.png`;

// SECONDARY WEAPONS
const weaponsFistsImage = `${imagePath}/weapons/Weapon - Dual Fists.png`;
const weaponsLeftFistImage = `${imagePath}/weapons/Weapon - Left Fist.png`;
const weaponsRightFistImage = `${imagePath}/weapons/Weapon - Right Fist.png`;

// GLOWS
const glowLeftFistImage = `${imagePath}/glows/Glow - Left Fist.png`;
const glowRightFistImage = `${imagePath}/glows/Glow - Right Fist.png`;
const glowDualFistsImage = `${imagePath}/glows/Glow - Dual Fists.png`;
const glowBoostersImage = `${imagePath}/glows/Glow - Boosters.png`;
const glowNelsonEyeImage = `${imagePath}/glows/Glow - Nelson Eye.png`;
const glowFlightModeEyeImage = `${imagePath}/glows/Glow - Flight Mode Eye.png`;
const glowFlightModeWingsDownImage = `${imagePath}/glows/Glow - Flight Mode - Wings Down.png`;
const glowFlightModeWingsUpImage = `${imagePath}/glows/Glow - Flight Mode - Wings Up.png`;
const glowTokugawaHeadImage = `${imagePath}/glows/Glow - Tokugawa Head.png`;

// ACCESSORIES
const accessoryBoostersImage = `${imagePath}/accessories/Boosters.png`;
const accessoryBoostersSubImage = `${imagePath}/accessories/Boosters Sublayer.png`;
const accessoryKatanaRibbonImage = `${imagePath}/accessories/Accessory - Katana Ribbon.png`;
const accessoryPolearmRibbonImage = `${imagePath}/accessories/Accessory - Polearm Ribbon.png`;
const accessoryExplosiveLanceRibbonImage = `${imagePath}/accessories/Accessory - Explosive Lance Ribbon.png`;
const accessoryKnightRobesImage = `${imagePath}/accessories/Accessory - Knight Robes.png`;
const accChassisDetailImage = `${imagePath}/accessories/chassis-NELSON-mid.png`;

const coreImage = `${imagePath}/Nelson 2.0 Flight - by Corvon.png`;
const core2Image = `${imagePath}/CharlieMerlynn_Nelson_2.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "57px",
    right: "40px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerlynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "8",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "3",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "NELSON",
            foremostSrc: headNelsonImage,
        },
        {
            value: 1,
            label: "FLIGHT TYPE",
            foremostSrc: headFlightTypeImage,
        },
        {
            value: 2,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 3,
            label: "BONEHEAD",
            foremostSrc: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "COCKPIT",
            foremostSrc: headCockpitImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "HORNED",
            foremostSrc: headHornedImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MAG",
            foremostSrc: headMagImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MOURNING CLOAK",
            foremostSrc: headMourningCloakImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RAIN",
            foremostSrc: headRainImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "TOKUGAWA",
            foremostSrc: headTokugawaImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "NELSON",
            src: bodyNelsonImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "FLIGHT MODE WINGS DOWN",
            src: bodyFlightDownImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "FLIGHT MODE WINGS UP",
            src: bodyFlightUpImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "FISTS",
                src: weaponsFistsImage,
            },
            {
                value: 1,
                label: "DAGGERS",
                src: weaponsDaggersImage,
            },
            {
                value: 2,
                label: "SWORD",
                src: weaponsSwordImage,
            },
            {
                value: 3,
                label: "SWORD + DAGGER",
                src: weaponsSwordDaggerImage,
            },
            {
                value: 4,
                label: "KATANA",
                src: weaponsKatanaImage,
            },
            {
                value: 5,
                label: "ZWEIHANDER",
                src: weaponsZweihanderImage,
            },
            {
                value: 6,
                label: "FLAMBERGE",
                src: weaponsFlambergeImage,
            },
            {
                value: 7,
                label: "HALBERD",
                src: weaponsHalberdImage,
            },
            {
                value: 8,
                label: "SPEAR",
                src: weaponsSpearImage,
            },
            {
                value: 9,
                label: "EXPLOSIVE LANCE",
                src: weaponsExplosiveLanceImage,
            },
            {
                value: 10,
                label: "LONG-ISH LANCE",
                src: weaponsMessiacideImage,
            },
            {
                value: 11,
                label: "RIFLE + BAYONET",
                src: weaponsRifleBayonetImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "LEFT FIST",
            src: weaponsLeftFistImage,
        },
        {
            value: 1,
            label: "RIGHT FIST",
            src: weaponsRightFistImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BOOSTERS",
            src: accessoryBoostersImage,
            backSrc: accessoryBoostersSubImage,
        },
        {
            value: 1,
            label: "KNIGHT ROBES",
            src: accessoryKnightRobesImage,
        },
        {
            value: 2,
            label: "KATANA RIBBON",
            foremostSrc: accessoryKatanaRibbonImage,
        },
        {
            value: 3,
            label: "POLEARM RIBBON",
            foremostSrc: accessoryPolearmRibbonImage,
        },
        {
            value: 4,
            label: "EXPLOSIVE LANCE RIBBON",
            foremostSrc: accessoryExplosiveLanceRibbonImage,
        },
        {
            value: 5,
            label: "COLOR - CHASSIS DETAILS",
            src: accChassisDetailImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 6,
            label: "LEFT FIST - GLOW",
            frontSrc: glowLeftFistImage,
        },
        {
            value: 7,
            label: "RIGHT FIST - GLOW",
            frontSrc: glowRightFistImage,
        },
        {
            value: 8,
            label: "DUAL FISTS - GLOW",
            frontSrc: glowDualFistsImage,
        },
        {
            value: 9,
            label: "BOOSTERS - GLOW",
            frontSrc: glowBoostersImage,
        },
        {
            value: 10,
            label: "NELSON EYE - GLOW",
            foremostSrc: glowNelsonEyeImage,
        },
        {
            value: 11,
            label: "FLIGHT TYPE EYE - GLOW",
            foremostSrc: glowFlightModeEyeImage,
        },
        {
            value: 12,
            label: "FLIGHT MODE WINGS DOWN - GLOW",
            frontSrc: glowFlightModeWingsDownImage,
        },
        {
            value: 13,
            label: "FLIGHT MODE WINGS UP - GLOW",
            frontSrc: glowFlightModeWingsUpImage,
        },
        {
            value: 14,
            label: "TOKUGAWA HEAD - GLOW",
            foremostSrc: glowTokugawaHeadImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
