import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyEmperorImage = `${BaseURL}/Mechs/SSC/Emperor/Chassis.png`;
const bodyUhudImage = `${BaseURL}/Mechs/SSC/Emperor/Chassis - Uhud.png`;

// HEADS
const headEmperorImage = `${BaseURL}/Mechs/SSC/Emperor/heads/Head - Emperor.png`;
const headKazuImage = `${BaseURL}/Mechs/SSC/Emperor/heads/Head - Kazu.png`;
// PREMIUM
const headAngleImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Angle.png`;
const headBlackSpotImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Black Spot.png`;
const headBlackWitchImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Black Witch.png`;
const headBriarImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Briar.png`;
const headCheddahImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Cheddah.png`;
const headCyclopsImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Cyclops.png`;
const headDunpeelHatImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Dunpeel Hat.png`;
const headFactoryStandardImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Factory Standard.png`;
const headJudicatorImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Judicator.png`;
const headLaborerImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Malice.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Natural Law Hair.png`;
const headPredatorImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Predator.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Rain Transformed Hair.png`;
const headRaleighImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Raleigh.png`;
const headScarabImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Scarab.png`;
const headVanguardImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Vanguard.png`;
const headVanquisherImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Vanquisher.png`;
const headVisionaryImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Visionary.png`;
const headUhudImage = `${BaseURL}/Mechs/SSC/Emperor/heads/premium/Head - Uhud.png`;

// WEAPONS
const weaponsBowAndNexusImage = `${BaseURL}/Mechs/SSC/Emperor/weapons/Weapon - Bow and Nexus.png`;
const weaponsCrossbowImage = `${BaseURL}/Mechs/SSC/Emperor/weapons/Weapon - Crossbow.png`;
const weaponsRevolversImage = `${BaseURL}/Mechs/SSC/Emperor/weapons/Weapon - Revolvers.png`;
const weaponsMagnumRevolversImage = `${BaseURL}/Mechs/SSC/Emperor/weapons/Weapon - Magnum Revolvers.png`;
const weaponsDurandalRifleImage = `${BaseURL}/Mechs/SSC/Emperor/weapons/Weapon - Durandal Rifle.png`;
const weaponsUhudBowImage = `${BaseURL}/Mechs/SSC/Emperor/weapons/Weapon - Uhud Bow - no shoulders.png`;
const weaponsUhudBowShouldersImage = `${BaseURL}/Mechs/SSC/Emperor/weapons/Weapon - Uhud Bow - Shoulders.png`;

// ACCESSORIES
const accessoryEmperorGarbBowImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Emperor Garb - Bow.png`;
const accessoryEmperorGarbRifleImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Emperor Garb - Rifle.png`;
const accessoryShrikeHoodOverImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Shrike Hood - Over.png`;
const accessoryShrikeHoodUnderImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Shrike Hood - Under.png`;
const accessoryApostateCloakHoodUpOverImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Apostate Cloak - Hood Up - Over.png`;
const accessoryApostateCloakHoodUpUnderImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Apostate Cloak - Hood Up - Under.png`;
const accessoryApostateCloakHoodDownOverImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Apostate Cloak - Hood Down - Over.png`;
const accessoryApostateCloakHoodDownUnderImage = `${BaseURL}/Mechs/SSC/Emperor/accessories/Accessory - Apostate Cloak - Hood Down - Under.png`;

// GLOW
const glowBowstringImage = `${BaseURL}/Mechs/SSC/Emperor/glows/Glow - Bowstring.png`;
const glowCrossbowImage = `${BaseURL}/Mechs/SSC/Emperor/glows/Glow - Crossbow.png`;
const glowEyeImage = `${BaseURL}/Mechs/SSC/Emperor/glows/Glow - Eye.png`;
const glowRoyaltyTopImage = `${BaseURL}/Mechs/SSC/Emperor/glows/Glow - Royalty - Top.png`;
const glowRoyaltyUnderImage = `${BaseURL}/Mechs/SSC/Emperor/glows/Glow - Royalty Under.png`;
const glowUhudBowImage = `${BaseURL}/Mechs/SSC/Emperor/glows/Glow - Uhud Bow.png`;

const coreImage = `${BaseURL}/Mechs/SSC/Emperor/Emperor (by Alraekinn).png`;

const previewImage = `${BaseURL}/Mechs/SSC/Emperor/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "57px",
    right: "39px",
};

const uhudLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "42px",
    right: "39px",
};

export default {
    secondaryWeaponIsChassisStanceSpecial: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Alrækinn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    removeNone: {
        weapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "2",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "EMPEROR",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "EMPEROR",
            src: headEmperorImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "ANGLE",
            src: headAngleImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "BLACK SPOT",
            src: headBlackSpotImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "BLACK WITCH",
            src: headBlackWitchImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "BRIAR",
            src: headBriarImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CHEDDAH",
            src: headCheddahImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "DUNPEEL HAT",
            src: headDunpeelHatImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "PREDATOR",
            src: headPredatorImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "RALEIGH",
            src: headRaleighImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "SCARAB",
            src: headScarabImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "VANGUARD",
            src: headVanguardImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "VANQUISHER",
            src: headVanquisherImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "VISIONARY",
            src: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 24,
            label: "UHUD",
            src: headUhudImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "EMPEROR",
            src: bodyEmperorImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "UHUD",
            src: bodyUhudImage,
            shadowDetails: uhudLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "EMPEROR": [
            {
                value: 0,
                label: "BOW AND NEXUS",
                src: weaponsBowAndNexusImage,
            },
            {
                value: 1,
                label: "CROSSBOW",
                src: weaponsCrossbowImage,
            },
            {
                value: 2,
                label: "REVOLVERS",
                src: weaponsRevolversImage,
            },
            {
                value: 3,
                label: "MAGNUM REVOLVERS",
                src: weaponsMagnumRevolversImage,
            },
            {
                value: 4,
                label: "DURANDAL RIFLE",
                src: weaponsDurandalRifleImage,
            },
            {
                value: 5,
                label: "UHUD BOW",
                src: weaponsUhudBowImage,
                isPremium: true,
                isLegendary: true,
            },
            {
                value: 6,
                label: "UHUD BOW + SHOULDERS",
                src: weaponsUhudBowShouldersImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
        "UHUD": [
            {
                value: 0,
                label: "BOW AND NEXUS",
                src: weaponsBowAndNexusImage,
            },
            {
                value: 1,
                label: "CROSSBOW",
                src: weaponsCrossbowImage,
            },
            {
                value: 2,
                label: "REVOLVERS",
                src: weaponsRevolversImage,
            },
            {
                value: 3,
                label: "MAGNUM REVOLVERS",
                src: weaponsMagnumRevolversImage,
            },
            {
                value: 4,
                label: "DURANDAL RIFLE",
                src: weaponsDurandalRifleImage,
            },
            {
                value: 5,
                label: "UHUD BOW",
                src: weaponsUhudBowImage,
                isPremium: true,
                isLegendary: true,
            },
            {
                value: 6,
                label: "UHUD BOW + SHOULDERS",
                src: weaponsUhudBowShouldersImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [
        {
            value: 0,
            label: "EMPEROR GARB - BOW",
            src: accessoryEmperorGarbBowImage,
        },
        {
            value: 1,
            label: "EMPEROR GARB - RIFLE",
            src: accessoryEmperorGarbRifleImage,
        },
        {
            value: 2,
            label: "SHRIKE HOOD",
            foremostSrc: accessoryShrikeHoodOverImage,
            src: accessoryShrikeHoodUnderImage,
        },
        {
            value: 3,
            label: "APOSTATE CLOAK",
            frontSrc: accessoryApostateCloakHoodDownOverImage,
            src: accessoryApostateCloakHoodDownUnderImage,
        },
        {
            value: 4,
            label: "APOSTATE CLOAK - HOOD",
            foremostSrc: accessoryApostateCloakHoodUpOverImage,
            src: accessoryApostateCloakHoodUpUnderImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BOWSTRING",
            foremostSrc: glowBowstringImage,
        },
        {
            value: 1,
            label: "CROSSBOW",
            foremostSrc: glowCrossbowImage,
        },
        {
            value: 2,
            label: "EYE",
            foremostSrc: glowEyeImage,
        },
        {
            value: 3,
            label: "ROYALTY",
            foremostSrc: glowRoyaltyTopImage,
            backSrc: glowRoyaltyUnderImage,
        },
        {
            value: 4,
            label: "UHUD BOW",
            foremostSrc: glowUhudBowImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "ACCESSORY",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ACCESSORY",
        "weapon": "WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
