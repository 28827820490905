import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodySunziImage = `${BaseURL}/Mechs/HA/Sunzi/Chassis.png`;

// HEADS
const headSunziImage = `${BaseURL}/Mechs/HA/Sunzi/heads/Head - Sunzi.png`;
const headKazuImage = `${BaseURL}/Mechs/HA/Sunzi/heads/Head - Kazu.png`;
// PREMIUM
const head8ballImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - 8 Ball.png`;
const headAngleImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Angle.png`;
const headBunkerImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Bunker.png`;
const headCalibanImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Caliban.png`;
const headFactoryStandardImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Factory Standard.png`;
const headLaborerImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Laborer.png`;
const headLudonautImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Ludonaut.png`;
const headMaliceImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Malice.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Natural Law Hair.png`;
const headPaladinImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Paladin.png`;
const headPredatorImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Predator.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Rain Transformed Hair.png`;
const headSaladinImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Saladin.png`;
const headScarabImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Scarab.png`;
const headVisionaryImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Visionary.png`;
const headScopehoundImage = `${BaseURL}/Mechs/HA/Sunzi/heads/premium/Head - Scopehound.png`;

// WEAPONS
// MAIN
const weaponMainARImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/main/2H Weapon - Assault Rifle.png`;
const weaponMainWarpRifleImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/main/2H Weapon - Warp Rifle.png`;

// RIGHT
const weaponRightKnifeImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/right/Right Arm - Knife.png`;
const weaponRightPistolImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/right/Right Arm - Pistol.png`;
const weaponRightNexusImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/right/Right Arm - Nexus.png`;
const weaponRightPalmImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/right/Right Arm - Palm.png`;
const weaponRightLowImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/right/Right Arm - Low.png`;
const weaponRightPointUpImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/right/Right Arm - Point Up.png`;
const weaponRightTheBirdImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/right/Right Arm - The Bird.png`;

// LEFT
const weaponLeftPalmImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/left/Left Arm - Palm.png`;
const weaponLeftLowImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/left/Left Arm - Low.png`;
const weaponLeftPointDownImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/left/Left Arm - Point Down.png`;
const weaponLeftTheBirdImage = `${BaseURL}/Mechs/HA/Sunzi/weapons/left/Left Arm - The Bird.png`;

// GLOW
const glowSunziHeadImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Sunzi head.png`;
const glowPistolImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Pistol.png`;
const glowNexusImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Nexus.png`;
const glowWarpRifleImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Warp Rifle.png`;
const glowWarpRiflePistolImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Warp Rifle Pistol.png`;
const glowWarpRifleNexusImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Warp Rifle Nexus.png`;
const glowSunziHeadPistolImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Sunzi head Pistol.png`;
const glowSunziHeadNexusImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Sunzi head Nexus.png`;
const glowSunziHeadWarpRifleImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Sunzi head Warp Rifle.png`;
const glowSunziHeadWarpRiflePistolImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Sunzi head Warp Rifle Pistol.png`;
const glowSunziHeadWarpRifleNexusImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow - Sunzi head Warp Rifle Nexus.png`;
const glowArcPortalFrontImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow 2 - Front - Arc Portal.png`;
const glowArcPortalRearImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow 2 - Rear - Arc Portal.png`;
const glowDoorPortalFrontImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow 2 - Front - Door Portal.png`;
const glowDoorPortalRearImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow 2 - Rear - Door Portal.png`;
const glowRoundPortalFrontImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow 2 - Front - Round Portal.png`;
const glowRoundPortalRearImage = `${BaseURL}/Mechs/HA/Sunzi/glows/Glow 2 - Rear - Round Portal.png`;

const coreImage = `${BaseURL}/Mechs/HA/Sunzi/Sunzi (by kgnotte).png`;

const previewImage = `${BaseURL}/Mechs/HA/Sunzi/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "86px",
    right: "71px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "kgnotte",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "0",
        "secondaryWeaponValue": "2",
        "accessory1Value": "0",
        "accessory2Value": "3",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SUNZI",
            foremostSrc: headSunziImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "ANGLE",
            foremostSrc: headAngleImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "BUNKER",
            foremostSrc: headBunkerImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "FACTORY STANDARD",
            foremostSrc: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "LUDONAUT",
            foremostSrc: headLudonautImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "PALADIN",
            foremostSrc: headPaladinImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "PREDATOR",
            foremostSrc: headPredatorImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "SALADIN",
            foremostSrc: headSaladinImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "SCARAB",
            foremostSrc: headScarabImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "8 BALL",
            foremostSrc: head8ballImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SUNZI",
            src: bodySunziImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "PALM",
                src: weaponLeftPalmImage,
            },
            {
                value: 1,
                label: "LOW",
                src: weaponLeftLowImage,
            },
            {
                value: 2,
                label: "POINT DOWN",
                src: weaponLeftPointDownImage,
            },
            {
                value: 3,
                label: "THE BIRD",
                src: weaponLeftTheBirdImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "PALM",
            backSrc: weaponRightPalmImage,
        },
        {
            value: 1,
            label: "LOW",
            backSrc: weaponRightLowImage,
        },
        {
            value: 2,
            label: "POINT UP",
            backSrc: weaponRightPointUpImage,
        },
        {
            value: 3,
            label: "THE BIRD",
            backSrc: weaponRightTheBirdImage,
        },
        {
            value: 4,
            label: "KNIFE",
            backSrc: weaponRightKnifeImage,
        },
        {
            value: 5,
            label: "PISTOL",
            backSrc: weaponRightPistolImage,
        },
        {
            value: 6,
            label: "NEXUS",
            backSrc: weaponRightNexusImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ASSAULT RIFLE",
            frontSrc: weaponMainARImage,
        },
        {
            value: 1,
            label: "WARP RIFLE",
            frontSrc: weaponMainWarpRifleImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "SUNZI HEAD",
            foremostSrc: glowSunziHeadImage,
        },
        {
            value: 1,
            label: "PISTOL",
            frontSrc: glowPistolImage,
        },
        {
            value: 2,
            label: "NEXUS",
            frontSrc: glowNexusImage,
        },
        {
            value: 3,
            label: "WARP RIFLE",
            frontSrc: glowWarpRifleImage,
        },
        {
            value: 4,
            label: "WARP RIFLE + PISTOL",
            frontSrc: glowWarpRiflePistolImage,
        },
        {
            value: 5,
            label: "WARP RIFLE + NEXUS",
            frontSrc: glowWarpRifleNexusImage,
        },
        {
            value: 6,
            label: "SUNZI HEAD + PISTOL",
            frontSrc: glowSunziHeadPistolImage,
            foremostSrc: glowSunziHeadImage,
        },
        {
            value: 7,
            label: "SUNZI HEAD + NEXUS",
            frontSrc: glowSunziHeadNexusImage,
            foremostSrc: glowSunziHeadImage,
        },
        {
            value: 8,
            label: "SUNZI HEAD + WARP RIFLE",
            frontSrc: glowSunziHeadWarpRifleImage,
            foremostSrc: glowSunziHeadImage,
        },
        {
            value: 9,
            label: "SUNZI HEAD + WARP RIFLE + PISTOL",
            frontSrc: glowSunziHeadWarpRiflePistolImage,
            foremostSrc: glowSunziHeadImage,
        },
        {
            value: 10,
            label: "SUNZI HEAD + WARP RIFLE + NEXUS",
            frontSrc: glowSunziHeadWarpRifleNexusImage,
            foremostSrc: glowSunziHeadImage,
        },
        {
            value: 11,
            label: "ROUND PORTAL",
            foremostSrc: glowRoundPortalFrontImage,
            backSrc: glowRoundPortalRearImage,
        },
        {
            value: 12,
            label: "DOOR PORTAL",
            foremostSrc: glowDoorPortalFrontImage,
            backSrc: glowDoorPortalRearImage,
        },
        {
            value: 13,
            label: "ARC PORTAL",
            foremostSrc: glowArcPortalFrontImage,
            backSrc: glowArcPortalRearImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "rearMount",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "MAIN WEAPON",
        "weapon": "LEFT",
        "secondaryWeapon": "RIGHT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
