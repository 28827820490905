import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/BBeard`;

// BODY
const bodyBlackbeardImage = `${imagePath}/Chassis.png`;
const leftArmDownImage = `${imagePath}/Left Arm.png`;
const leftArmUpImage = `${imagePath}/Left Arm Up.png`;

// HEADS
const headBlackbeardImage = `${imagePath}/heads/Head - Blackbeard.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headHoneyBloodImage = `${imagePath}/heads/Head - Honey Blood.png`;
const headLycanImage = `${imagePath}/heads/Head - Lycan.png`;
const headMakoImage = `${imagePath}/heads/Head - Mako.png`;
const headTricornImage = `${imagePath}/heads/Head - Tricorn.png`;
// PREMIUM
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${imagePath}/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/premium/Head - Cyclops.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/premium/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head - Malice.png`;
const headMetalmarkImage = `${imagePath}/heads/premium/Head - Metalmark.png`;
const headMonarchImage = `${imagePath}/heads/premium/Head - Monarch.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headRainImage = `${imagePath}/heads/premium/Head - Rain.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;

// ACCESSORY
const glowHCBLeftImage = `${imagePath}/accessories/Glow - Left HCB.png`;
const glowHCBRightImage = `${imagePath}/accessories/Glow - Right HCB.png`;
const glowHCBsImage = `${imagePath}/accessories/Glow - Dual HCB.png`;
const glowChainAxeLeftImage = `${imagePath}/accessories/Glow - Left Chain Axe.png`;
const glowChainAxeRightImage = `${imagePath}/accessories/Glow - Right Chain Axe.png`;
const glowChainAxesImage = `${imagePath}/accessories/Glow - Chain Axes.png`;
const synthMuscleDownImage = `${imagePath}/accessories/Accessory - Synthetic Muscle Netting.png`;
const synthMuscleUpImage = `${imagePath}/accessories/Accessory - Synthetic Muscle Netting - High Road.png`;
const bristlecrownLaunchersImage = `${imagePath}/accessories/Accessory - Bristlecrown Launchers.png`;
const glowEyeImage = `${imagePath}/accessories/Glow - Eye.png`;
const glowHeatSinksImage = `${imagePath}/accessories/Glow - Heat Sinks.png`;
const glowHeatSinksEyeImage = `${imagePath}/accessories/Glow - Heat Sinks and Eye.png`;
const synthMuscleComboDownImage = `${imagePath}/accessories/Glow - Synth Exhaust Eye.png`;
const synthMuscleComboUpImage = `${imagePath}/accessories/Glow - Synth Exhaust Eye High Road.png`;
const accChassisDetailsLowDownImage = `${imagePath}/accessories/chassis-LOW DOWN-mid.png`;
const accChassisDetailsHighRoadImage = `${imagePath}/accessories/chassis-HIGH ROAD-mid.png`;

// 1H RIGHT
const clawRight1HImage = `${imagePath}/weapons/right/Weapon Right Claw.png`;
const axeRight1HImage = `${imagePath}/weapons/right/Weapon Right Axe.png`;
const heavyAxeRight1HImage = `${imagePath}/weapons/right/Weapon Right Heavy Axe.png`;
const chainAxeRight1HImage = `${imagePath}/weapons/right/Weapon Right Chain Axe.png`;
const nBladeRight1HImage = `${imagePath}/weapons/right/Weapon Right Nanocarbon Blade.png`;
const pFistRight1HImage = `${imagePath}/weapons/right/Weapon Right Pneumatic Fist.png`;
const hcbRight1HImage = `${imagePath}/weapons/right/Weapon Right HCB.png`;
const hookRight1HImage = `${imagePath}/weapons/right/Right Weapon - Hook.png`;
// LEGENDARY
const tearRight1HImage = `${imagePath}/weapons/right/legendary/Weapon Right Tear.png`;

// 1H LEFT
const clawLeft1HImage = `${imagePath}/weapons/left/Weapon Left Claw.png`;
const axeLeft1HImage = `${imagePath}/weapons/left/Weapon Left Axe.png`;
const chainAxeLeft1HImage = `${imagePath}/weapons/left/Weapon Left Chain Axe.png`;
const pFistLeft1HImage = `${imagePath}/weapons/left/Weapon Left Pneumatic Fist.png`;
const hookLeft1HImage = `${imagePath}/weapons/left/Left Weapon - Hook.png`;
// LEGENDARY
const ripLeft1HImage = `${imagePath}/weapons/left/legendary/Weapon Left Rip.png`;
// UP
const hcbLeft1HImage = `${imagePath}/weapons/left/Weapon Left Up HCB.png`;
const greataxeLeft1HImage = `${imagePath}/weapons/left/Weapon Left Up Greataxe.png`;
const mmGreataxeLeft1HImage = `${imagePath}/weapons/left/Weapon Left Up Metalmark Greataxe.png`;
const drakesbaneLeft1HImage = `${imagePath}/weapons/left/Weapon Left Up Drakesbane.png`;
const shotgunLeft1HImage = `${imagePath}/weapons/left/Weapon Left Up Shotgun.png`;

const coreImage = `${imagePath}/Blackbeard 2.0 - by Dr. Ta13oo.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "48px",
    right: "49px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Dr. Ta13oo",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "stanceValue",
    hasMultipleStances: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "2",
        "accessory1Value": "8",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BLACKBEARD",
            src: headBlackbeardImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "HONEY BLOOD",
            src: headHoneyBloodImage,
            originalArtCredit: {
                title: "LuckyTyphlo + TheoTimeGM",
                url: "https://twitter.com/TheoTimeGM",
            },
        },
        {
            value: 3,
            label: "LYCAN",
            src: headLycanImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "MAKO",
            src: headMakoImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "TRICORN",
            src: headTricornImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "METALMARK",
            src: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MONARCH",
            src: headMonarchImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "LOW DOWN",
            src: bodyBlackbeardImage,
            frontSrc: leftArmDownImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "HIGH ROAD",
            src: bodyBlackbeardImage,
            frontSrc: leftArmUpImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "CLAW",
                src: clawLeft1HImage,
            },
            {
                value: 1,
                label: "PNEUMATIC FIST",
                src: pFistLeft1HImage,
            },
            {
                value: 2,
                label: "AXE",
                src: axeLeft1HImage,
            },
            {
                value: 3,
                label: "CHAIN AXE",
                src: chainAxeLeft1HImage,
            },
            {
                value: 4,
                label: "HOOK",
                src: hookLeft1HImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 5,
                label: "RIP",
                src: ripLeft1HImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
        "1": [
            {
                value: 0,
                label: "HCB",
                src: hcbLeft1HImage,
            },
            {
                value: 1,
                label: "GREATAXE",
                src: greataxeLeft1HImage,
            },
            {
                value: 2,
                label: "METALMARK GREATAXE",
                src: mmGreataxeLeft1HImage,
            },
            {
                value: 3,
                label: "DRAKESBANE",
                src: drakesbaneLeft1HImage,
            },
            {
                value: 4,
                label: "SHOTGUN",
                src: shotgunLeft1HImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CLAW",
            foremostSrc: clawRight1HImage,
        },
        {
            value: 1,
            label: "PNEUMATIC FIST",
            foremostSrc: pFistRight1HImage,
        },
        {
            value: 2,
            label: "NANOCARBON BLADE",
            foremostSrc: nBladeRight1HImage,
        },
        {
            value: 3,
            label: "HCB",
            foremostSrc: hcbRight1HImage,
        },
        {
            value: 4,
            label: "AXE",
            foremostSrc: axeRight1HImage,
        },
        {
            value: 5,
            label: "HEAVY AXE",
            foremostSrc: heavyAxeRight1HImage,
        },
        {
            value: 6,
            label: "CHAIN AXE",
            foremostSrc: chainAxeRight1HImage,
        },
        {
            value: 7,
            label: "HOOK",
            foremostSrc: hookRight1HImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 8,
            label: "TEAR",
            foremostSrc: tearRight1HImage,
            isPremium: true,
            isLegendary: true,
        },

    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "HCB LEFT",
            frontSrc: glowHCBLeftImage,
        },
        {
            value: 1,
            label: "HCB RIGHT",
            foremostSrc: glowHCBRightImage,
        },
        {
            value: 2,
            label: "DUAL HCBS",
            foremostSrc: glowHCBsImage,
        },
        {
            value: 3,
            label: "CHAIN AXE LEFT",
            frontSrc: glowChainAxeLeftImage,
        },
        {
            value: 4,
            label: "CHAIN AXE RIGHT",
            foremostSrc: glowChainAxeRightImage,
        },
        {
            value: 5,
            label: "DUAL CHAIN AXES",
            foremostSrc: glowChainAxesImage,
        },
        {
            value: 6,
            label: "EYE",
            frontSrc: glowEyeImage,
        },
        {
            value: 7,
            label: "HEAT SINKS",
            frontSrc: glowHeatSinksImage,
        },
        {
            value: 8,
            label: "HEAT SINKS + EYE",
            frontSrc: glowHeatSinksEyeImage,
        },
        {
            value: 9,
            label: "SYNTHETIC MUSCLE NETTING - LOW DOWN",
            frontSrc: synthMuscleDownImage,
        },
        {
            value: 10,
            label: "SYNTHETIC MUSCLE NETTING - HIGH ROAD",
            frontSrc: synthMuscleUpImage,
        },
        {
            value: 11,
            label: "SYNTH + EYE + HEAT SINKS - LOW DOWN",
            frontSrc: synthMuscleComboDownImage,
        },
        {
            value: 12,
            label: "SYNTH + EYE + HEAT SINKS - HIGH ROAD",
            frontSrc: synthMuscleComboUpImage,
        },
        {
            value: 13,
            label: "BRISTLECROWN LAUNCHERS",
            frontSrc: bristlecrownLaunchersImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 14,
            label: "COLOR - CHASSIS DETAILS - LOW DOWN",
            src: accChassisDetailsLowDownImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 15,
            label: "COLOR - CHASSIS DETAILS - HIGH ROAD",
            src: accChassisDetailsHighRoadImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "STANCE",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
