import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Jason`;

// BODY
const bodyChassisImage = `${imagePath}/Hull.png`;

// LEGS
const legsCatTracksImage = `${imagePath}/legs/CatTracks.png`;
const legsLegsImage = `${imagePath}/legs/Legs.png`;

// ARMS
// LEFT
const leftCombatSawImage = `${imagePath}/arms/CombatSaw.png`;
const leftRightArmImage = `${imagePath}/arms/RightArm.png`;
// RIGHT
const rightLeftArmImage = `${imagePath}/arms/LeftArm.png`;
const leftLavaBelcherImage = `${imagePath}/arms/LavaBelcher.png`;
const rightVictimImage = `${imagePath}/arms/Victim.png`;

// ACCESSORY
const accessoryDozerBladeImage = `${imagePath}/accessories/DozerBlade.png`;
const accessoryExcavatorBucketImage = `${imagePath}/accessories/ExcavatorBucket.png`;

// GLOWS
const glowLavaBelcherImage = `${imagePath}/glows/LavaBelcherGlow.png`;
const glowMouthImage = `${imagePath}/glows/MouthGlow.png`;
const glowSmogImage = `${imagePath}/glows/Smog.png`;
const glowVictimImage = `${imagePath}/glows/VictimGlow.png`;


const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "68px",
    right: "8px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "1",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [

    ],
    chassis: [
        {
            value: 0,
            label: "JASON",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "LoopDeLoop",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ARM",
                src: leftRightArmImage,
            },
            {
                value: 1,
                label: "COMBAT SAW",
                src: leftCombatSawImage,
            },
            {
                value: 2,
                label: "VICTIM",
                src: rightVictimImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARM",
            src: rightLeftArmImage,
        },
        {
            value: 1,
            label: "LAVA BELCHER",
            src: leftLavaBelcherImage,
        },
        {
            value: 2,
            label: "VICTIM",
            src: rightVictimImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CAT TRACKS",
            frontSrc: legsCatTracksImage,
        },
        {
            value: 1,
            label: "LEGS",
            frontSrc: legsLegsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "GLOW - LAVA BELCHER",
            frontSrc: glowLavaBelcherImage,
        },
        {
            value: 1,
            label: "GLOW - MOUTH",
            frontSrc: glowMouthImage,
        },
        {
            value: 2,
            label: "GLOW - VICTIM",
            frontSrc: glowVictimImage,
        },
        {
            value: 3,
            label: "DOZER BLADE",
            src: accessoryDozerBladeImage,
        },
        {
            value: 4,
            label: "EXCAVATOR BUCKET",
            src: accessoryExcavatorBucketImage,
        },
        {
            value: 5,
            label: "SMOG",
            src: glowSmogImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LOCOMOTION",
            value: "rearMount",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "EMPTY",
        "chassis": "CHASSIS",
        "rearMount": "LOCOMOTION",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
