import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/DFG-Envy`;

// BODY
const bodyChassisImage = `${imagePath}/Envy Chassis.png`;

// HEADS
const headSpinesDownImage = `${imagePath}/head/Back - Spines Down.png`;
const headSpinesUpImage = `${imagePath}/head/Back - Spines Up.png`;

// WINGS
// LEFT
const wingsLeftOpenImage = `${imagePath}/wings/Left Wing - Open.png`;
const wingsLeftRetractedImage = `${imagePath}/wings/Left Wing - Retracted.png`;

// RIGHT
const wingsRightOpenImage = `${imagePath}/wings/Right Wing - Open.png`;
const wingsRightRetractedImage = `${imagePath}/wings/Right Wing - Retracted.png`;

// REAR MOUNT
const rearMountFeathersImage = `${imagePath}/rear-mount/Tail - Feathers.png`;
const rearMountWhiptailImage = `${imagePath}/rear-mount/Tail - Whiptail.png`;

// GLOWS
const glowChassisImage = `${imagePath}/glows/Glow 1 - Chassis.png`;
const glowHorizontalScanImage = `${imagePath}/glows/Glow 2 - Horizontal Scan.png`;
const glowWingsArrayScanImage = `${imagePath}/glows/Glow 2 - Wing Array Scan.png`;
const glowWingsRetractedImage = `${imagePath}/glows/Glow 2 - Wings Retracted.png`;
const glowWingsRetractedBackImage = `${imagePath}/glows/Glow 2 - Wings Retracted Back.png`;
const glowWingsOpenSpikesUpImage = `${imagePath}/glows/Glow 2 - Wings Open Spikes Up.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "55px",
    right: "51px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "1",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "3",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DOWN",
            src: headSpinesDownImage,
        },
        {
            value: 1,
            label: "UP",
            src: headSpinesUpImage,
        },

    ],
    chassis: [
        {
            value: 0,
            label: "ENVY",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "OPEN",
                backSrc: wingsLeftOpenImage,
            },
            {
                value: 1,
                label: "RETRACTED",
                backSrc: wingsLeftRetractedImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "OPEN",
            src: wingsRightOpenImage,
        },
        {
            value: 1,
            label: "RETRACTED",
            src: wingsRightRetractedImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: 'FEATHERS',
            src: rearMountFeathersImage,
        },
        {
            value: 1,
            label: 'WHIPTAIL',
            src: rearMountWhiptailImage,
        },
        {
            value: 2,
            label: 'FEATHERS + WHIPTAIL',
            src: rearMountWhiptailImage,
            backSrc: rearMountFeathersImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "HORIZONTAL SCAN",
            frontSrc: glowHorizontalScanImage,
        },
        {
            value: 2,
            label: "WINGS - ARRAY SCAN",
            frontSrc: glowWingsArrayScanImage,
        },
        {
            value: 3,
            label: "WINGS - OPEN - SPINES UP",
            frontSrc: glowWingsOpenSpikesUpImage,
        },
        {
            value: 4,
            label: "WINGS - RETRACTED",
            frontSrc: glowWingsRetractedImage,
            src: glowWingsRetractedBackImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "SPINES",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "TAIL",
            value: "rearMount",
        },
        {
            label: "L WING",
            value: "weapon",
        },
        {
            label: "R WING",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "SPINES",
        "chassis": "CHASSIS",
        "rearMount": "TAIL",
        "weapon": "L WING",
        "secondaryWeapon": "R WING",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
