import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";

const imagePath = `${BaseURL}/Mechs/Custom/Gilgamesh`;

// BODY
const bodyGilgameshImage = `${imagePath}/IFD_Gilgamesh_Chassis.png`;

// HEADS
const headGilgameshImage = `${imagePath}/head/IFD_Gilgamesh_Head_Gilgamesh.png`;
const headBallisticsImage = `${imagePath}/head/IFD_Gilgamesh_Head_Ballistics.png`;
const headBiterImage = `${imagePath}/head/IFD_Gilgamesh_Head_Biter.png`;
const headMatapacosImage = `${imagePath}/head/IFD_Gilgamesh_Head_Matapacos.png`;
const headPegasusImage = `${imagePath}/head/IFD_Gilgamesh_Head_Pegasus.png`;
const headSifImage = `${imagePath}/head/IFD_Gilgamesh_Head_Sif.png`;
const headTeefImage = `${imagePath}/head/IFD_Gilgamesh_Head_Teef.png`;

// WEAPONS
// RIGHT
const rightWeaponRailGunImage = `${imagePath}/weapons/right/IFD_Gilgamesh_Right Weapon_ Rail Gun.png`;
const rightWeaponMissileLauncherSingleImage = `${imagePath}/weapons/right/IFD_Gilgamesh_Right Weapon_ Missile Launcher Single.png`;
const rightWeaponMissileLauncherDoubleImage = `${imagePath}/weapons/right/IFD_Gilgamesh_Right Weapon_ Missile Launcher Double.png`;

// LEFT
const leftWeaponRailGunImage = `${imagePath}/weapons/left/IFD_Gilgamesh_Left Weapon_ Rail Gun.png`;
const leftWeaponMissileLauncherSingleImage = `${imagePath}/weapons/left/IFD_Gilgamesh_Left Weapon_ Missile Launcher Single.png`;
const leftWeaponMissileLauncherDoubleImage = `${imagePath}/weapons/left/IFD_Gilgamesh_Left Weapon_ Missile Launcher Double.png`;


// REAR MOUNT
const rearMountWingsBackImage = `${imagePath}/rearmount/IFD_Gilgamesh_Rear Mount Back_Wings.png`;
const rearMountWingsFrontImage = `${imagePath}/rearmount/IFD_Gilgamesh_Rear Mount Front_Wings.png`;
const rearMountSiegeCannonImage = `${imagePath}/rearmount/Rear_Mount__Siege_Cannon.png`;

// GLOWS
const glowMidGlowHeadImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Mid_ Glow- Head.png`;
const glowMidLightningBodyImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Mid_ Lightning- Body.png`;
const glowMidLightningHeadImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Mid_ Lightning- Head.png`;
const glowMidGlowHeadBothImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Mid_ Glow- Head-both.png`;
const glowFrontGlowWingsBothImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories_Glow-Wings-both.png`;
const glowFrontGlowWingsImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Front_Glow- Wings.png`;
const glowFrontLightningWingsImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Front_Lightning- Wings.png`;
const glowFrontGlowRailgunRightImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Front_Glow- Railgun Left.png`;
const glowFrontGlowRailgunLeftImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Front_Glow- Railgun Right.png`;
const glowFrontGlowRailgunDualImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Front_Glow- Railgun Dual.png`;
const glowLowLightningGeneratorsActiveImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Low_Lightning Generators Active.png`;
const glowLowLightningGeneratorsDormantImage = `${imagePath}/glows/IFD_Gilgamesh_Accessories Low_Lightning Generators Dormant.png`;
const glowPlasmaTalonsImage = `${imagePath}/glows/Accessories_Plasma_Talons.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-26px",
    right: "74px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "2",
        "accessory2Value": "3",
        "accessory3Value": "6",
        "accessory4Value": "9",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "PTHALLFIRE",
            src: headGilgameshImage,
        },
        {
            value: 1,
            label: "BALLISTICS",
            src: headBallisticsImage,
        },
        {
            value: 2,
            label: "BITER",
            src: headBiterImage,
        },
        {
            value: 3,
            label: "MATAPACOS",
            src: headMatapacosImage,
        },
        {
            value: 4,
            label: "PEGASUS",
            src: headPegasusImage,
        },
        {
            value: 5,
            label: "SIF",
            src: headSifImage,
        },
        {
            value: 6,
            label: "TEEF",
            src: headTeefImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "IF-DRACOS PTHALLFIRE",
            src: bodyGilgameshImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.PINKHYENA,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "RAILGUN",
                backSrc: rightWeaponRailGunImage,
            },
            {
                value: 1,
                label: "MISSILE LAUNCHER",
                backSrc: rightWeaponMissileLauncherSingleImage,
            },
            {
                value: 2,
                label: "MISSILE LAUNCHER - DOUBLE",
                backSrc: rightWeaponMissileLauncherDoubleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "RAILGUN",
            frontSrc: leftWeaponRailGunImage,
        },
        {
            value: 1,
            label: "MISSILE LAUNCHER",
            frontSrc: leftWeaponMissileLauncherSingleImage,
        },
        {
            value: 2,
            label: "MISSILE LAUNCHER - DOUBLE",
            frontSrc: leftWeaponMissileLauncherDoubleImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "WINGS",
            foremostSrc: rearMountWingsFrontImage,
            backSrc: rearMountWingsBackImage,
        },
        {
            value: 1,
            label: "SIEGE CANNON",
            frontSrc: rearMountSiegeCannonImage,
        },
        {
            value: 2,
            label: "WINGS + SIEGE CANNON",
            foremostSrc: rearMountWingsFrontImage,
            frontSrc: rearMountSiegeCannonImage,
            backSrc: rearMountWingsBackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "HEAD",
            frontSrc: glowMidGlowHeadImage,
        },
        {
            value: 1,
            label: "HEAD - LIGHTNING",
            frontSrc: glowMidLightningHeadImage,
        },
        {
            value: 2,
            label: "HEAD + LIGHTNING",
            frontSrc: glowMidGlowHeadBothImage,
        },
        {
            value: 3,
            label: "BODY - LIGHTNING",
            frontSrc: glowMidLightningBodyImage,
        },
        {
            value: 4,
            label: "WINGS",
            foremostSrc: glowFrontGlowWingsImage,
        },
        {
            value: 5,
            label: "WINGS - LIGHTNING",
            foremostSrc: glowFrontLightningWingsImage,
        },
        {
            value: 6,
            label: "WINGS + LIGHTNING",
            foremostSrc: glowFrontGlowWingsBothImage,
        },
        {
            value: 7,
            label: "RAILGUN - LEFT",
            frontSrc: glowFrontGlowRailgunLeftImage,
        },
        {
            value: 8,
            label: "RAILGUN - RIGHT",
            frontSrc: glowFrontGlowRailgunRightImage,
        },
        {
            value: 9,
            label: "RAILGUN - DUAL",
            frontSrc: glowFrontGlowRailgunDualImage,
        },
        {
            value: 10,
            label: "LIGHTNING GENERATORS - ACTIVE",
            src: glowLowLightningGeneratorsActiveImage,
        },
        {
            value: 11,
            label: "LIGHTNING GENERATORS - DORMANT",
            src: glowLowLightningGeneratorsDormantImage,
        },
        {
            value: 12,
            label: "PLASMA TALONS",
            src: glowPlasmaTalonsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
