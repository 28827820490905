import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyRittayaImage = `${BaseURL}/Mechs/Custom/Rittaya/Chassis.png`;

// HEADS
const headRittaya1Image = `${BaseURL}/Mechs/Custom/Rittaya/heads/Head - Rittaya 1.png`;
const headRittaya2Image = `${BaseURL}/Mechs/Custom/Rittaya/heads/Head - Rittaya 2.png`;
const headBandaged2Image = `${BaseURL}/Mechs/Custom/Rittaya/heads/Head - Bandaged.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/Head - Kazu.png`;
const headLichImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/Head - Lich.png`;

// PREMIUM
const headBulkheadImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Bulkhead.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Caliban.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Cyclops.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Horned Hair.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Horned.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Malice.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Natural Law Hair.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Natural Law.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Rain Transformed Hair.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Rain Transformed.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - Visionary.png`;
const head8BallImage = `${BaseURL}/Mechs/Custom/Rittaya/heads/premium/Head - 8 Ball.png`;

// WEAPONS
const weaponsPistolImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Pistol.png`;
const weaponsHammerImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Hammer.png`;
const weaponsAxeImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Axe.png`;
const weaponsYuhawkImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Yuhawk.png`;
const weaponsBastardSwordImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Bastard Sword.png`;
const weaponsDaikatanaImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Daikatana.png`;
const weaponsPistonImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Piston.png`;
const weaponsDD288Image = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - DD288.png`;
const weaponsORBImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - O R B.png`;
const weaponsDecapitationImage = `${BaseURL}/Mechs/Custom/Rittaya/weapons/Weapon - Decapitation.png`;

// ACCESSORY
const accessoryLanternImage = `${BaseURL}/Mechs/Custom/Rittaya/accessories/Accessory - Lantern.png`;
const accessoryLanternRightImage = `${BaseURL}/Mechs/Custom/Rittaya/accessories/Accessory - Lantern Right.png`;
const accessoryCloakOverImage = `${BaseURL}/Mechs/Custom/Rittaya/accessories/Accessory - Cloak Over.png`;
const accessoryCloakUnderImage = `${BaseURL}/Mechs/Custom/Rittaya/accessories/Accessory - Cloak Under.png`;
const accessoryPlatedCloakOverImage = `${BaseURL}/Mechs/Custom/Rittaya/accessories/Accessory - Plated Cloak Over.png`;
const accessoryPlatedCloakUnderImage = `${BaseURL}/Mechs/Custom/Rittaya/accessories/Accessory - Plated Cloak Under.png`;

// GLOW
const glowBodyImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Body.png`;
const glowRittaya1Image = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Rittaya 1.png`;
const glowRittaya2Image = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Rittaya 2.png`;
const glowVisionaryImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Visionary.png`;
const glowCalibanImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Caliban.png`;
const glowBandagedImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Bandaged.png`;
const glowDD288Image = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - DD288.png`;
const glowLanternImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Lantern.png`;
const glowLanternRightImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - Lantern Right.png`;
const glowORBImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Glow - O R B.png`;
const glowTheEyesOverImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Accessory - The Eyes Over.png`;
const glowTheEyesUnderImage = `${BaseURL}/Mechs/Custom/Rittaya/glows/Accessory - The Eyes Under.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Rittaya/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Rittaya/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "21px",
    right: "44px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "4",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "9",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "RITTAYA 1",
            foremostSrc: headRittaya1Image,
        },
        {
            value: 1,
            label: "RITTAYA 2",
            foremostSrc: headRittaya2Image,
        },
        {
            value: 2,
            label: "MEMORIES OF CICERNA",
            foremostSrc: headBandaged2Image,
        },
        {
            value: 3,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 4,
            label: "LICH",
            foremostSrc: headLichImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Maboi000",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 5,
            label: "BULKHEAD",
            foremostSrc: headBulkheadImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HORNED",
            foremostSrc: headHornedImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MAG",
            foremostSrc: headMagImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "8 BALL",
            foremostSrc: head8BallImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "RITTAYA",
            src: bodyRittayaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Alrækinn, original by",
                title: "TheFaiao",
                url: "https://twitter.com/TheFaiao",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "PISTOL",
                src: weaponsPistolImage,
            },
            {
                value: 1,
                label: "CHAUSIKIAN CRUSHER",
                src: weaponsHammerImage,
            },
            {
                value: 2,
                label: "AXE",
                src: weaponsAxeImage,
            },
            {
                value: 3,
                label: "DRACULE-CLASS ZWEIHANDER",
                src: weaponsYuhawkImage,
            },
            {
                value: 4,
                label: "KENOSTIC BLADE",
                src: weaponsBastardSwordImage,
            },
            {
                value: 5,
                label: "KENOSTIC ODACHI",
                src: weaponsDaikatanaImage,
            },
            {
                value: 6,
                label: "PISTON",
                src: weaponsPistonImage,
            },
            {
                value: 7,
                label: "DD288",
                src: weaponsDD288Image,
            },
            {
                value: 8,
                label: "O R B",
                src: weaponsORBImage,
            },
            {
                value: 9,
                label: "DECAPITATION",
                src: weaponsDecapitationImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CZARNY CLOAK",
            frontSrc: accessoryCloakOverImage,
            backSrc: accessoryCloakUnderImage,
        },
        {
            value: 1,
            label: "ARMORED CZARNY CLOAK",
            frontSrc: accessoryPlatedCloakOverImage,
            backSrc: accessoryPlatedCloakUnderImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "LEFT",
            foremostSrc: accessoryLanternImage,
        },
        {
            value: 1,
            label: "RIGHT",
            foremostSrc: accessoryLanternRightImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BODY",
            src: glowBodyImage,
        },
        {
            value: 1,
            label: "RITTAYA 1 HEAD",
            foremostSrc: glowRittaya1Image,
        },
        {
            value: 2,
            label: "RITTAYA 2 HEAD",
            foremostSrc: glowRittaya2Image,
        },
        {
            value: 3,
            label: "MEMORIES OF CICERNA HEAD",
            foremostSrc: glowBandagedImage,
        },
        {
            value: 4,
            label: "CALIBAN HEAD",
            foremostSrc: glowCalibanImage,
        },
        {
            value: 5,
            label: "VISIONARY HEAD",
            foremostSrc: glowVisionaryImage,
        },
        {
            value: 6,
            label: "DD288",
            frontSrc: glowDD288Image,
        },
        {
            value: 7,
            label: "O R B",
            frontSrc: glowORBImage,
        },
        {
            value: 8,
            label: "O'ELENKTIS PLATFORM - LEFT",
            foremostSrc: glowLanternImage,
        },
        {
            value: 9,
            label: "O'ELENKTIS PLATFORM - RIGHT",
            foremostSrc: glowLanternRightImage,
        },
        {
            value: 10,
            label: "EYES OF THE MIND",
            src: glowTheEyesOverImage,
            backSrc: glowTheEyesUnderImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "CLOAK",
            value: "secondaryWeapon",
        },
        {
            label: "O'ELENKTIS PLATFORM",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "O'ELENKTIS PLATFORM",
        "weapon": "WEAPON",
        "secondaryWeapon": "CLOAK",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
