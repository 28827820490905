import BaseURL from "./images/Hosting-BaseURL";
import { removeTintValues } from "./common/settings";
import { shadowTypes } from "../styles/UnitShadow";

// BODY
const bodyMasculineImage = `${BaseURL}/Heroes/Seer/body/Body - M.png`;
const bodyFeminineImage = `${BaseURL}/Heroes/Seer/body/Body - F.png`;
const bodyLupineMasculineImage = `${BaseURL}/Heroes/Seer/body/Body - M Lupine.png`;
const bodyLupineFeminineImage = `${BaseURL}/Heroes/Seer/body/Body - F Lupine.png`;
const bodyFelineMasculineImage = `${BaseURL}/Heroes/Seer/body/Body - M Feline.png`;
const bodyFelineFeminineImage = `${BaseURL}/Heroes/Seer/body/Body - F Feline.png`;
const bodyReptileMasculineImage = `${BaseURL}/Heroes/Seer/body/Body - M Reptile.png`;
const bodyReptileFeminineImage = `${BaseURL}/Heroes/Seer/body/Body - F Reptile.png`;

// FACE
const faceMasculineImage = `${BaseURL}/Heroes/Seer/faces/Face - M.png`;
const faceFeminineImage = `${BaseURL}/Heroes/Seer/faces/Face - F.png`;
const faceElvishMasculineImage = `${BaseURL}/Heroes/Seer/faces/Face - Elvish M.png`;
const faceElvishFeminineImage = `${BaseURL}/Heroes/Seer/faces/Face - Elvish F.png`;
const faceElvishOverImage = `${BaseURL}/Heroes/Seer/faces/Face - Elvish M F Over.png`;
const faceFennecImage = `${BaseURL}/Heroes/Seer/faces/Face - Fennec.png`;
const faceFrogImage = `${BaseURL}/Heroes/Seer/faces/Face - Frog.png`;
const faceLepusImage = `${BaseURL}/Heroes/Seer/faces/Face - Lepus.png`;
const faceLupineImage = `${BaseURL}/Heroes/Seer/faces/Face - Lupine.png`;
const faceOwlImage = `${BaseURL}/Heroes/Seer/faces/Face - Owl.png`;
const faceReptileImage = `${BaseURL}/Heroes/Seer/faces/Face - Reptile.png`;
const faceVulpineImage = `${BaseURL}/Heroes/Seer/faces/Face - Vulpine.png`;
const faceXixo1Image = `${BaseURL}/Heroes/Seer/faces/Face - Xixo 1.png`;
const faceXixo2Image = `${BaseURL}/Heroes/Seer/faces/Face - Xixo 2.png`;
const faceXixo3Image = `${BaseURL}/Heroes/Seer/faces/Face - Xixo 3.png`;
const faceXixo4Image = `${BaseURL}/Heroes/Seer/faces/Face - Xixo 4.png`;
const faceCatEarsImage = `${BaseURL}/Heroes/Seer/faces/Head - Cat Ears.png`;
const faceCatEarsUnderImage = `${BaseURL}/Heroes/Seer/faces/Head - Cat Ears Under.png`;

// EYES
const eyeGlowImage = `${BaseURL}/Heroes/Seer/glows/Glow - Eye.png`;

// HAIR
const hairAfroImage = `${BaseURL}/Heroes/Seer/hair/Hair - Afro.png`;
const hairFauxhawkImage = `${BaseURL}/Heroes/Seer/hair/Hair - Faux Hawk.png`;
const hairFlowingLocksImage = `${BaseURL}/Heroes/Seer/hair/Hair - Flowing Locks.png`;
const hairGuileImage = `${BaseURL}/Heroes/Seer/hair/Hair - Guile.png`;
const hairHiroProtagonistImage = `${BaseURL}/Heroes/Seer/hair/Hair - Hiro Protagonist.png`;
const hairLongElvishImage = `${BaseURL}/Heroes/Seer/hair/Hair - Long Elvish.png`;
const hairLongWavyPartedImage = `${BaseURL}/Heroes/Seer/hair/Hair - Long Wavy Parted.png`;
const hairLongBraidUnderImage = `${BaseURL}/Heroes/Seer/hair/Head - Long Braid Under.png`;
const hairLongUnderImage = `${BaseURL}/Heroes/Seer/hair/Head - Long Hair Under.png`;
const hairMediumBackImage = `${BaseURL}/Heroes/Seer/hair/Hair - Medium Back.png`;
const hairBobbedImage = `${BaseURL}/Heroes/Seer/hair/Hair - Bobbed.png`;
const hairPonytailUnderImage = `${BaseURL}/Heroes/Seer/hair/Hair - Ponytail Under.png`;
const hairSeerImage = `${BaseURL}/Heroes/Seer/hair/Hair - Seer.png`;
const hairShortPartedImage = `${BaseURL}/Heroes/Seer/hair/Hair - Short Parted.png`;
const hairTousledImage = `${BaseURL}/Heroes/Seer/hair/Hair - Short Tousled.png`;
const hairTheMajorImage = `${BaseURL}/Heroes/Seer/hair/Hair - The Major.png`;
const hairTrogg1UnderImage = `${BaseURL}/Heroes/Seer/hair/Hair - Throgg 1 Under.png`;
const hairTroggLongImage = `${BaseURL}/Heroes/Seer/hair/Hair - Trogg Long.png`;
const hairTroggMediumImage = `${BaseURL}/Heroes/Seer/hair/Hair - Trogg Medium.png`;
const hairWildImage = `${BaseURL}/Heroes/Seer/hair/Hair - Wild.png`;

// FACIAL HAIR
const facialHairBubImage = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Bub.png`;
const facialHairHandlebarBubImage = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Handlebar Bub.png`;
const facialHairHandlebarStacheBeardImage = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Handlebar Moustache + Beard.png`;
const facialHairHandlebarStacheImage = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Handlebar Moustache.png`;
const facialHairShortBeard1Image = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Short Beard 1.png`;
const facialHairShortBeard2Image = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Short Beard 2.png`;
const facialHairMediumBeard1Image = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Medium Beard.png`;
const facialHairMediumBeard2Image = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Medium Beard Orcish.png`;
const facialHairLongBeardImage = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Long Beard.png`;
const facialHairLongGoateeImage = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Long Goatee.png`;
const facialHairLongMoustacheImage = `${BaseURL}/Heroes/Seer/hair/facial/Facial Hair - Long Moustache Beard.png`;

// HEAD
const headHorns1Image = `${BaseURL}/Heroes/Seer/head/Head - Horns 1.png`;
const headHorns2Image = `${BaseURL}/Heroes/Seer/head/Head - Horns 2.png`;
const headHorns3Image = `${BaseURL}/Heroes/Seer/head/Head - Horns 3.png`;
const headAntlersImage = `${BaseURL}/Heroes/Seer/head/Head - Antlers.png`;
const headSeerHatImage = `${BaseURL}/Heroes/Seer/head/Head - Seer Hat.png`;
const headSeerHatUnderImage = `${BaseURL}/Heroes/Seer/head/Head - Seer Hat Under.png`;
const headSeerHatHairImage = `${BaseURL}/Heroes/Seer/head/Head - Seer Hat + Hair.png`;
const headWizardHatImage = `${BaseURL}/Heroes/Seer/head/Head - Wizard Hat.png`;
const headWizardHatUnderImage = `${BaseURL}/Heroes/Seer/head/Head - Wizard Hat Under.png`;
const headCourtMagisterHoodImage = `${BaseURL}/Heroes/Seer/head/Head - Court Magister Hood.png`;
const headWeaverHoodImage = `${BaseURL}/Heroes/Seer/head/Head - Weaver Hood.png`;
const headWeaverHoodUnderImage = `${BaseURL}/Heroes/Seer/head/Head - Weaver Hood Under.png`;
const headPaintingGuardianImage = `${BaseURL}/Heroes/Seer/head/Head - Painting Guardian.png`;
const headRoningasaImage = `${BaseURL}/Heroes/Seer/head/Head - Roningasa.png`;
const headSandokasaImage = `${BaseURL}/Heroes/Seer/head/Head - Sandokasa.png`;
const headKomusoImage = `${BaseURL}/Heroes/Seer/head/Head - Komuso.png`;


// CHEST
const chestSeerRobesImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Seer Robes.png`;
const chestNoviceRobesMImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Novice Robes M.png`;
const chestNoviceRobesFImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Novice Robes F.png`;
const chestNoveRobesUnderImage = `${BaseURL}/Heroes/Seer/chest/Novice Robe Under.png`;
const chestScholarRobesImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Scholar Robes.png`;
const chestRagMageImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Rag Mage.png`;
const chestRagMageUnderImage = `${BaseURL}/Heroes/Seer/chest/Rag Mage Under.png`;
const chestLightRobesImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Light Robes.png`;
const chestCourtMagisterImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Court Magister.png`;
const chestMageCoatImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Mage Coat.png`;
const chestWeaverRobesImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Weaver Robes.png`;
const chestBadstarImage = `${BaseURL}/Heroes/Seer/chest/Outfit - Badstar.png`;

// LEGS
const legsWrapsImage = `${BaseURL}/Heroes/Seer/legs/Feet - Wraps.png`;
const legsWrapsSandalsImage = `${BaseURL}/Heroes/Seer/legs/Feet - Wraps + Sandals.png`;
const legsBoots1Image = `${BaseURL}/Heroes/Seer/legs/Feet - Low Heeled Boots.png`;
const legsBoots2Image = `${BaseURL}/Heroes/Seer/legs/Feet - Boots 2.png`;
const legsThighBootsImage = `${BaseURL}/Heroes/Seer/legs/Feet - Thigh Boots.png`;
const legsFlyKicksImage = `${BaseURL}/Heroes/Seer/legs/Feet - Fly Kicks.png`;

// LEFT HAND
const leftHandSingleCardImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Single Card.png`;
const leftHandFannedCardsImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Fanned Cards.png`;
const leftHandFlintlockImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Flintlock.png`;
const leftHandCastingHandImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Casting Hand.png`;
const leftHandCastingHand2Image = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Casting Hand 2.png`;
const leftHandGloveImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Glove.png`;
const leftHandGloveRightStaffImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Glove + Staff.png`;
const leftHandOpenSpellbookImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Open Spellbook.png`;
const leftHandGnarledStaffImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Gnarled Staff.png`;
const leftHandOrbStaffImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Orb Staff.png`;
const leftHandHelixStaffImage = `${BaseURL}/Heroes/Seer/weapons/left/Left Hand - Helix Staff.png`;

// RIGHT HAND
const rightHandSingleCardImage = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Single Card.png`;
const rightHandFannedCards1Image = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Fanned Cards 1.png`;
const rightHandFannedCards2Image = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Fanned Cards 2.png`;
const rightHandFannedCards3Image = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Fanned Cards 3.png`;
const rightHandFlintlockImage = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Flintlock.png`;
const rightHandSpellbookClosedImage = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Spellbook Closed.png`;
const rightHandSpellbookOpenImage = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Spellbook Open.png`;
const rightHandStaffGnarledImage = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Staff - Gnarled.png`;
const rightHandStaffHelixImage = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Staff - Helix.png`;
const rightHandStaffOrbImage = `${BaseURL}/Heroes/Seer/weapons/right/Right Hand - Staff - Orb.png`;

// ACCESSORIES
const accessoryNecklaceImage = `${BaseURL}/Heroes/Seer/accessories/Accessories - Necklace.png`;

// GLOWS
const glowFloatingRunesImage = `${BaseURL}/Heroes/Seer/glows/Glow - Floating Runes.png`;
const glowFloatingRunesUnderImage = `${BaseURL}/Heroes/Seer/glows/Glow - Floating Runes Under.png`;
const glowLeftCast1Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 1.png`;
const glowLeftCastSparkleImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 1 - Sparkle.png`;
const glowLeftCastOrbImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 1 - Orb.png`;
const glowLeftCastOrbArcsImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 1 - Orb Arcs.png`;
const glowLeftCastSymbol1Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 2 - Symbol 1.png`;
const glowLeftCastSymbol2Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 2 - Symbol 2.png`;
const glowLeftCastSymbol3Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 2 - Symbol 3.png`;
const glowLeftCast2Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 2.png`;
const glowLeftCast2SparkleImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 2 - Sparkle.png`;
const glowLeftCast2SparklesImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Hand Cast 2 - Sparkles.png`;
const glowLeftSingleCardImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Single Card.png`;
const glowLeftGnarledStaffImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Gnarled Staff.png`;
const glowLeftOrbStaff1Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Orb Staff 1.png`;
const glowLeftOrbStaff2Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Orb Staff 2.png`;
const glowLeftHelixStaffImage = `${BaseURL}/Heroes/Seer/glows/Glow - Left Helix Staff.png`;
const glowLeftHelixStaff2Image = `${BaseURL}/Heroes/Seer/glows/Glow - Left Helix Staff 2.png`;
const glowRightSingleCardImage = `${BaseURL}/Heroes/Seer/glows/Glow - Right Single Card.png`;
const glowRightSpellbookOpenImage = `${BaseURL}/Heroes/Seer/glows/Glow - Right Spellbook Open.png`;
const glowRightGnarledStaffImage = `${BaseURL}/Heroes/Seer/glows/Glow - Gnarled Staff.png`;
const glowRightOrbStaff1Image = `${BaseURL}/Heroes/Seer/glows/Glow -Right Orb Staff 1.png`;
const glowRightOrbStaff2Image = `${BaseURL}/Heroes/Seer/glows/Glow - Right Orb Staff 2.png`;
const glowRightHelixStaffImage = `${BaseURL}/Heroes/Seer/glows/Glow - Right Helix Staff.png`;
const glowRightHelixStaff2Image = `${BaseURL}/Heroes/Seer/glows/Glow - Right Helix Staff 2.png`;

// MAGIC CIRCLE
const magicCircleImage = `${BaseURL}/Heroes/Seer/glows/Glow - Ground - Magic Circle.png`;
const magicCircleHamsaImage = `${BaseURL}/Heroes/Seer/glows/Glow - Ground - Hamsa.png`;
const magicCircleSealOfSolomonImage = `${BaseURL}/Heroes/Seer/glows/Glow - Ground - Seal of Solomon.png`;

const previewImage = `${BaseURL}/Heroes/Seer/preview.png`;

const shadow = {
    size: shadowTypes.SMALL,
    bottom: "85px",
    right: "72px",
};

const defaultSelectionValues = {
    "skinValue": "1",
    "faceValue": "1",
    "eyesValue": "NONE",
    "hairValue": "NONE",
    "facialHairValue": "NONE",
    "headValue": "5",
    "chestValue": "0",
    "legsValue": "4",
    "leftArmValue": "0",
    "rightArmValue": "NONE",
    "leftWeaponValue": "0",
    "rightWeaponValue": "1",
    "accessory1Value": "21",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const emptySelectionValues = {
    "skinValue": "RANDOM",
    "faceValue": "RANDOM",
    "eyesValue": "NONE",
    "hairValue": "NONE",
    "facialHairValue": "NONE",
    "headValue": "NONE",
    "chestValue": "NONE",
    "legsValue": "NONE",
    "leftArmValue": "NONE",
    "rightArmValue": "NONE",
    "leftWeaponValue": "RANDOM",
    "rightWeaponValue": "RANDOM",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const defaultValues = Object.assign({}, defaultSelectionValues, removeTintValues);

export default {
    previewImg: previewImage,
    isExtraLarge: true,
    defaultValues: JSON.stringify(defaultValues),
    skin: [
        {
            value: 0,
            label: "Masculine",
            src: bodyMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 1,
            label: "Feminine",
            src: bodyFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 2,
            label: "Reptilekin Masculine",
            src: bodyReptileMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 3,
            label: "Reptilekin Feminine",
            src: bodyReptileFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 4,
            label: "Lupine Masculine",
            src: bodyLupineMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 5,
            label: "Lupine Feminine",
            src: bodyLupineFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 6,
            label: "Feline Masculine",
            src: bodyFelineMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 7,
            label: "Feline Feminine",
            src: bodyFelineFeminineImage,
            shadowDetails: shadow,
        },
    ],
    face: [
        {
            value: 0,
            label: "Masculine",
            src: faceMasculineImage,
        },
        {
            value: 1,
            label: "Feminine",
            src: faceFeminineImage,
        },
        {
            value: 2,
            label: "Elven Masculine",
            src: faceElvishMasculineImage,
            frontSrc: faceElvishOverImage,
        },
        {
            value: 3,
            label: "Elven Feminine",
            src: faceElvishFeminineImage,
            frontSrc: faceElvishOverImage,
        },
        {
            value: 4,
            label: "Feline Masculine",
            src: faceMasculineImage,
            frontSrc: faceCatEarsImage,
            backSrc: faceCatEarsUnderImage,
        },
        {
            value: 5,
            label: "Feline Feminine",
            src: faceFeminineImage,
            frontSrc: faceCatEarsImage,
            backSrc: faceCatEarsUnderImage,
        },
        {
            value: 6,
            label: "Fennec",
            src: faceFennecImage,
        },
        {
            value: 7,
            label: "Frog",
            src: faceFrogImage,
        },
        {
            value: 8,
            label: "Lepus",
            src: faceLepusImage,
        },
        {
            value: 9,
            label: "Lupine",
            src: faceLupineImage,
        },
        {
            value: 10,
            label: "Owl",
            src: faceOwlImage,
        },
        {
            value: 11,
            label: "Reptilekin",
            src: faceReptileImage,
        },
        {
            value: 12,
            label: "Vulpine",
            src: faceVulpineImage,
        },
        {
            value: 13,
            label: "Xixo 1",
            src: faceXixo1Image,
        },
        {
            value: 14,
            label: "Xixo 2",
            src: faceXixo2Image,
        },
        {
            value: 15,
            label: "Xixo 3",
            src: faceXixo3Image,
        },
        {
            value: 16,
            label: "Xixo 4",
            src: faceXixo4Image,
        },
    ],
    eyes: [
        {
            value: 0,
            label: "Glow",
            src: eyeGlowImage,
        },
    ],
    hair: [
        {
            value: 0,
            label: "Short Parted",
            src: hairShortPartedImage,
        },
        {
            value: 1,
            label: "Medium Back",
            src: hairMediumBackImage,
        },
        {
            value: 2,
            label: "Fauxhawk",
            src: hairFauxhawkImage,
        },
        {
            value: 3,
            label: "Wild",
            src: hairWildImage,
        },
        {
            value: 4,
            label: "Wild + Braid",
            src: hairWildImage,
            backSrc: hairLongBraidUnderImage,
        },
        {
            value: 5,
            label: "Afro",
            src: hairAfroImage,
        },
        {
            value: 6,
            label: "Flowing Locks",
            src: hairFlowingLocksImage,
        },
        {
            value: 7,
            label: "Bobbed",
            src: hairBobbedImage,
        },
        {
            value: 8,
            label: "Bobbed + Ponytail",
            src: hairBobbedImage,
            backSrc: hairPonytailUnderImage,
        },
        {
            value: 9,
            label: "Long Elvish",
            src: hairLongElvishImage,
            backSrc: hairLongUnderImage,
        },
        {
            value: 10,
            label: "Long Elvish Braid",
            src: hairLongElvishImage,
            backSrc: hairLongBraidUnderImage,
        },
        {
            value: 11,
            label: "Long Wavy Parted",
            src: hairLongWavyPartedImage,
            backSrc: hairLongUnderImage,
        },
        {
            value: 12,
            label: "Tousled",
            src: hairTousledImage,
        },
        {
            value: 13,
            label: "Guile",
            src: hairGuileImage,
        },
        {
            value: 14,
            label: "The Major",
            src: hairTheMajorImage,
        },
        {
            value: 15,
            label: "The Major + Ponytail",
            src: hairTheMajorImage,
            backSrc: hairPonytailUnderImage,
        },
        {
            value: 16,
            label: "Hiro Protagonist",
            src: hairHiroProtagonistImage,
        },
        {
            value: 17,
            label: "Seer",
            src: hairSeerImage,
        },
        {
            value: 18,
            label: "Trogg Medium",
            src: hairTroggMediumImage,
        },
        {
            value: 19,
            label: "Trogg Long",
            src: hairTroggLongImage,
            backSrc: hairTrogg1UnderImage,
        },
    ],
    facialHair: [
        {
            value: 0,
            label: "Bub",
            src: facialHairBubImage,
        },
        {
            value: 1,
            label: "Handlebar Stache",
            src: facialHairHandlebarStacheImage,
        },
        {
            value: 2,
            label: "Handlebar + Bub",
            src: facialHairHandlebarBubImage,
        },
        {
            value: 3,
            label: "Handlebar + Beard",
            src: facialHairHandlebarStacheBeardImage,
        },
        {
            value: 4,
            label: "Short Beard 1",
            frontSrc: facialHairShortBeard1Image,
        },
        {
            value: 5,
            label: "Short Beard 2",
            frontSrc: facialHairShortBeard2Image,
        },
        {
            value: 6,
            label: "Medium Beard 1",
            src: facialHairMediumBeard1Image,
        },
        {
            value: 7,
            label: "Medium Beard 2",
            src: facialHairMediumBeard2Image,
        },
        {
            value: 8,
            label: "Long Goatee",
            src: facialHairLongGoateeImage,
        },
        {
            value: 9,
            label: "Long Stache",
            src: facialHairLongMoustacheImage,
        },
        {
            value: 10,
            label: "Long Beard 1",
            src: facialHairLongBeardImage,
        },
        {
            value: 11,
            label: "Long Beard 2",
            frontSrc: facialHairLongMoustacheImage,
            src: facialHairLongGoateeImage,
        },
    ],
    head: [
        {
            value: 0,
            label: "Horns 1",
            src: headHorns1Image,
        },
        {
            value: 1,
            label: "Horns 2",
            src: headHorns2Image,
        },
        {
            value: 2,
            label: "Horns 3",
            src: headHorns3Image,
        },
        {
            value: 3,
            label: "Antlers",
            src: headAntlersImage,
        },
        {
            value: 4,
            label: "Seer Hat",
            src: headSeerHatImage,
            backSrc: headSeerHatUnderImage,
        },
        {
            value: 5,
            label: "Seer Hat + Hair",
            src: headSeerHatHairImage,
            backSrc: headSeerHatUnderImage,
        },
        {
            value: 6,
            label: "Wizard Hat",
            src: headWizardHatImage,
            backSrc: headWizardHatUnderImage,
        },
        {
            value: 7,
            label: "Painting Guardian",
            src: headPaintingGuardianImage,
        },
        {
            value: 8,
            label: "Komuso",
            src: headKomusoImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "Roningasa",
            src: headRoningasaImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "Sandokasa",
            src: headSandokasaImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "Court Magister Hood",
            src: headCourtMagisterHoodImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 12,
            label: "Weaver Hood",
            src: headWeaverHoodImage,
            backSrc: headWeaverHoodUnderImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chest: [
        {
            value: 0,
            label: "Seer Robes",
            src: chestSeerRobesImage,
        },
        {
            value: 1,
            label: "Novice Robes Feminine",
            src: chestNoviceRobesFImage,
            backSrc: chestNoveRobesUnderImage,
        },
        {
            value: 2,
            label: "Novice Robes Masculine",
            src: chestNoviceRobesMImage,
            backSrc: chestNoveRobesUnderImage,
        },
        {
            value: 3,
            label: "Mage Coat",
            src: chestMageCoatImage,
        },
        {
            value: 4,
            label: "Geffen Mage",
            src: chestRagMageImage,
            backSrc: chestRagMageUnderImage,
        },
        {
            value: 5,
            label: "Scholar Robes",
            src: chestScholarRobesImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "Light Robes",
            src: chestLightRobesImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "Weaver Robes",
            src: chestWeaverRobesImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "Court Magister",
            src: chestCourtMagisterImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 9,
            label: "Badstar",
            src: chestBadstarImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    legs: [
        {
            value: 0,
            label: "Wraps",
            src: legsWrapsImage,
        },
        {
            value: 1,
            label: "Wraps + Sandals",
            src: legsWrapsSandalsImage,
        },
        {
            value: 2,
            label: "Boots 1",
            src: legsBoots1Image,
        },
        {
            value: 3,
            label: "Boots 2",
            src: legsBoots2Image,
        },
        {
            value: 4,
            label: "Thigh Boots",
            src: legsThighBootsImage,
        },
        {
            value: 5,
            label: "Fly Kicks",
            src: legsFlyKicksImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    leftArm: [
        {
            value: 0,
            label: "Necklace",
            src: accessoryNecklaceImage,
        },
    ],
    rightArm: [
        {
            value: 0,
            label: "Magic Circle",
            backSrc: magicCircleImage,
        },
        {
            value: 1,
            label: "Seal of Solomon",
            backSrc: magicCircleSealOfSolomonImage,
        },
        {
            value: 2,
            label: "Hamsa",
            backSrc: magicCircleHamsaImage,
        },
    ],
    leftWeapon: [
        {
            value: 0,
            label: "Single Card",
            src: leftHandSingleCardImage,
        },
        {
            value: 1,
            label: "Fanned Cards",
            src: leftHandFannedCardsImage,
        },
        {
            value: 2,
            label: "Flintlock",
            src: leftHandFlintlockImage,
        },
        {
            value: 3,
            label: "Casting Hand 1",
            src: leftHandCastingHandImage,
        },
        {
            value: 4,
            label: "Casting Hand 2",
            src: leftHandCastingHand2Image,
        },
        {
            value: 5,
            label: "Glove",
            src: leftHandGloveImage,
        },
        {
            value: 6,
            label: "Gloved + Right Staff",
            src: leftHandGloveRightStaffImage,
        },
        {
            value: 7,
            label: "Open Spellbook",
            src: leftHandOpenSpellbookImage,
        },
        {
            value: 8,
            label: "Gnarled Staff",
            src: leftHandGnarledStaffImage,
        },
        {
            value: 9,
            label: "Helix Staff",
            src: leftHandHelixStaffImage,
        },
        {
            value: 10,
            label: "Orb Staff",
            src: leftHandOrbStaffImage,
        },
    ],
    rightWeapon: [
        {
            value: 0,
            label: "Single Card",
            foremostSrc: rightHandSingleCardImage,
        },
        {
            value: 1,
            label: "Fanned Cards 1",
            foremostSrc: rightHandFannedCards1Image,
        },
        {
            value: 2,
            label: "Fanned Cards 2",
            foremostSrc: rightHandFannedCards2Image,
        },
        {
            value: 3,
            label: "Fanned Cards 3",
            foremostSrc: rightHandFannedCards3Image,
        },
        {
            value: 4,
            label: "Flintlock",
            foremostSrc: rightHandFlintlockImage,
        },
        {
            value: 5,
            label: "Spellbook Closed",
            foremostSrc: rightHandSpellbookClosedImage,
        },
        {
            value: 6,
            label: "Spellbook Open",
            foremostSrc: rightHandSpellbookOpenImage,
        },
        {
            value: 7,
            label: "Gnarled Staff",
            foremostSrc: rightHandStaffGnarledImage,
        },
        {
            value: 8,
            label: "Helix Staff",
            foremostSrc: rightHandStaffHelixImage,
        },
        {
            value: 9,
            label: "Orb Staff",
            foremostSrc: rightHandStaffOrbImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "Casting 1 - Left",
            frontSrc: glowLeftCast1Image,
        },
        {
            value: 1,
            label: "Casting 1 Sparkle - Left",
            frontSrc: glowLeftCastSparkleImage,
        },
        {
            value: 2,
            label: "Casting 1 Orb - Left",
            frontSrc: glowLeftCastOrbImage,
        },
        {
            value: 3,
            label: "Casting 1 Orb Arcs - Left",
            frontSrc: glowLeftCastOrbArcsImage,
        },
        {
            value: 4,
            label: "Casting 2 - Left",
            frontSrc: glowLeftCast2Image,
        },
        {
            value: 5,
            label: "Casting 2 Sparkle - Left",
            frontSrc: glowLeftCast2SparkleImage,
        },
        {
            value: 6,
            label: "Casting 2 Sparkles - Left",
            frontSrc: glowLeftCast2SparklesImage,
        },
        {
            value: 7,
            label: "Casting 2 Symbol 1 - Left",
            frontSrc: glowLeftCastSymbol1Image,
        },
        {
            value: 8,
            label: "Casting 2 Symbol 2 - Left",
            frontSrc: glowLeftCastSymbol2Image,
        },
        {
            value: 9,
            label: "Casting 2 Symbol 3 - Left",
            frontSrc: glowLeftCastSymbol3Image,
        },
        {
            value: 10,
            label: "Floating Runes",
            frontSrc: glowFloatingRunesImage,
            backSrc: glowFloatingRunesUnderImage,
        },
        {
            value: 11,
            label: "Gnarled Staff - Left",
            frontSrc: glowLeftGnarledStaffImage,
        },
        {
            value: 12,
            label: "Gnarled Staff - Right",
            frontSrc: glowRightGnarledStaffImage,
        },
        {
            value: 13,
            label: "Helix Staff - Left",
            frontSrc: glowLeftHelixStaffImage,
        },
        {
            value: 14,
            label: "Helix Staff 2 - Left",
            frontSrc: glowLeftHelixStaff2Image,
        },
        {
            value: 15,
            label: "Helix Staff - Right",
            frontSrc: glowRightHelixStaffImage,
        },
        {
            value: 16,
            label: "Helix Staff 2 - Right",
            frontSrc: glowRightHelixStaff2Image,
        },
        {
            value: 17,
            label: "Orb Staff 1 - Left",
            frontSrc: glowLeftOrbStaff1Image,
        },
        {
            value: 18,
            label: "Orb Staff 2 - Left",
            frontSrc: glowLeftOrbStaff2Image,
        },
        {
            value: 19,
            label: "Orb Staff 1 - Right",
            frontSrc: glowRightOrbStaff1Image,
        },
        {
            value: 20,
            label: "Orb Staff 2 - Right",
            frontSrc: glowRightOrbStaff2Image,
        },
        {
            value: 21,
            label: "Single Card - Left",
            frontSrc: glowLeftSingleCardImage,
        },
        {
            value: 22,
            label: "Single Card - Right",
            frontSrc: glowRightSingleCardImage,
        },
        {
            value: 23,
            label: "Spellbook Open - Right",
            frontSrc: glowRightSpellbookOpenImage,
        },
    ],
    partList: [
        {
            label: "All",
            value: "ALL",
        },
        {
            label: "Skin Tone",
            value: "BODY",
        },
        {
            label: "Armor",
            value: "ARMOR",
        },
        {
            label: "Weapons",
            value: "WEAPONS",
        },
        {
            label: "All Hair",
            value: "ALLHAIR",
        },
        {
            label: "Glows",
            value: "ACCESSORIES",
        },
        {
            label: "Body",
            value: "skin",
        },
        {
            label: "Face",
            value: "face",
        },
        {
            label: "Eyes",
            value: "eyes",
            isGlow: true,
        },
        {
            label: "Hair",
            value: "hair",
        },
        {
            label: "Facial Hair",
            value: "facialHair",
        },
        {
            label: "Head",
            value: "head",
        },
        {
            label: "Chest",
            value: "chest",
        },
        {
            label: "Legs",
            value: "legs",
        },
        {
            label: "Accessory",
            value: "leftArm",
        },
        {
            label: "Magic Circle",
            value: "rightArm",
            isGlow: true,
        },
        {
            label: "Left Hand",
            value: "leftWeapon",
        },
        {
            label: "Right Hand",
            value: "rightWeapon",
        },
        {
            label: "Glow 1",
            value: "accessory1",
        },
        {
            label: "Glow 2",
            value: "accessory2",
        },
        {
            label: "Glow 3",
            value: "accessory3",
        },
        {
            label: "Glow 4",
            value: "accessory4",
        },
    ],
    labels: {
        "skin": "Body",
        "face": "Face",
        "eyes": "Eyes",
        "hair": "Hair",
        "facialHair": "Facial Hair",
        "head": "Head",
        "chest": "Chest",
        "legs": "Legs",
        "leftArm": "Accessory",
        "rightArm": "Magic Circle",
        "leftWeapon": "Left Hand",
        "rightWeapon": "Right Hand",
        "accessory1": "Glow 1",
        "accessory2": "Glow 2",
        "accessory3": "Glow 3",
        "accessory4": "Glow 4",
    },
    templateList: [
        {
            label: "Buff",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                },
            )),
        },
        {
            label: "Novice F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "faceValue": "1",
                    "headValue": "6",
                    "chestValue": "1",
                    "legsValue": "3",
                },
            )),
        },
        {
            label: "Novice M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "faceValue": "0",
                    "headValue": "6",
                    "chestValue": "2",
                    "legsValue": "3",
                },
            )),
        },
        {
            label: "Zealous Prince",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "skinValue": "0",
                    "faceValue": "2",
                    "hairValue": "9",
                    "chestValue": "4",
                    "legsValue": "4",
                    "leftWeaponValue": "5",
                    "rightWeaponValue": "7",
                },
            )),
        },
        {
            label: "Scholar",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "faceValue": "0",
                    "headValue": "6",
                    "chestValue": "3",
                    "legsValue": "4",
                    "leftWeaponValue": "7",
                    "rightWeaponValue": "9",
                },
            )),
        },
        {
            label: "Shrine Keeper",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "faceValue": "RANDOM",
                    "headValue": "10",
                    "chestValue": "6",
                    "legsValue": "2",
                    "leftWeaponValue": "4",
                    "rightWeaponValue": "7",
                },
            )),
        },
        {
            label: "Weaver",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "faceValue": "0",
                    "headValue": "12",
                    "chestValue": "7",
                    "legsValue": "4",
                    "leftWeaponValue": "0",
                    "rightWeaponValue": "2",
                },
            )),
        },
        {
            label: "Court Magister",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "faceValue": "0",
                    "headValue": "11",
                    "chestValue": "8",
                    "legsValue": "3",
                    "leftWeaponValue": "3",
                    "rightWeaponValue": "8",
                },
            )),
        },
    ],
};
