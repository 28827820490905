import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/Chassis.png`;

// HEADS
const headCambiareImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Cambiare.png`;
const headBastionImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Bastion.png`;
const headBlackspotImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Blackspot.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Caliban.png`;
const headCheddahImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Cheddah.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Chimera.png`;
const headCockpitImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Cockpit.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Cyclops.png`;
const headDungamImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Dungam.png`;
const headEnkiduImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Enkidu.png`;
const headHazardImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Hazard.png`;
const headHoodImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Hood.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Horned Hair.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Kazu.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Malice.png`;
const headMecha1Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 1.png`;
const headMecha2Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 2.png`;
const headMecha3Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 3.png`;
const headMecha4Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 4.png`;
const headMecha5Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 5.png`;
const headMecha6Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 6.png`;
const headMecha7Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 7.png`;
const headMecha8Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Nelson.png`;
const headNelson2Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Nelson 2.png`;
const headNelsonFTImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Nelson Flight Type.png`;
const headNemesisImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Nemesis.png`;
const headRainImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Rain.png`;
const headRainHairImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Rain Hair.png`;
const headSkullImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Skull 3.png`;
const headSlipstreamImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Slipstream.png`;
const headTokugawaImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Vanquish.png`;
const headViceroyImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Viceroy.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - Visionary.png`;
const head8BallImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/heads/Head - 8 Ball.png`;

// WEAPONS
// LEFT
const weaponsLeftHandImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/weapons/left/Left Arm - Hand.png`;
const weaponsLeftARImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/weapons/left/Left Arm - AR.png`;
const weaponsLeftBladeImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/weapons/left/Left Arm - Blade.png`;
// RIGHT
const weaponsRightHandImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/weapons/right/Right Arm - Hand.png`;
const weaponsRightARImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/weapons/right/Right Arm - AR.png`;
const weaponsRightBladeImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/weapons/right/Right Arm - Blade.png`;

// ACCESSORIES
const accessoryLeftShoulderClosedImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Left Shoulder - Closed.png`;
const accessoryLeftShoulderMicromissilesImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Left Shoulder - Micromissiles.png`;
const accessoryLeftShoulderMissiles1Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Left Shoulder - Missiles.png`;
const accessoryLeftShoulderMissiles2Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Left Shoulder - Missiles 2.png`;
const accessoryLeftShoulderRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Left Shoulder - Railgun.png`;
const accessoryRightShoulderClosedImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Right Shoulder - Closed.png`;
const accessoryRightShoulderMicromissilesImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Right Shoulder - Micromissiles.png`;
const accessoryRightShoulderMissiles1Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Right Shoulder - Missiles.png`;
const accessoryRightShoulderMissiles2Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Right Shoulder - Missiles 2.png`;
const accessoryRightShoulderRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Right Shoulder - Railgun.png`;
const accessoryRightShoulderLargeRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Right Shoulder - Large Railgun.png`;
const accessoryLeftShoulderLargeRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/accessories/Left Shoulder - Large Railgun.png`;

// REAR MOUNT
const rearMountTargettingSystemImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Targetting System.png`;
const rearMountMissilePod1UnderImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Missile Pod 1 under.png`;
const rearMountMissilePod1Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Missile Pod 1.png`;
const rearMountMissilePod2UnderImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Missile Pod 2 under.png`;
const rearMountMissilePod2Image = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Missile Pod 2.png`;
const rearMountMissileOlothontorUnderImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Missiles - Olothontor Under.png`;
const rearMountMissileOlothontorImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Missiles - Olothontor.png`;
const rearMountLargeMissileUnderImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Large Missiles Under.png`;
const rearMountLargeMissileImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/rear mount/Rear - Large Missiles.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Cambiare Biped/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "0px",
    right: "-10px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "6",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CAMBIARE",
            src: headCambiareImage,
        },
        {
            value: 1,
            label: "BASTION",
            src: headBastionImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 6,
            label: "COCKPIT",
            src: headCockpitImage,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 10,
            label: "HAZARD",
            src: headHazardImage,
        },
        {
            value: 11,
            label: "HOOD",
            src: headHoodImage,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 15,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 16,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 17,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 18,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 19,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 20,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 21,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 22,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 23,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 24,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 25,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 26,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 27,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 28,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 29,
            label: "NELSON 2",
            src: headNelson2Image,
        },
        {
            value: 30,
            label: "NELSON FT",
            src: headNelsonFTImage,
        },
        {
            value: 31,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 32,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 33,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 34,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 35,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
        },
        {
            value: 36,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 37,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 38,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 39,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 40,
            label: "8 BALL",
            src: head8BallImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CAMBIARE BIPED",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Tamrielo",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                src: weaponsLeftHandImage,
            },
            {
                value: 1,
                label: "AR",
                src: weaponsLeftARImage,
            },
            {
                value: 2,
                label: "BLADE",
                src: weaponsLeftBladeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            foremostSrc: weaponsRightHandImage,
        },
        {
            value: 1,
            label: "AR",
            foremostSrc: weaponsRightARImage,
        },
        {
            value: 2,
            label: "BLADE",
            foremostSrc: weaponsRightBladeImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "TARGETTING SYSTEM",
            foremostSrc: rearMountTargettingSystemImage,
        },
        {
            value: 1,
            label: "MISSILE POD 1",
            foremostSrc: rearMountMissilePod1Image,
            backSrc: rearMountMissilePod1UnderImage,
        },
        {
            value: 2,
            label: "MISSILE POD 2",
            foremostSrc: rearMountMissilePod2Image,
            backSrc: rearMountMissilePod2UnderImage,
        },
        {
            value: 3,
            label: "MISSILES - OLOTHONTOR",
            foremostSrc: rearMountMissileOlothontorImage,
            backSrc: rearMountMissileOlothontorUnderImage,
        },
        {
            value: 4,
            label: "LARGE MISSILES",
            foremostSrc: rearMountLargeMissileImage,
            backSrc: rearMountLargeMissileUnderImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CLOSED - LEFT SHOULDER",
            src: accessoryLeftShoulderClosedImage,
        },
        {
            value: 1,
            label: "MICROMISSILES - LEFT SHOULDER",
            src: accessoryLeftShoulderMicromissilesImage,
        },
        {
            value: 2,
            label: "MISSILES 1 - LEFT SHOULDER",
            src: accessoryLeftShoulderMissiles1Image,
        },
        {
            value: 3,
            label: "MISSILES 2 - LEFT SHOULDER",
            src: accessoryLeftShoulderMissiles2Image,
        },
        {
            value: 4,
            label: "CANNON - LEFT SHOULDER",
            src: accessoryLeftShoulderRailgunImage,
        },
        {
            value: 5,
            label: "RAILGUN - LEFT SHOULDER",
            src: accessoryLeftShoulderLargeRailgunImage,
        },
        {
            value: 6,
            label: "CLOSED - RIGHT SHOULDER",
            frontSrc: accessoryRightShoulderClosedImage,
        },
        {
            value: 7,
            label: "MICROMISSILES - RIGHT SHOULDER",
            frontSrc: accessoryRightShoulderMicromissilesImage,
        },
        {
            value: 8,
            label: "MISSILES 1 - RIGHT SHOULDER",
            frontSrc: accessoryRightShoulderMissiles1Image,
        },
        {
            value: 9,
            label: "MISSILES 2 - RIGHT SHOULDER",
            frontSrc: accessoryRightShoulderMissiles2Image,
        },
        {
            value: 10,
            label: "CANNON - RIGHT SHOULDER",
            frontSrc: accessoryRightShoulderRailgunImage,
        },
        {
            value: 11,
            label: "RAILGUN - RIGHT SHOULDER",
            frontSrc: accessoryRightShoulderLargeRailgunImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "SHOULDERS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "ARMS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "L SHOULDER",
            value: "accessory1",
        },
        {
            label: "R SHOULDER",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "L SHOULDER",
        "accessory2": "R SHOULDER",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
