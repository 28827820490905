import React, { useContext, useState } from "react";
import { array, func, object } from "prop-types";

import { AnalyticsContext } from "./../../Analytics/AnalyticsContextWrapper";
import { UserAuthContext } from "./../../User/UserAuthContextWrapper";
import { UnitContext } from "./../UnitContextWrapper";

import Unit from "./../styles/Unit";
import UnitImage from "./../styles/UnitImage";
import UnitVariantControl from "./../styles/UnitVariantControl";
import UnitVariantIndicator from "./../styles/UnitVariantIndicator";
import { UnitDualCenterButton } from "./../styles/UnitCenterButton";
import ExportFantasyButton from "./ExportFantasyButton";
import { safeName } from "./../Print/PrintAnalytics";
import Loader from "./components/Loader";

import {
    defaultColor,
} from "./../Category/Heroes/components/FantasyHeroCategoryStyles";
import { premiumTiersList } from "./PremiumPopup";
import { SPRITE_KEYS } from "../../common/styles/SpriteImage";

function UnitCategoryDisplay({ setShowPremiumPopupFunc, shadowDetails, variantsArray }) {
    const [currentVariant, setCurrentVariant] = useState(0);
    const [imageLoading, setImageLoading] = useState(true);

    const ReactGA = useContext(AnalyticsContext);
    const { user } = useContext(UserAuthContext);
    const { addUnit } = useContext(UnitContext);

    const isPremiumUser = user && user.isPremiumUser;
    const isLegendaryUser = user && user.isLegendaryUser;

    const hasVariants = variantsArray.length > 1;

    let unitImageSrc = variantsArray[currentVariant].src;

    if (variantsArray[currentVariant].legendary) {
        if (!isLegendaryUser) {
            unitImageSrc = variantsArray[currentVariant].previewSrc;
        }
    } else if (variantsArray[currentVariant].previewSrc) {
        if (!isPremiumUser) {
            unitImageSrc = variantsArray[currentVariant].previewSrc;
        }
    }

    const leftVariantFunction = () => (
        setCurrentVariant(currentVariant === 0 ? variantsArray.length - 1 : currentVariant - 1)
    );

    const rightVariantFunction = () => (
        setCurrentVariant(currentVariant + 1 === variantsArray.length ? 0 : currentVariant + 1)
    );

    const addUnitFunction = () => {
        ReactGA.event({
            category: "Unit",
            action: "Add Unit",
            label: `${variantsArray[currentVariant].filePrefix} ${safeName(variantsArray[currentVariant].unitNumber)} ${variantsArray[currentVariant].variant}`
        });

        if (variantsArray[currentVariant].legendary) {
            if (isLegendaryUser) {
                return addUnit(Object.assign({ variantsArray, currentVariant, id: `${currentVariant}-${Date.now()}`, shadowDetails }));
            } else {
                return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
            }
        } else if (variantsArray[currentVariant].previewSrc) {
            if (isPremiumUser) {
                return addUnit(Object.assign({ variantsArray, currentVariant, id: `${currentVariant}-${Date.now()}`, shadowDetails }));
            } else {
                return setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE);
            }
        } else {
            return addUnit(Object.assign({ variantsArray, currentVariant, id: `${currentVariant}-${Date.now()}`, shadowDetails }));
        }
    };

    return (
        <Unit
            onContextMenu={(e) => e.preventDefault()}
            isExtraLarge={variantsArray[currentVariant].isExtraLarge}
        >
            <UnitVariantIndicator
                currentVariant={currentVariant}
                isPremium={!!variantsArray[currentVariant].previewSrc}
                isLegendary={variantsArray[currentVariant].legendary}
                numVariants={variantsArray.length}
                unitID={`${variantsArray[currentVariant].filePrefix}${variantsArray[currentVariant].unitNumber}-${variantsArray[currentVariant].variant}`}
            />
            <UnitVariantControl
                alt="Previous Variant"
                className={SPRITE_KEYS.lArrow}
                onClick={hasVariants ? leftVariantFunction : null}
                displayControl={hasVariants}
            />
            <UnitImage
                src={unitImageSrc}
                alt={`${variantsArray[currentVariant].filePrefix}${variantsArray[currentVariant].unitNumber}-${variantsArray[currentVariant].variant}`}
                onLoad={() => setImageLoading(false)}
            />
            {imageLoading ?
                <Loader pixelColor={defaultColor} /> : null
            }
            <UnitVariantControl
                alt="Next Variant"
                className={SPRITE_KEYS.rArrow}
                onClick={hasVariants ? rightVariantFunction : null}
                displayControl={hasVariants}
            />
            <UnitDualCenterButton
                alt="Add Unit Button"
                title="Add Unit To Print List"
                className={SPRITE_KEYS.plusButton}
                onClick={addUnitFunction}
                displayControl
                isFirst
            />
            <ExportFantasyButton
                unit={variantsArray[currentVariant]}
                setShowPremiumPopupFunc={setShowPremiumPopupFunc}
            />
        </Unit>
    );
}

UnitCategoryDisplay.propTypes = {
    setShowPremiumPopupFunc: func.isRequired,
    shadowDetails: object.isRequired,
    variantsArray: array.isRequired,
};

export default UnitCategoryDisplay;
