import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const naniteCloudGlowImage = `${BaseURL}/Mechs/NPC/Corpro/Lurker/glows/Glow - Nanite Cloud.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: naniteCloudGlowImage,
    rearMountsAreChassis: true,
    defaultValues: `{
    "chassisValue": "0",
    "headValue": "NONE",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "NANITE CLOUD",
            src: naniteCloudGlowImage,
            shadowDetails: shadowDetails,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "NANITES",
            value: "chassis",
            isGlow: true,
        },
    ],
    labels: {
        "head": "EMPTY",
        "chassis": "NANITES",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
