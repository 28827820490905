import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyTreadImage = `${BaseURL}/Mechs/HA/Orcu/Chassis - Tread Mode.png`;
const bodySiegeImage = `${BaseURL}/Mechs/HA/Orcu/Chassis - Siege Mode.png`;

// WEAPON
const assaultRiflesImage = `${BaseURL}/Mechs/HA/Orcu/Assault Rifles.png`;
// LEFT
const weaponLeftSiegeCannonImage = `${BaseURL}/Mechs/HA/Orcu/weapons/left/Left Weapon - Siege Cannon.png`;
const weaponLeftApocRailUnchargedImage = `${BaseURL}/Mechs/HA/Orcu/weapons/left/Left Weapon - Apocalypse Rail Uncharged.png`;
const weaponLeftApocRailChargedImage = `${BaseURL}/Mechs/HA/Orcu/weapons/left/Left Weapon - Apocalypse Rail Charged.png`;

// RIGHT
const weaponRightSiegeCannonImage = `${BaseURL}/Mechs/HA/Orcu/weapons/right/Right Weapon - Siege Cannon.png`;
const weaponRightApocRailUnchargedImage = `${BaseURL}/Mechs/HA/Orcu/weapons/right/Right Weapon - Apocalypse Rail Uncharged.png`;
const weaponRightApocRailChargedImage = `${BaseURL}/Mechs/HA/Orcu/weapons/right/Right Weapon - Apocalypse Rail Charged.png`;

// ACCESSORY
const glowApocalypseRailChargedLeftImage = `${BaseURL}/Mechs/HA/Orcu/glow/Glow - Left Apocalypse Rail Charged.png`;
const glowApocalypseRailChargedRightImage = `${BaseURL}/Mechs/HA/Orcu/glow/Glow - Right Apocalypse Rail Charged.png`;

const previewImage = `${BaseURL}/Mechs/HA/Orcu/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/HA/Orcu/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "74px",
    right: "12px",
};

export default {
    restrictedPreviewImg: restrictedPreviewImage,
    previewImg: previewImage,
    rearMountsAreChassis: true,
    isMegadeus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "1",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ASSAULT RIFLES",
            src: assaultRiflesImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TREAD MODE",
            src: bodyTreadImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "SIEGE MODE",
            src: bodySiegeImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SIEGE CANNON",
                foremostSrc: weaponLeftSiegeCannonImage,
                isPremium: true,
                isLegendary: true,
            },
            {
                value: 1,
                label: "APOCALYPSE RAIL - UNCHARGED",
                foremostSrc: weaponLeftApocRailUnchargedImage,
                isPremium: true,
                isLegendary: true,
            },
            {
                value: 2,
                label: "APOCALYPSE RAIL - CHARGED",
                foremostSrc: weaponLeftApocRailChargedImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SIEGE CANNON",
            src: weaponRightSiegeCannonImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "APOCALYPSE RAIL - UNCHARGED",
            src: weaponRightApocRailUnchargedImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "APOCALYPSE RAIL - CHARGED",
            src: weaponRightApocRailChargedImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "APOCAPLYSE RAIL CHARGED - LEFT",
            foremostSrc: glowApocalypseRailChargedLeftImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "APOCAPLYSE RAIL CHARGED - RIGHT",
            frontSrc: glowApocalypseRailChargedRightImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "SMALL ARMS",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "LEFT MOUNT",
            value: "weapon",
        },
        {
            label: "RIGHT MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "SMALL ARMS",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT MOUNT",
        "secondaryWeapon": "RIGHT MOUNT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
