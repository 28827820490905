import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyBalorAggreImage = `${BaseURL}/Mechs/Horus/Balor/Balor - Aggregant.png`;

// HEADS
const headGlowImage = `${BaseURL}/Mechs/Horus/Balor/Balor - Aggregant Glow.png`;
const headGlowCloudsImage = `${BaseURL}/Mechs/Horus/Balor/Balor - Aggregant Glow Clouds.png`;

const previewImage = `${BaseURL}/Mechs/Horus/Balor/preview-aggregant.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Horus/Balor/preview-aggregant-restricted.png`;

const hoverShadow = {
    size: shadowTypes.LARGE,
    bottom: "27px",
    right: "35px",
};

export default {
    restrictedPreviewImg: restrictedPreviewImage,
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "1",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "AGGREGANT",
            src: bodyBalorAggreImage,
            shadowDetails: hoverShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "GLOW",
            src: headGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "NANITE SWARM",
            src: headGlowCloudsImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "MANIFESTATION",
            value: "chassis",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "MANIFESTATION",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
