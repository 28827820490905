import HeroesRelictsArchivist from "./Heroes-Relicts-Archivist";
import HeroesRelictsEmbalmer from "./Heroes-Relicts-Embalmer";
import HeroesRelictsExecutioner from "./Heroes-Relicts-Executioner";
import HeroesRelictsGrafter from "./Heroes-Relicts-Grafter";
import HeroesRelictsHeliolite from "./Heroes-Relicts-Heliolite";
import HeroesRelictsHusk1 from "./Heroes-Relicts-Husk-1";
import HeroesRelictsHusk2 from "./Heroes-Relicts-Husk-2";
import HeroesRelictsImmortalist from "./Heroes-Relicts-Immortalist";
import HeroesRelictsLegionary from "./Heroes-Relicts-Legionary";
import HeroesRelictsSarcophagus from "./Heroes-Relicts-Sarcophagus";
import HeroesRelictsServitor from "./Heroes-Relicts-Servitor";
import HeroesRelictsSoulFlayer from "./Heroes-Relicts-Soul-Flayer";
import HeroesRelictsTombGuard from "./Heroes-Relicts-Tomb-Guard";

export default [
    Object.assign({ value: 0, label: "Archivist" }, HeroesRelictsArchivist),
    Object.assign({ value: 1, label: "Embalmer" }, HeroesRelictsEmbalmer),
    Object.assign({ value: 2, label: "Exectioner" }, HeroesRelictsExecutioner),
    Object.assign({ value: 3, label: "Grafter" }, HeroesRelictsGrafter),
    Object.assign({ value: 4, label: "Heliolite" }, HeroesRelictsHeliolite),
    Object.assign({ value: 5, label: "Husk 1" }, HeroesRelictsHusk1),
    Object.assign({ value: 6, label: "Husk 2" }, HeroesRelictsHusk2),
    Object.assign({ value: 7, label: "Immortalist" }, HeroesRelictsImmortalist),
    Object.assign({ value: 8, label: "Legionary" }, HeroesRelictsLegionary),
    Object.assign({ value: 9, label: "Sarcophagus of Iz" }, HeroesRelictsSarcophagus),
    Object.assign({ value: 10, label: "Servitor" }, HeroesRelictsServitor),
    Object.assign({ value: 11, label: "Soul Flayer" }, HeroesRelictsSoulFlayer),
    Object.assign({ value: 12, label: "Tomb Guard" }, HeroesRelictsTombGuard),
];

export const heroesTemplateList = [
    {
        label: "Default",
        value: {"camo1Value":"NONE","camo2Value":"NONE","camo3Value":"NONE","camo4Value":"NONE","camo1TintValue":"none","camo2TintValue":"none","camo3TintValue":"none","camo4TintValue":"none","chassisTintValue":"none","weaponTintValue":"none","secondaryWeaponTintValue":"none"},
    },
    {
        label: "Bubblegum Crisis",
        value: {"camo1Value":"5","camo2Value":"0","camo3Value":"3","camo4Value":"8","camo1TintValue":"sepia(100%) saturate(300%) brightness(172%) hue-rotate(300deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(65%) saturate(312%) brightness(200%) hue-rotate(150deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(65%) saturate(263%) brightness(130%) hue-rotate(150deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(151%) brightness(97%) hue-rotate(261deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(66%) saturate(160%) brightness(98%) hue-rotate(189deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(65%) saturate(424%) brightness(200%) hue-rotate(286deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(300%) brightness(181%) hue-rotate(332deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Tundra Slate",
        value: {"camo1Value":"0","camo2Value":"3","camo3Value":"6","camo4Value":"4","camo1TintValue":"sepia(100%) saturate(69%) brightness(200%) hue-rotate(55deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(62%) brightness(43%) hue-rotate(141deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(62%) brightness(111%) hue-rotate(141deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(103%) brightness(43%) hue-rotate(141deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(103%) brightness(81%) hue-rotate(141deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(68%) saturate(89%) brightness(142%) hue-rotate(104deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(68%) saturate(366%) brightness(130%) hue-rotate(116deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Charcoal Sherbet",
        value: {"camo1Value":"NONE","camo2Value":"2","camo3Value":"5","camo4Value":"6","camo1TintValue":"none","camo2TintValue":"sepia(100%) saturate(448%) brightness(160%) hue-rotate(318deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(0%) brightness(40%) hue-rotate(345deg) invert(0%) contrast(100%)","camo4TintValue":"none","chassisTintValue":"sepia(100%) saturate(0%) brightness(44%) hue-rotate(300deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(38%) brightness(160%) hue-rotate(347deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(108%) hue-rotate(320deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Ferrous Oxide",
        value: {"camo1Value":"3","camo2Value":"0","camo3Value":"6","camo4Value":"9","camo1TintValue":"sepia(100%) saturate(364%) brightness(66%) hue-rotate(316deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(500%) brightness(54%) hue-rotate(305deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(260%) brightness(85%) hue-rotate(337deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(72%) saturate(265%) brightness(97%) hue-rotate(336deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(33%) saturate(239%) brightness(74%) hue-rotate(308deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(69%) brightness(129%) hue-rotate(337deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(500%) brightness(118%) hue-rotate(325deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Hedge Knight",
        value: {"camo1Value":"0","camo2Value":"4","camo3Value":"3","camo4Value":"9","camo1TintValue":"sepia(48%) saturate(0%) brightness(187%) hue-rotate(318deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(40%) saturate(352%) brightness(60%) hue-rotate(357deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(48%) saturate(308%) brightness(104%) hue-rotate(360deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(48%) saturate(373%) brightness(44%) hue-rotate(95deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(64%) saturate(282%) brightness(84%) hue-rotate(43deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(48%) saturate(185%) brightness(63%) hue-rotate(74deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(241%) brightness(137%) hue-rotate(328deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Brass Ochre",
        value: {"camo1Value":"0","camo2Value":"7","camo3Value":"1","camo4Value":"3","camo1TintValue":"sepia(100%) saturate(206%) brightness(85%) hue-rotate(345deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(134%) brightness(70%) hue-rotate(0deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(148%) brightness(95%) hue-rotate(87deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(100%) saturate(100%) brightness(80%) hue-rotate(0deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(93%) brightness(99%) hue-rotate(69deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(100%) saturate(52%) brightness(96%) hue-rotate(35deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(332%) brightness(122%) hue-rotate(337deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Lisa Frank",
        value: {"camo1Value":"6","camo2Value":"3","camo3Value":"5","camo4Value":"8","camo1TintValue":"sepia(100%) saturate(250%) brightness(168%) hue-rotate(153deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(39%) saturate(500%) brightness(89%) hue-rotate(271deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(39%) saturate(185%) brightness(130%) hue-rotate(182deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(41%) saturate(486%) brightness(156%) hue-rotate(251deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(250%) brightness(99%) hue-rotate(187deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(62%) saturate(178%) brightness(93%) hue-rotate(207deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(411%) brightness(100%) hue-rotate(210deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Pain",
        value: {"camo1Value":"6","camo2Value":"7","camo3Value":"1","camo4Value":"NONE","camo1TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(100%) saturate(300%) brightness(157%) hue-rotate(0deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(21%) brightness(110%) hue-rotate(0deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(186%) brightness(93%) hue-rotate(0deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(25%) saturate(250%) brightness(60%) hue-rotate(150deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(286%) brightness(106%) hue-rotate(341deg) invert(0%) contrast(100%)"},
    },
    {
        label: "Perfection",
        value: {"camo1Value":"0","camo2Value":"4","camo3Value":"7","camo4Value":"NONE","camo1TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","camo2TintValue":"sepia(65%) saturate(293%) brightness(69%) hue-rotate(57deg) invert(0%) contrast(100%)","camo3TintValue":"sepia(100%) saturate(197%) brightness(91%) hue-rotate(215deg) invert(0%) contrast(100%)","camo4TintValue":"sepia(0%) saturate(500%) brightness(45%) hue-rotate(0deg) invert(0%) contrast(100%)","chassisTintValue":"sepia(100%) saturate(186%) brightness(100%) hue-rotate(45deg) invert(0%) contrast(100%)","weaponTintValue":"sepia(25%) saturate(250%) brightness(60%) hue-rotate(150deg) invert(0%) contrast(100%)","secondaryWeaponTintValue":"sepia(100%) saturate(286%) brightness(106%) hue-rotate(341deg) invert(0%) contrast(100%)"},
    },
];
