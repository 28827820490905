import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI Ptolemy`;

// BODY
const bodyPtolemyImage = `${imagePath}/Chassis.png`;

// ARMS
const armsClawsImage = `${imagePath}/weapons/Amrs - Claws.png`;
const armsEnergyCannonsImage = `${imagePath}/weapons/Amrs - Energy Cannons.png`;
const armsShieldsGlossyImage = `${imagePath}/weapons/Amrs - Shields Glossy.png`;
const armsShieldsRoughImage = `${imagePath}/weapons/Amrs - Shields Rough.png`;

// TAILS
const tailDualEnergyCannonImage = `${imagePath}/weapons/Tail - Dual Energy Cannons.png`;
const tailGatlingCannonImage = `${imagePath}/weapons/Tail - Gatling Cannon.png`;
const tailLaserCannonImage = `${imagePath}/weapons/Tail - Laser Cannon.png`;
const tailMissilesImage = `${imagePath}/weapons/Tail - Missiles.png`;
const tailTeslaCannonImage = `${imagePath}/weapons/Tail - Tesla Cannon.png`;

// SIDE MOUNT
const sideInterdictorMissilesImage = `${imagePath}/sides/Side - Interdictor Missiles.png`;
const sideInterdictorMissilesUnderImage = `${imagePath}/sides/Side - Interdictor Missiles Under.png`;
const sidePDCsImage = `${imagePath}/sides/Side - PDCs.png`;
const sidePDCsUnderImage = `${imagePath}/sides/Side - PDCs Under.png`;
const sideVentingPlatesImage = `${imagePath}/sides/Side - Venting Plates.png`;
const sideVentingPlatesUnderImage = `${imagePath}/sides/Side - Venting Plates Under.png`;

// GLOWS
const glowVentingPlatesImage = `${imagePath}/glows/Glow - Venting Plates.png`;
const glowVentingPlatesUnderImage = `${imagePath}/glows/Glow - Venting Plates Under.png`;
const glowArmsEnergyCannonsImage = `${imagePath}/glows/Glow - Arm Energy Cannons.png`;
const glowTailDualEnergyImage = `${imagePath}/glows/Glow - Tail Dual Energy.png`;
const glowTailLaserImage = `${imagePath}/glows/Glow - Tail Laser.png`;
const glowTailTeslaImage = `${imagePath}/glows/Glow - Tail Tesla.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/Core_Ptolemy_Kottadragon.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "74px",
    right: "7px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Kottadragon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "2",
        "weaponValue": "1",
        "secondaryWeaponValue": "2",
        "accessory1Value": "1",
        "accessory2Value": "3",
        "accessory3Value": "0",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "MOI PTOLEMY",
            backSrc: bodyPtolemyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLAWS",
                src: armsClawsImage,
            },
            {
                value: 1,
                label: "ENERGY CANNONS",
                src: armsEnergyCannonsImage,
            },
            {
                value: 2,
                label: "SHIELDS - GLOSSY",
                src: armsShieldsGlossyImage,
            },
            {
                value: 3,
                label: "SHIELDS - ROUGH",
                src: armsShieldsRoughImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "INTERDICTOR MISSILES",
            src: sideInterdictorMissilesImage,
            backSrc: sideInterdictorMissilesUnderImage,
        },
        {
            value: 1,
            label: "PDC",
            src: sidePDCsImage,
            backSrc: sidePDCsUnderImage,
        },
        {
            value: 2,
            label: "VENTING PLATES",
            src: sideVentingPlatesImage,
            backSrc: sideVentingPlatesUnderImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "DUAL ENERGY CANNONS",
            src: tailDualEnergyCannonImage,
        },
        {
            value: 1,
            label: "GATLING CANNON",
            src: tailGatlingCannonImage,
        },
        {
            value: 2,
            label: "LASER CANNON",
            src: tailLaserCannonImage,
        },
        {
            value: 3,
            label: "MISSILES",
            src: tailMissilesImage,
        },
        {
            value: 4,
            label: "TESLA CANNON",
            src: tailTeslaCannonImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "VENTING PLATES",
            frontSrc: glowVentingPlatesImage,
            src: glowVentingPlatesUnderImage,
        },
        {
            value: 1,
            label: "ENERGY CANNONS - ARMS",
            frontSrc: glowArmsEnergyCannonsImage,
        },
        {
            value: 2,
            label: "DUAL ENERGY CANNONS - TAIL",
            src: glowTailDualEnergyImage,
        },
        {
            value: 3,
            label: "LASER CANNON - TAIL",
            src: glowTailLaserImage,
        },
        {
            value: 4,
            label: "TESLA CANNON - TAIL",
            src: glowTailTeslaImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "TAIL",
            value: "rearMount",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMS",
            value: "weapon",
        },
        {
            label: "SIDE MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "EMPTY",
        "chassis": "CHASSIS",
        "rearMount": "TAIL",
        "weapon": "ARMS",
        "secondaryWeapon": "SIDE MOUNT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
