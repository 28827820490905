import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// CENTER
const centerSubalt1Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Center - Subaltern 1.png`;
const centerSubalt2Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Center - Subaltern 2.png`;
const centerSubalt3Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Center - Subaltern 3.png`;
const centerSubalt4Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Center - Subaltern 4.png`;
const centerHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Center - Helicopter Drone.png`;
const deployableShieldImage = `${BaseURL}/Mechs/Custom/BDF Dao/accessories/Deployed Shield.png`;

// NE
const neSubalt1Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/ne/NE - Subaltern 1.png`;
const neSubalt2Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/ne/NE - Subaltern 2.png`;
const neSubalt3Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/ne/NE - Subaltern 3.png`;
const neSubalt4Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/ne/NE - Subaltern 4.png`;
const neHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/ne/NE - Helicopter Drone.png`;

// NW
const nwSubalt1Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/nw/NW - Subaltern 1.png`;
const nwSubalt2Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/nw/NW - Subaltern 2.png`;
const nwSubalt3Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/nw/NW - Subaltern 3.png`;
const nwSubalt4Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/nw/NW - Subaltern 4.png`;
const nwHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/nw/NW - Helicopter Drone.png`;

// SE
const seSubalt1Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/se/SE - Subaltern 1.png`;
const seSubalt2Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/se/SE - Subaltern 2.png`;
const seSubalt3Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/se/SE - Subaltern 3.png`;
const seSubalt4Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/se/SE - Subaltern 4.png`;
const seHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/se/SE - Helicopter Drone.png`;

// SW
const swSubalt1Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/sw/SW - Subaltern 1.png`;
const swSubalt2Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/sw/SW - Subaltern 2.png`;
const swSubalt3Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/sw/SW - Subaltern 3.png`;
const swSubalt4Image = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/sw/SW - Subaltern 4.png`;
const swHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/sw/SW - Helicopter Drone.png`;

// GLOWS
const glowShieldsDeployedImage = `${BaseURL}/Mechs/Custom/BDF Dao/glows/Glow - Deployed Shield.png`;
const glowAllSubaltsImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Glow - All Subalterns.png`;
const glowCenterSubaltImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Glow - Center Subaltern.png`;
const glowCenterHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/center/Glow - Center Helicopter Drone.png`;
const glowNeSubaltImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/ne/Glow - NE Subaltern.png`;
const glowNeHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/ne/Glow - NE Helicopter Drone.png`;
const glowNwSubaltImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/nw/Glow - NW Subaltern.png`;
const glowNwHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/nw/Glow - NW Helicopter Drone.png`;
const glowSeSubaltImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/se/Glow - SE Subaltern.png`;
const glowSeHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/se/Glow - SE Helicopter Drone.png`;
const glowSwSubaltImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/sw/Glow - SW Subaltern.png`;
const glowSwHeliDroneImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/sw/Glow - SW Helicopter Drone.png`;

const previewImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/BDF Dao Subalterns/preview-restricted.png`;

const shadowDetails = {
    size: shadowTypes.NONE,
    bottom: "0px",
    right: "30px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    isMegadeusPlus: true,
    accessory2IsChassis: true,
    chassisHasNone: true,
    defaultValues: `{
    "chassisValue": "3",
    "headValue": "3",
    "rearMountValue": "1",
    "weaponValue": "3",
    "secondaryWeaponValue": "0",
    "accessory1Value": "1",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SUBALTERN 1",
            backSrc: nwSubalt1Image,
        },
        {
            value: 1,
            label: "SUBALTERN 2",
            backSrc: nwSubalt2Image,
        },
        {
            value: 2,
            label: "SUBALTERN 3",
            backSrc: nwSubalt3Image,
        },
        {
            value: 3,
            label: "SUBALTERN 4",
            backSrc: nwSubalt4Image,
        },
        {
            value: 4,
            label: "HELICOPTER DRONE",
            backSrc: nwHeliDroneImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DEPLOYED SHIELD",
            src: deployableShieldImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 1,
            label: "SUBALTERN 1",
            src: centerSubalt1Image,
            shadowDetails: shadowDetails,
        },
        {
            value: 2,
            label: "SUBALTERN 2",
            src: centerSubalt2Image,
            shadowDetails: shadowDetails,
        },
        {
            value: 3,
            label: "SUBALTERN 3",
            src: centerSubalt3Image,
            shadowDetails: shadowDetails,
        },
        {
            value: 4,
            label: "SUBALTERN 4",
            src: centerSubalt4Image,
            shadowDetails: shadowDetails,
        },
        {
            value: 5,
            label: "HELICOPTER DRONE",
            src: centerHeliDroneImage,
            shadowDetails: shadowDetails,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SUBALTERN 1",
                src: swSubalt1Image,
            },
            {
                value: 1,
                label: "SUBALTERN 2",
                src: swSubalt2Image,
            },
            {
                value: 2,
                label: "SUBALTERN 3",
                src: swSubalt3Image,
            },
            {
                value: 3,
                label: "SUBALTERN 4",
                src: swSubalt4Image,
            },
            {
                value: 4,
                label: "HELICOPTER DRONE",
                src: swHeliDroneImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SUBALTERN 1",
            src: seSubalt1Image,
        },
        {
            value: 1,
            label: "SUBALTERN 2",
            src: seSubalt2Image,
        },
        {
            value: 2,
            label: "SUBALTERN 3",
            src: seSubalt3Image,
        },
        {
            value: 3,
            label: "SUBALTERN 4",
            src: seSubalt4Image,
        },
        {
            value: 4,
            label: "HELICOPTER DRONE",
            src: seHeliDroneImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SUBALTERN 1",
            src: neSubalt1Image,
        },
        {
            value: 1,
            label: "SUBALTERN 2",
            src: neSubalt2Image,
        },
        {
            value: 2,
            label: "SUBALTERN 3",
            src: neSubalt3Image,
        },
        {
            value: 3,
            label: "SUBALTERN 4",
            src: neSubalt4Image,
        },
        {
            value: 4,
            label: "HELICOPTER DRONE",
            src: neHeliDroneImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "SHIELD - CENTER",
            src: glowShieldsDeployedImage,
        },
        {
            value: 1,
            label: "ALL SUBALTERNS",
            frontSrc: glowAllSubaltsImage,
        },
        {
            value: 2,
            label: "SUBALTERN - CENTER",
            src: glowCenterSubaltImage,
        },
        {
            value: 3,
            label: "SUBALTERN - NE",
            src: glowNeSubaltImage,
        },
        {
            value: 4,
            label: "SUBALTERN - NW",
            src: glowNwSubaltImage,
        },
        {
            value: 5,
            label: "SUBALTERN - SE",
            frontSrc: glowSeSubaltImage,
        },
        {
            value: 6,
            label: "SUBALTERN - SW",
            frontSrc: glowSwSubaltImage,
        },
        {
            value: 7,
            label: "SUBALTERN - SE + NE",
            frontSrc: glowSeSubaltImage,
            src: glowNeSubaltImage,
        },
        {
            value: 8,
            label: "SUBALTERN - SW + NW",
            frontSrc: glowSwSubaltImage,
            src: glowNwSubaltImage,
        },
        {
            value: 9,
            label: "HELICOPTER DRONE - CENTER",
            src: glowCenterHeliDroneImage,
        },
        {
            value: 10,
            label: "HELICOPTER DRONE - NE",
            src: glowNeHeliDroneImage,
        },
        {
            value: 11,
            label: "HELICOPTER DRONE - NW",
            src: glowNwHeliDroneImage,
        },
        {
            value: 12,
            label: "HELICOPTER DRONE - SE",
            frontSrc: glowSeHeliDroneImage,
        },
        {
            value: 13,
            label: "HELICOPTER DRONE - SW",
            frontSrc: glowSwHeliDroneImage,
        },
        {
            value: 14,
            label: "HELICOPTER DRONE - SE + NE",
            frontSrc: glowSeHeliDroneImage,
            src: glowNeHeliDroneImage,
        },
        {
            value: 15,
            label: "HELICOPTER DRONE - SW + NW",
            src: glowNwHeliDroneImage,
            frontSrc: glowSwHeliDroneImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "NW",
            value: "head",
        },
        {
            label: "NE",
            value: "rearMount",
        },
        {
            label: "SE",
            value: "secondaryWeapon",
        },
        {
            label: "SW",
            value: "weapon",
        },
        {
            label: "CENTER",
            value: "chassis",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
        },
        {
            label: "GLOW 2",
            value: "accessory3",
        },
        {
            label: "GLOW 3",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "NW",
        "chassis": "CENTER",
        "rearMount": "NE",
        "weapon": "SW",
        "secondaryWeapon": "SE",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
