import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyNapoleonImage = `${BaseURL}/Mechs/HA/Napoleon/Chassis.png`;

// HEADS
const headNapoleonImage = `${BaseURL}/Mechs/HA/Napoleon/heads/Head - Napoleon.png`;
const headKazuImage = `${BaseURL}/Mechs/HA/Napoleon/heads/Head - Kazu.png`;
// PREMIUM
const headCalibanImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Chimera.png`;
const headHornedImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Malice.png`;
const headMetalmarkImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Metalmark.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Rain.png`;
const headRainHairImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Rain Hair.png`;
const headTokugawaImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Tokugawa.png`;
const headScopehoundImage = `${BaseURL}/Mechs/HA/Napoleon/heads/premium/Head - Scopehound.png`;

// GLOW
const glowDisplacerImage = `${BaseURL}/Mechs/HA/Napoleon/glows/Glow - Displacer.png`;
const glowDisplacerPackAllImage = `${BaseURL}/Mechs/HA/Napoleon/glows/Glow - Displacer Pack.png`;
const glowDisplacerPackSubsolarImage = `${BaseURL}/Mechs/HA/Napoleon/glows/Glow - Displacer Pack (subsolar).png`;
const glowMagCannonImage = `${BaseURL}/Mechs/HA/Napoleon/glows/Glow - Mag Cannon.png`;
const glowTrueblackAegisImage = `${BaseURL}/Mechs/HA/Napoleon/glows/Glow - Trueblack Aegis.png`;
const glowTrueblackAegisBirdImage = `${BaseURL}/Mechs/HA/Napoleon/glows/Glow - Trueblack Aegis Bird.png`;



// REAR MOUNTS
const rearMountBoostersImage = `${BaseURL}/Mechs/HA/Napoleon/rear mounts/Rear - Boosters.png`;
const rearMountDisplacerPowerUnitImage = `${BaseURL}/Mechs/HA/Napoleon/rear mounts/Rear - Displacer Power Unit.png`;

// WEAPONS
// LOWER
const weaponLowerUnarmedImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/lower/Lower Arms - Unarmed.png`;
const weaponLowerRightAssaultRifleImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/lower/Lower Arms - Assault Rifle.png`;
const weaponLowerRightMagCannonImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/lower/Lower Arms - Mag Cannon.png`;
const weaponLowerDisplacerImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/lower/Lower Arms - Displacer.png`;

// UPPER
const weaponUpperFistsImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Upper Arms - Fists.png`;
const weaponUpperKnifeImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Upper Arms - Knife.png`;
const weaponUpperAssaultCannonImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Upper Arms - Right Assault Cannon.png`;
const weaponUpperChainAxeImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Upper Arms - Right Chain Axe.png`;
const weaponUpperMacheteImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Upper Arms - Right Machete.png`;
const weaponUpperDualMachetesImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Upper Arms - Dual Machetes.png`;
const weaponUpperArgonautShieldImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Shield - Argonaut Shield.png`;
const weaponUpperSubsolarFrontImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Shield - Subsolar Over.png`;
const weaponUpperSubsolarBackImage = `${BaseURL}/Mechs/HA/Napoleon/weapons/upper/Shield - Subsolar Under.png`;

const coreImage = `${BaseURL}/Mechs/HA/Napoleon/Napolean (by BlindDuke).png`;

const previewImage = `${BaseURL}/Mechs/HA/Napoleon/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "86px",
    right: "66px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "BlindDuke",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    secondaryWeaponIsAccessory1: true,
    accessory1IsSecondaryWeapon: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "3",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "NAPOLEON",
            src: headNapoleonImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "METALMARK",
            src: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "RAIN TRANSFORMED",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "TOKUGAWA",
            src: headTokugawaImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "NAPOLEON",
            src: bodyNapoleonImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "UNARMED",
                src: weaponLowerUnarmedImage,
            },
            {
                value: 1,
                label: "ASSAULT RIFLE",
                src: weaponLowerRightAssaultRifleImage,
            },
            {
                value: 2,
                label: "MAG CANNON",
                src: weaponLowerRightMagCannonImage,
            },
            {
                value: 3,
                label: "DISPLACER",
                src: weaponLowerDisplacerImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DISPLACER",
            frontSrc: glowDisplacerImage,
        },
        {
            value: 1,
            label: "DISPLACER + PACK",
            frontSrc: glowDisplacerPackAllImage,
        },
        {
            value: 2,
            label: "DISPLACER + PACK (SUBSOLAR)",
            frontSrc: glowDisplacerPackSubsolarImage,
        },
        {
            value: 3,
            label: "MAG CANNON",
            frontSrc: glowMagCannonImage,
        },
        {
            value: 4,
            label: "TRUEBLACK AEGIS",
            frontSrc: glowTrueblackAegisImage,
        },
        {
            value: 5,
            label: "TRUEBLACK AEGIS BIRD",
            frontSrc: glowTrueblackAegisBirdImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "BOOSTERS",
            src: rearMountBoostersImage,
        },
        {
            value: 1,
            label: "DISPLACER POWER UNIT",
            src: rearMountDisplacerPowerUnitImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FISTS",
            src: weaponUpperFistsImage,
        },
        {
            value: 1,
            label: "KNIFE",
            src: weaponUpperKnifeImage,
        },
        {
            value: 2,
            label: "ASSAULT CANNON",
            src: weaponUpperAssaultCannonImage,
        },
        {
            value: 3,
            label: "CHAIN AXE",
            src: weaponUpperChainAxeImage,
        },
        {
            value: 4,
            label: "MACHETE",
            src: weaponUpperMacheteImage,
        },
        {
            value: 5,
            label: "DUAL MACHETES",
            src: weaponUpperDualMachetesImage,
        },
        {
            value: 6,
            label: "ARGONAUT SHIELD",
            src: weaponUpperArgonautShieldImage,
        },
        {
            value: 7,
            label: "SUBSOLAR",
            src: weaponUpperSubsolarFrontImage,
            backSrc: weaponUpperSubsolarBackImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "UPPER ARMS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "LOWER ARMS",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "UPPER ARMS 1",
            value: "accessory1",
        },
        {
            label: "UPPER ARMS 2",
            value: "accessory2",
        },
        {
            label: "UPPER ARMS 3",
            value: "accessory3",
        },
        {
            label: "UPPER ARMS 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LOWER ARMS",
        "secondaryWeapon": "GLOW",
        "accessory1": "UPPER ARMS 1",
        "accessory2": "UPPER ARMS 2",
        "accessory3": "UPPER ARMS 3",
        "accessory4": "UPPER ARMS 4",
    },
};
