import styled from "styled-components";

import Unit from "./Unit";

import BaseURL from "./../data/images/Hosting-BaseURL";

const unitBorderImage = `${BaseURL}/buttons/unit-selected-border.png`;

export function getSizeValue(props) {
    if (props.isMegadeusOmega) {
        return 350; //450;
    }
    else if (props.isMegadeusPlusPlus) {
        return 350; //400;
    }
    else if (props.isMegadeusPlus) {
        return 350;
    }
    else if (props.isMegadeus) {
        return 300;
    } else if (props.isRegular){
        return 150;
    } else {
        return 250;
    }
};

const LayeredUnitImage = styled.img`
    position: absolute;
    top: ${props => props.printDisplay || (props.isMegadeus || props.isMegadeusPlus || props.isMegadeusPlusPlus || props.isMegadeusOmega) ? "0" : "20px"};
    left: ${props => props.printDisplay || (props.isMegadeus || props.isMegadeusPlus || props.isMegadeusPlusPlus || props.isMegadeusOmega) ? "0" : "20px"};
    height: ${props => getSizeValue(props)}px;
    width: ${props => getSizeValue(props)}px;
    z-index: ${props => props.zIndex};
    color: transparent;

    border: 5px solid transparent;

    &.mirrored {
        transform: scaleX(-1);
    }

    filter: ${props => props.tintValue};

    ${Unit}:hover & {
        border-image-repeat: round;
        border-image-slice: 30;
        border-image-source: url(${unitBorderImage});
        border-image-width: 5;
    }

    transition: inherit;

    &.zoomed-1 {
        transform: scale(1.0);
    }

    &.zoomed-2 {
        transform: scale(1.5);
    }

    &.zoomed-3 {
        transform: scale(2.0);
    }

    &.zoomed-4 {
        transform: scale(2.5);
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        border-image-repeat: round;
        border-image-slice: 30;
        border-image-source: url(${unitBorderImage});
        border-image-width: 5;
    }
`;

LayeredUnitImage.displayName = "LayeredUnitImage";

export default LayeredUnitImage;
