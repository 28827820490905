import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyIskanderImage = `${BaseURL}/Mechs/HA/Iskander/Chassis.png`;

// HEADS
const headIskanderImage = `${BaseURL}/Mechs/HA/Iskander/heads/Head - Iskander.png`;
const headKazuImage = `${BaseURL}/Mechs/HA/Iskander/heads/Head - Kazu.png`;
const headYareYareDazeImage = `${BaseURL}/Mechs/HA/Iskander/heads/Head - Yare Yare Daze.png`;
// PREMIUM
const head8ballImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - 8 Ball.png`;
const headBunkerImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Bunker.png`;
const headCalibanImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Caliban.png`;
const headGenghisImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Genghis.png`;
const headHammerheadImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Hammerhead.png`;
const headHiImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Hi.png`;
const headPaladinImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Paladin.png`;
const headRumbleImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Rumble.png`;
const headShermanImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Sherman.png`;
const headTaurusImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Taurus.png`;
const headScopehoundImage = `${BaseURL}/Mechs/HA/Iskander/heads/premium/Head - Scopehound.png`;

// GLOW
const glowChassisImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Chassis.png`;
const glowChassisAllImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Chassis All.png`;
const glowChassisLeftGrenadesImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Chassis Left Grenades.png`;
const glowChassisAllLeftGrenadesImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Chassis All Left Grenades.png`;
const glowLeftArmImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Left Arm.png`;
const glowLeftGrenadesImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Left Grenades.png`;
const glowRightArmImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Right - Right Arm.png`;
const glowRightCannibalImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Right - Cannibal.png`;
const glowRightSnubCannonImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Right - Snub Cannon.png`;
const glowRightGrenadeLauncherImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Right - Grenade Launcher.png`;
const glowRightGravityCannonImage = `${BaseURL}/Mechs/HA/Iskander/glows/Glow - Right - Gravity Cannon.png`;

// ACCESSORY
const accessoryExplosiveBeltImage = `${BaseURL}/Mechs/HA/Iskander/accessory/Accessory - Explosive Belt.png`;
const accessoryGrenadeHarnessImage = `${BaseURL}/Mechs/HA/Iskander/accessory/Accessory - Grenade Harness.png`;
const accessoryRearExplosivesImage = `${BaseURL}/Mechs/HA/Iskander/accessory/Accessory - Rear Explosives.png`;
const accessoryAllImage = `${BaseURL}/Mechs/HA/Iskander/accessory/Accessory - All.png`;
const accessoryBreastplateImage = `${BaseURL}/Mechs/HA/Iskander/accessory/Accessory - Iskander_Breast_Plate.png`;

// WEAPONS
// RIGHT
const weaponRightArmImage = `${BaseURL}/Mechs/HA/Iskander/weapons/Right - Right Arm.png`;
const weaponRightCannibalImage = `${BaseURL}/Mechs/HA/Iskander/weapons/Right - Cannibal.png`;
const weaponRightSnubCannonImage = `${BaseURL}/Mechs/HA/Iskander/weapons/Right - Snub Cannon.png`;
const weaponRightGrenadeLauncherImage = `${BaseURL}/Mechs/HA/Iskander/weapons/Right - Grenade Launcher.png`;
const weaponRightGravityCannonImage = `${BaseURL}/Mechs/HA/Iskander/weapons/Right - Gravity Cannon.png`;

// LEFT
const weaponLeftGrenadesImage = `${BaseURL}/Mechs/HA/Iskander/weapons/Left - Grenades.png`;
const weaponLeftRollerMineImage = `${BaseURL}/Mechs/HA/Iskander/weapons/Left - Roller Mine.png`;

const coreImage = `${BaseURL}/Mechs/HA/Iskander/Iskander (by Hit-Monkey).png`;

const previewImage = `${BaseURL}/Mechs/HA/Iskander/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "47px",
    right: "38px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Hit-Monkey",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    secondaryWeaponSpecial: "accessory2Value",
    removeNone: {
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "7",
        "accessory2Value": "0",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ISKANDER",
            src: headIskanderImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "YARE YARE DAZE",
            src: headYareYareDazeImage,
        },
        {
            value: 3,
            label: "BUNKER",
            src: headBunkerImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "GENGHIS",
            src: headGenghisImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "HAMMERHEAD",
            foremostSrc: headHammerheadImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "PALADIN",
            src: headPaladinImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SHERMAN",
            src: headShermanImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "TAURUS",
            src: headTaurusImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "HI",
            src: headHiImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "8 BALL",
            src: head8ballImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ISKANDER",
            src: bodyIskanderImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "GRENADES",
                src: weaponLeftGrenadesImage,
            },
            {
                value: 1,
                label: "ROLLER MINE",
                src: weaponLeftRollerMineImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARM",
            src: weaponRightArmImage,
        },
        {
            value: 1,
            label: "CANNIBAL",
            src: weaponRightCannibalImage,
        },
        {
            value: 2,
            label: "SNUB CANNON",
            src: weaponRightSnubCannonImage,
        },
        {
            value: 3,
            label: "GRENADE LAUNCHER",
            src: weaponRightGrenadeLauncherImage,
        },
        {
            value: 4,
            label: "GRAVITY CANNON",
            src: weaponRightGravityCannonImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "EXPLOSIVE BELT",
            frontSrc: accessoryExplosiveBeltImage,
        },
        {
            value: 1,
            label: "GRENADE HARNESS",
            frontSrc: accessoryGrenadeHarnessImage,
        },
        {
            value: 2,
            label: "REAR EXPLOSIVES",
            frontSrc: accessoryRearExplosivesImage,
        },
        {
            value: 3,
            label: "ALL",
            frontSrc: accessoryAllImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "RIGHT - ARM",
            frontSrc: glowRightArmImage,
        },
        {
            value: 1,
            label: "RIGHT - CANNIBAL",
            frontSrc: glowRightCannibalImage,
        },
        {
            value: 2,
            label: "RIGHT - SNUB CANNON",
            frontSrc: glowRightSnubCannonImage,
        },
        {
            value: 3,
            label: "RIGHT - GRENADE LAUNCHER",
            frontSrc: glowRightGrenadeLauncherImage,
        },
        {
            value: 4,
            label: "RIGHT - GRAVITY CANNON",
            frontSrc: glowRightGravityCannonImage,
        },
        {
            value: 5,
            label: "LEFT - ARM",
            frontSrc: glowLeftArmImage,
        },
        {
            value: 6,
            label: "LEFT - GRENADES",
            frontSrc: glowLeftGrenadesImage,
        },
        {
            value: 7,
            label: "CHASSIS",
            frontSrc: glowChassisImage,
        },
        {
            value: 8,
            label: "CHASSIS + LEFT GRENADES",
            frontSrc: glowChassisLeftGrenadesImage,
        },
        {
            value: 9,
            label: "CHASSIS + ALL ACCESSORIES",
            frontSrc: glowChassisAllImage,
        },
        {
            value: 10,
            label: "CHASSIS + ALL + LEFT GRENADES",
            frontSrc: glowChassisAllLeftGrenadesImage,
        },
        {
            value: 11,
            label: "BREASTPLATE",
            frontSrc: accessoryBreastplateImage,
            originalArtCredit: {
                title: "Nemo#5386",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ACCESSORY",
            value: "rearMount",
        },
        {
            label: "LEFT",
            value: "weapon",
        },
        {
            label: "RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ACCESSORY",
        "weapon": "LEFT",
        "secondaryWeapon": "RIGHT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
