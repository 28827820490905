import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const unitPath = `${BaseURL}/Mechs/NPC/Corpro/Berserker`;

// BODY
const bodyBerserkerImage = `${unitPath}/Berserker.png`;

// MAIN WEAPON
const dualChainAxesImage = `${unitPath}/weapons/Weapon - Dual Chain Axes.png`;
const chainAxeHandImage = `${unitPath}/weapons/Weapon - Hand Chain Axe.png`;
const chainAxeHarpoonImage = `${unitPath}/weapons/Weapon - Harpoon Cannon Chain Axe.png`;

// AUX WEAPON
const tubingAxeImage = `${unitPath}/weapons/Aux - Tubing, Right Axe.png`;
const tubingDualAxesImage = `${unitPath}/weapons/Aux - Tubing, Dual Axes.png`;

// GLOW
const chassisGlowImage = `${unitPath}/glow/Glow - Chassis.png`;
const chassisAxeGlowImage = `${unitPath}/glow/Glow - Chassis Right Axe.png`;
const chassisAxesGlowImage = `${unitPath}/glow/Glow - Chassis Dual Axes.png`;
const eyeGlowImage = `${unitPath}/glow/Berserker Eye Glow.png`;
const eyeChassisGlowImage = `${unitPath}/glow/Berserker Glow - All Eye.png`;
const eyeChassisAxeGlowImage = `${unitPath}/glow/Berserker Glow - All Eye Left Axe.png`;
const eyeChassisAxesGlowImage = `${unitPath}/glow/Berserker Glow - All Eye Dual Axes.png`;
const simCamoImage = `${unitPath}/camo/Camo - Sim.png`;

// CAMO
const corproCamo1Image = `${unitPath}/camo/Camo - Corpro 1.png`;
const corproCamo2Image = `${unitPath}/camo/Camo - Corpro 2.png`;
const forestCamo1Image = `${unitPath}/camo/Camo - Forest 1.png`;
const forestCamo2Image = `${unitPath}/camo/Camo - Forest 2.png`;
const urbanCamo1Image = `${unitPath}/camo/Camo - Urban 1.png`;
const urbanCamo2Image = `${unitPath}/camo/Camo - Urban 2.png`;
const headCamoImage = `${unitPath}/camo/Camo - Head Only.png`;
const tigerCamoImage = `${unitPath}/camo/Camo - Tiger.png`;
const halftoneVertCamoImage = `${unitPath}/camo/Camo - Halftone Vert.png`;
const halftoneDiagCamoImage = `${unitPath}/camo/Camo - Halftone Diag.png`;
const survivorCamoImage = `${unitPath}/camo/Camo - Survivor.png`;

const previewImage = `${unitPath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "56px",
    right: "46px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "5",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    defaultInactiveValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "1",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "BERSERKER",
            src: bodyBerserkerImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: [
        {
            value: 0,
            label: "HAND + CHAIN AXE",
            src: chainAxeHandImage,
            backSrc: tubingAxeImage,
        },
        {
            value: 1,
            label: "HARPOON CANNON + CHAIN AXE",
            src: chainAxeHarpoonImage,
            backSrc: tubingAxeImage,
        },
        {
            value: 2,
            label: "DUAL CHAIN AXES",
            src: dualChainAxesImage,
            backSrc: tubingDualAxesImage,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "CHASSIS",
            src: chassisGlowImage,
        },
        {
            value: 1,
            label: "CHASSIS + RIGHT CHAIN AXE",
            src: chassisAxeGlowImage,
        },
        {
            value: 2,
            label: "CHASSIS + DUAL CHAIN AXES",
            src: chassisAxesGlowImage,
        },
        {
            value: 3,
            label: "EYE",
            src: eyeGlowImage,
        },
        {
            value: 4,
            label: "EYE + CHASSIS",
            src: eyeChassisGlowImage,
        },
        {
            value: 5,
            label: "EYE + CHASSIS + RIGHT CHAIN AXE",
            src: eyeChassisAxeGlowImage,
        },
        {
            value: 6,
            label: "EYE + CHASSIS + DUAL CHAIN AXES",
            src: eyeChassisAxesGlowImage,
        },
        {
            value: 7,
            label: "SIM",
            src: simCamoImage,
            originalArtCredit: contributorList.CATOFMANYFACES,
            isPlusTwo: true,
        },
    ],
    camo: [
        {
            value: 0,
            label: "HEAD",
            src: headCamoImage,
        },
        {
            value: 1,
            label: "CORPRO 1",
            src: corproCamo1Image,
        },
        {
            value: 2,
            label: "CORPRO 2",
            src: corproCamo2Image,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
        },
        {
            value: 5,
            label: "URBAN 1",
            src: urbanCamo1Image,
            isPremium: true,
        },
        {
            value: 6,
            label: "URBAN 2",
            src: urbanCamo2Image,
            isPremium: true,
        },
        {
            value: 7,
            label: "TIGER",
            src: tigerCamoImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HALFTONE VERT",
            src: halftoneVertCamoImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HALFTONE DIAG",
            src: halftoneDiagCamoImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SURVIVOR",
            src: survivorCamoImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
