
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Strix-Class-Omnifighter`;

// CHASSIS
const chassisOmnifighterImage = `${imagePath}/Chassis.png`;

// COCKPITS
const cockpitCanopyImage = `${imagePath}/heads/Cockpit - Canopy.png`;
const cockpitDroneImage = `${imagePath}/heads/Cockpit - Drone.png`;
const cockpitMechImage = `${imagePath}/heads/Cockpit - Mech.png`;
const cockpitRTypeImage = `${imagePath}/heads/Cockpit - R Type.png`;
const cockpitRaptorImage = `${imagePath}/heads/Cockpit - Raptor.png`;
const cockpitSpartanianImage = `${imagePath}/heads/Cockpit - Spartanian.png`;
const cockpitSpearheadImage = `${imagePath}/heads/Cockpit - Spearhead.png`;

// WINGS
const wingR1RightImage = `${imagePath}/wings/Wing R-1.png`;
const wingL1LeftImage = `${imagePath}/wings/Wing L-1.png`;
const wingR2RightImage = `${imagePath}/wings/Wing R-2.png`;
const wingL2LeftImage = `${imagePath}/wings/Wing L-2.png`;
const wingR3RightImage = `${imagePath}/wings/Wing R-3.png`;
const wingL3LeftImage = `${imagePath}/wings/Wing L-3.png`;
const wingR4RightImage = `${imagePath}/wings/Wing R-4.png`;
const wingL4LeftImage = `${imagePath}/wings/Wing L-4.png`;
const wingR5ARightImage = `${imagePath}/wings/Wing R-5A.png`;
const wingL5ALeftImage = `${imagePath}/wings/Wing L-5A.png`;
const wingR5BRightImage = `${imagePath}/wings/Wing R-5B.png`;
const wingL5BLeftImage = `${imagePath}/wings/Wing L-5B.png`;
const wingR6RightImage = `${imagePath}/wings/Wing R-6.png`;
const wingL6LeftImage = `${imagePath}/wings/Wing L-6.png`;
const wingR7RightImage = `${imagePath}/wings/Wing R-7.png`;
const wingL7LeftImage = `${imagePath}/wings/Wing L-7.png`;
const wingR8RightImage = `${imagePath}/wings/Wing R-8.png`;
const wingL8LeftImage = `${imagePath}/wings/Wing L-8.png`;
const wingR9RightImage = `${imagePath}/wings/Wing R-9.png`;
const wingL9LeftImage = `${imagePath}/wings/Wing L-9.png`;
const wingR10RightImage = `${imagePath}/wings/Wing R-10 Missiles.png`;
const wingL10LeftImage = `${imagePath}/wings/Wing L-10 Missiles.png`;

// TAILS
const tailEnginesImage = `${imagePath}/rear-mounts/Tail - Engines.png`;
const tailSpartanianImage = `${imagePath}/rear-mounts/Tail - Spartanian.png`;
const tailTomcatImage = `${imagePath}/rear-mounts/Tail - Tomcat.png`;
const tailVImage = `${imagePath}/rear-mounts/Tail - V.png`;

// ENGINE GLOWS
const glowStandardEngineImage = `${imagePath}/glows/Glow - Standard Engine.png`;
const glowStandardEngineUnderImage = `${imagePath}/glows/Glow - Standard Engine Under.png`;
const glowEnginesImage = `${imagePath}/glows/Glow -Engines.png`;
const glowSpartanianImage = `${imagePath}/glows/Glow -Spartanian.png`;

// ACCESSORIES
const weaponLAutocannonImage = `${imagePath}/weapons/Weapons L - Autocannon.png`;
const weaponLMissilesImage = `${imagePath}/weapons/Weapons L - Missiles.png`;
const weaponLMissiles2Image = `${imagePath}/weapons/Weapons L - Missiles 2.png`;
const weaponLRailgunImage = `${imagePath}/weapons/Weapons L - Railgun.png`;
const weaponRAutocannonImage = `${imagePath}/weapons/Weapons R - Autocannon.png`;
const weaponRMissilesImage = `${imagePath}/weapons/Weapons R - Missiles.png`;
const weaponRMissiles2Image = `${imagePath}/weapons/Weapons R - Missiles 2.png`;
const weaponRRailgunImage = `${imagePath}/weapons/Weapons R - Railgun.png`;
const glowRailgunsBackImage = `${imagePath}/glows/Glow - Railguns Back.png`;
const glowRailgunsFrontImage = `${imagePath}/glows/Glow - Railguns Front.png`;
const glowWings4Image = `${imagePath}/glows/Glow - Wings 4.png`;
const glowWings4UnderImage = `${imagePath}/glows/Glow - Wings 4 Under.png`;
const glowWings7Image = `${imagePath}/glows/Glow - Wings 7.png`;
const glowWings7UnderImage = `${imagePath}/glows/Glow - Wings 7 Under.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const shadowDetails = {
    size: shadowTypes.LARGE,
    bottom: "0px",
    right: "0px",
};

const originalArtCredit = {
    prefixText: "Commissioned by ",
    title: "CaptainAnonymous",
    url: "https://discord.gg/JUF47G5",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "3",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CANOPY",
            src: cockpitCanopyImage,
        },
        {
            value: 1,
            label: "DRONE",
            src: cockpitDroneImage,
        },
        {
            value: 2,
            label: "MECH",
            src: cockpitMechImage,
        },
        {
            value: 3,
            label: "R TYPE",
            src: cockpitRTypeImage,
        },
        {
            value: 4,
            label: "RAPTOR",
            src: cockpitRaptorImage,
        },
        {
            value: 5,
            label: "SPARTANIAN",
            src: cockpitSpartanianImage,
        },
        {
            value: 6,
            label: "SPEARHEAD",
            src: cockpitSpearheadImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "STRIX CLASS OMNIFIGHTER",
            src: chassisOmnifighterImage,
            shadowDetails,
            originalArtCredit,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "1",
                foremostSrc: wingR1RightImage,
                backSrc: wingL1LeftImage,
            },
            {
                value: 1,
                label: "2",
                foremostSrc: wingR2RightImage,
                backSrc: wingL2LeftImage,
            },
            {
                value: 2,
                label: "3",
                foremostSrc: wingR3RightImage,
                backSrc: wingL3LeftImage,
            },
            {
                value: 3,
                label: "4",
                foremostSrc: wingR4RightImage,
                backSrc: wingL4LeftImage,
            },
            {
                value: 4,
                label: "5 - A",
                foremostSrc: wingR5ARightImage,
                backSrc: wingL5ALeftImage,
            },
            {
                value: 5,
                label: "5 - B",
                foremostSrc: wingR5BRightImage,
                backSrc: wingL5BLeftImage,
            },
            {
                value: 6,
                label: "6",
                foremostSrc: wingR6RightImage,
                backSrc: wingL6LeftImage,
            },
            {
                value: 7,
                label: "7",
                foremostSrc: wingR7RightImage,
                backSrc: wingL7LeftImage,
            },
            {
                value: 8,
                label: "8",
                foremostSrc: wingR8RightImage,
                backSrc: wingL8LeftImage,
            },
            {
                value: 9,
                label: "9",
                foremostSrc: wingR9RightImage,
                backSrc: wingL9LeftImage,
            },
            {
                value: 10,
                label: "10 - MISSILES",
                foremostSrc: wingR10RightImage,
                backSrc: wingL10LeftImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "STANDARD ENGINES (NONE)",
            src: glowStandardEngineImage,
            backSrc: glowStandardEngineUnderImage,
        },
        {
            value: 1,
            label: "ENGINES",
            src: glowEnginesImage,
        },
        {
            value: 2,
            label: "SPARTANIAN",
            src: glowSpartanianImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ENGINES",
            frontSrc: tailEnginesImage,
        },
        {
            value: 1,
            label: "SPARTANIAN",
            frontSrc: tailSpartanianImage,
        },
        {
            value: 2,
            label: "TOMCAT",
            frontSrc: tailTomcatImage,
        },
        {
            value: 3,
            label: "V",
            frontSrc: tailVImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "AUTOCANNONS",
            backSrc: weaponLAutocannonImage,
            frontSrc: weaponRAutocannonImage,
        },
        {
            value: 1,
            label: "MISSILES 1",
            backSrc: weaponLMissilesImage,
            frontSrc: weaponRMissilesImage,
        },
        {
            value: 2,
            label: "MISSILES 2",
            backSrc: weaponLMissiles2Image,
            frontSrc: weaponRMissiles2Image,
        },
        {
            value: 3,
            label: "RAILGUNS",
            backSrc: weaponLRailgunImage,
            frontSrc: weaponRRailgunImage,
        },
        {
            value: 4,
            label: "RAILGUNS - GLOW",
            foremostSrc: glowRailgunsFrontImage,
            src: glowRailgunsBackImage,
        },
        {
            value: 5,
            label: "WINGS 4 - GLOW",
            frontSrc: glowWings4Image,
            backSrc: glowWings4UnderImage,
        },
        {
            value: 6,
            label: "WINGS 7 - GLOW",
            frontSrc: glowWings7Image,
            backSrc: glowWings7UnderImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "COCKPIT",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "TAIL",
            value: "rearMount",
        },
        {
            label: "WINGS",
            value: "weapon",
        },
        {
            label: "TAIL GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "COCKPIT",
        "chassis": "CHASSIS",
        "rearMount": "TAIL",
        "weapon": "WINGS",
        "secondaryWeapon": "TAIL GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
