import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Empakaai`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis.png`;

// HEADS
const headEmpakaaiImage = `${imagePath}/heads/Head - Empakaai.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelson2Image = `${imagePath}/heads/Head - Nelson 2.png`;
const headNelsonFTImage = `${imagePath}/heads/Head - Nelson FT.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headScopedogImage = `${imagePath}/heads/Head - Scopedog.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// RIGHT
const armColossusTermoUngusImage = `${imagePath}/weapons/COLOSSUS TERMO-UNGUS.png`;

// LOWER
const lowerAssaultRifleImage = `${imagePath}/weapons/lower/Lower Arm - Assault Rifle.png`;
const lowerChainKnuckleImage = `${imagePath}/weapons/lower/Lower Arm - Chain Knuckle.png`;
const lowerClawImage = `${imagePath}/weapons/lower/Lower Arm - Claw.png`;
const lowerSpearImage = `${imagePath}/weapons/lower/Lower Arm - Spear.png`;

// REAR MOUNT
const upperChainsawImage = `${imagePath}/rearmount/Upper Arm - Chainsaw.png`;
const upperCombatDrill1Image = `${imagePath}/rearmount/Upper Arm - Combat Drill 1.png`;
const upperCombatDrill2Image = `${imagePath}/rearmount/Upper Arm - Combat Drill 2.png`;
const upperDD288Image = `${imagePath}/rearmount/Upper Arm - DD288.png`;
const upperFistImage = `${imagePath}/rearmount/Upper Arm - Fist.png`;
const upperHeavyIkakalakaImage = `${imagePath}/rearmount/Upper Arm - Heavy Ikakalaka.png`;
const upperHeavyIkakalakaUnderImage = `${imagePath}/rearmount/Upper Arm Under - Heavy Ikakalaka.png`;
const upperIkakalakaImage = `${imagePath}/rearmount/Upper Arm - Ikakalaka.png`;
const upperMambeleImage = `${imagePath}/rearmount/Upper Arm - Mambele.png`;

// GLOWS
const glowChainsawImage = `${imagePath}/glow/Glow - Chainsaw.png`;
const glowCOLOSSUSTERMOUNGUSArcsImage = `${imagePath}/glow/Glow - COLOSSUS TERMO-UNGUS Arcs.png`;
const glowCOLOSSUSTERMOUNGUSSteamImage = `${imagePath}/glow/Glow - COLOSSUS TERMO-UNGUS Steam.png`;
const glowCOLOSSUSTERMOUNGUSImage = `${imagePath}/glow/Glow - COLOSSUS TERMO-UNGUS.png`;
const glowCombatDrill1Image = `${imagePath}/glow/Glow - Combat Drill 1.png`;
const glowCombatDrill2Image = `${imagePath}/glow/Glow - Combat Drill 2.png`;
const glowDD288Image = `${imagePath}/glow/Glow - DD288.png`;
const glowEmpakaaiAllImage = `${imagePath}/glow/Glow - Empakaai All.png`;
const glowEmpakaaiEyesImage = `${imagePath}/glow/Glow - Empakaai Eyes.png`;
const glowHeatsinksImage = `${imagePath}/glow/Glow - Heatsinks.png`;
const glowSteamImage = `${imagePath}/glow/Glow - Steam.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage1 = `${imagePath}/Empakaai by equalsnil.png`;
const coreImage2 = `${imagePath}/Empakaai 2 by equalsnil.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "138px",
    right: "70px",
};

export default {
    isMegadeusPlus: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage1,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "equalsnil",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: coreImage2,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "equalsnil",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "5",
        "weaponValue": "0",
        "secondaryWeaponValue": "1",
        "accessory1Value": "4",
        "accessory2Value": "9",
        "accessory3Value": "8",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "EMPAKAAI",
            src: headEmpakaaiImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 7,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 8,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 9,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 10,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 11,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 14,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 15,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 16,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 17,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 18,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 19,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 20,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 21,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 22,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 23,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 24,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 25,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 26,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 27,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 28,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 29,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 30,
            label: "NELSON 2",
            src: headNelson2Image,
        },
        {
            value: 31,
            label: "NELSON FT",
            src: headNelsonFTImage,
        },
        {
            value: 32,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 33,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 34,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 35,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 36,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 37,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 38,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 39,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 40,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 41,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 42,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "EMPAKAAI",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Original by ",
                title: "@siegefault",
                url: "https://twitter.com/siegefault/status/1565959644685762560",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "COLOSSUS TERMO-UNGUS",
                src: armColossusTermoUngusImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ASSAULT RIFLE",
            src: lowerAssaultRifleImage,
        },
        {
            value: 1,
            label: "CHAIN KNUCKLE",
            src: lowerChainKnuckleImage,
        },
        {
            value: 2,
            label: "CLAW",
            src: lowerClawImage,
        },
        {
            value: 3,
            label: "SPEAR",
            src: lowerSpearImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CHAINSAW",
            src: upperChainsawImage,
        },
        {
            value: 1,
            label: "COMBAT DRILL 1",
            src: upperCombatDrill1Image,
        },
        {
            value: 2,
            label: "COMBAT DRILL 2",
            src: upperCombatDrill2Image,
        },
        {
            value: 3,
            label: "DD288",
            src: upperDD288Image,
        },
        {
            value: 4,
            label: "FIST",
            src: upperFistImage,
        },
        {
            value: 5,
            label: "HEAVY IKAKALAKA",
            src: upperHeavyIkakalakaImage,
            backSrc: upperHeavyIkakalakaUnderImage,
        },
        {
            value: 6,
            label: "IKAKALAKA",
            src: upperIkakalakaImage,
        },
        {
            value: 7,
            label: "MAMBELE",
            src: upperFistImage,
            frontSrc: upperMambeleImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHAINSAW",
            src: glowChainsawImage,
        },
        {
            value: 1,
            label: "COMBAT DRILL 1",
            src: glowCombatDrill1Image,
        },
        {
            value: 2,
            label: "COMBAT DRILL 2",
            src: glowCombatDrill2Image,
        },
        {
            value: 3,
            label: "DD288",
            src: glowDD288Image,
        },
        {
            value: 4,
            label: "COLOSSUS TERMO-UNGUS",
            frontSrc: glowCOLOSSUSTERMOUNGUSImage,
        },
        {
            value: 5,
            label: "COLOSSUS TERMO-UNGUS + ARCS",
            frontSrc: glowCOLOSSUSTERMOUNGUSArcsImage,
        },
        {
            value: 6,
            label: "COLOSSUS TERMO-UNGUS + STEAM",
            frontSrc: glowCOLOSSUSTERMOUNGUSSteamImage,
        },
        {
            value: 7,
            label: "STEAM",
            frontSrc: glowSteamImage,
        },
        {
            value: 8,
            label: "HEATSINKS",
            frontSrc: glowHeatsinksImage,
        },
        {
            value: 9,
            label: "EMPAKAAI EYES",
            frontSrc: glowEmpakaaiEyesImage,
        },
        {
            value: 10,
            label: "EMPAKAAI - ALL",
            frontSrc: glowEmpakaaiAllImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "THE ARM",
            value: "weapon",
        },
        {
            label: "LOWER ARM",
            value: "secondaryWeapon",
        },
        {
            label: "UPPER ARM",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "UPPER ARM",
        "weapon": "THE ARM",
        "secondaryWeapon": "LOWER ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
