export const removeTintValues = {
    "skinTintValue": "none",
    "headTintValue": "none",
    "faceTintValue": "none",
    "eyesTintValue": "none",
    "hairTintValue": "none",
    "facialHairTintValue": "none",
    "chestTintValue": "none",
    "legsTintValue": "none",
    "leftArmTintValue": "none",
    "rightArmTintValue": "none",
    "leftWeaponTintValue": "none",
    "rightWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
};
