import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyScorpiOImage = `${BaseURL}/Mechs/Custom/Scorpi-O/Chassis.png`;

// HEADS
const headScorpiOImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/Head - Scorpi-O.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/Head - Kazu.png`;
// PREMIUM
const headLaborerImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Malice.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Cyclops.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Chimera.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Mag.png`;
const headMetalmarkImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Metalmark.png`;
const headMonarchImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Monarch.png`;
const headScopehoundImage = `${BaseURL}/Mechs/Custom/Scorpi-O/heads/premium/Head - Scopehound.png`;

// WEAPONS
// LEFT
const weaponsLeftPistonImage = `${BaseURL}/Mechs/Custom/Scorpi-O/weapons/Weapons - Left - Piston.png`;
const weaponsLeftShieldImage = `${BaseURL}/Mechs/Custom/Scorpi-O/weapons/Weapons - Left - Shield.png`;

// RIGHT
const weaponsRightPistonImage = `${BaseURL}/Mechs/Custom/Scorpi-O/weapons/Weapons - Right - Piston.png`;
const weaponsRightQuadMGImage = `${BaseURL}/Mechs/Custom/Scorpi-O/weapons/Weapons - Right - Quad MG.png`;
const weaponsRightGatlingCannonImage = `${BaseURL}/Mechs/Custom/Scorpi-O/weapons/Weapons - Right - Gatling Cannon.png`;
const weaponsRightPlasmaCannonImage = `${BaseURL}/Mechs/Custom/Scorpi-O/weapons/Weapons - Right - Plasma Cannon.png`;

// REAR MOUNT
const rearMountHeatSinksOpenImage = `${BaseURL}/Mechs/Custom/Scorpi-O/rear mounts/Rear - Heat Sinks - Open.png`;
const rearMountHeatSinksClosedImage = `${BaseURL}/Mechs/Custom/Scorpi-O/rear mounts/Rear - Heat Sinks - Closed.png`;
const rearMountSmokeLauncherImage = `${BaseURL}/Mechs/Custom/Scorpi-O/rear mounts/Rear - Smoke Launchers.png`;
const rearMountMissilesPodImage = `${BaseURL}/Mechs/Custom/Scorpi-O/rear mounts/Rear - Missiles Pod x2.png`;
const rearMountMachinegunImage = `${BaseURL}/Mechs/Custom/Scorpi-O/rear mounts/Rear - Machinegun x2.png`;
const rearMountMachinegunMissilesImage = `${BaseURL}/Mechs/Custom/Scorpi-O/rear mounts/Rear - Machinegun and Missiles.png`;

// ACCESSORY
const accessoryHeatSinkImage = `${BaseURL}/Mechs/Custom/Scorpi-O/accessories/Glow - Heatsinks.png`;
const accessoryPlasmaCannonImage = `${BaseURL}/Mechs/Custom/Scorpi-O/accessories/Glow - Plasma Cannon.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Scorpi-O/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "21px",
    right: "44px",
};

export default {
    previewImg: previewImage,
    removeNone: {
        head: true,
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SCORPI-O",
            src: headScorpiOImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "METALMARK",
            src: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "MONARCH",
            src: headMonarchImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SCORPI-O",
            src: bodyScorpiOImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "PISTON",
                src: weaponsLeftPistonImage,
            },
            {
                value: 1,
                label: "SHIELD",
                src: weaponsLeftShieldImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "PISTON",
            src: weaponsRightPistonImage,
        },
        {
            value: 1,
            label: "QUAD MACHINEGUN",
            src: weaponsRightQuadMGImage,
        },
        {
            value: 2,
            label: "GATLING CANNON",
            src: weaponsRightGatlingCannonImage,
        },
        {
            value: 3,
            label: "ENERGY CANNON",
            src: weaponsRightPlasmaCannonImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "HEAT SINKS - CLOSED",
            frontSrc: rearMountHeatSinksClosedImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "HEAT SINKS - OPEN",
            frontSrc: rearMountHeatSinksOpenImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "SMOKE LAUNCHERS",
            frontSrc: rearMountSmokeLauncherImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "MISSILES POD X2",
            frontSrc: rearMountMissilesPodImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "MACHINEGUNS X2",
            frontSrc: rearMountMachinegunImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "MACHINEGUN + MISSILES",
            frontSrc: rearMountMachinegunMissilesImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "ENERGY CANNON",
            frontSrc: accessoryPlasmaCannonImage,
        },
        {
            value: 1,
            label: "HEAT SINK",
            frontSrc: accessoryHeatSinkImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
