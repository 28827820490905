import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const torsoImage = `${BaseURL}/Mechs/Custom/Dreadnought/Torso.png`;
const turretImage = `${BaseURL}/Mechs/Custom/Dreadnought/Turret.png`;
const armLeftImage = `${BaseURL}/Mechs/Custom/Dreadnought/Left Arm.png`;
const armRightImage = `${BaseURL}/Mechs/Custom/Dreadnought/Right Arm.png`;
const legsImage = `${BaseURL}/Mechs/Custom/Dreadnought/Legs.png`;
const legsWheelImage = `${BaseURL}/Mechs/Custom/Dreadnought/Legs - Wheel Mode.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Dreadnought/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Dreadnought/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "26px",
    right: "7px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1h",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HOWITZER",
            src: turretImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TORSO",
            src: torsoImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1h": [
            {
                value: 0,
                label: "DOUBLE GATLING CANNONS",
                src: armLeftImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DOUBLE GATLING CANNONS",
            src: armRightImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ALL-TERRAIN",
            src: legsImage,
        },
        {
            value: 1,
            label: "WHEEL-MODE",
            src: legsWheelImage,
        },
    ],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "TURRET",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEGS",
            value: "rearMount",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
    ],
    labels: {
        "head": "TURRET",
        "chassis": "CHASSIS",
        "rearMount": "LEGS",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
