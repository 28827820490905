import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// TURRET
const sentryDroneBaseImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Base.png`;

const sentryDroneTurretImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret.png`;
const sentryDroneTurret2Image = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret 2.png`;

const sentryDroneTurretGlowImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret 1 Glow.png`;
const sentryDroneTurret2GlowImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret 2 Glow.png`;

const droneCarapaceImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Carapace Color.png`;
const droneCarapace2Image = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Carapace 2 Color.png`;

const previewImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/preview.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    headTemplateSpecial: ["rearMountValue",],
    defaultValues: `{
    "chassisValue": "0",
    "headValue": "0",
    "rearMountValue": "0",
    "weaponValue": "0",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "TURRET",
            src: sentryDroneTurretImage,
        },
        {
            value: 1,
            label: "TURRET 2",
            src: sentryDroneTurret2Image,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SENTRY DRONE BASE",
            src: sentryDroneBaseImage,
            shadowDetails: shadowDetails,
            originalArtCredit: {
                prefixText: "Commissioned by",
                title: "Veritas",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CARAPACE",
                src: droneCarapaceImage
            },
            {
                value: 1,
                label: "CARAPACE 2",
                src: droneCarapace2Image
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [
        {
            value: 0,
            label: "TURRET",
            foremostSrc: sentryDroneTurretGlowImage,
        },
        {
            value: 1,
            label: "TURRET 2",
            foremostSrc: sentryDroneTurret2GlowImage,
        },
    ],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "TURRET BASE",
            value: "chassis",
        },
        {
            label: "TURRET",
            value: "head",
        },
        {
            label: "GLOW",
            value: "rearMount",
            isGlow: true,
        },
        {
            label: "CARAPACE",
            value: "weapon",
        },
    ],
    labels: {
        "head": "TURRET",
        "chassis": "TURRET BASE",
        "rearMount": "GLOW",
        "weapon": "CARAPACE",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
