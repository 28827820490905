import React, { lazy, Suspense, useContext } from "react";
import { func, string } from "prop-types";
import styled from "styled-components";

import { tabHeaderImages } from "./Tabs/MenuTabs";
import { AnalyticsContext } from "./../Analytics/AnalyticsContextWrapper";
import SpriteImage, { SPRITE_KEYS } from "../common/styles/SpriteImage";

const NewsContent = lazy(() => import("./Content/NewsContent"));
const MembershipContent = lazy(() => import("./Content/MembershipContent"));
const FAQContent = lazy(() => import("./Content/FAQContent"));
const PromoCodesContent = lazy(() => import("./Content/PromoCodesContent"));
const ContactContent = lazy(() => import("./Content/ContactContent"));

export const MenuContentHeader = styled.div`
    display: flex;
    align-content: center;
    justify-content: flex-end;

    h2 {
        display: none;
    }

    img, h3, div {
        color: #FFF;
        margin-left: auto;
        align-self: center;
    }
`;

MenuContentHeader.displayName = "MenuContentHeader";

export const HeaderImage = styled(SpriteImage)`
    margin: auto;
`;

HeaderImage.displayName = "HeaderImage";

export const CloseButton = styled(SpriteImage)`
    margin-right: 0;
    cursor: pointer;

    :active {
        transform: translateY(2px);
    }
`;

CloseButton.displayName = "CloseButton";

const xImage = SPRITE_KEYS.xButton;

function MenuContentContainer({ activeMenuTab, closeTabFunc }) {
    const ReactGA = useContext(AnalyticsContext);

    switch (activeMenuTab) {
        case "Membership":
            ReactGA.modalview(activeMenuTab);

            return (
                <Suspense fallback={null}>
                    <MembershipContent>
                        <MenuContentHeader>
                            <h2>{activeMenuTab}</h2>
                            <CloseButton
                                className={xImage}
                                alt="Close"
                                onClick={closeTabFunc}
                            />
                        </MenuContentHeader>
                    </MembershipContent>
                </Suspense>
            );
        case "FAQ":
            ReactGA.modalview(activeMenuTab);

            return (
                <Suspense fallback={null}>
                    <FAQContent>
                        <MenuContentHeader>
                            <HeaderImage
                                className={tabHeaderImages[activeMenuTab]}
                                alt={activeMenuTab}
                            />
                            <h2>{activeMenuTab}</h2>
                            <CloseButton
                                className={xImage}
                                alt="Close"
                                onClick={closeTabFunc}
                            />
                        </MenuContentHeader>
                    </FAQContent>
                </Suspense>
            );
        case "News":
            ReactGA.modalview(activeMenuTab);

            return (
                <Suspense fallback={null}>
                    <NewsContent>
                        <MenuContentHeader>
                            <HeaderImage
                                className={tabHeaderImages[activeMenuTab]}
                                alt={activeMenuTab}
                            />
                            <h2>{activeMenuTab}</h2>
                            <CloseButton
                                className={xImage}
                                alt="Close"
                                onClick={closeTabFunc}
                            />
                        </MenuContentHeader>
                    </NewsContent>
                </Suspense>
            );
        case "Contact":
            ReactGA.modalview(activeMenuTab);

            return (
                <Suspense fallback={null}>
                    <ContactContent>
                        <MenuContentHeader>
                            <HeaderImage
                                className={tabHeaderImages[activeMenuTab]}
                                alt={activeMenuTab}
                            />
                            <h2>{activeMenuTab}</h2>
                            <CloseButton
                                className={xImage}
                                alt="Close"
                                onClick={closeTabFunc}
                            />
                        </MenuContentHeader>
                    </ContactContent>
                </Suspense>
            );
        case "Promo Codes":
            ReactGA.modalview(activeMenuTab);

            return (
                <Suspense fallback={null}>
                    <PromoCodesContent>
                        <MenuContentHeader>
                            <HeaderImage
                                className={tabHeaderImages[activeMenuTab]}
                                alt={activeMenuTab}
                            />
                            <h2>{activeMenuTab}</h2>
                            <CloseButton
                                className={xImage}
                                alt="Close"
                                onClick={closeTabFunc}
                            />
                        </MenuContentHeader>
                    </PromoCodesContent>
                </Suspense>
            );
        default:
            return null;
    }
}

MenuContentContainer.propTypes = {
    activeMenuTab: string.isRequired,
    closeTabFunc: func.isRequired,
};

export default MenuContentContainer;
