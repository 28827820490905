const contactActiveImage = "bg-contact-active";
const contactInactiveImage = "bg-contact-inactive";

const faqActiveImage = "bg-faq-active";
const faqInactiveImage = "bg-faq-inactive";

const membershipActiveImage = "bg-membership-active";
const membershipInactiveImage = "bg-membership-inactive";

const promoCodesActiveImage = "bg-promo-codes-active";
const promoCodesInactiveImage = "bg-promo-codes-inactive";

const newsActiveImage = "bg-news-active";
const newsInactiveImage = "bg-news-inactive";

export const tabHeaderImages = {
    News: newsActiveImage,
    Membership: membershipActiveImage,
    FAQ: faqActiveImage,
    Contact: contactActiveImage,
    "Promo Codes": promoCodesActiveImage,
};

export default [
    {
        title: "News",
        activeImage: newsActiveImage,
        inactiveImage: newsInactiveImage,
    },
    {
        title: "Membership",
        activeImage: membershipActiveImage,
        inactiveImage: membershipInactiveImage,
    },
    {
        title: "FAQ",
        activeImage: faqActiveImage,
        inactiveImage: faqInactiveImage,
    },
    {
        title: "Promo Codes",
        activeImage: promoCodesActiveImage,
        inactiveImage: promoCodesInactiveImage,
    },
    {
        title: "Contact",
        activeImage: contactActiveImage,
        inactiveImage: contactInactiveImage,
    },
];
