import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyFleaUpImage = `${BaseURL}/Mechs/Horus/Flea/Goblin - Flea Body Cockpit up.png`;
const bodyFleaDownImage = `${BaseURL}/Mechs/Horus/Flea/Goblin - Flea Body Cockpit down.png`;

// 1H
const weaponAutopodImage = `${BaseURL}/Mechs/Horus/Flea/Goblin - Flea Autopod.png`;

// SECONDARY WEAPON
const secondaryWeaponNexusImage = `${BaseURL}/Mechs/Horus/Flea/Goblin - Flea Nexus.png`;

// ACCESSORY
const accessoryGlowUpImage = `${BaseURL}/Mechs/Horus/Flea/Goblin - Flea Glow Cockpit up.png`;
const accessoryGlowDownImage = `${BaseURL}/Mechs/Horus/Flea/Goblin - Flea Glow Cockpit down.png`;
const accessoryPilotImage = `${BaseURL}/Mechs/Horus/Flea/Goblin - Flea Pilot.png`;

const previewImage = `${BaseURL}/Mechs/Horus/Flea/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Horus/Flea/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "83px",
    right: "77px",
};

export default {
    restrictedPreviewImg: restrictedPreviewImage,
    previewImg: previewImage,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "accessory1Value",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "PILOT",
            backSrc: accessoryPilotImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "COCKPIT CLOSED",
            src: bodyFleaDownImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "COCKPIT OPEN",
            src: bodyFleaUpImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AUTOPOD",
                backSrc: weaponAutopodImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "NEXUS",
            src: secondaryWeaponNexusImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "COCKPIT CLOSED",
            src: accessoryGlowDownImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "COCKPIT OPEN",
            src: accessoryGlowUpImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
