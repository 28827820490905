import React, { useContext, useState } from "react";
import styled from "styled-components";

import MenuTabs from "./Tabs/MenuTabs";
import MenuTab from "./Tabs/MenuTab";
import MenuContentContainer from "./MenuContentContainer";
import LoginButton from "../User/Login/LoginButton";
import UserMenu from "./../User/UserMenu";
import MembershipTier from "./../User/MembershipTier";
import { UserAuthContext } from "./../User/UserAuthContextWrapper";
import { ShowLandingContext } from "./../Landing/ShowLandingContextWrapper";
import { HideUIContext } from "./../common/ToggleHideUIContextWrapper";
import { CurrentThemeContext } from "./../common/ThemeContextWrapper";

const MenuContainerWrapper = styled.nav`
    margin-left: auto;
    margin-top: 25px;
    right: 0;
    top: 0px;
    position: fixed;
    text-align: center;
    z-index: 20;

    width: 240px;
    min-width: 240px;

    @media print {
        display: none;
    }

    margin-top: ${props => props.showLanding ? "-700px" : "25px"};
    transition-delay: ${props => props.theme.landingTransitionDuration}s;
    transition-duration: 0.75s;
    transition-property: margin-top;
    transition-timing-function: linear;

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        margin-top: ${props => props.showLanding ? "-700px" : props.currentSide === "mech" ? "210px" : "170px"};
        position: absolute;
    }
`;

MenuContainerWrapper.displayName = "MenuContainerWrapper";

function MenuContainer() {
    const [ activeMenuTab, setActiveMenuTab ] = useState("None");
    const { user } = useContext(UserAuthContext);
    const { showLanding } = useContext(ShowLandingContext);
    const { currentSide } = useContext(CurrentThemeContext);
    const hideUI = useContext(HideUIContext);

    const closeTabFunc = () => {
        return setActiveMenuTab("None");
    };

    const menuTabsEl = MenuTabs.map((menuTab) => {
        return (
            <MenuTab
                key={menuTab.title}
                menuTabObject={menuTab}
                isActive={activeMenuTab === menuTab.title}
                setActiveMenuTabFunc={setActiveMenuTab}
            />
        );
    });

    return (
        <MenuContainerWrapper
            className={hideUI ? "hide-ui" : ""}
            showLanding={showLanding}
            currentSide={currentSide}
        >
            <MembershipTier />
            {!user ? <LoginButton /> : <UserMenu />}
            {menuTabsEl}
            <MenuContentContainer
                activeMenuTab={activeMenuTab}
                closeTabFunc={closeTabFunc}
            />
        </MenuContainerWrapper>
    );
}

export default React.memo(MenuContainer);
