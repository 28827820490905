import { shadowDetailsNone as shadowDetails } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Pitoloklo`;

// BODY
const femaleFinImage = `${imagePath}/retrograde_pitoloklo_female_fin.png`;
const maleFinImage = `${imagePath}/retrograde_pitoloklo_male_fin.png`;
const trimaleFinImage = `${imagePath}/retrograde_pitoloklo_trimale_fin.png`;

// HEADS
const faceplate1Image = `${imagePath}/retrograde_pitoloklo_faceplate_1.png`;
const faceplate2Image = `${imagePath}/retrograde_pitoloklo_faceplate_2.png`;
const faceplate3Image = `${imagePath}/retrograde_pitoloklo_faceplate_3.png`;

// ARMS
const armsImage = `${imagePath}/retrograde_pitoloklo_arms.png`;
const armsGunImage = `${imagePath}/retrograde_pitoloklo_arms_gun.png`;

// OPTION
const gillsMoodFlukesImage = `${imagePath}/retrograde_pitoloklo_gills_and_mood_flukes.png`;

// ACCESSORY
const clothsImage = `${imagePath}/retrograde_pitoloklo_cloths.png`;

const previewImage = `${imagePath}/preview.png`;

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "FACEPLATE 1",
            src: faceplate1Image,
        },
        {
            value: 1,
            label: "FACEPLATE 2",
            src: faceplate2Image,
        },
        {
            value: 2,
            label: "FACEPLATE 3",
            src: faceplate3Image,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "FEMALE FIN",
            src: femaleFinImage,
            shadowDetails,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Celi",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 1,
            label: "MALE FIN",
            src: maleFinImage,
            shadowDetails,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Celi",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 2,
            label: "TRIMALE FIN",
            src: trimaleFinImage,
            shadowDetails,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Celi",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ARMS",
                src: armsImage,
            },
            {
                value: 1,
                label: "ARMS GUN",
                src: armsGunImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "GILLS + MOOD FLUKE",
            frontSrc: gillsMoodFlukesImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "PITOLOKLO CLOTHS",
            src: clothsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMS",
            value: "weapon",
        },
        {
            label: "GILLS",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "ARMS",
        "secondaryWeapon": "GILLS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
