import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Horus/Hydra`;

// BODY
const bodyUnumImage = `${imagePath}/Hydra - Unum.png`;
const bodyPlurumImage = `${imagePath}/Hydra - Plurum.png`;
const bodyPlurumGlowImage = `${imagePath}/Hydra - Plurum Glow.png`;

// HEADS
const headUnumImage = `${imagePath}/heads/Head - Hydra Unum.png`;
const headPlurumImage = `${imagePath}/heads/Head - Hydra Plurum.png`;
const headPlurumGlowImage = `${imagePath}/heads/Head - Hydra Plurum Glow.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
// PREMIUM
const headAImage = `${imagePath}/heads/premium/Head - Factory Standard.png`;
const headBImage = `${imagePath}/heads/premium/Head - Paladin.png`;
const headCImage = `${imagePath}/heads/premium/Head - Ogre.png`;
const headDImage = `${imagePath}/heads/premium/Head - Rogue.png`;
const headEImage = `${imagePath}/heads/premium/Head - Bastion.png`;
const headGImage = `${imagePath}/heads/premium/Head - Scarab.png`;
const headHImage = `${imagePath}/heads/premium/Head - Ramshackle.png`;
const headIImage = `${imagePath}/heads/premium/Head - Slipstream.png`;
const headJImage = `${imagePath}/heads/premium/Head - Bunker.png`;
const headKImage = `${imagePath}/heads/premium/Head - Dungam.png`;
const headLImage = `${imagePath}/heads/premium/Head - Talon.png`;
const headMImage = `${imagePath}/heads/premium/Head - Nelson.png`;
const headNImage = `${imagePath}/heads/premium/Head - Vlad.png`;

// REAR MOUNT
const rearMountDroneNexusImage = `${imagePath}/rear mount/Rear - Drone Nexus.png`;
const rearMountDroneNexusFrontImage = `${imagePath}/rear mount/Rear - Drone Nexus Over.png`;
const rearMountDroneNexusGlowImage = `${imagePath}/rear mount/Rear - Drone Nexus Glow.png`;
const rearMountExDroneNexusImage = `${imagePath}/rear mount/Rear - Expanded Drone Nexus.png`;
const rearMountExDroneNexusGlowImage = `${imagePath}/rear mount/Rear - Expanded Drone Nexus Glow.png`;
const rearMountAnnihilationNexusImage = `${imagePath}/rear mount/Rear - Annihilation Nexus.png`;
const rearMountAnnihilationNexusGlowImage = `${imagePath}/rear mount/Rear - Annihilation Nexus Glow.png`;

const coreImage = `${imagePath}/Hydra (by JuneauGlow).png`;
const core2Image = `${imagePath}/Hydra_by_Gummies139.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "25px",
    right: "-24px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "JuneauGlow",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    weaponSpecial: "secondaryWeaponValue",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "UNUM",
            src: headUnumImage,
        },
        {
            value: 1,
            label: "PLURUM",
            src: headPlurumImage,
        },
        {
            value: 2,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 3,
            label: "PALADIN",
            src: headBImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "OGRE",
            src: headCImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "ROGUE",
            src: headDImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "BASTION",
            src: headEImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCARAB",
            src: headGImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RAMSHACKLE",
            src: headHImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SLIPSTREAM",
            src: headIImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "BUNKER",
            src: headJImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            src: headKImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "TALON",
            src: headLImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NELSON",
            src: headMImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "VLAD",
            src: headNImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "FACTORY STANDARD",
            src: headAImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "UNUM",
            src: bodyUnumImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "PLURUM",
            src: bodyPlurumImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "DRONE",
                backSrc: rearMountDroneNexusGlowImage,
            },
            {
                value: 1,
                label: "EXPANDED DRONE",
                backSrc: rearMountExDroneNexusGlowImage,
            },
            {
                value: 2,
                label: "ANNIHILATION",
                backSrc: rearMountAnnihilationNexusGlowImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DRONE",
            src: rearMountDroneNexusFrontImage,
            backSrc: rearMountDroneNexusImage,
        },
        {
            value: 1,
            label: "EXPANDED DRONE",
            backSrc: rearMountExDroneNexusImage,
        },
        {
            value: 2,
            label: "ANNIHILATION",
            backSrc: rearMountAnnihilationNexusImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "PLURUM HEAD",
            frontSrc: headPlurumGlowImage,
        },
        {
            value: 1,
            label: "PLURUM CHASSIS",
            src: bodyPlurumGlowImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "NEXUS ENERGY",
            value: "weapon",
        },
        {
            label: "NEXUS",
            value: "secondaryWeapon",
        },
        {
            label: "ENERGY 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "ENERGY 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ENERGY 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ENERGY 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "NEXUS ENERGY",
        "secondaryWeapon": "NEXUS",
        "accessory1": "ENERGY 1",
        "accessory2": "ENERGY 2",
        "accessory3": "ENERGY 3",
        "accessory4": "ENERGY 4",
    },
};
