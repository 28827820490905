import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Auroran`

// BODY
const bodyAuroranImage = `${imagePath}/Chassis.png`;

// HEADS
const headAuroranImage = `${imagePath}/heads/Auroran_Heads_Auroran.png`;
const headBlackspotImage = `${imagePath}/heads/Auroran_Heads_Blackspot.png`;
const headCheddahImage = `${imagePath}/heads/Auroran_Heads_Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Auroran_Heads_Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Auroran_Heads_Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Auroran_Heads_Dungam.png`;
const headHornedImage = `${imagePath}/heads/Auroran_Heads_Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Auroran_Heads_Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Auroran_Heads_Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Auroran_Heads_Laborer.png`;
const headMagImage = `${imagePath}/heads/Auroran_Heads_Mag.png`;
const headMaliceImage = `${imagePath}/heads/Auroran_Heads_Malice.png`;
const headMecha1Image = `${imagePath}/heads/Auroran_Heads_Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Auroran_Heads_Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Auroran_Heads_Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Auroran_Heads_Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Auroran_Heads_Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Auroran_Heads_Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Auroran_Heads_Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Auroran_Heads_Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Auroran_Heads_Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Auroran_Heads_Natural Law Hair.png`;
const headNemesisImage = `${imagePath}/heads/Auroran_Heads_Nemesis.png`;
const headRainTransformedImage = `${imagePath}/heads/Auroran_Heads_Rain Transformed.png`;
const headRainTransformedhairImage = `${imagePath}/heads/Auroran_Heads_Rain Transformed hair.png`;
const headViceroyImage = `${imagePath}/heads/Auroran_Heads_Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Auroran_Heads_Visionary.png`;

// WEAPONS
// LEFT
const weaponsLeftLauncherImage = `${imagePath}/weapons/Auroran_Weapons_Launcher.png`;

// RIGHT
const weaponsRightFrontMissileLaunchersImage = `${imagePath}/weapons/Auroran_Accesories Front_Missile Launchers.png`

// REAR MOUNT
const rearMountWingPackImage = `${imagePath}/rear mount/Auroran_Rear mount back_Wing Pack.png`;

// GLOWS
const glowWingPackImage = `${imagePath}/glows/Auroran_Accesories back_Wing pack Glow.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-36px",
    right: "-66px",
};

export default {
    previewImg: previewImage,
    isMegadeus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AURORAN",
            foremostSrc: headAuroranImage,
        },
        {
            value: 1,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
        },
        {
            value: 2,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 3,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
        },
        {
            value: 4,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 5,
            label: "DUNGAM",
            foremostSrc: headDungamImage,
        },
        {
            value: 6,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 8,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 9,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 10,
            label: "MAG",
            foremostSrc: headMagImage,
        },
        {
            value: 11,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 12,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 13,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 14,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 15,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 16,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 17,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 18,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 19,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 20,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 21,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 22,
            label: "NEMESIS",
            foremostSrc: headNemesisImage,
        },
        {
            value: 23,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
        },
        {
            value: 24,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedhairImage,
        },
        {
            value: 25,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 26,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "AURORAN",
            frontSrc: bodyAuroranImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Commissioned by @Fun_Titan. Art by `,
                title: "@SiFSweetman",
                url: "https://twitter.com/SiFSweetman",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "LAUNCHER",
                src: weaponsLeftLauncherImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FRONT MISSILE LAUNCHERS",
            src: weaponsRightFrontMissileLaunchersImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "WING PACK",
            src: rearMountWingPackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "WING PACK",
            src: glowWingPackImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "ALT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "SHOULDERS",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "SHOULDERS",
        "weapon": "WEAPON",
        "secondaryWeapon": "ALT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
