import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Ibis`

// BODY
const bodyIbisImage = `${imagePath}/Ibis_Chassis.png`;

// HEADS
const headIbisImage = `${imagePath}/heads/Ibis_Head_Ibis.png`;
const headIbisVariantImage = `${imagePath}/heads/Ibis_Head_Ibis Variant.png`;
const headBoneheadImage = `${imagePath}/heads/Ibis_Head_Bonehead.png`;
const headBriarImage = `${imagePath}/heads/Ibis_Head_Briar.png`;
const headCheddahImage = `${imagePath}/heads/Ibis_Head_Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Ibis_Head_Chimera.png`;
const headDungamImage = `${imagePath}/heads/Ibis_Head_Dungam.png`;
const headExoneratedImage = `${imagePath}/heads/Ibis_Head_Exonerated.png`;
const headFirstFormImage = `${imagePath}/heads/Ibis_Head_First Form.png`;
const headFinalFormImage = `${imagePath}/heads/Ibis_Head_Final Form.png`;
const headGoblinImage = `${imagePath}/heads/Ibis_Head_Goblin.png`;
const headKazuImage = `${imagePath}/heads/Ibis_Head_Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Ibis_Head_Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Ibis_Head_Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Ibis_Head_Malice.png`;
const headMecha1Image = `${imagePath}/heads/Ibis_Head_Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Ibis_Head_Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Ibis_Head_Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Ibis_Head_Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Ibis_Head_Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Ibis_Head_Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Ibis_Head_Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Ibis_Head_Mecha 8.png`;
const headMourningCloakImage = `${imagePath}/heads/Ibis_Head_Mourning Cloak.png`;
const headNaturalLawImage = `${imagePath}/heads/Ibis_Head_Natural Law.png`;
const headNaturalLawhairImage = `${imagePath}/heads/Ibis_Head_Natural Law hair.png`;
const headPaladinImage = `${imagePath}/heads/Ibis_Head_Paladin.png`;
const headPerfectionImage = `${imagePath}/heads/Ibis_Head_Perfection.png`;
const headPredatorImage = `${imagePath}/heads/Ibis_Head_Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Ibis_Head_Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Ibis_Head_Rain Transformed Hair.png`;
const headScarabImage = `${imagePath}/heads/Ibis_Head_Scarab.png`;
const headStalwartImage = `${imagePath}/heads/Ibis_Head_Stalwart.png`;
const headThothImage = `${imagePath}/heads/Ibis_Head_Thoth.png`;
const headVanquisherImage = `${imagePath}/heads/Ibis_Head_Vanquisher.png`;
const headWiredImage = `${imagePath}/heads/Ibis_Head_Wired.png`;

// ARMS
const armsLowerImage = `${imagePath}/arms/Ibis_Bottom Arms_Arms.png`;

// REAR MOUNT
const rearCloakImage = `${imagePath}/rear/Ibis_Accessories Top_Cloak.png`;

// GLOWS
const glowBodyImage = `${imagePath}/glows/Ibis_Accessories Mid_Body Glow.png`;
const glowBottomArmsImage = `${imagePath}/glows/Ibis_Accessories Top_Bottom Arm Glow.png`;
const glowWiredImage = `${imagePath}/glows/Ibis_Accessories Top_Head Glow Wired.png`;
const glowHeadImage = `${imagePath}/glows/Ibis_Accessories Top_Ibis Head Glow.png`;
const glowHeadVariantImage = `${imagePath}/glows/Ibis_Accessories Top_Ibis Head Glow Variant.png`;
const glowLeftHandHackingImage = `${imagePath}/glows/Ibis_Hand Left_Hacking Glow.png`;
const glowRightHandHackingImage = `${imagePath}/glows/Ibis_Hand Right_Hacking Glow.png`;
const glowBothHandHackingImage = `${imagePath}/glows/Ibis_HandBoth_Hacking Glow.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "21px",
    right: "33px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "1",
        "accessory2Value": "0",
        "accessory3Value": "2",
        "accessory4Value": "7",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "IBIS",
            src: headIbisImage,
        },
        {
            value: 1,
            label: "IBIS - VARIANT",
            src: headIbisVariantImage,
        },
        {
            value: 2,
            label: "BONEHEAD",
            src: headBoneheadImage,
        },
        {
            value: 3,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 6,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 7,
            label: "EXONERATED",
            src: headExoneratedImage,
        },
        {
            value: 8,
            label: "FIRST FORM",
            src: headFirstFormImage,
        },
        {
            value: 9,
            label: "FINAL FORM",
            src: headFinalFormImage,
        },
        {
            value: 10,
            label: "GOBLIN",
            src: headGoblinImage,
        },
        {
            value: 11,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 12,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 13,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 14,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 15,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 16,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 17,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 18,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 19,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 20,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 21,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 22,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 23,
            label: "MOURNING CLOAK",
            src: headMourningCloakImage,
        },
        {
            value: 24,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 25,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawhairImage,
        },
        {
            value: 26,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 27,
            label: "PERFECTION",
            src: headPerfectionImage,
        },
        {
            value: 28,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 29,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 30,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 31,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 32,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 33,
            label: "THOTH",
            src: headThothImage,
        },
        {
            value: 34,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 35,
            label: "WIRED",
            src: headWiredImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "IBIS",
            src: bodyIbisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Commissioned by Vostro. Original art by `,
                title: "shadefish",
                url: "https://twitter.com/ShadeFish1",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BOTTOM ARMS",
                src: armsLowerImage,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [
        {
            value: 0,
            label: "CLOAK",
            foremostSrc: rearCloakImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BODY",
            src: glowBodyImage,
        },
        {
            value: 1,
            label: "BOTTOM ARMS",
            frontSrc: glowBottomArmsImage,
        },
        {
            value: 2,
            label: "HEAD IBIS",
            frontSrc: glowHeadImage,
        },
        {
            value: 3,
            label: "HEAD IBIS VARIANT",
            frontSrc: glowHeadVariantImage,
        },
        {
            value: 4,
            label: "HEAD WIRED",
            frontSrc: glowWiredImage,
        },
        {
            value: 5,
            label: "LEFT HAND HACKING",
            frontSrc: glowLeftHandHackingImage,
        },
        {
            value: 6,
            label: "RIGHT HAND HACKING",
            frontSrc: glowRightHandHackingImage,
        },
        {
            value: 7,
            label: "BOTH HAND HACKING",
            frontSrc: glowBothHandHackingImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMS",
            value: "weapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "ARMS",
        "secondaryWeapon": "EMPTY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
