const unitDefaultValues = `{
    "chassisValue": "0",
    "legsValue": "0",
    "armLeftValue": "0",
    "armRightValue": "0",
    "stanceValue": "1H",
    "headValue": "0",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitHackerValues = `{
    "chassisValue": "11",
    "legsValue": "14",
    "armLeftValue": "27",
    "armRightValue": "27",
    "stanceValue": "1H",
    "headValue": "NONE",
    "rearMountValue": "28",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "7",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitVanguardValues = `{
    "chassisValue": "10",
    "legsValue": "12",
    "armLeftValue": "13",
    "armRightValue": "13",
    "stanceValue": "1H",
    "headValue": "16",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitAssaultValues = `{
    "chassisValue": "9",
    "legsValue": "11",
    "armLeftValue": "9",
    "armRightValue": "9",
    "stanceValue": "2H",
    "headValue": "15",
    "rearMountValue": "11",
    "weaponValue": "4",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "4-2H",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitSniperValues = `{
    "chassisValue": "9",
    "legsValue": "13",
    "armLeftValue": "1",
    "armRightValue": "1",
    "stanceValue": "2H",
    "headValue": "11",
    "rearMountValue": "20",
    "weaponValue": "17",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "17-2H",
    "accessory1Value": "9",
    "accessory2Value": "5",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitSupportValues = `{
    "chassisValue": "4",
    "legsValue": "15",
    "armLeftValue": "10",
    "armRightValue": "10",
    "stanceValue": "2H",
    "headValue": "18",
    "rearMountValue": "17",
    "weaponValue": "11",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "11-2H",
    "accessory1Value": "15",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitFrontlineValues = `{
    "chassisValue": "8",
    "legsValue": "10",
    "armLeftValue": "8",
    "armRightValue": "8",
    "stanceValue": "1H",
    "headValue": "14",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitTankValues = `{
    "chassisValue": "9",
    "legsValue": "21",
    "armLeftValue": "NONE",
    "armRightValue": "NONE",
    "stanceValue": "1H",
    "headValue": "42",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitTurretValues = `{
    "chassisValue": "9",
    "legsValue": "23",
    "armLeftValue": "NONE",
    "armRightValue": "NONE",
    "stanceValue": "1H",
    "headValue": "42",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitLumberPuppyValues = `{
    "chassisValue": "16",
    "legsValue": "18",
    "armLeftValue": "24",
    "armRightValue": "24",
    "stanceValue": "1H",
    "headValue": "NONE",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "17",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitScoutValues = `{
    "chassisValue": "18",
    "legsValue": "24",
    "armLeftValue": "30",
    "armRightValue": "32",
    "stanceValue": "1H",
    "headValue": "NONE",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

const unitPangolinValues = `{
    "chassisValue": "19",
    "legsValue": "25",
    "armLeftValue": "11",
    "armRightValue": "11",
    "stanceValue": "1H",
    "headValue": "NONE",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "weaponSelectionValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",

    "chassisTintValue": "none",
    "legsTintValue": "none",
    "armLeftTintValue": "none",
    "armRightTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
}`;

function makeTemplateValues(frameIndex) {
    return `{
        "chassisValue": "${frameIndex}",
        "legsValue": "${frameIndex}",
        "armLeftValue": "${frameIndex}",
        "armRightValue": "${frameIndex}",
        "stanceValue": "1H",
        "headValue": "${frameIndex}",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "weaponSelectionValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",

        "chassisTintValue": "none",
        "legsTintValue": "none",
        "armLeftTintValue": "none",
        "armRightTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`
}

export default {
    unitDefaultValues,
    unitHackerValues,
    unitVanguardValues,
    unitAssaultValues,
    unitSniperValues,
    unitSupportValues,
    unitFrontlineValues,
    unitTankValues,
    unitTurretValues,
    unitLumberPuppyValues,
    unitScoutValues,
    unitPangolinValues,
    makeTemplateValues: makeTemplateValues,
};
