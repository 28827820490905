import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/SSC/Swallowtail-2`;

// BODY
const bodySwallowtailImage = `${imagePath}/Legs - Swallowtail.png`;
const bodyRangerImage = `${imagePath}/Legs - Ranger.png`;
const bodySwallowtoesImage = `${imagePath}/Legs - Swallowtoes.png`;

// HEADS
const turretSwallowtailImage = `${imagePath}/heads/Turret - Swallowtail.png`;
const turretRangerImage = `${imagePath}/heads/Turret - Ranger.png`;

// WEAPONS
// LEFT
const leftArrayImage = `${imagePath}/weapons/Left Mount - Array.png`;
const leftAutocannonImage = `${imagePath}/weapons/Left Mount - Autocannon.png`;
const leftMahcinegunCoverImage = `${imagePath}/weapons/Left Mount - Mahcinegun Cover.png`;
const leftMahcinegunImage = `${imagePath}/weapons/Left Mount - Mahcinegun.png`;
const leftSwallowtailCannonImage = `${imagePath}/weapons/Left Mount - Swallowtail Cannon.png`;

// RIGHT
const rightArrayImage = `${imagePath}/weapons/Right Mount - Array.png`;
const rightAutocannonImage = `${imagePath}/weapons/Right Mount - Autocannon.png`;
const rightMachinegunImage = `${imagePath}/weapons/Right Mount - Machinegun.png`;
const rightMissilePodImage = `${imagePath}/weapons/Right Mount - Missile Pod.png`;

// GLOWS
const glowArrayHackLinesImage = `${imagePath}/Accessories/Accessories - Array Hack lines.png`;
const glowArrayHackImage = `${imagePath}/Accessories/Accessories - Glow - Array Hack.png`;
const glowArraysImage = `${imagePath}/Accessories/Accessories - Glow - Arrays.png`;
const glowArrayLeftImage = `${imagePath}/Accessories/Glow - Left Array.png`;
const glowArrayRightImage = `${imagePath}/Accessories/Glow - Right Array.png`;
const glowRangerTurretImage = `${imagePath}/Accessories/Accessories - Glow - Ranger Turret.png`;
const glowSwallowtailImage = `${imagePath}/Accessories/Accessories - Glow - Swallowtail.png`;

const accRangerCamoflaugeImage = `${imagePath}/Accessories/Accessories - Ranger Camoflauge.png`;
const accRangerCamoflaugeFrontImage = `${imagePath}/Accessories/Accessories - Ranger Camoflauge front.png`;
const accSkirtingImage = `${imagePath}/Accessories/Accessories - Swallowtail Skirting.png`;
const accArms1Image = `${imagePath}/Accessories/Arms - 1.png`;
const accArms2Image = `${imagePath}/Accessories/Arms - 2.png`;
const accArms3Image = `${imagePath}/Accessories/Arms - 3.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage1 = `${imagePath}/SWALLOWTAIL_2_3-AzMiLion.png`;
const coreImage2 = `${imagePath}/SwallowtailCore1-Just like a metaphor.png`;
const coreImage3 = `${imagePath}/rangertail-AzMiLion.png`;
const coreImage4 = `${imagePath}/corebook_rangertail-MechaMaria64.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "75px",
    right: "-31px",
};

export default {
    rearMountsAreChassis: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage1,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: coreImage2,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Just like a metaphor",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: coreImage3,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: coreImage4,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "4",
        "secondaryWeaponValue": "0",
        "accessory1Value": "6",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "8",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SWALLOWTAIL",
            src: turretSwallowtailImage,
        },
        {
            value: 1,
            label: "RANGER",
            src: turretRangerImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SWALLOWTAIL",
            src: bodySwallowtailImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "RANGER",
            src: bodyRangerImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "SWALLOWTOES",
            src: bodySwallowtoesImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ARRAY",
                backSrc: leftArrayImage,
            },
            {
                value: 1,
                label: "AUTOCANNON",
                backSrc: leftAutocannonImage,
            },
            {
                value: 2,
                label: "MACHINEGUN",
                backSrc: leftMahcinegunImage,
            },
            {
                value: 3,
                label: "MACHINEGUN COVER",
                backSrc: leftMahcinegunCoverImage,
            },
            {
                value: 4,
                label: "SWALLOWTAIL CANNON",
                backSrc: leftSwallowtailCannonImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARRAY",
            src: rightArrayImage,
        },
        {
            value: 1,
            label: "AUTOCANNON",
            src: rightAutocannonImage,
        },
        {
            value: 2,
            label: "MACHINEGUN",
            src: rightMachinegunImage,
        },
        {
            value: 3,
            label: "MISSILE POD",
            src: rightMissilePodImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ARRAY HACK LINES",
            frontSrc: glowArrayHackLinesImage,
        },
        {
            value: 1,
            label: "ARRAY HACK",
            frontSrc: glowArrayHackImage,
        },
        {
            value: 2,
            label: "ARRAY - LEFT",
            frontSrc: glowArrayLeftImage,
        },
        {
            value: 3,
            label: "ARRAY - RIGHT",
            frontSrc: glowArrayRightImage,
        },
        {
            value: 4,
            label: "ARRAY - DUAL",
            frontSrc: glowArraysImage,
        },
        {
            value: 5,
            label: "RANGER TURRET",
            frontSrc: glowRangerTurretImage,
        },
        {
            value: 6,
            label: "SWALLOWTAIL TURRET",
            frontSrc: glowSwallowtailImage,
        },
        {
            value: 7,
            label: "ACCESSORY - RANGER CAMOFLAUGE",
            src: accRangerCamoflaugeImage,
            frontSrc: accRangerCamoflaugeFrontImage,
        },
        {
            value: 8,
            label: "ACCESSORY - ARMS 1",
            src: accArms1Image,
        },
        {
            value: 9,
            label: "ACCESSORY - ARMS 2",
            src: accArms2Image,
        },
        {
            value: 10,
            label: "ACCESSORY - ARMS 3",
            src: accArms3Image,
        },
        {
            value: 11,
            label: "ACCESSORY - SWALLOWTAIL SKIRTING",
            src: accSkirtingImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS / ACCESSORY",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "TURRET",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L MOUNT",
            value: "weapon",
        },
        {
            label: "R MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "TURRET",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "L MOUNT",
        "secondaryWeapon": "R MOUNT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "ACCESSORY",
    },
};
