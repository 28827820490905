export default [
    {
        label: "DEFAULT",
        value: "none",
    },
    {
        label: "RED",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(500%) brightness(63%) hue-rotate(313deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 500,
        brightness: 63,
        hueRotate: 313,
        invert: 0,
        contrast: 100,
    },
    {
        label: "GREEN",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(186%) brightness(100%) hue-rotate(45deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 186,
        brightness: 100,
        hueRotate: 45,
        invert: 0,
        contrast: 100,
    },
    {
        label: "BLUE",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(270%) brightness(79%) hue-rotate(167deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 270,
        brightness: 79,
        hueRotate: 167,
        invert: 0,
        contrast: 100,
    },
    {
        label: "YELLOW",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(300%) brightness(157%) hue-rotate(1deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 300,
        brightness: 157,
        hueRotate: 1,
        invert: 0,
        contrast: 100,
    },
    {
        label: "BLACK",
        value: "CUSTOM",
        // value: "sepia(0%) saturate(500%) brightness(45%) hue-rotate(1deg) invert(0%) contrast(100%)",
        sepia: 0,
        saturate: 500,
        brightness: 45,
        hueRotate: 1,
        invert: 0,
        contrast: 100,
    },
    {
        label: "GOLD",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(186%) brightness(93%) hue-rotate(1deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 186,
        brightness: 93,
        hueRotate: 1,
        invert: 0,
        contrast: 100,
    },
    {
        label: "SILVER",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(21%) brightness(110%) hue-rotate(1deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 21,
        brightness: 110,
        hueRotate: 1,
        invert: 0,
        contrast: 100,
    },
    {
        label: "PLATINUM",
        value: "CUSTOM",
        // value: "sepia(27%) saturate(137%) brightness(130%) hue-rotate(192deg) invert(0%) contrast(100%)",
        sepia: 27,
        saturate: 137,
        brightness: 130,
        hueRotate: 192,
        invert: 0,
        contrast: 100,
    },
    {
        label: "PURPLE",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(197%) brightness(91%) hue-rotate(215deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 197,
        brightness: 91,
        hueRotate: 215,
        invert: 0,
        contrast: 100,
    },
    {
        label: "PINK",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(216%) brightness(93%) hue-rotate(263deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 216,
        brightness: 93,
        hueRotate: 263,
        invert: 0,
        contrast: 100,
    },
    {
        label: "SAGE",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(83%) brightness(91%) hue-rotate(37deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 83,
        brightness: 91,
        hueRotate: 37,
        invert: 0,
        contrast: 100,
    },
    {
        label: "TAN",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(119%) brightness(91%) hue-rotate(1deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 119,
        brightness: 91,
        hueRotate: 1,
        invert: 0,
        contrast: 100,
    },
    {
        label: "ORANGE",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(242%) brightness(96%) hue-rotate(334deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 242,
        brightness: 96,
        hueRotate: 334,
        invert: 0,
        contrast: 100,
    },
    {
        label: "AQUA",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(167%) brightness(91%) hue-rotate(115deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 167,
        brightness: 91,
        hueRotate: 115,
        invert: 0,
        contrast: 100,
    },
    {
        label: "SCARLET",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(500%) brightness(45%) hue-rotate(310deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 500,
        brightness: 45,
        hueRotate: 310,
        invert: 0,
        contrast: 100,
    },
    {
        label: "GUNSTEEL",
        value: "CUSTOM",
        // value: "sepia(25%) saturate(250%) brightness(60%) hue-rotate(150deg) invert(0%) contrast(100%)",
        sepia: 25,
        saturate: 250,
        brightness: 60,
        hueRotate: 150,
        invert: 0,
        contrast: 100,
    },
    {
        label: "DAYKNIGHT",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(286%) brightness(106%) hue-rotate(341deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 286,
        brightness: 106,
        hueRotate: 341,
        invert: 0,
        contrast: 100,
    },
    {
        label: "SPEARMINT",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(300%) brightness(90%) hue-rotate(110deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 300,
        brightness: 90,
        hueRotate: 110,
        invert: 0,
        contrast: 100,
    },
    {
        label: "CHERENKOV",
        value: "CUSTOM",
        // value: "sepia(73%) saturate(500%) brightness(79%) hue-rotate(134deg) invert(0%) contrast(100%)",
        sepia: 73,
        saturate: 500,
        brightness: 79,
        hueRotate: 134,
        invert: 0,
        contrast: 100,
    },
    {
        label: "ALLOY",
        value: "CUSTOM",
        // value: "sepia(62%) saturate(25%) brightness(85%) hue-rotate(143deg) invert(0%) contrast(100%)",
        sepia: 62,
        saturate: 25,
        brightness: 85,
        hueRotate: 143,
        invert: 0,
        contrast: 100,
    },
    {
        label: "DIJON",
        value: "CUSTOM",
        // value: "sepia(64%) saturate(364%) brightness(93%) hue-rotate(14deg) invert(0%) contrast(100%)",
        sepia: 64,
        saturate: 364,
        brightness: 93,
        hueRotate: 14,
        invert: 0,
        contrast: 100,
    },
    {
        label: "DULL COPPER",
        value: "CUSTOM",
        // value: "sepia(48%) saturate(226%) brightness(93%) hue-rotate(359deg) invert(0%) contrast(100%)",
        sepia: 48,
        saturate: 226,
        brightness: 93,
        hueRotate: 359,
        invert: 0,
        contrast: 100,
    },
    {
        label: "DARK GREEN",
        value: "CUSTOM",
        // value: "sepia(65%) saturate(293%) brightness(69%) hue-rotate(57deg) invert(0%) contrast(100%)",
        sepia: 65,
        saturate: 293,
        brightness: 69,
        hueRotate: 57,
        invert: 0,
        contrast: 100,
    },
    {
        label: "BRONZE",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(175%) brightness(71%) hue-rotate(338deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 175,
        brightness: 71,
        hueRotate: 338,
        invert: 0,
        contrast: 100,
    },
    {
        label: "BONE",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(96%) brightness(114%) hue-rotate(1deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 96,
        brightness: 114,
        hueRotate: 1,
        invert: 0,
        contrast: 100,
    },
    {
        label: "NIGHT GHOST BLUE",
        value: "CUSTOM",
        // value: "sepia(77%) saturate(332%) brightness(114%) hue-rotate(182deg) invert(0%) contrast(100%)",
        sepia: 77,
        saturate: 332,
        brightness: 114,
        hueRotate: 182,
        invert: 0,
        contrast: 100,
    },
    {
        label: "TOP BRASS",
        value: "CUSTOM",
        // value: "sepia(88%) saturate(202%) brightness(130%) hue-rotate(4deg) invert(0%) contrast(100%)",
        sepia: 88,
        saturate: 202,
        brightness: 130,
        hueRotate: 4,
        invert: 0,
        contrast: 100,
    },
    {
        label: "WROUGHT IRON",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(31%) brightness(63%) hue-rotate(202deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 31,
        brightness: 63,
        hueRotate: 202,
        invert: 0,
        contrast: 100,
    },
    {
        label: "TORRINGTON CYAN",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(145%) brightness(120%) hue-rotate(125deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 145,
        brightness: 120,
        hueRotate: 125,
        invert: 0,
        contrast: 100,
    },
    {
        label: "NOVA ORANGE",
        value: "CUSTOM",
        // value: "sepia(100%) saturate(500%) brightness(100%) hue-rotate(318deg) invert(0%) contrast(100%)",
        sepia: 100,
        saturate: 500,
        brightness: 100,
        hueRotate: 318,
        invert: 0,
        contrast: 100,
    },
    {
        label: "ROYAL NAVY",
        value: "CUSTOM",
        // value: "sepia(79%) saturate(209%) brightness(40%) hue-rotate(170deg) invert(0%) contrast(100%)",
        sepia: 79,
        saturate: 209,
        brightness: 40,
        hueRotate: 170,
        invert: 0,
        contrast: 100,
    },
    {
        label: "SAVED CUSTOM 1",
        value: "SAVED CUSTOM-1",
    },
    {
        label: "SAVED CUSTOM 2",
        value: "SAVED CUSTOM-2",
    },
    {
        label: "SAVED CUSTOM 3",
        value: "SAVED CUSTOM-3",
    },
    {
        label: "SAVED CUSTOM 4",
        value: "SAVED CUSTOM-4",
    },
    {
        label: "CUSTOM",
        value: "CUSTOM",
    },
    {
        label: "RANDOM",
        value: "RANDOM",
    },
];
