import ArtCreditLink from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Loza`;

// BODY
const bodyLozaImage = `${imagePath}/Chassis.png`;

// HEADS
const headLozaImage = `${imagePath}/heads/Head - Loza.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackSpotImage = `${imagePath}/heads/Head - Black Spot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headPaladinImage = `${imagePath}/heads/Head - Paladin.png`;
const headPartisanImage = `${imagePath}/heads/Head - Partisan.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headScopedogImage = `${imagePath}/heads/Head - Scopedog.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponLeftARImage = `${imagePath}/weapons/left/Left Weapon - AR.png`;
const weaponLeftARAmmoDrumImage = `${imagePath}/weapons/left/Left Weapon - AR Ammo Drum.png`;
const weaponLeftAutoshotgunImage = `${imagePath}/weapons/left/Left Weapon - Autoshotgun.png`;
const weaponLeftHammerImage = `${imagePath}/weapons/left/Left Weapon - Hammer.png`;
const weaponLeftHeavyWrenchImage = `${imagePath}/weapons/left/Left Weapon - Heavy Wrench.png`;
const weaponLeftLaserCarbineImage = `${imagePath}/weapons/left/Left Weapon - Laser Carbine.png`;
const weaponLeftLibraeRifle1Image = `${imagePath}/weapons/left/Left Weapon - Librae Rifle 1.png`;
const weaponLeftLibraeRifle2Image = `${imagePath}/weapons/left/Left Weapon - Librae Rifle 2.png`;
const weaponLeftMinigunImage = `${imagePath}/weapons/left/Left Weapon - Minigun.png`;
const weaponLeftPlasmaCasterImage = `${imagePath}/weapons/left/Left Weapon - Plasma Caster.png`;
const weaponLeftGrenadeLauncherImage = `${imagePath}/weapons/left/Left Weapon - Grenade Launcher.png`;
const weaponLeftPowerMaceImage = `${imagePath}/weapons/left/Left Weapon - Power Mace.png`;

// RIGHT
const weaponRightHandImage = `${imagePath}/weapons/right/Right Weapon - Hand.png`;
const weaponRightFrenchNailImage = `${imagePath}/weapons/right/Right Weapon - French Nail.png`;
const weaponRightHammerImage = `${imagePath}/weapons/right/Right Weapon - Hammer.png`;
const weaponRightHeavyShieldERAImage = `${imagePath}/weapons/right/Right Weapon - Heavy Shield ERA.png`;
const weaponRightHeavyShieldImage = `${imagePath}/weapons/right/Right Weapon - Heavy Shield.png`;
const weaponRightHeavyWrenchImage = `${imagePath}/weapons/right/Right Weapon - Heavy Wrench.png`;
const weaponRightKnifeScabbardImage = `${imagePath}/weapons/right/Right Weapon - Knife Scabbard.png`;
const weaponRightKnifeImage = `${imagePath}/weapons/right/Right Weapon - Knife.png`;
const weaponRightMediumShieldImage = `${imagePath}/weapons/right/Right Weapon - Medium Shield.png`;
const weaponRightRevolverImage = `${imagePath}/weapons/right/Right Weapon - Revolver.png`;

// ACCESSORIES
const accessoryBackpackImage = `${imagePath}/accessories/Accessory - Backpack.png`;
const accessoryCloakImage = `${imagePath}/accessories/Accessory - Cloak.png`;
const accessoryCloakUnderImage = `${imagePath}/accessories/Accessory - Cloak Under.png`;
const accessoryERAImage = `${imagePath}/accessories/Accessory - ERA.png`;
const accessoryShoulderKnifeImage = `${imagePath}/accessories/Accessory - Shoulder Knife.png`;
const accessoryStrapsImage = `${imagePath}/accessories/Accessory - Straps.png`;
const accessoryTorsoKnifeImage = `${imagePath}/accessories/Accessory - Torso Knife.png`;

// GLOWS
const glowChassisImage = `${imagePath}/glows/Glow - Chassis.png`;
const glowChassisBackpackImage = `${imagePath}/glows/Glow - Chassis Backpack.png`;
const glowHaloImage = `${imagePath}/glows/Glow - Halo.png`;
const glowLaserCarbineImage = `${imagePath}/glows/Glow - Laser Carbine.png`;
const glowLibraeRifle1Image = `${imagePath}/glows/Glow - Librae Rifle 1.png`;
const glowLibraeRifle2Image = `${imagePath}/glows/Glow - Librae Rifle 2.png`;
const glowPlasmaCasterImage = `${imagePath}/glows/Glow - Plasma Caster.png`;
const glowPowerMaceImage = `${imagePath}/glows/Glow - Power Mace.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/loza_1-AzMiLion.png`;
const core2Image = `${imagePath}/loza_2-AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "35px",
    right: "50px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "10",
        "secondaryWeaponValue": "0",
        "accessory1Value": "6",
        "accessory2Value": "NONE",
        "accessory3Value": "8",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "LOZA",
            src: headLozaImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 2,
            label: "BLACK SPOT",
            src: headBlackSpotImage,
        },
        {
            value: 3,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 6,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 7,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 8,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 9,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 10,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 11,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 12,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 13,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 14,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 15,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 16,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 17,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 18,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 19,
            label: "PARTISAN",
            src: headPartisanImage,
        },
        {
            value: 20,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 21,
            label: "RAIN",
            src: headRainTransformedImage,
        },
        {
            value: 22,
            label: "RAIN HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 23,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 24,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "HA LOZA",
            src: bodyLozaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Shade Fish",
                url: "https://twitter.com/ShadeFish1",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AR",
                src: weaponLeftARImage,
            },
            {
                value: 1,
                label: "AR + AMMO DRUM",
                src: weaponLeftARAmmoDrumImage,
            },
            {
                value: 2,
                label: "AUTOSHOTGUN",
                src: weaponLeftAutoshotgunImage,
            },
            {
                value: 3,
                label: "GRENADE LAUNCHER",
                src: weaponLeftGrenadeLauncherImage,
            },
            {
                value: 4,
                label: "HAMMER",
                src: weaponLeftHammerImage,
            },
            {
                value: 5,
                label: "HEAVY WRENCH",
                src: weaponLeftHeavyWrenchImage,
            },
            {
                value: 6,
                label: "LASER CARBINE",
                src: weaponLeftLaserCarbineImage,
            },
            {
                value: 7,
                label: "LIBRAE RIFLE 1",
                src: weaponLeftLibraeRifle1Image,
            },
            {
                value: 8,
                label: "LIBRAE RIFLE 2",
                src: weaponLeftLibraeRifle2Image,
            },
            {
                value: 9,
                label: "MINIGUN",
                src: weaponLeftMinigunImage,
            },
            {
                value: 10,
                label: "PLASMA CASTER",
                src: weaponLeftPlasmaCasterImage,
            },
            {
                value: 11,
                label: "POWER MACE",
                src: weaponLeftPowerMaceImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            src: weaponRightHandImage,
        },
        {
            value: 1,
            label: "FRENCH NAIL",
            src: weaponRightFrenchNailImage,
        },
        {
            value: 2,
            label: "HAMMER",
            src: weaponRightHammerImage,
        },
        {
            value: 3,
            label: "HEAVY SHIELD",
            foremostSrc: weaponRightHeavyShieldImage,
        },
        {
            value: 4,
            label: "HEAVY SHIELD ERA",
            foremostSrc: weaponRightHeavyShieldERAImage,
        },
        {
            value: 5,
            label: "HEAVY WRENCH",
            src: weaponRightHeavyWrenchImage,
        },
        {
            value: 6,
            label: "KNIFE",
            src: weaponRightKnifeImage,
        },
        {
            value: 7,
            label: "KNIFE + SCABBARD",
            src: weaponRightKnifeScabbardImage,
        },
        {
            value: 8,
            label: "MEDIUM SHIELD",
            foremostSrc: weaponRightMediumShieldImage,
        },
        {
            value: 9,
            label: "REVOLVER",
            src: weaponRightRevolverImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CHASSIS",
            frontSrc: glowChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS + BACKPACK - GLOW",
            frontSrc: glowChassisBackpackImage,
        },
        {
            value: 2,
            label: "HALO",
            frontSrc: glowHaloImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS - GLOW",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS + BACKPACK - GLOW",
            src: glowChassisBackpackImage,
        },
        {
            value: 2,
            label: "HALO - GLOW",
            src: glowHaloImage,
        },
        {
            value: 3,
            label: "LASER CARBINE - GLOW",
            frontSrc: glowLaserCarbineImage,
        },
        {
            value: 4,
            label: "LIBRAE RIFLE 1 - GLOW",
            frontSrc: glowLibraeRifle1Image,
        },
        {
            value: 5,
            label: "LIBRAE RIFLE 2 - GLOW",
            frontSrc: glowLibraeRifle2Image,
        },
        {
            value: 6,
            label: "PLASMA CASTER - GLOW",
            frontSrc: glowPlasmaCasterImage,
        },
        {
            value: 7,
            label: "POWER MACE - GLOW",
            frontSrc: glowPowerMaceImage,
        },
        {
            value: 8,
            label: "BACKPACK",
            backSrc: accessoryBackpackImage,
        },
        {
            value: 9,
            label: "CLOAK",
            frontSrc: accessoryCloakImage,
            backSrc: accessoryCloakUnderImage,
        },
        {
            value: 10,
            label: "ERA",
            src: accessoryERAImage,
        },
        {
            value: 11,
            label: "SHOULDER KNIFE",
            frontSrc: accessoryShoulderKnifeImage,
        },
        {
            value: 12,
            label: "STRAPS",
            src: accessoryStrapsImage,
        },
        {
            value: 13,
            label: "TORSO KNIFE",
            src: accessoryTorsoKnifeImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "rearMount",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "GLOW 1",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 2",
        "accessory2": "GLOW 3",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
