import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Epialos`;

// BODY
const bodyChitinImage = `${imagePath}/Chassis - Chitin.png`;
const bodySmoothImage = `${imagePath}/Chassis - Smooth.png`;

// HEADS
const headEpialosClosedImage = `${imagePath}/heads/Head - Epialos Closed.png`;
const headEpialosOpenImage = `${imagePath}/heads/Head - Epialos Open.png`;
const headEvaImage = `${imagePath}/heads/Head - Eva.png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNelsonFlightTypeImage = `${imagePath}/heads/Head - Nelson Flight Type.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkull3Image = `${imagePath}/heads/Head - Skull 3.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headZahhakImage = `${imagePath}/heads/Head - Zahhak.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_epialos.png`;

// WEAPONS
// UPPER
const upperArmsEpialosImage = `${imagePath}/arms/upper/Arms 1 - Epialos Claws.png`;
const upperArmsChainaxesImage = `${imagePath}/arms/upper/Arms 1 - Chainaxes.png`;
const upperArmsEnergyRiflesImage = `${imagePath}/arms/upper/Arms 1 - Energy Rifles.png`;
const upperArmsSmoothImage = `${imagePath}/arms/upper/Arms 1 - Smooth.png`;
const upperArmsWingsBackImage = `${imagePath}/arms/upper/epialos_wings_back.png`;
const upperArmsWingsFrontImage = `${imagePath}/arms/upper/epialos_wings_front.png`;

// MID
const midArmsEpialosImage = `${imagePath}/arms/middle/Arms 2 - Epialos Claws.png`;
const midArmsTorchesImage = `${imagePath}/arms/middle/Arms 2 - Torches.png`;
const midArmsBladesImage = `${imagePath}/arms/middle/Arms 2 - Blades.png`;
const midArmsMonowireBladesImage = `${imagePath}/arms/middle/Arms 2 - Monowire Blades.png`;
const midArmsSmoothImage = `${imagePath}/arms/middle/Arms 2 - Smooth.png`;

// LOWER
const lowerArmsEpialosImage = `${imagePath}/arms/lower/Arms 3 - Epialos.png`;
const lowerArmsSmoothImage = `${imagePath}/arms/lower/Arms 3 - Smooth.png`;

// GLOWS
const glowChitinChassisNoMidImage = `${imagePath}/glows/Glow - Chitin Chassis full tail.png`;
// const glowChitinChassisImage = `${imagePath}/glows/Glow - Chitin Chassis.png`;
const glowSynthNettingMidClawsImage = `${imagePath}/glows/Glow - Synthetic Muscle Netting Mid Claws.png`;
const glowSynthNettingMidLowClawsImage = `${imagePath}/glows/Glow - Synthetic Muscle Netting Mid Low Claws.png`;
const glowSynthNettingAllClawsImage = `${imagePath}/glows/Glow - Synthetic Muscle Netting All Claws.png`;
const glowGoopChassisImage = `${imagePath}/glows/Glow - Goop.png`;
const glowArms1ChainaxesImage = `${imagePath}/glows/Glow - Arms 1 Chainaxes.png`;
const glowArms1EnergyRiflesImage = `${imagePath}/glows/Glow - Arms 1 Energy Rifles.png`;
const glowArms2MonowiresImage = `${imagePath}/glows/Glow - Arms 2 Monowires.png`;
const glowArms2Torches1Image = `${imagePath}/glows/Glow - Arms 2 Torches I.png`;
const glowArms2Torches2Image = `${imagePath}/glows/Glow - Arms 2 Torches II.png`;
const glowArms2Torches3Image = `${imagePath}/glows/Glow - Arms 2 Torches III.png`;
const glowAllClawsImage = `${imagePath}/glows/Glow - All Claws.png`;
const glowAllClawsArcsImage = `${imagePath}/glows/Glow - All Claws Arcs.png`;

// ACCESSORY
const accessoryBludeonImage = `${imagePath}/accessories/Tail - Bludgeon.png`;
const accessoryLargeBladeImage = `${imagePath}/accessories/Tail - Large Blade.png`;
const accessoryGrapplerImage = `${imagePath}/accessories/Tail - Grappler.png`;
const accessoryGrapplerHeadImage = `${imagePath}/accessories/Tail - Grappler Head.png`;
const accessoryStingerImage = `${imagePath}/accessories/Tail - Stinger.png`;
const accessoryPilotImage = `${imagePath}/accessories/Accessory - Pilot.png`;
const accessoryPilot2Image = `${imagePath}/accessories/Accessory - Pilot 2.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "48px",
    right: "-11px",
};

export default {
    previewImg: previewImage,
    accessory2IsChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "17",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "EPIALOS - CLOSED",
            foremostSrc: headEpialosClosedImage,
        },
        {
            value: 1,
            label: "EPIALOS - OPEN",
            foremostSrc: headEpialosOpenImage,
        },
        {
            value: 2,
            label: "EVA",
            foremostSrc: headEvaImage,
        },
        {
            value: 3,
            label: "BONEHEAD",
            foremostSrc: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "8 BALL",
            foremostSrc: head8BallImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "DUNGAM",
            foremostSrc: headDungamImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "ENKIDU",
            foremostSrc: headEnkiduImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "HORNED",
            foremostSrc: headHornedImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "KAZU",
            foremostSrc: headKazuImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "MAG",
            foremostSrc: headMagImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
            isPremium: true,
        },
        {
            value: 19,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
            isPremium: true,
        },
        {
            value: 20,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
            isPremium: true,
        },
        {
            value: 21,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
            isPremium: true,
        },
        {
            value: 22,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
            isPremium: true,
        },
        {
            value: 23,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
            isPremium: true,
        },
        {
            value: 24,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
            isPremium: true,
        },
        {
            value: 25,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
            isPremium: true,
        },
        {
            value: 26,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 27,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 28,
            label: "NELSON",
            foremostSrc: headNelsonImage,
            isPremium: true,
        },
        {
            value: 29,
            label: "NELSON FLIGHT TYPE",
            foremostSrc: headNelsonFlightTypeImage,
            isPremium: true,
        },
        {
            value: 30,
            label: "NEMESIS",
            foremostSrc: headNemesisImage,
            isPremium: true,
        },
        {
            value: 31,
            label: "RAIN",
            foremostSrc: headRainImage,
            isPremium: true,
        },
        {
            value: 32,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
            isPremium: true,
        },
        {
            value: 33,
            label: "SKULL 3",
            foremostSrc: headSkull3Image,
            isPremium: true,
        },
        {
            value: 34,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
            isPremium: true,
        },
        {
            value: 35,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
            isPremium: true,
        },
        {
            value: 36,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 37,
            label: "ZAHHAK",
            foremostSrc: headZahhakImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CHITIN",
            src: bodyChitinImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Battle Pope, original by",
                title: "Peter Balogh",
                url: "https://www.artstation.com/peterprime",
            },
        },
        {
            value: 1,
            label: "SMOOTH",
            src: bodySmoothImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "EPIALOS CLAWS",
                src: midArmsEpialosImage,
            },
            {
                value: 1,
                label: "TORCHES",
                src: midArmsTorchesImage,
            },
            {
                value: 2,
                label: "BLADES",
                src: midArmsBladesImage,
            },
            {
                value: 3,
                label: "MONOWIRE BLADES",
                src: midArmsMonowireBladesImage,
            },
            {
                value: 4,
                label: "SMOOTH",
                src: midArmsSmoothImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "EPIALOS CLAWS",
            src: lowerArmsEpialosImage,
        },
        {
            value: 1,
            label: "SMOOTH",
            src: lowerArmsSmoothImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "EPIALOS CLAWS",
            src: upperArmsEpialosImage,
        },
        {
            value: 1,
            label: "CHAINAXES",
            src: upperArmsChainaxesImage,
        },
        {
            value: 2,
            label: "ENERGY RIFLES",
            src: upperArmsEnergyRiflesImage,
        },
        {
            value: 3,
            label: "SMOOTH",
            src: upperArmsSmoothImage,
        },
        {
            value: 4,
            label: "WINGS",
            frontSrc: upperArmsWingsFrontImage,
            backSrc: upperArmsWingsBackImage,
            originalArtCredit: {
                ...contributorList.PINKHYENA,
                isContributorAsset: false,
                prefixText: "Support with +2 patron tier. Commissioned by Toaster. Created by "
            },
            isPlusTwo: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS - CHITIN",
            src: glowChitinChassisNoMidImage,
        },
        {
            value: 1,
            label: "CHASSIS - SYNTH MUSCLE NETTING + MID CLAWS",
            frontSrc: glowSynthNettingMidClawsImage,
        },
        {
            value: 2,
            label: "CHASSIS - SYNTH MUSCLE NETTING + MID + LOW CLAWS",
            frontSrc: glowSynthNettingMidLowClawsImage,
        },
        {
            value: 3,
            label: "CHASSIS - SYNTH MUSCLE NETTING + ALL CLAWS",
            frontSrc: glowSynthNettingAllClawsImage,
        },
        {
            value: 4,
            label: "GOOP",
            frontSrc: glowGoopChassisImage,
        },
        {
            value: 5,
            label: "ALL CLAWS",
            frontSrc: glowAllClawsImage,
        },
        {
            value: 6,
            label: "ALL CLAWS + ARCS",
            frontSrc: glowAllClawsArcsImage,
        },
        {
            value: 7,
            label: "ARMS TOP - CHAINAXES",
            src: glowArms1ChainaxesImage,
        },
        {
            value: 8,
            label: "ARMS TOP - ENERGY RIFLES",
            src: glowArms1EnergyRiflesImage,
        },
        {
            value: 9,
            label: "ARMS MID - MONOWIRES",
            frontSrc: glowArms2MonowiresImage,
        },
        {
            value: 10,
            label: "ARMS MID - TORCHES I",
            frontSrc: glowArms2Torches1Image,
        },
        {
            value: 11,
            label: "ARMS MID - TORCHES II",
            frontSrc: glowArms2Torches2Image,
        },
        {
            value: 12,
            label: "ARMS MID - TORCHES III",
            frontSrc: glowArms2Torches3Image,
        },
        {
            value: 13,
            label: "TAIL - BLUDGEON",
            src: accessoryBludeonImage,
        },
        {
            value: 14,
            label: "TAIL - LARGE BLADE",
            src: accessoryLargeBladeImage,
        },
        {
            value: 15,
            label: "TAIL - GRAPPLER",
            src: accessoryGrapplerImage,
        },
        {
            value: 16,
            label: "TAIL - GRAPPLER + HEAD",
            src: accessoryGrapplerHeadImage,
        },
        {
            value: 17,
            label: "TAIL - STINGER",
            src: accessoryStingerImage,
        },
        {
            value: 18,
            label: "PILOT 1",
            src: accessoryPilotImage,
        },
        {
            value: 19,
            label: "PILOT 2",
            src: accessoryPilot2Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS / ACCESSORY",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMS TOP",
            value: "rearMount",
        },
        {
            label: "ARMS MID",
            value: "weapon",
        },
        {
            label: "ARMS LOW",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ARMS TOP",
        "weapon": "ARMS MID",
        "secondaryWeapon": "ARMS LOW",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 2",
        "accessory4": "ACCESSORY",
    },
};
