import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Raleigh`;

// BODY
const bodyRaleighImage = `${imagePath}/Body.png`;
const bodyRaleighCoverImage = `${imagePath}/Head Over.png`;
const bodyCloakImage = `${imagePath}/Body - Cloak.png`;
// LEGENDARY
const bodyStampedeImage = `${imagePath}/Body - Stampede.png`;
const bodyStampedeCoverImage = `${imagePath}/Head Over - Stampede.png`;

// REAR MOUNT
const rearMountAMRifleImage = `${imagePath}/rear mount/Back - AntiMaterial Rifle.png`;
const rearMountMissilesImage = `${imagePath}/rear mount/Back - Missiles.png`;
const rearMountRPGImage = `${imagePath}/rear mount/Back - RPG.png`;

// HEADS
const headRaleighImage = `${imagePath}/heads/Head - Raleigh.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_raliegh.png`;
// PREMIUM
const headAImage = `${imagePath}/heads/premium/Head A.png`;
const headBImage = `${imagePath}/heads/premium/Head B.png`;
const headCImage = `${imagePath}/heads/premium/Head C.png`;
const headDImage = `${imagePath}/heads/premium/Head D (no over).png`;
const headEImage = `${imagePath}/heads/premium/Head E.png`;
const headFImage = `${imagePath}/heads/premium/Head F.png`;
const headGImage = `${imagePath}/heads/premium/Head G.png`;
const headHImage = `${imagePath}/heads/premium/Head H.png`;
const headIImage = `${imagePath}/heads/premium/Head I.png`;
const headJImage = `${imagePath}/heads/premium/Head J.png`;
const headKImage = `${imagePath}/heads/premium/Head K.png`;
const headLImage = `${imagePath}/heads/premium/Head L.png`;
const headMImage = `${imagePath}/heads/premium/Head M.png`;
const headNImage = `${imagePath}/heads/premium/Head N (no over).png`;
const headOImage = `${imagePath}/heads/premium/Head O.png`;
const headPImage = `${imagePath}/heads/premium/Head P.png`;
const headQImage = `${imagePath}/heads/premium/Head - Gat.png`;
const headRImage = `${imagePath}/heads/premium/Head - Stampede.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;

// ACCESSORY
const accessoryGrenadesMinesImage = `${imagePath}/accessories/Accessory-Grenades and Mines.png`;
// LEGENDARY
const accessoryStampedeCloakImage = `${imagePath}/accessories/Raleigh - Stampede Cloak.png`;
const accessoryStampedeCloakBackImage = `${imagePath}/accessories/Raleigh - Stampede Cloak Under.png`;

// 1H RIGHT
const catalyticHammerRight1HImage = `${imagePath}/weapons/right/Aux Weapon - Catalytic Hammer Right.png`;
const donutRight1HImage = `${imagePath}/weapons/right/Aux Weapon - Donut Right.png`;
const revolverRight1HImage = `${imagePath}/weapons/right/Aux Weapon - Revolver Right.png`;

// 1H LEFT
const revolverLeft1HImage = `${imagePath}/weapons/left/Main Weapon - Revolver.png`;
const boltThrowerLeft1HImage = `${imagePath}/weapons/left/Main Weapon - Bolt Thrower.png`;
const catalyticHammerLeft1HImage = `${imagePath}/weapons/left/Main Weapon - Catalytic Hammer Left.png`;
const hammerLeft1HImage = `${imagePath}/weapons/left/Main Weapon - Hammer.png`;
const hammerHandleLeft1HImage = `${imagePath}/weapons/left/Back - Hammer Handle.png`;
const cycloneCannonLeft1HImage = `${imagePath}/weapons/left/Main Weapon - Cyclone Cannon.png`;
const cycloneCannonBackLeft1HImage = `${imagePath}/weapons/left/Back - Cyclone Cannon.png`;
const howitzerLeft1HImage = `${imagePath}/weapons/left/Main Weapon - Howitzer.png`;
const cannibalShotgunLeft1HImage = `${imagePath}/weapons/left/Main Weapon - Cannibal Shotgun.png`;

const cShotgunRevolverImage = `${imagePath}/weapons/Main Weapon - Cannibal Shotgun and Revolver.png`;
const revolversAkimboImage = `${imagePath}/weapons/Main Weapon - Akimbo Revolvers.png`;
const catalyticHammersAkimboImage = `${imagePath}/weapons/Aux Weapon - Akimbo Catalytic Hammers.png`;
const accChassisDetailImage = `${imagePath}/accessories/chassis-RALEIGH-mid.png`;

const coreImage = `${imagePath}/Raleigh (by JuneauGlow).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "72px",
    right: "70px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "JuneauGlow",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    accessory2IsChassis: true,
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "RALEIGH",
            src: headRaleighImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "BONEHEAD",
            src: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
            removeBodyCover: true,
        },
        {
            value: 3,
            label: "STAMPEDE",
            src: headRImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "FACTORY STANDARD",
            src: headAImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "PALADIN",
            src: headBImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "OGRE",
            src: headCImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "ROGUE",
            src: headDImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 8,
            label: "BASTION",
            src: headEImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "ICARUS",
            src: headFImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SCARAB",
            src: headGImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "RAMSHACKLE",
            src: headHImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "SLIPSTREAM",
            src: headIImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "BUNKER",
            src: headJImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "DUNGAM",
            src: headKImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "TALON",
            src: headLImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "JUDICATOR",
            src: headMImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "RUMBLE",
            src: headNImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 18,
            label: "NELSON",
            src: headOImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "TORTUGA",
            src: headPImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 21,
            label: "GAT",
            src: headQImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            removeBodyCover: true,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "RALEIGH",
            src: bodyRaleighImage,
            frontSrc: bodyRaleighCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "RALEIGH - CLOAK",
            src: bodyCloakImage,
            frontSrc: bodyRaleighCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "STAMPEDE",
            src: bodyStampedeImage,
            frontSrc: bodyStampedeCoverImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "REVOLVER",
                src: revolverLeft1HImage,
            },
            {
                value: 1,
                label: "BOLT THROWER",
                src: boltThrowerLeft1HImage,
            },
            {
                value: 2,
                label: "CATALYTIC HAMMER",
                src: catalyticHammerLeft1HImage,
            },
            {
                value: 3,
                label: "HAMMER",
                src: hammerLeft1HImage,
                backSrc: hammerHandleLeft1HImage,
            },
            {
                value: 4,
                label: "CYCLONE CANNON",
                src: cycloneCannonLeft1HImage,
                backSrc: cycloneCannonBackLeft1HImage,
            },
            {
                value: 5,
                label: "HOWITZER",
                src: howitzerLeft1HImage,
            },
            {
                value: 6,
                label: "REVOLVERS AKIMBO",
                src: revolversAkimboImage,
            },
            {
                value: 7,
                label: "C. HAMMERS AKIMBO",
                src: catalyticHammersAkimboImage,
            },
            {
                value: 8,
                label: "CANNIBAL SHOTGUN",
                src: cannibalShotgunLeft1HImage,
            },
            {
                value: 9,
                label: "CANNIBAL SHOTGUN + REVOLVER",
                src: cShotgunRevolverImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "REVOLVER",
            src: revolverRight1HImage,
        },
        {
            value: 1,
            label: "CATALYTIC HAMMER",
            src: catalyticHammerRight1HImage,
        },
        {
            value: 2,
            label: "DONUT",
            src: donutRight1HImage,
        },
        {
            value: 3,
            label: "REVOLVERS AKIMBO",
            src: revolversAkimboImage,
        },
        {
            value: 4,
            label: "CANNIBAL SHOTGUN + REVOLVER",
            src: cShotgunRevolverImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ANTIMAT RIFLE",
            src: rearMountAMRifleImage,
        },
        {
            value: 1,
            label: "RPG",
            src: rearMountRPGImage,
        },
        {
            value: 2,
            label: "MISSILES",
            src: rearMountMissilesImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "GRENADES + MINES",
            frontSrc: accessoryGrenadesMinesImage,
        },
        {
            value: 1,
            label: "COLOR - CHASSIS DETAILS",
            src: accChassisDetailImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 2,
            label: "STAMPEDE CLOAK",
            src: accessoryStampedeCloakImage,
            backSrc: accessoryStampedeCloakBackImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 2",
        "accessory4": "ACCESSORY 3",
    },
};
