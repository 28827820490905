import BaseURL from "./images/Hosting-BaseURL";
import { shadowDetailsNone } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI-Medea`;

// BODY
const droneAriesDroneSoloImage = `${imagePath}/drones/Chassis - Aries Drone Solo.png`;
const droneAriesCrookImage = `${imagePath}/drones/Drones - Aries Crook.png`;
const droneAriesDrones1Image = `${imagePath}/drones/Drones - Aries Drones 1.png`;
const droneAriesDrones2Image = `${imagePath}/drones/Drones - Aries Drones 2.png`;
const droneAriesDrones3Image = `${imagePath}/drones/Drones - Aries Drones 3.png`;
const droneAriesDrones4Image = `${imagePath}/drones/Drones - Aries Drones 4.png`;

// GLOW
const glowDroneAriesDrones1Image = `${imagePath}/drones/Glow - Aries Drones 1.png`;
const glowDroneAriesDrones2Image = `${imagePath}/drones/Glow - Aries Drones 2.png`;
const glowDroneAriesDrones3Image = `${imagePath}/drones/Glow - Aries Drones 3.png`;
const glowDroneAriesDrones4Image = `${imagePath}/drones/Glow - Aries Drones 4.png`;
const glowDroneAriesDronesSoloImage = `${imagePath}/drones/Glow - Aries Drones Solo.png`;
const glowDroneJasonsLoveAriesDroneImage = `${imagePath}/drones/Glow - Jason_s Love Aries Drone.png`;
const glowDroneSheepCrookImage = `${imagePath}/drones/Glow - Sheep Crook.png`;

const previewImage = `${imagePath}/drones/Chassis - Aries Drone Solo.png`;

const originalArtCredit = {
    prefixText: "Commissioned by ",
    title: "Pysbomb",
    url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
};

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    isMegadeus: true,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ARIES DRONES 1",
            src: droneAriesDrones1Image,
        },
        {
            value: 1,
            label: "ARIES DRONES 2",
            src: droneAriesDrones2Image,
        },
        {
            value: 2,
            label: "ARIES DRONES 3",
            src: droneAriesDrones3Image,
        },
        {
            value: 3,
            label: "ARIES DRONES 4",
            src: droneAriesDrones4Image,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ARIES DRONE SOLO",
            src: droneAriesDroneSoloImage,
            originalArtCredit,
            shadowDetails,
        },
        {
            value: 1,
            label: "JASON'S LOVE ARIES DRONE",
            src: glowDroneJasonsLoveAriesDroneImage,
            originalArtCredit,
            shadowDetails,
        },
        {
            value: 2,
            label: "ARIES DRONE - CROOK",
            src: droneAriesCrookImage,
            originalArtCredit,
            shadowDetails,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ARIES DRONES SOLO",
            frontSrc: glowDroneAriesDronesSoloImage,
        },
        {
            value: 1,
            label: "JASON'S LOVE ARIES DRONE",
            frontSrc: glowDroneJasonsLoveAriesDroneImage,
        },
        {
            value: 2,
            label: "SHEEP CROOK",
            frontSrc: glowDroneSheepCrookImage,
        },
        {
            value: 3,
            label: "ARIES DRONES 1",
            frontSrc: glowDroneAriesDrones1Image,
        },
        {
            value: 4,
            label: "ARIES DRONES 2",
            frontSrc: glowDroneAriesDrones2Image,
        },
        {
            value: 5,
            label: "ARIES DRONES 3",
            frontSrc: glowDroneAriesDrones3Image,
        },
        {
            value: 6,
            label: "ARIES DRONES 4",
            frontSrc: glowDroneAriesDrones4Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "DRONES",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "DRONES",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
