import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MO_S-Donner`;

// BODY
const bodyDonnerImage = `${imagePath}/Chassis.png`;

// INNER ARMS
const innerArmsARImage = `${imagePath}/rearmount/Inner Arms - AR.png`;
const innerArmsClawbladesImage = `${imagePath}/rearmount/Inner Arms - Clawblades.png`;
const innerArmsGrabbiesImage = `${imagePath}/rearmount/Inner Arms - Grabbies.png`;
const innerArmsKnivesImage = `${imagePath}/rearmount/Inner Arms - Knives.png`;
const innerArmsMachinePistolImage = `${imagePath}/rearmount/Inner Arms - Machine Pistol.png`;

// WEAPONS
// LEFT
const weaponsLeftCarrionClawImage = `${imagePath}/weapons/left/Left Arm - Carrion Claw.png`;
const weaponsLeftChirurgeonClawImage = `${imagePath}/weapons/left/Left Arm - Chirurgeon Claw.png`;
const weaponsLeftPilebunkerImage = `${imagePath}/weapons/left/Left Arm - Pilebunker.png`;
// RIGHT
const weaponsRightCarrionClawImage = `${imagePath}/weapons/right/Right Arm - Carrion Claw.png`;
const weaponsRightChirurgeonClawImage = `${imagePath}/weapons/right/Right Arm - Chirurgeon Claw.png`;
const weaponsRightPilebunkerImage = `${imagePath}/weapons/right/Right Arm - Pilebunker.png`;

// REAR MOUNT
const rearMountAutocannonsImage = `${imagePath}/accessories/Back - Autocannons.png`;
const rearMountCloakDronesImage = `${imagePath}/accessories/Back - Cloak + Drones.png`;
const rearMountCloakImage = `${imagePath}/accessories/Back - Cloak.png`;
const rearMountCloakUnderImage = `${imagePath}/accessories/Back - Cloak Under.png`;
const rearMountIgorDroneSwarmImage = `${imagePath}/accessories/Back - Igor Drone Swarm.png`;
const rearMountIgorDroneSwarmUnderImage = `${imagePath}/accessories/Back - Igor Drone Swarm Under.png`;
const rearMountOrbImage = `${imagePath}/accessories/Back - O R B.png`;

// GLOWS
const glowEyesImage = `${imagePath}/glows/Glow - Eyes.png`;
const glowIgorDroneSwarmImage = `${imagePath}/glows/Glow - Igor Drones Swarm.png`;
const glowOrbImage = `${imagePath}/glows/Glow - O R B.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "76px",
    right: "-4px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "2",
        "rearMountValue": "2",
        "weaponValue": "1",
        "secondaryWeaponValue": "1",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AR",
            src: innerArmsARImage,
        },
        {
            value: 1,
            label: "CLAWBLADES",
            src: innerArmsClawbladesImage,
        },
        {
            value: 2,
            label: "GRABBIES",
            src: innerArmsGrabbiesImage,
        },
        {
            value: 3,
            label: "KNIVES",
            src: innerArmsKnivesImage,
        },
        {
            value: 4,
            label: "MACHINE PISTOL",
            src: innerArmsMachinePistolImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MO&S DONNER",
            src: bodyDonnerImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Vox ",
                title: "Link in News",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CARRION CLAW",
                src: weaponsLeftCarrionClawImage,
            },
            {
                value: 1,
                label: "CHIRURGEON CLAW",
                src: weaponsLeftChirurgeonClawImage,
            },
            {
                value: 2,
                label: "PILEBUNKER",
                src: weaponsLeftPilebunkerImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CARRION CLAW",
            src: weaponsRightCarrionClawImage,
        },
        {
            value: 1,
            label: "CHIRURGEON CLAW",
            src: weaponsRightChirurgeonClawImage,
        },
        {
            value: 2,
            label: "PILEBUNKER",
            src: weaponsRightPilebunkerImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "AUTOCANNONS",
            frontSrc: rearMountAutocannonsImage,
        },
        {
            value: 1,
            label: "CLOAK",
            frontSrc: rearMountCloakImage,
            src: rearMountCloakUnderImage,
        },
        {
            value: 2,
            label: "IGOR DRONE SWARM",
            frontSrc: rearMountIgorDroneSwarmImage,
            src: rearMountIgorDroneSwarmUnderImage,
        },
        {
            value: 3,
            label: "CLOAK + DRONES",
            frontSrc: rearMountCloakDronesImage,
            src: rearMountIgorDroneSwarmUnderImage,
        },
        {
            value: 4,
            label: "O R B",
            frontSrc: rearMountOrbImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "EYES",
            frontSrc: glowEyesImage,
        },
        {
            value: 1,
            label: "IGOR DRONE SWARM",
            frontSrc: glowIgorDroneSwarmImage,
        },
        {
            value: 2,
            label: "O R B",
            frontSrc: glowOrbImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "INNER ARMS",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "INNER ARMS",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
