import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Balorina`;

// BODY
const body1Image = `${imagePath}/Chassis 1.png`;
const body2Image = `${imagePath}/Chassis 2.png`;
const body3Image = `${imagePath}/Chassis 3.png`;

// HEADS
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHoodRazorscarfImage = `${imagePath}/heads/Head - Hood + Razorscarf.png`;
const headHornsDownImage = `${imagePath}/heads/Head - Horns Down.png`;
const headHornsUpImage = `${imagePath}/heads/Head - Horns Up.png`;
const headBlackspotImage = `${imagePath}/glows/Glow - Blackspot.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam 2.png`;
const headEnkiduImage = `${imagePath}/glows/Glow - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNelsonFTImage = `${imagePath}/heads/Head - Nelson Flight Type.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headZahhakImage = `${imagePath}/heads/Head - Zahhak.png`;
const head8BallImage = `${imagePath}/glows/Glow - 8 Ball.png`;

// WEAPONS
// LEFT
const weaponsLeftHandImage = `${imagePath}/weapons/left/Left Arm - Hand.png`;
const weaponsLeftClawImage = `${imagePath}/weapons/left/Left Arm - Claw.png`;
const weaponsLeftCannonImage = `${imagePath}/weapons/left/Left Arm - Cannon.png`;
const weaponsLeftWhipclawImage = `${imagePath}/weapons/left/Left Arm - Whipclaw.png`;
// RIGHT
const weaponsRightHandImage = `${imagePath}/weapons/right/Right Arm - Hand.png`;
const weaponsRightClawImage = `${imagePath}/weapons/right/Right Arm - Claws.png`;
const weaponsRightSwordImage = `${imagePath}/weapons/right/Right Arm - Sword.png`;
const weaponsRightCannonImage = `${imagePath}/weapons/right/Right Arm - Cannon.png`;
const weaponsRightWhipclawImage = `${imagePath}/weapons/right/Right Arm - Whipclaw.png`;

// ACCESSORIES
const accessoryNephtisRingImage = `${imagePath}/accessory/Accessory - Nephtis Ring.png`;
const accessoryShoulderHaloImage = `${imagePath}/accessory/Accessory - Shoulder Halo.png`;
const accessoryStoleImage = `${imagePath}/accessory/Accessory - Stole.png`;
const accessoryStole2Image = `${imagePath}/accessory/Accessory - Stole 2.png`;
const accessoryStole3Image = `${imagePath}/accessory/Stole 3.png`;
const accessoryNephtisCuirassImage = `${imagePath}/accessory/Accessory - Nephtis Cuirass.png`;
const accessoryLoinclothImage = `${imagePath}/accessory/Accessory - Loincloth.png`;
const accessoryLoinclothUnderImage = `${imagePath}/accessory/Accessory - Loincloth Under.png`;
const accessoryHellsImage = `${imagePath}/accessory/balorina hells.png`;

// GLOW
const glowChassis1Image = `${imagePath}/nanites/Glow - Chassis 1 Nanites.png`;
const glowChassis1FlamesImage = `${imagePath}/nanites/Glow - Chassis 1 Nanites + Flames.png`;
const glowChassis2Image = `${imagePath}/nanites/Glow - Chassis 2 Nanites.png`;
const glowChassis2FlamesImage = `${imagePath}/nanites/Glow - Chassis 2 Nanites + Flames.png`;

const glowHoodImage = `${imagePath}/glows/Glow - Hood.png`;
const glowHoodRazorscarfImage = `${imagePath}/glows/Glow - Hood + Razorscarf.png`;
const glowRazorscarfImage = `${imagePath}/glows/Glow - Razorscarf.png`;
const glowHornsUpImage = `${imagePath}/glows/Glow - Horns Up.png`;
const glowHornsDownImage = `${imagePath}/glows/Glow - Horns Down.png`;
const glowNephtisRingImage = `${imagePath}/glows/Glow - Nephtis Ring.png`;
const glowShoulderHaloImage = `${imagePath}/glows/Glow - Shoulder Halo.png`;
const glowEschatologicalOffenseImage = `${imagePath}/glows/Glow - Eschatological Offense.png`;
const glowLeftClawImage = `${imagePath}/glows/Glow - Left Claw.png`;
const glowRightClawImage = `${imagePath}/glows/Glow - Right Claw.png`;
// const glowDualClawImage = `${imagePath}/glows/Glow - Dual Claws.png`;
const glowLeftCannonImage = `${imagePath}/glows/Glow - Left Cannon.png`;
const glowRightCannonImage = `${imagePath}/glows/Glow - Right Cannon.png`;
const glowLeftWhipclawEnkiduImage = `${imagePath}/glows/Left Arm - Whipclaw Enkidu Glow.png`;
const glowLeftWhipclaw1Image = `${imagePath}/glows/Left Arm - Whipclaw Glow 1.png`;
const glowLeftWhipclaw2Image = `${imagePath}/glows/Left Arm - Whipclaw Glow 2.png`;
const glowRightWhipclawEnkiduImage = `${imagePath}/glows/Right Arm - Whipclaw Enkidu Glow.png`;
const glowRightWhipclaw1Image = `${imagePath}/glows/Right Arm - Whipclaw Glow 1.png`;
const glowRightWhipclaw2Image = `${imagePath}/glows/Right Arm - Whipclaw Glow 2.png`;
const glowStole32Image = `${imagePath}/glows/Stole 3 Glow 2.png`;
const glowStole31Image = `${imagePath}/glows/Stole 3 Glow.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "23px",
    right: "5px",
};

export default {
    rearMountsAreChassis: true,
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "3",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "15",
        "accessory2Value": "8",
        "accessory3Value": "18",
        "accessory4Value": "2",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HOOD",
            src: headHoodImage,
        },
        {
            value: 1,
            label: "HOOD + RAZORSCARF",
            src: headHoodRazorscarfImage,
        },
        {
            value: 2,
            label: "HORNS DOWN",
            src: headHornsDownImage,
        },
        {
            value: 3,
            label: "HORNS UP",
            src: headHornsUpImage,
        },
        {
            value: 4,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 5,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 10,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 11,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 12,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 13,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 14,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 15,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 16,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 17,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 18,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 19,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 20,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 21,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 22,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 23,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 24,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 25,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 26,
            label: "NELSON FT",
            src: headNelsonFTImage,
        },
        {
            value: 27,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 28,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 29,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 30,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 31,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 32,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 33,
            label: "ZAHHAK",
            src: headZahhakImage,
        },
        {
            value: 34,
            label: "8 BALL",
            src: head8BallImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CHASSIS 1",
            src: body1Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "DHDragon",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 1,
            label: "CHASSIS 2",
            src: body2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "DHDragon",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 2,
            label: "CHASSIS 3",
            src: body3Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "DHDragon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                backSrc: weaponsLeftHandImage,
            },
            {
                value: 1,
                label: "CLAW",
                backSrc: weaponsLeftClawImage,
            },
            {
                value: 2,
                label: "CANNON",
                backSrc: weaponsLeftCannonImage,
            },
            {
                value: 3,
                label: "WHIPCLAW",
                backSrc: weaponsLeftWhipclawImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            src: weaponsRightHandImage,
        },
        {
            value: 1,
            label: "CLAW",
            src: weaponsRightClawImage,
        },
        {
            value: 2,
            label: "SWORD",
            src: weaponsRightSwordImage,
        },
        {
            value: 3,
            label: "CANNON",
            src: weaponsRightCannonImage,
        },
        {
            value: 4,
            label: "WHIPCLAW",
            src: weaponsRightWhipclawImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "NEPHTIS RING",
            src: accessoryNephtisRingImage,
        },
        {
            value: 1,
            label: "NEPHTIS CUIRASS",
            src: accessoryNephtisCuirassImage,
        },
        {
            value: 2,
            label: "SHOULDER HALO",
            src: accessoryShoulderHaloImage,
        },
        {
            value: 3,
            label: "LOINCLOTH",
            src: accessoryLoinclothImage,
            backSrc: accessoryLoinclothUnderImage,
        },
        {
            value: 4,
            label: "STOLE 1",
            foremostSrc: accessoryStoleImage,
        },
        {
            value: 5,
            label: "STOLE 2",
            foremostSrc: accessoryStole2Image,
        },
        {
            value: 6,
            label: "STOLE 3",
            foremostSrc: accessoryStole3Image,
        },
        {
            value: 7,
            label: "HEELS",
            foremostSrc: accessoryHellsImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 8,
            label: "NANITES - CHASSIS 1",
            src: glowChassis1Image,
        },
        {
            value: 9,
            label: "NANITES + FLAMES - CHASSIS 1",
            src: glowChassis1FlamesImage,
        },
        {
            value: 10,
            label: "NANITES - CHASSIS 2",
            src: glowChassis2Image,
        },
        {
            value: 11,
            label: "NANITES + FLAMES - CHASSIS 2",
            src: glowChassis2FlamesImage,
        },
        {
            value: 12,
            label: "GLOW - HOOD",
            frontSrc: glowHoodImage,
        },
        {
            value: 13,
            label: "GLOW - HOOD + RAZORSCARF",
            frontSrc: glowHoodRazorscarfImage,
        },
        {
            value: 14,
            label: "GLOW - RAZORSCARF",
            frontSrc: glowRazorscarfImage,
        },
        {
            value: 15,
            label: "GLOW - HORNS UP",
            frontSrc: glowHornsUpImage,
        },
        {
            value: 16,
            label: "GLOW - HORNS DOWN",
            frontSrc: glowHornsDownImage,
        },
        {
            value: 17,
            label: "GLOW - NEPHTIS RING",
            src: glowNephtisRingImage,
        },
        {
            value: 18,
            label: "GLOW - SHOULDER HALO",
            src: glowShoulderHaloImage,
        },
        {
            value: 19,
            label: "GLOW - ESCHATOLOGICAL OFFENSE",
            src: glowEschatologicalOffenseImage,
        },
        {
            value: 20,
            label: "GLOW - LEFT CLAW",
            src: glowLeftClawImage,
        },
        {
            value: 21,
            label: "GLOW - RIGHT CLAW",
            frontSrc: glowRightClawImage,
        },
        {
            value: 22,
            label: "GLOW - DUAL CLAWS",
            frontSrc: glowRightClawImage,
            src: glowLeftClawImage,
        },
        {
            value: 23,
            label: "GLOW - LEFT CANNON",
            src: glowLeftCannonImage,
        },
        {
            value: 24,
            label: "GLOW - RIGHT CANNON",
            frontSrc: glowRightCannonImage,
        },
        {
            value: 25,
            label: "GLOW - DUAL CANNONS",
            frontSrc: glowRightCannonImage,
            src: glowLeftCannonImage,
        },
        {
            value: 26,
            label: "GLOW - LEFT WHIPCLAW 1",
            src: glowLeftWhipclaw1Image,
        },
        {
            value: 27,
            label: "GLOW - RIGHT WHIPCLAW 1",
            frontSrc: glowRightWhipclaw1Image,
        },
        {
            value: 28,
            label: "GLOW - DUAL WHIPCLAWS 1",
            frontSrc: glowRightWhipclaw1Image,
            src: glowLeftWhipclaw1Image,
        },
        {
            value: 29,
            label: "GLOW - LEFT WHIPCLAW 2",
            src: glowLeftWhipclaw2Image,
        },
        {
            value: 30,
            label: "GLOW - RIGHT WHIPCLAW 2",
            frontSrc: glowRightWhipclaw2Image,
        },
        {
            value: 31,
            label: "GLOW - DUAL WHIPCLAWS 2",
            frontSrc: glowRightWhipclaw2Image,
            src: glowLeftWhipclaw2Image,
        },
        {
            value: 32,
            label: "GLOW - LEFT WHIPCLAW ENKIDU",
            src: glowLeftWhipclawEnkiduImage,
        },
        {
            value: 33,
            label: "GLOW - RIGHT WHIPCLAW ENKIDU",
            frontSrc: glowRightWhipclawEnkiduImage,
        },
        {
            value: 34,
            label: "GLOW - DUAL WHIPCLAWS ENKIDU",
            frontSrc: glowRightWhipclawEnkiduImage,
            src: glowLeftWhipclawEnkiduImage,
        },
        {
            value: 35,
            label: "GLOW - STOLE 3",
            foremostSrc: glowStole31Image,
        },
        {
            value: 36,
            label: "GLOW - STOLE 3 - ALT",
            foremostSrc: glowStole32Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "ARMS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
