import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const unitPath = `${BaseURL}/Mechs/NPC/Corpro/Barricade`;

// BODY
const bodyBarricadeImage = `${unitPath}/Barricade.png`;

// MAIN WEAPON
const wallImage = `${unitPath}/glow/Aux - Wall.png`;

// GLOW
const wallGlowImage = `${unitPath}/glow/Glow - Chassis Wall.png`;
const chassisGlowImage = `${unitPath}/glow/Glow - Chassis.png`;
const eyeGlowImage = `${unitPath}/glow/Barricade Eye Glow.png`;
const eyeWallGlowImage = `${unitPath}/glow/Barricade Chassis Cubes Eye Glow.png`;
const eyeChassisGlowImage = `${unitPath}/glow/Barricade Chassis Eye Glow.png`;
const simCamo1Image = `${unitPath}/camo/Camo - Sim.png`;
const simCamo2Image = `${unitPath}/camo/Camo - Sim 2.png`;

// CAMO
const corproCamo1Image = `${unitPath}/camo/Camo - Corpro 1.png`;
const corproCamo2Image = `${unitPath}/camo/Camo - Corpro 2.png`;
const forestCamo1Image = `${unitPath}/camo/Camo - Forest 1.png`;
const forestCamo2Image = `${unitPath}/camo/Camo - Forest 2.png`;
const urbanCamo1Image = `${unitPath}/camo/Camo - Urban 1.png`;
const urbanCamo2Image = `${unitPath}/camo/Camo - Urban 2.png`;
const headCamoImage = `${unitPath}/camo/Camo - Head Only.png`;
const tigerCamoImage = `${unitPath}/camo/Camo - Tiger.png`;
const halftoneVertCamoImage = `${unitPath}/camo/Camo - Halftone Vert.png`;
const halftoneDiagCamoImage = `${unitPath}/camo/Camo - Halftone Diag.png`;
const survivorCamoImage = `${unitPath}/camo/Camo - Survivor.png`;

const previewImage = `${unitPath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "17px",
    right: "-24px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "4",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    defaultInactiveValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "1",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "BARRICADE",
            src: bodyBarricadeImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: [
        {
            value: 0,
            label: "WALL",
            frontSrc: wallImage,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "CHASSIS",
            src: chassisGlowImage,
        },
        {
            value: 1,
            label: "CHASSIS + WALL",
            frontSrc: wallGlowImage,
        },
        {
            value: 2,
            label: "EYE",
            frontSrc: eyeGlowImage,
        },
        {
            value: 3,
            label: "CHASSIS + EYE",
            frontSrc: eyeChassisGlowImage,
        },
        {
            value: 4,
            label: "CHASSIS + WALL + EYE",
            frontSrc: eyeWallGlowImage,
        },
        {
            value: 5,
            label: "SIM 1",
            src: simCamo1Image,
        },
        {
            value: 6,
            label: "SIM 2",
            src: simCamo2Image,
        },
    ],
    camo: [
        {
            value: 0,
            label: "HEAD",
            src: headCamoImage,
        },
        {
            value: 1,
            label: "CORPRO 1",
            src: corproCamo1Image,
        },
        {
            value: 2,
            label: "CORPRO 2",
            src: corproCamo2Image,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
        },
        {
            value: 5,
            label: "URBAN 1",
            src: urbanCamo1Image,
            isPremium: true,
        },
        {
            value: 6,
            label: "URBAN 2",
            src: urbanCamo2Image,
            isPremium: true,
        },
        {
            value: 7,
            label: "TIGER",
            src: tigerCamoImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HALFTONE VERT",
            src: halftoneVertCamoImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HALFTONE DIAG",
            src: halftoneDiagCamoImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SURVIVOR",
            src: survivorCamoImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
