import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/HA/Iskander-2`;

// BODY
const bodyIskanderImage = `${imagePath}/Chassis.png`;

// HEADS
const headIskanderImage = `${imagePath}/heads/Head - Iskander 2.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headYareYareDazeImage = `${imagePath}/heads/Head - Yare Yare Daze.png`;
// PREMIUM
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackbeard2Image = `${imagePath}/heads/Head - Blackbeard 2.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headHammerheadImage = `${imagePath}/heads/Head - Hammerhead.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headPartisanImage = `${imagePath}/heads/Head - Partisan.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headScopedogImage = `${imagePath}/heads/Head - Scopedog.png`;
const headTaurusImage = `${imagePath}/heads/Head - Taurus.png`;

// GLOW
const glowChassisImage = `${imagePath}/glows/Glow - Chassis.png`;
const glowChassisAntigravImage = `${imagePath}/glows/Glow - Chassis Antigrav.png`;
const glowCalibanImage = `${imagePath}/glows/Glow - Caliban.png`;
const glowGravGunImage = `${imagePath}/glows/Glow - Grav Gun.png`;
const glowLeftHandImage = `${imagePath}/glows/Glow - Left Hand.png`;
const glowLeftHandNadeImage = `${imagePath}/glows/Glow - Left Hand Nade.png`;
const glowLeftHandMineImage = `${imagePath}/glows/Glow - Left Hand Mine.png`;
const glowRightHandImage = `${imagePath}/glows/Glow - Right Hand.png`;
const glowRightHandNadeImage = `${imagePath}/glows/Glow - Rightt Hand Nade.png`;
const glowRightHandMineImage = `${imagePath}/glows/Glow - Right Hand Mine.png`;
const glowDualHandsImage = `${imagePath}/glows/Glow - Dual Hands.png`;
const glowDualHandsNadesImage = `${imagePath}/glows/Glow - Both Hands Nades.png`;
const glowDualHandsMinesImage = `${imagePath}/glows/Glow - Both Hands Mines.png`;

// ACCESSORY
const accessoryExplosivesImage = `${imagePath}/accessories/Accessory - Explosives.png`;
const accessoryCloakImage = `${imagePath}/accessories/Accessory - Cloak.png`;

// WEAPONS
// LEFT
const weaponLeftHandImage = `${imagePath}/weapons/left/Left Arm - Hand.png`;
const weaponLeftHandNadeImage = `${imagePath}/weapons/left/Left Arm - Hand Nade.png`;
const weaponLeftHandMineImage = `${imagePath}/weapons/left/Left Arm - Hand Mine.png`;
const weaponLeftSpearImage = `${imagePath}/weapons/left/Left Arm - Spear.png`;
const weaponLeftGravityGunImage = `${imagePath}/weapons/left/Left Arm - Gravity Gun.png`;
const weaponLeftGravityGunOrbImage = `${imagePath}/weapons/left/Left Arm - Gravity Gun orb.png`;
const weaponLeftLauncherImage = `${imagePath}/weapons/left/Left Arm - Launcher.png`;
const weaponLeftLauncher2Image = `${imagePath}/weapons/left/Left Arm - Launcher 2.png`;
// RIGHT
const weaponRightHandImage = `${imagePath}/weapons/right/Right Arm - Hand.png`;
const weaponRightHandNadeImage = `${imagePath}/weapons/right/Right Arm - Hand nade.png`;
const weaponRightHandMineImage = `${imagePath}/weapons/right/Right Arm - Hand Mine.png`;
const weaponRightShieldImage = `${imagePath}/weapons/right/Right Arm - Shield.png`;
const weaponRightLauncherImage = `${imagePath}/weapons/right/Right Arm - Launcher.png`;
const weaponRightAnnihilatorImage = `${imagePath}/weapons/right/Right Arm - Annihilator.png`;

// REAR MOUNT
const shouldersRightLaunchersImage = `${imagePath}/rear mounts/Shoulders - Right Launchers.png`;
const shouldersLeftLaunchersImage = `${imagePath}/rear mounts/Shoulders - Left Launchers.png`;
const shouldersDualLaunchersImage = `${imagePath}/rear mounts/Shoulders - Dual Launchers.png`;

const previewImage = `${imagePath}/preview.png`;
const coreImage = `${imagePath}/ISKANDER_2_COREBOOK_AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-56px",
    right: "-54px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "6",
        "secondaryWeaponValue": "1",
        "accessory1Value": "13",
        "accessory2Value": "0",
        "accessory3Value": "8",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ISKANDER",
            src: headIskanderImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "YARE YARE DAZE",
            src: headYareYareDazeImage,
        },
        {
            value: 3,
            label: "ANGULAR",
            src: headAngularImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "BLACKBEARD 2.0",
            src: headBlackbeard2Image,
            isPremium: true,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "HAMMERHEAD",
            src: headHammerheadImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "LUDONAUT",
            src: headLudonautImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "MECHA 1",
            src: headMecha1Image,
            isPremium: true,
        },
        {
            value: 10,
            label: "MECHA 2",
            src: headMecha2Image,
            isPremium: true,
        },
        {
            value: 11,
            label: "MECHA 3",
            src: headMecha3Image,
            isPremium: true,
        },
        {
            value: 12,
            label: "MECHA 5",
            src: headMecha5Image,
            isPremium: true,
        },
        {
            value: 13,
            label: "MECHA 8",
            src: headMecha8Image,
            isPremium: true,
        },
        {
            value: 14,
            label: "PARTISAN",
            src: headPartisanImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "PREDATOR",
            src: headPredatorImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "SCOPEDOG",
            src: headScopedogImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "TAURUS",
            src: headTaurusImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ISKANDER",
            src: bodyIskanderImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                src: weaponLeftHandImage,
            },
            {
                value: 1,
                label: "HAND + NADE",
                src: weaponLeftHandNadeImage,
            },
            {
                value: 2,
                label: "HAND + MINE",
                src: weaponLeftHandMineImage,
            },
            {
                value: 3,
                label: "SPEAR",
                frontSrc: weaponLeftSpearImage,
            },
            {
                value: 4,
                label: "GRAVITY GUN",
                src: weaponLeftGravityGunImage,
            },
            {
                value: 5,
                label: "GRAVITY GUN + ORB",
                src: weaponLeftGravityGunOrbImage,
            },
            {
                value: 6,
                label: "LAUNCHER 1",
                src: weaponLeftLauncherImage,
            },
            {
                value: 7,
                label: "LAUNCHER 2",
                src: weaponLeftLauncher2Image,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            src: weaponRightHandImage,
        },
        {
            value: 1,
            label: "HAND + NADE",
            src: weaponRightHandNadeImage,
        },
        {
            value: 2,
            label: "HAND + MINE",
            src: weaponRightHandMineImage,
        },
        {
            value: 3,
            label: "SHIELD",
            src: weaponRightShieldImage,
        },
        {
            value: 4,
            label: "LAUNCHER",
            src: weaponRightLauncherImage,
        },
        {
            value: 5,
            label: "ANNIHILATOR",
            src: weaponRightAnnihilatorImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "LEFT LAUNCHER",
            frontSrc: shouldersLeftLaunchersImage,
        },
        {
            value: 1,
            label: "RIGHT LAUNCHER",
            frontSrc: shouldersRightLaunchersImage,
        },
        {
            value: 2,
            label: "DUAL LAUNCHERS",
            frontSrc: shouldersDualLaunchersImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS + ANTIGRAV",
            src: glowChassisAntigravImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            frontSrc: glowCalibanImage,
        },
        {
            value: 3,
            label: "GRAV GUN",
            frontSrc: glowGravGunImage,
        },
        {
            value: 4,
            label: "LEFT - HAND",
            frontSrc: glowLeftHandImage,
        },
        {
            value: 5,
            label: "LEFT - NADE",
            frontSrc: glowLeftHandNadeImage,
        },
        {
            value: 6,
            label: "LEFT - MINE",
            frontSrc: glowLeftHandMineImage,
        },
        {
            value: 7,
            label: "RIGHT - HAND",
            frontSrc: glowRightHandImage,
        },
        {
            value: 8,
            label: "RIGHT - NADE",
            frontSrc: glowRightHandNadeImage,
        },
        {
            value: 9,
            label: "RIGHT - MINE",
            frontSrc: glowRightHandMineImage,
        },
        {
            value: 10,
            label: "DUAL - HANDS",
            frontSrc: glowDualHandsImage,
        },
        {
            value: 11,
            label: "DUAL - NADES",
            frontSrc: glowDualHandsNadesImage,
        },
        {
            value: 12,
            label: "DUAL - MINES",
            frontSrc: glowDualHandsMinesImage,
        },
        {
            value: 13,
            label: "ACCESSORY - CLOAK",
            src: accessoryCloakImage,
        },
        {
            value: 14,
            label: "ACCESSORY - EXPLOSIVES",
            src: accessoryExplosivesImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORY / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "SHOULDERS",
            value: "rearMount",
        },
        {
            label: "LEFT",
            value: "weapon",
        },
        {
            label: "RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY",
            value: "accessory1",
        },
        {
            label: "GLOW 1",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "SHOULDERS",
        "weapon": "LEFT",
        "secondaryWeapon": "RIGHT",
        "accessory1": "ACCESSORY",
        "accessory2": "GLOW 1",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
