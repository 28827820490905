import React, { createContext, useEffect } from "react";
import ReactGA from "react-ga";

export const AnalyticsContext = createContext(null);

function AnalyticsContextWrapper ({ ...props }) {
    useEffect(() => {
        ReactGA.initialize("UA-146052155-1", { testMode: process.env.NODE_ENV === "test" });
        ReactGA.pageview("/");
    }, []);

    return (
        <AnalyticsContext.Provider value={ReactGA}>
            {props.children}
        </AnalyticsContext.Provider>
    );
}

export default AnalyticsContextWrapper;
