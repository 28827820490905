import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/DFG-Avarice`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis.png`;

// HEADS
const headAvariceImage = `${imagePath}/heads/Head - Avarice.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEmpakaaiImage = `${imagePath}/heads/Head - Empakaai.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponBladeImage = `${imagePath}/weapons/Left Arm - Blade.png`;
const weaponDetonatorImage = `${imagePath}/weapons/Left Arm - Detonator.png`;
const weaponHandImage = `${imagePath}/weapons/Left Arm - Hand.png`;
const weaponMineImage = `${imagePath}/weapons/Left Arm - Mine.png`;
const weaponMineLauncherImage = `${imagePath}/weapons/Left Arm - Mine Launcher.png`;

// BACK
const backClaymoresImage = `${imagePath}/weapons/Back - Claymores.png`;
const backGrenadesImage = `${imagePath}/weapons/Back - Grenades.png`;
const backMicromissilesImage = `${imagePath}/weapons/Back - Micromissiles.png`;

// GLOWS
const glowAvariceVisorEyeImage = `${imagePath}/glows/Glow - Avarice Visor Eye.png`;
const glowAvariceVisorEyesImage = `${imagePath}/glows/Glow - Avarice Visor Eyes.png`;
const glowAvariceVisorFullImage = `${imagePath}/glows/Glow - Avarice Visor Full.png`;
const glowAvariceVisorLinesImage = `${imagePath}/glows/Glow - Avarice Visor Lines.png`;
const glowDetonatorImage = `${imagePath}/glows/Glow - Detonator.png`;
const glowEmpakaaiEyesImage = `${imagePath}/glows/Glow - Empakaai Eyes.png`;
const glowMineLauncherImage = `${imagePath}/glows/Glow - Mine Launcher.png`;
const glowMineReactorArcImage = `${imagePath}/glows/Glow - Mine Reactor Arc.png`;
const glowMineImage = `${imagePath}/glows/Glow - Mine.png`;
const glowReactorImage = `${imagePath}/glows/Glow - Reactor.png`;
const glowAllMinesImage = `${imagePath}/glows/Glow - All Mines.png`;
const glowAllMinesArcsImage = `${imagePath}/glows/Glow - All Mines Arcs.png`;
const glowAllMinesClaymoresImage = `${imagePath}/glows/Glow - All Mines Claymores.png`;
const glowAllMinesGrenadesImage = `${imagePath}/glows/Glow - All Mines Grenades.png`;
const glowAllMinesMicromissilesImage = `${imagePath}/glows/Glow - All Mines Micromissiles.png`;

// SOLO MINE
const mineSoloImage = `${imagePath}/mine/Chassis - Mine.png`;
const mineGlowImage = `${imagePath}/mine/Glow - Solo Mine.png`;
const mineGlowArcsImage = `${imagePath}/mine/Glow - Solo Mine Arcs.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "25px",
    right: "-23px",
};

export default {
    rearMountsAreChassis: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "3",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "2",
        "accessory2Value": "9",
        "accessory3Value": "10",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AVARICE",
            src: headAvariceImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 6,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 7,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 8,
            label: "EMPAKAAI",
            src: headEmpakaaiImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 10,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 11,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 12,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 13,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 14,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 15,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 16,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 17,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 18,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 19,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 20,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 21,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 22,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 23,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 24,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 25,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 26,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 27,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DFG AVARICE",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
        {
            value: 1,
            label: "MINE",
            src: mineSoloImage,
            shadowDetails: shadowTypes.NONE,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BLADE",
                src: weaponBladeImage,
            },
            {
                value: 1,
                label: "DETONATOR",
                src: weaponDetonatorImage,
            },
            {
                value: 2,
                label: "HAND",
                src: weaponHandImage,
            },
            {
                value: 3,
                label: "MINE",
                src: weaponMineImage,
            },
            {
                value: 4,
                label: "MINE LAUNCHER",
                src: weaponMineLauncherImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CLAYMORES",
            src: backClaymoresImage,
        },
        {
            value: 1,
            label: "GRENADES",
            src: backGrenadesImage,
        },
        {
            value: 2,
            label: "MICROMISSILES",
            src: backMicromissilesImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "AVARICE VISOR - EYE",
            frontSrc: glowAvariceVisorEyeImage,
        },
        {
            value: 1,
            label: "AVARICE VISOR - EYES",
            frontSrc: glowAvariceVisorEyesImage,
        },
        {
            value: 2,
            label: "AVARICE VISOR - FULL",
            frontSrc: glowAvariceVisorFullImage,
        },
        {
            value: 3,
            label: "AVARICE VISOR - LINES",
            frontSrc: glowAvariceVisorLinesImage,
        },
        {
            value: 4,
            label: "DETONATOR",
            frontSrc: glowDetonatorImage,
        },
        {
            value: 5,
            label: "EMPAKAAI EYES",
            frontSrc: glowEmpakaaiEyesImage,
        },
        {
            value: 6,
            label: "MINE LAUNCHER",
            frontSrc: glowMineLauncherImage,
        },
        {
            value: 7,
            label: "MINE",
            frontSrc: glowMineImage,
        },
        {
            value: 8,
            label: "REACTOR",
            frontSrc: glowReactorImage,
        },
        {
            value: 9,
            label: "MINE + REACTOR + ARCS",
            frontSrc: glowMineReactorArcImage,
        },
        {
            value: 10,
            label: "BACK MINES",
            frontSrc: glowAllMinesImage,
        },
        {
            value: 11,
            label: "BACK MINES + ARCS",
            frontSrc: glowAllMinesArcsImage,
        },
        {
            value: 12,
            label: "BACK MINES + CLAYMORES",
            frontSrc: glowAllMinesClaymoresImage,
        },
        {
            value: 13,
            label: "BACK MINES + GRENADES",
            frontSrc: glowAllMinesGrenadesImage,
        },
        {
            value: 14,
            label: "BACK MINES + MICROMISSILES",
            frontSrc: glowAllMinesMicromissilesImage,
        },
        {
            value: 15,
            label: "SOLO MINE",
            frontSrc: mineGlowImage,
        },
        {
            value: 16,
            label: "SOLO MINE + ARCS",
            frontSrc: mineGlowArcsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT HAND",
            value: "weapon",
        },
        {
            label: "BACK",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "LEFT HAND",
        "secondaryWeapon": "BACK",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
