import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Horus/Kobold`;

// BODY
const bodyKoboldImage = `${imagePath}/Chassis.png`;
const bodySlagImage = `${imagePath}/Slag - Cool.png`;

// HEADS
const headKoboldImage = `${imagePath}/heads/Head - Kobold.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_Kobold.png`;
// PREMIUM
const headFactoryStandardImage = `${imagePath}/heads/premium/Head - Factory Standard.png`;
const headPaladinImage = `${imagePath}/heads/premium/Head - Paladin.png`;
const headOgreImage = `${imagePath}/heads/premium/Head - Ogre.png`;
const headRogueImage = `${imagePath}/heads/premium/Head - Rogue.png`;
const headRumbleImage = `${imagePath}/heads/premium/Head - Rumble.png`;
const headSlipstreamImage = `${imagePath}/heads/premium/Head - Slipstream.png`;
const headJudicatorImage = `${imagePath}/heads/premium/Head - Judicator.png`;
const headDungamImage = `${imagePath}/heads/premium/Head - Dungam.png`;
const headBunkerImage = `${imagePath}/heads/premium/Head - Bunker.png`;
const headNelsonImage = `${imagePath}/heads/premium/Head - Nelson.png`;
const headSkullImage = `${imagePath}/heads/premium/Head - Skull.png`;
const headPartisanImage = `${imagePath}/heads/premium/Head - Partisan.png`;
const headDwarfImage = `${imagePath}/heads/premium/Head - Dwarf.png`;
const headIronSkullImage = `${imagePath}/heads/premium/Head - Iron Skull.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;
const headBriarImage = `${imagePath}/heads/premium/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/premium/Head - Cheddah.png`;
const headHornedImage = `${imagePath}/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/premium/Head - Horned Hair.png`;
const headMagImage = `${imagePath}/heads/premium/Head - Mag.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headRainTransformedImage = `${imagePath}/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/premium/Head - Rain Transformed Hair.png`;
const headVanquisherImage = `${imagePath}/heads/premium/Head - Vanquisher.png`;

// WEAPONS
const weaponsPistolImage = `${imagePath}/weapons/Weapons - Left - Pistol.png`;
const weaponsRevolverImage = `${imagePath}/weapons/Weapons - Left - Revolver.png`;
const weaponsNailgunImage = `${imagePath}/weapons/Weapons - Left - Nailgun.png`;
const weaponsFusionRifleImage = `${imagePath}/weapons/Weapons - Left - Fusion Rifle.png`;
const weaponsSlagCannonImage = `${imagePath}/weapons/Weapons - Left - Slag Cannon.png`;
const weaponsMacheteImage = `${imagePath}/weapons/Weapons - Left - Machete.png`;
const weaponsPickaxeImage = `${imagePath}/weapons/Weapons - Left - Pickaxe.png`;

// SECONDARY WEAPONS
const secondaryWeaponsCatalyticHammerImage = `${imagePath}/weapons/Weapons - Right - Catalytic Hammer.png`;
const secondaryWeaponsForgeClampsImage = `${imagePath}/weapons/Weapons - Right - Forge Clamps.png`;
const secondaryWeaponsMacheteImage = `${imagePath}/weapons/Weapons - Right - Machete.png`;
const secondaryWeaponsNailgunImage = `${imagePath}/weapons/Weapons - Right - Nailgun.png`;
const secondaryWeaponsSpearImage = `${imagePath}/weapons/Weapons - Right - Spear.png`;
const secondaryWeaponsUngratefulSpearImage = `${imagePath}/weapons/Weapons - Right - Ungrateful Spear.png`;
const secondaryWeaponsMagnumImage = `${imagePath}/weapons/Weapon - Magnum Right.png`;

// ACCESSORIES
const accessoryFusionRImage = `${imagePath}/accessories/Glow - Fusion Rifle.png`;
const accessorySlagCannonImage = `${imagePath}/accessories/Glow - Slag Cannon.png`;
const accessoryAllImage = `${imagePath}/accessories/Glow - Kobold All.png`;
const accessoryNoHeadImage = `${imagePath}/accessories/Glow - Kobold No Head.png`;
const accessoryNoHeadNoSpikesImage = `${imagePath}/accessories/Glow - Kobold no head no spikes.png`;
const accessoryNoHeadFusionRImage = `${imagePath}/accessories/Glow - Kobold No Head, Fusion R.png`;
const accessoryNoHeadSlagCImage = `${imagePath}/accessories/Glow - Kobold No Head, Slag C.png`;
const accessoryNoHeadNoSpikesFusionRImage = `${imagePath}/accessories/Glow - Kobold no head no spikes, Fusion R.png`;
const accessoryNoHeadNoSpikesSlagCImage = `${imagePath}/accessories/Glow - Kobold no head no spikes, Slag C.png`;
const accessoryAllFusionRImage = `${imagePath}/accessories/Glow - Kobold All, Fusion R.png`;
const accessoryAllSlagCImage = `${imagePath}/accessories/Glow - Kobold All, Slag C.png`;
const accessorySlagMoltenImage = `${imagePath}/accessories/Glow - Slag molten.png`;
const accessoryPartisanImage = `${imagePath}/accessories/Accessory - Partisan.png`;

const coreImage = `${imagePath}/Rockem_Socks - Kobold.png`;
const core2Image = `${imagePath}/Kobold_Maboi.png`;
const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SMALL,
    bottom: "52px",
    right: "82px",
};

const slagShadow = {
    size: shadowTypes.LARGE,
    bottom: "57px",
    right: "19px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Rockem_Socks",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Maboi000",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "3",
        "secondaryWeaponValue": "1",
        "accessory1Value": "3",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "KOBOLD",
            src: headKoboldImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "BONEHEAD",
            src: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "BRIAR",
            src: headBriarImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "BUNKER",
            src: headBunkerImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CHEDDAH",
            src: headCheddahImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "DUNGAM",
            src: headDungamImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "DWARF",
            src: headDwarfImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "IRON SKULL",
            src: headIronSkullImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "OGRE",
            src: headOgreImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "PALADIN",
            src: headPaladinImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "PARTISAN",
            src: headPartisanImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "ROGUE",
            src: headRogueImage,
            isPremium: true,
        },
        {
            value: 24,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 25,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
        {
            value: 26,
            label: "SKULL",
            src: headSkullImage,
            isPremium: true,
        },
        {
            value: 27,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
            isPremium: true,
        },
        {
            value: 28,
            label: "VANQUISHER",
            src: headVanquisherImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "KOBOLD",
            src: bodyKoboldImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "SLAG",
            src: bodySlagImage,
            shadowDetails: slagShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "PISTOL",
                src: weaponsPistolImage,
            },
            {
                value: 1,
                label: "REVOLVER",
                src: weaponsRevolverImage,
            },
            {
                value: 2,
                label: "NAILGUN",
                src: weaponsNailgunImage,
            },
            {
                value: 3,
                label: "FUSION RIFLE",
                src: weaponsFusionRifleImage,
            },
            {
                value: 4,
                label: "SLAG CANNON",
                src: weaponsSlagCannonImage,
            },
            {
                value: 5,
                label: "MACHETE",
                src: weaponsMacheteImage,
            },
            {
                value: 6,
                label: "PICKAXE",
                src: weaponsPickaxeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CATALYTIC HAMMER",
            foremostSrc: secondaryWeaponsCatalyticHammerImage,
        },
        {
            value: 1,
            label: "FORGE CLAMPS",
            foremostSrc: secondaryWeaponsForgeClampsImage,
        },
        {
            value: 2,
            label: "MACHETE",
            foremostSrc: secondaryWeaponsMacheteImage,
        },
        {
            value: 3,
            label: "NAILGUN",
            foremostSrc: secondaryWeaponsNailgunImage,
        },
        {
            value: 4,
            label: "MAGNUM",
            foremostSrc: secondaryWeaponsMagnumImage,
        },
        {
            value: 5,
            label: "SPEAR",
            foremostSrc: secondaryWeaponsSpearImage,
        },
        {
            value: 6,
            label: "UNGRATEFUL SPEAR",
            src: secondaryWeaponsUngratefulSpearImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "FUSION RIFLE",
            frontSrc: accessoryFusionRImage,
        },
        {
            value: 1,
            label: "SLAG CANNON",
            frontSrc: accessorySlagCannonImage,
        },
        {
            value: 2,
            label: "ALL",
            frontSrc: accessoryAllImage,
        },
        {
            value: 3,
            label: "ALL + FUSION RIFLE",
            frontSrc: accessoryAllFusionRImage,
        },
        {
            value: 4,
            label: "ALL + SLAG CANNON",
            frontSrc: accessoryAllSlagCImage,
        },
        {
            value: 5,
            label: "NO HEAD",
            frontSrc: accessoryNoHeadImage,
        },
        {
            value: 6,
            label: "NO HEAD + NO SPIKES",
            frontSrc: accessoryNoHeadNoSpikesImage,
        },
        {
            value: 7,
            label: "NO HEAD + FUSION RIFLE",
            frontSrc: accessoryNoHeadFusionRImage,
        },
        {
            value: 8,
            label: "NO HEAD + SLAG CANNON",
            frontSrc: accessoryNoHeadSlagCImage,
        },
        {
            value: 9,
            label: "NO HEAD + NO SPIKES + FUSION RIFLE",
            frontSrc: accessoryNoHeadNoSpikesFusionRImage,
        },
        {
            value: 10,
            label: "NO HEAD + NO SPIKES + SLAG CANNON",
            frontSrc: accessoryNoHeadNoSpikesSlagCImage,
        },
        {
            value: 11,
            label: "SLAG - MOLTEN",
            frontSrc: accessorySlagMoltenImage,
        },
        {
            value: 12,
            label: "ACCESSORY - PARTISAN",
            frontSrc: accessoryPartisanImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON LEFT",
            value: "weapon",
        },
        {
            label: "WEAPON RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON LEFT",
        "secondaryWeapon": "WEAPON RIGHT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
