import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyPegasusImage = `${BaseURL}/Mechs/Horus/Pegasus-2/chassis.png`;

// HEADS
const headPegasus2Image = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/Head - Pegasus 2.png`;
// PREMIUM
const headBiterImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Biter.png`;
const headBladebiterImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Bladebiter.png`;
const headHammerheadImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Hammerhead.png`;
const headMaliceImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Malice.png`;
const headMatapacosImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Matapacos.png`;
const headNeck1Image = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - neck 1.png`;
const headNeck2Image = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - neck 2.png`;
const headPegasus1Image = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Pegasus 1.png`;
const headSifImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Sif.png`;
const headSmoothImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Smooth.png`;
const headStalkerImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Stalker.png`;
const headTeefImage = `${BaseURL}/Mechs/Horus/Pegasus-2/heads/premium/Head - Teef.png`;

// WEAPONS
// LEFT
const weaponLeftCarnivoreCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Carnivore Cannon.png`;
const weaponLeftCycloneCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Cyclone Cannon.png`;
const weaponLeftHeavyCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Heavy Cannon.png`;
const weaponLeftJaegerCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Jaeger Cannon.png`;
const weaponLeftMissilePods1Image = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Missile Pods 1.png`;
const weaponLeftMissilePods2Image = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Missile Pods 2.png`;
const weaponLeftNexusImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Nexus.png`;
const weaponLeftPenetratorCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Penetrator Cannon.png`;
const weaponLeftTempestCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Tempest Cannon.png`;
const weaponLeftUltraheavyCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Ultraheavy Cannon.png`;
const weaponLeftBladeScabbardImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/left/Left Weapon - Blade Scabbard.png`;

// RIGHT
const weaponRightCarnivoreCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Carnivore Cannon.png`;
const weaponRightHeavyCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Heavy Cannon.png`;
const weaponRightJaegerCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Jaeger Cannon.png`;
const weaponRightNexusImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Nexus.png`;
const weaponRightPenetratorCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Penetrator Cannon.png`;
const weaponRightTempestCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Tempest Cannon.png`;
const weaponRightBladeScabbardImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Blade Scabbard.png`;
const weaponRightBladeImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/right/Right Weapon - Blade.png`;

// HEAVY
const weaponHeavyMimicGunImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/heavy/Heavy Mount - Mimic Gun.png`;
const weaponHeavyRailgunImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/heavy/Heavy Mount - Railgun.png`;
const weaponHeavyChainsawImage = `${BaseURL}/Mechs/Horus/Pegasus-2/weapons/heavy/Heavy Mount - Heavy Chainsaw.png`;

// GLOWS
const glowMimicGun1Image = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Mimic Gun 1.png`;
const glowMimicGun2Image = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Mimic Gun 2.png`;
const glowPegasusHead2Image = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Pegasus 2 Head.png`;
const glowPenetratorsImage = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Penetrators.png`;
const glowRailgunImage = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Railgun.png`;
const glowSifHeadImage = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Sif Glow.png`;
const glowChainsawTailImage = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Chainsaw Tail.png`;
const glowHeavyChainsawImage = `${BaseURL}/Mechs/Horus/Pegasus-2/glows/Glow - Heavy Chainsaw.png`;

// ACCESSORIES
const accessoriesClawsImage = `${BaseURL}/Mechs/Horus/Pegasus-2/accessories/Accessory - Claws.png`;
const accessoriesTailImage = `${BaseURL}/Mechs/Horus/Pegasus-2/accessories/Accessory - Tail.png`;
const accessoriesTailKnifeImage = `${BaseURL}/Mechs/Horus/Pegasus-2/accessories/Accessory - Tail Knife.png`;
const accessoriesTailChainsawImage = `${BaseURL}/Mechs/Horus/Pegasus-2/accessories/Accessory - Tail Chainsaw.png`;

const previewImage = `${BaseURL}/Mechs/Horus/Pegasus-2/preview.png`;

const quadLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "57px",
    right: "33px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "2",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "6",
        "accessory4Value": "5",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "PEGASUS 2.0",
            src: headPegasus2Image,
        },
        {
            value: 1,
            label: "BITER",
            src: headBiterImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "BLADEBITER",
            src: headBladebiterImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "HAMMERHEAD",
            src: headHammerheadImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "MATAPACOS",
            src: headMatapacosImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "NECK 1",
            src: headNeck1Image,
            isPremium: true,
        },
        {
            value: 7,
            label: "NECK 2",
            src: headNeck2Image,
            isPremium: true,
        },
        {
            value: 8,
            label: "PEGASUS 1.0",
            src: headPegasus1Image,
            isPremium: true,
        },
        {
            value: 9,
            label: "SIF",
            src: headSifImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SMOOTH",
            src: headSmoothImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "STALKER",
            src: headStalkerImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "TEEF",
            src: headTeefImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "PEGASUS 2.0",
            src: bodyPegasusImage,
            shadowDetails: quadLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CARNIVORE CANNON",
                backSrc: weaponLeftCarnivoreCannonImage,
            },
            {
                value: 1,
                label: "HEAVY CANNON",
                backSrc: weaponLeftHeavyCannonImage,
            },
            {
                value: 2,
                label: "JAEGER CANNON",
                backSrc: weaponLeftJaegerCannonImage,
            },
            {
                value: 3,
                label: "PENETRATOR CANNON",
                backSrc: weaponLeftPenetratorCannonImage,
            },
            {
                value: 4,
                label: "TEMPEST CANNON",
                backSrc: weaponLeftTempestCannonImage,
            },
            {
                value: 5,
                label: "NEXUS",
                backSrc: weaponLeftNexusImage,
            },
            {
                value: 6,
                label: "BLADE SCABBARD",
                backSrc: weaponLeftBladeScabbardImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CARNIVORE CANNON",
            frontSrc: weaponRightCarnivoreCannonImage,
        },
        {
            value: 1,
            label: "HEAVY CANNON",
            frontSrc: weaponRightHeavyCannonImage,
        },
        {
            value: 2,
            label: "JAEGER CANNON",
            frontSrc: weaponRightJaegerCannonImage,
        },
        {
            value: 3,
            label: "PENETRATOR CANNON",
            frontSrc: weaponRightPenetratorCannonImage,
        },
        {
            value: 4,
            label: "TEMPEST CANNON",
            frontSrc: weaponRightTempestCannonImage,
        },
        {
            value: 5,
            label: "NEXUS",
            frontSrc: weaponRightNexusImage,
        },
        {
            value: 6,
            label: "BLADE SCABBARD",
            frontSrc: weaponRightBladeScabbardImage,
        },
        {
            value: 7,
            label: "BLADE",
            frontSrc: weaponRightBladeImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "MIMIC GUN",
            frontSrc: weaponHeavyMimicGunImage,
        },
        {
            value: 1,
            label: "RAILGUN",
            frontSrc: weaponHeavyRailgunImage,
        },
        {
            value: 2,
            label: "CYCLONE CANNON",
            frontSrc: weaponLeftCycloneCannonImage,
        },
        {
            value: 3,
            label: "ULTRAHEAVY CANNON",
            frontSrc: weaponLeftUltraheavyCannonImage,
        },
        {
            value: 4,
            label: "MISSILE PODS 1",
            frontSrc: weaponLeftMissilePods1Image,
        },
        {
            value: 5,
            label: "MISSILE PODS 2",
            frontSrc: weaponLeftMissilePods2Image,
        },
        {
            value: 6,
            label: "HEAVY CHAINSAW",
            frontSrc: weaponHeavyChainsawImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CLAWS",
            src: accessoriesClawsImage,
        },
        {
            value: 1,
            label: "TAIL",
            backSrc: accessoriesTailImage,
        },
        {
            value: 2,
            label: "TAIL + KNIFE",
            backSrc: accessoriesTailKnifeImage,
        },
        {
            value: 3,
            label: "TAIL + CHAINSAW",
            backSrc: accessoriesTailChainsawImage,
        },
        {
            value: 4,
            label: "GLOW MIMIC GUN 1",
            frontSrc: glowMimicGun1Image,
        },
        {
            value: 5,
            label: "GLOW MIMIC GUN 2",
            frontSrc: glowMimicGun2Image,
        },
        {
            value: 6,
            label: "GLOW PEGASUS 2.0 HEAD",
            frontSrc: glowPegasusHead2Image,
        },
        {
            value: 7,
            label: "GLOW SIF HEAD",
            frontSrc: glowSifHeadImage,
        },
        {
            value: 8,
            label: "GLOW CHAINSAW TAIL",
            frontSrc: glowChainsawTailImage,
        },
        {
            value: 9,
            label: "GLOW HEAVY CHAINSAW",
            frontSrc: glowHeavyChainsawImage,
        },
        {
            value: 10,
            label: "GLOW PENETRATORS",
            frontSrc: glowPenetratorsImage,
        },
        {
            value: 11,
            label: "GLOW RAILGUN",
            frontSrc: glowRailgunImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "HEAVY WEAPON",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "HEAVY WEAPON",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
