import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Tortuga`;

// BODY
const bodyTortugaImage = `${imagePath}/Body Tortuga.png`;
const headTortugaCoverImage = `${imagePath}/Head Over.png`;
// LEGENDARY
const bodyHortugaImage = `${imagePath}/HORtuga Chassis.png`;

// HEADS
const headTortugaImage = `${imagePath}/heads/Head Tortuga.png`;
const headKazuImage = `${imagePath}/heads/Head Kazu.png`;
// PREMIUM
const headFactoryStandardImage = `${imagePath}/heads/premium/Head Factory Standard.png`;
const headPaladinImage = `${imagePath}/heads/premium/Head Paladin.png`;
const headOgreImage = `${imagePath}/heads/premium/Head Ogre.png`;
const headRogueImage = `${imagePath}/heads/premium/Head Rogue.png`;
const headBastionImage = `${imagePath}/heads/premium/Head Bastion.png`;
const headIcarusImage = `${imagePath}/heads/premium/Head Icarus.png`;
const headScarabImage = `${imagePath}/heads/premium/Head Scarab.png`;
const headRamshackleImage = `${imagePath}/heads/premium/Head Ramshackle.png`;
const headSlipstreamImage = `${imagePath}/heads/premium/Head Slipstream.png`;
const headBunkerImage = `${imagePath}/heads/premium/Head Bunker.png`;
const headDungamImage = `${imagePath}/heads/premium/Head Dungam.png`;
const headTalonImage = `${imagePath}/heads/premium/Head Talon.png`;
const headNelsonImage = `${imagePath}/heads/premium/Head Nelson.png`;
const headJudicatorImage = `${imagePath}/heads/premium/Head Judicator.png`;
const headRumbleImage = `${imagePath}/heads/premium/Head Rumble.png`;
const headCyclopsImage = `${imagePath}/heads/premium/Head Cyclops.png`;
const headChimeraImage = `${imagePath}/heads/premium/Head Chimera.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head Laborer.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head Malice.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;
// LEGENDARY
const headHortugaImage = `${imagePath}/heads/legendary/Head - HORtuga.png`;

// REAR MOUNT
const rearMountSiegeRamImage = `${imagePath}/rear mount/Rear Siege Ram.png`;
const rearMountDaisyCutterImage = `${imagePath}/rear mount/Rear Daisy Cutter.png`;

// 2H LEFT
const deckSweeperLeft1HImage = `${imagePath}/weapons/left/Main Deck Sweeper.png`;
const daisyCutterLeft1HImage = `${imagePath}/weapons/left/Main Daisy Cutter.png`;
const assaultRifleLeft1HImage = `${imagePath}/weapons/left/Main Assault Rifle.png`;
const hmgLeft1HImage = `${imagePath}/weapons/left/Main Heavy Machinegun.png`;

// ACCESSORIES
const auxCatalyticHammerImage = `${imagePath}/accessories/Aux Catalytic Hammer.png`;
const accessoryArgoShieldImage = `${imagePath}/accessories/Accessory - Argonaut Shield.png`;
const glowHortugaImage = `${imagePath}/accessories/legendary/Glow- HORtuga.png`;
const accChassisDetailImage = `${imagePath}/accessories/chassis-TORTUGA-mid.png`;
const auxLeviathanHeavyAssaultCannonImage = `${imagePath}/accessories/tortuga leviathan heavy assault cannon.png`;

const coreImage = `${imagePath}/Tortuga (by Corvon).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "53px",
    right: "46px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    secondaryWeaponIsChassisStanceSpecial: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "TORTUGA",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "TORTUGA",
            src: headTortugaImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "PALADIN",
            src: headPaladinImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "OGRE",
            src: headOgreImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ROGUE",
            src: headRogueImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "BASTION",
            src: headBastionImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ICARUS",
            src: headIcarusImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCARAB",
            src: headScarabImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RAMSHACKLE",
            src: headRamshackleImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "BUNKER",
            src: headBunkerImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            src: headDungamImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "TALON",
            src: headTalonImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "HORTUGA",
            src: headHortugaImage,
            isPremium: true,
            isLegendary: true,
            originalArtCredit: {
                title: "Juan Gee",
                url: "https://twitter.com/juangeedraws",
            },
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TORTUGA",
            src: bodyTortugaImage,
            frontSrc: headTortugaCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "HORTUGA",
            src: bodyHortugaImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
            originalArtCredit: {
                title: "Juan Gee",
                url: "https://twitter.com/juangeedraws",
            },
        },
    ],
    weapon: {
        "TORTUGA": [
            {
                value: 0,
                label: "DECK SWEEPER",
                src: deckSweeperLeft1HImage,
            },
            {
                value: 1,
                label: "DAISY CUTTER",
                src: daisyCutterLeft1HImage,
            },
            {
                value: 2,
                label: "ASSAULT RIFLE",
                src: assaultRifleLeft1HImage,
            },
            {
                value: 3,
                label: "HEAVY MACHINE GUN",
                foremostSrc: hmgLeft1HImage,
            },
        ],
        "HORTUGA": [
            {
                value: 0,
                label: "DECK SWEEPER",
                src: deckSweeperLeft1HImage,
            },
            {
                value: 1,
                label: "DAISY CUTTER",
                src: daisyCutterLeft1HImage,
            },
            {
                value: 2,
                label: "ASSAULT RIFLE",
                src: assaultRifleLeft1HImage,
            },
            {
                value: 3,
                label: "HEAVY MACHINE GUN",
                foremostSrc: hmgLeft1HImage,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [
        {
            value: 0,
            label: "SIEGE RAM",
            src: rearMountSiegeRamImage,
        },
        {
            value: 1,
            label: "DAISY CUTTER",
            src: rearMountDaisyCutterImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "AUX CATALYTIC HAMMER",
            src: auxCatalyticHammerImage,
        },
        {
            value: 1,
            label: "SIEGE RAM",
            src: rearMountSiegeRamImage,
        },
        {
            value: 2,
            label: "ARGONAUT SHIELD",
            frontSrc: accessoryArgoShieldImage,
        },
        {
            value: 3,
            label: "LEVIATHAN HEAVY ASSAULT CANNON - RIGHT ARM",
            frontSrc: auxLeviathanHeavyAssaultCannonImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 4,
            label: "COLOR - CHASSIS DETAILS",
            src: accChassisDetailImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "GLOW - HORTUGA",
            src: glowHortugaImage,
            isPremium: true,
            isLegendary: true,
            originalArtCredit: {
                title: "Juan Gee",
                url: "https://twitter.com/juangeedraws",
            },
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
