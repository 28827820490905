import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";
import { emptyImage } from "../styles/UnitImage";

const imagePath = `${BaseURL}/Mechs/Misc/Tank`;

// CHASSIS
const chassisTreadsImage = `${imagePath}/Chassis-Treads.png`;
const chassisHoverImage = `${imagePath}/Chassis-Hover.png`;
const chassisMammothImage = `${imagePath}/Chassis-Mammoth.png`;

// TURRET
const turretStandardImage = `${imagePath}/heads/Turret-Standard.png`;
const turretHeavyImage = `${imagePath}/heads/Turret-Heavy.png`;

// MAIN GUN
const gunMediumCannonImage = `${imagePath}/weapons/Gun-Standard-Medium-Cannon.png`;
const gunDoubleImage = `${imagePath}/weapons/Gun-Standard-Double.png`;
const gunMortarImage = `${imagePath}/weapons/Gun-Standard-Mortar.png`;
const gunRailgunImage = `${imagePath}/weapons/Gun-Standard-Railgun.png`;
const gunRailgunClosedImage = `${imagePath}/weapons/Gun-Standard-Railgun-Closed.png`;
const gunRayImage = `${imagePath}/weapons/Gun-Standard-Ray.png`;
const gunHeavyHeavyCannonImage = `${imagePath}/weapons/Gun-Heavy-Heavy-Cannon.png`;
const gunHeavyDoubleImage = `${imagePath}/weapons/Gun-Heavy-Double.png`;
const gunHeavyRailgunImage = `${imagePath}/weapons/Gun-Heavy-Railgun.png`;
const gunHeavyRailgunClosedImage = `${imagePath}/weapons/Gun-Heavy-Railgun-Closed.png`;
const gunHeavySuperRailgunImage = `${imagePath}/weapons/Gun-Heavy-Super-Railgun.png`;
const gunLightTurretSingleCannonImage = `${imagePath}/weapons/tank light turret single cannon.png`;
const gunHeavyTurretSmallCannonsImage = `${imagePath}/weapons/tank heavy turret small cannons.png`;

// ACCESSORIES
const accessoryStandardOpenHatchImage = `${imagePath}/accessories/Accessory-Standard-Open-Hatch.png`;
const accessoryStandardOpenHatchCommanderImage = `${imagePath}/accessories/Accessory-Standard-Open-Hatch-Commander.png`;
const accessoryStandardMachinegunsImage = `${imagePath}/accessories/Accessory-Standard-Machineguns.png`;
const accessoryStandardMiniRocketpodsImage = `${imagePath}/accessories/Accessory-Standard-Mini-Rocketpods.png`;
const accessoryStandardPintelFrontImage = `${imagePath}/accessories/Accessory-Standard-Pintel-Front.png`;
const accessoryStandardPintelBackImage = `${imagePath}/accessories/Accessory-Standard-Pintel-Back.png`;
const accessoryStandardRocketpodImage = `${imagePath}/accessories/Accessory-Standard-Rocketpod.png`;
const accessoryHeavyRocketpodsImage = `${imagePath}/accessories/Accessory-Heavy-Rocketpods.png`;

// GLOWS
const glowChassisHoverImage = `${imagePath}/glows/Chassis-Hover-Glow.png`;
const glowHeavyRailgunImage = `${imagePath}/glows/Gun-Heavy-Railgun-Glow.png`;
const glowStandardRailgunImage = `${imagePath}/glows/Gun-Standard-Railgun-Glow.png`;
const glowStandardRayImage = `${imagePath}/glows/Gun-Standard-Ray-Glow.png`;

const previewImage = `${BaseURL}/Mechs/Misc/Tank/preview.png`;

const treadsShadow = {
    size: shadowTypes.LARGE,
    bottom: "112px",
    right: "53px",
};

const mammothShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "86px",
    right: "3px",
};

const hoverShadow = {
    size: shadowTypes.LARGE,
    bottom: "88px",
    right: "15px",
};

const originalArtCredit = {
    title: "SkaianDestiny",
    url: "https://discord.gg/JUF47G5",
};

export default {
    previewImg: previewImage,
    accessory2IsChassis: true,
    headSpecial: "stanceValue",
    isMegadeus: true,
    removeNone: {
        head: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "STANDARD",
            src: turretStandardImage,
        },
        {
            value: 1,
            label: "HEAVY",
            src: turretHeavyImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TREADS",
            src: chassisTreadsImage,
            shadowDetails: treadsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "HOVER",
            src: chassisHoverImage,
            shadowDetails: hoverShadow,
            originalArtCredit,
        },
        {
            value: 2,
            label: "MAMMOTH",
            src: chassisMammothImage,
            shadowDetails: mammothShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "MEDIUM CANNON",
                src: gunMediumCannonImage,
            },
            {
                value: 1,
                label: "DOUBLE CANNON",
                src: gunDoubleImage,
            },
            {
                value: 2,
                label: "MORTAR",
                src: gunMortarImage,
            },
            {
                value: 3,
                label: "RAILGUN",
                src: gunRailgunImage,
            },
            {
                value: 4,
                label: "RAILGUN CLOSED",
                src: gunRailgunClosedImage,
            },
            {
                value: 5,
                label: "RAYGUN",
                src: gunRayImage,
            },
            {
                value: 6,
                label: "SINGLE CANNON",
                src: gunLightTurretSingleCannonImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
        ],
        "1": [
            {
                value: 0,
                label: "HEAVY CANNON",
                src: gunHeavyHeavyCannonImage,
            },
            {
                value: 1,
                label: "DOUBLE CANNON",
                src: gunHeavyDoubleImage,
            },
            {
                value: 2,
                label: "HEAVY RAILGUN",
                src: gunHeavyRailgunImage,
            },
            {
                value: 3,
                label: "HEAVY RAILGUN CLOSED",
                src: gunHeavyRailgunClosedImage,
            },
            {
                value: 4,
                label: "SUPER RAILGUN",
                src: gunHeavySuperRailgunImage,
            },
            {
                value: 5,
                label: "EMPTY (PERFECTLY BALANCED)",
                src: emptyImage,
            },
            {
                value: 6,
                label: "SMALL CANNONS",
                src: gunHeavyTurretSmallCannonsImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "RAILGUN",
            frontSrc: glowStandardRailgunImage,
        },
        {
            value: 1,
            label: "RAY GUN",
            frontSrc: glowStandardRayImage,
        },
        {
            value: 2,
            label: "HEAVY RAILGUN",
            frontSrc: glowHeavyRailgunImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "HOVER",
            frontSrc: glowChassisHoverImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "OPEN HATCH",
            frontSrc: accessoryStandardOpenHatchImage,
        },
        {
            value: 1,
            label: "OPEN HATCH COMMANDER",
            frontSrc: accessoryStandardOpenHatchCommanderImage,
        },
        {
            value: 2,
            label: "MACHINEGUNS",
            frontSrc: accessoryStandardMachinegunsImage,
        },
        {
            value: 3,
            label: "PINTEL - FRONT",
            frontSrc: accessoryStandardPintelFrontImage,
        },
        {
            value: 4,
            label: "PINTEL - REAR",
            frontSrc: accessoryStandardPintelBackImage,
        },
        {
            value: 5,
            label: "ROCKETPOD",
            frontSrc: accessoryStandardRocketpodImage,
        },
        {
            value: 6,
            label: "MINI ROCKETPODS",
            frontSrc: accessoryStandardMiniRocketpodsImage,
        },
        {
            value: 7,
            label: "ROCKETPODS - HEAVY TURRET",
            frontSrc: accessoryHeavyRocketpodsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "TURRET",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "CHASSIS GLOW",
            value: "rearMount",
            isGlow: true,
        },
        {
            label: "MAIN GUN",
            value: "weapon",
        },
        {
            label: "GUN GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "TURRET",
        "chassis": "CHASSIS",
        "rearMount": "CHASSIS GLOW",
        "weapon": "MAIN GUN",
        "secondaryWeapon": "GUN GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 2",
        "accessory4": "ACCESSORY 3",
    },
};
