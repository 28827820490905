import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyMonarchImage = `${BaseURL}/Mechs/SSC/Monarch/Chassis.png`;

const armsMonarchImage = `${BaseURL}/Mechs/SSC/Monarch/Arms - 2H.png`;
const armsMissilesImage = `${BaseURL}/Mechs/SSC/Monarch/Arms - Missiles.png`;
const armsOopsAllMissilesImage = `${BaseURL}/Mechs/SSC/Monarch/Arms - Oops All Missiles.png`;
const armsBusterMissileImage = `${BaseURL}/Mechs/SSC/Monarch/Monarch Weapon Buster Missile.png`;
const armsMegaBlastersImage = `${BaseURL}/Mechs/SSC/Monarch/Monarch Weapon Mega Blasters.png`;
const armsRockinLauncherImage = `${BaseURL}/Mechs/SSC/Monarch/Monarch Weapon Rockin_ Launcher.png`;

// HEADS
const headMonarchImage = `${BaseURL}/Mechs/SSC/Monarch/heads/Head - Monarch.png`;
const headKazuImage = `${BaseURL}/Mechs/SSC/Monarch/heads/Head - Kazu.png`;
const headL10Image = `${BaseURL}/Mechs/SSC/Monarch/heads/Monarch Head L-10.png`;
const headBlastardImage = `${BaseURL}/Mechs/SSC/Monarch/heads/Monarch Head Blastard.png`;
const headKinglyImage = `${BaseURL}/Mechs/SSC/Monarch/heads/Monarch Head Kingly.png`;
// PREMIUM
const headCalibanImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Chimera.png`;
const headCockpitArmoredImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Armored Cockpit.png`;
const headCyclopsImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Cyclops.png`;
const headLaborerImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Malice.png`;
const headMetalmarkImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Metalmark.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Natural Law.png`;
const headRainImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Rain.png`;
const headWitchImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Witch.png`;
const headCockpitImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/SSC/Monarch/heads/premium/Head - Scopehound.png`;

// MAIN WEAPON
const weaponARImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/Weapon - AR.png`;
const weaponAntiMatRifleImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/Weapon - AMR.png`;
const weaponLauncherImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/Weapon - Launcher.png`;
const weaponHeavyLauncherImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/Weapon - Heavy Launcher.png`;
const weaponSHeavyLauncherImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/Weapon - Superheavy Launcher.png`;

// SECONDARY WEAPON
const secondaryWeaponMissilePodsImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Shoulders - Missile Pods.png`;
const secondaryWeaponMissileRackX16Image = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Shoulders - Missile Rack (16).png`;
const secondaryWeaponMissileRackX32Image = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Shoulders - Missile Rack (32).png`;
const secondaryWeaponMissilePodsX32Image = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Shoulders - Missile Pods and Racks (32).png`;
const secondaryWeaponFlightUnitBackImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Monarch Flight Unit Back.png`;
const secondaryWeaponFlightUnitFrontImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Monarch Flight Unit Front.png`;
const secondaryWeaponKinglyCloakBackImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Monarch Kingly Cloak Back.png`;
const secondaryWeaponKinglyCloakFrontImage = `${BaseURL}/Mechs/SSC/Monarch/weapons/secondary/Monarch Kingly Cloak Front.png`;

// REAR MOUNTS
const rearMountMonarchImage = `${BaseURL}/Mechs/SSC/Monarch/rear mounts/Rear - Monarch.png`;
const rearMountMissilePodsImage = `${BaseURL}/Mechs/SSC/Monarch/rear mounts/Rear - Missile Pods.png`;
const rearMountMissilesX2Image = `${BaseURL}/Mechs/SSC/Monarch/rear mounts/Rear - Missiles (2).png`;
const rearMountMissilesX4Image = `${BaseURL}/Mechs/SSC/Monarch/rear mounts/Rear - Missiles (4).png`;
const rearMountMissilesX12Image = `${BaseURL}/Mechs/SSC/Monarch/rear mounts/Rear - Missiles (12).png`;
const rearMountShoulderLaunchersImage = `${BaseURL}/Mechs/SSC/Monarch/rear mounts/Monarch Shoulder Launchers.png`;
const rearMountDootLaunchersImage = `${BaseURL}/Mechs/SSC/Monarch/rear mounts/Monarch Doot Launchers.png`;

// ACCESSORY
const accessoryKnifeImage = `${BaseURL}/Mechs/SSC/Monarch/accessories/Accessory - Knife.png`;
const accessoryChestMissilesImage = `${BaseURL}/Mechs/SSC/Monarch/accessories/Accessory - Chest Missiles.png`;
const accessoryCodPieceImage = `${BaseURL}/Mechs/SSC/Monarch/accessories/Monarch Cod Piece.png`;
const accessoryShoulderLaunchersGlowImage = `${BaseURL}/Mechs/SSC/Monarch/accessories/Monarch Shoulder Launchers Glow.png`;
const accessoryRockinlauncherGlowImage = `${BaseURL}/Mechs/SSC/Monarch/accessories/Monarch Weapon Rockin_ Launcher Glow.png`;
const accessoryMegaBlastersGlowImage = `${BaseURL}/Mechs/SSC/Monarch/accessories/Monarch Weapon Mega Blasters Glow.png`;
// PREMIUM
const accessorySweetKicksImage = `${BaseURL}/Mechs/SSC/Monarch/accessories/premium/Accessory - Sweet Kicks.png`;

const coreImage = `${BaseURL}/Mechs/SSC/Monarch/Monarch (by BlindDuke).png`;

const previewImage = `${BaseURL}/Mechs/SSC/Monarch/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "19px",
    right: "42px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: contributorList.PINKHYENA,
        },
    ],
    removeNone: {
        weapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "MONARCH",
            foremostSrc: headMonarchImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "L-10",
            foremostSrc: headL10Image,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "BLASTARD",
            foremostSrc: headBlastardImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "KINGLY",
            foremostSrc: headKinglyImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "COCKPIT",
            foremostSrc: headCockpitImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ARMORED COCKPIT",
            foremostSrc: headCockpitArmoredImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MAG",
            foremostSrc: headMagImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "METALMARK",
            foremostSrc: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN",
            foremostSrc: headRainImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "WITCH",
            foremostSrc: headWitchImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MONARCH",
            src: bodyMonarchImage,
            frontSrc: armsMonarchImage,
            shadowDetails: bipedLegsShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE",
                src: weaponARImage,
            },
            {
                value: 1,
                label: "ANTI-MATERIEL RIFLE",
                src: weaponAntiMatRifleImage,
            },
            {
                value: 2,
                label: "LAUNCHER",
                src: weaponLauncherImage,
            },
            {
                value: 3,
                label: "HEAVY LAUNCHER",
                src: weaponHeavyLauncherImage,
            },
            {
                value: 4,
                label: "SUPERHEAVY LAUNCHER",
                src: weaponSHeavyLauncherImage,
            },
            {
                value: 5,
                label: "WRIST MISSILES",
                src: armsMissilesImage,
                removeBodyCover: true,
            },
            {
                value: 6,
                label: "BUSTER MISSILE",
                backSrc: armsBusterMissileImage,
                removeBodyCover: true,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 7,
                label: "MEGA BLASTERS",
                src: armsMegaBlastersImage,
                removeBodyCover: true,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 8,
                label: "ROCKIN' LAUNCHER",
                src: armsRockinLauncherImage,
                removeBodyCover: true,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 9,
                label: "OOPS! ALL MISSILES",
                src: armsOopsAllMissilesImage,
                isPremium: true,
                isLegendary: true,
                removeBodyCover: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MISSILE PODS",
            src: secondaryWeaponMissilePodsImage,
        },
        {
            value: 1,
            label: "MISSILE RACK X16",
            src: secondaryWeaponMissileRackX16Image,
        },
        {
            value: 2,
            label: "MISSILE RACK X32",
            src: secondaryWeaponMissileRackX32Image,
        },
        {
            value: 3,
            label: "MISSILE PODS + MISSILE RACK X32",
            src: secondaryWeaponMissilePodsX32Image,
        },
        {
            value: 4,
            label: "FLIGHT UNIT",
            frontSrc: secondaryWeaponFlightUnitFrontImage,
            src: secondaryWeaponFlightUnitBackImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "KINGLY CLOAK",
            frontSrc: secondaryWeaponKinglyCloakFrontImage,
            backSrc: secondaryWeaponKinglyCloakBackImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "MONARCH",
            frontSrc: rearMountMonarchImage,
        },
        {
            value: 1,
            label: "MISSILE PODS",
            frontSrc: rearMountMissilePodsImage,
        },
        {
            value: 2,
            label: "MISSILES X2",
            frontSrc: rearMountMissilesX2Image,
        },
        {
            value: 3,
            label: "MISSILES X4",
            frontSrc: rearMountMissilesX4Image,
        },
        {
            value: 4,
            label: "MISSILES X12",
            frontSrc: rearMountMissilesX12Image,
        },
        {
            value: 5,
            label: "SHOULDER LAUNCHERS",
            frontSrc: rearMountShoulderLaunchersImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 6,
            label: "DOOT LAUNCHERS",
            frontSrc: rearMountDootLaunchersImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "KNIFE",
            frontSrc: accessoryKnifeImage,
        },
        {
            value: 1,
            label: "CHEST MISSILES",
            src: accessoryChestMissilesImage,
        },
        {
            value: 2,
            label: "COD PIECE",
            src: accessoryCodPieceImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "SHOULDER LAUNCHERS - GLOW",
            frontSrc: accessoryShoulderLaunchersGlowImage,
        },
        {
            value: 4,
            label: "ROCKIN' LAUNCHER - GLOW",
            frontSrc: accessoryRockinlauncherGlowImage,
        },
        {
            value: 5,
            label: "MEGA BLASTERS - GLOW",
            frontSrc: accessoryMegaBlastersGlowImage,
        },
        {
            value: 6,
            label: "SWEET KICKS",
            src: accessorySweetKicksImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "SHOULDER RACK",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "SHOULDER RACK",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
