import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Mariana`;

// BODY
const bodyShowroomfloorImage = `${imagePath}/Chassis_Showroom Floor.png`;
const bodyDiscountSpecialImage = `${imagePath}/Chassis_Discount Special.png`;

// REAR MOUNT
const rearMountJetBoosterImage = `${imagePath}/rearmount/Rear Mount_Jet Booster.png`;
const rearMountBackMissilePodsImage = `${imagePath}/rearmount/Rear Mount Back_ Missile Pods.png`;
const rearMountNexusBayImage = `${imagePath}/rearmount/Rear Mount_Nexus Bay.png`;

// WEAPONS
// LEFT
const weaponsLeftHeavyChargeBladeImage = `${imagePath}/weapons/left/Weapons Left_Heavy Charge Blade.png`;
const weaponsLeftMissilePodsImage = `${imagePath}/weapons/left/Weapons Left_ Missile Pods.png`;
const weaponsLeftMaceratorImage = `${imagePath}/weapons/left/Weapons Left_Macerator.png`;
const weaponsLeftNanocarbonBladeImage = `${imagePath}/weapons/left/Weapons Left_Nanocarbon Blade.png`;
const weaponsLeftStreetLampImage = `${imagePath}/weapons/left/Weapons Left_Street Lamp.png`;
const weaponsLeftTreeImage = `${imagePath}/weapons/left/Weapons Left_Tree.png`;

// RIGHT
const weaponsRightCompensatorImage = `${imagePath}/weapons/right/Weapon Right_Compensator.png`;
const weaponsRightGyratorImage = `${imagePath}/weapons/right/Weapon Right_Gyrator.png`;
const weaponsRightMissilePodImage = `${imagePath}/weapons/right/Weapon Right_Missile Pod.png`;
const weaponsRightPenetratorImage = `${imagePath}/weapons/right/Weapon Right_Penetrator.png`;
const weaponsRightPenetratorXLImage = `${imagePath}/weapons/right/Weapon Right_Penetrator XL.png`;

// GLOWS
const glowJetBoosterImage = `${imagePath}/glows/Accessories Back_Jet Booster Glow.png`;
const glowCompensatorImage = `${imagePath}/glows/Accesories front_Compensator Glow.png`;
const glowHeavyChargeBladeImage = `${imagePath}/glows/Accesories front_Heavy Charge Blade Glow.png`;
const glowPenetratorImage = `${imagePath}/glows/Accesories front_Penetrator Glow.png`;
const glowPenetratorXLImage = `${imagePath}/glows/Accesories front_Penetrator XL Glow.png`;
const glowSuperheatedMaceratorImage = `${imagePath}/glows/Accesories front_Superheated Macerator.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-6px",
    right: "27px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "3",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "3",
        "accessory1Value": "2",
        "accessory2Value": "5",
        "accessory3Value": "3",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "JET BOOSTER",
            backSrc: rearMountJetBoosterImage,
        },
        {
            value: 1,
            label: "MISSILE PODS",
            backSrc: rearMountBackMissilePodsImage,
        },
        {
            value: 2,
            label: "NEXUS BAY",
            src: rearMountNexusBayImage,
        },
        {
            value: 3,
            label: "NEXUS BAY + JET BOOSTER",
            src: rearMountNexusBayImage,
            backSrc: rearMountJetBoosterImage,
        },
        {
            value: 4,
            label: "NEXUS BAY + MISSILE PODS",
            src: rearMountNexusBayImage,
            backSrc: rearMountBackMissilePodsImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SHOWROOM FLOOR",
            src: bodyShowroomfloorImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Commissioned by Old Man Maz Created by `,
                title: contributorList.PINKHYENA.title,
                url: contributorList.PINKHYENA.url,
            },
        },
        {
            value: 1,
            label: "DISCOUNT SPECIAL",
            src: bodyDiscountSpecialImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Commissioned by Old Man Maz Created by `,
                title: contributorList.PINKHYENA.title,
                url: contributorList.PINKHYENA.url,
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HEAVY CHARGE BLADE",
                backSrc: weaponsLeftHeavyChargeBladeImage,
            },
            {
                value: 1,
                label: "MISSILE PODS",
                backSrc: weaponsLeftMissilePodsImage,
            },
            {
                value: 2,
                label: "MACERATOR",
                backSrc: weaponsLeftMaceratorImage,
            },
            {
                value: 3,
                label: "NANOCARBON BLADE",
                backSrc: weaponsLeftNanocarbonBladeImage,
            },
            {
                value: 4,
                label: "STREET LAMP",
                backSrc: weaponsLeftStreetLampImage,
            },
            {
                value: 5,
                label: "TREE",
                backSrc: weaponsLeftTreeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "COMPENSATOR",
            src: weaponsRightCompensatorImage,
        },
        {
            value: 1,
            label: "GYRATOR",
            src: weaponsRightGyratorImage,
        },
        {
            value: 2,
            label: "MISSILE PODS",
            src: weaponsRightMissilePodImage,
        },
        {
            value: 3,
            label: "PENETRATOR",
            src: weaponsRightPenetratorImage,
        },
        {
            value: 4,
            label: "PENETRATOR XL",
            src: weaponsRightPenetratorXLImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "COMPENSATOR",
            src: glowCompensatorImage,
        },
        {
            value: 1,
            label: "HEAVY CHARGE BLADE",
            src: glowHeavyChargeBladeImage,
        },
        {
            value: 2,
            label: "JET BOOSTER",
            backSrc: glowJetBoosterImage,
        },
        {
            value: 3,
            label: "PENETRATOR",
            frontSrc: glowPenetratorImage,
        },
        {
            value: 4,
            label: "PENETRATOR XL",
            frontSrc: glowPenetratorXLImage,
        },
        {
            value: 5,
            label: "SUPERHEATED MACERATOR",
            src: glowSuperheatedMaceratorImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "REAR MOUNT",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "weapon",
        },
        {
            label: "RIGHT ARM",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "REAR MOUNT",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "LEFT ARM",
        "secondaryWeapon": "RIGHT ARM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
