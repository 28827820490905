import React, { useContext } from "react";

import { CurrentThemeContext } from "./ThemeContextWrapper";
import { RowWrapper, HalfWrapper } from "./ToggleHideUIContextWrapper";
import { UIText } from "./styles/UITextButton";

function BackgroundController({ className }) {
    const { currentTheme, selectTheme, themeOptions } = useContext(CurrentThemeContext);

    return (
        <RowWrapper>
            <HalfWrapper>
                <UIText className={className} title="Background Select Label">
                    BACKGROUND:
                </UIText>
            </HalfWrapper>
            <HalfWrapper>
                <select
                    id="background-select"
                    name="background"
                    onChange={(event) => {
                        return selectTheme(event.target.value);
                    }}
                    value={currentTheme}
                >
                    {themeOptions}
                </select>
            </HalfWrapper>
        </RowWrapper>
    );
}

export default BackgroundController;
