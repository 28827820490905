/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useState } from "react";

import {
    InterfaceButton,
    InterfaceThirdButton,
} from "./MechCategoryStyles";
import { drawImages } from "./../../ExportImages";

import { UserAuthContext } from "./../../../../User/UserAuthContextWrapper";
import { AnalyticsContext } from "./../../../../Analytics/AnalyticsContextWrapper";
import { UnitContext } from "./../../../UnitContextWrapper";
import ColorList, { OpacityList } from "./../../../../common/ColorList";
import ExportWrapper from "../../components/ExportWrapper";
import {
    BrandedMechPrintAnalytics,
    NPCMechPrintAnalytics,
    CustomMechPrintAnalytics,
    CustomPlusMechPrintAnalytics,
    safeName,
 } from "./../../../Print/PrintAnalytics";

import { brandList as mechNPCBrandList, npcLayerOrder } from "../../../LayerData/MechNPCLayerOrder";
import { brandList as mechBrandedBrandList, brandedLayerOrder } from "../../../LayerData/MechBrandedLayerOrder";

import { createExportLayerOptions } from "../../../LayerData/CreateLayerFunctions";

import Mechs from "./../../../data/Mechs-GMS";
import MechsCustomPlus from "./../../../data/Mechs-622";

import { premiumTiersList } from "../../PremiumPopup";

const brandList = Object.assign({},
    mechNPCBrandList,
    mechBrandedBrandList,
);

const canvasRef = createRef();
const anchorRef = createRef();

function ExportMechButton({ displayTheme, unit, name, mechType, setShowPremiumPopupFunc, singleImageType = false, isButtonThird = false }) {
    const { user } = useContext(UserAuthContext);
    const ReactGA = useContext(AnalyticsContext);
    const { unitBackgroundColor, unitBackgroundOpacity } = useContext(UnitContext);

    const [isLoading, setIsLoading] = useState(false);

    const isPremiumUser = user && user.isPremiumUser;

    let imageSize = 250;

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeus) {
        imageSize = 300;
    }

    if (
        mechType === "CUSTOM-PLUS"
        || (mechType !== "CUSTOM" && brandList[unit.brandValue][unit.frameValue].isMegadeusPlus)
    ) {
        imageSize = 350;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusPlusPlus) {
        imageSize = 400;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusOmega) {
        imageSize = 450;
    }

    function printSingleUnit(unit) {
        ReactGA.event({
            category: "Print",
            action: "Export",
            label: `core-${brandList[unit.brandValue][unit.frameValue].label}`,
        });

        anchorRef.current.click();
        setIsLoading(false);
    }
    if (typeof singleImageType === "number") {
        return (
            <ExportWrapper>
                <InterfaceThirdButton
                    className={displayTheme}
                    title={"Export Currently Selected Mech as PNG"}
                    onClick={isLoading ? () => {} : () => {
                        setIsLoading(true);
                        return printSingleUnit(unit);
                    }}
                >
                    {isLoading ? "LOADING" : "⇓ PNG"}
                </InterfaceThirdButton>
                <a
                    ref={anchorRef}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={brandList[unit.brandValue][unit.frameValue].coreImg[singleImageType].src}
                    download
                />
            </ExportWrapper>
        );
    }

    async function printCustomMech(unit) {
        let imageLayersArray = [];

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].backSrc,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].backSrc,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].backSrc,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].backSrc,
                filter: unit.accessory4TintValue,
            });
        }

        if (unit.weaponValue !== "NONE" && !!Mechs.weapon[unit.stanceValue][unit.weaponValue].backSrc) {
            imageLayersArray.push({
                src: Mechs.weapon[unit.stanceValue][unit.weaponValue].backSrc,
                filter: unit.weaponTintValue,
            });
        }

        if (unit.secondaryWeaponValue !== "NONE" && !!Mechs.secondaryWeapon[unit.secondaryWeaponValue].backSrc) {
            imageLayersArray.push({
                src: Mechs.secondaryWeapon[unit.secondaryWeaponValue].backSrc,
                filter: unit.secondaryWeaponTintValue,
            });
        }

        if (unit.rearMountValue !== "NONE" && !!Mechs.rearMount[unit.rearMountValue].backSrc) {
            imageLayersArray.push({
                src: Mechs.rearMount[unit.rearMountValue].backSrc,
                filter: unit.rearMountTintValue,
            });
        }

        if (unit.rearMountValue !== "NONE" && !!Mechs.rearMount[unit.rearMountValue].src) {
            imageLayersArray.push({
                src: Mechs.rearMount[unit.rearMountValue].src,
                filter: unit.rearMountTintValue,
            });
        }

        if (unit.legsValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.legs[unit.legsValue].src,
                filter: unit.legsTintValue,
            });
        }

        if (unit.armLeftValue !== "NONE" && !!Mechs.arms[unit.armLeftValue].src[unit.stanceValue].skeleton) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armLeftValue].src[unit.stanceValue].skeleton,
                filter: unit.armLeftTintValue,
            });
        }

        if (unit.armLeftValue !== "NONE" && (Mechs.arms[unit.armLeftValue].isMounted || Mechs.arms[unit.armLeftValue].isLeftArmBackRow)) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armLeftValue].src[unit.stanceValue].left,
                filter: unit.armLeftTintValue,
            });
        }

        if (unit.chassisValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.chassis[unit.chassisValue].src,
                filter: unit.chassisTintValue,
            });
        }

        if (unit.rearMountValue !== "NONE" && !!Mechs.rearMount[unit.rearMountValue].frontSrc) {
            imageLayersArray.push({
                src: Mechs.rearMount[unit.rearMountValue].frontSrc,
                filter: unit.rearMountTintValue,
            });
        }

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory1Value].altSrc :
                Mechs.accessory[unit.accessory1Value].src,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory2Value].altSrc :
                Mechs.accessory[unit.accessory2Value].src,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory3Value].altSrc :
                Mechs.accessory[unit.accessory3Value].src,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory4Value].altSrc :
                Mechs.accessory[unit.accessory4Value].src,
                filter: unit.accessory4TintValue,
            });
        }

        if (unit.headValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.head[unit.headValue].src,
                filter: unit.headTintValue,
            });
        }

        if (unit.armRightValue !== "NONE" && !Mechs.arms[unit.armRightValue].isMounted) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armRightValue].src[unit.stanceValue].right,
                filter: unit.armRightTintValue,
            });
        }

        if (unit.armLeftValue !== "NONE" && !(Mechs.arms[unit.armLeftValue].isMounted || Mechs.arms[unit.armLeftValue].isLeftArmBackRow)) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armLeftValue].src[unit.stanceValue].left,
                filter: unit.armLeftTintValue,
            });
        }

        if (unit.secondaryWeaponValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.secondaryWeapon[unit.secondaryWeaponValue].src,
                filter: unit.secondaryWeaponTintValue,
            });
        }

        if (unit.weaponValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.weapon[unit.stanceValue][unit.weaponValue].src,
                filter: unit.weaponTintValue,
            });
        }

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].frontSrc,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].frontSrc,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].frontSrc,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].frontSrc,
                filter: unit.accessory4TintValue,
            });
        }

        if (unit.armRightValue !== "NONE" && Mechs.arms[unit.armRightValue].isMounted) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armRightValue].src[unit.stanceValue].right,
                filter: unit.armRightTintValue,
            });
        }

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].foremostSrc,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].foremostSrc,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].foremostSrc,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].foremostSrc,
                filter: unit.accessory4TintValue,
            });
        }

        const canvas = canvasRef.current;
        var ctx = canvas.getContext('2d');

        let compositeImage;

        ctx.fillStyle = `${ColorList[unitBackgroundColor].hex}${OpacityList[unitBackgroundOpacity].opacity}`;
        ctx.fillRect(0, 0, imageSize, imageSize);

        await drawImages(ctx, imageLayersArray);

        compositeImage = await canvas.toDataURL("image/png");

        anchorRef.current.download = `Retrograde-Minis-${safeName(name)}.png`;
        anchorRef.current.href = compositeImage;
        navigator.clipboard.writeText(compositeImage);
        anchorRef.current.click();

        ReactGA.event({
            category: "Print",
            action: "Export",
            label: `${safeName(name)}`,
        });
        CustomMechPrintAnalytics(unit, ReactGA);

        ctx.clearRect(0, 0, imageSize, imageSize);
        setIsLoading(false);
    }

    async function printCustomPlusMech(unit) {
        let imageLayersArray = [];

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].backSrc,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].backSrc,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].backSrc,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].backSrc,
                filter: unit.accessory4TintValue,
            });
        }

        if (unit.weaponValue !== "NONE" && !!MechsCustomPlus.weapon[unit.stanceValue][unit.weaponValue].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.weapon[unit.stanceValue][unit.weaponValue].backSrc,
                filter: unit.weaponTintValue,
            });
        }

        if (unit.secondaryWeaponValue !== "NONE" && !!MechsCustomPlus.secondaryWeapon[unit.secondaryWeaponValue].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.secondaryWeapon[unit.secondaryWeaponValue].backSrc,
                filter: unit.secondaryWeaponTintValue,
            });
        }

        if (unit.rearMountValue !== "NONE" && !!MechsCustomPlus.rearMount[unit.rearMountValue].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.rearMount[unit.rearMountValue].backSrc,
                filter: unit.rearMountTintValue,
            });
        }

        if (unit.rearMountValue !== "NONE" && !!MechsCustomPlus.rearMount[unit.rearMountValue].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.rearMount[unit.rearMountValue].src,
                filter: unit.rearMountTintValue,
            });
        }

        if (unit.legsValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.legs[unit.legsValue].src,
                filter: unit.legsTintValue,
            });
        }

        if (unit.armLeftValue !== "NONE" && !!MechsCustomPlus.leftArm[unit.armLeftValue].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.leftArm[unit.armLeftValue].src,
                filter: unit.armLeftTintValue,
            });
        }

        if (unit.chassisValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.chassis[unit.chassisValue].src,
                filter: unit.chassisTintValue,
            });
        }

        if (unit.rearMountValue !== "NONE" && !!MechsCustomPlus.rearMount[unit.rearMountValue].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.rearMount[unit.rearMountValue].frontSrc,
                filter: unit.rearMountTintValue,
            });
        }

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory1Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory1Value].src,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory2Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory2Value].src,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory3Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory3Value].src,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory4Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory4Value].src,
                filter: unit.accessory4TintValue,
            });
        }

        if (unit.headValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.head[unit.headValue].src,
                filter: unit.headTintValue,
            });
        }

        if (unit.armRightValue !== "NONE" && !!MechsCustomPlus.rightArm[unit.armRightValue].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.rightArm[unit.armRightValue].src,
                filter: unit.armRightTintValue,
            });
        }

        if (unit.secondaryWeaponValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.secondaryWeapon[unit.secondaryWeaponValue].src,
                filter: unit.secondaryWeaponTintValue,
            });
        }

        if (unit.weaponValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.weapon[unit.stanceValue][unit.weaponValue].src,
                filter: unit.weaponTintValue,
            });
        }

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].frontSrc,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].frontSrc,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].frontSrc,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].frontSrc,
                filter: unit.accessory4TintValue,
            });
        }

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].foremostSrc,
                filter: unit.accessory1TintValue,
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].foremostSrc,
                filter: unit.accessory2TintValue,
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].foremostSrc,
                filter: unit.accessory3TintValue,
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].foremostSrc,
                filter: unit.accessory4TintValue,
            });
        }

        const canvas = canvasRef.current;
        var ctx = canvas.getContext('2d');

        let compositeImage;

        ctx.fillStyle = `${ColorList[unitBackgroundColor].hex}${OpacityList[unitBackgroundOpacity].opacity}`;
        ctx.fillRect(0, 0, imageSize, imageSize);

        await drawImages(ctx, imageLayersArray);

        compositeImage = await canvas.toDataURL("image/png");

        anchorRef.current.download = `Retrograde-Minis-${safeName(name)}.png`;
        anchorRef.current.href = compositeImage;
        navigator.clipboard.writeText(compositeImage);
        anchorRef.current.click();

        ReactGA.event({
            category: "Print",
            action: "Export",
            label: `${safeName(name)}`,
        });
        CustomPlusMechPrintAnalytics(unit, ReactGA);

        ctx.clearRect(0, 0, imageSize, imageSize);
        setIsLoading(false);
    }

    async function printBrandedMech(unit) {
        let imageLayersArray = createExportLayerOptions({unit, brandList: mechBrandedBrandList, layerOrder: brandedLayerOrder});

        const canvas = canvasRef.current;
        var ctx = canvas.getContext('2d');

        let compositeImage;

        ctx.fillStyle = `${ColorList[unitBackgroundColor].hex}${OpacityList[unitBackgroundOpacity].opacity}`;
        ctx.fillRect(0, 0, imageSize, imageSize);

        await drawImages(ctx, imageLayersArray);

        compositeImage = await canvas.toDataURL("image/png");

        anchorRef.current.download = `Retrograde-Minis-${safeName(name)}.png`;
        anchorRef.current.href = compositeImage;
        navigator.clipboard.writeText(compositeImage);
        anchorRef.current.click();

        ReactGA.event({
            category: "Print",
            action: "Export",
            label: `${safeName(name)}`,
        });
        BrandedMechPrintAnalytics(unit, ReactGA);

        ctx.clearRect(0, 0, imageSize, imageSize);
        setIsLoading(false);
    }

    async function printNPCMech(unit) {
        let imageLayersArray = createExportLayerOptions({unit, brandList: mechNPCBrandList, layerOrder: npcLayerOrder});

        const canvas = canvasRef.current;
        var ctx = canvas.getContext('2d');

        let compositeImage;

        ctx.fillStyle = `${ColorList[unitBackgroundColor].hex}${OpacityList[unitBackgroundOpacity].opacity}`;
        ctx.fillRect(0, 0, imageSize, imageSize);

        await drawImages(ctx, imageLayersArray);

        compositeImage = await canvas.toDataURL("image/png");

        anchorRef.current.download = `Retrograde-Minis-${safeName(name)}.png`;
        anchorRef.current.href = compositeImage;
        navigator.clipboard.writeText(compositeImage);
        anchorRef.current.click();

        ReactGA.event({
            category: "Print",
            action: "Export",
            label: `${safeName(name)}`,
        });
        NPCMechPrintAnalytics(unit, ReactGA);

        ctx.clearRect(0, 0, imageSize, imageSize);
        setIsLoading(false);
    }

    const ButtonComponent = isButtonThird ? InterfaceThirdButton : InterfaceButton;

    return (
        <ExportWrapper>
            <ButtonComponent
                className={displayTheme}
                title={"Export Currently Selected Mech Parts as PNG with currently applied color tints"}
                onClick={isLoading ? () => {} : () => {
                    if (!isPremiumUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE);
                    } else {
                        setIsLoading(true);
                        if (mechType === "CUSTOM") {
                            return printCustomMech(unit);
                        } else if (mechType === "CUSTOM-PLUS") {
                            return printCustomPlusMech(unit);
                        } else if (mechType === "NPC") {
                            return printNPCMech(unit);
                        } else {
                            return printBrandedMech(unit);
                        }
                    }
                }}
            >
                {isLoading ? "LOADING" : "⇣ PNG ●"}
            </ButtonComponent>
            <canvas ref={canvasRef} height={imageSize} width={imageSize} crossOrigin="anonymous" />
            <a ref={anchorRef} target="_blank" download />
        </ExportWrapper>
    );
}

export default ExportMechButton;
