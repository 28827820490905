import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Thunderhog`;

// BODY
const bodyWingsDownImage = `${imagePath}/Chassis - Wings Down.png`;
const bodyWingsUpImage = `${imagePath}/Chassis - Wings Up.png`;

// WING MOUNT
const wingMissileslegsdeployedImage = `${imagePath}/wings/Wing Mounts - Missiles legs deployed.png`;
const wingMissileslegsretractedImage = `${imagePath}/wings/Wing Mounts - Missiles legs retracted.png`;
const wingVTOLEnginesMissilesImage = `${imagePath}/wings/Wing Mounts - VTOL Engines Missiles.png`;
const wingVTOLEnginesImage = `${imagePath}/wings/Wing Mounts - VTOL Engines.png`;

// LEGS
const legsDeployedImage = `${imagePath}/legs/Legs Over - Deployed.png`;
const legsDeployedBackImage = `${imagePath}/legs/Legs Under - Deployed.png`;
const legsRetractedImage = `${imagePath}/legs/Legs Over - Retracted.png`;
const legsRetractedBackImage = `${imagePath}/legs/Legs Under - Retracted.png`;

// WEAPONS
const weaponDualHMGImage = `${imagePath}/weapons/Heavy Weapon - Dual HMG.png`;
const weaponHeavyGatling1Image = `${imagePath}/weapons/Heavy Weapon - Heavy Gatling 1.png`;
const weaponHeavyGatling2Image = `${imagePath}/weapons/Heavy Weapon - Heavy Gatling 2.png`;

// ACCESSORIES
const accCannonsImage = `${imagePath}/accessories/Chassis Mounts - Cannons.png`;
const accDualGatlingsImage = `${imagePath}/accessories/Chassis Mounts - Dual Gatlings.png`;
const accMissilesImage = `${imagePath}/accessories/Chassis Mounts - Missiles.png`;

// GLOWS
const glowMainEnginesImage = `${imagePath}/glow/Glow - Main Engines Engines.png`;
const glowMainEnginesBackImage = `${imagePath}/glow/Glow - Main Engines Engines Back.png`;
const glowVTOLImage = `${imagePath}/glow/Glow - VTOL Engines.png`;
const glowMainEnginesVTOLImage = `${imagePath}/glow/Glow - Main VTOL.png`;
const glowMainEnginesVTOLBackImage = `${imagePath}/glow/Glow - Main VTOL Back.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const regularShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "65px",
    right: "65px",
};

const stiltsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "72px",
    right: "66px",
};

export default {
    isMegadeusPlus: true,
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "stanceValue",
    hasMultipleStances: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "2",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "1",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "3",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DUAL HMG",
            backSrc: weaponDualHMGImage,
        },
        {
            value: 1,
            label: "HEAVY GATLING 1",
            backSrc: weaponHeavyGatling1Image,
        },
        {
            value: 2,
            label: "HEAVY GATLING 2",
            backSrc: weaponHeavyGatling2Image,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "WINGS DOWN",
            src: bodyWingsDownImage,
            shadowDetails: regularShadow,
        },
        {
            value: 1,
            label: "WINGS UP",
            src: bodyWingsUpImage,
            shadowDetails: stiltsShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "MISSILES - LEGS DEPLOYED",
                src: wingMissileslegsdeployedImage,
            },
            {
                value: 1,
                label: "MISSILES - LEGS RETRACTED",
                src: wingMissileslegsretractedImage,
            },
            {
                value: 2,
                label: "VTOL ENGINES",
                src: wingVTOLEnginesImage,
            },
            {
                value: 3,
                label: "VTOL ENGINES + MISSILES",
                src: wingVTOLEnginesMissilesImage,
            },
        ],
        "1": [],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DEPLOYED",
            src: legsDeployedImage,
            backSrc: legsDeployedBackImage,
        },
        {
            value: 1,
            label: "RETRACTED",
            src: legsRetractedImage,
            backSrc: legsRetractedBackImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CANNONS",
            frontSrc: accCannonsImage,
        },
        {
            value: 1,
            label: "DUAL GATLINGS",
            frontSrc: accDualGatlingsImage,
        },
        {
            value: 2,
            label: "MISSILES",
            frontSrc: accMissilesImage,
        },
        {
            value: 3,
            label: "GLOW - MAIN ENGINES",
            frontSrc: glowMainEnginesImage,
            backSrc: glowMainEnginesBackImage,
        },
        {
            value: 4,
            label: "GLOW - VTOL",
            frontSrc: glowVTOLImage,
        },
        {
            value: 5,
            label: "GLOW - MAIN ENGINES + VTOL",
            frontSrc: glowMainEnginesVTOLImage,
            backSrc: glowMainEnginesVTOLBackImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "CANNON",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WING MOUNT",
            value: "weapon",
        },
        {
            label: "LEGS",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "CANNON",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "WING MOUNT",
        "secondaryWeapon": "LEGS",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
