import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyChestClosedImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Closed.png`;
const bodyChestClosedBatteriesImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Closed, Batteries.png`;
const bodyChestClosedFlightUnitImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Closed, Flight Unit.png`;
const bodyChestClosedFlightUnitBatteriesImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Closed, Flight Unit, Batteries.png`;
const bodyChestClosedHeatSinksImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Closed, Heat Sinks.png`;
const bodyChestClosedHeatSinksBatteriesImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Closed, Heat Sinks, Batteries.png`;
const bodyChestOpenImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Open.png`;
const bodyChestOpenBatteriesImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Open, Batteries.png`;
const bodyChestOpenFlightUnitImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Open, Flight Unit.png`;
const bodyChestOpenFlightUnitBatteriesImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Open, Flight Unit, Batteries.png`;
const bodyChestOpenHeatSinksImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Open, Heat Sinks.png`;
const bodyChestOpenHeatSinksBatteriesImage = `${BaseURL}/Mechs/HA/Tokugawa/Chassis - Chest Open, Heat Sinks, Batteries.png`;

// HEADS
const headTokugawaImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/Head - Tokugawa.png`;
const headKazuImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/Head - Kazu.png`;
// PREMIUM
const headCalibanImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Chimera.png`;
const headCockpitImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Cockpit.png`;
const headCyclopsImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Cyclops.png`;
const headHornedImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Malice.png`;
const headMetalmarkImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Metalmark.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Rain.png`;
const headRainHairImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Rain Hair.png`;
const headScopehoundImage = `${BaseURL}/Mechs/HA/Tokugawa/heads/premium/Head - Scopehound.png`;

// WEAPONS
// LOWER
const weaponLowerFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/lower/Lower Arms - Fists.png`;
const weaponLowerKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/lower/Lower Arms - Knives.png`;
const weaponLowerTorchesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/lower/Lower Arms - Torches.png`;
const weaponLowerVariableBladesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/lower/Lower Arms - Variable Blades.png`;
const weaponLowerChargedBladesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/lower/Lower Arms - Charged Blades.png`;
const weaponLowerSOLPatternRifleImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/lower/Lower Arms - SOL Pattern Rifle.png`;
const weaponLowerAkimboSOLsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/lower/Lower Arms - Akimbo SOLs.png`;

// UPPER
const weaponUpperFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/upper/Upper Arms - Fists.png`;
const weaponUpperKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/upper/Upper Arms - Knives.png`;
const weaponUpperTorchesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/upper/Upper Arms - Torches.png`;
const weaponUpperThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/upper/Upper Arms - Thermal Pistols.png`;
const weaponUpperLMGsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/upper/Upper Arms - LMGs.png`;
const weaponUpperLaunchersImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/upper/Upper Arms - Launchers.png`;

// COMBO
const weaponComboFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Fists.png`;
const weaponComboFistsKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Fists Knives.png`;
const weaponComboFistsThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Fists Thermal Pistols.png`;
const weaponComboKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Knives.png`;
const weaponComboKnivesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Knives Fists.png`;
const weaponComboKnivesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Knives Thermal Pistols.png`;
const weaponComboTorches1Image = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Torches I.png`;
const weaponComboTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Torches II.png`;
const weaponComboTorchesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Torches Fists.png`;
const weaponComboTorchesKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Torches Knives.png`;
const weaponComboTorchesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Torches Thermal Pistols.png`;
const weaponComboVariableBladesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Variable Blades Fists.png`;
const weaponComboVariableBladesKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Variable Blades Knives.png`;
const weaponComboVariableBladesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Variable Blades Thermal Pistols.png`;
const weaponComboVariableBladesTorches1Image = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Variable Blades Torches I.png`;
const weaponComboVariableBladesTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Variable Blades Torches II.png`;
const weaponComboChargedBladesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Charged Blades Fists.png`;
const weaponComboChargedBladesKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Charged Blades Knives.png`;
const weaponComboChargedBladesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Charged Blades Thermal Pistols.png`;
const weaponComboChargedBladesTorches1Image = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Charged Blades Torches.png`;
const weaponComboChargedBladesTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Charged Blades Torches II.png`;
const weaponComboSOLThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/SOL Thermal Pistols.png`;
const weaponComboSOLLMGsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/SOL LMGs.png`;
const weaponComboSOLRPGLaunchersImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/SOL RPG Launchers.png`;
const weaponComboAkimboSOLThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Akimbo SOL Pistols.png`;
const weaponComboAkimboSOLLMGsImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Akimbo SOL LMGs.png`;
const weaponComboAkimboSOLRPGLaunchersImage = `${BaseURL}/Mechs/HA/Tokugawa/weapons/Akimbo SOL RPG Launchers.png`;



// GLOW
// BODY
const glowChestClosedImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Closed.png`;
const glowChestClosedNoHeadImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Closed No Head.png`;
const glowChestOpenImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Open.png`;
const glowChestOpenNoHeadImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Open No Head.png`;
const glowChestOpenFlamesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Open Flames.png`;
const glowChestOpenFlamesNoHeadImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Open Flames No Head.png`;
const glowChestOpenFlamesAnnihilatorImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Open Flames Annihilator.png`;
const glowChestOpenFlamesAnnihilatorNoHeadImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Dangerzone Chest Open Flames Annihilator No Head.png`;
const glowHeatSinkImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Heat Sinks.png`;
const glowFlightUnitImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - Flight Unit.png`;
const glowBatteriesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Chassis Glow - External Batteries.png`;
const glowBatteriesHeatSinksImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/chassis/Glow - Heat Sinks, Batteries.png`;

// WEAPONS
const glowLowerFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - Fists Glow.png`;
const glowLowerKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - Knives Glow.png`;
const glowLowerTorchesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - Torches Glow I.png`;
const glowLowerTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - Torches Glow II.png`;
const glowLowerVariableBladesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - Variable Blades Glow.png`;
const glowLowerChargedBladesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - Charged Blades Glow.png`;
const glowLowerSOLPatternRifleImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - SOL pattern rifle Glow.png`;
const glowLowerAkimboSOLsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Lower - Akimbo SOL Glow.png`;
const glowUpperFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Upper - Fists Glow.png`;
const glowUpperKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Upper - Knives Glow.png`;
const glowUpperTorchesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Upper - Torches Glow I.png`;
const glowUpperTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Upper - Torches Glow II.png`;
const glowUpperThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Upper - Thermal Pistols Glow.png`;
const glowUpperLMGsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Upper - LMGs Glow.png`;
const glowUpperLaunchersImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Upper - Launchers Glow.png`;
const glowComboFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Fists Glow.png`;
const glowComboFistsKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Fists Knives Glow.png`;
const glowComboFistsThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Fists Thermal Pistols Glow.png`;
const glowComboKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Knives Glow.png`;
const glowComboKnivesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Knives Fists Glow.png`;
const glowComboKnivesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Knives Thermal Pistols Glow.png`;
const glowComboTorches1Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Torches Glow I.png`;
const glowComboTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Torches Glow II.png`;
const glowComboTorchesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Torches Fists Glow.png`;
const glowComboTorchesKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Torches Knives Glow.png`;
const glowComboTorchesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Torches Thermal Pistols Glow.png`;
const glowComboVariableBladesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Variable Blades Fists Glow.png`;
const glowComboVariableBladesKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Variable Blades Knives Glow.png`;
const glowComboVariableBladesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Variable Blades Thermal Pistols Glow.png`;
const glowComboVariableBladesTorches1Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Variable Blades Torches Glow.png`;
const glowComboVariableBladesTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Variable Blades Torches Glow II.png`;
const glowComboChargedBladesFistsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Charged Blades Fists Glow.png`;
const glowComboChargedBladesKnivesImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Charged Blades Knives Glow.png`;
const glowComboChargedBladesThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Charged Blades Thermal Pistols Glow.png`;
const glowComboChargedBladesTorches1Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Charged Blades Torches Glow I.png`;
const glowComboChargedBladesTorches2Image = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Charged Blades Torches Glow II.png`;
const glowComboSOLThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/SOL Thermal Pistols Glow.png`;
const glowComboSOLLMGsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/SOL LMGs Glow.png`;
const glowComboSOLRPGLaunchersImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/SOL RPG Launchers Glow.png`;
const glowComboAkimboSOLThermalPistolsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Akimbo SOL Pistols Glow.png`;
const glowComboAkimboSOLLMGsImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Akimbo SOL LMGs Glow.png`;
const glowComboAkimboSOLRPGLaunchersImage = `${BaseURL}/Mechs/HA/Tokugawa/glows/weapons/Akimbo SOL RPG Launchers Glow.png`;

const coreImage = `${BaseURL}/Mechs/HA/Tokugawa/Tokugawa (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/HA/Tokugawa/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "59px",
    right: "34px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    weaponSpecial: "secondaryWeaponValue",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "22",
        "secondaryWeaponValue": "22",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "METALMARK",
            src: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RAIN HAIR",
            src: headRainHairImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CHEST CLOSED",
            src: bodyChestClosedImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "CHEST CLOSED + BATTERIES",
            src: bodyChestClosedBatteriesImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "CHEST CLOSED + FLIGHT UNIT",
            src: bodyChestClosedFlightUnitImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "CHEST CLOSED + HEAT SINKS",
            src: bodyChestClosedHeatSinksImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 4,
            label: "CHEST CLOSED + HEAT SINKS + BATTERIES",
            src: bodyChestClosedHeatSinksBatteriesImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 5,
            label: "CHEST CLOSED + FLIGHT UNIT + BATTERIES",
            src: bodyChestClosedFlightUnitBatteriesImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 6,
            label: "CHEST OPEN",
            src: bodyChestOpenImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 7,
            label: "CHEST OPEN + BATTERIES",
            src: bodyChestOpenBatteriesImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 8,
            label: "CHEST OPEN + FLIGHT UNIT",
            src: bodyChestOpenFlightUnitImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 9,
            label: "CHEST OPEN + HEAT SINKS",
            src: bodyChestOpenHeatSinksImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 10,
            label: "CHEST OPEN + HEAT SINKS + BATTERIES",
            src: bodyChestOpenHeatSinksBatteriesImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 11,
            label: "CHEST OPEN + FLIGHT UNIT + BATTERIES",
            src: bodyChestOpenFlightUnitBatteriesImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "LOWER - FISTS",
                src: weaponLowerFistsImage,
            },
            {
                value: 1,
                label: "LOWER - KNIVES",
                src: weaponLowerKnivesImage,
            },
            {
                value: 2,
                label: "LOWER - TORCHES I",
                src: weaponLowerTorchesImage,
            },
            {
                value: 3,
                label: "LOWER - TORCHES II",
                src: weaponLowerTorchesImage,
            },
            {
                value: 4,
                label: "LOWER - VARIABLE BLADES",
                src: weaponLowerVariableBladesImage,
            },
            {
                value: 5,
                label: "LOWER - CHARGED BLADES",
                src: weaponLowerChargedBladesImage,
            },
            {
                value: 6,
                label: "LOWER - SOL PATTERN RIFLE",
                src: weaponLowerSOLPatternRifleImage,
            },
            {
                value: 7,
                label: "LOWER - SOLS AKIMBO",
                src: weaponLowerAkimboSOLsImage,
            },
            {
                value: 8,
                label: "UPPER - FISTS",
                src: weaponUpperFistsImage,
            },
            {
                value: 9,
                label: "UPPER - KNIVES",
                src: weaponUpperKnivesImage,
            },
            {
                value: 10,
                label: "UPPER - TORCHES I",
                src: weaponUpperTorchesImage,
            },
            {
                value: 11,
                label: "UPPER - TORCHES II",
                src: weaponUpperTorchesImage,
            },
            {
                value: 12,
                label: "UPPER - THERMAL PISTOLS",
                src: weaponUpperThermalPistolsImage,
            },
            {
                value: 13,
                label: "UPPER - LMGS",
                frontSrc: weaponUpperLMGsImage,
            },
            {
                value: 14,
                label: "UPPER - LAUNCHERS",
                frontSrc: weaponUpperLaunchersImage,
            },
            {
                value: 15,
                label: "FISTS X4",
                src: weaponComboFistsImage,
            },
            {
                value: 16,
                label: "FISTS + KNIVES",
                src: weaponComboFistsKnivesImage,
            },
            {
                value: 17,
                label: "FISTS + THERMAL PISTOLS",
                src: weaponComboFistsThermalPistolsImage,
            },
            {
                value: 18,
                label: "KNIVES X4",
                src: weaponComboKnivesImage,
            },
            {
                value: 19,
                label: "KNIVES + FISTS",
                src: weaponComboKnivesFistsImage,
            },
            {
                value: 20,
                label: "KNIVES + THERMAL PISTOLS",
                src: weaponComboKnivesThermalPistolsImage,
            },
            {
                value: 21,
                label: "TORCHES I X4",
                src: weaponComboTorches1Image,
            },
            {
                value: 22,
                label: "TORCHES II X4",
                src: weaponComboTorches2Image,
            },
            {
                value: 23,
                label: "TORCHES + FISTS",
                src: weaponComboTorchesFistsImage,
            },
            {
                value: 24,
                label: "TORCHES + KNIVES",
                src: weaponComboTorchesKnivesImage,
            },
            {
                value: 25,
                label: "TORCHES + THERMAL PISTOLS",
                src: weaponComboTorchesThermalPistolsImage,
            },
            {
                value: 26,
                label: "VARIABLE BLADES + FISTS",
                src: weaponComboVariableBladesFistsImage,
            },
            {
                value: 27,
                label: "VARIABLE BLADES + KNIVES",
                src: weaponComboVariableBladesKnivesImage,
            },
            {
                value: 28,
                label: "VARIABLE BLADES + THERMAL PISTOLS",
                src: weaponComboVariableBladesThermalPistolsImage,
            },
            {
                value: 29,
                label: "VARIABLE BLADES + TORCHES I",
                src: weaponComboVariableBladesTorches1Image,
            },
            {
                value: 30,
                label: "VARIABLE BLADES + TORCHES II",
                src: weaponComboVariableBladesTorches2Image,
            },
            {
                value: 31,
                label: "CHARGED BLADES + FISTS",
                src: weaponComboChargedBladesFistsImage,
            },
            {
                value: 32,
                label: "CHARGED BLADES + KNIVES",
                src: weaponComboChargedBladesKnivesImage,
            },
            {
                value: 33,
                label: "CHARGED BLADES + THERMAL PISTOLS",
                src: weaponComboChargedBladesThermalPistolsImage,
            },
            {
                value: 34,
                label: "CHARGED BLADES + TORCHES I",
                src: weaponComboChargedBladesTorches1Image,
            },
            {
                value: 35,
                label: "CHARGED BLADES + TORCHES II",
                src: weaponComboChargedBladesTorches2Image,
            },
            {
                value: 36,
                label: "SOL RIFLE + THERMAL PISTOLS",
                src: weaponComboSOLThermalPistolsImage,
            },
            {
                value: 37,
                label: "SOL RIFLE + LMGS",
                frontSrc: weaponComboSOLLMGsImage,
            },
            {
                value: 38,
                label: "SOL RIFLE + LAUNCHERS",
                frontSrc: weaponComboSOLRPGLaunchersImage,
            },
            {
                value: 39,
                label: "SOL AKIMBO + THERMAL PISTOLS",
                src: weaponComboAkimboSOLThermalPistolsImage,
            },
            {
                value: 40,
                label: "SOL AKIMBO + LMGS",
                frontSrc: weaponComboAkimboSOLLMGsImage,
            },
            {
                value: 41,
                label: "SOL AKIMBO + LAUNCHERS",
                frontSrc: weaponComboAkimboSOLRPGLaunchersImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "LOWER - FISTS",
            foremostSrc: glowLowerFistsImage,
        },
        {
            value: 1,
            label: "LOWER - KNIVES",
            foremostSrc: glowLowerKnivesImage,
        },
        {
            value: 2,
            label: "LOWER - TORCHES I",
            foremostSrc: glowLowerTorchesImage,
        },
        {
            value: 3,
            label: "LOWER - TORCHES II",
            foremostSrc: glowLowerTorches2Image,
        },
        {
            value: 4,
            label: "LOWER - VARIABLE BLADES",
            foremostSrc: glowLowerVariableBladesImage,
        },
        {
            value: 5,
            label: "LOWER - CHARGED BLADES",
            foremostSrc: glowLowerChargedBladesImage,
        },
        {
            value: 6,
            label: "LOWER - SOL PATTERN RIFLE",
            foremostSrc: glowLowerSOLPatternRifleImage,
        },
        {
            value: 7,
            label: "LOWER - SOLS AKIMBO",
            foremostSrc: glowLowerAkimboSOLsImage,
        },
        {
            value: 8,
            label: "UPPER - FISTS",
            foremostSrc: glowUpperFistsImage,
        },
        {
            value: 9,
            label: "UPPER - KNIVES",
            foremostSrc: glowUpperKnivesImage,
        },
        {
            value: 10,
            label: "UPPER - TORCHES I",
            foremostSrc: glowUpperTorchesImage,
        },
        {
            value: 11,
            label: "UPPER - TORCHES II",
            foremostSrc: glowUpperTorches2Image,
        },
        {
            value: 12,
            label: "UPPER - THERMAL PISTOLS",
            foremostSrc: glowUpperThermalPistolsImage,
        },
        {
            value: 13,
            label: "UPPER - LMGS",
            foremostSrc: glowUpperLMGsImage,
        },
        {
            value: 14,
            label: "UPPER - LAUNCHERS",
            foremostSrc: glowUpperLaunchersImage,
        },
        {
            value: 15,
            label: "FISTS X4",
            foremostSrc: glowComboFistsImage,
        },
        {
            value: 16,
            label: "FISTS + KNIVES",
            foremostSrc: glowComboFistsKnivesImage,
        },
        {
            value: 17,
            label: "FISTS + THERMAL PISTOLS",
            foremostSrc: glowComboFistsThermalPistolsImage,
        },
        {
            value: 18,
            label: "KNIVES X4",
            foremostSrc: glowComboKnivesImage,
        },
        {
            value: 19,
            label: "KNIVES + FISTS",
            foremostSrc: glowComboKnivesFistsImage,
        },
        {
            value: 20,
            label: "KNIVES + THERMAL PISTOLS",
            foremostSrc: glowComboKnivesThermalPistolsImage,
        },
        {
            value: 21,
            label: "TORCHES I X4",
            foremostSrc: glowComboTorches1Image,
        },
        {
            value: 22,
            label: "TORCHES II X4",
            foremostSrc: glowComboTorches2Image,
        },
        {
            value: 23,
            label: "TORCHES + FISTS",
            foremostSrc: glowComboTorchesFistsImage,
        },
        {
            value: 24,
            label: "TORCHES + KNIVES",
            foremostSrc: glowComboTorchesKnivesImage,
        },
        {
            value: 25,
            label: "TORCHES + THERMAL PISTOLS",
            foremostSrc: glowComboTorchesThermalPistolsImage,
        },
        {
            value: 26,
            label: "VARIABLE BLADES + FISTS",
            foremostSrc: glowComboVariableBladesFistsImage,
        },
        {
            value: 27,
            label: "VARIABLE BLADES + KNIVES",
            foremostSrc: glowComboVariableBladesKnivesImage,
        },
        {
            value: 28,
            label: "VARIABLE BLADES + THERMAL PISTOLS",
            foremostSrc: glowComboVariableBladesThermalPistolsImage,
        },
        {
            value: 29,
            label: "VARIABLE BLADES + TORCHES I",
            foremostSrc: glowComboVariableBladesTorches1Image,
        },
        {
            value: 30,
            label: "VARIABLE BLADES + TORCHES II",
            foremostSrc: glowComboVariableBladesTorches2Image,
        },
        {
            value: 31,
            label: "CHARGED BLADES + FISTS",
            foremostSrc: glowComboChargedBladesFistsImage,
        },
        {
            value: 32,
            label: "CHARGED BLADES + KNIVES",
            foremostSrc: glowComboChargedBladesKnivesImage,
        },
        {
            value: 33,
            label: "CHARGED BLADES + THERMAL PISTOLS",
            foremostSrc: glowComboChargedBladesThermalPistolsImage,
        },
        {
            value: 34,
            label: "CHARGED BLADES + TORCHES I",
            foremostSrc: glowComboChargedBladesTorches1Image,
        },
        {
            value: 35,
            label: "CHARGED BLADES + TORCHES II",
            foremostSrc: glowComboChargedBladesTorches2Image,
        },
        {
            value: 36,
            label: "SOL RIFLE + THERMAL PISTOLS",
            foremostSrc: glowComboSOLThermalPistolsImage,
        },
        {
            value: 37,
            label: "SOL RIFLE + LMGS",
            foremostSrc: glowComboSOLLMGsImage,
        },
        {
            value: 38,
            label: "SOL RIFLE + LAUNCHERS",
            foremostSrc: glowComboSOLRPGLaunchersImage,
        },
        {
            value: 39,
            label: "SOL AKIMBO + THERMAL PISTOLS",
            foremostSrc: glowComboAkimboSOLThermalPistolsImage,
        },
        {
            value: 40,
            label: "SOL AKIMBO + LMGS",
            foremostSrc: glowComboAkimboSOLLMGsImage,
        },
        {
            value: 41,
            label: "SOL AKIMBO + LAUNCHERS",
            foremostSrc: glowComboAkimboSOLRPGLaunchersImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "DANGERZONE CHEST CLOSED",
            src: glowChestClosedImage,
        },
        {
            value: 1,
            label: "DANGERZONE CHEST CLOSED - NO HEAD",
            src: glowChestClosedNoHeadImage,
        },
        {
            value: 2,
            label: "DANGERZONE CHEST OPEN",
            src: glowChestOpenImage,
        },
        {
            value: 3,
            label: "DANGERZONE CHEST OPEN - NO HEAD",
            src: glowChestOpenNoHeadImage,
        },
        {
            value: 4,
            label: "DANGERZONE FLAMES",
            src: glowChestOpenFlamesImage,
        },
        {
            value: 5,
            label: "DANGERZONE FLAMES - NO HEAD",
            src: glowChestOpenFlamesNoHeadImage,
        },
        {
            value: 6,
            label: "DANGERZONE ANNIHILATOR FLAMES",
            src: glowChestOpenFlamesAnnihilatorImage,
        },
        {
            value: 7,
            label: "DANGERZONE ANNIHILATOR FLAMES - NO HEAD",
            src: glowChestOpenFlamesAnnihilatorNoHeadImage,
        },
        {
            value: 8,
            label: "BATTERIES",
            src: glowBatteriesImage,
        },
        {
            value: 9,
            label: "HEAT SINK",
            src: glowHeatSinkImage,
        },
        {
            value: 10,
            label: "FLIGHT UNIT",
            backSrc: glowFlightUnitImage,
        },
        {
            value: 11,
            label: "BATTERIES + HEAT SINK",
            src: glowBatteriesHeatSinksImage,
        },
        {
            value: 12,
            label: "BATTERIES + FLIGHT UNIT",
            src: glowBatteriesImage,
            backSrc: glowFlightUnitImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "WEAPON GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPONS",
        "secondaryWeapon": "WEAPON GLOW",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
