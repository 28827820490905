/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useState } from "react";

import { SelectDropdown as MechSelectDropdown } from "./MechCategoryStyles";
import { SelectDropdown as FantasySelectDropdown } from "../../Heroes/components/FantasyHeroCategoryStyles";

import { UserAuthContext } from "./../../../../User/UserAuthContextWrapper";
import { AnalyticsContext } from "./../../../../Analytics/AnalyticsContextWrapper";
import ExportWrapper from "../../components/ExportWrapper";

import { brandList } from "../../../LayerData/MechNPCLayerOrder";

const fileInputRef = createRef();

function validateCamoValue(value, npcCategory, isPremiumUser) {
    if (value === "NONE") {
        return value;
    }

    const optionsLength = brandList[npcCategory][0].camo.length;

    if (value > optionsLength - 1) {
        return optionsLength - 1;
    }

    if (brandList[npcCategory][0].camo[value].isPremium && !isPremiumUser) {
        return brandList[npcCategory][0].camo.filter((option) => !option.isPremium).length - 1;
    }

    return value;
}

function ImportNPCCamoSettingsButton({ displayTheme, applyCamoSettingsFunc, npcCategory, isFantasyTheme }) {
    const { user } = useContext(UserAuthContext);
    const ReactGA = useContext(AnalyticsContext);

    const SelectDropdown = isFantasyTheme ? FantasySelectDropdown : MechSelectDropdown;

    const [isLoading, setIsLoading] = useState(false);
    const isPremiumUser = user && user.isPremiumUser;

    const loadingText = isFantasyTheme ? "Loading" : "LOADING";
    const buttonText = isFantasyTheme ? "Import Settings File" : "IMPORT CAMO SETTINGS FILE";

    function importNPCCamoSettings(selectedFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
            try {
                const parsedJSON = JSON.parse(event.target.result);

                const importCamoSettings = {
                    "camo1Value": validateCamoValue(parsedJSON.camo1Value, npcCategory, isPremiumUser),
                    "camo2Value": validateCamoValue(parsedJSON.camo2Value, npcCategory, isPremiumUser),
                    "camo3Value": validateCamoValue(parsedJSON.camo3Value, npcCategory, isPremiumUser),
                    "camo4Value": validateCamoValue(parsedJSON.camo4Value, npcCategory, isPremiumUser),
                    "camo1TintValue": parsedJSON.camo1TintValue ?? "none",
                    "camo2TintValue": parsedJSON.camo2TintValue ?? "none",
                    "camo3TintValue": parsedJSON.camo3TintValue ?? "none",
                    "camo4TintValue": parsedJSON.camo4TintValue ?? "none",
                    "chassisTintValue": parsedJSON.chassisTintValue ?? "none",
                    "weaponTintValue": parsedJSON.weaponTintValue ?? "none",
                    "secondaryWeaponTintValue": parsedJSON.secondaryWeaponTintValue ?? "none",
                };

                applyCamoSettingsFunc(importCamoSettings);

                ReactGA.event({
                    category: "Print",
                    action: "NPC Camo Import",
                    label: npcCategory,
                });
            } catch(error) {
                window.alert("Incorrect file or formatting. Please select a valid JSON file.");
            }
            setIsLoading(false);
        };

        reader.readAsText(selectedFile);
    }

    return (
        <ExportWrapper>
            <SelectDropdown
                className={displayTheme}
                onClick={(e) => {
                    fileInputRef.current.click();
                }}
            >
                <label className={"clickable"}>
                    {isLoading ? loadingText : buttonText}
                </label>
                <input
                    ref={fileInputRef}
                    type="file"
                    accept="application/JSON"
                    onChange={(e) => {
                        importNPCCamoSettings(e.target.files[0])
                    }}
                />
            </SelectDropdown>
        </ExportWrapper>
    );
}

export default ImportNPCCamoSettingsButton;
