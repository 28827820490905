import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

// BODY
const bodyGMSImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - GMS.png`;
const bodyHAImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - HA.png`;
const bodyHorusImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - Horus.png`;
const bodyIPSNImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - IPS-N.png`;
const bodySSCImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - SSC.png`;
const bodyRepairLegsImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - Repairbot Legs.png`;
const bodyWheelsImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - Wheels.png`;
const bodyWalkaboutImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - Walkabout.png`;
const bodyHoverImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - Hover.png`;
const bodyHoverManipulatorsImage = `${BaseURL}/Mechs/Misc/Turrets/base/Base - Hover - Manipulators.png`;
const sentryDroneBaseImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Base.png`;

// HEADS
const turretGMSImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - GMS.png`;
const turretHAImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - HA.png`;
const turretHorusImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Horus.png`;
const turretIPSNImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - IPS-N.png`;
const turretSSCImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - SSC.png`;
const turretCommArrayImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Comm Array.png`;
const turretTechArrayImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Tech Array.png`;
const turretHexChargeImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Hex Charge.png`;
const turretMissilesImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Missiles.png`;
const turretBuddyImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Buddy.png`;
const turretAmmoImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Ammo Dispenser.png`;
const turretRepairbotTorchesImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Repairbot Torches.png`;
const turretRepairbotClampsImage = `${BaseURL}/Mechs/Misc/Turrets/turret/Turret - Repairbot Clamps.png`;
const sentryDroneTurretImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret.png`;
const sentryDroneTurret2Image = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret 2.png`;

// GLOW
const glowGMSImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - GMS.png`;
const glowHAImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - HA.png`;
const glowHorusImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Horus.png`;
const glowIPSNImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - IPS-N.png`;
const glowSSCImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - SSC.png`;
const glowCommArrayImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Comm Array.png`;
const glowTechArrayImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Tech Array.png`;
const glowHexChargeImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Hex Charge.png`;
const glowMissilesImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Missiles.png`;
const glowBuddyImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Buddy.png`;
const glowAmmoImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Ammo.png`;
const glowRepairbotTorchesImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Repairbot Torches.png`;
const glowRepairbotClampsImage = `${BaseURL}/Mechs/Misc/Turrets/glow/Glow - Repairbot Clamps.png`;
const sentryDroneTurretGlowImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret 1 Glow.png`;
const sentryDroneTurret2GlowImage = `${BaseURL}/Mechs/Horus/Gorgon-Turret/Sentry Drone Turret 2 Glow.png`;

const previewImage = `${BaseURL}/Mechs/Misc/Turrets/preview.png`;

const turretShadow = {
    size: shadowTypes.SMALL,
    bottom: "138px",
    right: "76px",
};

const haShadow = {
    size: shadowTypes.SMALL,
    bottom: "129px",
    right: "79px",
};

const legsShadow = {
    size: shadowTypes.SMALL,
    bottom: "139px",
    right: "80px",
};

const legs2Shadow = {
    size: shadowTypes.SMALL,
    bottom: "117px",
    right: "79px",
};

const hoverShadow = {
    size: shadowTypes.SMALL,
    bottom: "82px",
    right: "54px",
};

export default {
    previewImg: previewImage,
    accessory2IsChassis: true,
    headTemplateSpecial: ["chassisValue", "secondaryWeaponValue", "accessory1Value"],
    secondaryWeaponSpecial: "accessory1Value",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "GMS TURRET",
        },
        {
            value: 1,
            label: "HA TURRET",
        },
        {
            value: 2,
            label: "HORUS TURRET",
        },
        {
            value: 3,
            label: "IPS-N TURRET",
        },
        {
            value: 4,
            label: "SSC TURRET",
        },
        {
            value: 5,
            label: "REPAIRBOT",
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GMS",
            src: bodyGMSImage,
            shadowDetails: turretShadow,
        },
        {
            value: 1,
            label: "HA",
            src: bodyHAImage,
            shadowDetails: haShadow,
        },
        {
            value: 2,
            label: "HORUS",
            src: bodyHorusImage,
            shadowDetails: haShadow,
        },
        {
            value: 3,
            label: "IPS-N",
            src: bodyIPSNImage,
            shadowDetails: turretShadow,
        },
        {
            value: 4,
            label: "SSC",
            src: bodySSCImage,
            shadowDetails: haShadow,
        },
        {
            value: 5,
            label: "REPAIRBOT LEGS",
            src: bodyRepairLegsImage,
            shadowDetails: legsShadow,
        },
        {
            value: 6,
            label: "SENTRY DRONE",
            src: sentryDroneBaseImage,
            shadowDetails: haShadow,
        },
        {
            value: 7,
            label: "WHEELS",
            src: bodyWheelsImage,
            shadowDetails: haShadow,
            isPremium: true,
        },
        {
            value: 8,
            label: "WALKABOUT",
            src: bodyWalkaboutImage,
            shadowDetails: legs2Shadow,
            isPremium: true,
        },
        {
            value: 9,
            label: "HOVER",
            src: bodyHoverImage,
            shadowDetails: hoverShadow,
            isPremium: true,
        },
        {
            value: 10,
            label: "HOVER + MANIPULATORS",
            src: bodyHoverManipulatorsImage,
            shadowDetails: hoverShadow,
            isPremium: true,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "GMS",
            src: turretGMSImage,
        },
        {
            value: 1,
            label: "HA",
            src: turretHAImage,
        },
        {
            value: 2,
            label: "HORUS",
            src: turretHorusImage,
        },
        {
            value: 3,
            label: "IPS-N",
            src: turretIPSNImage,
        },
        {
            value: 4,
            label: "SSC",
            src: turretSSCImage,
        },
        {
            value: 5,
            label: "REPAIRBOT TORCHES",
            src: turretRepairbotTorchesImage,
        },
        {
            value: 6,
            label: "REPAIRBOT CLAMPS",
            src: turretRepairbotClampsImage,
        },
        {
            value: 7,
            label: "AMMO DISPENSER",
            src: turretAmmoImage,
        },
        {
            value: 8,
            label: "SENTRY DRONE TURRET 1",
            frontSrc: sentryDroneTurretImage,
        },
        {
            value: 9,
            label: "SENTRY DRONE TURRET 2",
            frontSrc: sentryDroneTurret2Image,
        },
        {
            value: 10,
            label: "COMM ARRAY",
            src: turretCommArrayImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "TECH ARRAY",
            src: turretTechArrayImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "HEX CHARGE",
            src: turretHexChargeImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "MISSILES",
            src: turretMissilesImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "BUDDY",
            src: turretBuddyImage,
            isPremium: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "GMS",
            frontSrc: glowGMSImage,
        },
        {
            value: 1,
            label: "HA",
            frontSrc: glowHAImage,
        },
        {
            value: 2,
            label: "HORUS",
            frontSrc: glowHorusImage,
        },
        {
            value: 3,
            label: "IPS-N",
            frontSrc: glowIPSNImage,
        },
        {
            value: 4,
            label: "SSC",
            frontSrc: glowSSCImage,
        },
        {
            value: 5,
            label: "REPAIRBOT TORCHES",
            frontSrc: glowRepairbotTorchesImage,
        },
        {
            value: 6,
            label: "REPAIRBOT CLAMPS",
            frontSrc: glowRepairbotClampsImage,
        },
        {
            value: 7,
            label: "AMMO DISPENSER",
            frontSrc: glowAmmoImage,
        },
        {
            value: 8,
            label: "SENTRY DRONE TURRET 1",
            frontSrc: sentryDroneTurretGlowImage,
        },
        {
            value: 9,
            label: "SENTRY DRONE TURRET 2",
            frontSrc: sentryDroneTurret2GlowImage,
        },
        {
            value: 10,
            label: "COMM ARRAY",
            frontSrc: glowCommArrayImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "TECH ARRAY",
            frontSrc: glowTechArrayImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "HEX CHARGE",
            frontSrc: glowHexChargeImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "MISSILES",
            frontSrc: glowMissilesImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "BUDDY",
            frontSrc: glowBuddyImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "TURRET",
            value: "secondaryWeapon",
        },
        {
            label: "TURRET BASE",
            value: "chassis",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "TEMPLATE",
        "chassis": "TURRET BASE",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "TURRET",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
