import tank from "./Heroes-Tank";
import ranger from "./Heroes-Ranger";
import sealer from "./Heroes-Sealer";
import seer from "./Heroes-Seer";
import harvester from "./Heroes-Harvester";
import dragon from "./Heroes-Dragon";

export default [
    Object.assign({ value: 0, label: "Dragon" }, dragon),
    Object.assign({ value: 1, label: "Harvester" }, harvester),
    Object.assign({ value: 2, label: "Ranger" }, ranger),
    Object.assign({ value: 3, label: "Sealer" }, sealer),
    Object.assign({ value: 4, label: "Seer - Mage" }, seer),
    Object.assign({ value: 5, label: "Tank" }, tank),
];
