import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/MIA-Thalia`;

// BODY
const bodyThaliaLanceImage = `${imagePath}/chassis-lance.png`;
const bodyThaliaSwordImage = `${imagePath}/chassis-sword.png`;

// HEADS
const headThaliaImage = `${imagePath}/head/THALIA-Head.png`;

// WEAPONS
// LEFT
const weaponLeftBAPLanceImage = `${imagePath}/accessory/THALIA-BAPLance-front.png`;
const weaponLeftBAPLanceBackImage = `${imagePath}/accessory/THALIA-BAPLance-back.png`;
const weaponLeftSwordImage = `${imagePath}/accessory/THALIA-Sword.png`;

// RIGHT
const weaponRightJESSImage = `${imagePath}/misc/THALIA-JESS.png`;

// ACCESSORY
const thrusterBothImage = `${imagePath}/rear/THALIA-2xVentThrusters.png`;
const thrusterLeftImage = `${imagePath}/rear/THALIA-LVentThrusters.png`;
const thrusterRightImage = `${imagePath}/rear/THALIA-RVentThrusters.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "66px",
    right: "21px",
};

export default {
    previewImg: previewImage,
    isMegadeusPlus: true,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "stanceValue",
    hasMultipleStances: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "THALIA",
            src: headThaliaImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "LANCE",
            src: bodyThaliaLanceImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Full individual partlist available on Discord. Created by",
                title: "Cassette",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 1,
            label: "SWORD",
            src: bodyThaliaSwordImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Full individual partlist available on Discord. Created by",
                title: "Cassette",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "BAP LANCE",
                src: weaponLeftBAPLanceImage,
                backmostSrc: weaponLeftBAPLanceBackImage,
            },
        ],
        "1": [
            {
                value: 0,
                label: "SWORD",
                src: weaponLeftSwordImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "JESS",
            backSrc: weaponRightJESSImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "THRUSTER X2",
            backmostSrc: thrusterBothImage,
        },
        {
            value: 1,
            label: "THRUSTER LEFT",
            backmostSrc: thrusterLeftImage,
        },
        {
            value: 2,
            label: "THRUSTER RIGHT",
            backmostSrc: thrusterRightImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "STANCE",
        "rearMount": "EMPTY",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
