import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";

const imagePath = `${BaseURL}/Mechs/Custom/Notredame`;

// BODY
const bodyNotredameImage = `${imagePath}/chassis-NOTREDAME-mid.png`;
const bodyNotredameNoArmImage = `${imagePath}/chassis-NOTREDAME oops the arm got blown off again V1-mid.png`;

// WEAPONS
// RIGHT
const rightWeaponCannonMountImage = `${imagePath}/weapons/right/weapon-CANNON MOUNT RIGHT-mid.png`;
const rightWeaponRotaryCannonMountImage = `${imagePath}/weapons/right/weapon-CANNON MOUNT ROTARY CANNON RIGHT V1-mid.png`;
const rightWeaponGaussBarrelMountImage = `${imagePath}/weapons/right/weapon-GAUSS BARREL MOUNT RIGHT-mid.png`;
const rightWeaponLaserStackImage = `${imagePath}/weapons/right/weapon-LASER STACK-mid.png`;
const rightWeaponBlankMissileStackImage = `${imagePath}/weapons/right/weapon-BLANK MISSILE MOUNT-mid.png`;
const rightWeaponMissileStackImage = `${imagePath}/weapons/right/weapon-MISSILE MOUNT STACK-mid.png`;
const rightWeaponMissileStackExtraImage = `${imagePath}/weapons/right/weapon-MISSILE MOUNT STACK EXTRA-mid.png`;
const rightWeaponShortMissileStackImage = `${imagePath}/weapons/right/weapon-SHORT MISSILE MOUNT STACK RIGHT-mid.png`;
const rightWeaponShortMissileStackExtraImage = `${imagePath}/weapons/right/weapon-SHORT MISSILE MOUNT STACK RIGHT EXTRA-mid.png`;

// LEFT
const leftWeaponEmptyShoulderImage = `${imagePath}/weapons/left/weapon-BLANK SHOULDER MOUNT LEFT-mid.png`;
const leftWeaponCannonMountImage = `${imagePath}/weapons/left/weapon-CANNON MOUNT LEFT-mid.png`;
const leftWeaponRotaryCannonMountImage = `${imagePath}/weapons/left/weapon-CANNON MOUNT ROTARY CANNON LEFT V1-mid.png`;
const leftWeaponGaussBarrelMountImage = `${imagePath}/weapons/left/weapon-GAUSS BARREL MOUNT LEFT-mid.png`;
const leftWeaponLaserStackImage = `${imagePath}/weapons/left/weapon-LASER STACK LEFT-mid.png`;
const leftWeaponBlankMissileStackImage = `${imagePath}/weapons/left/weapon-BLANK MISSILE MOUNT LEFT-mid.png`;
const leftWeaponMissileStackImage = `${imagePath}/weapons/left/weapon-MISSILE MOUNT STACK LEFT-mid.png`;
const leftWeaponMissileStackExtraImage = `${imagePath}/weapons/left/weapon-MISSILE MOUNT STACK EXTRA LEFT-mid.png`;
const leftWeaponShortMissileStackImage = `${imagePath}/weapons/left/weapon-SHORT MISSILE MOUNT STACK LEFT-mid.png`;
const leftWeaponShortMissileStackExtraImage = `${imagePath}/weapons/left/weapon-SHORT MISSILE MOUNT STACK LEFT EXTRA-mid.png`;

// ACCESSORY
const accessoryBonkingStickImage = `${imagePath}/accessory/BONKING STICK-foremost.png`;
const accessoryTriLaserImage = `${imagePath}/accessory/secondary-weapon-TR LASER COMBO-mid.png`;

// GLOWS
const glowCockpitImage = `${imagePath}/glows/accessory-COCKPITGLASS-tint - 1 -foremost.png`;
const glowTinyLasersImage = `${imagePath}/glows/accessory-TINYLASERS-tint - 1 -foremost.png`;
const glowTinyLasersArmsOffImage = `${imagePath}/glows/accessory-TINYLASERS-tint - 1 -foremost arms off.png`;
const glowArmsOffImage = `${imagePath}/glows/accessory-HARBUN oops the arm got blown off again GLOW-tint - 1 -foremost.png`;
const glowTriLaserImage = `${imagePath}/glows/accessory-TR LASER COMBO LIGHT-tint - 1 -foremost.png`;
const glowCannonLeftImage = `${imagePath}/glows/Muzzle Glow LEFT.png`;
const glowCannonRightImage = `${imagePath}/glows/Muzzle Glow RIGHT.png`;
const glowRotaryCannonLeftImage = `${imagePath}/glows/Muzzle Glow ROTARY CANNON LEFT.png`;
const glowRotaryCannonRightImage = `${imagePath}/glows/Muzzle Glow ROTARY CANNON RIGHT.png`;
const glowLaserStackLeftImage = `${imagePath}/glows/accessory-LASER STACK LIGHTS LEFT-tint - 1 -foremost.png`;
const glowLaserStackRightImage = `${imagePath}/glows/accessory-LASER STACK LIGHTS-tint - 1 -foremost.png`;
const glowGaussBarrelLeftImage = `${imagePath}/glows/Muzzle Glow GUASS LEFT.png`;
const glowGaussBarrelRightImage = `${imagePath}/glows/Muzzle Glow GUASS RIGHT.png`;
const glowMissileLeftImage = `${imagePath}/glows/accessory-MISSILE LEFT LIGHT-tint - 1 -foremost.png`;
const glowMissileRightImage = `${imagePath}/glows/accessory-MISSILE RIGHT LIGHT-tint - 1 -foremost.png`;
const glowMissileExtraLeftImage = `${imagePath}/glows/accessory-MISSILE EXTRA LEFT LIGHT COMBO-tint - 1 -foremost.png`;
const glowMissileExtraRightImage = `${imagePath}/glows/accessory-MISSILE EXTRA RIGHT LIGHT COMBINED-tint - 1 -foremost.png`;
const glowShortMissileLeftImage = `${imagePath}/glows/accessory-SHORT MISSILE LEFT LIGHT-tint - 1 -foremost.png`;
const glowShortMissileRightImage = `${imagePath}/glows/accessory-SHORT MISSILE RIGHT LIGHT-tint - 1 -foremost.png`;
const glowShortMissileExtraLeftImage = `${imagePath}/glows/accessory-SHORT MISSILE LEFT LIGHT EXTRA-tint - 1 -foremost.png`;
const glowShortMissileExtraRightImage = `${imagePath}/glows/accessory-SHORT MISSILE RIGHT LIGHT EXTRA-tint - 1 -foremost.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "19px",
    right: "42px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "6",
        "accessory3Value": "NONE",
        "accessory4Value": "2",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BONKING STICK",
            foremostSrc: accessoryBonkingStickImage,
        },
        {
            value: 1,
            label: "TR LASER",
            src: accessoryTriLaserImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "NOTREDAME",
            src: bodyNotredameImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.FRESH_SLAV,
        },
        {
            value: 1,
            label: "NOTREDAME - OOPS ARMS OFF AGAIN",
            src: bodyNotredameNoArmImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.FRESH_SLAV,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SHOULDER",
                src: leftWeaponEmptyShoulderImage,
            },
            {
                value: 1,
                label: "CANNON",
                src: leftWeaponCannonMountImage,
            },
            {
                value: 2,
                label: "ROTARY CANNON",
                src: leftWeaponRotaryCannonMountImage,
            },
            {
                value: 3,
                label: "GAUSS BARREL",
                src: leftWeaponGaussBarrelMountImage,
            },
            {
                value: 4,
                label: "LASER STACK",
                src: leftWeaponLaserStackImage,
            },
            {
                value: 5,
                label: "BLANK MISSILE STACK",
                src: leftWeaponBlankMissileStackImage,
            },
            {
                value: 6,
                label: "MISSILE STACK",
                src: leftWeaponMissileStackImage,
            },
            {
                value: 7,
                label: "MISSILE STACK - EXTRA",
                src: leftWeaponMissileStackExtraImage,
            },
            {
                value: 8,
                label: "SHORT MISSILE STACK",
                src: leftWeaponShortMissileStackImage,
            },
            {
                value: 9,
                label: "SHORT MISSILE STACK - EXTRA",
                src: leftWeaponShortMissileStackExtraImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CANNON",
            src: rightWeaponCannonMountImage,
        },
        {
            value: 1,
            label: "ROTARY CANNON",
            src: rightWeaponRotaryCannonMountImage,
        },
        {
            value: 2,
            label: "GAUSS BARREL",
            src: rightWeaponGaussBarrelMountImage,
        },
        {
            value: 3,
            label: "LASER STACK",
            src: rightWeaponLaserStackImage,
        },
        {
            value: 4,
            label: "BLANK MISSILE STACK",
            src: rightWeaponBlankMissileStackImage,
        },
        {
            value: 5,
            label: "MISSILE STACK",
            src: rightWeaponMissileStackImage,
        },
        {
            value: 6,
            label: "MISSILE STACK - EXTRA",
            src: rightWeaponMissileStackExtraImage,
        },
        {
            value: 7,
            label: "SHORT MISSILE STACK",
            src: rightWeaponShortMissileStackImage,
        },
        {
            value: 8,
            label: "SHORT MISSILE STACK - EXTRA",
            src: rightWeaponShortMissileStackExtraImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "COCKPIT",
            src: glowCockpitImage,
        },
        {
            value: 1,
            label: "OOPS ARMS OFF AGAIN",
            src: glowArmsOffImage,
        },
        {
            value: 2,
            label: "TINY LASERS",
            src: glowTinyLasersImage,
        },
        {
            value: 3,
            label: "TINY LASERS - ARMS OFF",
            src: glowTinyLasersArmsOffImage,
        },
        {
            value: 4,
            label: "TINY LASERS + ARMS OFF",
            src: glowTinyLasersArmsOffImage,
            frontSrc: glowArmsOffImage,
        },
        {
            value: 5,
            label: "TR LASER",
            frontSrc: glowTriLaserImage,
        },
        {
            value: 6,
            label: "CANNON - LEFT",
            frontSrc: glowCannonLeftImage,
        },
        {
            value: 7,
            label: "CANNON - RIGHT",
            frontSrc: glowCannonRightImage,
        },
        {
            value: 8,
            label: "CANNON - BOTH",
            foremostSrc: glowCannonRightImage,
            frontSrc: glowCannonLeftImage,
        },
        {
            value: 9,
            label: "ROTARY CANNON - LEFT",
            frontSrc: glowRotaryCannonLeftImage,
        },
        {
            value: 10,
            label: "ROTARY CANNON - RIGHT",
            frontSrc: glowRotaryCannonRightImage,
        },
        {
            value: 11,
            label: "ROTARY CANNON - BOTH",
            foremostSrc: glowRotaryCannonRightImage,
            frontSrc: glowRotaryCannonLeftImage,
        },
        {
            value: 12,
            label: "GAUSS - LEFT",
            frontSrc: glowGaussBarrelLeftImage,
        },
        {
            value: 13,
            label: "GAUSS - RIGHT",
            frontSrc: glowGaussBarrelRightImage,
        },
        {
            value: 14,
            label: "GAUSS - BOTH",
            foremostSrc: glowGaussBarrelRightImage,
            frontSrc: glowGaussBarrelLeftImage,
        },
        {
            value: 15,
            label: "LASER STACK - LEFT",
            frontSrc: glowLaserStackLeftImage,
        },
        {
            value: 16,
            label: "LASER STACK - RIGHT",
            frontSrc: glowLaserStackRightImage,
        },
        {
            value: 17,
            label: "LASER STACK - BOTH",
            foremostSrc: glowLaserStackRightImage,
            frontSrc: glowLaserStackLeftImage,
        },
        {
            value: 18,
            label: "MISSILE STACK - LEFT",
            frontSrc: glowMissileLeftImage,
        },
        {
            value: 19,
            label: "MISSILE STACK - RIGHT",
            frontSrc: glowMissileRightImage,
        },
        {
            value: 20,
            label: "MISSILE STACK - BOTH",
            foremostSrc: glowMissileRightImage,
            frontSrc: glowMissileLeftImage,
        },
        {
            value: 21,
            label: "MISSILE STACK - EXTRA - LEFT",
            frontSrc: glowMissileExtraLeftImage,
        },
        {
            value: 22,
            label: "MISSILE STACK - EXTRA - RIGHT",
            frontSrc: glowMissileExtraRightImage,
        },
        {
            value: 23,
            label: "MISSILE STACK - EXTRA - BOTH",
            foremostSrc: glowMissileExtraRightImage,
            frontSrc: glowMissileExtraLeftImage,
        },
        {
            value: 24,
            label: "SHORT MISSILE STACK - LEFT",
            frontSrc: glowShortMissileLeftImage,
        },
        {
            value: 25,
            label: "SHORT MISSILE STACK - RIGHT",
            frontSrc: glowShortMissileRightImage,
        },
        {
            value: 26,
            label: "SHORT MISSILE STACK - BOTH",
            foremostSrc: glowShortMissileRightImage,
            frontSrc: glowShortMissileLeftImage,
        },
        {
            value: 27,
            label: "SHORT MISSILE STACK - EXTRA - LEFT",
            frontSrc: glowShortMissileExtraLeftImage,
        },
        {
            value: 28,
            label: "SHORT MISSILE STACK - EXTRA - RIGHT",
            frontSrc: glowShortMissileExtraRightImage,
        },
        {
            value: 29,
            label: "SHORT MISSILE STACK - EXTRA - BOTH",
            foremostSrc: glowShortMissileExtraRightImage,
            frontSrc: glowShortMissileExtraLeftImage,
        },

    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "ACCESSORY",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
