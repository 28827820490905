import React, { useContext, useState } from "react";
import { array, func, object } from "prop-types";
import styled from "styled-components";

import { AnalyticsContext } from "./../../../Analytics/AnalyticsContextWrapper";
import { UserAuthContext } from "./../../../User/UserAuthContextWrapper";
import { UnitContext } from "./../../UnitContextWrapper";
import { safeName } from "./../../Print/PrintAnalytics";

import Unit from "./../../styles/Unit";
import UnitImage, { SmallUnitImage } from "./../../styles/UnitImage";
import UnitVariantControl from "./../../styles/UnitVariantControl";
import UnitVariantIndicator from "./../../styles/UnitVariantIndicator";
import { UnitDualCenterButton } from "./../../styles/UnitCenterButton";
import ExportFantasyButton from "./../ExportFantasyButton";
import Loader from "../components/Loader";
import {
    SelectDropdown,
} from "./components/MechCategoryStyles";
import { premiumTiersList } from "../PremiumPopup";
import { SPRITE_KEYS } from "../../../common/styles/SpriteImage";
import ContentWarningCover from "../components/ContentWarningCover";

const MechSingleUnit = styled(Unit)`
    max-width: unset;
    flex-direction: column;
`;

MechSingleUnit.displayName = "MechSingleUnit";

const UnitRow = styled.div`
    align-self: stretch;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
`;

UnitRow.displayName = "UnitRow";

function MechSinglesUnitCategoryDisplay({ setShowPremiumPopupFunc, shadowDetails, variantsArray, isFantasy = false }) {
    const [currentVariant, setCurrentVariant] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const ReactGA = useContext(AnalyticsContext);
    const { user } = useContext(UserAuthContext);
    const { addUnit } = useContext(UnitContext);

    const isPremiumUser = user && user.isPremiumUser;
    const isLegendaryUser = user && user.isLegendaryUser;

    const hasVariants = variantsArray.length > 1;

    const isRestricted = !!variantsArray[0].tags?.includes("censored");

    let unitImageSrc = variantsArray[currentVariant].src;

    if (variantsArray[currentVariant].legendary) {
        if (!isLegendaryUser) {
            unitImageSrc = variantsArray[currentVariant].previewSrc;
        }
    } else if (variantsArray[currentVariant].previewSrc) {
        if (!isPremiumUser) {
            unitImageSrc = variantsArray[currentVariant].previewSrc;
        }
    }

    const leftVariantFunction = () => (
        setCurrentVariant(currentVariant === 0 ? variantsArray.length - 1 : currentVariant - 1)
    );

    const rightVariantFunction = () => (
        setCurrentVariant(currentVariant + 1 === variantsArray.length ? 0 : currentVariant + 1)
    );

    const unitSafeLabel = `${variantsArray[currentVariant].filePrefix} ${safeName(variantsArray[currentVariant].unitNumber)} ${variantsArray[currentVariant].variant}`;

    const addUnitFunction = () => {
        ReactGA.event({
            category: "Unit",
            action: "Add Unit",
            label: unitSafeLabel,
        });

        if (variantsArray[currentVariant].legendary) {
            if (isLegendaryUser) {
                return addUnit(Object.assign({ variantsArray, currentVariant, id: `${currentVariant}-${Date.now()}`, shadowDetails }));
            } else {
                return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
            }
        } else if (variantsArray[currentVariant].previewSrc) {
            if (isPremiumUser) {
                return addUnit(Object.assign({ variantsArray, currentVariant, id: `${currentVariant}-${Date.now()}`, shadowDetails }));
            } else {
                return setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE);
            }
        } else {
            return addUnit(Object.assign({
                variantsArray,
                currentVariant,
                id: `${currentVariant}-${Date.now()}`,
                shadowDetails,
                singleMechUnit: true,
            }));
        }
    };

    return (
        <MechSingleUnit
            onContextMenu={(e) => e.preventDefault()}
            isExtraLarge={variantsArray[currentVariant].isExtraLarge}
            isExtraSmall={variantsArray[currentVariant].isExtraSmall}
        >
            <UnitRow>
                <UnitVariantIndicator
                    currentVariant={currentVariant}
                    isPremium={!!variantsArray[currentVariant].previewSrc}
                    isLegendary={variantsArray[currentVariant].legendary}
                    numVariants={variantsArray.length}
                    unitID={`${variantsArray[currentVariant].filePrefix}${variantsArray[currentVariant].unitNumber}-${variantsArray[currentVariant].variant}`}
                />
            </UnitRow>


            <UnitRow>
                <UnitVariantControl
                    alt="Previous Variant"
                    className={SPRITE_KEYS.lArrow}
                    onClick={hasVariants ? leftVariantFunction : null}
                    displayControl={hasVariants}
                />
                {isLoading ? <Loader /> : null}
                {variantsArray[currentVariant].isExtraSmall ?
                    <ContentWarningCover
                        key={unitSafeLabel}
                        isRestricted={isRestricted}
                        height={variantsArray[currentVariant].height * 2}
                        width={variantsArray[currentVariant].width * 2}
                    >
                        <SmallUnitImage
                            src={unitImageSrc}
                            alt={`${variantsArray[currentVariant].filePrefix}${variantsArray[currentVariant].unitNumber}-${variantsArray[currentVariant].variant}`}
                            height={variantsArray[currentVariant].height * 2}
                            width={variantsArray[currentVariant].width * 2}
                            onLoad={() => setIsLoading(false)}
                        />
                    </ContentWarningCover> :
                    <ContentWarningCover
                        key={unitSafeLabel}
                        isRestricted={isRestricted}
                        height={variantsArray[currentVariant].height}
                        width={variantsArray[currentVariant].width}
                    >
                        <UnitImage
                            src={unitImageSrc}
                            alt={`${variantsArray[currentVariant].filePrefix}${variantsArray[currentVariant].unitNumber}-${variantsArray[currentVariant].variant}`}
                            onLoad={() => setIsLoading(false)}
                        />
                    </ContentWarningCover>
                }
                <UnitVariantControl
                    alt="Next Variant"
                    className={SPRITE_KEYS.rArrow}
                    onClick={hasVariants ? rightVariantFunction : null}
                    displayControl={hasVariants}
                />
            </UnitRow>

            <UnitRow>
                <UnitDualCenterButton
                    alt="Add Unit Button"
                    className={SPRITE_KEYS.plusButton}
                    onClick={addUnitFunction}
                    displayControl
                    isFirst
                />
                <ExportFantasyButton
                    unit={variantsArray[currentVariant]}
                    setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                    isFree
                />
            </UnitRow>

            <UnitRow>
                <SelectDropdown
                    className={isFantasy ? "fantasy" : "default"}
                    disabled={false}
                >
                    <label className={"long-text-display"} htmlFor="weapon-select" title={`Added on ${variantsArray[currentVariant].dateAdded}${variantsArray[0]?.tags?.length ? `\nTags: ${variantsArray[0]?.tags?.join(", ")}` : ""}`}>
                        {variantsArray[currentVariant]?.variant?.length >= 100 ? (
                            <marquee>{`${variantsArray[currentVariant].variant} by ${variantsArray[currentVariant].unitNumber}`}</marquee>
                            ) : `${variantsArray[currentVariant].variant} by ${variantsArray[currentVariant].unitNumber}`
                        }
                    </label>
                </SelectDropdown>
            </UnitRow>
        </MechSingleUnit>
    );
}

MechSinglesUnitCategoryDisplay.propTypes = {
    setShowPremiumPopupFunc: func.isRequired,
    shadowDetails: object.isRequired,
    variantsArray: array.isRequired,
};

export default MechSinglesUnitCategoryDisplay;
