import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Horus/Goblin-2`;

// BODY
const bodySpikeyImage = `${imagePath}/Chassis - Spikey.png`;
const bodySmoothImage = `${imagePath}/Chassis - Smooth.png`;

// HEADS
const headGoblinSpikeyImage = `${imagePath}/heads/Head - Goblin Spikey.png`;
const headGoblinSmoothImage = `${imagePath}/heads/Head - Goblin Smooth.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_goblin_2.png`;
// PREMIUM
const headBriarImage = `${imagePath}/heads/premium/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/premium/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/premium/Head - Chimera.png`;
const headHazardImage = `${imagePath}/heads/premium/Head - Hazard.png`;
const headHornedImage = `${imagePath}/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/premium/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head - Malice.png`;
const headMourningCloakImage = `${imagePath}/heads/premium/Head - Mourning Cloak.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/premium/Head - Nelson.png`;
const headNelsonFlightTypeImage = `${imagePath}/heads/premium/Head - Nelson Flight Type.png`;
const headRainImage = `${imagePath}/heads/premium/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/premium/Head - Rain Hair.png`;
const headScopedogImage = `${imagePath}/heads/premium/Head - Scopedog.png`;
const headTokugawaImage = `${imagePath}/heads/premium/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/premium/Head - Vanquisher.png`;

// WEAPONS
const weaponsHandImage = `${imagePath}/weapons/Weapon - Hand.png`;
const weaponsWristHUDImage = `${imagePath}/weapons/Weapon - Wrist HUD.png`;
const weaponsARShortImage = `${imagePath}/weapons/Weapon - AR Short.png`;
const weaponsARLongImage = `${imagePath}/weapons/Weapon - AR Long.png`;
const weaponsHorusARLongImage = `${imagePath}/weapons/Weapon - HORUS AR.png`;
const weaponsAutopodImage = `${imagePath}/weapons/Weapon - Autopod.png`;

// REAR MOUNTS
const rearMountAntennaeImage = `${imagePath}/rear mounts/Rear Mount - Antennae.png`;
const rearMountAutopodHorzImage = `${imagePath}/rear mounts/Rear Mount - Autopod Horz.png`;
const rearMountAutopodVertImage = `${imagePath}/rear mounts/Rear Mount - Autopod Vert.png`;
const rearMountBladeOverImage = `${imagePath}/rear mounts/Rear Mount - Blade Over.png`;
const rearMountBladeImage = `${imagePath}/rear mounts/Rear Mount - Blade.png`;
const rearMountFlightArrayImage = `${imagePath}/rear mounts/Rear Mount - Flight Array.png`;
const rearMountInvestitureOverImage = `${imagePath}/rear mounts/Rear Mount - Investiture Over.png`;
const rearMountInvestitureImage = `${imagePath}/rear mounts/Rear Mount - Investiture.png`;
const rearMountShoulderOverImage = `${imagePath}/rear mounts/Rear Mount - Shoulders Over.png`;
const rearMountUshabtiImage = `${imagePath}/rear mounts/Rear Mount - Ushabti.png`;

// GLOWS
const glowAntennaeImage = `${imagePath}/glows/Glow 1 - Antennae.png`;
const glowWristHUDImage = `${imagePath}/glows/Glow 2 - Wrist HUD.png`;
const glowHorusARImage = `${imagePath}/glows/Glow 2 - HORUS AR.png`;
const glowUshabtiImage = `${imagePath}/glows/Glow 1 - Ushabti.png`;
const glowInvestitureImage = `${imagePath}/glows/Glow 2 - Investiture.png`;
const glowSmoothImage = `${imagePath}/glows/Glow 1 - Smooth no head.png`;
const glowSmoothHeadImage = `${imagePath}/glows/Glow 1 - Head Smooth.png`;
const glowSpikeyImage = `${imagePath}/glows/Glow 1 - Spikes no head.png`;
const glowSpikeyHeadImage = `${imagePath}/glows/Glow 1 - Head Spikey.png`;
const glowGroundHackImage = `${imagePath}/glows/Glow 2 - Ground Hack.png`;
const glowSpikesEtherHackImage = `${imagePath}/glows/Glow 1 - Spikes no head Ether Hack.png`;
const glowSpikesHeadEtherHackImage = `${imagePath}/glows/Glow 1 - Head Spikey Ether Hack.png`;

const coreImage = `${imagePath}/Goblin (by Hit-Monkey).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "87px",
    right: "65px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Hit-Monkey",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "8",
        "accessory2Value": "5",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SPIKEY",
            foremostSrc: headGoblinSpikeyImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "BONEHEAD",
            foremostSrc: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "SMOOTH",
            foremostSrc: headGoblinSmoothImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "BRIAR",
            foremostSrc: headBriarImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HAZARD",
            foremostSrc: headHazardImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HORNED",
            foremostSrc: headHornedImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MAG",
            foremostSrc: headMagImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "MOURNING CLOAK",
            foremostSrc: headMourningCloakImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "NELSON",
            foremostSrc: headNelsonImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "NELSON FLIGHT TYPE",
            foremostSrc: headNelsonFlightTypeImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "RAIN",
            foremostSrc: headRainImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "SCOPEDOG",
            foremostSrc: headScopedogImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "TOKUGAWA",
            foremostSrc: headTokugawaImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SPIKEY",
            frontSrc: bodySpikeyImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "SMOOTH",
            frontSrc: bodySmoothImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                foremostSrc: weaponsHandImage,
            },
            {
                value: 1,
                label: "WRIST HUD",
                foremostSrc: weaponsWristHUDImage,
            },
            {
                value: 2,
                label: "AR SHORT",
                foremostSrc: weaponsARShortImage,
            },
            {
                value: 3,
                label: "AR LONG",
                foremostSrc: weaponsARLongImage,
            },
            {
                value: 4,
                label: "HORUS AR",
                foremostSrc: weaponsHorusARLongImage,
            },
            {
                value: 5,
                label: "AUTOPOD",
                foremostSrc: weaponsAutopodImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ANTENNAE",
            src: rearMountShoulderOverImage,
            backSrc: rearMountAntennaeImage,
        },
        {
            value: 1,
            label: "AUTOPOD - HORIZONTAL",
            src: rearMountShoulderOverImage,
            backSrc: rearMountAutopodHorzImage,
        },
        {
            value: 2,
            label: "AUTOPOD - VERTICAL",
            src: rearMountShoulderOverImage,
            backSrc: rearMountAutopodVertImage,
        },
        {
            value: 3,
            label: "BLADE",
            src: rearMountBladeOverImage,
            backSrc: rearMountBladeImage,
        },
        {
            value: 4,
            label: "FLIGHT ARRAY",
            src: rearMountShoulderOverImage,
            backSrc: rearMountFlightArrayImage,
        },
        {
            value: 5,
            label: "INVESTITURE",
            src: rearMountInvestitureOverImage,
            backSrc: rearMountInvestitureImage,
        },
        {
            value: 6,
            label: "USHABTI",
            src: rearMountShoulderOverImage,
            backSrc: rearMountUshabtiImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ANTENNAE",
            frontSrc: glowAntennaeImage,
        },
        {
            value: 1,
            label: "WRIST HUD",
            foremostSrc: glowWristHUDImage,
        },
        {
            value: 2,
            label: "HORUS AR",
            foremostSrc: glowHorusARImage,
        },
        {
            value: 3,
            label: "USHABTI",
            frontSrc: glowUshabtiImage,
        },
        {
            value: 4,
            label: "INVESTITURE",
            frontSrc: glowInvestitureImage,
        },
        {
            value: 5,
            label: "GROUND HACK",
            frontSrc: glowGroundHackImage,
        },
        {
            value: 6,
            label: "SPIKEY CHASSIS",
            frontSrc: glowSpikeyImage,
        },
        {
            value: 7,
            label: "SPIKEY HEAD",
            foremostSrc: glowSpikeyHeadImage,
        },
        {
            value: 8,
            label: "SPIKEY ALL",
            foremostSrc: glowSpikeyHeadImage,
            frontSrc: glowSpikeyImage,
        },
        {
            value: 9,
            label: "ETHER HACK SPIKEY CHASSIS",
            frontSrc: glowSpikesEtherHackImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "ETHER HACK SPIKEY HEAD",
            foremostSrc: glowSpikesHeadEtherHackImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "ETHER HACK SPIKEY ALL",
            foremostSrc: glowSpikesHeadEtherHackImage,
            frontSrc: glowSpikesEtherHackImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "SMOOTH CHASSIS",
            frontSrc: glowSmoothImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "SMOOTH HEAD",
            foremostSrc: glowSmoothHeadImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "SMOOTH ALL",
            foremostSrc: glowSmoothHeadImage,
            frontSrc: glowSmoothImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "REAR MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "REAR MOUNT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
