/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useEffect, useState } from "react";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

import {
    InterfaceButton,
} from "./MechCategoryStyles";
import {
    CamoSettingsDisplay,
    CamoSettingOption,
} from "../MechNPCCategory";
import { drawImages, drawZipImages, } from "./../../ExportImages";
import ColorList, { OpacityList } from "./../../../../common/ColorList";
import ExportWrapper from "../../components/ExportWrapper";

import { UserAuthContext } from "../../../../User/UserAuthContextWrapper";
import { AnalyticsContext } from "../../../../Analytics/AnalyticsContextWrapper";
import { NPCMechPrintAnalytics } from "../../../Print/PrintAnalytics";
import { UnitContext } from "./../../../UnitContextWrapper";

import { brandList, npcLayerOrder } from "../../../LayerData/MechNPCLayerOrder";
import { createExportLayerOptionsWithDefaults } from "../../../LayerData/CreateLayerFunctions";
import { premiumTiersList } from "../../PremiumPopup";
import { getSizeValue } from "../../../styles/LayeredUnitImage";

const canvasRef = createRef();

function ExportAllNPCButton({ displayTheme, mechType, setShowPremiumPopupFunc, isLegendaryCategory = false }) {
    const { user } = useContext(UserAuthContext);
    const ReactGA = useContext(AnalyticsContext);
    const { unitBackgroundColor, unitBackgroundOpacity } = useContext(UnitContext);
    const [camoSettingsIndex, setCamoSettingsIndex] = useState(0);
    const [savedCamoSettings, setSavedCamoSettings] = useState(Array(5).fill({
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none",
        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none"
    }));
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const camoSettingsCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`${mechType}CamoSettings=`));

        if (camoSettingsCookie.length) {
            if (JSON.parse(camoSettingsCookie[0].split("=")[1]).length !== 5) {
                setSavedCamoSettings(JSON.parse(camoSettingsCookie[0].split("=")[1]).concat([
                    {
                        "camo1Value": "NONE",
                        "camo2Value": "NONE",
                        "camo3Value": "NONE",
                        "camo4Value": "NONE",
                        "camo1TintValue": "none",
                        "camo2TintValue": "none",
                        "camo3TintValue": "none",
                        "camo4TintValue": "none",
                        "chassisTintValue": "none",
                        "weaponTintValue": "none",
                        "secondaryWeaponTintValue": "none"
                    }
                ]));
            } else {
                setSavedCamoSettings(JSON.parse(camoSettingsCookie[0].split("=")[1]));
            }
        }
    }, [mechType]);

    const isPremiumUser = user && user.isPremiumUser;
    const isLegendaryUser = user && user.isLegendaryUser;

    async function printNPCMechParts(unit, zip, defaultExportFlag) {
        return new Promise(async (resolve, reject) => {
            let imageSize = getSizeValue(unit);

            let unitDefaults;

            if (defaultExportFlag) {
                unitDefaults = Object.assign({}, JSON.parse(unit.defaultValues));
            } else {
                unitDefaults = Object.assign({}, JSON.parse(unit.defaultValues), savedCamoSettings[camoSettingsIndex]);
            }

            let imageLayersArray = createExportLayerOptionsWithDefaults({unit, unitDefaults, layerOrder: npcLayerOrder});

            const zipFolder = zip.folder(`${mechType}-Parts/${mechType}-${unit.label}`);

            const canvas = canvasRef.current;
            let ctx = canvas.getContext('2d');

            await drawZipImages({
                ctxObject: ctx,
                canvas,
                zipObject: zipFolder,
                images: imageLayersArray,
                imageSize,
            });

            setTimeout(async () => {
                ctx.clearRect(0, 0, imageSize, imageSize);

                resolve();
            }, 0);
        });
    }

    async function printNPCMech(unit, images, defaultExportFlag) {
        return new Promise(async (resolve, reject) => {
            let imageSize = getSizeValue(unit);

            let unitDefaults;

            if (defaultExportFlag) {
                unitDefaults = Object.assign({}, JSON.parse(unit.defaultValues));
            } else {
                unitDefaults = Object.assign({}, JSON.parse(unit.defaultValues), savedCamoSettings[camoSettingsIndex]);
            }

            let imageLayersArray = createExportLayerOptionsWithDefaults({unit, unitDefaults, layerOrder: npcLayerOrder});

            canvasRef.current.height = imageSize;
            canvasRef.current.width = imageSize;

            const canvas = canvasRef.current;

            var ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, imageSize, imageSize);

            let compositeImage;

            ctx.fillStyle = `${ColorList[unitBackgroundColor].hex}${OpacityList[unitBackgroundOpacity].opacity}`;
            ctx.fillRect(0, 0, imageSize, imageSize);

            await drawImages(ctx, imageLayersArray);

            compositeImage = await canvas.toDataURL("image/png");

            images.file(`${mechType}-${unit.label}.png`, compositeImage.substr(compositeImage.indexOf(',') + 1), { base64: true });

            NPCMechPrintAnalytics(Object.assign({}, unitDefaults, { brandValue: mechType, frameValue: unit.value }), ReactGA);

            setTimeout(async () => {
                ctx.clearRect(0, 0, imageSize, imageSize);

                resolve();
            }, 0);
        });
    }

    async function printAllNPCMech(unitList, defaultExportFlag) {
        let zip = new JSZip();

        const images = zip.folder(`${mechType}`);

        let unitListPromiseArray = [].concat(
            unitList.map((unit) => () => printNPCMech(unit, images, defaultExportFlag)),
            unitList.filter((unit) => !!unit.defaultInactiveValues)
                .map((unit) => () => printNPCMech(
                    Object.assign(
                        {},
                        unit,
                        {
                            defaultValues: unit.defaultInactiveValues,
                            label: `${unit.label}-Inactive`
                        }
                    ), images, defaultExportFlag)
                )
        );

        function executeSequentially(promiseFactories) {
            var result = Promise.resolve();

            promiseFactories.forEach((promiseFactory) => {
                result = result.then(promiseFactory);
            });

            return result;
        }

        await executeSequentially(unitListPromiseArray);

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${mechType}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export All NPC",
            label: `${mechType}`,
        });

        setIsLoading(false);
    }

    async function printAllNPCMechParts(unitList, defaultExportFlag) {
        let zip = new JSZip();

        let unitListPromiseArray = unitList.map((unit) => () => printNPCMechParts(unit, zip, defaultExportFlag));

        function executeSequentially(promiseFactories) {
            var result = Promise.resolve();

            promiseFactories.forEach((promiseFactory) => {
                result = result.then(promiseFactory);
            });

            return result;
        }

        await executeSequentially(unitListPromiseArray);

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${mechType}-Parts.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export All NPC Parts",
            label: `${mechType}`,
        });

        setIsLoading(false);
    }

    const camoSettingsOptions = savedCamoSettings.map((camoSetting, index) => (
        <CamoSettingOption
            key={`camo-setting-option-${index}`}
            value={index}
            className={`${index === camoSettingsIndex ? "selected " : ""} ${displayTheme ? "retro-terminal" : "default"}`}
            onClick={() => setCamoSettingsIndex(index)}
        />
    ));

    return (
        <ExportWrapper>
            <InterfaceButton
                className={displayTheme ? "retro-terminal" : "default"}
                onClick={isLoading ? () => {} : () => {
                    if (isLegendaryCategory && !isLegendaryUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                    } else if (!isPremiumUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE);
                    } else {
                        setIsLoading(true);
                        return printAllNPCMech(brandList[mechType], false);
                    }
                }}
            >
                {isLoading ? "LOADING" : `EXPORT All ●${isLegendaryCategory ? '●' : ''}`}
            </InterfaceButton>
            <InterfaceButton
                className={displayTheme ? "retro-terminal" : "default"}
                onClick={isLoading ? () => {} : () => {
                    if (!isLegendaryUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                    } else {
                        setIsLoading(true);
                        return printAllNPCMechParts(brandList[mechType], false);
                    }
                }}
            >
                {isLoading ? "LOADING" : "AS PARTS ●●"}
            </InterfaceButton>

            <InterfaceButton
                className={`${displayTheme ? "retro-terminal" : "default"} no-active`}
            >
                W/ SAVED CAMO SETTINGS
                <CamoSettingsDisplay
                    className={displayTheme ? "retro-terminal" : "default"}
                >
                    {camoSettingsOptions}
                </CamoSettingsDisplay>
            </InterfaceButton>

            <InterfaceButton
                className={displayTheme ? "retro-terminal" : "default"}
                onClick={isLoading ? () => {} : () => {
                    if (isLegendaryCategory && !isLegendaryUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                    } else if (!isPremiumUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE);
                    } else {
                        setIsLoading(true);
                        return printAllNPCMech(brandList[mechType], true);
                    }
                }}
            >
                {isLoading ? "LOADING" : `W/ DEFAULT ●${isLegendaryCategory ? '●' : ''}`}
            </InterfaceButton>

            <InterfaceButton
                className={displayTheme ? "retro-terminal" : "default"}
                onClick={isLoading ? () => {} : () => {
                    if (!isLegendaryUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                    } else {
                        setIsLoading(true);
                        return printAllNPCMechParts(brandList[mechType], true);
                    }
                }}
            >
                {isLoading ? "LOADING" : "AS PARTS ●●"}
            </InterfaceButton>
            <canvas ref={canvasRef} />
        </ExportWrapper>
    );
}

export default ExportAllNPCButton;
