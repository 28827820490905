import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyDaelusImage = `${BaseURL}/Mechs/Custom/Daelus/Chassis.png`;

// HEADS
const headDaelusImage = `${BaseURL}/Mechs/Custom/Daelus/heads/Head - Daelus.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Daelus/heads/Head - Kazu.png`;
// PREMIUM
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Cyclops.png`;
const headFactoryStandardImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Factory Standard.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Horned Hair.png`;
const headJudicatorImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Judicator.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Malice.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Nelson.png`;
const headPredatorImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Predator.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Rain Transformed Hair.png`;
const headScarabImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Scarab.png`;
const headTalonImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Talon.png`;
const headVanguardImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Vanguard.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Daelus/heads/premium/Head - Visionary.png`;

// SHOULDER
const shoulderScabbardImage = `${BaseURL}/Mechs/Custom/Daelus/Left Shoulder - Scabbard.png`;
const shoulderCloakImage = `${BaseURL}/Mechs/Custom/Daelus/Left Shoulder - Cloak.png`;

// WEAPONS
// LEFT
const leftWeaponLongswordImage = `${BaseURL}/Mechs/Custom/Daelus/weapons/Left Weapon - Longsword.png`;
const leftWeaponRifleImage = `${BaseURL}/Mechs/Custom/Daelus/weapons/Left Weapon - Rifle.png`;

// RIGHT
const rightWeaponLongswordImage = `${BaseURL}/Mechs/Custom/Daelus/weapons/Right Weapon - Longsword.png`;
const rightWeaponRifleImage = `${BaseURL}/Mechs/Custom/Daelus/weapons/Right Weapon - Rifle.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Daelus/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-50px",
    right: "-103px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DAELUS",
            src: headDaelusImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "PREDATOR",
            src: headPredatorImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "SCARAB",
            src: headScarabImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "TALON",
            src: headTalonImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "VANGUARD",
            src: headVanguardImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "VISIONARY",
            src: headVisionaryImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DAELUS",
            src: bodyDaelusImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Mykell Pledger",
                url: "https://twitter.com/mykedsgn",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "LONGSWORD",
                src: leftWeaponLongswordImage,
            },
            {
                value: 1,
                label: "RIFLE",
                src: leftWeaponRifleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "LONGSWORD",
            frontSrc: rightWeaponLongswordImage,
        },
        {
            value: 1,
            label: "RIFLE",
            src: rightWeaponRifleImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SCABBARD",
            frontSrc: shoulderScabbardImage,
        },
        {
            value: 1,
            label: "CLOAK",
            frontSrc: shoulderCloakImage,
            isPremium: true,
        },],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "SHOULDER",
            value: "rearMount",
        },
        {
            label: "LEFT HAND",
            value: "weapon",
        },
        {
            label: "RIGHT HAND",
            value: "secondaryWeapon",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "SHOULDER",
        "weapon": "LEFT HAND",
        "secondaryWeapon": "RIGHT HAND",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
