import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const unitPath = `${BaseURL}/Mechs/NPC/Corpro/Operator`;

// BODY
const bodyOperatorImage = `${unitPath}/Operator.png`;

// MAIN WEAPON
const plasmaRifle1Image = `${unitPath}/weapons/Weapon - Plasma Rifle 1.png`;
const plasmaRifle2Image = `${unitPath}/weapons/Weapon - Plasma Rifle 2.png`;

// GLOW
const portalGlowImage = `${unitPath}/glows/Glow - Portal.png`;
const operatorPR1GlowImage = `${unitPath}/glows/Glow - Operator Plasma Rifle 1.png`;
const operatorPR2GlowImage = `${unitPath}/glows/Glow - Operator Plasma Rifle 2.png`;
const operatorPR1PortalGlowImage = `${unitPath}/glows/Glow - Portal Operator Plasma Rifle 1.png`;
const operatorPR2PortalGlowImage = `${unitPath}/glows/Glow - Portal Operator Plasma Rifle 2.png`;
const eyeGlowImage = `${unitPath}/glows/Operator Eye Glow.png`;
const eyeOperatorPR1GlowImage = `${unitPath}/glows/Operator 1 Eye Glow.png`;
const eyeOperatorPR2GlowImage = `${unitPath}/glows/Operator 2 Eye Glow.png`;
const eyeOperatorPR1PortalGlowImage = `${unitPath}/glows/Operator 1 Eye Glow Portal.png`;
const eyeOperatorPR2PortalGlowImage = `${unitPath}/glows/Operator 2 Eye Glow Portal.png`;
const simCamoImage = `${unitPath}/camo/Camo - Sim.png`;

// CAMO
const corproCamo1Image = `${unitPath}/camo/Camo - Corpro 1.png`;
const corproCamo2Image = `${unitPath}/camo/Camo - Corpro 2.png`;
const forestCamo1Image = `${unitPath}/camo/Camo - Forest 1.png`;
const forestCamo2Image = `${unitPath}/camo/Camo - Forest 2.png`;
const urbanCamo1Image = `${unitPath}/camo/Camo - Urban 1.png`;
const urbanCamo2Image = `${unitPath}/camo/Camo - Urban 2.png`;
const headCamoImage = `${unitPath}/camo/Camo - Head.png`;
const tigerCamoImage = `${unitPath}/camo/Camo - Tiger.png`;
const halftoneVertCamoImage = `${unitPath}/camo/Camo - Halftone Vert.png`;
const halftoneDiagCamoImage = `${unitPath}/camo/Camo - Halftone Diag.png`;
const survivorCamoImage = `${unitPath}/camo/Camo - Survivor.png`;

const previewImage = `${unitPath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "83px",
    right: "5px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "6",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    defaultInactiveValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "OPERATOR",
            src: bodyOperatorImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: [
        {
            value: 0,
            label: "PLASMA RIFLE 1",
            src: plasmaRifle1Image,
        },
        {
            value: 1,
            label: "PLASMA RIFLE 2",
            src: plasmaRifle2Image,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "OPERATOR + PLASMA RIFLE 1",
            src: operatorPR1GlowImage,
        },
        {
            value: 1,
            label: "OPERATOR + PLASMA RIFLE 2",
            src: operatorPR2GlowImage,
        },
        {
            value: 2,
            label: "PORTAL",
            src: portalGlowImage,
        },
        {
            value: 3,
            label: "PORTAL + OPERATOR + PLASMA RIFLE 1",
            src: operatorPR1PortalGlowImage,
        },
        {
            value: 4,
            label: "PORTAL + OPERATOR + PLASMA RIFLE 2",
            src: operatorPR2PortalGlowImage,
        },
        {
            value: 5,
            label: "EYE",
            src: eyeGlowImage,
        },
        {
            value: 6,
            label: "EYE + OPERATOR + PLASMA RIFLE 1",
            src: eyeOperatorPR1GlowImage,
        },
        {
            value: 7,
            label: "EYE + OPERATOR + PLASMA RIFLE 2",
            src: eyeOperatorPR2GlowImage,
        },
        {
            value: 8,
            label: "EYE + PORTAL + OPERATOR + PLASMA RIFLE 1",
            src: eyeOperatorPR1PortalGlowImage,
        },
        {
            value: 9,
            label: "EYE + PORTAL + OPERATOR + PLASMA RIFLE 2",
            src: eyeOperatorPR2PortalGlowImage,
        },
        {
            value: 10,
            label: "SIM",
            src: simCamoImage,
            originalArtCredit: contributorList.CATOFMANYFACES,
            isPlusTwo: true,
        },
    ],
    camo: [
        {
            value: 0,
            label: "HEAD",
            src: headCamoImage,
        },
        {
            value: 1,
            label: "CORPRO 1",
            src: corproCamo1Image,
        },
        {
            value: 2,
            label: "CORPRO 2",
            src: corproCamo2Image,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
        },
        {
            value: 5,
            label: "URBAN 1",
            src: urbanCamo1Image,
            isPremium: true,
        },
        {
            value: 6,
            label: "URBAN 2",
            src: urbanCamo2Image,
            isPremium: true,
        },
        {
            value: 7,
            label: "TIGER",
            src: tigerCamoImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HALFTONE VERT",
            src: halftoneVertCamoImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HALFTONE DIAG",
            src: halftoneDiagCamoImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SURVIVOR",
            src: survivorCamoImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
