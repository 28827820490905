import styled from "styled-components";

import {
    backgroundColor,
    retroTerminalBackgroundColor,
    retroTerminalColor,
} from "./../../Mechs/components/MechCategoryStyles";

export const defaultColor = "#072547";

export const SelectDropdown = styled.div`
    margin-top: 5px;
    width: 190px;
    font-family: arial;
    position: relative;

    label {
        position: relative;
        display: flex;
        gap: 5px;
        font-weight: bolder;
        text-align: left;
        padding: 2px 15px;
        max-width: 100%;
        text-transform: uppercase;
        font-size: 0.9em;
        justify-content: space-between;
        align-items: center;

        ::first-letter {
            font-size: 1.2em;
        }

        span {
            margin-right: auto;
        }
    }

    select, option, .range-wrapper {
        font-family: arial;
        text-transform: uppercase;
        width: 190px;
        display: block;
        padding: 7px 10px;
        height: auto;
        background-color: transparent;
        font-size: 0.9em;
        font-weight: bolder;
        appearance: none;
        text-align: center;
        text-align-last: center;

        :hover {
            cursor: pointer;
        }

        :focus {
            outline: none;
        }
    }


    &.default {
        :-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 ${defaultColor};
        }
        :-moz-focusring * {
            color: ${defaultColor};
            text-shadow: none;
        }

        label {
            background: ${props => props.disabled ? "#333" : defaultColor};
            color: ${backgroundColor};

            border-color: ${props => props.theme.categoryPrimaryColor};
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            border-width: 2px 2px 0px 2px;

            :nth-of-type(2) {
                padding-top: 10px;
                padding-bottom: 5px;
                border-top-right-radius: 0px;
                border-top-left-radius: 0px;
            }
        }

        select, option, .range-wrapper {
            font-family: arial;
            text-transform: uppercase;
            color: ${defaultColor};
            border-color: ${defaultColor};
            border-style: solid;
            border-width: 2px;
            border-top-width: 0px;

            :disabled {
                border-color: #333;
                color: #333;
            }
        }

        input {
            color: ${defaultColor};
        }

        option {
            background-color: ${backgroundColor};
        }
    }

    &.retro-terminal {
        :-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 ${retroTerminalColor};
        }
        :-moz-focusring * {
            color: ${retroTerminalColor};
            text-shadow: none;
        }

        label {
            background: transparent;
            color: ${props => props.disabled ? "#666" : retroTerminalColor};
        }

        select, option, .range-wrapper {
            color: ${retroTerminalColor};
            border-color: ${retroTerminalColor};
            border-style: dashed solid;
            border-width: 2px;

            :disabled {
                border-color: #666;
                color: #666;
            }
        }

        input {
            color: ${retroTerminalColor};
        }

        option {
            background-color: ${retroTerminalBackgroundColor};
        }
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        margin-top: 15px;
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 150px;

        select, option, .range-wrapper {
            width: 150px;
        }

        select {
            font-size: 0.7em;
        }

        label {
            font-size: 0.9em;
        }
    }
`;

SelectDropdown.displayName = "SelectDropdown";

export const InterfaceButton = styled.div`
    width: 150px;
    display: block;
    font-family: arial;
    padding: 7px 20px 7px 13px;
    font-size: 1.05em;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    position: relative;
    text-transform: uppercase;

    border-width: 2px;
    border-style: outset;
    border-radius: 10px;
    border-color: ${props => props.theme.categoryPrimaryColor};

    ::first-letter {
        font-size: 1.2em;
    }

    &.default {
        :active {
            border-style: inset;
            transform: translateY(2px);
        }

        :hover {
            cursor: pointer;
        }

        color: ${backgroundColor};
        background-color: ${defaultColor};
    }

    &.retro-terminal {
        border-color: ${retroTerminalColor};
        border-style: solid;
        border-width: 2px;
        color: ${retroTerminalColor};

        :hover {
            color: ${retroTerminalBackgroundColor};
            background-color: ${retroTerminalColor};
        }
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 110px;
        margin-top: 20px;
    }
`;

InterfaceButton.displayName = "InterfaceButton";

export const InterfaceThirdButton = styled(InterfaceButton)`
    width: 80px;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 75px;
    }
`;

InterfaceThirdButton.displayName = "InterfaceThirdButton";


export const InterfacePlaceholder = styled.div`
    width: 150px;
    display: block;
    font-family: arial;
    padding: 7px 20px 7px 13px;
    font-size: 1.05em;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    position: relative;
    border-width: 2px;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 110px;
        margin-top: 20px;
    }
`;

InterfacePlaceholder.displayName = "InterfacePlaceholder";

export const FantasyHeroForm = styled.form`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;

    .display-controls {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        flex-direction: row;
    }

    .options-selectors {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: space-around;
        flex-direction: row;
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        .options-selectors, .display-controls {
            width: 100%;
        }
    }
`;

FantasyHeroForm.displayName = "FantasyHeroForm";

export const CustomMechForm = styled(FantasyHeroForm)`
    .options-selectors {
        height: 485px;
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        .options-selectors, .display-controls {
            height: unset;
        }
    }
`;

CustomMechForm.displayName = "CustomMechForm";

export const InterfaceControls = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;

    :first-child {
        align-self: flex-start;
    }
`;

InterfaceControls.displayName = "InterfaceControls";

export const IndexSelectors = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    flex-direction: column;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        flex-direction: row;

        div {
            margin: 0 10px;
        }
    }
`;

IndexSelectors.displayName = "IndexSelectors";

export const IndexSelector = styled.div`
    width: 20px;
    display: block;
    font-family: arial;
    padding: 5px;
    font-size: 1.05em;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    border-width: 2px;
    text-transform: uppercase;

    ::first-letter {
        font-size: 1.2em;
    }

    :active {
        transform: translateY(2px);
    }

    :hover {
        cursor: pointer;
    }

    &.default {
        border-color: ${defaultColor};
        color: ${props => props.isActive ? backgroundColor : defaultColor};
        background-color: ${props => props.isActive ? defaultColor : backgroundColor};
        border-style: ${props => props.isActive ? "hidden" : "hidden solid"};

        .corners {
            width: 0;
            height: 0;
            position: absolute;

            &.top-left {
                top: 0;
                left: 0;
                border-top: 5px solid ${backgroundColor};
                border-right: 5px solid transparent;
            }

            &.top-right {
                top: 0;
                right: 0;
                border-top: 5px solid ${backgroundColor};
                border-left: 5px solid transparent;
            }

            &.bottom-left {
                bottom: 0;
                left: 0;
                border-bottom: 5px solid ${backgroundColor};
                border-right: 5px solid transparent;
            }

            &.bottom-right {
                bottom: 0;
                right: 0;
                border-bottom: 5px solid ${backgroundColor};
                border-left: 5px solid transparent;
            }
        }
    }

    &.retro-terminal {
        border-color: ${retroTerminalColor};
        color: ${props => props.isActive ? retroTerminalBackgroundColor : retroTerminalColor};
        background-color: ${props => props.isActive ? retroTerminalColor : "transparent"};
        border-style: hidden solid;

        .corners {
            display: none;
        }
    }
`;

IndexSelector.displayName = "IndexSelector";
