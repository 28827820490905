import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Gray Glint`;

// BODY
const bodyGrayGlintImage = `${imagePath}/Chassis.png`;

// HEADS
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
// PREMIUM
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headCockpitImage = `${imagePath}/heads/Head - Cockpit.png`;
const headScopehoundImage = `${imagePath}/heads/Head - Scopehound.png`;

// WEAPONS
// LEFT
const weaponsLeftBladeImage = `${imagePath}/weapons/Main Weapon Left - Blade.png`;
const weaponsLeftARImage = `${imagePath}/weapons/Main Weapon Left - Assault Rifle.png`;

// RIGHT
const weaponsRightBladeImage = `${imagePath}/weapons/Main Weapon Right - Blade.png`;
const weaponsRightARImage = `${imagePath}/weapons/Main Weapon Right - Assault Rifle.png`;
const weaponsRightGunBladeImage = `${imagePath}/weapons/gray glint gunblade.png`;

// LEGS
const legsBoostImage = `${imagePath}/legs/Legs - Boost.png`;
const legsNeedletipImage = `${imagePath}/legs/Legs - Needletip.png`;
const legsTalonsImage = `${imagePath}/legs/Legs - Talons.png`;
const legsTreadImage = `${imagePath}/legs/Legs - Tread.png`;

// SHOULDERS
const shouldersWingsImage = `${imagePath}/shoulders/Shoulder - Wings.png`;
const shouldersWingsMissilesImage = `${imagePath}/shoulders/Shoulder - Wings _ Missiles.png`;
// LEGENDARY
const shouldersAssaultImage = `${imagePath}/shoulders/Shoulder - Assault.png`;
const shouldersMissilesImage = `${imagePath}/shoulders/Shoulder - Missiles.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "21px",
    right: "44px",
};

export default {
    previewImg: previewImage,
    removeNone: {
        head: true,
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "COCKPIT",
            foremostSrc: headCockpitImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GRAY GLINT",
            src: bodyGrayGlintImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BOOST",
                backSrc: legsBoostImage,
            },
            {
                value: 1,
                label: "NEEDLETIP",
                backSrc: legsNeedletipImage,
                isPremium: true,
            },
            {
                value: 2,
                label: "TALONS",
                backSrc: legsTalonsImage,
                isPremium: true,
            },
            {
                value: 3,
                label: "TREAD",
                backSrc: legsTreadImage,
                isPremium: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "WINGS",
            src: shouldersWingsImage,
        },
        {
            value: 1,
            label: "WINGS + MISSILES",
            src: shouldersWingsMissilesImage,
        },
        {
            value: 2,
            label: "ASSAULT",
            src: shouldersAssaultImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "MISSILES",
            src: shouldersMissilesImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "LEFT - BLADE",
            src: weaponsLeftBladeImage,
        },
        {
            value: 1,
            label: "LEFT - AR",
            src: weaponsLeftARImage,
        },
        {
            value: 2,
            label: "RIGHT - BLADE",
            src: weaponsRightBladeImage,
        },
        {
            value: 3,
            label: "RIGHT - AR",
            src: weaponsRightARImage,
        },
        {
            value: 4,
            label: "RIGHT - GUNBLADE",
            src: weaponsRightGunBladeImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEGS",
            value: "weapon",
        },
        {
            label: "SHOULDERS",
            value: "secondaryWeapon",
        },
        {
            label: "WEAPON 1",
            value: "accessory1",
        },
        {
            label: "WEAPON 2",
            value: "accessory2",
        },
        {
            label: "WEAPON 3",
            value: "accessory3",
        },
        {
            label: "WEAPON 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEGS",
        "secondaryWeapon": "SHOULDERS",
        "accessory1": "WEAPON 1",
        "accessory2": "WEAPON 2",
        "accessory3": "WEAPON 3",
        "accessory4": "WEAPON 4",
    },
};
