import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/MFC-Grootslang`;

// BODY
const bodyChassisStockImage = `${imagePath}/Chassis.png`;

// HEADS
const headClosedImage = `${imagePath}/heads/Aux - Closed.png`;
const headEyesImage = `${imagePath}/heads/Aux - Eyes.png`;
const headGatlingsImage = `${imagePath}/heads/Aux - Gatlings.png`;
const headMissilesImage = `${imagePath}/heads/Aux - Missiles.png`;

// WEAPONS
// HEAVY
const heavyWepClosedImage = `${imagePath}/weapons/Heavy Mount - Closed.png`;
const heavyWepLeviathanCannonImage = `${imagePath}/weapons/Heavy Mount - Leviathan Cannon.png`;
const heavyWepMfecanesHandImage = `${imagePath}/weapons/Heavy Mount - Mfecane_s Hand.png`;

// PLATFORM
const platformGatlingImage = `${imagePath}/weapons/Platform - Gatling.png`;
const platformHMGImage = `${imagePath}/weapons/Platform - HMG.png`;

// ACCESSORY
const accGraffitiImage = `${imagePath}/accessories/Decals - Graffiti.png`;
const accMfecaneImage = `${imagePath}/accessories/Decals - Mfecane.png`;

// GLOWS
const glowEyesImage = `${imagePath}/glows/Glow - Eyes.png`;
const glowMfecanesHandImage = `${imagePath}/glows/Glow - Mfecane_s Hand.png`;
const glowMfecanesHandArcsImage = `${imagePath}/glows/Glow - Mfecane_s Hand Arcs.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "34px",
    right: "29px",
};

export default {
    isMegadeusPlus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "1",
        "rearMountValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CLOSED",
            src: headClosedImage,
        },
        {
            value: 1,
            label: "EYES",
            src: headEyesImage,
        },
        {
            value: 2,
            label: "GATLINGS",
            src: headGatlingsImage,
        },
        {
            value: 3,
            label: "MISSILES",
            src: headMissilesImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MFC GROOTSLANG",
            src: bodyChassisStockImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by @StationUkuwa, original by",
                title: "@siegefault",
                url: "https://twitter.com/siegefault/status/1476339219534594049",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLOSED",
                src: heavyWepClosedImage,
            },
            {
                value: 1,
                label: "LEVIATHAN CANNON",
                src: heavyWepLeviathanCannonImage,
            },
            {
                value: 2,
                label: "MFECANE'S HAND",
                src: heavyWepMfecanesHandImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "GATLING",
            src: platformGatlingImage,
        },
        {
            value: 1,
            label: "HMG",
            src: platformHMGImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "GRAFFITI",
            frontSrc: accGraffitiImage,
        },
        {
            value: 1,
            label: "MFECANE",
            frontSrc: accMfecaneImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "EYES",
            frontSrc: glowEyesImage,
        },
        {
            value: 1,
            label: "MFECANE'S HAND",
            frontSrc: glowMfecanesHandImage,
        },
        {
            value: 2,
            label: "MFECANE'S HAND + ARCS",
            frontSrc: glowMfecanesHandArcsImage,
        },
        {
            value: 3,
            label: "DECAL - GRAFFITI",
            frontSrc: accGraffitiImage,
        },
        {
            value: 4,
            label: "DECAL - MFECANE",
            frontSrc: accMfecaneImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "H MOUNT",
            value: "weapon",
        },
        {
            label: "PLATFORM",
            value: "secondaryWeapon",
        },
        {
            label: "DECAL",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "DECAL",
        "weapon": "H MOUNT",
        "secondaryWeapon": "PLATFORM",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
