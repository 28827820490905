import React, { useContext } from "react";
import styled from "styled-components";

import { MenuTabWrapper } from "./../../Menu/Tabs/MenuTab";
import { CurrentThemeContext } from "./../../common/ThemeContextWrapper";
import SpriteImage, { SPRITE_KEYS } from "../../common/styles/SpriteImage";

const clientID = "90tc9i6YK29kntXYbuFpifKTabCQ1N1p8TYLxg2DGtXepwPDV1cGLmNszz05gwbU";
const redirectURI = window.encodeURI("https://www.retrogrademinis.com");
const scope = window.encodeURI("identity identity.memberships identity[email] campaigns");

const PatreonLoginButton = styled(MenuTabWrapper)`
    border-width: 4px 0 4px 4px;
    color: white;
    width: 225px;
    margin-top: 4px;

    &.fantasy {
        background: #E3523E;
        border-style: outset;
        border-color: #E3523E;
    }

    &.mech {
        background: #E3523E;
        border-style: solid;
        border-color: #E3523E;

        img {
            display: block;
        }
    }

    img {
        width: 97px;
        padding: 5px 10px;
    }

    :active {
        border-style: inset;
    }
`;

PatreonLoginButton.displayName = "PatreonLoginButton";

function LoginButton() {
    const { currentSide } = useContext(CurrentThemeContext);

    return (
        <PatreonLoginButton
            className={currentSide}
            as="a"
            href={`https://www.patreon.com/oauth2/authorize?response_type=code&redirect_uri=${redirectURI}&client_id=${clientID}&scope=${scope}`}
        >
            <SpriteImage className={SPRITE_KEYS.patreonLoginWhite} alt="Patreon Login" />
        </PatreonLoginButton>
    )
}

export default LoginButton;
