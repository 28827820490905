import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Remus`

// BODY
const bodyRemusImage = `${imagePath}/Remus_Chassis_Remus.png`;
const bodyOrthusImage = `${imagePath}/Remus_Chassis_Orthus.png`;

// HEADS
const headRemusImage = `${imagePath}/heads/Remus_Head_Remus.png`;
const headChimeraImage = `${imagePath}/heads/Remus_Head_Chimera.png`;
const headGaruImage = `${imagePath}/heads/Remus_Head_Garu.png`;
const headHundImage = `${imagePath}/heads/Remus_Head_Hund.png`;
const headLupinSabreImage = `${imagePath}/heads/Remus_Head_Lupin Sabre.png`;
const headOrthusImage = `${imagePath}/heads/Remus_Head_Orthus.png`;
const headPthallfireImage = `${imagePath}/heads/Remus_Head_Pthallfire.png`;
const headSifImage = `${imagePath}/heads/Remus_Head_Sif.png`;

// WEAPONS
// LEFT
const weaponsLeftBoosterImage = `${imagePath}/weapons/left/Remus_Weapons Left_Booster.png`;
const weaponsLeftChainsawImage = `${imagePath}/weapons/left/Remus_Weapons Left_Chainsaw.png`;
const weaponsLeftLMGImage = `${imagePath}/weapons/left/Remus_Weapons Left_LMG.png`;
const weaponsLeftMissilesImage = `${imagePath}/weapons/left/Remus_Weapons Left_Missiles.png`;

// RIGHT
const weaponsRightBoosterImage = `${imagePath}/weapons/right/Remus_Weapons Right_Booster.png`;
const weaponsRightChainsawImage = `${imagePath}/weapons/right/Remus_Weapons Right_chainsaw.png`;
const weaponsRightLMGImage = `${imagePath}/weapons/right/Remus_Weapons Right_LMG.png`;
const weaponsRightMissilesImage = `${imagePath}/weapons/right/Remus_Weapons Right_Missile.png`;

// REAR MOUNT
const rearExhaustImage = `${imagePath}/rear/Remus_Accessories Mid_Exhaust.png`;
const rearExhaustBackImage = `${imagePath}/rear/Remus_Accessories Rear_Exhaust.png`;
const rearStingCannonImage = `${imagePath}/rear/Remus_Rear Mount_Sting Cannon.png`;
const rearTurretImage = `${imagePath}/rear/Remus_Rear Mount_Turret.png`;

// GLOWS
const glowBoosterGlowRightFrontImage = `${imagePath}/glows/Remus_Accessories Front_Booster Glow Right.png`;
const glowBoosterGlowLeftImage = `${imagePath}/glows/Remus_Accessories Mid_Booster Glow Left.png`;
const glowPlasmaClawsImage = `${imagePath}/glows/Remus_Accessories Front_Plasma Claws.png`;
const glowChimeraHeadGlowImage = `${imagePath}/glows/Remus_Accessories Front_Chimera Head Glow.png`;
const glowGaruHeadGlowImage = `${imagePath}/glows/Remus_Accessories Front_Garu Head Glow.png`;
const glowHundHeadGlowImage = `${imagePath}/glows/Remus_Accessories Front_Hund Head Glow.png`;
const glowOrthusHeadGlowImage = `${imagePath}/glows/Remus_Accessories Front_Orthus Head Glow.png`;
const glowRemusHeadGlowImage = `${imagePath}/glows/Remus_Accessories Front_Remus Head Glow.png`;
const glowSifHeadGlowImage = `${imagePath}/glows/Remus_Accessories Front_Sif Head Glow.png`;
const glowOrthusChassisGlowImage = `${imagePath}/glows/Remus_Accessories Mid_Orthus Chassis Glow.png`;
const glowRemusChassisGlowImage = `${imagePath}/glows/Remus_Accessories Mid_Remus Chassis Glow.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "47px",
    right: "-5px",
};

export default {
    accessory2IsChassis: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "12",
        "accessory2Value": "NONE",
        "accessory3Value": "9",
        "accessory4Value": "0",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "REMUS",
            src: headRemusImage,
        },
        {
            value: 1,
            label: "ORTHUS",
            src: headOrthusImage,
        },
        {
            value: 2,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 3,
            label: "GARU",
            src: headGaruImage,
        },
        {
            value: 4,
            label: "HUND",
            src: headHundImage,
        },
        {
            value: 5,
            label: "LUPIN SABRE",
            src: headLupinSabreImage,
        },
        {
            value: 6,
            label: "PTHALLFIRE",
            src: headPthallfireImage,
        },
        {
            value: 7,
            label: "SIF",
            src: headSifImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "REMUS",
            src: bodyRemusImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Commissioned by `,
                title: "xYISKAx",
                url: contributorList.PINKHYENA.url,
            },
        },
        {
            value: 1,
            label: "ORTHUS",
            src: bodyOrthusImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Commissioned by `,
                title: "xYISKAx",
                url: contributorList.PINKHYENA.url,
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BOOSTER",
                backSrc: weaponsLeftBoosterImage,
            },
            {
                value: 1,
                label: "CHAINSAW",
                backSrc: weaponsLeftChainsawImage,
            },
            {
                value: 2,
                label: "LMG",
                backSrc: weaponsLeftLMGImage,
            },
            {
                value: 3,
                label: "MISSILES",
                backSrc: weaponsLeftMissilesImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BOOSTER",
            src: weaponsRightBoosterImage,
        },
        {
            value: 1,
            label: "CHAINSAW",
            src: weaponsRightChainsawImage,
        },
        {
            value: 2,
            label: "LMG",
            src: weaponsRightLMGImage,
        },
        {
            value: 3,
            label: "MISSILES",
            src: weaponsRightMissilesImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "EXHAUST",
            frontSrc: rearExhaustImage,
            src: rearExhaustBackImage,
        },
        {
            value: 1,
            label: "STING CANNON",
            frontSrc: rearStingCannonImage,
        },
        {
            value: 2,
            label: "TURRET",
            frontSrc: rearTurretImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "EXHAUST - ACCESSORY",
            src: rearExhaustImage,
            backSrc: rearExhaustBackImage,
        },
        {
            value: 1,
            label: "BOOSTER - LEFT",
            backSrc: glowBoosterGlowLeftImage,
        },
        {
            value: 2,
            label: "BOOSTER - RIGHT",
            frontSrc: glowBoosterGlowRightFrontImage,
        },
        {
            value: 3,
            label: "BOOSTER - BOTH",
            backSrc: glowBoosterGlowLeftImage,
            frontSrc: glowBoosterGlowRightFrontImage,
        },
        {
            value: 4,
            label: "PLASMA CLAWS",
            src: glowPlasmaClawsImage,
        },
        {
            value: 5,
            label: "CHIMERA HEAD",
            frontSrc: glowChimeraHeadGlowImage,
        },
        {
            value: 6,
            label: "GARU HEAD",
            frontSrc: glowGaruHeadGlowImage,
        },
        {
            value: 7,
            label: "HUND HEAD",
            frontSrc: glowHundHeadGlowImage,
        },
        {
            value: 8,
            label: "ORTHUS HEAD",
            frontSrc: glowOrthusHeadGlowImage,
        },
        {
            value: 9,
            label: "REMUS HEAD",
            frontSrc: glowRemusHeadGlowImage,
        },
        {
            value: 10,
            label: "SIF HEAD",
            frontSrc: glowSifHeadGlowImage,
        },
        {
            value: 11,
            label: "ORTHUS CHASSIS",
            src: glowOrthusChassisGlowImage,
        },
        {
            value: 12,
            label: "REMUS CHASSIS",
            src: glowRemusChassisGlowImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "EMPTY",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
