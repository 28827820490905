import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyMatapacoImage = `${BaseURL}/Mechs/Custom/Matapacos/Chassis.png`;

// HEADS
const headMatapacoImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Matapaco.png`;
const headLupinSabreImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Lupin Sabre.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Malice.png`;
const headPlateImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Plate.png`;
const headSifImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Sif.png`;
const headTeefImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Teef.png`;
const headVisorImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Visor.png`;
const headVisorDarkImage = `${BaseURL}/Mechs/Custom/Matapacos/heads/Head - Visor Dark.png`;

// WEAPONS
const weaponsHeavyClawsImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Heavy Claws.png`;
const weaponsKnifeOverImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Knife Over.png`;
const weaponsKnifeUnderImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Knife Under.png`;
const weaponsHeavyBladeOverImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Heavy Blade Over.png`;
const weaponsHeavyBladeUnderImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Heavy Blade Under.png`;
const weaponsARImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - AR.png`;
const weaponsDualARImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Dual AR.png`;
const weaponsSideARImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Side ARs.png`;
const weaponsQuadARImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Quad ARs.png`;
const weaponsCannonImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Cannon.png`;
const weaponsDualCannonImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Dual Cannons.png`;
const weaponsAssaultCannonImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Assault Cannon.png`;
const weaponsAssaultCannonSideARImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Assault Cannon and Side ARs.png`;
const weaponsMissilesImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Missiles.png`;
const weaponsARMissilesImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - AR and Missiles.png`;
const weaponsCannonMissilesImage = `${BaseURL}/Mechs/Custom/Matapacos/weapons/Weapon - Cannon and Missiles.png`;

// REAR MOUNT
const rearMountTailImage = `${BaseURL}/Mechs/Custom/Matapacos/rear mount/Rear - Tail.png`;
const rearMountDoubleTailImage = `${BaseURL}/Mechs/Custom/Matapacos/rear mount/Rear - Double Tail.png`;
const rearMountTailAxeImage = `${BaseURL}/Mechs/Custom/Matapacos/rear mount/Rear - Tail Axe.png`;
const rearMountTailBladeImage = `${BaseURL}/Mechs/Custom/Matapacos/rear mount/Rear - Tail Blade.png`;

// ACCESSORIES
const accessoryBoostersImage = `${BaseURL}/Mechs/Custom/Matapacos/accessories/Accessory - Boosters.png`;
const accessoryFinsOverImage = `${BaseURL}/Mechs/Custom/Matapacos/accessories/Accessory - Fins Over.png`;
const accessoryFinsUnderImage = `${BaseURL}/Mechs/Custom/Matapacos/accessories/Accessory - Fins Under.png`;
const accessoryMatapacoBandanaImage = `${BaseURL}/Mechs/Custom/Matapacos/accessories/Accessory - Matapaco Bandana.png`;
const accessoryPackImage = `${BaseURL}/Mechs/Custom/Matapacos/accessories/Accessory - Pack.png`;
const glowHeavyBladeImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Heavy Blade.png`;
const glowHeavyClawsImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Heavy Claws.png`;
const glowTailBladeImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Tail Blade.png`;

// GLOW
const glowEyeImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Eye Glow.png`;
const glowLupinSabreImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Lupin Sabre.png`;
const glowMaliceImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Malice.png`;
const glowPlateImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Plate.png`;
const glowSifImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Sif Eyes.png`;
const glowTeefImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Teef.png`;
const glowVisorImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Visor.png`;
const glowVisorDarkImage = `${BaseURL}/Mechs/Custom/Matapacos/glow/Glow - Visor Dark.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Matapacos/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "84px",
    right: "38px",
};

export default {
    previewImg: previewImage,
    headSpecial: "secondaryWeaponValue",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "MATAPACOS",
            foremostSrc: headMatapacoImage,
        },
        {
            value: 1,
            label: "LUPIN SABRE",
            foremostSrc: headLupinSabreImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "PLATE",
            foremostSrc: headPlateImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "SIF",
            foremostSrc: headSifImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "TEEF",
            foremostSrc: headTeefImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "VISOR",
            foremostSrc: headVisorImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "VISOR DARK",
            foremostSrc: headVisorDarkImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MATAPACOS",
            src: bodyMatapacoImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HEAVY CLAWS",
                src: weaponsHeavyClawsImage,
            },
            {
                value: 1,
                label: "KNIFE",
                foremostSrc: weaponsKnifeOverImage,
                backSrc: weaponsKnifeUnderImage,
            },
            {
                value: 2,
                label: "HEAVY BLADE",
                foremostSrc: weaponsHeavyBladeOverImage,
                backSrc: weaponsHeavyBladeUnderImage,
            },
            {
                value: 3,
                label: "AR",
                src: weaponsARImage,
            },
            {
                value: 4,
                label: "DUAL AR",
                foremostSrc: weaponsDualARImage,
            },
            {
                value: 5,
                label: "SIDE AR",
                src: weaponsSideARImage,
            },
            {
                value: 6,
                label: "QUAD AR",
                foremostSrc: weaponsQuadARImage,
            },
            {
                value: 7,
                label: "CANNON",
                src: weaponsCannonImage,
            },
            {
                value: 8,
                label: "DUAL CANNON",
                foremostSrc: weaponsDualCannonImage,
            },
            {
                value: 9,
                label: "ASSAULT CANNON",
                src: weaponsAssaultCannonImage,
            },
            {
                value: 10,
                label: "ASSAULT CANNON + SIDE AR",
                src: weaponsAssaultCannonSideARImage,
            },
            {
                value: 11,
                label: "MISSILES",
                src: weaponsMissilesImage,
            },
            {
                value: 12,
                label: "AR + MISSILES",
                src: weaponsARMissilesImage,
            },
            {
                value: 13,
                label: "CANNON + MISSILES",
                src: weaponsCannonMissilesImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MATAPACOS",
            foremostSrc: glowEyeImage,
        },
        {
            value: 1,
            label: "LUPIN SABRE",
            foremostSrc: glowLupinSabreImage,
        },
        {
            value: 2,
            label: "MALICE",
            foremostSrc: glowMaliceImage,
        },
        {
            value: 3,
            label: "PLATE",
            foremostSrc: glowPlateImage,
        },
        {
            value: 4,
            label: "SIF",
            foremostSrc: glowSifImage,
        },
        {
            value: 5,
            label: "TEEF",
            foremostSrc: glowTeefImage,
        },
        {
            value: 6,
            label: "VISOR",
            foremostSrc: glowVisorImage,
        },
        {
            value: 7,
            label: "VISOR DARK",
            foremostSrc: glowVisorDarkImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "TAIL",
            src: rearMountTailImage,
        },
        {
            value: 1,
            label: "DOUBLE TAIL",
            src: rearMountDoubleTailImage,
        },
        {
            value: 2,
            label: "TAIL AXE",
            src: rearMountTailAxeImage,
        },
        {
            value: 3,
            label: "TAIL BLADE",
            src: rearMountTailBladeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BOOSTERS",
            src: accessoryBoostersImage,
        },
        {
            value: 1,
            label: "PACK",
            src: accessoryPackImage,
        },
        {
            value: 2,
            label: "MATAPACO BANDANA",
            src: accessoryMatapacoBandanaImage,
        },
        {
            value: 3,
            label: "FINS",
            foremostSrc: accessoryFinsOverImage,
            src: accessoryFinsUnderImage,
        },
        {
            value: 4,
            label: "HEAVY CLAWS",
            src: weaponsHeavyClawsImage,
        },
        {
            value: 5,
            label: "KNIFE",
            foremostSrc: weaponsKnifeOverImage,
            backSrc: weaponsKnifeUnderImage,
        },
        {
            value: 6,
            label: "HEAVY BLADE",
            foremostSrc: weaponsHeavyBladeOverImage,
            backSrc: weaponsHeavyBladeUnderImage,
        },
        {
            value: 7,
            label: "HEAVY CLAWS GLOW",
            foremostSrc: glowHeavyClawsImage,
        },
        {
            value: 8,
            label: "HEAVY BLADE GLOW",
            foremostSrc: glowHeavyBladeImage,
        },
        {
            value: 9,
            label: "TAIL BLADE GLOW",
            foremostSrc: glowTailBladeImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "TAIL",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "TAIL",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
