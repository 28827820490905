import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// WHEELS
const wheelsSingle3AxImage = `${BaseURL}/Mechs/Misc/Truck/wheels/Wheels - Single - 3 Axle.png`;
const wheelsSingle6WheelImage = `${BaseURL}/Mechs/Misc/Truck/wheels/Wheels - Single - 6 Wheel.png`;
const wheelsSingleOffroadImage = `${BaseURL}/Mechs/Misc/Truck/wheels/Wheels - Single - Offroad.png`;
const wheelsDoubleImage = `${BaseURL}/Mechs/Misc/Truck/wheels/Wheels - Double.png`;
const wheelsDoubleOffroadImage = `${BaseURL}/Mechs/Misc/Truck/wheels/Wheels - Double - Offroad.png`;
const wheelsTreadsImage = `${BaseURL}/Mechs/Misc/Truck/wheels/Wheels - Treads.png`;

// CAB
const cabLonghaulImage = `${BaseURL}/Mechs/Misc/Truck/heads/Cab - Longhaul.png`;
const cabAPCImage = `${BaseURL}/Mechs/Misc/Truck/heads/Cab - APC.png`;
const cabAPC2Image = `${BaseURL}/Mechs/Misc/Truck/heads/Cab - APC 2.png`;
const cabBulwarkImage = `${BaseURL}/Mechs/Misc/Truck/heads/Cab - Bulwark.png`;
const cabOptimalImage = `${BaseURL}/Mechs/Misc/Truck/heads/Cab - Optimal.png`;
const cabPatchjobImage = `${BaseURL}/Mechs/Misc/Truck/heads/Cab - Patchjob.png`;

// CARGO
const cargoContainerImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Container.png`;
const cargoContainerDoubleImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Double Container.png`;
const cargoFlatbedImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Flatbed.png`;
const cargoFlatbedDoubleImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Double - Flatbed.png`;
const cargoFlatbedLoadedImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Flatbed Loaded.png`;
const cargoFlatbedLoadedDoubleImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Double - Loaded Flatbed.png`;
const cargoFlatbedMechImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Flatbed - Mech.png`;
const cargoTankerImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - Tanker.png`;
const cargoAPCImage = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - APC.png`;
const cargoAPC2Image = `${BaseURL}/Mechs/Misc/Truck/cargo/Cargo - APC 2.png`;

const previewImage = `${BaseURL}/Mechs/Misc/Truck/preview.png`;

const wheelsShadow = {
    size: shadowTypes.LARGE,
    bottom: "91px",
    right: "53px",
};

const wheelsDoubleShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "80px",
    right: "3px",
};

export default {
    previewImg: previewImage,
    accessory2IsChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "LONGHAUL",
            src: cabLonghaulImage,
        },
        {
            value: 1,
            label: "APC",
            src: cabAPCImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "APC 2",
            src: cabAPC2Image,
            isPremium: true,
        },
        {
            value: 3,
            label: "BULWARK",
            src: cabBulwarkImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "OPTIMAL",
            src: cabOptimalImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "PATCHJOB",
            src: cabPatchjobImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SINGLE 3 AXLE",
            src: wheelsSingle3AxImage,
            shadowDetails: wheelsShadow,
        },
        {
            value: 1,
            label: "SINGLE 6 WHEEL",
            src: wheelsSingle6WheelImage,
            shadowDetails: wheelsShadow,
            isPremium: true,
        },
        {
            value: 2,
            label: "SINGLE OFFROAD",
            src: wheelsSingleOffroadImage,
            shadowDetails: wheelsShadow,
            isPremium: true,
        },
        {
            value: 3,
            label: "DOUBLE",
            src: wheelsDoubleImage,
            shadowDetails: wheelsDoubleShadow,
            isPremium: true,
        },
        {
            value: 4,
            label: "DOUBLE OFFROAD",
            src: wheelsDoubleOffroadImage,
            shadowDetails: wheelsDoubleShadow,
            isPremium: true,
        },
        {
            value: 5,
            label: "TREADS",
            src: wheelsTreadsImage,
            shadowDetails: wheelsShadow,
            isPremium: true,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [
        {
            value: 0,
            label: "CONTAINER",
            frontSrc: cargoContainerImage,
        },
        {
            value: 1,
            label: "FLATBED",
            frontSrc: cargoFlatbedImage,
        },
        {
            value: 2,
            label: "CONTAINER DOUBLE",
            frontSrc: cargoContainerDoubleImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "FLATBED DOUBLE",
            frontSrc: cargoFlatbedDoubleImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "FLATBED LOADED",
            frontSrc: cargoFlatbedLoadedImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "FLATBED LOADED DOUBLE",
            frontSrc: cargoFlatbedLoadedDoubleImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "FLATBED LOADED MECH",
            frontSrc: cargoFlatbedMechImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "TANKER",
            frontSrc: cargoTankerImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "APC",
            frontSrc: cargoAPCImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "APC 2",
            frontSrc: cargoAPC2Image,
            isPremium: true,
        },
    ],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CAB",
            value: "head",
        },
        {
            label: "WHEELS",
            value: "chassis",
        },
        {
            label: "CARGO",
            value: "rearMount",
        },
    ],
    labels: {
        "head": "CAB",
        "chassis": "WHEELS",
        "rearMount": "CARGO",
        "weapon": "WEAPON",
        "secondaryWeapon": "SECONDARY WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 2",
        "accessory4": "ACCESSORY 3",
    },
};
