import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Huginn`

// BODY
const bodyHuginnImage = `${imagePath}/Huginn_Chassis.png`;

// HEADS
const headCockpitImage = `${imagePath}/Huginn_Cockpit_Huginn.png`;

// WEAPONS
// LEFT
const weaponsLeftLaserCannonImage = `${imagePath}/Huginn_Left_Weapon_Laser_Cannon.png`;

// RIGHT
const weaponsRightMissileLauncherImage = `${imagePath}/Huginn_Right_Weapon_Missile_Launcher.png`;

// REAR MOUNT
const rearMountArrayImage = `${imagePath}/Huginn_Rear_Mount_Muninn_Array.png`;

// LEGS
const legsRunningImage = `${imagePath}/Huginn_Legs_Running.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "28px",
    right: "5px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "COCKPIT",
            src: headCockpitImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "HUGINN",
            src: bodyHuginnImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Commissioned by `,
                title: "@starbright4evr",
                url: "https://twitter.com/starbright4evr?s=20",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "LASER CANNON",
                src: weaponsLeftLaserCannonImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MISSILE LAUNCHER",
            src: weaponsRightMissileLauncherImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "MUNINN ARRAY",
            src: rearMountArrayImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "LEGS - RUNNING",
            src: legsRunningImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "Weapons",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
