import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Stortebeker`;

// BODY
const bodyImage = `${imagePath}/Chassis.png`;

// HEADS
const headStortebekerImage = `${imagePath}/heads/Head - Stortebeker.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackbeardImage = `${imagePath}/heads/Head - Blackbeard.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headFactoryStandardImage = `${imagePath}/heads/Head - Factory Standard.png`;
const headFrontlineImage = `${imagePath}/heads/Head - Frontline.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazu3Image = `${imagePath}/heads/Head - Kazu 3.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headOgreImage = `${imagePath}/heads/Head - Ogre.png`;
const headPaladinImage = `${imagePath}/heads/Head - Paladin.png`;
const headPartisanImage = `${imagePath}/heads/Head - Partisan.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headRaleighImage = `${imagePath}/heads/Head - Raleigh.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headScopedogImage = `${imagePath}/heads/Head - Scopedog.png`;
const headStalwartImage = `${imagePath}/heads/Head - Stalwart.png`;
const headStampedeImage = `${imagePath}/heads/Head - Stampede.png`;
const headVanguardImage = `${imagePath}/heads/Head - Vanguard.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const leftChestwinter1887Image = `${imagePath}/weapons/left/Weapons - Left - Chestwinter 1887.png`;
const leftCrossbow1Image = `${imagePath}/weapons/left/Weapons - Left - Crossbow 1.png`;
const leftCrossbow2Image = `${imagePath}/weapons/left/Weapons - Left - Crossbow 2.png`;
const leftHandCannonImage = `${imagePath}/weapons/left/Weapons - Left - Hand Cannon.png`;
const leftKineticHammerImage = `${imagePath}/weapons/left/Weapons - Left - Kinetic Hammer.png`;
const leftPilebunkerImage = `${imagePath}/weapons/left/Weapons - Left - Pilebunker.png`;
const leftRevolverImage = `${imagePath}/weapons/left/Weapons - Left - Revolver.png`;
const leftWendigoImage = `${imagePath}/weapons/left/Weapons - Left - Wendigo.png`;

// RIGHT
const rightChestwinter1887sImage = `${imagePath}/weapons/right/Weapons - Right - Chestwinter 1887s.png`;
const rightCrossbows1Image = `${imagePath}/weapons/right/Weapons - Right - Crossbows 1.png`;
const rightCrossbows2Image = `${imagePath}/weapons/right/Weapons - Right - Crossbows 2.png`;
const rightHandCannonsImage = `${imagePath}/weapons/right/Weapons - Right - Hand Cannons.png`;
const rightPilebunkersImage = `${imagePath}/weapons/right/Weapons - Right - Pilebunkers.png`;
const rightRevolversImage = `${imagePath}/weapons/right/Weapons - Right - Revolvers.png`;
const rightWendigosImage = `${imagePath}/weapons/right/Weapons - Right - Wendigos.png`;

// LOWER
const lowerCannibalImage = `${imagePath}/weapons/lower/Weapons - Lower Arms - Cannibal.png`;
const lowerCannibalBayonetImage = `${imagePath}/weapons/lower/Weapons - Lower Arms - Cannibal Bayonet.png`;
const lowerCannibalHandCannonImage = `${imagePath}/weapons/lower/Weapons - Lower Arms - Cannibal Hand Cannon.png`;
const lowerCannibalPilebunkerImage = `${imagePath}/weapons/lower/Weapons - Lower Arms - Cannibal Pilebunker.png`;
const lowerCannibalRevolverImage = `${imagePath}/weapons/lower/Weapons - Lower Arms - Cannibal Revolver.png`;
const lowerBowImage = `${imagePath}/weapons/lower/Stort_Bow.png`;
const lowerBowQuiverImage = `${imagePath}/weapons/lower/Stort_Bow_Quiver.png`;

// GLOWS
const glowEyeGlintImage = `${imagePath}/glows/Glow - Main - Eye Glint.png.png`;
const glowTruesilverImage = `${imagePath}/glows/Glow - Main - Truesilver.png`;
const glowCannibalImage = `${imagePath}/glows/Glow Top - Cannibal.png`;
const glowChestwinterTruesilverImage = `${imagePath}/glows/Glow Top - Chestwinter Truesilver.png`;
const glowCrossbows1TruesilverImage = `${imagePath}/glows/Glow Top - Crossbows 1 Truesilver.png`;
const glowCrossbows2TruesilverImage = `${imagePath}/glows/Glow Top - Crossbows 2 Truesilver.png`;
const glowHandCannonTruesilverImage = `${imagePath}/glows/Glow Top - Hand Cannon Truesilver.png`;
const glowRevolverTruesilverImage = `${imagePath}/glows/Glow Top - Revolver Truesilver.png`;
const glowWendigoTruesilverImage = `${imagePath}/glows/Glow Top - Wendigo Truesilver.png`;

// ACCESSORY
const accessoryCloakImage = `${imagePath}/glows/Accessories -Cloak Gray Over.png`;
const accessoryCloakBackImage = `${imagePath}/glows/Accessories Under - Cloak Gray.png`;
const accessoryCloakGreenImage = `${imagePath}/glows/Accessories -Cloak Grn Over.png`;
const accessoryCloakGreenBackImage = `${imagePath}/glows/Accessories Under - Cloak Grn.png`;

const coreImage = `${imagePath}/Stortebeker-core-Kanso_Wizard.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "-11px",
    right: "66px",
};

export default {
    isMegadeus: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Kanso_Wizard",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "3",
        "secondaryWeaponValue": "3",
        "accessory1Value": "2",
        "accessory2Value": "7",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "STORTEBEKER",
            src: headStortebekerImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            src: headAngularImage,
        },
        {
            value: 2,
            label: "BLACKBEARD",
            src: headBlackbeardImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 5,
            label: "EXCLUSIVE - CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 7,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
        },
        {
            value: 8,
            label: "FRONTLINE",
            src: headFrontlineImage,
        },
        {
            value: 9,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 10,
            label: "KAZU 3",
            src: headKazu3Image,
        },
        {
            value: 11,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 12,
            label: "LUDONAUT",
            src: headLudonautImage,
        },
        {
            value: 13,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 14,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 15,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 16,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 17,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 18,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 19,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 20,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 21,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 22,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 23,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 24,
            label: "OGRE",
            src: headOgreImage,
        },
        {
            value: 25,
            label: "PALADIN",
            src: headPaladinImage,
        },
        {
            value: 26,
            label: "PARTISAN",
            src: headPartisanImage,
        },
        {
            value: 27,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 28,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 29,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 30,
            label: "RALEIGH",
            src: headRaleighImage,
        },
        {
            value: 31,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 32,
            label: "SCOPEDOG",
            src: headScopedogImage,
        },
        {
            value: 33,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 34,
            label: "STAMPEDE",
            src: headStampedeImage,
        },
        {
            value: 35,
            label: "VANGUARD",
            src: headVanguardImage,
        },
        {
            value: 36,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "STORTEBEKER",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CHESTWINTER 1887",
                src: leftChestwinter1887Image,
            },
            {
                value: 1,
                label: "CROSSBOW 1",
                src: leftCrossbow1Image,
            },
            {
                value: 2,
                label: "CROSSBOW 2",
                src: leftCrossbow2Image,
            },
            {
                value: 3,
                label: "HAND CANNON",
                src: leftHandCannonImage,
            },
            {
                value: 4,
                label: "KINETIC HAMMER",
                src: leftKineticHammerImage,
            },
            {
                value: 5,
                label: "PILEBUNKER",
                src: leftPilebunkerImage,
            },
            {
                value: 6,
                label: "REVOLVER",
                src: leftRevolverImage,
            },
            {
                value: 7,
                label: "WENDIGO",
                src: leftWendigoImage,
            },
            {
                value: 8,
                label: "EXCLUSIVE - CANNIBAL",
                src: lowerCannibalImage,
            },
            {
                value: 9,
                label: "EXCLUSIVE - CANNIBAL + BAYONET",
                src: lowerCannibalBayonetImage,
            },
            {
                value: 10,
                label: "EXCLUSIVE - CANNIBAL + HAND CANNON",
                src: lowerCannibalHandCannonImage,
            },
            {
                value: 11,
                label: "EXCLUSIVE - CANNIBAL + PILEBUNKER",
                src: lowerCannibalPilebunkerImage,
            },
            {
                value: 12,
                label: "EXCLUSIVE - CANNIBAL + REVOLVER",
                src: lowerCannibalRevolverImage,
            },
            {
                value: 13,
                label: "EXCLUSIVE - BOW",
                src: lowerBowImage,
                originalArtCredit: {
                    prefixText: "Commissioned by Toaster. Support with +2 patron tier. Created by ",
                    url: contributorList.PINKHYENA.url,
                    title: contributorList.PINKHYENA.title,
                },
                isPlusTwo: true,
            },
            {
                value: 14,
                label: "EXCLUSIVE - BOW + QUIVER",
                src: lowerBowQuiverImage,
                originalArtCredit: {
                    prefixText: "Commissioned by Toaster. Support with +2 patron tier. Created by ",
                    url: contributorList.PINKHYENA.url,
                    title: contributorList.PINKHYENA.title,
                },
                isPlusTwo: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CHESTWINTER 1887",
            src: rightChestwinter1887sImage,
        },
        {
            value: 1,
            label: "CROSSBOWS 1",
            src: rightCrossbows1Image,
        },
        {
            value: 2,
            label: "CROSSBOWS 2",
            src: rightCrossbows2Image,
        },
        {
            value: 3,
            label: "HAND CANNONS",
            src: rightHandCannonsImage,
        },
        {
            value: 4,
            label: "PILEBUNKERS",
            src: rightPilebunkersImage,
        },
        {
            value: 5,
            label: "REVOLVERS",
            src: rightRevolversImage,
        },
        {
            value: 6,
            label: "WENDIGOS",
            src: rightWendigosImage,
        },
        {
            value: 7,
            label: "EXCLUSIVE - CANNIBAL",
            src: lowerCannibalImage,
        },
        {
            value: 8,
            label: "EXCLUSIVE - CANNIBAL + BAYONET",
            src: lowerCannibalBayonetImage,
        },
        {
            value: 9,
            label: "EXCLUSIVE - CANNIBAL + HAND CANNON",
            src: lowerCannibalHandCannonImage,
        },
        {
            value: 10,
            label: "EXCLUSIVE - CANNIBAL + PILEBUNKER",
            src: lowerCannibalPilebunkerImage,
        },
        {
            value: 11,
            label: "EXCLUSIVE - CANNIBAL + REVOLVER",
            src: lowerCannibalRevolverImage,
        },
        {
            value: 12,
            label: "EXCLUSIVE - BOW",
            src: lowerBowImage,
            originalArtCredit: {
                prefixText: "Commissioned by Toaster. Support with +2 patron tier. Created by ",
                url: contributorList.PINKHYENA.url,
                title: contributorList.PINKHYENA.title,
            },
            isPlusTwo: true,
        },
        {
            value: 13,
            label: "EXCLUSIVE - BOW + QUIVER",
            src: lowerBowQuiverImage,
            originalArtCredit: {
                prefixText: "Commissioned by Toaster. Support with +2 patron tier. Created by ",
                url: contributorList.PINKHYENA.url,
                title: contributorList.PINKHYENA.title,
            },
            isPlusTwo: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "INERT",
            frontSrc: accessoryCloakImage,
            backSrc: accessoryCloakBackImage,
        },
        {
            value: 1,
            label: "GREEN",
            frontSrc: accessoryCloakGreenImage,
            backSrc: accessoryCloakGreenBackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "EYE GLINT",
            frontSrc: glowEyeGlintImage,
        },
        {
            value: 1,
            label: "TRUESILVER CHASSIS",
            src: glowTruesilverImage,
        },
        {
            value: 2,
            label: "TRUESILVER + EYE GLINT",
            frontSrc: glowEyeGlintImage,
            src: glowTruesilverImage,
        },
        {
            value: 3,
            label: "CANNIBAL",
            frontSrc: glowCannibalImage,
        },
        {
            value: 4,
            label: "CHESTWINTER",
            frontSrc: glowChestwinterTruesilverImage,
        },
        {
            value: 5,
            label: "CROSSBOWS 1",
            frontSrc: glowCrossbows1TruesilverImage,
        },
        {
            value: 6,
            label: "CROSSBOWS 2",
            frontSrc: glowCrossbows2TruesilverImage,
        },
        {
            value: 7,
            label: "HAND CANNON",
            frontSrc: glowHandCannonTruesilverImage,
        },
        {
            value: 8,
            label: "REVOLVER",
            frontSrc: glowRevolverTruesilverImage,
        },
        {
            value: 9,
            label: "WENDIGO",
            frontSrc: glowWendigoTruesilverImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "CLOAK",
            value: "rearMount",
        },
        {
            label: "WEAPONS L",
            value: "weapon",
        },
        {
            label: "WEAPONS R",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "CLOAK",
        "weapon": "WEAPONS L",
        "secondaryWeapon": "WEAPONS R",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
