import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyBarbarossaImage = `${BaseURL}/Mechs/HA/Barbarossa/Chassis.png`;

// HEADS
const headBarbarossaImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/Head - Barbarossa.png`;
const headKazuImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/Head - Kazu.png`;
// PREMIUM
const headAngularImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Angular.png`;
const headCalibanImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Caliban.png`;
const headGenghisImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Genghis.png`;
const headHammerheadImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Hammerhead.png`;
const headHighballImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Highball.png`;
const head8BallImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - 8 ball.png`;
const headRumbleImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Rumble.png`;
const headTaurusImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Taurus.png`;
const headGunAImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Gun A.png`;
const headGunBImage = `${BaseURL}/Mechs/HA/Barbarossa/heads/premium/Head - Gun B.png`;

// REAR MOUNT
const rearMountMortarImage = `${BaseURL}/Mechs/HA/Barbarossa/rear mount/Right Shoulder - Mortar.png`;
const rearMountSiegeCannonImage = `${BaseURL}/Mechs/HA/Barbarossa/rear mount/Right Shoulder - Siege Cannon.png`;

// WEAPON
// PRIMARY
const weaponAssaultRifleAImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/primary/Left Arm - Assault Rifle A.png`;
const weaponAssaultRifleBImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/primary/Left Arm - Assault Rifle B.png`;
const weaponGatlingCannonAImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/primary/Left Arm - Gatling Cannon A.png`;
const weaponGatlingCannonBImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/primary/Left Arm - Gatling Cannon B.png`;

// SECONDARY
const secondaryWeaponMortarImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/secondary/Left Shoulder - Mortar.png`;
const secondaryWeaponSiegeCannonImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/secondary/Left Shoulder - Siege Cannon.png`;
const secondaryWeaponApocalypseRailUnchargedImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/secondary/Left Shoulder - Apocalypse Rail Uncharged.png`;
const secondaryWeaponApocalypseRailChargedImage = `${BaseURL}/Mechs/HA/Barbarossa/weapons/secondary/Left Shoulder - Apocalypse Rail Charged.png`;

// ACCESSORY
const accessoryFlakBatteriesImage = `${BaseURL}/Mechs/HA/Barbarossa/accessory/Accessory - Flak Batteries.png`;
const accessoryHonorsImage = `${BaseURL}/Mechs/HA/Barbarossa/accessory/Accessory - Honors.png`;
const glowHighballImage = `${BaseURL}/Mechs/HA/Barbarossa/accessory/Glow - Highball.png`;
const glowApocalypseRailChargedImage = `${BaseURL}/Mechs/HA/Barbarossa/accessory/Glow - Apocalypse Rail Charged.png`;

const coreImage = `${BaseURL}/Mechs/HA/Barbarossa/Barbarossa - by Dr. Ta13oo.png`;

const previewImage = `${BaseURL}/Mechs/HA/Barbarossa/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-6px",
    right: "-23px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Dr. Ta13oo",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    isMegadeus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "2",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BARBAROSSA",
            src: headBarbarossaImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "ANGULAR",
            src: headAngularImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "GENGHIS",
            src: headGenghisImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "HAMMERHEAD",
            src: headHammerheadImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "HIGHBALL",
            src: headHighballImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "8 BALL",
            src: head8BallImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "TAURUS",
            src: headTaurusImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "GUN A",
            src: headGunAImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "GUN B",
            src: headGunBImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BARBAROSSA",
            src: bodyBarbarossaImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE A",
                src: weaponAssaultRifleAImage,
            },
            {
                value: 1,
                label: "ASSAULT RIFLE B",
                src: weaponAssaultRifleBImage,
            },
            {
                value: 2,
                label: "GATLING CANNON A",
                src: weaponGatlingCannonAImage,
            },
            {
                value: 3,
                label: "GATLING CANNON B",
                src: weaponGatlingCannonBImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MORTAR",
            src: secondaryWeaponMortarImage,
        },
        {
            value: 1,
            label: "SIEGE CANNON",
            src: secondaryWeaponSiegeCannonImage,
        },
        {
            value: 2,
            label: "APOCALYPSE RAIL - UNCHARGED",
            src: secondaryWeaponApocalypseRailUnchargedImage,
        },
        {
            value: 3,
            label: "APOCALYPSE RAIL - CHARGED",
            src: secondaryWeaponApocalypseRailChargedImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "MORTAR",
            frontSrc: rearMountMortarImage,
        },
        {
            value: 1,
            label: "SIEGE CANNON",
            frontSrc: rearMountSiegeCannonImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FLAK BATTERIES",
            frontSrc: accessoryFlakBatteriesImage,
        },
        {
            value: 1,
            label: "GLOW - APOCAPLYSE RAIL CHARGED",
            frontSrc: glowApocalypseRailChargedImage,
        },
        {
            value: 2,
            label: "GLOW - HIGHBALL",
            frontSrc: glowHighballImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "HONORS",
            src: accessoryHonorsImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "RIGHT MOUNT",
            value: "rearMount",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "LEFT MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "RIGHT MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "LEFT MOUNT",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
