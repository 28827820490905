import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodySaladinImage = `${BaseURL}/Mechs/HA/Saladin/Chassis.png`;

// REAR MOUNT
const rearMountShieldGeneratorImage = `${BaseURL}/Mechs/HA/Saladin/rear mounts/Rear - Shield Generator.png`;
const rearMountMissileRack1Image = `${BaseURL}/Mechs/HA/Saladin/rear mounts/Rear - Missile Rack x1.png`;
const rearMountMissileRack2Image = `${BaseURL}/Mechs/HA/Saladin/rear mounts/Rear - Missile Rack x2.png`;

// HEADS
const headSaladinImage = `${BaseURL}/Mechs/HA/Saladin/heads/Head - Saladin.png`;
const headKazuImage = `${BaseURL}/Mechs/HA/Saladin/heads/Head - Kazu.png`;
// PREMIUM
const head8ballImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - 8 Ball.png`;
const headAngularImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Angular.png`;
const headBunkerImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Bunker.png`;
const headCalibanImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Caliban.png`;
const headCyclopsImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Cyclops.png`;
const headFactoryStandardImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Factory Standard.png`;
const headHornedImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Horned Hair.png`;
const headJudicatorImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Judicator.png`;
const headLaborerImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Malice.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Nelson.png`;
const headPaladinImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Paladin.png`;
const headPredatorImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Predator.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Rain Transformed Hair.png`;
const headRumbleImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Rumble.png`;
const headScarabImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Scarab.png`;
const headSkullImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Skull.png`;
const headSlipstreamImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Slipstream.png`;
const headTalonImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Talon.png`;
const headVanguardImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Vanguard.png`;
const headVisionaryImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Visionary.png`;
const headWhiteWitchImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - White Witch.png`;
const headScopehoundImage = `${BaseURL}/Mechs/HA/Saladin/heads/premium/Head - Scopehound.png`;

// GLOW
// CHASSIS
const glowChassisImage = `${BaseURL}/Mechs/HA/Saladin/glows/chassis/Glow - Chassis.png`;
const glowChassiShieldImage = `${BaseURL}/Mechs/HA/Saladin/glows/chassis/Glow - Chassis - Tower Shield.png`;
const glowChassisNoHeadImage = `${BaseURL}/Mechs/HA/Saladin/glows/chassis/Glow - Chassis No Head.png`;
const glowHandImage = `${BaseURL}/Mechs/HA/Saladin/glows/chassis/Glow - Hand Only.png`;
const glowHeadImage = `${BaseURL}/Mechs/HA/Saladin/glows/chassis/Glow - Saladin Head.png`;
const glowTowerShieldImage = `${BaseURL}/Mechs/HA/Saladin/glows/chassis/Glow - Tower Shield.png`;
// SHIELD
const glowShieldDomeImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Shield Dome.png`;
const glowShieldImpactsImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Shield Impacts.png`;
const glowAegishjalmurImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Aegishjalmur.png`;
const glowFUImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - FU.png`;
const glowHamsaImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Hamsa.png`;
const glowMagicCircleImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Magic Circle.png`;
const glowSolomonImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Solomon.png`;
const glowAbsoluteTerrorImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Absolute Terror.png`;
const glowSTOPImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - STOP.png`;
const glowReverseImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Reverse.png`;

// WEAPON
const weaponARImage = `${BaseURL}/Mechs/HA/Saladin/weapons/Weapon - Assault Rifle.png`;
const weaponAssaultCannonImage = `${BaseURL}/Mechs/HA/Saladin/weapons/Weapon - Assault Cannon.png`;
const weaponMissileLauncherImage = `${BaseURL}/Mechs/HA/Saladin/weapons/Weapon - Missile Launcher.png`;
const weaponBucklerImage = `${BaseURL}/Mechs/HA/Saladin/weapons/Weapon - Buckler.png`;
const weaponTowerShieldImage = `${BaseURL}/Mechs/HA/Saladin/weapons/Weapon - Tower Shield.png`;

const coreImage = `${BaseURL}/Mechs/HA/Saladin/Saladin (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/HA/Saladin/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "59px",
    right: "31px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SALADIN",
            src: headSaladinImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "ANGULAR",
            src: headAngularImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "BUNKER",
            src: headBunkerImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "PALADIN",
            src: headPaladinImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "PREDATOR",
            src: headPredatorImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "SCARAB",
            src: headScarabImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "SKULL",
            src: headSkullImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "TALON",
            src: headTalonImage,
            isPremium: true,
        },
        {
            value: 24,
            label: "VANGUARD",
            src: headVanguardImage,
            isPremium: true,
        },
        {
            value: 25,
            label: "VISIONARY",
            src: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 26,
            label: "WHITE WITCH",
            src: headWhiteWitchImage,
            isPremium: true,
        },
        {
            value: 27,
            label: "8 BALL",
            src: head8ballImage,
            isPremium: true,
        },
        {
            value: 28,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SALADIN",
            src: bodySaladinImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE",
                src: weaponARImage,
            },
            {
                value: 1,
                label: "ASSAULT CANNON",
                src: weaponAssaultCannonImage,
            },
            {
                value: 2,
                label: "MISSILE LAUNCHER",
                src: weaponMissileLauncherImage,
            },
            {
                value: 3,
                label: "BUCKLER",
                src: weaponBucklerImage,
            },
            {
                value: 4,
                label: "TOWER SHIELD",
                src: weaponTowerShieldImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ALL",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS",
            src: glowChassisNoHeadImage,
        },
        {
            value: 2,
            label: "CHASSIS + TOWER SHIELD",
            src: glowChassiShieldImage,
            frontSrc: glowTowerShieldImage,
        },
        {
            value: 3,
            label: "HAND ONLY",
            src: glowHandImage,
        },
        {
            value: 4,
            label: "HEAD ONLY",
            src: glowHeadImage,
        },
        {
            value: 5,
            label: "TOWER SHIELD ONLY",
            frontSrc: glowTowerShieldImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SHIELD GENERATOR",
            src: rearMountShieldGeneratorImage,
        },
        {
            value: 1,
            label: "MISSILE RACK X1",
            frontSrc: rearMountMissileRack1Image,
        },
        {
            value: 2,
            label: "MISSILE RACK X2",
            frontSrc: rearMountMissileRack2Image,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "SOLOMON",
            foremostSrc: glowSolomonImage,
        },
        {
            value: 1,
            label: "HAMSA",
            foremostSrc: glowHamsaImage,
        },
        {
            value: 2,
            label: "MAGIC CIRCLE",
            foremostSrc: glowMagicCircleImage,
        },
        {
            value: 3,
            label: "AEGISHJALMUR",
            foremostSrc: glowAegishjalmurImage,
        },
        {
            value: 4,
            label: "ABSOLUTE TERROR",
            foremostSrc: glowAbsoluteTerrorImage,
        },
        {
            value: 5,
            label: "STOP",
            foremostSrc: glowSTOPImage,
        },
        {
            value: 6,
            label: "REVERSE",
            foremostSrc: glowReverseImage,
        },
        {
            value: 7,
            label: "FU",
            foremostSrc: glowFUImage,
        },
        {
            value: 8,
            label: "SHIELD DOME",
            frontSrc: glowShieldDomeImage,
        },
        {
            value: 9,
            label: "SHIELD DOME - IMPACTS",
            frontSrc: glowShieldImpactsImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "SHIELD GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "CHASSIS GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "SHIELD GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "SHIELD GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "SHIELD GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "SHIELD GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "CHASSIS GLOW",
        "accessory1": "SHIELD GLOW 1",
        "accessory2": "SHIELD GLOW 2",
        "accessory3": "SHIELD GLOW 3",
        "accessory4": "SHIELD GLOW 4",
    },
};
