import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Fires-of-Retribution`;

// BODY
const bodyChassisImage = `${imagePath}/chassis/chassis-combined.png`;
const bodyChassisLegsImage = `${imagePath}/chassis/legs.png`;

// HEADS
const headFiresOfRetributionImage = `${imagePath}/head/head.png`;

// WEAPONS
// LEFT
const weaponMuramasaImage = `${imagePath}/LeftWeapon/muramasa_blade.png`;
const weaponMuramasaBackImage = `${imagePath}/LeftWeapon/muramasa_back.png`;

// RIGHT
const weaponRedshiftBackImage = `${imagePath}/RightWeapon/redshift_back.png`;

// REAR MOUNT
const wingsUpImage = `${imagePath}/Rear Mount/wingsup.png`;
const wingsDownImage = `${imagePath}/Rear Mount/wingsdown.png`;

// GLOWS
const glowChassis_glowImage = `${imagePath}/Glows/chassis_glow.png`;
const glowDangerzone_chassis_glowImage = `${imagePath}/Glows/dangerzone_chassis_glow.png`;
const glowDangerzone_glowImage = `${imagePath}/Glows/dangerzone_glow.png`;
const glowWings_glowImage = `${imagePath}/Glows/wings_glow.png`;
const glowMuramasa_glowImage = `${imagePath}/Glows/muramasa_glow.png`;
const glowRedshift_glowImage = `${imagePath}/Glows/redshift_glow.png`;
const glowMuramasaRedshift_glowImage = `${imagePath}/Glows/muramasa redshift_glow.png`;

// ACCESSORIES
const accessoryTailImage = `${imagePath}/Accessories/tail.png`;
const accessoryLeft_sheathImage = `${imagePath}/Accessories/left_sheath.png`;
const accessoryRight_sheathImage = `${imagePath}/Accessories/right_sheath.png`;
const accessorySheathsImage = `${imagePath}/Accessories/sheaths.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/CorePaintJob_normal.png`;
const core2Image = `${imagePath}/CorePaintJob_dangerzone.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-13px",
    right: "22px",
};

export default {
    isMegadeusOmega: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Fliren",
                url: "https://linktr.ee/fliren?utm_source=linktree_profile_share&ltsid=1a59ce3f-a79f-40e0-8035-d8fdd74aa3c4",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Fliren",
                url: "https://linktr.ee/fliren?utm_source=linktree_profile_share&ltsid=1a59ce3f-a79f-40e0-8035-d8fdd74aa3c4",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "8",
        "accessory2Value": "NONE",
        "accessory3Value": "9",
        "accessory4Value": "12",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "FIRES OF RETRIBUTION",
            src: headFiresOfRetributionImage,
            originalArtCredit: {
                prefixText: "Sprite by ",
                title: "Fliren",
                url: "https://linktr.ee/fliren?utm_source=linktree_profile_share&ltsid=1a59ce3f-a79f-40e0-8035-d8fdd74aa3c4",
            },
        },
    ],
    chassis: [
        {
            value: 0,
            label: "FIRES OF RETRIBUTION",
            src: bodyChassisImage,
            backSrc: bodyChassisLegsImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Design and artwork by ",
                title: "Astron",
                url: "https://linktr.ee/astron_owo?utm_source=linktree_profile_share&ltsid=e6d9ac39-ba62-405a-ac94-7a366162b70b",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "MURAMASA",
                src: weaponMuramasaImage,
                backSrc: weaponMuramasaBackImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "REDSHIFT",
            backSrc: weaponRedshiftBackImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: 'WINGS UP',
            src: wingsUpImage,
        },
        {
            value: 1,
            label: 'WINGS DOWN',
            src: wingsDownImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS GLOW",
            src: glowChassis_glowImage,
        },
        {
            value: 1,
            label: "WINGS GLOW",
            backSrc: glowWings_glowImage,
        },
        {
            value: 2,
            label: "CHASSIS + WINGS GLOW",
            src: glowChassis_glowImage,
            backSrc: glowWings_glowImage,
        },
        {
            value: 3,
            label: "DANGERZONE GLOW",
            frontSrc: glowDangerzone_glowImage,
        },
        {
            value: 4,
            label: "DANGERZONE + CHASSIS GLOW",
            src: glowDangerzone_chassis_glowImage,
        },
        {
            value: 5,
            label: "FULL DANGERZONE",
            src: glowDangerzone_chassis_glowImage,
            backSrc: glowWings_glowImage,
        },
        {
            value: 6,
            label: "MURAMASA GLOW",
            frontSrc: glowMuramasa_glowImage,
        },
        {
            value: 7,
            label: "REDSHIFT GLOW",
            frontSrc: glowRedshift_glowImage,
        },
        {
            value: 8,
            label: "MURAMASA + REDSHIFT GLOW",
            frontSrc: glowMuramasaRedshift_glowImage,
        },
        {
            value: 9,
            label: "ACCESSORY TAIL",
            backSrc: accessoryTailImage,
        },
        {
            value: 10,
            label: "ACCESSORY LEFT SHEATH",
            src: accessoryLeft_sheathImage,
        },
        {
            value: 11,
            label: "ACCESSORY RIGHT SHEATH",
            src: accessoryRight_sheathImage,
        },
        {
            value: 12,
            label: "ACCESSORY SHEATHS",
            src: accessorySheathsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS + ACCESSORIES",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "L HAND",
            value: "weapon",
        },
        {
            label: "R HAND",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L HAND",
        "secondaryWeapon": "R HAND",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
