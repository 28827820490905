import {
    brandList as mechNPCBrandList,
    npcLayerOrder,
} from "../LayerData/MechNPCLayerOrder";
import {
    brandList as mechBrandedBrandList,
    brandedLayerOrder,
} from "../LayerData/MechBrandedLayerOrder";
import {
    brandList as fantasyHeroBrandList,
    heroLayerOrder,
} from "../LayerData/FantasyHeroLayerOrder";
import {
    brandList as fantasyNPCBrandList,
    npcLayerOrder as fantasyNPCLayerOrder,
} from "../LayerData/FantasyNPCLayerOrder";

import Mechs from "./../data/Mechs-GMS";
import MechsCustomPlus from "./../data/Mechs-622";

const mechBrandList = Object.assign({},
    mechNPCBrandList,
    mechBrandedBrandList,
);

const fantasyBrandList = Object.assign({},
    fantasyNPCBrandList,
    fantasyHeroBrandList,
);

export const nameRegex = /\/|\(|\)/gm;

export function safeName(string) {
    return string.replace(/[^a-zA-Z0-9-_]/g, "-").trim();
}

export function sortUserSubmissionContent(a, b) {
    return (
        b.variants[0]["dateAdded"].localeCompare(a.variants[0]["dateAdded"])
        || a.variants[0]["unitNumber"].localeCompare(b.variants[0]["unitNumber"])
        || a.variants[0]["variant"].localeCompare(b.variants[0]["variant"])
    );
}

export function FantasyHeroPrintAnalytics(unit, ReactGA) {
    function analyticsPartUsageEvent(label) {
        ReactGA.event({
            category: "Options",
            action: "Part",
            label: safeName(label),
        });
    }

    if (!!fantasyNPCBrandList[unit.brandValue]) {
        fantasyNPCLayerOrder.forEach((layer) => {
            if (unit[layer.name] !== "NONE") {
                analyticsPartUsageEvent(`${unit.brandValue} - ${fantasyBrandList[unit.brandValue][unit.frameValue].label} - ${layer.slot.toUpperCase()} - ${fantasyBrandList[unit.brandValue][unit.frameValue][layer.slot][unit[layer.name]].label}`);
            }
        });
    } else {
        heroLayerOrder.forEach((layer) => {
            if (unit[layer.name] !== "NONE") {
                analyticsPartUsageEvent(`${unit.brandValue} - ${fantasyBrandList[unit.brandValue][unit.frameValue].label} - ${layer.slot.toUpperCase()} - ${fantasyBrandList[unit.brandValue][unit.frameValue][layer.slot][unit[layer.name]].label}`);
            }
        });
    }
}

export function CustomMechPrintAnalytics(unit, ReactGA) {
    function analyticsPartUsageEvent(label) {
        ReactGA.event({
            category: "Options",
            action: "Part",
            label: safeName(label),
        });
    }

    if (unit.accessory1Value !== "NONE") {
        analyticsPartUsageEvent(`GMS - ACCESSORY - ${Mechs.accessory[unit.accessory1Value].label}`);
    }

    if (unit.accessory2Value !== "NONE") {
        analyticsPartUsageEvent(`GMS - ACCESSORY - ${Mechs.accessory[unit.accessory2Value].label}`);
    }

    if (unit.accessory3Value !== "NONE") {
        analyticsPartUsageEvent(`GMS - ACCESSORY - ${Mechs.accessory[unit.accessory3Value].label}`);
    }

    if (unit.accessory4Value !== "NONE") {
        analyticsPartUsageEvent(`GMS - ACCESSORY - ${Mechs.accessory[unit.accessory4Value].label}`);
    }

    if (unit.weaponValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - WEAPON - ${Mechs.weapon[unit.stanceValue][unit.weaponValue].label}`);
    }

    if (unit.secondaryWeaponValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - AUX WEAPON - ${Mechs.secondaryWeapon[unit.secondaryWeaponValue].label}`);
    }

    if (unit.rearMountValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - REAR MOUNT - ${Mechs.rearMount[unit.rearMountValue].label}`);
    }

    if (unit.legsValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - LEGS - ${Mechs.legs[unit.legsValue].label}`);
    }

    if (unit.armLeftValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - LEFT ARM - ${Mechs.arms[unit.armLeftValue].label}`);
    }

    if (unit.chassisValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - CHASSIS - ${Mechs.chassis[unit.chassisValue].label}`);
    }

    if (unit.headValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - HEAD - ${Mechs.head[unit.headValue].label}`);
    }

    if (unit.armRightValue !== "NONE") {
        analyticsPartUsageEvent(`GMS - RIGHT ARM - ${Mechs.arms[unit.armRightValue].label}`);
    }
}

export function CustomPlusMechPrintAnalytics(unit, ReactGA) {
    function analyticsPartUsageEvent(label) {
        ReactGA.event({
            category: "Options",
            action: "Part",
            label: safeName(label),
        });
    }

    if (unit.accessory1Value !== "NONE") {
        analyticsPartUsageEvent(`622 - ACCESSORY - ${MechsCustomPlus.accessory[unit.accessory1Value].label}`);
    }

    if (unit.accessory2Value !== "NONE") {
        analyticsPartUsageEvent(`622 - ACCESSORY - ${MechsCustomPlus.accessory[unit.accessory2Value].label}`);
    }

    if (unit.accessory3Value !== "NONE") {
        analyticsPartUsageEvent(`622 - ACCESSORY - ${MechsCustomPlus.accessory[unit.accessory3Value].label}`);
    }

    if (unit.accessory4Value !== "NONE") {
        analyticsPartUsageEvent(`622 - ACCESSORY - ${MechsCustomPlus.accessory[unit.accessory4Value].label}`);
    }

    if (unit.weaponValue !== "NONE") {
        analyticsPartUsageEvent(`622 - WEAPON - ${MechsCustomPlus.weapon[unit.stanceValue][unit.weaponValue].label}`);
    }

    if (unit.secondaryWeaponValue !== "NONE") {
        analyticsPartUsageEvent(`622 - AUX WEAPON - ${MechsCustomPlus.secondaryWeapon[unit.secondaryWeaponValue].label}`);
    }

    if (unit.rearMountValue !== "NONE") {
        analyticsPartUsageEvent(`622 - REAR MOUNT - ${MechsCustomPlus.rearMount[unit.rearMountValue].label}`);
    }

    if (unit.legsValue !== "NONE") {
        analyticsPartUsageEvent(`622 - LEGS - ${MechsCustomPlus.legs[unit.legsValue].label}`);
    }

    if (unit.armLeftValue !== "NONE") {
        analyticsPartUsageEvent(`622 - LEFT ARM - ${MechsCustomPlus.leftArm[unit.armLeftValue].label}`);
    }

    if (unit.chassisValue !== "NONE") {
        analyticsPartUsageEvent(`622 - CHASSIS - ${MechsCustomPlus.chassis[unit.chassisValue].label}`);
    }

    if (unit.headValue !== "NONE") {
        analyticsPartUsageEvent(`622 - HEAD - ${MechsCustomPlus.head[unit.headValue].label}`);
    }

    if (unit.armRightValue !== "NONE") {
        analyticsPartUsageEvent(`622 - RIGHT ARM - ${MechsCustomPlus.rightArm[unit.armRightValue].label}`);
    }
}

export function BrandedMechPrintAnalytics(unit, ReactGA) {
    function analyticsPartUsageEvent(label) {
        ReactGA.event({
            category: "Options",
            action: "Part",
            label: safeName(label),
        });
    }

    brandedLayerOrder.forEach((layer) => {
        if (unit[layer.name] !== "NONE") {
            if (layer.stanceValue) {
                analyticsPartUsageEvent(`${unit.brandValue} - ${mechBrandList[unit.brandValue][unit.frameValue].label} - ${layer.slot.toUpperCase()} - ${mechBrandList[unit.brandValue][unit.frameValue][layer.slot][unit[layer.stanceValue]][unit[layer.name]].label}`);
            } else {
                analyticsPartUsageEvent(`${unit.brandValue} - ${mechBrandList[unit.brandValue][unit.frameValue].label} - ${layer.slot.toUpperCase()} - ${mechBrandList[unit.brandValue][unit.frameValue][layer.slot][unit[layer.name]].label}`);
            }
        }
    });
}


export function NPCMechPrintAnalytics(unit, ReactGA) {
    function analyticsPartUsageEvent(label) {
        ReactGA.event({
            category: "Options",
            action: "Part",
            label: safeName(label),
        });
    }

    npcLayerOrder.forEach((layer) => {
        if (unit[layer.name] !== "NONE" && !!mechBrandList?.[unit?.brandValue]?.[unit?.frameValue]?.[layer?.slot]?.[unit?.[layer?.name]]) {
            analyticsPartUsageEvent(`${unit.brandValue} - ${mechBrandList[unit.brandValue][unit.frameValue].label} - ${layer.slot.toUpperCase()} - ${mechBrandList[unit.brandValue][unit.frameValue][layer.slot][unit[layer.name]].label}`);
        }
    });
}
