
import styled from "styled-components";

const BorderWindowContainerWrapper = styled.div`
    background: ${props => `linear-gradient(to bottom right, ${props.theme.categoryPrimaryColor} 0%, ${props.theme.categorySecondaryColor} 100%)`};
    border-color: ${props => props.theme.categoryPrimaryColor};
    border-radius: 10px;
    border-style: outset;
    border-width: 4px;
    box-shadow: 0 0 2px 1px #000;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    padding: 20px;
    right: 0;
    left: 0;
    top: 0;
    position: fixed;
    text-align: center;
    z-index: 300;
    max-width: 350px;

    @media print {
        display: none;
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        max-width: 85%;
        margin-top: 150px;
    }
`;

BorderWindowContainerWrapper.displayName = "BorderWindowContainerWrapper";

export default BorderWindowContainerWrapper;
