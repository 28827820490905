import BaseURL from "./images/Hosting-BaseURL";
import { shadowDetailsNone } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/BDF-Akinaka`;

// BODY
const droneSaturationImage = `${imagePath}/drone/Drone Center - Saturation Drone.png`;
const droneYazataImage = `${imagePath}/drone/Drone Center - Yazata Drone.png`;

// GLOW
const glowSaturationImage = `${imagePath}/drone/Drone Center Glow - Saturation Drone.png`;
const glowYazataImage = `${imagePath}/drone/Drone Center Glow - Yazata Drone.png`;

const previewImage = `${imagePath}/drone/preview.png`;

const originalArtCredit = {
    prefixText: "Commissioned by Clem Cuddlecakes, original by",
    title: "Zerovirus",
    url: "https://www.artstation.com/drazelic",
};

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SATURATION",
            src: glowSaturationImage,
        },
        {
            value: 1,
            label: "YAZATA",
            src: glowYazataImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SATURATION",
            src: droneSaturationImage,
            originalArtCredit,
            shadowDetails,
        },
        {
            value: 1,
            label: "YAZATA",
            src: droneYazataImage,
            originalArtCredit,
            shadowDetails,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
        {
            label: "DRONE",
            value: "chassis",
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "DRONE",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
