import styled from "styled-components";

const OpaqueWindowContent = styled.div`
    align-content: flex-start;
    align-items: center;
    color: white;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: left;
    max-height: 55vh;
    margin-top: 5px;

    p, li, h2, h3, h4 {
        user-select: text;
    }

    a {
        color: orange;
        font-weight: bold;
    }

    overflow-y: scroll;

    /* Firefox styling  */
    scrollbar-color: #333 transparent;

    /* All the Rest */
    ::-webkit-scrollbar {
        width: 20px;
    }
    ::-webkit-scrollbar-track {
        &:horizontal {
            display: none;
        }
    }
    ::-webkit-scrollbar-track-piece {
        color: #333;
        box-shadow: inset 10px #333;
    }
    ::-webkit-scrollbar-thumb {
        background: #333;

        &:vertical {
            border: 2px outset #222;
        }
        &:horizontal {
            border: 2px outset #222;
        }
    }
`;

OpaqueWindowContent.displayName = "OpaqueWindowContent";

export default OpaqueWindowContent;
