import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyPegasusImage = `${BaseURL}/Mechs/Horus/Pegasus/Chassis.png`;

// HEADS
const headPegasusImage = `${BaseURL}/Mechs/Horus/Pegasus/heads/Head 1 - Pegasus.png`;
// PREMIUM
const headVanguardImage = `${BaseURL}/Mechs/Horus/Pegasus/heads/premium/Head 2 - Vanguard.png`;
const headCarnivoreImage = `${BaseURL}/Mechs/Horus/Pegasus/heads/premium/Head 3 - Carnivore.png`;
const headBallisticsImage = `${BaseURL}/Mechs/Horus/Pegasus/heads/premium/Head 4 - Ballistics.png`;
const headUshtabiImage = `${BaseURL}/Mechs/Horus/Pegasus/heads/premium/Head 5 - Ushtabi.png`;
const headPlateImage = `${BaseURL}/Mechs/Horus/Pegasus/heads/premium/Head 6 - Plate.png`;
const headCockpitImage = `${BaseURL}/Mechs/Horus/Pegasus/heads/premium/Head - Cockpit.png`;

// MAIN WEAPONS
const weaponSpinalSmartgunImage = `${BaseURL}/Mechs/Horus/Pegasus/weapons/Main Weapon - Spinal Smartgun.png`;
const weaponDualSpinalSmartgunImage = `${BaseURL}/Mechs/Horus/Pegasus/weapons/Main Weapon - Dual Spinal Smartgun.png`;
const weaponMissilesImage = `${BaseURL}/Mechs/Horus/Pegasus/weapons/Main Weapon - Missiles.png`;
const weaponMimiGunTechImage = `${BaseURL}/Mechs/Horus/Pegasus/weapons/Main Weapon - Mimic Gun - Tech.png`;
const weaponMimiGunGeigerImage = `${BaseURL}/Mechs/Horus/Pegasus/weapons/Main Weapon - Mimic Gun Geiger.png`;
const weaponMimiGunRingImage = `${BaseURL}/Mechs/Horus/Pegasus/weapons/Main Weapon - Mimic Gun - Ring.png`;

// REAR WEAPONS
const secondaryWeaponUshtabiImage = `${BaseURL}/Mechs/Horus/Pegasus/weapons/Rear Weapon - Ushtabi.png`;

// REAR MOUNT
const rearMountTailImage = `${BaseURL}/Mechs/Horus/Pegasus/rear mount/Tail.png`;
const rearMountTailAxeImage = `${BaseURL}/Mechs/Horus/Pegasus/rear mount/Tail Axe.png`;

// ACCESSORIES
const accessoriesGlowHeadUshtabiImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Head 5 - Ushtabi Glow.png`;
const accessoriesGlowRearUshtabiImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Rear Weapon - Ushtabi Glow.png`;
const accessoriesGlowHeadRearUshtabiImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Glow - Ushtabi Head and Rear.png`;
const accessoriesGlowHeadUshtabiMimicImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Glow - Ushtabi Head and Mimic.png`;
const accessoriesGlowMimicImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Main Weapon - Mimic Gun - Tech Glow.png`;
const accessoriesGlowMimicRingImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Main Weapon - Mimic Gun - Ring Glow.png`;
const accessoriesGlowHeadUshtabiMimicRingImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Glow - Ushtabi Head and Mimic Ring.png`;
const accessoriesBackCLosedImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Accessory - Back Closed.png`;
// LEGENDARY
const accessoriesArmorImage = `${BaseURL}/Mechs/Horus/Pegasus/accessories/Accessory - Armor.png`;

const coreImage = `${BaseURL}/Mechs/Horus/Pegasus/Pegasus (by Hit-Monkey).png`;

const previewImage = `${BaseURL}/Mechs/Horus/Pegasus/preview.png`;

const quadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "99px",
    right: "34px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Hit-Monkey",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "PEGASUS",
            src: headPegasusImage,
        },
        {
            value: 1,
            label: "USHABTI",
            src: headUshtabiImage,
        },
        {
            value: 2,
            label: "VANGUARD",
            src: headVanguardImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CARNIVORE",
            src: headCarnivoreImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "BALLISTICS",
            src: headBallisticsImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "PLATE",
            src: headPlateImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "PEGASUS",
            src: bodyPegasusImage,
            shadowDetails: quadLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SPINAL SMARTGUN",
                frontSrc: weaponSpinalSmartgunImage,
            },
            {
                value: 1,
                label: "DUAL SPINAL SMARTGUN",
                frontSrc: weaponDualSpinalSmartgunImage,
            },
            {
                value: 2,
                label: "MISSILES",
                frontSrc: weaponMissilesImage,
            },
            {
                value: 3,
                label: "MIMIC GUN - TECH",
                frontSrc: weaponMimiGunTechImage,
            },
            {
                value: 4,
                label: "MIMIC GUN - GEIGER",
                frontSrc: weaponMimiGunGeigerImage,
            },
            {
                value: 5,
                label: "MIMIC GUN - RING",
                frontSrc: weaponMimiGunRingImage,
            },
            {
                value: 6,
                label: "BACK CLOSED",
                frontSrc: accessoriesBackCLosedImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "USHABTI",
            src: secondaryWeaponUshtabiImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "TAIL",
            src: rearMountTailImage,
        },
        {
            value: 1,
            label: "TAIL AXE",
            src: rearMountTailAxeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "MIMIC GUN - TECH",
            frontSrc: accessoriesGlowMimicImage,
        },
        {
            value: 1,
            label: "MIMIC GUN - RING",
            frontSrc: accessoriesGlowMimicRingImage,
        },
        {
            value: 2,
            label: "USHABTI REAR",
            frontSrc: accessoriesGlowRearUshtabiImage,
        },
        {
            value: 3,
            label: "USHABTI HEAD",
            frontSrc: accessoriesGlowHeadUshtabiImage,
        },
        {
            value: 4,
            label: "USHABTI HEAD + REAR",
            frontSrc: accessoriesGlowHeadRearUshtabiImage,
        },
        {
            value: 5,
            label: "MIMIC GUN - TECH + USHABTI HEAD",
            frontSrc: accessoriesGlowHeadUshtabiMimicImage,
        },
        {
            value: 6,
            label: "MIMIC GUN - RING + USHABTI HEAD",
            frontSrc: accessoriesGlowHeadUshtabiMimicRingImage,
        },
        {
            value: 7,
            label: "ARMOR - ACCESSORY",
            src: accessoriesArmorImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "FRONT WEAPON",
            value: "weapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "REAR WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "FRONT WEAPON",
        "secondaryWeapon": "REAR WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
