import blackWitch from "./Mechs-Black-Witch";
import whiteWitch from "./Mechs-White-Witch";
import atlas from "./Mechs-Atlas";
import metalmark from "./Mechs-Metalmark";
import blackmark from "./Mechs-Blackmark";
import metalmark2 from "./Mechs-Metalmark-2";
import metalmarkVanguard from "./Mechs-Metalmark-Vanguard";
import monarch from "./Mechs-Monarch";
import deathsHead from "./Mechs-Deaths-Head";
import mourningCloak from "./Mechs-Mourning Cloak";
import duskWing from "./Mechs-Dusk-Wing";
import swallowtail from "./Mechs-Swallowtail";
import emperor from "./Mechs-Emperor";
import orchis from "./Mechs-Orchis";
import viceroy from "./Mechs-SSC-Viceroy";
import MechsSwallowtail2 from "./Mechs-Swallowtail-2";

export default [
    Object.assign({ value: 0, label: "ATLAS" }, atlas),
    Object.assign({ value: 1, label: "BLACK WITCH" }, blackWitch),
    Object.assign({ value: 2, label: "DEATHS HEAD" }, deathsHead),
    Object.assign({ value: 3, label: "DUSK WING" }, duskWing),
    Object.assign({ value: 4, label: "EMPEROR" }, emperor),
    Object.assign({ value: 5, label: "METALMARK 2.0" }, metalmark2),
    Object.assign({ value: 6, label: "BLACKMARK", isPremium: true, isLegendary: true }, blackmark),
    Object.assign({ value: 7, label: "METALMARK 1.0" }, metalmark),
    Object.assign({ value: 8, label: "METALMARK - VANGUARD", isPremium: true, isLegendary: true }, metalmarkVanguard),
    Object.assign({ value: 9, label: "MONARCH" }, monarch),
    Object.assign({ value: 10, label: "MOURNING CLOAK" }, mourningCloak),
    Object.assign({ value: 11, label: "ORCHIS" }, orchis),
    Object.assign({ value: 12, label: "SWALLOWTAIL" }, swallowtail),
    Object.assign({ value: 13, label: "SWALLOWTAIL 2.0" }, MechsSwallowtail2),
    Object.assign({ value: 14, label: "VICEROY" }, viceroy),
    Object.assign({ value: 15, label: "WHITE WITCH" }, whiteWitch),
];
