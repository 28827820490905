import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";

const imagePath = `${BaseURL}/Mechs/Custom/MO_S Frankenstein`;

// BODY
const bodyFrankensteinImage = `${imagePath}/Chassis.png`;

// HEADS
const headFrankieImage = `${imagePath}/heads/Head - Frankie.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBulkheadImage = `${imagePath}/heads/Head - Bulkhead.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - Flight Type Nelson.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headRamshackleImage = `${imagePath}/heads/Head - Ramshackle.png`;
const headRumbleImage = `${imagePath}/heads/Head - Rumble.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull3.png`;
const headTalonImage = `${imagePath}/heads/Head - Talon.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const head8BallImage = `${imagePath}/heads/Head - 8ball.png`;
const head8Ball2Image = `${imagePath}/heads/Head - 8ball 2.png`;
const headGeneralFrankyImage = `${imagePath}/heads/Frankie_Head_General Franky.png`;

// WEAPONS
// LEFT
const weaponsLeftClimbingHookImage = `${imagePath}/weapons/left/Left Arm - Climbing Hook.png`;
const weaponsLeftExacerbatorGaunletImage = `${imagePath}/weapons/left/Left Arm - Exacerbator Gauntlet.png`;
const weaponsLeftExacerbatorGaunletOpenImage = `${imagePath}/weapons/left/Left Arm - Exacerbator Gauntlet Open.png`;
const weaponsLeftFrankieImage = `${imagePath}/weapons/left/Frankie_Weapons Left_Frankie.png`;
// RIGHT
const weaponsRightClimbingHookImage = `${imagePath}/weapons/right/Right Arm - Climbing Hook.png`;
const weaponsRightGencolaLauncherImage = `${imagePath}/weapons/right/Right Arm - GENCOLA Launcher.png`;
const weaponsRightPumpShottyImage = `${imagePath}/weapons/right/Right Arm - Pump Shotty.png`;

// REAR MOUNT
const rearMountExhaustTubesImage = `${imagePath}/rear mounts/Rear - Exhaust Tubes.png`;
const rearMountJumpJetsImage = `${imagePath}/rear mounts/Rear - Jump Jets.png`;

// GLOWS
const glowEyeImage = `${imagePath}/glows/Glows Top - Eye.png`;
const glowEGauntletOpenImage = `${imagePath}/glows/Glow - Exacerbator Gauntlet Open.png`;
const glowEGuantletImage = `${imagePath}/glows/Glow - Exacerbator Gauntlet.png`;
const glowJumpJetsImage = `${imagePath}/glows/Glows Mid - Jump Jets.png`;
const glowFrankieSmokeImage = `${imagePath}/glows/Frankie_Accessory_Smoke.png`;

const previewImage = `${imagePath}/preview.png`;
const core1Image = `${imagePath}/core-frankenstein-AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "64px",
    right: "54px",
};

export default {
    previewImg: previewImage,
    isMegadeus: true,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "FRANKIE",
            src: headFrankieImage,
        },
        {
            value: 1,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 2,
            label: "BULKHEAD",
            src: headBulkheadImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 6,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 7,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 8,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 9,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 10,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 11,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 12,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 13,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 14,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 15,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 16,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 17,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 18,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 19,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 20,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 21,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 22,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 23,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 24,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 25,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 26,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 27,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 28,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 29,
            label: "RAMSHACKLE",
            src: headRamshackleImage,
        },
        {
            value: 30,
            label: "RUMBLE",
            src: headRumbleImage,
        },
        {
            value: 31,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 32,
            label: "TALON",
            src: headTalonImage,
        },
        {
            value: 33,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 34,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 35,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 36,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 37,
            label: "8 BALL - 2",
            src: head8Ball2Image,
        },
        {
            value: 38,
            label: "GENERAL FRANKY",
            src: headGeneralFrankyImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MO&S FRANKENSTEIN",
            src: bodyFrankensteinImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Vox ",
                title: "Link in News",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLIMBING HOOK",
                src: weaponsLeftClimbingHookImage,
            },
            {
                value: 1,
                label: "EXACERBATOR GAUNTLET",
                src: weaponsLeftExacerbatorGaunletImage,
            },
            {
                value: 2,
                label: "EXACERBATOR GAUNTLET - OPEN",
                src: weaponsLeftExacerbatorGaunletOpenImage,
            },
            {
                value: 3,
                label: "PILEBUNKER",
                src: weaponsLeftFrankieImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CLIMBING HOOK",
            src: weaponsRightClimbingHookImage,
        },
        {
            value: 1,
            label: "GENCOLA LAUNCHER",
            src: weaponsRightGencolaLauncherImage,
        },
        {
            value: 2,
            label: "PUMP SHOTTY",
            src: weaponsRightPumpShottyImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "EXHAUST TUBES",
            frontSrc: rearMountExhaustTubesImage,
        },
        {
            value: 1,
            label: "JUMP JETS",
            src: rearMountJumpJetsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FRANKIE - EYE",
            frontSrc: glowEyeImage,
        },
        {
            value: 1,
            label: "EXACERBATOR GAUNTLET",
            frontSrc: glowEGuantletImage,
        },
        {
            value: 2,
            label: "EXACERBATOR GAUNTLET - OPEN",
            frontSrc: glowEGauntletOpenImage,
        },
        {
            value: 3,
            label: "JUMP JETS",
            src: glowJumpJetsImage,
        },
        {
            value: 4,
            label: "SMOKE - PILEBUNKER",
            frontSrc: glowFrankieSmokeImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
