import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Pilot 2`;

const bodyMImage =  `${imagePath}/Pilot 2 M.png`;
const bodyFImage =  `${imagePath}/Pilot 2 F.png`;

// HEADS
const headShortHairImage = `${imagePath}/heads/Head - Short Hair.png`;
const headLongHairImage = `${imagePath}/heads/Head - Long Hair.png`;
const headPonytailImage = `${imagePath}/heads/Head - Ponytail.png`;
const headSpikeyImage = `${imagePath}/heads/Head - Spikey Hair.png`;
const headHalfHelmetImage = `${imagePath}/heads/Head - Half Helmet.png`;
const headFullHelmetImage = `${imagePath}/heads/Head - Full Helmet L.png`;
const headFullHelmetRImage = `${imagePath}/heads/Head - Full Helmet R.png`;
const headExoSuitImage = `${imagePath}/heads/Head - Exo Helmet.png`;
const headCowboyImage = `${imagePath}/heads/Head - Cowboy Hat.png`;
const headTruckerImage = `${imagePath}/heads/Head - Trucker Hat.png`;
const head97LImage = `${imagePath}/heads/Helmet - 97 L.png`;
const head97RImage = `${imagePath}/heads/Helmet - 97 R.png`;
const headHoodLImage = `${imagePath}/heads/Helmet - Hood L.png`;
const headHoodRImage = `${imagePath}/heads/Helmet - Hood R.png`;
const headImpLImage = `${imagePath}/heads/Helmet - Imp L.png`;
const headImpRImage = `${imagePath}/heads/Helmet - Imp R.png`;
const headIsaacLImage = `${imagePath}/heads/Helmet - Isaac L.png`;
const headIsaacRImage = `${imagePath}/heads/Helmet - Isaac R.png`;
const headJohnLImage = `${imagePath}/heads/Helmet - John L.png`;
const headJohnRImage = `${imagePath}/heads/Helmet - John R.png`;
const headPilotLImage = `${imagePath}/heads/Helmet - Pilot L.png`;
const headPilotRImage = `${imagePath}/heads/Helmet - Pilot R.png`;
const headReconLImage = `${imagePath}/heads/Helmet - Recon L.png`;
const headReconRImage = `${imagePath}/heads/Helmet - Recon R.png`;
const headRepublicLImage = `${imagePath}/heads/Helmet - Republic L.png`;
const headRepublicRImage = `${imagePath}/heads/Helmet - Republic R.png`;
const headSkullLImage = `${imagePath}/heads/Helmet - Skull L.png`;
const headSkullRImage = `${imagePath}/heads/Helmet - Skull R.png`;
const headSlayerLImage = `${imagePath}/heads/Helmet - Slayer L.png`;
const headSlayerRImage = `${imagePath}/heads/Helmet - Slayer R.png`;

// CHEST
const chestJacketImage = `${imagePath}/chest/Chest - Jacket.png`;
const chestOpenJacketImage = `${imagePath}/chest/Chest - Open Jacket.png`;
const chestLongCoatImage = `${imagePath}/chest/Chest - Long Coat.png`;
const fullBodyArmorImage = `${imagePath}/chest/Full - Body Armor.png`;
const fullFlightSuitImage = `${imagePath}/chest/Full - Flight Suit.png`;
const fullExoSuitImage = `${imagePath}/chest/Full - Exo Suit.png`;
const fullPlugSuitImage = `${imagePath}/chest/Full - Plug Suit.png`;

// SHIRT
const shirtTankTopMImage = `${imagePath}/chest/Shirt - Tank Top M.png`;
const shirtTankTopFImage = `${imagePath}/chest/Shirt - Tank Top F.png`;
const shirtCropTopImage = `${imagePath}/chest/Shirt - Crop Top.png`;
const shirtShortSleeveMImage = `${imagePath}/chest/Shirt - Short Sleeves M.png`;
const shirtShortSleeveFImage = `${imagePath}/chest/Shirt - Short Sleeves F.png`;
const shirtLongSleeveMImage = `${imagePath}/chest/Shirt - Long Sleeves M.png`;
const shirtLongSleeveFImage = `${imagePath}/chest/Shirt - Long Sleeves F.png`;

// LEGS
const legsBodyArmorImage = `${imagePath}/legs/Legs - Body Armor.png`;
const legsFlightSuitImage = `${imagePath}/legs/Legs - Flight Suit.png`;
const legsExoSuitImage = `${imagePath}/legs/Legs - Exo.png`;
const legsPlugSuitImage = `${imagePath}/legs/Legs - Plugs.png`;
const legsSkirt1Image = `${imagePath}/legs/Skirt 1 L.png`;
const legsSkirt2Image = `${imagePath}/legs/Skirt 2 L.png`;

// WEAPON
const weaponAssaultRifleImage = `${imagePath}/accessories/Accessory - AR.png`;
const weaponLongRifleImage = `${imagePath}/accessories/Accessory - Long Rim.png`;
const weaponSwordImage = `${imagePath}/accessories/Accessory - Sword.png`;
const weaponHeavyWeaponImage = `${imagePath}/accessories/Accessory - Heavy Experimental Weapon.png`;
const weaponHeavyMeleeImage = `${imagePath}/accessories/Accessory - Heavy Melee.png`;

// ACCESSORY
const weaponChargesImage = `${imagePath}/accessories/Accessory - Charges.png`;
const weaponOmnihookImage = `${imagePath}/accessories/Accessory - Omnihook.png`;
const weaponPersonalDroneImage = `${imagePath}/accessories/Accessory - Personal Drone.png`;
const weaponTertiaryArmImage = `${imagePath}/accessories/Accessory - Tertiary Arm.png`;
const accessoryCloakImage = `${imagePath}/accessories/Accessory - Cloak.png`;
const accessoryCloakBackImage = `${imagePath}/accessories/Accessory - Cloak Behind.png`;
const accessoryHoloOverlayImage = `${imagePath}/accessories/accessory-holo-overlay.png`;

const previewImage = `${imagePath}/preview.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    accessory2IsChassis: true,
    defaultValues: `{
    "chassisValue": "0",
    "headValue": "5",
    "rearMountValue": "NONE",
    "weaponValue": "4",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SHORT HAIR",
            src: headShortHairImage,
        },
        {
            value: 1,
            label: "LONG HAIR",
            src: headLongHairImage,
        },
        {
            value: 2,
            label: "PONYTAIL",
            src: headPonytailImage,
        },
        {
            value: 3,
            label: "SPIKEY",
            src: headSpikeyImage,
        },
        {
            value: 4,
            label: "HALF HELMET",
            src: headHalfHelmetImage,
        },
        {
            value: 5,
            label: "FULL HELMET - LEFT",
            src: headFullHelmetImage,
        },
        {
            value: 6,
            label: "FULL HELMET - RIGHT",
            src: headFullHelmetRImage,
        },
        {
            value: 7,
            label: "EXO SUIT",
            foremostSrc: headExoSuitImage,
        },
        {
            value: 8,
            label: "COWBOY HAT",
            foremostSrc: headCowboyImage,
        },
        {
            value: 9,
            label: "TRUCKER HAT",
            foremostSrc: headTruckerImage,
        },
        {
            value: 10,
            label: "97 - LEFT",
            foremostSrc: head97LImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 11,
            label: "97 - RIGHT",
            foremostSrc: head97RImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 12,
            label: "HOOD - LEFT",
            foremostSrc: headHoodLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 13,
            label: "HOOD - RIGHT",
            foremostSrc: headHoodRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 14,
            label: "IMP - LEFT",
            foremostSrc: headImpLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 15,
            label: "IMP - RIGHT",
            foremostSrc: headImpRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 16,
            label: "ISAAC - LEFT",
            foremostSrc: headIsaacLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 17,
            label: "ISAAC - RIGHT",
            foremostSrc: headIsaacRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 18,
            label: "JOHN - LEFT",
            foremostSrc: headJohnLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 19,
            label: "JOHN - RIGHT",
            foremostSrc: headJohnRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 20,
            label: "PILOT - LEFT",
            foremostSrc: headPilotLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 21,
            label: "PILOT - RIGHT",
            foremostSrc: headPilotRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 22,
            label: "RECON - LEFT",
            foremostSrc: headReconLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 23,
            label: "RECON - RIGHT",
            foremostSrc: headReconRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 24,
            label: "REPUBLIC - LEFT",
            foremostSrc: headRepublicLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 25,
            label: "REPUBLIC - RIGHT",
            foremostSrc: headRepublicRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 26,
            label: "SKULL - LEFT",
            foremostSrc: headSkullLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 27,
            label: "SKULL - RIGHT",
            foremostSrc: headSkullRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 28,
            label: "SLAYER - LEFT",
            foremostSrc: headSlayerLImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 29,
            label: "SLAYER - RIGHT",
            foremostSrc: headSlayerRImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BODY M",
            src: bodyMImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 1,
            label: "BODY F",
            src: bodyFImage,
            shadowDetails: shadowDetails,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "OPEN JACKET",
                frontSrc: chestOpenJacketImage,
            },
            {
                value: 1,
                label: "JACKET",
                frontSrc: chestJacketImage,
            },
            {
                value: 2,
                label: "LONG COAT",
                frontSrc: chestLongCoatImage,
            },
            {
                value: 3,
                label: "FULL BODY ARMOR",
                src: fullBodyArmorImage,
            },
            {
                value: 4,
                label: "FULL FLIGHT SUIT",
                src: fullFlightSuitImage,
            },
            {
                value: 5,
                label: "FULL EXO SUIT",
                src: fullExoSuitImage,
            },
            {
                value: 6,
                label: "FULL PLUG SUIT",
                src: fullPlugSuitImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BODY ARMOR",
            frontSrc: legsBodyArmorImage,
        },
        {
            value: 1,
            label: "FLIGHT SUIT",
            frontSrc: legsFlightSuitImage,
        },
        {
            value: 2,
            label: "EXO SUIT",
            frontSrc: legsExoSuitImage,
        },
        {
            value: 3,
            label: "PLUG SUIT",
            frontSrc: legsPlugSuitImage,
        },
        {
            value: 4,
            label: "SKIRT 1",
            frontSrc: legsSkirt1Image,
        },
        {
            value: 5,
            label: "SKIRT 2",
            frontSrc: legsSkirt2Image,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ASSAULT RIFLE",
            foremostSrc: weaponAssaultRifleImage,
        },
        {
            value: 1,
            label: "LONG RIFLE",
            foremostSrc: weaponLongRifleImage,
        },
        {
            value: 2,
            label: "HEAVY WEAPON",
            foremostSrc: weaponHeavyWeaponImage,
        },
        {
            value: 3,
            label: "SWORD",
            foremostSrc: weaponSwordImage,
        },
        {
            value: 4,
            label: "HEAVY MELEE",
            foremostSrc: weaponHeavyMeleeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "TANK TOP M",
            src: shirtTankTopMImage,
        },
        {
            value: 1,
            label: "TANK TOP F",
            src: shirtTankTopFImage,
        },
        {
            value: 2,
            label: "CROP TOP",
            src: shirtCropTopImage,
        },
        {
            value: 3,
            label: "SHORT SLEEVE M",
            src: shirtShortSleeveMImage,
        },
        {
            value: 4,
            label: "SHORT SLEEVE F",
            src: shirtShortSleeveFImage,
        },
        {
            value: 5,
            label: "LONG SLEEVE M",
            src: shirtLongSleeveMImage,
        },
        {
            value: 6,
            label: "LONG SLEEVE F",
            src: shirtLongSleeveFImage,
        },
        {
            value: 7,
            label: "OPEN JACKET",
            frontSrc: chestOpenJacketImage,
        },
        {
            value: 8,
            label: "JACKET",
            frontSrc: chestJacketImage,
        },
        {
            value: 9,
            label: "LONG COAT",
            frontSrc: chestLongCoatImage,
        },
        {
            value: 10,
            label: "CLOAK",
            frontSrc: accessoryCloakImage,
            backSrc: accessoryCloakBackImage,
        },
        {
            value: 11,
            label: "CHARGES",
            frontSrc: weaponChargesImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 12,
            label: "OMNIHOOK",
            backSrc: weaponOmnihookImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 13,
            label: "PERSONAL DRONE",
            frontSrc: weaponPersonalDroneImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 14,
            label: "TERTIARY ARM",
            frontSrc: weaponTertiaryArmImage,
            originalArtCredit: {
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 15,
            label: "SKIRT 1",
            frontSrc: legsSkirt1Image,
        },
        {
            value: 16,
            label: "SKIRT 2",
            frontSrc: legsSkirt2Image,
        },
        {
            value: 17,
            label: "HOLO OVERLAY",
            src: accessoryHoloOverlayImage,
            originalArtCredit: {
                title: "L7Delta",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "BODY",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "rearMount",
        },
        {
            label: "CHEST OUTER",
            value: "weapon",
        },
        {
            label: "LEGS",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "BODY",
        "rearMount": "WEAPON",
        "weapon": "CHEST OUTER",
        "secondaryWeapon": "LEGS",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 2",
        "accessory4": "ACCESSORY 3",
    },
};
