import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOS-Zahhak`;

// BODY
const bodyImage = `${imagePath}/Zahhak Chassis.png`;

// HEADS
const headIceWormImage = `${imagePath}/heads/Head - Ice Worm.png`;
const headZahhakImage = `${imagePath}/heads/Head - Zahhak.png`;

// WEAPONS
const weaponMantleRiflesImage = `${imagePath}/weapons/Weapon - Mantle Rifles.png`;
const weaponMantleRiflesPandoraInterceptImage = `${imagePath}/weapons/Weapon - Mantle Rifles Pandora Intercept.png`;
const weaponMantleRiflesUnderImage = `${imagePath}/weapons/Weapon Under - Mantle Rifles.png`;
const weaponViperClassMechTaserImage = `${imagePath}/weapons/Weapon - Viper-Class Mech Taser.png`;

// GLOWS
const glowZahhakImage = `${imagePath}/glows/Glow - Zahhak.png`;
const glowZahhakViperImage = `${imagePath}/glows/Glow - Zahhak Viper.png`;
const glowViperClassMechTaserImage = `${imagePath}/glows/Glow - Viper-Class Mech Taser.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-18px",
    right: "-5px",
};

const originalArtCredit = {
    prefixText: "Commissioned by Vox ",
    title: "Link in News",
    url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
};

export default {
    previewImg: previewImage,
    isMegadeusPlus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "1",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ICE WORM",
            src: headIceWormImage,
        },
        {
            value: 1,
            label: "ZAHHAK",
            src: headZahhakImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ZAHHAK",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "MANTLE RIFLES",
                src: weaponMantleRiflesImage,
                backSrc: weaponMantleRiflesUnderImage,
            },
            {
                value: 1,
                label: "MANTLE RIFLES + PANDORA INTERCEPT",
                src: weaponMantleRiflesPandoraInterceptImage,
                backSrc: weaponMantleRiflesUnderImage,
            },
            {
                value: 2,
                label: "VIPER-CLASS MECH TASER",
                src: weaponViperClassMechTaserImage,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ZAHHAK",
            frontSrc: glowZahhakImage,
        },
        {
            value: 1,
            label: "ZAHHAK VIPER",
            frontSrc: glowZahhakViperImage,
        },
        {
            value: 2,
            label: "VIPER-CLASS MECH TASER",
            frontSrc: glowViperClassMechTaserImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "WEAPON",
        "secondaryWeapon": "EMPTY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
