import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Charioteer`;

// BODY
const bodyImage = `${imagePath}/Charioteer_Chassis.png`;

// HEADS
const headCharioteerImage = `${imagePath}/heads/Charioteer_Head_Charioteer.png`;
const headCharioteerNoTubesImage = `${imagePath}/heads/Charioteer_Head_Charioteer No Tubes.png`;
const headAtomsKImage = `${imagePath}/heads/Charioteer_Head_Atoms K.png`;
const headBeatImage = `${imagePath}/heads/Charioteer_Head_Beat.png`;
const headBombRushImage = `${imagePath}/heads/Charioteer_Head_Bomb Rush.png`;
const headDevilImage = `${imagePath}/heads/Charioteer_Head_Devil.png`;
const headHornedImage = `${imagePath}/heads/Charioteer_Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Charioteer_Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Charioteer_Head_Kazu.png`;
const headMetalImage = `${imagePath}/heads/Charioteer_Head_Metal.png`;
const headNaturalLawImage = `${imagePath}/heads/Charioteer_Head_Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Charioteer_Head_Natural Law Hair.png`;
const headPrototypeImage = `${imagePath}/heads/Charioteer_Head_Prototype.png`;
const headPrototypeHairImage = `${imagePath}/heads/Charioteer_Head_Prototype Hair.png`;
const headRainTransformedImage = `${imagePath}/heads/Charioteer_Head_Rain Transformed.png`;
const headRainTransformedhairImage = `${imagePath}/heads/Charioteer_Head_Rain Transformed Hair.png`;
const headRiderImage = `${imagePath}/heads/Charioteer_Head_Rider.png`;
const headSilverChariotImage = `${imagePath}/heads/Charioteer_Head_Silver Chariot.png`;
const headUrbanImage = `${imagePath}/heads/Charioteer_Head_Urban.png`;
const headVanquisherImage = `${imagePath}/heads/Charioteer_Head_Vanquisher.png`;

// WEAPONS
// UPPER
const weaponsUpperBladesImage = `${imagePath}/weapons/top/Charioteer_Top Arms_Arm Blades.png`;
const weaponsUpperBoomboxImage = `${imagePath}/weapons/top/Charioteer_Top Arms_Boombox.png`;
const weaponsUpperMissileBoxImage = `${imagePath}/weapons/top/Charioteer_Top Arms_Missile Box.png`;
const weaponsUpperNanocarbonBladeImage = `${imagePath}/weapons/top/Charioteer_Top Arms_Nanocarbon Blade.png`;
const weaponsUpperNanocarbonBladeRearImage = `${imagePath}/weapons/top/Charioteer_Top Arms Rear_Nanocarbon Blade.png`;
const weaponsUpperTargetingRelayImage = `${imagePath}/weapons/top/Charioteer_Top Arms_Targeting Relay.png`;
const weaponsUpperLauncherImage = `${imagePath}/weapons/top/Charioteer_Top Arms_Arm Wheels.png`;

// LOWER
const weaponsLowerDualBladesImage = `${imagePath}/weapons/bottom/Charioteer_Bottom Arms_Dual Blades.png`;
const weaponsLowerDualKnivesImage = `${imagePath}/weapons/bottom/Charioteer_Bottom Arms_Dual Knives.png`;
const weaponsLowerGraffitiCansImage = `${imagePath}/weapons/bottom/Charioteer_Bottom Arms_Graffiti Cans.png`;
const weaponsLowerHandsImage = `${imagePath}/weapons/bottom/Charioteer_Bottom Arms_Hands.png`;
const weaponsLowerReconCarbineImage = `${imagePath}/weapons/bottom/Charioteer_Bottom Arms_Recon Carbine.png`;
const weaponsLowerSMGsImage = `${imagePath}/weapons/bottom/Charioteer_Bottom Arms_Smgs.png`;

// REAR MOUNT
const rearMountAntennaImage = `${imagePath}/rearmount/Charioteer_Rear Mount_Antenna.png`;
const rearMountAntennaFlagsImage = `${imagePath}/rearmount/Charioteer_Rear Mount_Antenna Flags.png`;
const rearMountBombletDispenserImage = `${imagePath}/rearmount/Charioteer_Rear Mount_Bomblet Dispenser.png`;
const rearMountJetBoosterImage = `${imagePath}/rearmount/Charioteer_Rear Mount_Jet Booster.png`;
const rearMountSpoilerImage = `${imagePath}/rearmount/Charioteer_Rear Mount_Spoiler.png`;

// ACCESSORIES
const accessoryFeetBladesImage = `${imagePath}/accessories/Charioteer_Feet_Blades.png`;
const accessoryFeetGlideRollersImage = `${imagePath}/accessories/Charioteer_Feet_Glide Rollers.png`;
const accessoryFeetJetBootsImage = `${imagePath}/accessories/Charioteer_Feet_Jet Boots.png`;
const accessoryFrontShoulderLauncherImage = `${imagePath}/accessories/Charioteer_Accessories Front_Shoulder Launcher.png`;
const accessoryExhaustsImage = `${imagePath}/accessories/Charioteer_Accessories Middle_Exhausts.png`;

// GLOWS
const glowArmBladesImage = `${imagePath}/glows/Charioteer_Accessories Front_ Arm Blades Glow.png`;
const glowBladesImage = `${imagePath}/glows/Charioteer_Accessories Middle_Blades Glow.png`;
const glowBoomboxImage = `${imagePath}/glows/Charioteer_Accessories Front_Boombox Glow.png`;
const glowDualKnivesImage = `${imagePath}/glows/Charioteer_Accessories Front_Dual Knives Glow.png`;
const glowGlideRollersImage = `${imagePath}/glows/Charioteer_Accessories Middle_Glide Rollers Glow.png`;
const glowGraffitiGasImage = `${imagePath}/glows/Charioteer_Accessories Front_Graffiti Gas.png`;
const glowJetBoosterImage = `${imagePath}/glows/Charioteer_Accessories Low_Jet Booster Glow.png`;
const glowSkidHandImage = `${imagePath}/glows/Charioteer_Accessories Middle_Hand Skid.png`;
const glowSkidNanocarbonImage = `${imagePath}/glows/Charioteer_Accessories Middle_Nanocarbon Skid.png`;
const glowRocketBootGlowImage = `${imagePath}/glows/Charioteer_Accessories Middle_Rocket Boot Glow.png`;

const previewImage = `${imagePath}/preview.png`;
const coreImage = `${imagePath}/CHARIOTEER_AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "53px",
    right: "61px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "3",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "9",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CHARIOTEER",
            foremostSrc: headCharioteerImage,
        },
        {
            value: 1,
            label: "CHARIOTEER - NO TUBES",
            foremostSrc: headCharioteerNoTubesImage,
        },
        {
            value: 2,
            label: "ATOMS K",
            foremostSrc: headAtomsKImage,
        },
        {
            value: 3,
            label: "BEAT",
            foremostSrc: headBeatImage,
        },
        {
            value: 4,
            label: "BOMB RUSH",
            foremostSrc: headBombRushImage,
        },
        {
            value: 5,
            label: "DEVIL",
            foremostSrc: headDevilImage,
        },
        {
            value: 6,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 8,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 9,
            label: "METAL",
            foremostSrc: headMetalImage,
        },
        {
            value: 10,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 11,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 12,
            label: "PROTOTYPE",
            foremostSrc: headPrototypeImage,
        },
        {
            value: 13,
            label: "PROTOTYPE HAIR",
            foremostSrc: headPrototypeHairImage,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
        },
        {
            value: 15,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedhairImage,
        },
        {
            value: 16,
            label: "RIDER",
            foremostSrc: headRiderImage,
        },
        {
            value: 17,
            label: "SILVER CHARIOT",
            foremostSrc: headSilverChariotImage,
        },
        {
            value: 18,
            label: "URBAN",
            foremostSrc: headUrbanImage,
        },
        {
            value: 19,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CHARIOTEER",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Homebrew by Kai Tave. Commissioned by Terrance Turner. Designed by `,
                title: "Magnus",
                url: "https://twitter.com/Magnus_TheMage",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BLADES",
                src: weaponsUpperBladesImage,
            },
            {
                value: 1,
                label: "BOOMBOX",
                src: weaponsUpperBoomboxImage,
            },
            {
                value: 2,
                label: "MISSILE BOX",
                src: weaponsUpperMissileBoxImage,
            },
            {
                value: 3,
                label: "NANOCARBON BLADE",
                src: weaponsUpperNanocarbonBladeImage,
                backSrc: weaponsUpperNanocarbonBladeRearImage,
            },
            {
                value: 4,
                label: "TARGETING RELAY",
                src: weaponsUpperTargetingRelayImage,
            },
            {
                value: 5,
                label: "WHEELS",
                src: weaponsUpperLauncherImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DUAL BLADES",
            src: weaponsLowerDualBladesImage,
        },
        {
            value: 1,
            label: "DUAL KNIVES",
            src: weaponsLowerDualKnivesImage,
        },
        {
            value: 2,
            label: "GRAFFITI CANS",
            src: weaponsLowerGraffitiCansImage,
        },
        {
            value: 3,
            label: "HANDS",
            src: weaponsLowerHandsImage,
        },
        {
            value: 4,
            label: "RECON CARBINE",
            src: weaponsLowerReconCarbineImage,
        },
        {
            value: 5,
            label: "SMGS",
            src: weaponsLowerSMGsImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ANTENNA",
            src: rearMountAntennaImage,
        },
        {
            value: 1,
            label: "ANTENNA FLAGS",
            src: rearMountAntennaFlagsImage,
        },
        {
            value: 2,
            label: "BOMBLET DISPENSER",
            src: rearMountBombletDispenserImage,
        },
        {
            value: 3,
            label: "JET BOOSTER",
            src: rearMountJetBoosterImage,
        },
        {
            value: 4,
            label: "SPOILER",
            src: rearMountSpoilerImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FEET - BLADES",
            src: accessoryFeetBladesImage,
        },
        {
            value: 1,
            label: "FEET - GLIDE ROLLERS",
            src: accessoryFeetGlideRollersImage,
        },
        {
            value: 2,
            label: "FEET - JET BOOTS",
            src: accessoryFeetJetBootsImage,
        },
        {
            value: 3,
            label: "SHOULDER LAUNCHER",
            src: accessoryFrontShoulderLauncherImage,
        },
        {
            value: 4,
            label: "EXHAUSTS",
            src: accessoryExhaustsImage,
        },
        {
            value: 5,
            label: "GLOW - ARM BLADES",
            frontSrc: glowArmBladesImage,
        },
        {
            value: 6,
            label: "GLOW - FEET BLADES",
            frontSrc: glowBladesImage,
        },
        {
            value: 7,
            label: "GLOW - BOOMBOX",
            frontSrc: glowBoomboxImage,
        },
        {
            value: 8,
            label: "GLOW - DUAL KNIVES",
            frontSrc: glowDualKnivesImage,
        },
        {
            value: 9,
            label: "GLOW - GLIDE ROLLERS",
            frontSrc: glowGlideRollersImage,
        },
        {
            value: 10,
            label: "GLOW - GRAFFITI GAS",
            frontSrc: glowGraffitiGasImage,
        },
        {
            value: 11,
            label: "GLOW - JET BOOSTERS",
            frontSrc: glowJetBoosterImage,
        },
        {
            value: 12,
            label: "GLOW - JET BOOTS",
            frontSrc: glowRocketBootGlowImage,
        },
        {
            value: 13,
            label: "GLOW - SKID - HAND",
            frontSrc: glowSkidHandImage,
        },
        {
            value: 14,
            label: "GLOW - SKID - NANOCARBON",
            frontSrc: glowSkidNanocarbonImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "UPPER ARMS",
            value: "weapon",
        },
        {
            label: "LOWER ARMS",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "UPPER ARMS",
        "secondaryWeapon": "LOWER ARMS",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
