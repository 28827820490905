import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/622`;

// CHASSIS
const chassisStandardImage = `${imagePath}/chest/Chest - Standard.png`;
const chassisHeavyImage = `${imagePath}/chest/Chest - Heavy.png`;
const chassisNachtImage = `${imagePath}/chest/Chest - Nacht.png`;

// HEADS
const headStandardImage = `${imagePath}/heads/Head - Standard.png`;
const headHeavyImage = `${imagePath}/heads/Head - Heavy.png`;
const headNachtImage = `${imagePath}/heads/Head - Nacht.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - FT Nelson.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headUnlabelledBaseImage = `${imagePath}/heads/Head - unlabelled - base.png`;
const headUnlabelledGlowImage = `${imagePath}/heads/Head - unlabelled - glow.png`;
const headUnlabelledBothImage = `${imagePath}/heads/Head - unlabelled - both.png`;
const headAlbaBaseImage = `${imagePath}/heads/Alba Head.png`;
const headAlbaBothImage = `${imagePath}/heads/Alba Head and Glow.png`;
const headAlbaGlowImage = `${imagePath}/heads/Alba Head Glow.png`;

// LEFT ARMS
const leftArmStandardImage = `${imagePath}/arms/left/Left Arm - Standard.png`;
const leftArmHeavyImage = `${imagePath}/arms/left/Left Arm - Heavy.png`;
const leftArmNachtImage = `${imagePath}/arms/left/Left Arm - Nacht.png`;

// RIGHT ARMS
const rightArmStandardImage = `${imagePath}/arms/right/Right Arm - Standard.png`;
const rightArmHeavyImage = `${imagePath}/arms/right/Right Arm - Heavy.png`;
const rightArmNachtImage = `${imagePath}/arms/right/Right Arm - Nacht.png`;

// LEGS
const legsStandard1Image = `${imagePath}/legs/Legs - Standard 1.png`;
const legsStandard2Image = `${imagePath}/legs/Legs - Standard 2.png`;
const legsReverse1Image = `${imagePath}/legs/Legs - Reverse 1.png`;
const legsReverse2Image = `${imagePath}/legs/Legs - Reverse 2.png`;
const legsHeavyImage = `${imagePath}/legs/Legs - Heavy.png`;
const legsTankImage = `${imagePath}/legs/Legs - Tank.png`;
const legsQuadsImage = `${imagePath}/legs/Legs - Quads.png`;

// REAR MOUNT
const rearMount4LaunchersImage = `${imagePath}/rearmount/Shoulder Weapons - 4x4 Launchers.png`;
const rearMount8LaunchersImage = `${imagePath}/rearmount/Shoulder Weapons - 8x8 Launchers.png`;

// ACCESSORY
const accessoryBoosterImage = `${imagePath}/boosters/Boosters.png`;

// LEFT WEAPONS
const leftWeaponAR1Image = `${imagePath}/weapons/left/Left Arm Weapons - AR 1.png`;
const leftWeaponAR2Image = `${imagePath}/weapons/left/Left Arm Weapons - AR 2.png`;
const leftWeaponChaingunImage = `${imagePath}/weapons/left/Left Arm Weapons - Chaingun.png`;
const leftWeaponHandgunImage = `${imagePath}/weapons/left/Left Arm Weapons - Handgun.png`;
const leftWeaponHeavyShotgunImage = `${imagePath}/weapons/left/Left Arm Weapons - Heavy Shotgun.png`;
const leftWeaponShieldImage = `${imagePath}/weapons/left/Left Arm Weapons - Shield.png`;
const leftWeaponShieldUnderImage = `${imagePath}/weapons/left/Left Arm Under - Shield.png`;
const leftWeaponSMG1Image = `${imagePath}/weapons/left/Left Arm Weapons - SMG 1.png`;
const leftWeaponSMG2Image = `${imagePath}/weapons/left/Left Arm Weapons - SMG 2.png`;

// RIGHT WEAPONS
const rightWeaponChaingunImage = `${imagePath}/weapons/right/Right Arm  Weapon - Chaingun.png`;
const rightWeaponHandgunImage = `${imagePath}/weapons/right/Right Arm  Weapon - Handgun.png`;
const rightWeaponHeavyShotgunImage = `${imagePath}/weapons/right/Right Arm  Weapon - Heavy Shotgun.png`;
const rightWeaponPilebunkerImage = `${imagePath}/weapons/right/Right Arm  Weapon - Pilebunker.png`;
const rightWeaponPlasmaBladeImage = `${imagePath}/weapons/right/Right Arm  Weapon - Plasma Blade.png`;
const rightWeaponSMG1Image = `${imagePath}/weapons/right/Right Arm  Weapon - SMG 1.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "40px",
    right: "50px",
};

const bipedLegsAltShadow = {
    size: shadowTypes.LARGE,
    bottom: "40px",
    right: "37px",
};

const bipedLegsTallShadow = {
    size: shadowTypes.LARGE,
    bottom: "30px",
    right: "45px",
};

const quadLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "50px",
    right: "2px",
};

const tankLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "80px",
    right: "-1px",
};

export default {
    isMegadeusPlus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "armLeftValue": "0",
        "armRightValue": "0",
        "legsValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "4",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "STANDARD",
            src: headStandardImage,
        },
        {
            value: 1,
            label: "HEAVY",
            src: headHeavyImage,
        },
        {
            value: 2,
            label: "NACHT",
            src: headNachtImage,
        },
        {
            value: 3,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 4,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 6,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 7,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 8,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 9,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 10,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 11,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 14,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 15,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 16,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 17,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 18,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 19,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 20,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 21,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 22,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 23,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 24,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 25,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 26,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 27,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 28,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 29,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 30,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 31,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 32,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 33,
            label: "VERRILL",
            src: headUnlabelledBaseImage,
            originalArtCredit: {
                title: "Memetarion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 34,
            label: "VERRILL + GLOW",
            src: headUnlabelledBothImage,
            originalArtCredit: {
                title: "Memetarion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 35,
            label: "ALBA",
            src: headAlbaBaseImage,
            originalArtCredit: {
                title: "Memetarion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 36,
            label: "ALBA + GLOW",
            src: headAlbaBothImage,
            originalArtCredit: {
                title: "Memetarion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    chassis: [
        {
            value: 0,
            label: "STANDARD",
            src: chassisStandardImage,
        },
        {
            value: 1,
            label: "HEAVY",
            src: chassisHeavyImage,
        },
        {
            value: 2,
            label: "NACHT",
            src: chassisNachtImage,
        },
    ],
    leftArm: [
        {
            value: 0,
            label: "STANDARD",
            src: leftArmStandardImage,
        },
        {
            value: 1,
            label: "HEAVY",
            src: leftArmHeavyImage,
        },
        {
            value: 2,
            label: "NACHT",
            src: leftArmNachtImage,
        },
    ],
    rightArm: [
        {
            value: 0,
            label: "STANDARD",
            src: rightArmStandardImage,
        },
        {
            value: 1,
            label: "HEAVY",
            src: rightArmHeavyImage,
        },
        {
            value: 2,
            label: "NACHT",
            src: rightArmNachtImage,
        },
    ],
    legs: [
        {
            value: 0,
            label: "STANDARD 1",
            src: legsStandard1Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "STANDARD 2",
            src: legsStandard2Image,
            shadowDetails: bipedLegsAltShadow,
        },
        {
            value: 2,
            label: "REVERSE 1",
            src: legsReverse1Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "REVERSE 2",
            src: legsReverse2Image,
            shadowDetails: bipedLegsTallShadow,
        },
        {
            value: 4,
            label: "HEAVY",
            src: legsHeavyImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 5,
            label: "TANK",
            src: legsTankImage,
            shadowDetails: tankLegsShadow,
        },
        {
            value: 6,
            label: "QUADS",
            src: legsQuadsImage,
            shadowDetails: quadLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AR 1",
                src: leftWeaponAR1Image,
            },
            {
                value: 1,
                label: "AR 2",
                src: leftWeaponAR2Image,
            },
            {
                value: 2,
                label: "CHAINGUN",
                src: leftWeaponChaingunImage,
            },
            {
                value: 3,
                label: "HANDGUN",
                src: leftWeaponHandgunImage,
            },
            {
                value: 4,
                label: "HEAVY SHOTGUN",
                src: leftWeaponHeavyShotgunImage,
            },
            {
                value: 5,
                label: "SHIELD",
                src: leftWeaponShieldImage,
                backSrc: leftWeaponShieldUnderImage,
            },
            {
                value: 6,
                label: "SMG 1",
                src: leftWeaponSMG1Image,
            },
            {
                value: 7,
                label: "SMG 2",
                src: leftWeaponSMG2Image,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CHAINGUN",
            src: rightWeaponChaingunImage,
        },
        {
            value: 1,
            label: "HANDGUN",
            src: rightWeaponHandgunImage,
        },
        {
            value: 2,
            label: "HEAVY SHOTGUN",
            src: rightWeaponHeavyShotgunImage,
        },
        {
            value: 3,
            label: "PILEBUNKER",
            src: rightWeaponPilebunkerImage,
        },
        {
            value: 4,
            label: "PLASMA BLADE",
            src: rightWeaponPlasmaBladeImage,
        },
        {
            value: 5,
            label: "SMG 1",
            src: rightWeaponSMG1Image,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "4X4 LAUNCHERS",
            src: rearMount4LaunchersImage,
        },
        {
            value: 1,
            label: "8X8 LAUNCHERS",
            src: rearMount8LaunchersImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BOOSTER",
            backSrc: accessoryBoosterImage,
        },
        {
            value: 1,
            label: "HEAD - VERRILL - GLOW",
            frontSrc: headUnlabelledGlowImage,
            originalArtCredit: {
                title: "Memetarion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 2,
            label: "HEAD - ALBA - GLOW",
            frontSrc: headAlbaGlowImage,
            originalArtCredit: {
                title: "Memetarion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "leftArm",
        },
        {
            label: "RIGHT ARM",
            value: "rightArm",
        },
        {
            label: "LEGS",
            value: "legs",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "legs": "LEGS",
        "leftArm": "LEFT ARM",
        "rightArm": "RIGHT ARM",
        "rearMount": "REAR MOUNT",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
