import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyVladImage = `${BaseURL}/Mechs/IPS/Vlad/Vlad Body.png`;
const bodyVladPIERCEImage = `${BaseURL}/Mechs/IPS/Vlad/Body - PIERCE THE HEAVENS.png`;

// HEADS
const headVladImage = `${BaseURL}/Mechs/IPS/Vlad/heads/Head Vlad.png`;
const headKazuImage = `${BaseURL}/Mechs/IPS/Vlad/heads/Head - Kazu.png`;
// PREMIUM
const headAImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head A.png`;
const headBImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head B.png`;
const headCImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head C.png`;
const headDImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head D.png`;
const headEImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head E.png`;
const headFImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head F.png`;
const headGImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head G.png`;
const headHImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head H.png`;
const headIImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head I.png`;
const headJImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head J.png`;
const headKImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head K.png`;
const headLImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head L.png`;
const headMImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head M.png`;
const headNImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head N.png`;
const headOImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head O.png`;
const headPImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head P.png`;
const headCockpitImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/IPS/Vlad/heads/premium/Head - Scopehound.png`;

// 1H RIGHT
const weaponBladeRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right B Blade.png`;
const weaponChargedStakeRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right B Charged Stake.png`;
const weaponSpearRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right B Spear.png`;
const weaponStakesRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right B Stakes.png`;
const weaponThermalBladeRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right B Thermal Blade.png`;
const weaponTriconeDrillRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right B Tricone Drill.png`;

// 1H LEFT
const weaponBladeLeft1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/left/Weapon Left B Blade.png`;
const weaponCombatDrillLeft1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/left/Weapon Left B Combat Drill.png`;
const weaponSnareLeft1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/left/Weapon Left B Snare.png`;
const weaponThermalBladeLeft1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/left/Weapon Left B Thermal Blade.png`;
const weaponTriconeDrillLeft1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/left/Weapon Left B Tricone Drill.png`;

// ACCESSORY
const accessoryImpactLanceImage = `${BaseURL}/Mechs/IPS/Vlad/accessories/Accessory Impact Lance Chest.png`;
const accessoryBelieveInMeImage = `${BaseURL}/Mechs/IPS/Vlad/accessories/Accessory - BELIEVE IN ME.png`;
const accessoryWhoBelievesInYouImage = `${BaseURL}/Mechs/IPS/Vlad/accessories/Accessory - WHO BELIEVES IN YOU.png`;

// ACCESSORY WEAPONS
const accWeaponSpearRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right A Spear.png`;
const accWeaponNailgunRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right A Nailgun.png`;
const accWeaponMacheteRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right A Machete.png`;
const accWeaponCatalyticHammerRight1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/right/Weapon Right A Catalytic Hammer.png`;

const accWeaponNailgunLeft1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/left/Weapon Left A Nailgun.png`;
const accWeaponMacheteLeft1HImage = `${BaseURL}/Mechs/IPS/Vlad/weapons/left/Weapon Left A Machete.png`;

const coreImage = `${BaseURL}/Mechs/IPS/Vlad/Vlad 1.0 (by Corvon).png`;

const previewImage = `${BaseURL}/Mechs/IPS/Vlad/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "62px",
    right: "70px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "3",
        "secondaryWeaponValue": "3",
        "accessory1Value": "4",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "VLAD",
            src: headVladImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "PALADIN",
            src: headBImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "OGRE",
            src: headCImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ROGUE",
            src: headDImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "BASTION",
            src: headEImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ICARUS",
            src: headFImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCARAB",
            src: headGImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RAMSHACKLE",
            src: headHImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SLIPSTREAM",
            src: headIImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "BUNKER",
            src: headJImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            src: headKImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "TALON",
            src: headLImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "JUDICATOR",
            src: headMImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RUMBLE",
            src: headNImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "NELSON",
            src: headOImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "TORTUGA",
            src: headPImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "FACTORY STANDARD",
            src: headAImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "VLAD",
            src: bodyVladImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "PIERCE THE HEAVENS",
            src: bodyVladPIERCEImage,
            shadowDetails: bipedLegsShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "BLADE",
                src: weaponBladeLeft1HImage,
            },
            {
                value: 1,
                label: "THERMAL BLADE",
                src: weaponThermalBladeLeft1HImage,
            },
            {
                value: 2,
                label: "SNARE",
                src: weaponSnareLeft1HImage,
            },
            {
                value: 3,
                label: "COMBAT DRILL",
                src: weaponCombatDrillLeft1HImage,
            },
            {
                value: 4,
                label: "TRICONE DRILL",
                src: weaponTriconeDrillLeft1HImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BLADE",
            src: weaponBladeRight1HImage,
        },
        {
            value: 1,
            label: "THERMAL BLADE",
            src: weaponThermalBladeRight1HImage,
        },
        {
            value: 2,
            label: "SPEAR",
            src: weaponSpearRight1HImage,
        },
        {
            value: 3,
            label: "STAKES",
            src: weaponStakesRight1HImage,
        },
        {
            value: 4,
            label: "CHARGED STAKE",
            src: weaponChargedStakeRight1HImage,
        },
        {
            value: 5,
            label: "TRICONE DRILL",
            src: weaponTriconeDrillRight1HImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "IMPACT LANCE",
            frontSrc: accessoryImpactLanceImage,
        },
        {
            value: 1,
            label: "BELIEVE IN ME",
            frontSrc: accessoryBelieveInMeImage,
        },
        {
            value: 2,
            label: "WHO BELIEVES IN YOU",
            frontSrc: accessoryWhoBelievesInYouImage,
        },

        {
            value: 3,
            label: "NAILGUN / NONE",
            frontSrc: accWeaponNailgunLeft1HImage,
        },
        {
            value: 4,
            label: "NAILGUN / NAILGUN",
            frontSrc: accWeaponNailgunLeft1HImage,
            src: accWeaponNailgunRight1HImage,
        },
        {
            value: 5,
            label: "NAILGUN / SPEAR",
            frontSrc: accWeaponNailgunLeft1HImage,
            src: accWeaponSpearRight1HImage,
        },
        {
            value: 6,
            label: "NAILGUN / NAILGUN",
            frontSrc: accWeaponNailgunLeft1HImage,
            src: accWeaponNailgunRight1HImage,
        },
        {
            value: 7,
            label: "NAILGUN / MACHETE",
            frontSrc: accWeaponNailgunLeft1HImage,
            src: accWeaponMacheteRight1HImage,
        },
        {
            value: 8,
            label: "NAILGUN / C. HAMMER",
            frontSrc: accWeaponNailgunLeft1HImage,
            src: accWeaponCatalyticHammerRight1HImage,
        },
        {
            value: 9,
            label: "MACHETE / NONE",
            frontSrc: accWeaponMacheteLeft1HImage,
        },
        {
            value: 10,
            label: "MACHETE / MACHETE",
            frontSrc: accWeaponMacheteLeft1HImage,
            src: accWeaponMacheteRight1HImage,
        },
        {
            value: 11,
            label: "MACHETE / NAILGUN",
            frontSrc: accWeaponMacheteLeft1HImage,
            src: accWeaponNailgunRight1HImage,
        },
        {
            value: 12,
            label: "MACHETE / SPEAR",
            frontSrc: accWeaponMacheteLeft1HImage,
            src: accWeaponSpearRight1HImage,
        },
        {
            value: 13,
            label: "MACHETE / C. HAMMER",
            frontSrc: accWeaponMacheteLeft1HImage,
            src: accWeaponCatalyticHammerRight1HImage,
        },
        {
            value: 14,
            label: "NONE / MACHETE",
            src: accWeaponMacheteRight1HImage,
        },
        {
            value: 15,
            label: "NONE / NAILGUN",
            src: accWeaponNailgunRight1HImage,
        },
        {
            value: 16,
            label: "NONE / SPEAR",
            src: accWeaponSpearRight1HImage,
        },
        {
            value: 17,
            label: "NONE / C. HAMMER",
            src: accWeaponCatalyticHammerRight1HImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR LEFT",
            value: "weapon",
        },
        {
            label: "REAR RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "REAR LEFT",
        "secondaryWeapon": "REAR RIGHT",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
