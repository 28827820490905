import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyBlackbeardImage = `${BaseURL}/Mechs/IPS/Blackbeard/Body Blackbeard.png`;
const bodyBlackbeardCoverImage = `${BaseURL}/Mechs/IPS/Blackbeard/Over Head.png`;

// HEADS
const headBlackbeardImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/Head Blackbeard.png`;
// PREMIUM
const headAImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head A.png`;
const headBImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head B.png`;
const headCImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head C.png`;
const headDImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head D.png`;
const headEImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head E.png`;
const headFImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head F.png`;
const headGImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head G.png`;
const headHImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head H.png`;
const headIImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head I.png`;
const headJImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head J.png`;
const headKImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head K.png`;
const headLImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head L.png`;
const headMImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head M.png`;
const headNImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head N.png`;
const headOImage = `${BaseURL}/Mechs/IPS/Blackbeard/heads/premium/Head O.png`;

// ACCESSORY
const ramImage = `${BaseURL}/Mechs/IPS/Blackbeard/accessories/Accessory - Right Pneumatic Ram.png`;

// 1H RIGHT
const chainAxeRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/Right Weapon Chain Axe.png`;
const katanaRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/Right Weapon Katana.png`;
const swordRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/Right Weapon Sword.png`;
const spearRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/Right Weapon Spear.png`;
const tBladeRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/Right Weapon Thermal Blade.png`;
// PREMIUM
const bladeRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/premium/Right Weapon Blade.png`;
const hammerRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/premium/Right Weapon Hammer.png`;
const macheteRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/premium/Right Weapon Machete.png`;
const zweihanderRight1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/right/premium/Right Weapon Zweihander.png`;

// 1H LEFT
const axeHammerHandleLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/Left Axe - Hammer Handle.png`;
const chainAxeLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/Left Weapon Chain Axe.png`;
const swordLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/Left Weapon Sword.png`;
const shotgunLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/Left Weapon Shotgun.png`;
const tBladeLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/Left Weapon Thermal Blade.png`;
// PREMIUM
const axeLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/premium/Left Weapon Axe.png`;
const hammerLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/premium/Left Weapon Hammer.png`;
const macheteLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/premium/Left Weapon Machete.png`;
const monoSwordLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/premium/Left Weapon Monofilament Sword.png`;
const sickleLeft1HImage = `${BaseURL}/Mechs/IPS/Blackbeard/weapons/left/premium/Left Weapon Sickle.png`;

const previewImage = `${BaseURL}/Mechs/IPS/Blackbeard/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "82px",
    right: "50px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "2",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BLACKBEARD",
            src: headBlackbeardImage,
            frontSrc: bodyBlackbeardCoverImage,
        },
        {
            value: 1,
            label: "FACTORY STANDARD",
            src: headAImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "PALADIN",
            src: headBImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "OGRE",
            src: headCImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ROGUE",
            src: headDImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "BASTION",
            src: headEImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ICARUS",
            src: headFImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCARAB",
            src: headGImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RAMSHACKLE",
            src: headHImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SLIPSTREAM",
            src: headIImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "BUNKER",
            src: headJImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            src: headKImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "TALON",
            src: headLImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NELSON",
            src: headMImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "JUDICATOR",
            src: headNImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RUMBLE",
            src: headOImage,
            frontSrc: bodyBlackbeardCoverImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BLACKBEARD",
            src: bodyBlackbeardImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CHAIN AXE",
                src: chainAxeLeft1HImage,
                backSrc: axeHammerHandleLeft1HImage,
            },
            {
                value: 1,
                label: "SHOTGUN",
                src: shotgunLeft1HImage,
            },
            {
                value: 2,
                label: "SWORD",
                src: swordLeft1HImage,
            },
            {
                value: 3,
                label: "THERMAL BLADE",
                src: tBladeLeft1HImage,
            },
            {
                value: 4,
                label: "HAMMER",
                src: hammerLeft1HImage,
                backSrc: axeHammerHandleLeft1HImage,
                isPremium: true,
            },
            {
                value: 5,
                label: "AXE",
                src: axeLeft1HImage,
                backSrc: axeHammerHandleLeft1HImage,
                isPremium: true,
            },
            {
                value: 6,
                label: "MACHETE",
                src: macheteLeft1HImage,
                isPremium: true,
            },
            {
                value: 7,
                label: "MONOFILAMENT SWORD",
                src: monoSwordLeft1HImage,
                isPremium: true,
            },
            {
                value: 8,
                label: "SICKLE",
                src: sickleLeft1HImage,
                isPremium: true,
            }
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SWORD",
            src: swordRight1HImage,
        },
        {
            value: 1,
            label: "KATANA",
            src: katanaRight1HImage,
        },
        {
            value: 2,
            label: "CHAIN AXE",
            src: chainAxeRight1HImage,
        },
        {
            value: 3,
            label: "THERMAL BLADE",
            src: tBladeRight1HImage,
        },
        {
            value: 4,
            label: "SPEAR",
            src: spearRight1HImage,
        },
        {
            value: 5,
            label: "BLADE",
            src: bladeRight1HImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "HAMMER",
            src: hammerRight1HImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "MACHETE",
            src: macheteRight1HImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "ZWEIHANDER",
            src: zweihanderRight1HImage,
            isPremium: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "RAM",
            src: ramImage,
            frontSrc: ramImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
