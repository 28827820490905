import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyWolfKnightImage = `${BaseURL}/Mechs/Custom/Wolf Knight/Chassis.png`;
const bodyHeadCoverImage = `${BaseURL}/Mechs/Custom/Wolf Knight/Head Over.png`;

// HEADS
const headWolfKnightImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/Head - Wolf Knight.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/Head - Kazu.png`;
// PREMIUM
const headFactoryStandardImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Factory Standard.png`;
const headPaladinImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Paladin.png`;
const headOgreImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Ogre.png`;
const headRogueImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Rogue.png`;
const headScarabImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Scarab.png`;
const headBastionImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Bastion.png`;
const headRamshackleImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Ramshackle.png`;
const headRumbleImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Rumble.png`;
const headBunkerImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Bunker.png`;
const headSlipstreamImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Slipstream.png`;
const headDungamImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Dungam.png`;
const headJudicatorImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Judicator.png`;
const headHydraImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Hydra.png`;
const headTalonImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Talon.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Nelson.png`;
const headEliteKnightImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Elite Knight.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Cyclops.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Malice.png`;
const headMetalmarkImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Metalmark.png`;
const headMonarchImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Monarch.png`;
const headMourningCloakImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Mourning Cloak.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Rain.png`;
const headRainHairImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Rain Hair.png`;
const headWitchImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Witch.png`;
const headBriarImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Briar.png`;
const headScopehoundImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/premium/Head - Scopehound.png`;

const theWolfImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/Head - The Wolf.png`;
const theWolfGlowImage = `${BaseURL}/Mechs/Custom/Wolf Knight/heads/Glow - The Wolf.png`;

// WEAPONS
const weaponsSwordImage = `${BaseURL}/Mechs/Custom/Wolf Knight/weapons/Main Weapon - Sword.png`;
const weaponsAxeImage = `${BaseURL}/Mechs/Custom/Wolf Knight/weapons/Main Weapon - Axe.png`;
const weaponsRifleImage = `${BaseURL}/Mechs/Custom/Wolf Knight/weapons/Main Weapon - Rifle.png`;

// SECONDARY WEAPONS
const secondaryWeaponsTatteredCloakImage = `${BaseURL}/Mechs/Custom/Wolf Knight/cloaks/Accessory - Tattered Cloak.png`;
// LEGENDARY
const secondaryWeaponsCloakImage = `${BaseURL}/Mechs/Custom/Wolf Knight/cloaks/Accessory - Cloak.png`;
const secondaryWeaponsDarkCloakImage = `${BaseURL}/Mechs/Custom/Wolf Knight/cloaks/Accessory - Dark Cloak.png`;
const secondaryWeaponsCamoCloakImage = `${BaseURL}/Mechs/Custom/Wolf Knight/cloaks/Accessory - Camoflauge Cloak.png`;

// REAR MOUNT
const rearMountSwordHolsterImage = `${BaseURL}/Mechs/Custom/Wolf Knight/rear mount/Sword Holster.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Wolf Knight/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "27px",
    right: "24px",
};

export default {
    previewImg: previewImage,
    removeNone: {
        weapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "WOLF KNIGHT",
            src: headWolfKnightImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "PALADIN",
            src: headPaladinImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "OGRE",
            src: headOgreImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ROGUE",
            src: headRogueImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "SCARAB",
            src: headScarabImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "BASTION",
            src: headBastionImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "RAMSHACKLE",
            src: headRamshackleImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "BUNKER",
            src: headBunkerImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            src: headDungamImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "HYDRA",
            src: headHydraImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "TALON",
            src: headTalonImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "ELITE KNIGHT",
            src: headEliteKnightImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "METALMARK",
            src: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 24,
            label: "MONARCH",
            src: headMonarchImage,
            isPremium: true,
        },
        {
            value: 25,
            label: "MOURNING CLOAK",
            src: headMourningCloakImage,
            isPremium: true,
        },
        {
            value: 26,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 27,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 28,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 29,
            label: "RAIN HAIR",
            src: headRainHairImage,
            isPremium: true,
        },
        {
            value: 30,
            label: "WITCH",
            src: headWitchImage,
            isPremium: true,
        },
        {
            value: 31,
            label: "BRIAR",
            src: headBriarImage,
            isPremium: true,
        },
        {
            value: 32,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 33,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            removeBodyCover: true,
            isPremium: true,
        },
        {
            value: 34,
            label: "THE WOLF",
            src: theWolfImage,
            removeBodyCover: true,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "WOLF KNIGHT",
            src: bodyWolfKnightImage,
            frontSrc: bodyHeadCoverImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SWORD",
                backSrc: weaponsSwordImage,
            },
            {
                value: 1,
                label: "AXE",
                backSrc: weaponsAxeImage,
            },
            {
                value: 2,
                label: "RIFLE",
                frontSrc: weaponsRifleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "TATTERED CLOAK",
            src: secondaryWeaponsTatteredCloakImage,
        },
        {
            value: 1,
            label: "CLOAK",
            src: secondaryWeaponsCloakImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "DARK CLOAK",
            src: secondaryWeaponsDarkCloakImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "CAMOFLAUGE CLOAK",
            src: secondaryWeaponsCamoCloakImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SWORD HOLSTER",
            frontSrc: rearMountSwordHolsterImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "THE WOLF",
            frontSrc: theWolfGlowImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "CLOAK",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "CLOAK",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
