import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Heavy-Transport-Chopper`;

// BODY
const bodyTransportAirborneImage = `${imagePath}/Chassis - Transport Airborne.png`;
const bodyTransportGroundedUnloadedImage = `${imagePath}/Chassis - Transport Grounded Unloaded.png`;
const bodyTransportGroundedImage = `${imagePath}/Chassis - Transport Grounded.png`;
const bodySoloContainerImage = `${imagePath}/Chassis - Solo Container.png`;

// REAR MOUNT
const bladesAtRestImage = `${imagePath}/rear/Blades - Static.png`;
const bladesInMotionImage = `${imagePath}/rear/Blades - In Motion.png`;

// ACCESSORY
const accBallTurretsImage = `${imagePath}/Weapons - Ball Turrets.png`;
const accMissilesImage = `${imagePath}/Weapons - Missiles.png`;
const accWingMissilesImage = `${imagePath}/Weapons - Wing Missiles.png`;
const accAllMissilesImage = `${imagePath}/Weapons - All Missiles.png`;

// GLOW
const glowEngineImage = `${imagePath}/Glow - Engine.png`;

const previewImage = `${imagePath}/preview.png`;

const airborneShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-32px",
    right: "-55px",
};

const groundedShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "108px",
    right: "0px",
};

const groundedContainerShadow = {
    size: shadowTypes.LARGE,
    bottom: "138px",
    right: "35px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    isMegadeusPlus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "1",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AT REST",
            foremostSrc: bladesAtRestImage,
        },
        {
            value: 1,
            label: "IN MOTION",
            foremostSrc: bladesInMotionImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TRANSPORT - AIRBORNE",
            src: bodyTransportAirborneImage,
            shadowDetails: airborneShadow,
        },
        {
            value: 1,
            label: "TRANSPORT - GROUNDED - UNLOADED",
            src: bodyTransportGroundedUnloadedImage,
            shadowDetails: groundedShadow,
        },
        {
            value: 2,
            label: "TRANSPORT - GROUNDED",
            src: bodyTransportGroundedImage,
            shadowDetails: groundedShadow,
        },
        {
            value: 3,
            label: "SOLO CONTAINER",
            src: bodySoloContainerImage,
            shadowDetails: groundedContainerShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "ENGINE",
                src: glowEngineImage,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BALL TURRETS",
            src: accBallTurretsImage,
        },
        {
            value: 1,
            label: "MISSILES",
            src: accMissilesImage,
        },
        {
            value: 2,
            label: "WING MISSILES",
            src: accWingMissilesImage,
        },
        {
            value: 3,
            label: "ALL MISSILES",
            src: accAllMissilesImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BLADES",
            value: "head",
        },
        {
            label: "GLOW",
            value: "weapon",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "BLADES",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "GLOW",
        "secondaryWeapon": "EMPTY",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
