import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";

import BaseURL from "./../Unit/data/images/Hosting-BaseURL";
import { ShowLandingContext } from "./../Landing/ShowLandingContextWrapper";
import { CurrentThemeContext } from "./ThemeContextWrapper";

const voidAnim = keyframes`
    0% {
        background-position: bottom right; /*OR bottom right*/
    }

    100% {
        background-position: top left; /*OR top left*/
    }
`;

const AppMainBackground = styled.div`
    background-color: ${props => props.showLanding ? props.theme.landingBackground : "transparent"};
    transition-duration: ${props => `${props.theme.landingTransitionDuration}s`};
    transition-property: background-color;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${props => props.showLanding ? 2 : -1};

    &.void {
        background-color: ${props => props.showLanding ? props.theme.landingBackground : props.theme.mainBackground};
        background-image: url(${BaseURL}/background/grid.png);
        animation: ${voidAnim} infinite 600s linear;
        /* animation-name: ${voidAnim};
        animation-duration: 600s;
        animation-iteration-count: infinite; */
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        max-width: 100vw;
    }

    @media print {
        background-color: #FFF;
    }
`;

AppMainBackground.displayName = "AppMainBackground";

function AppMainBackgroundProvider() {
    const { showLanding } = useContext(ShowLandingContext);
    const { currentTheme } = useContext(CurrentThemeContext);

    return (
        <AppMainBackground
            className={currentTheme === "lvoid" || currentTheme === "dvoid" ? "void" : ""}
            showLanding={showLanding}
        />
    );
}

export default AppMainBackgroundProvider;
