import React, { useContext } from "react";
import styled from "styled-components";

import { UserAuthContext } from "./../User/UserAuthContextWrapper";
import { CurrentThemeContext } from "./../common/ThemeContextWrapper";

import MembershipTierImages from "../common/MembershipTierImages";
import SpriteImage from "../common/styles/SpriteImage";

const MembershipTierDisplay = styled.div`
    align-content: center;
    align-items: center;
    border-width: 4px 0 4px 4px;
    color: #FFF;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: bold;
    justify-content: flex-start;
    padding: 10px 0px;
    width: ${props => props.tierWidth};
    margin-left: ${props => props.tierOffset};
    position: relative;

    img, div {
        margin-top: 2px;
        margin-left: 10px;

        :last-child {
            height: 19px;

            @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
                display: none;
            }
        }
    }

    &.fantasy {
        background: linear-gradient(to bottom right, #666 0%, #333 100%);
        box-shadow: 0 0 2px 1px #000;
        border-color: #5A5A5A;
        border-style: outset;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
    }

    &.mech {
        background: #666;
        border-color: transparent;
        border-style: solid;
        // Clip Bottom Left Corner
        clip-path: polygon(0% 0%,100% 0%,100% 100%,20px 100%,0% calc(100% - 20px));
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 70px;
        margin-left: auto;
    }
`;

MembershipTierDisplay.displayName = "MembershipTierDisplay";

function MembershipTier() {
    const { user } = useContext(UserAuthContext);
    const { currentSide } = useContext(CurrentThemeContext);

    let membershipLevel = "mundaneTier";
    let tierWidth = "390px";
    let tierOffset = "-155px";

    if (user && user.isLegendaryUser) {
        membershipLevel = "legendaryTier";
        tierWidth = "410px";
        tierOffset = "-175px";
    } else if (user && user.isPremiumUser) {
        membershipLevel = "plusOneTier";
        tierOffset = "-30px";
    }

    return (
        <MembershipTierDisplay
            className={currentSide}
            tierOffset={tierOffset}
            tierWidth={tierWidth}
        >
            <img src={MembershipTierImages[membershipLevel].emblem} alt={MembershipTierImages[membershipLevel].alt} />
            <SpriteImage className={MembershipTierImages[membershipLevel].textSprite} alt={MembershipTierImages[membershipLevel].alt} />
        </MembershipTierDisplay>
    );
}

export default MembershipTier;
