import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/BDF-Khanda`;

// BODY
const chassisImage = `${imagePath}/Chassis.png`;

// HEADS
const headKhandaImage = `${imagePath}/heads/Head - Khanda.png`;
const headAngularImage = `${imagePath}/heads/Head - Angular.png`;
const headBlackbeardImage = `${imagePath}/heads/Head - Blackbeard.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Black Spot.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headFactoryStandardImage = `${imagePath}/heads/Head - Factory Standard.png`;
const headFrontlineImage = `${imagePath}/heads/Head - Frontline.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headJudicatorImage = `${imagePath}/heads/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/Head - Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headRainImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Transformed - Hair.png`;
const headScarabImage = `${imagePath}/heads/Head - Scarab.png`;
const headStampedeImage = `${imagePath}/heads/Head - Stampede.png`;
const headTaurusImage = `${imagePath}/heads/Head - Taurus.png`;
const headVanguardImage = `${imagePath}/heads/Head - Vanguard.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// ARMS
const armsAlseidesImage = `${imagePath}/arms/Arms - Alseides.png`;
const armsAxialImage = `${imagePath}/arms/Arms - Axial.png`;
const armsKhandaImage = `${imagePath}/arms/Arms - Khanda.png`;
const armsMissileLaunchersImage = `${imagePath}/arms/Arms - Missile Launchers.png`;

// SHOULDERS
const shoulderAlseidesImage = `${imagePath}/shoulders/Shoulders - Alseides.png`;
const shoulderAsleidesBackImage = `${imagePath}/shoulders/Shoulders Under - Asleides.png`;
const shoulderFerrofluidImage = `${imagePath}/shoulders/Shoulders - Ferrofluid.png`;
const shoulderFerrofluidArmsImage = `${imagePath}/shoulders/Shoulders - Ferrofluid Arms.png`;
const shoulderFerrofluidBackImage = `${imagePath}/shoulders/Shoulders Under - Ferrofluid.png`;
const shoulderKhandaCloakImage = `${imagePath}/shoulders/Shoulders - Khanda Cloak.png`;
const shoulderKhandaCloakDeployedImage = `${imagePath}/shoulders/Shoulders - Khanda Cloak Deployed.png`;
const shoulderKhandaDeployedImage = `${imagePath}/shoulders/Shoulders - Khanda Deployed.png`;
const shoulderKhandaImage = `${imagePath}/shoulders/Shoulders - Khanda.png`;
const shoulderKhandaBackImage = `${imagePath}/shoulders/Shoulders Under - Khanda.png`;

// GLOW
const glowAlseidesImage = `${imagePath}/glows/Glow - Shoulders - Alseides.png`;
const glowKhandaCloakImage = `${imagePath}/glows/Glow - Shoulders - Khanda Cloak.png`;
const glowKhandaDeployedImage = `${imagePath}/glows/Glow - Shoulders - Khanda Deployed.png`;
const glowKhandaBackImage = `${imagePath}/glows/Shoulders Under - Glow - Khanda.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const coreImage = `${imagePath}/KHANDA_by_AzMiLion.png`;
const coreImage1 = `${imagePath}/BDF_Khanda_core_color-Volkenhammer.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "12px",
    right: "-10px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: coreImage1,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Volkenhammer",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "3",
        "secondaryWeaponValue": "2",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "KHANDA",
            foremostSrc: headKhandaImage,
        },
        {
            value: 1,
            label: "ANGULAR",
            foremostSrc: headAngularImage,
        },
        {
            value: 2,
            label: "BLACKBEARD",
            foremostSrc: headBlackbeardImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
        },
        {
            value: 4,
            label: "BRIAR",
            foremostSrc: headBriarImage,
        },
        {
            value: 5,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 6,
            label: "FACTORY STANDARD",
            foremostSrc: headFactoryStandardImage,
        },
        {
            value: 7,
            label: "FRONTLINE",
            foremostSrc: headFrontlineImage,
        },
        {
            value: 8,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 9,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 10,
            label: "JUDICATOR",
            foremostSrc: headJudicatorImage,
        },
        {
            value: 11,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 12,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 13,
            label: "LUDONAUT",
            foremostSrc: headLudonautImage,
        },
        {
            value: 14,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 15,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 16,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 17,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 18,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 19,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 20,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 21,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 22,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 23,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 24,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 25,
            label: "NELSON",
            foremostSrc: headNelsonImage,
        },
        {
            value: 26,
            label: "PREDATOR",
            foremostSrc: headPredatorImage,
        },
        {
            value: 27,
            label: "RAIN",
            foremostSrc: headRainImage,
        },
        {
            value: 28,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
        },
        {
            value: 29,
            label: "SCARAB",
            foremostSrc: headScarabImage,
        },
        {
            value: 30,
            label: "STAMPEDE",
            foremostSrc: headStampedeImage,
        },
        {
            value: 31,
            label: "TAURUS",
            foremostSrc: headTaurusImage,
        },
        {
            value: 32,
            label: "VANGUARD",
            foremostSrc: headVanguardImage,
        },
        {
            value: 33,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BDF KHANDA",
            src: chassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Clem Cuddlecakes, original by",
                title: "Zerovirus",
                url: "https://www.artstation.com/drazelic",
            },
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "ALSEIDES",
                src: shoulderAlseidesImage,
                backSrc: shoulderAsleidesBackImage,
            },
            {
                value: 1,
                label: "FERROFLUID",
                src: shoulderFerrofluidImage,
                backSrc: shoulderFerrofluidBackImage,
            },
            {
                value: 2,
                label: "FERROFLUID ARMS",
                src: shoulderFerrofluidArmsImage,
                backSrc: shoulderFerrofluidBackImage,
            },
            {
                value: 3,
                label: "KHANDA",
                src: shoulderKhandaImage,
            },
            {
                value: 4,
                label: "KHANDA - DEPLOYED",
                src: shoulderKhandaDeployedImage,
            },
            {
                value: 5,
                label: "KHANDA + CLOAK",
                src: shoulderKhandaCloakImage,
                backSrc: shoulderKhandaBackImage,
            },
            {
                value: 6,
                label: "KHANDA + CLOAK - DEPLOYED",
                src: shoulderKhandaCloakDeployedImage,
                backSrc: shoulderKhandaBackImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ALSEIDES",
            src: armsAlseidesImage,
        },
        {
            value: 1,
            label: "AXIAL",
            src: armsAxialImage,
        },
        {
            value: 2,
            label: "KHANDA",
            src: armsKhandaImage,
        },
        {
            value: 3,
            label: "MISSILE LAUNCHERS",
            src: armsMissileLaunchersImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ALSEIDES - SHOULDERS",
            frontSrc: glowAlseidesImage,
        },
        {
            value: 1,
            label: "KHANDA - CLOAK",
            frontSrc: glowKhandaCloakImage,
            backSrc: glowKhandaBackImage,
        },
        {
            value: 2,
            label: "KHANDA - DEPLOYED - SHOULDERS",
            frontSrc: glowKhandaDeployedImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "SHOULDERS",
            value: "weapon",
        },
        {
            label: "ARMS",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "SHOULDERS",
        "secondaryWeapon": "ARMS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
