import styled from "styled-components";

import Unit from "./Unit";

import BaseURL from "./../data/images/Hosting-BaseURL";

const unitBorderImage = `${BaseURL}/buttons/unit-selected-border.png`;

export const emptyImage = `${BaseURL}/background/empty.png`;

const UnitImage = styled.img`
    border: 5px solid transparent;
    max-width: 100%;
    min-width: 150px;
    min-height: 150px;
    color: transparent;

    ${Unit}:hover & {
        border-image-repeat: round;
        border-image-slice: 30;
        border-image-source: url(${unitBorderImage});
        border-image-width: 5;
    }

    &.mirrored {
        transform: scaleX(-1);
    }

    ${props =>
        props.auraColor ?
        `filter: drop-shadow(0 0 0.4rem ${props.auraColor});` :
        ""
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        border-image-repeat: round;
        border-image-slice: 30;
        border-image-source: url(${unitBorderImage});
        border-image-width: 5;
    }
`;

UnitImage.displayName = "UnitImage";

export const SmallUnitImage = styled(UnitImage)`
    min-width: ${props => props.width}px;
    min-height: ${props => props.height}px;
`;

SmallUnitImage.displayName = "SmallUnitImage";

export default UnitImage;
