import styled from "styled-components";

const BorderWindowContent = styled.div`
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        margin: 10px;
        padding: 0;
    }
`;

BorderWindowContent.displayName = "BorderWindowContent";

export default BorderWindowContent;
