import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/GMS`;

// CHASSIS
const chassisAImage = `${imagePath}/chassis/Chassis A.png`;
const chassisBImage = `${imagePath}/chassis/Chassis B.png`;
const chassisCImage = `${imagePath}/chassis/Chassis C.png`;
const chassisDImage = `${imagePath}/chassis/Chassis D.png`;
const chassisEImage = `${imagePath}/chassis/Chassis E.png`;
const chassisFImage = `${imagePath}/chassis/Chassis F.png`;
const chassisGImage = `${imagePath}/chassis/Chassis G.png`;
const chassisHImage = `${imagePath}/chassis/Chassis H.png`;
const chassisIImage = `${imagePath}/chassis/Chassis - Frontline.png`;
const chassisAssaultImage = `${imagePath}/chassis/Chassis - Assault.png`;
const chassisVanguardImage = `${imagePath}/chassis/Chassis - Vanguard.png`;
const chassisHackerImage = `${imagePath}/chassis/Chassis - Hacker.png`;
const chassisAdamantImage = `${imagePath}/chassis/Chassis - Adamant.png`;
const chassisCannonSImage = `${imagePath}/chassis/Chassis - Cannon S.png`;
const chassisCannonLImage = `${imagePath}/chassis/Chassis - Cannon L.png`;
const chassisInnerSphereLImage = `${imagePath}/chassis/Chassis - Inner Sphere.png`;
const chassisLumberPuppyImage = `${imagePath}/chassis/Chassis - Lumber Puppy.png`;
const chassisBrimstone2Image = `${imagePath}/chassis/Chassis - Brimstone 2.png`;
const chassisScoutImage = `${imagePath}/chassis/Chassis - Scout.png`;
const chassisPangolinImage = `${imagePath}/chassis/Chassis - Pangolin.png`;

// HEADS
const headAImage = `${imagePath}/heads/Head A.png`;
const headBImage = `${imagePath}/heads/Head B.png`;
const headCImage = `${imagePath}/heads/Head C.png`;
const headDImage = `${imagePath}/heads/Head D.png`;
const headEImage = `${imagePath}/heads/Head E.png`;
const headFImage = `${imagePath}/heads/Head F.png`;
const headGImage = `${imagePath}/heads/Head G.png`;
const headHImage = `${imagePath}/heads/Head H.png`;
const headIImage = `${imagePath}/heads/Head I.png`;
const headJImage = `${imagePath}/heads/Head J.png`;
const headKImage = `${imagePath}/heads/Head K.png`;
const headLImage = `${imagePath}/heads/Head L.png`;
const headMImage = `${imagePath}/heads/Head M.png`;
const headNImage = `${imagePath}/heads/Head N.png`;
const headOImage = `${imagePath}/heads/Head - Frontline.png`;
const headAssaultImage = `${imagePath}/heads/Head - Assault.png`;
const headVanguardImage = `${imagePath}/heads/Head - Vanguard.png`;
const headStalwartImage = `${imagePath}/heads/Head - Stalwart.png`;
const headSupportImage = `${imagePath}/heads/Head - Support.png`;
const headPredatorImage = `${imagePath}/heads/Head - Predator.png`;
const headAtomsKImage = `${imagePath}/heads/Head - Atoms K.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headBriarImage = `${imagePath}/heads/Head - Briar.png`;
const headDayglowSkullImage = `${imagePath}/heads/Head - Dayglow Skull.png`;
const headIronSkullImage = `${imagePath}/heads/Head - Iron Skull.png`;
const headDwarfImage = `${imagePath}/heads/Head - Dwarf.png`;
const headPartisanImage = `${imagePath}/heads/Head - Partisan.png`;
const headBulkheadImage = `${imagePath}/heads/Head - Bulkhead.png`;
const headCockpitImage = `${imagePath}/heads/Head - Cockpit.png`;
const headDeadboltImage = `${imagePath}/heads/Head - Deadbolt.png`;
const headMainCannonImage = `${imagePath}/heads/Head - Main Cannon.png`;
const headRipAndTearImage = `${imagePath}/heads/Head - Rip and Tear.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headDungamWingImage = `${imagePath}/heads/Head - Dungam 2.png`;
const headDualCannonImage = `${imagePath}/heads/Head - Dual Cannon.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headBlackSpotLeftImage = `${imagePath}/heads/Head - Black Spot Left.png`;
const headBlackSpotRightImage = `${imagePath}/heads/Head - Black Spot Right.png`;
const headScopehoundImage = `${imagePath}/heads/Head - Scopehound.png`;
const headWeeb1Image = `${imagePath}/heads/Head - Weeb 1.png`;
const headWeeb2Image = `${imagePath}/heads/Head - Weeb 2.png`;
const headWeeb3Image = `${imagePath}/heads/Head - Weeb 3.png`;
const headWeeb4Image = `${imagePath}/heads/Head - Weeb 4.png`;
const headWeeb5Image = `${imagePath}/heads/Head - Weeb 5.png`;
const headWeeb6Image = `${imagePath}/heads/Head - Weeb 6.png`;
const headWeeb7Image = `${imagePath}/heads/Head - Weeb 7.png`;
const headWeeb8Image = `${imagePath}/heads/Head - Weeb 8.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_GMS.png`;

// ARMS 1H LEFT
const leftArm1HAImage = `${imagePath}/1H arms/1H1H Left Arm A.png`;
const leftArm1HBImage = `${imagePath}/1H arms/1H1H Left Arm B.png`;
const leftArm1HCImage = `${imagePath}/1H arms/1H1H Left Arm C.png`;
const leftArm1HDImage = `${imagePath}/1H arms/1H1H Left Arm D.png`;
const leftArm1HEImage = `${imagePath}/1H arms/1H1H Left Arm E.png`;
const leftArm1HFImage = `${imagePath}/1H arms/1H1H Left Arm F.png`;
const leftArm1HGImage = `${imagePath}/1H arms/1H1H Left Arm G.png`;
const leftArm1HHImage = `${imagePath}/1H arms/1H1H Left Arm H.png`;
const leftArm1HIImage = `${imagePath}/1H arms/1H1H Left Arm - Frontline.png`;
const leftArm1HAssaultImage = `${imagePath}/1H arms/1H1H Left Arm Assault.png`;
const leftArm1HSupportImage = `${imagePath}/1H arms/1H1H Left Arm Support.png`;
const leftArm1HScoutCannonImage = `${imagePath}/1H arms/Left Arm - Scout Cannon.png`;
const leftArm1HScoutGatlingImage = `${imagePath}/1H arms/Left Arm - Scout Gatling.png`;
const leftArm1HScoutRocketPodImage = `${imagePath}/1H arms/Left Arm - Scout Rocket Pod.png`;
const leftArm1HPangolinImage = `${imagePath}/1H arms/1H1H Left Arm Pangolin.png`;

// ARMS 1H RIGHT
const rightArm1HAImage = `${imagePath}/1H arms/1H1H Right Arm A.png`;
const rightArm1HBImage = `${imagePath}/1H arms/1H1H Right Arm B.png`;
const rightArm1HCImage = `${imagePath}/1H arms/1H1H Right Arm C.png`;
const rightArm1HDImage = `${imagePath}/1H arms/1H1H Right Arm D.png`;
const rightArm1HEImage = `${imagePath}/1H arms/1H1H Right Arm E.png`;
const rightArm1HFImage = `${imagePath}/1H arms/1H1H Right Arm F.png`;
const rightArm1HGImage = `${imagePath}/1H arms/1H1H Right Arm G.png`;
const rightArm1HHImage = `${imagePath}/1H arms/1H1H Right Arm H.png`;
const rightArm1HIImage = `${imagePath}/1H arms/1H1H Right Arm - Frontline.png`;
const rightArm1HAssaultImage = `${imagePath}/1H arms/1H1H Right Arm Assault.png`;
const rightArm1HSupportImage = `${imagePath}/1H arms/1H1H Right Arm Support.png`;
const rightArm1HScoutCannonImage = `${imagePath}/1H arms/Right Arm - Scout Cannon.png`;
const rightArm1HScoutGatlingImage = `${imagePath}/1H arms/Right Arm - Scout Gatling.png`;
const rightArm1HScoutRocketPodImage = `${imagePath}/1H arms/Right Arm - Scout Rocket Pod.png`;
const rightArm1HPangolinImage = `${imagePath}/1H arms/1H1H Right Arm Pangolin.png`;

// ARM SKELETONS
const arm1HSkeletonAImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms A.png`;
const arm1HSkeletonBImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms B.png`;
const arm1HSkeletonCImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms C.png`;
const arm1HSkeletonDImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms D.png`;
const arm1HSkeletonEImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms E.png`;
const arm1HSkeletonFImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms F.png`;
const arm1HSkeletonGImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms G.png`;
const arm1HSkeletonHImage = `${imagePath}/1H arms/Skeleton for 1H1H Arms H.png`;

// ARMS 2H LEFT
const leftArm2HAImage = `${imagePath}/2H arms/2H Left Arm A.png`;
const leftArm2HBImage = `${imagePath}/2H arms/2H Left Arm B.png`;
const leftArm2HCImage = `${imagePath}/2H arms/2H Left Arm C.png`;
const leftArm2HDImage = `${imagePath}/2H arms/2H Left Arm D.png`;
const leftArm2HEImage = `${imagePath}/2H arms/2H Left Arm E.png`;
const leftArm2HFImage = `${imagePath}/2H arms/2H Left Arm F.png`;
const leftArm2HGImage = `${imagePath}/2H arms/2H Left Arm G.png`;
const leftArm2HHImage = `${imagePath}/2H arms/2H Left Arm H.png`;
const leftArm2HIImage = `${imagePath}/2H arms/2H Left Arm - Frontline.png`;
const leftArm2HAssaultImage = `${imagePath}/2H arms/2H Left Arm - Assault.png`;
const leftArm2HSupportImage = `${imagePath}/2H arms/2h Left Arm Support.png`;
const leftArm2HPangolinImage = `${imagePath}/2H arms/2H Left Arm Pangolin.png`;

// ARMS 2H RIGHT
const rightArm2HAImage = `${imagePath}/2H arms/2H Right Arm A.png`;
const rightArm2HBImage = `${imagePath}/2H arms/2H Right Arm B.png`;
const rightArm2HCImage = `${imagePath}/2H arms/2H Right Arm C.png`;
const rightArm2HDImage = `${imagePath}/2H arms/2H Right Arm D.png`;
const rightArm2HEImage = `${imagePath}/2H arms/2H Right Arm E.png`;
const rightArm2HFImage = `${imagePath}/2H arms/2H Right Arm F.png`;
const rightArm2HGImage = `${imagePath}/2H arms/2H Right Arm G.png`;
const rightArm2HHImage = `${imagePath}/2H arms/2H Right Arm H.png`;
const rightArm2HIImage = `${imagePath}/2H arms/2H Right Arm - Frontline.png`;
const rightArm2HAssaultImage = `${imagePath}/2H arms/2h Right Arm Assault.png`;
const rightArm2HSupportImage = `${imagePath}/2H arms/2h Right Arm Support.png`;
const rightArm2HPangolinImage = `${imagePath}/2H arms/2H Right Arm Pangolin.png`;

// ARM SKELETONS
const arm2HSkeletonAImage = `${imagePath}/2H arms/Skeleton for 2H Arms A.png`;
const arm2HSkeletonBImage = `${imagePath}/2H arms/Skeleton for 2H Arms B.png`;
const arm2HSkeletonCImage = `${imagePath}/2H arms/Skeleton for 2H Arms C.png`;
const arm2HSkeletonDImage = `${imagePath}/2H arms/Skeleton for 2H Arms D.png`;
const arm2HSkeletonEImage = `${imagePath}/2H arms/Skeleton for 2H Arms E.png`;
const arm2HSkeletonFImage = `${imagePath}/2H arms/Skeleton for 2H Arms F.png`;
const arm2HSkeletonGImage = `${imagePath}/2H arms/Skeleton for 2H Arms G.png`;
const arm2HSkeletonHImage = `${imagePath}/2H arms/Skeleton for 2H Arms H.png`;

// ARMS HEAVY LEFT
const leftArmHeavyAImage = `${imagePath}/heavy arms/Heavy Left Arm A.png`;
const leftArmHeavyBImage = `${imagePath}/heavy arms/Heavy Left Arm B.png`;
const leftArmHeavyCImage = `${imagePath}/heavy arms/Heavy Left Arm C.png`;
const leftArmHeavyDImage = `${imagePath}/heavy arms/Heavy Left Arm D.png`;
const leftArmHeavyEImage = `${imagePath}/heavy arms/Heavy Left Arm E.png`;
const leftArmHeavyFImage = `${imagePath}/heavy arms/Heavy Left Arm F.png`;
const leftArmHeavyGImage = `${imagePath}/heavy arms/Heavy Left Arm G.png`;
const leftArmHeavyHImage = `${imagePath}/heavy arms/Heavy Left Arm H.png`;
const leftArmHeavyIImage = `${imagePath}/heavy arms/Heavy Left Arm - Frontline.png`;
const leftArmHeavyAssaultImage = `${imagePath}/heavy arms/Heavy Left Arm Assault.png`;
const leftArmHeavySupportImage = `${imagePath}/heavy arms/Heavy Left Arm Support.png`;
const leftArmHeavyPangolinImage = `${imagePath}/heavy arms/Left Arm Heavy Pangolin.png`;

// ARMS HEAVY RIGHT
const rightArmHeavyAImage = `${imagePath}/heavy arms/Heavy Right Arm A.png`;
const rightArmHeavyBImage = `${imagePath}/heavy arms/Heavy Right Arm B.png`;
const rightArmHeavyCImage = `${imagePath}/heavy arms/Heavy Right Arm C.png`;
const rightArmHeavyDImage = `${imagePath}/heavy arms/Heavy Right Arm D.png`;
const rightArmHeavyEImage = `${imagePath}/heavy arms/Heavy Right Arm E.png`;
const rightArmHeavyFImage = `${imagePath}/heavy arms/Heavy Right Arm F.png`;
const rightArmHeavyGImage = `${imagePath}/heavy arms/Heavy Right Arm G.png`;
const rightArmHeavyHImage = `${imagePath}/heavy arms/Heavy Right Arm H.png`;
const rightArmHeavyIImage = `${imagePath}/heavy arms/Heavy Right Arm - Frontline.png`;
const rightArmHeavyAssaultImage = `${imagePath}/heavy arms/Heavy Right Arm Assault.png`;
const rightArmHeavySupportImage = `${imagePath}/heavy arms/Heavy Right Arm Support.png`;
const rightArmHeavyPangolinImage = `${imagePath}/heavy arms/Right Arm Heavy Pangolin.png`;

// ARM SKELETONS
const armHeavySkeletonAImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms A.png`;
const armHeavySkeletonBImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms B.png`;
const armHeavySkeletonCImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms C.png`;
const armHeavySkeletonDImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms D.png`;
const armHeavySkeletonEImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms E.png`;
const armHeavySkeletonFImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms F.png`;
const armHeavySkeletonGImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms G.png`;
const armHeavySkeletonHImage = `${imagePath}/heavy arms/Skeleton for Heavy Arms H.png`;
const armHeavySkeletonAssaultImage = `${imagePath}/heavy arms/Skeleton for Assault.png`;
const armHeavySkeletonSupportImage = `${imagePath}/heavy arms/Skeleton for Support.png`;
const armHeavySkeletonPangolinImage = `${imagePath}/heavy arms/Skeleton - Left Arm Heavy Pangolin.png`;

// LEGS
const legsAImage = `${imagePath}/legs/Legs A.png`;
const legsBImage = `${imagePath}/legs/Legs B.png`;
const legsCImage = `${imagePath}/legs/Legs C.png`;
const legsDImage = `${imagePath}/legs/Legs D.png`;
const legsEImage = `${imagePath}/legs/Legs E.png`;
const legsFImage = `${imagePath}/legs/Legs F.png`;
const legsGImage = `${imagePath}/legs/Legs G.png`;
const legsHImage = `${imagePath}/legs/Legs H.png`;
const legsIImage = `${imagePath}/legs/Legs I.png`;
const legsJImage = `${imagePath}/legs/Legs - Frontline.png`;
const legsAssaultImage = `${imagePath}/legs/Legs - Assault.png`;
const legsVanguardImage = `${imagePath}/legs/Legs - Vanguard.png`;
const legsSniperImage = `${imagePath}/legs/Legs - Sniper.png`;
const legsHackerImage = `${imagePath}/legs/Legs - Hacker.png`;
const legsSupportImage = `${imagePath}/legs/Legs - Support.png`;
const legsQuadLImage = `${imagePath}/legs/Legs - Quad L.png`;
const legsStriderImage = `${imagePath}/legs/Legs - Strider.png`;
const legsTreadsImage = `${imagePath}/legs/Legs - Treads.png`;
const legsTurretBaseImage = `${imagePath}/legs/Legs - Turret Base.png`;
const legsSmallTreadsImage = `${imagePath}/legs/Legs - Small Treads.png`;
const legsBulwarkImage = `${imagePath}/legs/Legs - Bulwark.png`;
const legsLumberPuppyImage = `${imagePath}/legs/Legs - Lumber Puppy.png`;
const legsLowProfileTreadsImage = `${imagePath}/legs/Legs - Treads Low Profile.png`;
const legsLowProfileSmallTreadsImage = `${imagePath}/legs/Legs - Treads Small Low Profiles.png`;
const legsScoutImage = `${imagePath}/legs/Legs - Scout.png`;
const legsPangolinImage = `${imagePath}/legs/Legs - Pangolin.png`;

// REAR MOUNT
const rearMountARImage = `${imagePath}/rear mount/Back AR.png`;
const rearMountRPGImage = `${imagePath}/rear mount/Back RPG.png`;
const rearMountSwordImage = `${imagePath}/rear mount/Back Sword.png`;
const rearMountNexusImage = `${imagePath}/rear mount/Back Nexus A.png`;
const rearMountNexus2Image = `${imagePath}/rear mount/Back Nexus B.png`;
const rearMountHowitzerImage = `${imagePath}/rear mount/Back Howitzer.png`;
const rearMountDualHowitzerImage = `${imagePath}/rear mount/Back Dual Howitzers.png`;
const rearMountHowitzerGrandeImage = `${imagePath}/rear mount/Rear - Howitzer Grande.png`;
const rearMountMissile1Image = `${imagePath}/rear mount/Back Missile Rack.png`;
const rearMountMissile2Image = `${imagePath}/rear mount/Back Missile Rack 2.png`;
const rearMountCommArrayImage = `${imagePath}/rear mount/Back Comm Array.png`;
const rearMountSensorArrayImage = `${imagePath}/rear mount/Back Sensor Array.png`;
const rearMountHowitzerCImage = `${imagePath}/rear mount/Rear - Howitzer.png`;
const rearMountDeployCoverImage = `${imagePath}/rear mount/Rear - Deployable Cover.png`;
const rearMountTurretsImage = `${imagePath}/rear mount/Rear - Turret Rack x4.png`;
const rearMountTurretsCoverImage = `${imagePath}/rear mount/Rear - Turret Rack and Deployable Cover.png`;
const rearMountTurretsFrontImage = `${imagePath}/rear mount/Rear - Turret Rack x4 Over.png`;
const rearMountTurretsCoverFrontImage = `${imagePath}/rear mount/Rear - Turret Rack and Deployable Cover Over.png`;
const rearMountMortarImage = `${imagePath}/rear mount/Rear - Mortar.png`;
const rearMountMortarLeftImage = `${imagePath}/rear mount/Rear - Mortar 2.png`;
const rearMountFlightHowitzerImage = `${imagePath}/rear mount/Rear - Boosters Howitzer C.png`;
const rearMountFlightImage = `${imagePath}/rear mount/Rear Flight Unit.png`;
const rearMountBoostersImage = `${imagePath}/rear mount/Rear Boosters.png`;
const rearMountFlight2Image = `${imagePath}/rear mount/Rear Flight Unit 2 (no over).png`;
const rearMountFlight3Image = `${imagePath}/rear mount/Rear Flight Unit 3.png`;
const rearMountFlightFrontImage = `${imagePath}/rear mount/Rear Flight Unit OVER.png`;
const rearMountMissileRackBladeImage = `${imagePath}/rear mount/Rear - Missile Rack and Blade.png`;
const canisterRifleFrontImage = `${imagePath}/rear mount/Rear - Large Rifle and Canister Straps.png`;
const canisterRifleBackImage = `${imagePath}/rear mount/Rear - Large Rifle and Canister.png`;
const rearMountBoosters2Image = `${imagePath}/rear mount/Rear - Boosters 2.png`;
const rearMountBoosters2FrontImage = `${imagePath}/rear mount/Rear - Boosters 2 Over.png`;

// ACCESSORY
const ammoBandolierImage = `${imagePath}/accessories/Ammo Bandolier.png`;
const backpackImage = `${imagePath}/accessories/Backpack.png`;
const granadeBandImage = `${imagePath}/accessories/Grenade Band.png`;
const manipulatorArmsImage = `${imagePath}/accessories/Manipulators for A,B,C,E,G,H.png`;
const manipulatorArmsAltImage = `${imagePath}/accessories/Manipulators for D,F.png`;
const knifeImage = `${imagePath}/accessories/Knife.png`;
const knifeLeftImage = `${imagePath}/accessories/Accessory - Left Arm Knife.png`;
const packsImage = `${imagePath}/accessories/Packs.png`;
const pistolHolsterImage = `${imagePath}/accessories/Pistol Holster.png`;
const pistolHolsterStrapImage = `${imagePath}/accessories/Pistol Holster Straps.png`;
// const pistolHolsterArmImage = `${imagePath}/accessories/Pistol Holster Arm.png`;
const turretsBackImage = `${imagePath}/accessories/Turrets Back.png`;
const turretsFrontImage = `${imagePath}/accessories/Turrets Front.png`;
const cloakTopHoodImage = `${imagePath}/accessories/Cloak - Top - Hood Up.png`;
const cloakTopImage = `${imagePath}/accessories/Cloak - Top.png`;
const cloakMidImage = `${imagePath}/accessories/Cloak - Mid.png`;
const cloakBackImage = `${imagePath}/accessories/Cloak - Back.png`;
const canisterBackImage = `${imagePath}/accessories/Accessory - Rear Canister.png`;
const canisterFrontImage = `${imagePath}/accessories/Accessory - Rear Canister Strap.png`;
const rifleBackImage = `${imagePath}/accessories/Accessory - Rear Rifle.png`;
const rifleFrontImage = `${imagePath}/accessories/Accessory - Rear Rifle Strap.png`;
const accessoryBladeImage = `${imagePath}/accessories/Accessory - Rear Blade Mount.png`;
const accessoryBladeRightFrontImage = `${imagePath}/accessories/Accessory - Right Arm Blade Mount Over.png`;
const accessoryBladeRightBackImage = `${imagePath}/accessories/Accessory - Right Arm Blade Mount Under.png`;
const keffiyaTop = `${imagePath}/accessories/Keffiya Over.png`;
const keffiyaMid = `${imagePath}/accessories/Keffiya Under.png`;
const missilePodsBehind = `${imagePath}/accessories/Accessory Behind - Missile Pods.png`;
const missilePodsFront = `${imagePath}/accessories/Accessory Front - Missile Pods.png`;

// 2H
const ar2HAImage = `${imagePath}/weapons/2H/2H AR A.png`;
const ar2HAScopeImage = `${imagePath}/weapons/2H/2H Weapon - AR-A Scope.png`;
const argl2HAImage = `${imagePath}/weapons/2H/2H AR A GL.png`;
const argl2HAScopeImage = `${imagePath}/weapons/2H/2H Weapon - AR-A GL Scope.png`;
const ar2HBImage = `${imagePath}/weapons/2H/2H AR B.png`;
const ar2HBScopeImage = `${imagePath}/weapons/2H/2H Weapon - AR-B Scope.png`;
const ar2HCImage = `${imagePath}/weapons/2H/2H AR C.png`;
const ar2HCScopeImage = `${imagePath}/weapons/2H/2H Weapon - AR-C Scope.png`;
const ar2HDImage = `${imagePath}/weapons/2H/2H AR D.png`;
const ar2HDScopeImage = `${imagePath}/weapons/2H/2H Weapon - AR-D.png`;
const shotgun2HAImage = `${imagePath}/weapons/2H/2H Shotgun A.png`;
const shotgun2HBImage = `${imagePath}/weapons/2H/2H Shotgun B.png`;
const shotgun2HCImage = `${imagePath}/weapons/2H/2H Shotgun C.png`;
const mortar2HImage = `${imagePath}/weapons/2H/2H Mortar.png`;
const hmg2HImage = `${imagePath}/weapons/2H/2H HMG.png`;
const hmgC2HImage = `${imagePath}/weapons/2H/2H HMG - Chunky.png`;
const antimatRifle2HImage = `${imagePath}/weapons/2H/2H Antimat Rifle.png`;
const thermalRifle2HImage = `${imagePath}/weapons/2H/2H Thermal Rifle.png`;
const grenadeLauncher2HImage = `${imagePath}/weapons/2H/2H Grenade Launcher.png`;
const dmr2HImage = `${imagePath}/weapons/2H/2H DMR.png`;
const arLaser2HImage = `${imagePath}/weapons/2H/2H AR Laser.png`;
const flameThrower2HImage = `${imagePath}/weapons/2H/2H Flamethrower.png`;
const flameThrower2HBackImage = `${imagePath}/weapons/2H/2H Flamethrower Back.png`;
const smgA2HImage = `${imagePath}/weapons/2H/2H SMG A.png`;
const smgAGL2HImage = `${imagePath}/weapons/2H/2H SMG A GL.png`;
const laserRifle2HImage = `${imagePath}/weapons/2H/2H Laser Rifle.png`;
const laserRifleScope2HImage = `${imagePath}/weapons/2H/2H Weapon - Laser Rifle Scope.png`;
const arPangolin2HImage = `${imagePath}/weapons/2H/2H - Pangolin AR.png`;
const laserRifleAlt2HImage = `${imagePath}/weapons/2H/gms laser rifle alt.png`;

// HEAVY
const heavyGatlingImage = `${imagePath}/weapons/heavy/Superheavy Gatling Cannon.png`;
const heavyGatlingBackImage = `${imagePath}/weapons/heavy/Superheavy Gatling Cannon Ammo Back.png`;
const heavyThermalImage = `${imagePath}/weapons/heavy/Superheavy Thermal Lance.png`;
const heavyThermalBackImage = `${imagePath}/weapons/heavy/Superheavy Thermal Lance Cables Back.png`;
const heavyMissileLauncherImage = `${imagePath}/weapons/heavy/Superheavy Missile Launcher.png`;
const heavyRocketLauncherImage = `${imagePath}/weapons/heavy/Superheavy Rocket Launcher.png`;
const heavyCycloneImage = `${imagePath}/weapons/heavy/Superheavy Weapon - Cyclone Cannon.png`;
const heavyBowImage = `${imagePath}/weapons/heavy/Superheavy - Bow.png`;
const heavySiegeCannonImage = `${imagePath}/weapons/heavy/Superheavy Weapon - Siege Cannon.png`;
const heavyPangolinCannonImage = `${imagePath}/weapons/heavy/Heavy - Pangolin Heavy Cannon.png`;


// 1H RIGHT
const arRight1HImage = `${imagePath}/weapons/1H/1H1H Right Assault Rifle.png`;
const knifeRight1HImage = `${imagePath}/weapons/1H/1H1H Right Tac Knife.png`;
const swordRight1HImage = `${imagePath}/weapons/1H/1H1H Right Sword.png`;
const pHammerRight1HImage = `${imagePath}/weapons/1H/1H1H Right Pneumatic Hammer.png`;
const hammerRight1HImage = `${imagePath}/weapons/1H/1H1H Right Hammer.png`;
const pistolRight1HImage = `${imagePath}/weapons/1H/1H1H Right Pistol.png`;
const bladeRight1HImage = `${imagePath}/weapons/1H/1H1H Right Blade.png`;
const tBladeRight1HImage = `${imagePath}/weapons/1H/1H1H Right Thermal Blade.png`;
const spearRight1HImage = `${imagePath}/weapons/1H/1H1H Right Spear.png`;
const shieldRight1HImage = `${imagePath}/weapons/1H/1H1H Right Shield.png`;
const shotgunRight1HImage = `${imagePath}/weapons/1H/1H1H Right Shotgun.png`;
const mSwordRight1HImage = `${imagePath}/weapons/1H/1H1H Right Monofilament Sword.png`;
const hcbRight1HImage = `${imagePath}/weapons/1H/1H1H Right HCB.png`;
const axeRight1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Axe.png`;
const blade2Right1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Blade 2.png`;
const knifeRevRight1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Knife Reverse.png`;
const beamSwordGlowRight1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Beam Sword Glow.png`;
const beamSwordHandleRight1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Beam Sword Handle.png`;
const pneumaticFistRight1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Pneumatic Fist.png`;
const ar2Right1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Assault Rifle.png`;
const pilebunkerRight1HImage = `${imagePath}/weapons/1H/1H1H Weapon - Right - Pilebunker.png`;
const heavyShieldRight1HImage = `${imagePath}/weapons/1H/1H1H Right - Heavy Shield.png`;
const pangolinShieldRight1HImage = `${imagePath}/weapons/1H/1H1H Right Weapon - Pangolin Shield.png`;
const RPGRight1HImage = `${imagePath}/weapons/1H/gms 1h rpg.png`;
const nelsonSwordRight1HImage = `${imagePath}/weapons/1H/gms nelson sword.png`;

// 1H LEFT
const arLeft1HImage = `${imagePath}/weapons/1H/1H1H Left AR.png`;
const arDLeft1HImage = `${imagePath}/weapons/1H/1H1H Left AR D.png`;
const pistolLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Pistol.png`;
const tPistolLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Thermal Pistol.png`;
const revolverLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Revolver.png`;
const rpgLeft1HImage = `${imagePath}/weapons/1H/1H1H Left RPG.png`;
const shieldBackLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Shield Back.png`;
const shieldHandleLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Shield Handle.png`;
const axeLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Axe.png`;
const axeHandleLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Axe Handle Back.png`;
const tBladeLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Thermal Blade.png`;
const extenableBladeLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Extendable Blade Over.png`;
const extendableBladeBackLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Extendable Blade Under.png`;
const grenadeLauncherLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Grenade Launcher.png`;
const machinePistolLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Machine Pistol.png`;
const nailgunLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Nailgun.png`;
const hammerLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Hammer.png`;
const hcbLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Weapon - HCB.png`;
const pneumaticFistLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Weapon - Pneumatic Fist.png`;
const knifeLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Weapon - Knife.png`;
const pilebunkerLeft1HImage = `${imagePath}/weapons/1H/1H1H Weapon - Left - Pilebunker.png`;
const arELeft1HImage = `${imagePath}/weapons/1H/1H1H Left - AR E.png`;
const heavyHammerLeft1HImage = `${imagePath}/weapons/1H/1H1H Left - Heavy Hammer.png`;
const pangolinRifleLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Weapon - Pangolin Rifle.png`;
const pangolinRifleUnderLeft1HImage = `${imagePath}/weapons/1H/1H1H Left Weapon - Pangolin Rifle Under.png`;

// MOUNTED
const autocannonRightImage = `${imagePath}/weapons/mounted/Right Arm AutoCannon.png`;
const autocannonLeftImage = `${imagePath}/weapons/mounted/Left Arm AutoCannon.png`;
const missilesRightImage = `${imagePath}/weapons/mounted/Right Arm Missiles.png`;
const missilesLeftImage = `${imagePath}/weapons/mounted/Left Arm Missiles.png`;
const nexusLeftImage = `${imagePath}/weapons/mounted/Left Arm - HK Nexus.png`;
const nexusRightImage = `${imagePath}/weapons/mounted/Right Arm - Dual Nexus.png`;
const vanguardLeftImage = `${imagePath}/weapons/mounted/Left Arm - Vanguard - HCB.png`;
const vanguardRightImage = `${imagePath}/weapons/mounted/Right Arm - Vanguard - Shotgun.png`;
const plasmaCannonLeftImage = `${imagePath}/weapons/mounted/Left Arm - Railgun.png`;
const railgun2LeftImage = `${imagePath}/weapons/mounted/Left Arm - Railgun 2.png`;
const targetingSystemRightImage = `${imagePath}/weapons/mounted/Right Arm - Targetting System.png`;
const grenadeLauncherRightImage = `${imagePath}/weapons/mounted/Right Arm - grenade Launcher.png`;
const grenadeLauncherLeftImage = `${imagePath}/weapons/mounted/Left Arm - grenade Launcher.png`;
const heavyBallisticsRightImage = `${imagePath}/weapons/mounted/Right Arm - Heavy Ballistics.png`;
const heavyBallisticsLeftImage = `${imagePath}/weapons/mounted/Left - Heavy Ballistics Gatling.png`;
const howitzerGrandeLeftImage = `${imagePath}/weapons/mounted/Left - Heavy Ballistics.png`;
const autocannonMediumLeftImage = `${imagePath}/weapons/mounted/Left Arm - Medium Autocannon.png`;
const autocannonMediumRightImage = `${imagePath}/weapons/mounted/Right Arm - Medium Autocannon.png`;
const ppcLeftImage = `${imagePath}/weapons/mounted/Left Arm - PPC.png`;
const ppcRightImage = `${imagePath}/weapons/mounted/Right Arm - PPC.png`;
const gatlingCannonLeftImage = `${imagePath}/weapons/mounted/Left Arm - Gatling Cannon.png`;
const gatlingCannonRightImage = `${imagePath}/weapons/mounted/Right Arm - Gatling Cannon.png`;
const missilePodLeftImage = `${imagePath}/weapons/mounted/Left Arm - Missile Pod.png`;
const missilePodRightImage = `${imagePath}/weapons/mounted/Right Arm - Missile Pod.png`;
const nexus2LeftImage = `${imagePath}/weapons/mounted/Left Arm - Nexus II.png`;
const nexus2RightImage = `${imagePath}/weapons/mounted/Right Arm - Nexus II.png`;
const nexus2DownLeftImage = `${imagePath}/weapons/mounted/Left Arm - Nexus II down.png`;
const nexus2DownRightImage = `${imagePath}/weapons/mounted/Right Arm - Nexus II down.png`;
const heavyMissilesLeftImage = `${imagePath}/weapons/mounted/Left Weapon - Heavy Missiles.png`;
const heavyMissilesRightImage = `${imagePath}/weapons/mounted/Right Weapon - Heavy Missiles.png`;
const vanguardKoboldARRightImage = `${imagePath}/weapons/mounted/gms kobold ar vanguard arm.png`;
const vanguardKoboldARRightGlowImage = `${imagePath}/weapons/mounted/GMS_KoboldArmAR_glow.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "93px",
    right: "59px",
};

const bipedLegsFrontlineShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "86px",
    right: "67px",
};

const bipedLegsScarabShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "61px",
    right: "55px",
};

const bipedLegsSupportShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "70px",
    right: "63px",
};

const bipedLegsHackerShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "56px",
    right: "72px",
};

const bipedLegsSniperShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "77px",
    right: "55px",
};

const bipedLegsAssaultShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "79px",
    right: "57px",
};

const bipedLegsVanguardShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "74px",
    right: "55px",
};

const bipedLegsStriderShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "56px",
    right: "55px",
};

const quadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "116px",
    right: "38px",
};

const quadLargeLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "89px",
    right: "18px",
};

const treadsLargeLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "106px",
    right: "18px",
};

const turretLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "109px",
    right: "19px",
};

const bulwarkLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "59px",
    right: "23px",
};

const lumberPuppyLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "63px",
    right: "30px",
};

const scoutLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "79px",
    right: "26px",
};

const pangolinLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "40px",
    right: "26px",
};

export default {
    head: [
        {
            value: 0,
            label: "FACTORY STANDARD",
            src: headAImage,
        },
        {
            value: 1,
            label: "PALADIN",
            src: headBImage,
        },
        {
            value: 2,
            label: "OGRE",
            src: headCImage,
        },
        {
            value: 3,
            label: "ROGUE",
            src: headDImage,
        },
        {
            value: 4,
            label: "BASTION",
            src: headEImage,
        },
        {
            value: 5,
            label: "ICARUS",
            src: headFImage,
        },
        {
            value: 6,
            label: "SCARAB",
            src: headGImage,
        },
        {
            value: 7,
            label: "RAMSHACKLE",
            src: headHImage,
        },
        {
            value: 8,
            label: "SLIPSTREAM",
            src: headIImage,
        },
        {
            value: 9,
            label: "BUNKER",
            src: headJImage,
        },
        {
            value: 10,
            label: "DUNGAM",
            src: headKImage,
        },
        {
            value: 11,
            label: "TALON",
            src: headLImage,
        },
        {
            value: 12,
            label: "JUDICATOR",
            src: headMImage,
        },
        {
            value: 13,
            label: "RUMBLE",
            src: headNImage,
        },
        {
            value: 14,
            label: "FRONTLINE",
            src: headOImage,
        },
        {
            value: 15,
            label: "ASSAULT",
            src: headAssaultImage,
        },
        {
            value: 16,
            label: "VANGUARD",
            src: headVanguardImage,
        },
        {
            value: 17,
            label: "STALWART",
            src: headStalwartImage,
        },
        {
            value: 18,
            label: "SUPPORT",
            src: headSupportImage,
        },
        {
            value: 19,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 20,
            label: "ATOMS K",
            src: headAtomsKImage,
        },
        {
            value: 21,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 22,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 23,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 24,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 25,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 26,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 27,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 28,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 29,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 30,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 31,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 32,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 33,
            label: "DAYGLOW SKULL",
            src: headDayglowSkullImage,
        },
        {
            value: 34,
            label: "IRON SKULL",
            src: headIronSkullImage,
        },
        {
            value: 35,
            label: "DWARF",
            src: headDwarfImage,
        },
        {
            value: 36,
            label: "PARTISAN",
            src: headPartisanImage,
        },
        {
            value: 37,
            label: "RIP AND TEAR",
            src: headRipAndTearImage,
        },
        {
            value: 38,
            label: "COCKPIT",
            src: headCockpitImage,
        },
        {
            value: 39,
            label: "DEADBOLT",
            src: headDeadboltImage,
        },
        {
            value: 40,
            label: "BULKHEAD",
            src: headBulkheadImage,
        },
        {
            value: 41,
            label: "MAIN CANNON",
            src: headMainCannonImage,
        },
        {
            value: 42,
            label: "DUAL CANNON",
            src: headDualCannonImage,
        },
        {
            value: 43,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 44,
            label: "DUNGAM WING",
            src: headDungamWingImage,
        },
        {
            value: 45,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 46,
            label: "BLACK SPOT - LEFT",
            src: headBlackSpotLeftImage,
        },
        {
            value: 47,
            label: "BLACK SPOT - RIGHT",
            src: headBlackSpotRightImage,
        },
        {
            value: 48,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
        },
        {
            value: 49,
            label: "MECHA 1",
            src: headWeeb1Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 50,
            label: "MECHA 2",
            src: headWeeb2Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 51,
            label: "MECHA 3",
            src: headWeeb3Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 52,
            label: "MECHA 4",
            src: headWeeb4Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 53,
            label: "MECHA 5",
            src: headWeeb5Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 54,
            label: "MECHA 6",
            src: headWeeb6Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 55,
            label: "MECHA 7",
            src: headWeeb7Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 56,
            label: "MECHA 8",
            src: headWeeb8Image,
            originalArtCredit: {
                title: "@ammoradio1337",
                url: "https://twitter.com/ammoradio1337",
            },
        },
        {
            value: 57,
            label: "BONEHEAD",
            src: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },

    ],
    chassis: [
        {
            value: 0,
            label: "FACTORY STANDARD",
            src: chassisAImage,
        },
        {
            value: 1,
            label: "PALADIN",
            src: chassisBImage,
        },
        {
            value: 2,
            label: "OGRE",
            src: chassisCImage,
        },
        {
            value: 3,
            label: "ROGUE",
            src: chassisDImage,
        },
        {
            value: 4,
            label: "BASTION",
            src: chassisEImage,
        },
        {
            value: 5,
            label: "ICARUS",
            src: chassisFImage,
        },
        {
            value: 6,
            label: "SCARAB",
            src: chassisGImage,
        },
        {
            value: 7,
            label: "RAMSHACKLE",
            src: chassisHImage,
        },
        {
            value: 8,
            label: "FRONTLINE",
            src: chassisIImage,
        },
        {
            value: 9,
            label: "ASSAULT",
            src: chassisAssaultImage,
        },
        {
            value: 10,
            label: "VANGUARD",
            src: chassisVanguardImage,
        },
        {
            value: 11,
            label: "HACKER",
            src: chassisHackerImage,
        },
        {
            value: 12,
            label: "ADAMANT",
            src: chassisAdamantImage,
        },
        {
            value: 13,
            label: "INNER SPHERE",
            src: chassisInnerSphereLImage,
        },
        {
            value: 14,
            label: "CANNON S",
            src: chassisCannonSImage,
        },
        {
            value: 15,
            label: "CANNON L",
            src: chassisCannonLImage,
        },
        {
            value: 16,
            label: "LUMBER PUPPY",
            src: chassisLumberPuppyImage,
        },
        {
            value: 17,
            label: "BRIMSTONE II",
            src: chassisBrimstone2Image,
        },
        {
            value: 18,
            label: "SCOUT",
            src: chassisScoutImage,
        },
        {
            value: 19,
            label: "PANGOLIN",
            src: chassisPangolinImage,
        },
    ],
    arms: [
        {
            value: 0,
            label: "FACTORY STANDARD",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HAImage,
                    right: rightArm1HAImage,
                    skeleton: arm1HSkeletonAImage,
                },
                "2H": {
                    left: leftArm2HAImage,
                    right: rightArm2HAImage,
                    skeleton: arm2HSkeletonAImage,
                },
                heavy: {
                    left: leftArmHeavyAImage,
                    right: rightArmHeavyAImage,
                    skeleton: armHeavySkeletonAImage,
                },
            },
        },
        {
            value: 1,
            label: "PALADIN",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HBImage,
                    right: rightArm1HBImage,
                    skeleton: arm1HSkeletonBImage,
                },
                "2H": {
                    left: leftArm2HBImage,
                    right: rightArm2HBImage,
                    skeleton: arm2HSkeletonBImage,
                },
                heavy: {
                    left: leftArmHeavyBImage,
                    right: rightArmHeavyBImage,
                    skeleton: armHeavySkeletonBImage,
                },
            },
        },
        {
            value: 2,
            label: "OGRE",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HCImage,
                    right: rightArm1HCImage,
                    skeleton: arm1HSkeletonCImage,
                },
                "2H": {
                    left: leftArm2HCImage,
                    right: rightArm2HCImage,
                    skeleton: arm2HSkeletonCImage,
                },
                heavy: {
                    left: leftArmHeavyCImage,
                    right: rightArmHeavyCImage,
                    skeleton: armHeavySkeletonCImage,
                },
            },
        },
        {
            value: 3,
            label: "ROGUE",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HDImage,
                    right: rightArm1HDImage,
                    skeleton: arm1HSkeletonDImage,
                },
                "2H": {
                    left: leftArm2HDImage,
                    right: rightArm2HDImage,
                    skeleton: arm2HSkeletonDImage,
                },
                heavy: {
                    left: leftArmHeavyDImage,
                    right: rightArmHeavyDImage,
                    skeleton: armHeavySkeletonDImage,
                },
            },
        },
        {
            value: 4,
            label: "BASTION",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HEImage,
                    right: rightArm1HEImage,
                    skeleton: arm1HSkeletonEImage,
                },
                "2H": {
                    left: leftArm2HEImage,
                    right: rightArm2HEImage,
                    skeleton: arm2HSkeletonEImage,
                },
                heavy: {
                    left: leftArmHeavyEImage,
                    right: rightArmHeavyEImage,
                    skeleton: armHeavySkeletonEImage,
                },
            },
        },
        {
            value: 5,
            label: "ICARUS",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HFImage,
                    right: rightArm1HFImage,
                    skeleton: arm1HSkeletonFImage,
                },
                "2H": {
                    left: leftArm2HFImage,
                    right: rightArm2HFImage,
                    skeleton: arm2HSkeletonFImage,
                },
                heavy: {
                    left: leftArmHeavyFImage,
                    right: rightArmHeavyFImage,
                    skeleton: armHeavySkeletonFImage,
                },
            },
        },
        {
            value: 6,
            label: "SCARAB",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HGImage,
                    right: rightArm1HGImage,
                    skeleton: arm1HSkeletonGImage,
                },
                "2H": {
                    left: leftArm2HGImage,
                    right: rightArm2HGImage,
                    skeleton: arm2HSkeletonGImage,
                },
                heavy: {
                    left: leftArmHeavyGImage,
                    right: rightArmHeavyGImage,
                    skeleton: armHeavySkeletonGImage,
                },
            },
        },
        {
            value: 7,
            label: "RAMSHACKLE",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HHImage,
                    right: rightArm1HHImage,
                    skeleton: arm1HSkeletonHImage,
                },
                "2H": {
                    left: leftArm2HHImage,
                    right: rightArm2HHImage,
                    skeleton: arm2HSkeletonHImage,
                },
                heavy: {
                    left: leftArmHeavyHImage,
                    right: rightArmHeavyHImage,
                    skeleton: armHeavySkeletonHImage,
                },
            },
        },
        {
            value: 8,
            label: "FRONTLINE",
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HIImage,
                    right: rightArm1HIImage,
                },
                "2H": {
                    left: leftArm2HIImage,
                    right: rightArm2HIImage,
                },
                heavy: {
                    left: leftArmHeavyIImage,
                    right: rightArmHeavyIImage,
                },
            },
        },
        {
            value: 9,
            label: "ASSAULT",
            isMounted: false,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HAssaultImage,
                    right: rightArm1HAssaultImage,
                    skeleton: armHeavySkeletonAssaultImage,
                },
                "2H": {
                    left: leftArm2HAssaultImage,
                    right: rightArm2HAssaultImage,
                    skeleton: armHeavySkeletonAssaultImage,
                },
                heavy: {
                    left: leftArmHeavyAssaultImage,
                    right: rightArmHeavyAssaultImage,
                    skeleton: armHeavySkeletonAssaultImage,
                },
            },
        },
        {
            value: 10,
            label: "SUPPORT",
            isMounted: false,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HSupportImage,
                    right: rightArm1HSupportImage,
                    skeleton: armHeavySkeletonSupportImage,
                },
                "2H": {
                    left: leftArm2HSupportImage,
                    right: rightArm2HSupportImage,
                    skeleton: armHeavySkeletonSupportImage,
                },
                heavy: {
                    left: leftArmHeavySupportImage,
                    right: rightArmHeavySupportImage,
                    skeleton: armHeavySkeletonSupportImage,
                },
            },
        },
        {
            value: 11,
            label: "PANGOLIN",
            isMounted: false,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HPangolinImage,
                    right: rightArm1HPangolinImage,
                },
                "2H": {
                    left: leftArm2HPangolinImage,
                    right: rightArm2HPangolinImage,
                },
                heavy: {
                    left: leftArmHeavyPangolinImage,
                    right: rightArmHeavyPangolinImage,
                    skeleton: armHeavySkeletonPangolinImage,
                },
            },
        },
        {
            value: 12,
            label: "---WEAPON ARMS---",
            disabled: true,
        },
        {
            value: 13,
            label: "VANGUARD - HCB / SHOTGUN",
            isMounted: true,
            src: {
                "1H": {
                    left: vanguardLeftImage,
                    right: vanguardRightImage,
                },
                "2H": {
                    left: vanguardLeftImage,
                    right: vanguardRightImage,
                },
                heavy: {
                    left: vanguardLeftImage,
                    right: vanguardRightImage,
                },
            },
        },
        {
            value: 14,
            label: "VANGUARD - HEAVY HAMMER / SHOTGUN",
            isMounted: true,
            src: {
                "1H": {
                    left: heavyHammerLeft1HImage,
                    right: vanguardRightImage,
                },
                "2H": {
                    left: heavyHammerLeft1HImage,
                    right: vanguardRightImage,
                },
                heavy: {
                    left: heavyHammerLeft1HImage,
                    right: vanguardRightImage,
                },
            },
        },
        {
            value: 15,
            label: "VANGUARD - HEAVY HAMMER / KOBOLD AR",
            isMounted: true,
            src: {
                "1H": {
                    left: heavyHammerLeft1HImage,
                    right: vanguardKoboldARRightImage,
                },
                "2H": {
                    left: heavyHammerLeft1HImage,
                    right: vanguardKoboldARRightImage,
                },
                heavy: {
                    left: heavyHammerLeft1HImage,
                    right: vanguardKoboldARRightImage,
                },
            },
        },
        {
            value: 16,
            label: "GRENADE LAUNCHER",
            isMounted: true,
            src: {
                "1H": {
                    left: grenadeLauncherLeftImage,
                    right: grenadeLauncherRightImage,
                },
                "2H": {
                    left: grenadeLauncherLeftImage,
                    right: grenadeLauncherRightImage,
                },
                heavy: {
                    left: grenadeLauncherLeftImage,
                    right: grenadeLauncherRightImage,
                },
            },
        },
        {
            value: 17,
            label: "AUTOCANNON",
            isMounted: true,
            src: {
                "1H": {
                    left: autocannonLeftImage,
                    right: autocannonRightImage,
                },
                "2H": {
                    left: autocannonLeftImage,
                    right: autocannonRightImage,
                },
                heavy: {
                    left: autocannonLeftImage,
                    right: autocannonRightImage,
                },
            },
        },
        {
            value: 18,
            label: "MEDIUM AUTOCANNON",
            isMounted: true,
            src: {
                "1H": {
                    left: autocannonMediumLeftImage,
                    right: autocannonMediumRightImage,
                },
                "2H": {
                    left: autocannonMediumLeftImage,
                    right: autocannonMediumRightImage,
                },
                heavy: {
                    left: autocannonMediumLeftImage,
                    right: autocannonMediumRightImage,
                },
            },
        },
        {
            value: 19,
            label: "GATLING CANNON AA",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: heavyBallisticsLeftImage,
                    right: heavyBallisticsRightImage,
                },
                "2H": {
                    left: heavyBallisticsLeftImage,
                    right: heavyBallisticsRightImage,
                },
                heavy: {
                    left: heavyBallisticsLeftImage,
                    right: heavyBallisticsRightImage,
                },
            },
        },
        {
            value: 20,
            label: "GATLING CANNON",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: gatlingCannonLeftImage,
                    right: gatlingCannonRightImage,
                },
                "2H": {
                    left: gatlingCannonLeftImage,
                    right: gatlingCannonRightImage,
                },
                heavy: {
                    left: gatlingCannonLeftImage,
                    right: gatlingCannonRightImage,
                },
            },
        },
        {
            value: 21,
            label: "HOWITZER GRANDE / TARGETING",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: howitzerGrandeLeftImage,
                    right: targetingSystemRightImage,
                },
                "2H": {
                    left: howitzerGrandeLeftImage,
                    right: targetingSystemRightImage,
                },
                heavy: {
                    left: howitzerGrandeLeftImage,
                    right: targetingSystemRightImage,
                },
            },
        },
        {
            value: 22,
            label: "PLASMA CANNON / TARGETING",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: plasmaCannonLeftImage,
                    right: targetingSystemRightImage,
                },
                "2H": {
                    left: plasmaCannonLeftImage,
                    right: targetingSystemRightImage,
                },
                heavy: {
                    left: plasmaCannonLeftImage,
                    right: targetingSystemRightImage,
                },
            },
        },
        {
            value: 23,
            label: "RAILGUN / TARGETING",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: railgun2LeftImage,
                    right: targetingSystemRightImage,
                },
                "2H": {
                    left: railgun2LeftImage,
                    right: targetingSystemRightImage,
                },
                heavy: {
                    left: railgun2LeftImage,
                    right: targetingSystemRightImage,
                },
            },
        },
        {
            value: 24,
            label: "PPC",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: ppcLeftImage,
                    right: ppcRightImage,
                },
                "2H": {
                    left: ppcLeftImage,
                    right: ppcRightImage,
                },
                heavy: {
                    left: ppcLeftImage,
                    right: ppcRightImage,
                },
            },
        },
        {
            value: 25,
            label: "MISSILES",
            isMounted: true,
            src: {
                "1H": {
                    left: missilesLeftImage,
                    right: missilesRightImage,
                },
                "2H": {
                    left: missilesLeftImage,
                    right: missilesRightImage,
                },
                heavy: {
                    left: missilesLeftImage,
                    right: missilesRightImage,
                },
            },
        },
        {
            value: 26,
            label: "MISSILE POD",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: missilePodLeftImage,
                    right: missilePodRightImage,
                },
                "2H": {
                    left: missilePodLeftImage,
                    right: missilePodRightImage,
                },
                heavy: {
                    left: missilePodLeftImage,
                    right: missilePodRightImage,
                },
            },
        },
        {
            value: 27,
            label: "HACKER - NEXUS",
            isMounted: true,
            src: {
                "1H": {
                    left: nexusLeftImage,
                    right: nexusRightImage,
                },
                "2H": {
                    left: nexusLeftImage,
                    right: nexusRightImage,
                },
                heavy: {
                    left: nexusLeftImage,
                    right: nexusRightImage,
                },
            },
        },
        {
            value: 28,
            label: "NEXUS II",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: nexus2LeftImage,
                    right: nexus2RightImage,
                },
                "2H": {
                    left: nexus2LeftImage,
                    right: nexus2RightImage,
                },
                heavy: {
                    left: nexus2LeftImage,
                    right: nexus2RightImage,
                },
            },
        },
        {
            value: 29,
            label: "NEXUS II DOWN",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: nexus2DownLeftImage,
                    right: nexus2DownRightImage,
                },
                "2H": {
                    left: nexus2DownLeftImage,
                    right: nexus2DownRightImage,
                },
                heavy: {
                    left: nexus2DownLeftImage,
                    right: nexus2DownRightImage,
                },
            },
        },
        {
            value: 30,
            label: "SCOUT CANNON",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HScoutCannonImage,
                    right: rightArm1HScoutCannonImage,
                },
                "2H": {
                    left: leftArm1HScoutCannonImage,
                    right: rightArm1HScoutCannonImage,
                },
                heavy: {
                    left: leftArm1HScoutCannonImage,
                    right: rightArm1HScoutCannonImage,
                },
            },
        },
        {
            value: 31,
            label: "SCOUT GATLING",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HScoutGatlingImage,
                    right: rightArm1HScoutGatlingImage,
                },
                "2H": {
                    left: leftArm1HScoutGatlingImage,
                    right: rightArm1HScoutGatlingImage,
                },
                heavy: {
                    left: leftArm1HScoutGatlingImage,
                    right: rightArm1HScoutGatlingImage,
                },
            },
        },
        {
            value: 32,
            label: "SCOUT ROCKET POD",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: leftArm1HScoutRocketPodImage,
                    right: rightArm1HScoutRocketPodImage,
                },
                "2H": {
                    left: leftArm1HScoutRocketPodImage,
                    right: rightArm1HScoutRocketPodImage,
                },
                heavy: {
                    left: leftArm1HScoutRocketPodImage,
                    right: rightArm1HScoutRocketPodImage,
                },
            },
        },
        {
            value: 33,
            label: "HEAVY MISSILES",
            isMounted: true,
            isLeftArmBackRow: true,
            src: {
                "1H": {
                    left: heavyMissilesLeftImage,
                    right: heavyMissilesRightImage,
                },
                "2H": {
                    left: heavyMissilesLeftImage,
                    right: heavyMissilesRightImage,
                },
                heavy: {
                    left: heavyMissilesLeftImage,
                    right: heavyMissilesRightImage,
                },
            },
            originalArtCredit: {
                title: "@WyrmBardic",
                url: "https://twitter.com/wyrmbardic",
            },
        },
    ],
    legs: [
        {
            value: 0,
            label: "FACTORY STANDARD",
            src: legsAImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "PALADIN",
            src: legsBImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "OGRE",
            src: legsCImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "ROGUE",
            src: legsDImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 4,
            label: "BASTION",
            src: legsEImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 5,
            label: "ICARUS",
            src: legsFImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 6,
            label: "SCARAB",
            src: legsGImage,
            shadowDetails: bipedLegsScarabShadow,
        },
        {
            value: 7,
            label: "RAMSHACKLE",
            src: legsHImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 8,
            label: "QUAD",
            src: legsIImage,
            shadowDetails: quadLegsShadow,
        },
        {
            value: 9,
            label: "QUAD-L",
            src: legsQuadLImage,
            shadowDetails: quadLargeLegsShadow,
        },
        {
            value: 10,
            label: "FRONTLINE",
            src: legsJImage,
            shadowDetails: bipedLegsFrontlineShadow,
        },
        {
            value: 11,
            label: "ASSAULT",
            src: legsAssaultImage,
            shadowDetails: bipedLegsAssaultShadow,
        },
        {
            value: 12,
            label: "VANGUARD",
            src: legsVanguardImage,
            shadowDetails: bipedLegsVanguardShadow,
        },
        {
            value: 13,
            label: "SNIPER",
            src: legsSniperImage,
            shadowDetails: bipedLegsSniperShadow,
        },
        {
            value: 14,
            label: "HACKER",
            src: legsHackerImage,
            shadowDetails: bipedLegsHackerShadow,
        },
        {
            value: 15,
            label: "SUPPORT",
            src: legsSupportImage,
            shadowDetails: bipedLegsSupportShadow,
        },
        {
            value: 16,
            label: "STRIDER",
            src: legsStriderImage,
            shadowDetails: bipedLegsStriderShadow,
        },
        {
            value: 17,
            label: "BULWARK",
            src: legsBulwarkImage,
            shadowDetails: bulwarkLegsShadow,
        },
        {
            value: 18,
            label: "LUMBER PUPPY",
            src: legsLumberPuppyImage,
            shadowDetails: lumberPuppyLegsShadow,
        },
        {
            value: 19,
            label: "TREADS",
            src: legsTreadsImage,
            shadowDetails: treadsLargeLegsShadow,
        },
        {
            value: 20,
            label: "SMALL TREADS",
            src: legsSmallTreadsImage,
            shadowDetails: treadsLargeLegsShadow,
        },
        {
            value: 21,
            label: "TREADS - LOW PROFILE",
            src: legsLowProfileTreadsImage,
            shadowDetails: treadsLargeLegsShadow,
        },
        {
            value: 22,
            label: "SMALL TREADS - LOW PROFILE",
            src: legsLowProfileSmallTreadsImage,
            shadowDetails: treadsLargeLegsShadow,
        },
        {
            value: 23,
            label: "TURRET BASE",
            src: legsTurretBaseImage,
            shadowDetails: turretLegsShadow,
        },
        {
            value: 24,
            label: "SCOUT",
            src: legsScoutImage,
            shadowDetails: scoutLegsShadow,
        },
        {
            value: 25,
            label: "PANGOLIN",
            src: legsPangolinImage,
            shadowDetails: pangolinLegsShadow,
        },
    ],
    weapon: {
        "2H": [
            {
                value: "0",
                label: "---2H ARMS---",
                disabled: true,
            },
            {
                value: "1-2H",
                label: "AR-A",
                src: ar2HAImage,
            },
            {
                value: "2-2H",
                label: "AR-A SCOPED",
                src: ar2HAScopeImage,
            },
            {
                value: "3-2H",
                label: "AR-B",
                src: ar2HBImage,
            },
            {
                value: "4-2H",
                label: "AR-B SCOPED",
                src: ar2HBScopeImage,
            },
            {
                value: "5-2H",
                label: "AR-C",
                src: ar2HCImage,
            },
            {
                value: "6-2H",
                label: "AR-C SCOPED",
                src: ar2HCScopeImage,
            },
            {
                value: "7-2H",
                label: "AR-D",
                src: ar2HDImage,
            },
            {
                value: "8-2H",
                label: "AR-D SCOPED",
                src: ar2HDScopeImage,
            },
            {
                value: "9-2H",
                label: "AR-A GL",
                src: argl2HAImage,
            },
            {
                value: "10-2H",
                label: "AR-A GL SCOPED",
                src: argl2HAScopeImage,
            },
            {
                value: "11-2H",
                label: "SHOTGUN-A",
                src: shotgun2HAImage,
            },
            {
                value: "12-2H",
                label: "SHOTGUN-B",
                src: shotgun2HBImage,
            },
            {
                value: "13-2H",
                label: "SHOTGUN-C",
                src: shotgun2HCImage,
            },
            {
                value: "14-2H",
                label: "MORTAR",
                src: mortar2HImage,
            },
            {
                value: "15-2H",
                label: "HMG",
                src: hmg2HImage,
            },
            {
                value: "16-2H",
                label: "HMG-C",
                src: hmgC2HImage,
            },
            {
                value: "17-2H",
                label: "ANTIMAT RIFLE",
                src: antimatRifle2HImage,
            },
            {
                value: "18-2H",
                label: "THERMAL RIFLE",
                src: thermalRifle2HImage,
            },
            {
                value: "19-2H",
                label: "GRENADE LAUNCHER",
                src: grenadeLauncher2HImage,
            },
            {
                value: "20-2H",
                label: "DMR",
                src: dmr2HImage,
            },
            {
                value: "21-2H",
                label: "LASER AR",
                src: arLaser2HImage,
            },
            {
                value: "22-2H",
                label: "LASER RIFLE",
                src: laserRifle2HImage,
            },
            {
                value: "23-2H",
                label: "LASER RIFLE SCOPED",
                src: laserRifleScope2HImage,
            },
            {
                value: "24-2H",
                label: "LASER RIFLE ALT",
                src: laserRifleAlt2HImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
            {
                value: "25-2H",
                label: "FLAMETHROWER",
                src: flameThrower2HImage,
                backSrc: flameThrower2HBackImage,
            },
            {
                value: "26-2H",
                label: "SMG",
                src: smgA2HImage,
            },
            {
                value: "27-2H",
                label: "SMG GL",
                src: smgAGL2HImage,
            },
            {
                value: "28-2H",
                label: "PANGOLIN AR",
                src: arPangolin2HImage,
            },
        ],
        heavy: [
            {
                value: "0",
                label: "---SUPERHEAVY ARMS---",
                disabled: true,
            },
            {
                value: "1-heavy",
                label: "GATLING CANNON",
                src: heavyGatlingImage,
                backSrc: heavyGatlingBackImage,
            },
            {
                value: "2-heavy",
                label: "THERMAL LANCE",
                src: heavyThermalImage,
                backSrc: heavyThermalBackImage,
            },
            {
                value: "3-heavy",
                label: "MISSILE LAUNCHER",
                src: heavyMissileLauncherImage,
            },
            {
                value: "4-heavy",
                label: "ROCKET LAUNCHER",
                src: heavyRocketLauncherImage,
            },
            {
                value: "5-heavy",
                label: "CYCLONE CANNON",
                src: heavyCycloneImage,
            },
            {
                value: "6-heavy",
                label: "BOW",
                src: heavyBowImage,
            },
            {
                value: "7-heavy",
                label: "SIEGE CANNON",
                src: heavySiegeCannonImage,
            },
            {
                value: "8-heavy",
                label: "PANGOLIN CANNON",
                src: heavyPangolinCannonImage,
            },
        ],
        "1H": [
            {
                value: "0",
                label: "---1H ARMS---",
                disabled: true,
            },
            {
                value: "1-1H",
                label: "PISTOL",
                src: pistolRight1HImage,
            },
            {
                value: "2-1H",
                label: "SMG",
                src: arRight1HImage,
            },
            {
                value: "3-1H",
                label: "AR",
                src: ar2Right1HImage,
            },
            {
                value: "4-1H",
                label: "SHOTGUN",
                src: shotgunRight1HImage,
            },
            {
                value: "5-1H",
                label: "KNIFE",
                src: knifeRight1HImage,
            },
            {
                value: "6-1H",
                label: "KNIFE - REVERSE",
                src: knifeRevRight1HImage,
            },
            {
                value: "7-1H",
                label: "SWORD",
                src: swordRight1HImage,
            },
            {
                value: "8-1H",
                label: "BLADE",
                src: bladeRight1HImage,
            },
            {
                value: "9-1H",
                label: "BLADE 2",
                src: blade2Right1HImage,
            },
            {
                value: "10-1H",
                label: "THERMAL BLADE",
                src: tBladeRight1HImage,
            },
            {
                value: "11-1H",
                label: "HCB",
                src: hcbRight1HImage,
            },
            {
                value: "12-1H",
                label: "BEAM SWORD",
                src: beamSwordGlowRight1HImage,
                backSrc: beamSwordHandleRight1HImage,
            },
            {
                value: "13-1H",
                label: "MONOFILAMENT SWORD",
                src: mSwordRight1HImage,
            },
            {
                value: "14-1H",
                label: "SPEAR",
                src: spearRight1HImage,
            },
            {
                value: "15-1H",
                label: "AXE",
                src: axeRight1HImage,
            },
            {
                value: "16-1H",
                label: "HAMMER",
                src: hammerRight1HImage,
            },
            {
                value: "17-1H",
                label: "PNEUMATIC HAMMER",
                src: pHammerRight1HImage,
            },
            {
                value: "18-1H",
                label: "PNEUMATIC FIST",
                src: pneumaticFistRight1HImage,
            },
            {
                value: "19-1H",
                label: "SHIELD",
                src: shieldRight1HImage,
            },
            {
                value: "20-1H",
                label: "HEAVY SHIELD",
                src: heavyShieldRight1HImage,
            },
            {
                value: "21-1H",
                label: "PILEBUNKER",
                src: pilebunkerRight1HImage,
            },
            {
                value: "22-1H",
                label: "PANGOLIN SHIELD",
                src: pangolinShieldRight1HImage,
            },
            {
                value: "23-1H",
                label: "RPG PILEBUNKER",
                src: RPGRight1HImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
            {
                value: "24-1H",
                label: "GREAT SWORD",
                src: nelsonSwordRight1HImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "PISTOL",
            src: pistolLeft1HImage,
        },
        {
            value: 1,
            label: "THERMAL PISTOL",
            src: tPistolLeft1HImage,
        },
        {
            value: 2,
            label: "REVOLVER",
            src: revolverLeft1HImage,
        },
        {
            value: 3,
            label: "MACHINE PISTOL",
            src: machinePistolLeft1HImage,
        },
        {
            value: 4,
            label: "NAILGUN",
            src: nailgunLeft1HImage,
        },
        {
            value: 5,
            label: "SMG",
            src: arLeft1HImage,
        },
        {
            value: 6,
            label: "AR-D",
            src: arDLeft1HImage,
        },
        {
            value: 7,
            label: "AR-E",
            src: arELeft1HImage,
        },
        {
            value: 8,
            label: "KNIFE",
            src: knifeLeft1HImage,
        },
        {
            value: 9,
            label: "EXTENDABLE BLADE",
            src: extenableBladeLeft1HImage,
            backSrc: extendableBladeBackLeft1HImage,
        },
        {
            value: 10,
            label: "THERMAL BLADE",
            src: tBladeLeft1HImage,
        },
        {
            value: 11,
            label: "HCB",
            src: hcbLeft1HImage,
        },
        {
            value: 12,
            label: "AXE",
            src: axeLeft1HImage,
            backSrc: axeHandleLeft1HImage,
        },
        {
            value: 13,
            label: "HAMMER",
            src: hammerLeft1HImage,
            backSrc: axeHandleLeft1HImage,
        },
        {
            value: 14,
            label: "PNUEMATIC FIST",
            src: pneumaticFistLeft1HImage,
        },
        {
            value: 15,
            label: "SHIELD",
            src: shieldHandleLeft1HImage,
            backSrc: shieldBackLeft1HImage,
        },
        {
            value: 16,
            label: "RPG",
            src: rpgLeft1HImage,
        },
        {
            value: 17,
            label: "GRENADE LAUNCHER",
            src: grenadeLauncherLeft1HImage,
        },
        {
            value: 18,
            label: "PILEBUNKER",
            src: pilebunkerLeft1HImage,
        },
        {
            value: 19,
            label: "PANGOLIN RIFLE",
            src: pangolinRifleLeft1HImage,
            backSrc: pangolinRifleUnderLeft1HImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "BACK AR",
            src: rearMountARImage,
        },
        {
            value: 1,
            label: "BACK RPG",
            src: rearMountRPGImage,
        },
        {
            value: 2,
            label: "BACK SWORD",
            src: rearMountSwordImage,
        },
        {
            value: 3,
            label: "BACK NEXUS",
            src: rearMountNexusImage,
        },
        {
            value: 4,
            label: "BACK NEXUS X2",
            src: rearMountNexus2Image,
        },
        {
            value: 5,
            label: "HOWITZER",
            src: rearMountHowitzerImage,
        },
        {
            value: 6,
            label: "HOWITZER X2",
            src: rearMountDualHowitzerImage,
        },
        {
            value: 7,
            label: "HOWITZER-C",
            src: rearMountHowitzerCImage,
        },
        {
            value: 8,
            label: "HOWITZER-GRANDE",
            src: rearMountHowitzerGrandeImage,
        },
        {
            value: 9,
            label: "MISSILE RACK",
            src: rearMountMissile1Image,
        },
        {
            value: 10,
            label: "MISSILE RACK X2",
            src: rearMountMissile2Image,
        },
        {
            value: 11,
            label: "MISSILE RACK + BLADE",
            src: rearMountMissileRackBladeImage,
        },
        {
            value: 12,
            label: "COMM ARRAY",
            src: rearMountCommArrayImage,
        },
        {
            value: 13,
            label: "SENSOR ARRAY",
            src: rearMountSensorArrayImage,
        },
        {
            value: 14,
            label: "TURRET",
            frontSrc: turretsFrontImage,
            src: turretsBackImage,
        },
        {
            value: 15,
            label: "TURRET RACK",
            src: rearMountTurretsImage,
            frontSrc: rearMountTurretsFrontImage,
        },
        {
            value: 16,
            label: "DEPLOYABLE COVER",
            src: rearMountDeployCoverImage,
        },
        {
            value: 17,
            label: "DEPLOYABLE COVER + TURRET RACK",
            src: rearMountTurretsCoverImage,
            frontSrc: rearMountTurretsCoverFrontImage,
        },
        {
            value: 18,
            label: "MORTAR",
            src: rearMountMortarLeftImage,
        },
        {
            value: 19,
            label: "MORTAR CENTER",
            src: rearMountMortarImage,
        },
        {
            value: 20,
            label: "CANISTER + RIFLE",
            frontSrc: canisterRifleFrontImage,
            src: canisterRifleBackImage,
        },
        {
            value: 21,
            label: "FLIGHT UNITS",
            frontSrc: rearMountFlightFrontImage,
            src: rearMountFlightImage,
        },
        {
            value: 22,
            label: "FLIGHT UNITS - NO FRONT",
            src: rearMountFlightImage,
        },
        {
            value: 23,
            label: "FLIGHT UNITS 2",
            src: rearMountFlight2Image,
        },
        {
            value: 24,
            label: "FLIGHT UNITS 3",
            frontSrc: rearMountFlightFrontImage,
            src: rearMountFlight3Image,
        },
        {
            value: 25,
            label: "FLIGHT UNITS 3 - NO FRONT",
            src: rearMountFlight3Image,
        },
        {
            value: 26,
            label: "BOOSTERS",
            frontSrc: rearMountFlightFrontImage,
            src: rearMountBoostersImage,
        },
        {
            value: 27,
            label: "BOOSTERS - NO FRONT",
            src: rearMountBoostersImage,
        },
        {
            value: 28,
            label: "BOOSTERS + HOWITZER",
            src: rearMountFlightHowitzerImage,
        },
        {
            value: 29,
            label: "BOOSTERS 2",
            frontSrc: rearMountBoosters2FrontImage,
            src: rearMountBoosters2Image,
        },
        {
            value: 30,
            label: "BOOSTERS 2 - NO FRONT",
            src: rearMountBoosters2Image,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "AMMO BANDOLIER",
            src: ammoBandolierImage,
        },
        {
            value: 1,
            label: "GRENADE BAND",
            src: granadeBandImage,
        },
        {
            value: 2,
            label: "STRAPS",
            src: packsImage,
        },
        {
            value: 3,
            label: "BACKPACK",
            src: packsImage,
            backSrc: backpackImage,
        },
        {
            value: 4,
            label: "KNIFE-A",
            src: knifeImage,
        },
        {
            value: 5,
            label: "KNIFE-B",
            src: knifeLeftImage,
        },
        {
            value: 6,
            label: "PISTOL HOLSTER",
            src: pistolHolsterStrapImage,
            backSrc: pistolHolsterImage,
        },
        // {
        //     value: 6,
        //     label: "PISTOL HOLSTER ARM",
        //     src: pistolHolsterArmImage,
        // },
        {
            value: 7,
            label: "MANIPULATOR ARMS",
            src: manipulatorArmsImage,
            altSrc: manipulatorArmsAltImage,
        },
        {
            value: 8,
            label: "CLOAK",
            frontSrc: cloakTopImage,
            src: cloakMidImage,
            backSrc: cloakBackImage,
        },
        {
            value: 9,
            label: "HOODED CLOAK",
            frontSrc: cloakTopHoodImage,
            src: cloakMidImage,
            backSrc: cloakBackImage,
        },
        {
            value: 10,
            label: "CANISTER",
            src: canisterFrontImage,
            backSrc: canisterBackImage,
        },
        {
            value: 11,
            label: "RIFLE",
            src: rifleFrontImage,
            backSrc: rifleBackImage,
        },
        {
            value: 12,
            label: "CANISTER + RIFLE",
            src: canisterRifleFrontImage,
            backSrc: canisterRifleBackImage,
        },
        {
            value: 13,
            label: "REAR BLADE MOUNT",
            backSrc: accessoryBladeImage,
        },
        {
            value: 14,
            label: "RIGHT ARM BLADE MOUNT",
            backSrc: accessoryBladeRightBackImage,
            frontSrc: accessoryBladeRightFrontImage,
        },
        {
            value: 15,
            label: "MORTAR",
            backSrc: rearMountMortarLeftImage,
        },
        {
            value: 16,
            label: "KEFFIYA",
            frontSrc: keffiyaTop,
            src: keffiyaMid,
        },
        {
            value: 17,
            label: "MISSILE PODS",
            frontSrc: missilePodsFront,
            backSrc: missilePodsBehind,
        },
        {
            value: 18,
            label: "GLOW - VANGUARD ARM - KOBOLD AR",
            foremostSrc: vanguardKoboldARRightGlowImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT ARM",
            value: "armLeft",
        },
        {
            label: "RIGHT ARM",
            value: "armRight",
        },
        {
            label: "LEGS",
            value: "legs",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
};
