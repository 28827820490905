import React, { useContext, useEffect, useState } from "react";
import { func, object } from "prop-types";
import styled from "styled-components";

import {
    UnitCategoryWrapper,
    UnitCategoryHeader,
    UnitCategoryHeaderText,
    UnitContainer,
} from "./../UnitCategory";
import {
    SelectDropdown,
    IndexSelectors,
    IndexSelector,
    MechForm,
    InterfaceButton,
    InterfacePlaceholder,
    InterfaceControls,
    retroTerminalColor,
    defaultColor,
} from "./components/MechCategoryStyles";
import { brandList } from "../../LayerData/MechNPCLayerOrder";
import ContainerWrapper from "../components/ContainerWrapper";
import Unit from "./../../styles/Unit";
import MechNPCCategoryDisplay from "./MechNPCCategoryDisplay";
import MechBrandedFrameDisplay from "./MechBrandedFrameDisplay";
import CustomColorControls from "./components/CustomColorControls";
import ExportMechButton from "./components/ExportMechButton";
import ExportMechPartsButton from "./components/ExportMechPartsButton";
import ExportAllMechPartsButton from "./components/ExportAllMechPartsButton";
import ExportAllMechPartsAndTintsButton from "./components/ExportAllMechPartsAndTintsButton";
import ExportNPCCamoSettingsButton from "./components/ExportNPCCamoSettingsButton";
import ImportNPCCamoSettingsButton from "./components/ImportNPCCamoSettingsButton";
import ArtCreditLink from "../components/ArtCreditLink";

import { AnalyticsContext } from "./../../../Analytics/AnalyticsContextWrapper";
import { UserAuthContext } from "./../../../User/UserAuthContextWrapper";
import { UnitContext } from "./../../UnitContextWrapper";
import { CurrentThemeContext } from "./../../../common/ThemeContextWrapper";
import { CustomColorContext } from "../../CustomColorContextWrapper";

import ColorList from "../components/ColorList";
import { getRandomIntInclusive } from "./MechCustomCategory";
import { safeName } from "./../../Print/PrintAnalytics";

import MembershipTierImages from "./../../../common/MembershipTierImages";

import MechUnitImage from "./../../styles/MechUnitImage";
import ZoomWrapper from "../components/ZoomWrapper";
import BaseURL from "./../../../Unit/data/images/Hosting-BaseURL";
import { shadowTypes } from "../../styles/UnitShadow";
import { premiumTiersList } from "../PremiumPopup";
import { MechLogoSprite } from "./MechBrandedCategory";
import SpriteImage, { SPRITE_KEYS } from "../../../common/styles/SpriteImage";

const backArrowWhiteImage = `${BaseURL}/buttons/back_arrow_white.png`;
const glowPreviewImage = `${BaseURL}/buttons/Color-Sample-Orb-Glow.png`;
const metalPreviewImage = `${BaseURL}/buttons/Color-Sample-Orb.png`;
const colorSampleSquareGlowImage = `${BaseURL}/buttons/Color-Sample-Square-Glow-18.png`;
const colorSampleSquareMetalImage = `${BaseURL}/buttons/Color-Sample-Square-18.png`;

const MechUnitContainer = styled(UnitContainer)`
    align-items: center;
    overflow-y: unset;
    justify-content: space-around;
    margin-right: 10px;

    ${({noFrameSelected}) => noFrameSelected ? `
        height: 500px;

        @media only screen and (max-width: ${({theme}) => theme.tabletCutoffWidth}px) {
            height: 1090px;
        }

        @media only screen and (max-width: ${({theme}) => theme.mobileCutoffWidth}px) {
            height: 1192px;
        }
    ` : `
        padding: 5px 0 10px;
        block-size: fit-content;
    `}
`;

MechUnitContainer.displayName = "MechNPCUnitContainer";

export const CamoSettingsDisplay = styled.span`
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    &.retro-terminal {
        background-color: transparent;
    }

    &.default {
        background-color: ${defaultColor};
    }
`;

CamoSettingsDisplay.displayName = "CamoSettingsDisplay";

export const CamoSettingOption = styled.span`
    border: 2px solid;
    cursor: pointer;
    display: inline-block;
    padding: 2px;
    width: 8px;
    height: 8px;

    &.retro-terminal {
        border-color: ${retroTerminalColor};

        &.selected {
            background-color: ${retroTerminalColor};
        }
    }

    &.default {
        border-color: #FFF;

        &.selected {
            background-color: #FFF;
        }
    }
`;

CamoSettingOption.displayName = "CamoSettingOption";

function MechNPCCategory({ setShowPremiumPopupFunc, unitCategory }) {
    const [savedCamoSettings, setSavedCamoSettings] = useState(Array(5).fill({
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none",
        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none"
    }));
    const [camoSettingsIndex, setCamoSettingsIndex] = useState(0);

    const [isExpanded, setIsExpanded] = useState(false);

    const [randomLockChassis, setRandomLockChassis] = useState(false);
    const [randomLockWeapon, setRandomLockWeapon] = useState(false);
    const [randomLockSecondaryWeapon, setRandomLockSecondaryWeapon] = useState(false);
    const [randomLockCamo1, setRandomLockCamo1] = useState(false);
    const [randomLockCamo2, setRandomLockCamo2] = useState(false);
    const [randomLockCamo3, setRandomLockCamo3] = useState(false);
    const [randomLockCamo4, setRandomLockCamo4] = useState(false);

    const [targetPartValue, setTargetPartValue] = useState(0);

    const [templateValue, setTemplateValue] = useState(0);
    const [currentUnitIndex, setCurrentUnitIndex] = useState(0);
    const [unitState, setUnitState] = useState([
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
    ]);

    const ReactGA = useContext(AnalyticsContext);
    const { user } = useContext(UserAuthContext);
    const { addUnit } = useContext(UnitContext);
    const { displayTheme } = useContext(CurrentThemeContext);
    const {
        colorValue,
        displayCustomControls,
        tintValue,
        handleColorOptionChange,
        getColorTint,
    } = useContext(CustomColorContext);

    const isPremiumUser = user && user.isPremiumUser;
    const isLegendaryUser = user && user.isLegendaryUser;

    let categoryTierImage = MembershipTierImages.mundaneTier.orb;
    const categoryTierImageAlt = MembershipTierImages.mundaneTier.alt;
    const categoryClassname = "seasonal-category mech-category";

    if (!!unitCategory.premium) {
        categoryTierImage = MembershipTierImages.themepackTier.orb;
    }

    useEffect(() => {
        if (isExpanded) {
            ReactGA.event({
                category: "Unit Category",
                action: "View Category",
                label: unitCategory.title
            });
        }
    }, [isExpanded, ReactGA, unitCategory.title]);

    useEffect(() => {
        const camoSettingsCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`${unitCategory.title}CamoSettings=`));

        if (camoSettingsCookie.length) {
            if (JSON.parse(camoSettingsCookie[0].split("=")[1]).length !== 5) {
                setSavedCamoSettings(JSON.parse(camoSettingsCookie[0].split("=")[1]).concat([
                    {
                        "camo1Value": "NONE",
                        "camo2Value": "NONE",
                        "camo3Value": "NONE",
                        "camo4Value": "NONE",
                        "camo1TintValue": "none",
                        "camo2TintValue": "none",
                        "camo3TintValue": "none",
                        "camo4TintValue": "none",
                        "chassisTintValue": "none",
                        "weaponTintValue": "none",
                        "secondaryWeaponTintValue": "none"
                    }
                ]));
            } else {
                setSavedCamoSettings(JSON.parse(camoSettingsCookie[0].split("=")[1]));
            }
        }
    }, [unitCategory.title]);

    if (unitState[currentUnitIndex].frameValue === null) {
        return (
            <UnitCategoryWrapper
                className={categoryClassname}
                isExpanded={isExpanded}
                numColumnsVisible={4}
                isExtraTall={false}
                borderPrimaryColor={unitCategory.borderPrimaryColor}
                borderSecondaryColor={unitCategory.borderSecondaryColor}
            >
                <UnitCategoryHeader
                    className={categoryClassname}
                    onClick={() => setIsExpanded(!isExpanded)}
                    title={unitCategory.description}
                >
                    <MechLogoSprite
                        className={unitCategory.logoImage ? categoryClassname : `${categoryClassname} hide-image`}
                        alt="Units Rarity"
                        title={categoryTierImageAlt}
                    />
                    <UnitCategoryHeaderText alt={unitCategory.label} className={categoryClassname}>{unitCategory.label}</UnitCategoryHeaderText>
                </UnitCategoryHeader>
                <ContainerWrapper>
                    <MechUnitContainer
                        className={categoryClassname}
                        themeBackgroundPrimaryColor={displayTheme ? unitCategory.retroTerminalBackgroundColor : unitCategory.themeBackgroundPrimaryColor}
                        themeBackgroundSecondaryColor={displayTheme ? unitCategory.retroTerminalBackgroundSecondaryColor : unitCategory.themeBackgroundSecondaryColor}
                        isExpanded={isExpanded}
                        numColumnsVisible={4}
                        isExtraTall={false}
                        borderPrimaryColor={unitCategory.borderPrimaryColor}
                        borderSecondaryColor={unitCategory.borderSecondaryColor}
                        displayCustomControls={displayCustomControls}
                        noFrameSelected={true}
                    >
                        <MechBrandedFrameDisplay
                            setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                            setFrameValueFunc={(value) => handleChange(value, "frameValue")}
                            partList={unitCategory.partList}
                            isPremiumUser={isPremiumUser}
                            isLegendaryUser={isLegendaryUser}
                            displayTheme={displayTheme}
                            npcCategory={unitCategory.title}
                            isLegendaryCategory={unitCategory.premium}
                        />
                    </MechUnitContainer>
                </ContainerWrapper>
            </UnitCategoryWrapper>
        );
    }

    function randomFunction() {
        let chassisAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.filter(item => !item.isPremium).length;
        let chassisValue;

        let weaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon.filter(item => !item.isPremium).length;
        let secondaryWeaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.filter(item => !item.isPremium).length;
        let camoAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].camo.filter(item => !item.isPremium).length;

        if (isPremiumUser) {
            chassisAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.filter(item => !item.isLegendary).length;
            weaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon.filter(item => !item.isLegendary).length;
            secondaryWeaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.filter(item => !item.isLegendary).length;
            camoAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].camo.filter(item => !item.isLegendary).length;
        }

        if (isLegendaryUser) {
            chassisAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.length;
            weaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon.length;
            secondaryWeaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.length;
            camoAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].camo.length;
        }

        chassisValue = getRandomIntInclusive(0, chassisAmount - 1);

        const randomUnitValues = {
            chassisValue,
            weaponValue: weaponAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, weaponAmount - 1) : "NONE" : "NONE",
            secondaryWeaponValue: secondaryWeaponAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, secondaryWeaponAmount - 1) : "NONE" : "NONE",
            camo1Value: camoAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, camoAmount - 1) : "NONE" : "NONE",
            camo2Value: camoAmount > 1 ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, camoAmount - 1) : "NONE" : "NONE",
            camo3Value: camoAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, camoAmount - 1) : "NONE" : "NONE",
            camo4Value: camoAmount > 1 ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, camoAmount - 1) : "NONE" : "NONE",
            brandValue: unitCategory.title,
        };

        if (unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone) {
            if (randomUnitValues.chassisValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.chassis) {
                randomUnitValues.chassisValue = 0;
            }

            if (randomUnitValues.weaponValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.weapon) {
                randomUnitValues.weaponValue = 0;
            }

            if (randomUnitValues.secondaryWeaponValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.secondaryWeapon) {
                randomUnitValues.secondaryWeaponValue = 0;
            }

            if (randomUnitValues.camo1Value === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.camo) {
                randomUnitValues.camo1Value = 0;
            }

            if (randomUnitValues.camo2Value === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.camo) {
                randomUnitValues.camo2Value = 0;
            }
        }

        if (randomLockChassis) {
            delete randomUnitValues.chassisValue;
        }

        if (randomLockWeapon) {
            delete randomUnitValues.weaponValue;
        }

        if (randomLockSecondaryWeapon) {
            delete randomUnitValues.secondaryWeaponValue;
        }

        if (randomLockCamo1) {
            delete randomUnitValues.camo1Value;
        }

        if (randomLockCamo2) {
            delete randomUnitValues.camo2Value;
        }

        if (randomLockCamo3) {
            delete randomUnitValues.camo3Value;
        }

        if (randomLockCamo4) {
            delete randomUnitValues.camo4Value;
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, randomUnitValues);
            }

            return unit;
        }));
    }

    function paintFunction() {
        const updatedValues = {};
        const targetPart = unitCategory.partList[unitState[currentUnitIndex].frameValue].partList[targetPartValue].value;
        let color = getColorTint();

        switch (targetPart) {
            case "BODY":
                updatedValues.chassisTintValue = color;
                break;

            case "WEAPONS":
                updatedValues.weaponTintValue = color;
                updatedValues.secondaryWeaponTintValue = color;
                break;

            case "ALL":
                updatedValues.chassisTintValue = color;
                updatedValues.weaponTintValue = color;
                updatedValues.secondaryWeaponTintValue = color;
                updatedValues.camo1TintValue = color;
                updatedValues.camo2TintValue = color;
                updatedValues.camo3TintValue = color;
                updatedValues.camo4TintValue = color;
                break;

            default:
                updatedValues[`${targetPart}TintValue`] = color;
                break;
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, updatedValues);
            }

            return unit;
        }));
    }

    function handleChange(value, valueName) {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                if (valueName === "frameValue") {
                    if (value !== null) {
                        return Object.assign(
                            {},
                            JSON.parse(unitCategory.partList[value].defaultValues),
                            {
                                frameValue: value,
                                brandValue: unitCategory.title
                            },
                            {
                                "camo1TintValue": ColorList[unitCategory.defaultCamoIndex].value,
                                "camo2TintValue": ColorList[unitCategory.defaultCamoIndex].value,
                                "camo3TintValue": ColorList[unitCategory.defaultCamoIndex].value,
                                "camo4TintValue": ColorList[unitCategory.defaultCamoIndex].value,
                            },
                        );
                    } else {
                        return Object.assign({}, { frameValue: value, brandValue: unitCategory.title });
                    }
                }

                return Object.assign({}, unit, {
                    [valueName]: value,
                });
            }

            return unit;
        }));
    }

    function applyCamoSettings() {
        if (savedCamoSettings[camoSettingsIndex]) {
            return handleMultipleChanges(savedCamoSettings[camoSettingsIndex]);
        }
    }

    function saveCamoSettings() {
        const newSavedCamoSettings = savedCamoSettings.map((camoSettings, index) => {
            if (index === camoSettingsIndex) {
                return {
                    "camo1Value": unitState[currentUnitIndex].camo1Value,
                    "camo2Value": unitState[currentUnitIndex].camo2Value,
                    "camo3Value": unitState[currentUnitIndex].camo3Value,
                    "camo4Value": unitState[currentUnitIndex].camo4Value,
                    "camo1TintValue": unitState[currentUnitIndex].camo1TintValue,
                    "camo2TintValue": unitState[currentUnitIndex].camo2TintValue,
                    "camo3TintValue": unitState[currentUnitIndex].camo3TintValue,
                    "camo4TintValue": unitState[currentUnitIndex].camo4TintValue,
                    "chassisTintValue": unitState[currentUnitIndex].chassisTintValue,
                    "weaponTintValue": unitState[currentUnitIndex].weaponTintValue,
                    "secondaryWeaponTintValue": unitState[currentUnitIndex].secondaryWeaponTintValue,
                };
            }

            return camoSettings;
        });

        document.cookie = `${unitCategory.title}CamoSettings=false; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

        document.cookie = `${unitCategory.title}CamoSettings=${JSON.stringify(newSavedCamoSettings)}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;

        return setSavedCamoSettings(newSavedCamoSettings);
    }

    function handleMultipleChanges(valuesObject) {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, valuesObject);
            }

            return unit;
        }));
    }

    function resetFunction() {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                setRandomLockChassis(false);
                setRandomLockWeapon(false);
                setRandomLockSecondaryWeapon(false);
                setRandomLockCamo1(false);
                setRandomLockCamo2(false);

                return Object.assign(
                    {},
                    JSON.parse(unitCategory.partList[unitState[currentUnitIndex].frameValue].defaultValues),
                    {
                        frameValue: unitState[currentUnitIndex].frameValue,
                        brandValue: unitCategory.title
                    },
                );
            }

            return unit;
        }));
    }

    function addUnitFunction() {
        const mechLabel = `MECH ${unitCategory.title} ${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`;

        const variantsArray = [
            Object.assign(
                {},
                unitState[currentUnitIndex],
                {
                    isExtraLarge: true,
                    isMegadeus: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus,
                    isMegadeusPlus: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus,
                    isMegadeusPlusPlus: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus,
                    isMegadeusOmega: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega,
                }
            )
        ];

        ReactGA.event({
            category: "Unit",
            action: "Add Unit",
            label: safeName(mechLabel),
        });

        let shadowDetails = unitState[currentUnitIndex].chassisValue !== "NONE" ?
            unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis[unitState[currentUnitIndex].chassisValue].shadowDetails :
            {
                size: shadowTypes.NONE,
                bottom: "0px",
                right: "0px",
            };

        return addUnit(Object.assign({
            variantsArray,
            currentVariant: 0,
            id: `${mechLabel}-${Date.now()}`,
            shadowDetails,
            npcMechUnit: true,
            mechLabel,
        }));
    };

    const chassisOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.map((chassisOption) => (
        <option
            key={`${chassisOption.value}-${chassisOption.label}`}
            value={chassisOption.value}
            disabled={
                (chassisOption.isPremium && !isPremiumUser) ||
                (chassisOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${chassisOption.label} ${chassisOption.isPlusTwo ? "*" : ""}${chassisOption.isPremium ? "●" : ""}${chassisOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const weaponOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon.map((weaponOption) => (
        <option
            key={`${weaponOption.value}-${weaponOption.label}`}
            value={weaponOption.value}
            disabled={
                (weaponOption.isPremium && !isPremiumUser) ||
                (weaponOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${weaponOption.label} ${weaponOption.isPlusTwo ? "*" : ""}${weaponOption.isPremium ? "●" : ""}${weaponOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const secondaryWeaponOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.map((weaponOption) => (
        <option
            key={`${weaponOption.value}-${weaponOption.label}`}
            value={weaponOption.value}
            disabled={
                (weaponOption.isPremium && !isPremiumUser) ||
                (weaponOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${weaponOption.label} ${weaponOption.isPlusTwo ? "*" : ""}${weaponOption.isPremium ? "●" : ""}${weaponOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const camoOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].camo.map((camoOption) => (
        <option
            key={`${camoOption.value}-${camoOption.label}`}
            value={camoOption.value}
            disabled={
                (camoOption.isPremium && !isPremiumUser) ||
                (camoOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${camoOption.label} ${camoOption.isPlusTwo ? "*" : ""}${camoOption.isPremium ? "●" : ""}${camoOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const unitIndexSelectors = unitState.map((unit, index) => (
        <IndexSelector
            key={`unit-index-selector-${index}`}
            className={displayTheme ? "retro-terminal" : "default"}
            onClick={() => setCurrentUnitIndex(index)}
            isActive={currentUnitIndex === index}
            title={`Temporary Config ${index + 1}`}
        >
            {index + 1}
        </IndexSelector>
    ));

    const targetPartListOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.map((part, index) => (
        <option
            key={`paint-${part.value}-${part.label}`}
            value={index}
        >
            {part.label}
        </option>
    ));

    const colorListOptions = ColorList.map((color, index) => (
        <option
            key={`${color.value}-${color.label}`}
            value={index}
        >
            {color.label}
        </option>
    ));

    const camoSettingsOptions = savedCamoSettings.map((camoSetting, index) => (
        <CamoSettingOption
            key={`camo-setting-option-${index}`}
            value={index}
            className={`${index === camoSettingsIndex ? "selected " : ""} ${displayTheme ? "retro-terminal" : "default"}`}
            onClick={() => setCamoSettingsIndex(index)}
        />
    ));

    const templateListOptions = unitCategory.templateList.map((template, index) => (
        <option
            key={`template--${template.label}`}
            value={index}
        >
            {template.label}
        </option>
    ));

    const targetPartIsGlow = !!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList[targetPartValue].isGlow;

    return (
        <UnitCategoryWrapper
            className={categoryClassname}
            isExpanded={isExpanded}
            numColumnsVisible={4}
            isExtraTall={
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus ||
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus ||
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus ||
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega
            }
            borderPrimaryColor={unitCategory.borderPrimaryColor}
            borderSecondaryColor={unitCategory.borderSecondaryColor}
        >
            <UnitCategoryHeader
                className={categoryClassname}
                onClick={() => setIsExpanded(!isExpanded)}
                title={unitCategory.description}
            >
                <MechLogoSprite
                    className={unitCategory.logoImage ? categoryClassname : `${categoryClassname} hide-image`}
                    src={categoryTierImage}
                    alt="Units Rarity"
                    title={categoryTierImageAlt}
                />
                <UnitCategoryHeaderText alt={unitCategory.label} className={categoryClassname}>{unitCategory.label}</UnitCategoryHeaderText>
            </UnitCategoryHeader>
            <ContainerWrapper>
                <MechUnitContainer
                    className={categoryClassname}
                    themeBackgroundPrimaryColor={displayTheme ? unitCategory.retroTerminalBackgroundColor : unitCategory.themeBackgroundPrimaryColor}
                    themeBackgroundSecondaryColor={displayTheme ? unitCategory.retroTerminalBackgroundSecondaryColor : unitCategory.themeBackgroundSecondaryColor}
                    isExpanded={isExpanded}
                    numColumnsVisible={4}
                    isExtraTall={
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus ||
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus ||
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus ||
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega
                    }
                    borderPrimaryColor={unitCategory.borderPrimaryColor}
                    borderSecondaryColor={unitCategory.borderSecondaryColor}
                    displayCustomControls={displayCustomControls}
                >
                    {isExpanded ? (
                        <MechForm>
                            <div className="options-selectors">
                                {/* ROW 1 START */}
                                <div>
                                    <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                        <label
                                            htmlFor="frame-select"
                                            onClick={() => {
                                                handleChange(null, "frameValue");
                                                setTargetPartValue(0);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                className="back"
                                                alt="Back"
                                                src={backArrowWhiteImage}
                                            />
                                            &nbsp;FRAME SELECT
                                        </label>
                                    </SelectDropdown>
                                    &nbsp;
                                    <ArtCreditLink unit={unitState[currentUnitIndex]} brandList={brandList} isNPCCategory />
                                </div>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label style={{ cursor: "pointer" }} onClick={() => setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE)}>
                                        ●&nbsp;&nbsp;&nbsp;+1 TIER
                                    </label>
                                    <label style={{ cursor: "pointer" }} onClick={() => setShowPremiumPopupFunc(premiumTiersList.PLUS_TWO)}>
                                        *&nbsp;&nbsp;&nbsp;+2 TIER
                                    </label>
                                    <label style={{ cursor: "pointer" }} onClick={() => setShowPremiumPopupFunc(premiumTiersList.LEGENDARY)}>
                                        ●●&nbsp;LEGEND TIER
                                    </label>
                                </SelectDropdown>
                                {/* ROW 1 END */}

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="template-select">
                                        CAMO TEMPLATES
                                    </label>
                                    <select
                                        id="template-select"
                                        name="template"
                                        onChange={(event) => {
                                            handleMultipleChanges(unitCategory.templateList[event.target.value].value);

                                            return setTemplateValue(event.target.value)
                                        }}
                                        value={templateValue}
                                    >
                                        {templateListOptions}
                                    </select>
                                </SelectDropdown>

                                <InterfacePlaceholder />

                                {/* ROW 2 START */}
                                {unitCategory.partList[unitState[currentUnitIndex].frameValue].weaponIsChassisSpecial ?
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!chassisOptions.length}
                                    >
                                        <label htmlFor="chassis-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]}</span>
                                            {!chassisOptions.length
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "chassis")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].chassisTintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockChassis ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockChassis ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockChassis(!randomLockChassis)}
                                            />
                                        </label>
                                        <select
                                            id="chassis-select"
                                            name="chassis"
                                            onChange={unitCategory.partList[unitState[currentUnitIndex].frameValue].chassisSpecial ?
                                                (event) => handleMultipleChanges({
                                                    [unitCategory.partList[unitState[currentUnitIndex].frameValue].chassisSpecial]: event.target.value,
                                                    chassisValue: event.target.value,
                                                }) :
                                                (event) => handleChange(event.target.value, "chassisValue")
                                            }
                                            value={unitState[currentUnitIndex].chassisValue}
                                            disabled={!chassisOptions.length}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.chassis ?
                                                null : <option value="NONE">NONE</option>
                                            }
                                            {chassisOptions}
                                        </select>
                                    </SelectDropdown>
                                    : <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!weaponOptions.length}
                                    >
                                        <label htmlFor="weapon-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["weapon"]}</span>
                                            {!weaponOptions.length
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["weapon"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "weapon")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].weaponTintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockWeapon ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockWeapon ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockWeapon(!randomLockWeapon)}
                                            />
                                        </label>
                                        <select
                                            id="weapon-select"
                                            name="weapon"
                                            onChange={unitCategory.partList[unitState[currentUnitIndex].frameValue].weaponSpecial ?
                                                (event) => handleMultipleChanges({
                                                    [unitCategory.partList[unitState[currentUnitIndex].frameValue].weaponSpecial]: event.target.value,
                                                    weaponValue: event.target.value,
                                                }) :
                                                (event) => handleChange(event.target.value, "weaponValue")
                                            }
                                            value={unitState[currentUnitIndex].weaponValue}
                                            disabled={!weaponOptions.length}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.weapon ?
                                                null : <option value="NONE">NONE</option>
                                            }
                                            {weaponOptions}
                                        </select>
                                    </SelectDropdown>}


                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!camoOptions.length}
                                >
                                    <label htmlFor="camo-1-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo1"]}</span>
                                        {!camoOptions.length
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo1"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "camo1")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].camo1TintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockCamo1 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockCamo1 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockCamo1(!randomLockCamo1)}
                                        />
                                    </label>
                                    <select
                                        id="camo-1-select"
                                        name="camo1"
                                        onChange={(event) => handleChange(event.target.value, "camo1Value")}
                                        value={unitState[currentUnitIndex].camo1Value}
                                        disabled={!camoOptions.length}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.camo1 ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {camoOptions}
                                    </select>
                                </SelectDropdown>
                                {/* ROW 2 END */}

                                {/* ROW 3 START */}
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!secondaryWeaponOptions.length}
                                >
                                    <label htmlFor="secondary-weapon-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["secondaryWeapon"]}</span>
                                        {!secondaryWeaponOptions.length
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["secondaryWeapon"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "secondaryWeapon")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].secondaryWeaponTintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockSecondaryWeapon ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockSecondaryWeapon ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockSecondaryWeapon(!randomLockSecondaryWeapon)}
                                        />
                                    </label>
                                    <select
                                        id="secondary-weapon-select"
                                        name="secondary weapon"
                                        onChange={(event) => {
                                            if (unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeaponSpecial) {
                                                handleMultipleChanges({
                                                    [unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeaponSpecial]: event.target.value,
                                                    secondaryWeaponValue: event.target.value,
                                                });
                                            } else {
                                                handleChange(event.target.value, "secondaryWeaponValue");
                                            }
                                        }}
                                        value={unitState[currentUnitIndex].secondaryWeaponValue}
                                        disabled={!secondaryWeaponOptions.length}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.secondaryWeapon ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {secondaryWeaponOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!camoOptions.length}
                                >
                                    <label htmlFor="camo-2-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo2"]}</span>
                                        {!camoOptions.length
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo2"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "camo2")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].camo2TintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockCamo2 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockCamo2 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockCamo2(!randomLockCamo2)}
                                        />
                                    </label>
                                    <select
                                        id="camo-2-select"
                                        name="camo2"
                                        onChange={(event) => handleChange(event.target.value, "camo2Value")}
                                        value={unitState[currentUnitIndex].camo2Value}
                                        disabled={!camoOptions.length}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.camo2 ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {camoOptions}
                                    </select>
                                </SelectDropdown>
                                {/* ROW 3 END */}

                                {/* ROW 4 START */}
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                >
                                    <label
                                        className={"clickable"}
                                        onClick={() => saveCamoSettings()}
                                    >
                                        SAVE CAMO SETTINGS
                                    </label>
                                    <CamoSettingsDisplay
                                        className={displayTheme ? "retro-terminal" : "default"}
                                    >
                                        {camoSettingsOptions}
                                    </CamoSettingsDisplay>
                                </SelectDropdown>

                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!camoOptions.length}
                                >
                                    <label htmlFor="camo-3-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo3"]}</span>
                                        {!camoOptions.length
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo3"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "camo3")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].camo3TintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockCamo3 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockCamo3 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockCamo3(!randomLockCamo3)}
                                        />
                                    </label>
                                    <select
                                        id="camo-3-select"
                                        name="camo3"
                                        onChange={(event) => handleChange(event.target.value, "camo3Value")}
                                        value={unitState[currentUnitIndex].camo3Value}
                                        disabled={!camoOptions.length}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.camo1 ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {camoOptions}
                                    </select>
                                </SelectDropdown>
                                {/* ROW 4 END */}

                                {/* ROW 5 START */}
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    onClick={() => applyCamoSettings()}
                                    disabled={!savedCamoSettings[camoSettingsIndex]}
                                >
                                    <label className={"clickable"}>
                                        APPLY CAMO SETTINGS
                                    </label>
                                </SelectDropdown>

                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!camoOptions.length}
                                >
                                    <label htmlFor="camo-4-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo4"]}</span>
                                        {!camoOptions.length
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["camo4"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "camo4")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].camo4TintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockCamo4 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockCamo4 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockCamo4(!randomLockCamo4)}
                                        />
                                    </label>
                                    <select
                                        id="camo-4-select"
                                        name="camo4"
                                        onChange={(event) => handleChange(event.target.value, "camo4Value")}
                                        value={unitState[currentUnitIndex].camo4Value}
                                        disabled={!camoOptions.length}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.camo2 ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {camoOptions}
                                    </select>
                                </SelectDropdown>
                                {/* ROW 5 END */}

                                {/* ROW 6 START */}
                                <ExportNPCCamoSettingsButton
                                    displayTheme={displayTheme ? "retro-terminal" : "default"}
                                    camoSettings={savedCamoSettings[camoSettingsIndex]}
                                    npcCategory={unitCategory.title}
                                />

                                <ImportNPCCamoSettingsButton
                                    displayTheme={displayTheme ? "retro-terminal" : "default"}
                                    applyCamoSettingsFunc={handleMultipleChanges}
                                    npcCategory={unitCategory.title}
                                />
                                {/* ROW 6 END */}

                                {/* ROW 7 START */}
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="color-select">
                                        COLOR
                                    </label>
                                    <select
                                        id="color-select"
                                        name="color"
                                        onChange={handleColorOptionChange}
                                        value={colorValue}
                                    >
                                        {colorListOptions}
                                    </select>
                                    <MechUnitImage
                                        style={{ top: "30px", left: "25px", width: "21px", height: "21px", border: "none" }}
                                        tintValue={tintValue}
                                        src={targetPartIsGlow ? glowPreviewImage : metalPreviewImage}
                                        alt="paint-preview"
                                        zIndex={"10"}
                                    />
                                </SelectDropdown>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="part-select">
                                        COLOR TARGET
                                    </label>
                                    <select
                                        id="part-select"
                                        name="part"
                                        onChange={(event) => setTargetPartValue(event.target.value)}
                                        value={targetPartValue}
                                    >
                                        {targetPartListOptions}
                                    </select>
                                </SelectDropdown>
                                {/* ROW 7 END */}
                            </div>
                            <div className="display-controls">
                                <Unit
                                    onContextMenu={(e) => e.preventDefault()}
                                    isExtraLarge
                                    isMegadeus={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus}
                                    isMegadeusPlus={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus}
                                    isMegadeusPlusPlus={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus}
                                    isMegadeusOmega={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega}
                                >
                                    <ZoomWrapper>
                                        <MechNPCCategoryDisplay
                                            unit={unitState[currentUnitIndex]}
                                            isInCategory
                                        />
                                    </ZoomWrapper>
                                </Unit>
                                <IndexSelectors>
                                    {unitIndexSelectors}
                                </IndexSelectors>
                                <InterfaceControls>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={resetFunction}
                                    >
                                        RESET
                                    </InterfaceButton>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={randomFunction}
                                    >
                                        RANDOM
                                    </InterfaceButton>

                                    <ExportMechButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                        mechType={"NPC"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <ExportMechPartsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                        mechType={"NPC"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <ExportAllMechPartsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                        mechType={"NPC"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <ExportAllMechPartsAndTintsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                        mechType={"NPC"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={paintFunction}
                                    >
                                        APPLY COLOR
                                    </InterfaceButton>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={addUnitFunction}
                                    >
                                        FABRICATE
                                    </InterfaceButton>
                                </InterfaceControls>
                            </div>

                            {displayCustomControls ?
                                <CustomColorControls
                                    displayTheme={displayTheme}
                                    targetPartIsGlow={targetPartIsGlow}
                                /> : null
                            }
                        </MechForm>
                    ) : null}
                </MechUnitContainer>
            </ContainerWrapper>
        </UnitCategoryWrapper>
    )
}

MechNPCCategory.propTypes = {
    setShowPremiumPopupFunc: func.isRequired,
    unitCategory: object.isRequired,
};

export default MechNPCCategory;
