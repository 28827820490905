import styled from "styled-components";

import { retroTerminalColor } from "../Mechs/components/MechCategoryStyles";

import BaseURL from "../../data/images/Hosting-BaseURL";

const colorSampleGlowImage = `${BaseURL}/buttons/Color-Sample-Orb-Glow.png`;
const colorSampleMetalImage = `${BaseURL}/buttons/Color-Sample-Orb.png`;

export const CustomColorControlsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;

    &.default {
        margin-top: 15px;
        row-gap: 15px;
    }

    &.retro-terminal {
        row-gap: 10px;
        margin-top: 10px;
    }
`;

CustomColorControlsWrapper.displayName = "CustomColorControlsWrapper";

export const InputRange = styled.input`
    background-color: transparent;
    font-weight: bolder;
    border: none;

    /* RESET STYLING */
    &[type=range] {
        -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
        width: 90%; /* Specific width is required for Firefox. */
        background: transparent; /* Otherwise white in Chrome */
    }

    &[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
    }

    &[type=range]:focus {
        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    &[type=range]::-ms-track {
        width: 90%;
        cursor: pointer;

        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
    }
    /* RESET STYLING */

    &[type=range]::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 21px;
        width: 21px;
        margin: 0;
        cursor: pointer;
        background-image: url(${props => props.targetPartIsGlow ? colorSampleGlowImage : colorSampleMetalImage});
        margin-top: -8px;
        filter: ${props => props.tintValue};
    }

    &[type=range]::-moz-range-thumb {
        -webkit-appearance: none;
        height: 21px;
        width: 21px;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        background-image: url(${props => props.targetPartIsGlow ? colorSampleGlowImage : colorSampleMetalImage});
        filter: ${props => props.tintValue};
    }

    &[type=range]::-ms-thumb {
        -webkit-appearance: none;
        height: 21px;
        width: 21px;
        background-repeat: no-repeat;
        cursor: pointer;
        background-image: url(${props => props.targetPartIsGlow ? colorSampleGlowImage : colorSampleMetalImage});
        filter: ${props => props.tintValue};
    }

    &[type=range]::-webkit-slider-runnable-track {
        width: 90%;
        cursor: pointer;
        border-color: transparent;
        color: transparent;
        height: 5px;
    }

    &[type=range]::-moz-range-track {
        width: 90%;
        cursor: pointer;
        border-color: transparent;
        color: transparent;
    }

    &[type=range]::-ms-track {
        width: 90%;
        cursor: pointer;
        border-color: transparent;
        color: transparent;
    }

    &[type=number] {
        font-size: 1.1em;
        width: 90%;
    }

    &[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }


    &.retro-terminal {
        &[type=range]::-webkit-slider-runnable-track {
            background-color: ${retroTerminalColor};
        }

        &[type=range]::-moz-range-track {
            background-color: ${retroTerminalColor};
        }

        &[type=range]::-ms-track {
            background-color: ${retroTerminalColor};
        }
    }
`;

InputRange.displayName = "InputRange";

export const ColorSelectorButton = styled.img`
    margin-top: 2px;
    margin-left: 5px;
    cursor: pointer;
`;

ColorSelectorButton.displayName = "ColorSelectorButton";

export const ColorSelectorOption = styled.img`
    margin: 2px;
    padding: 3px;
    cursor: pointer;

    border: 2px solid transparent;

    &.is-selected {
        border: 2px solid gray;
    }
`;

ColorSelectorOption.displayName = "ColorSelectorOption";

export const ColorSaveContainer = styled.section`
    display: flex;
    align-content: space-evenly;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
`;

ColorSaveContainer.displayName = "ColorSaveContainer";

export const ColorSaveButton = styled.img`
    cursor: pointer;
    filter: ${props => props.tintValue};
`;

ColorSaveButton.displayName = "ColorSaveButton";
