import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// GLOW
// SHIELD
const glowShieldDomeImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Shield Dome.png`;
const glowShieldImpactsImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Shield Impacts.png`;
const glowAegishjalmurImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Aegishjalmur.png`;
const glowFUImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - FU.png`;
const glowHamsaImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Hamsa.png`;
const glowMagicCircleImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Magic Circle.png`;
const glowSolomonImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Solomon.png`;
const glowAbsoluteTerrorImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Absolute Terror.png`;
const glowSTOPImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - STOP.png`;
const glowReverseImage = `${BaseURL}/Mechs/HA/Saladin/glows/shields/Glow - Reverse.png`;

const previewImage = `${BaseURL}/Mechs/HA/Saladin/preview-shields.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SOLOMON",
            foremostSrc: glowSolomonImage,
        },
        {
            value: 1,
            label: "HAMSA",
            foremostSrc: glowHamsaImage,
        },
        {
            value: 2,
            label: "MAGIC CIRCLE",
            foremostSrc: glowMagicCircleImage,
        },
        {
            value: 3,
            label: "AEGISHJALMUR",
            foremostSrc: glowAegishjalmurImage,
        },
        {
            value: 4,
            label: "ABSOLUTE TERROR",
            foremostSrc: glowAbsoluteTerrorImage,
        },
        {
            value: 5,
            label: "STOP",
            foremostSrc: glowSTOPImage,
        },
        {
            value: 6,
            label: "REVERSE",
            foremostSrc: glowReverseImage,
        },
        {
            value: 7,
            label: "FU",
            foremostSrc: glowFUImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SHIELD DOME",
            frontSrc: glowShieldDomeImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 1,
            label: "SHIELD DOME - IMPACTS",
            frontSrc: glowShieldImpactsImage,
            isPremium: true,
            shadowDetails: shadowDetails,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [
    ],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "SHIELD DOME",
            value: "chassis",
            isGlow: true,
        },
        {
            label: "SHIELD SYMBOL",
            value: "head",
            isGlow: true,
        },
    ],
    labels: {
        "head": "SHIELD SYMBOL",
        "chassis": "SHIELD DOME",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
