import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyDeathsHeadImage = `${BaseURL}/Mechs/SSC/Deaths Head/Chassis.png`;
// LEGENDARY
const bodyBrimstoneImage = `${BaseURL}/Mechs/SSC/Deaths Head/Chassis - Brimstone.png`;

// LEGS
const legsDeathsHeadImage = `${BaseURL}/Mechs/SSC/Deaths Head/Legs.png`;
// LEGENDARY
const legsBrimstoneImage = `${BaseURL}/Mechs/SSC/Deaths Head/Legs - Brimstone.png`;

// WEAPONS
// LEFT
const weaponsNoWeaponImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - No Weapon.png`;
const weaponsHunterKillerNexusImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Hunter-Killer Nexus.png`;
const weaponsAntiMatRifleImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Anti Materiel Rifle.png`;
const weaponsCyclonePulseRifleImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Cyclone Pulse Rifle.png`;
const weaponsMediumAutocannonImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Medium Autocannon.png`;
const weaponsHowitzerGrandeImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Howitzer Grande.png`;
const weaponsPlasmaCannonImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Plasma Cannon.png`;
const weaponsRailgunImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Railgun.png`;
const weaponsMimicGunImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/left/Left Weapon - Mimic Gun (ring).png`;

// RIGHT
const secondaryWeaponsNoWeaponImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/right/Right Weapon - No Weapon.png`;
const secondaryWeaponsTargettingSystemImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/right/Right Weapon - Targetting System.png`;
const secondaryWeaponsNexusImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/right/Right Weapon - Nexus.png`;
const secondaryWeaponsAssaultRifleImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/right/Right Weapon - Assault Rifle.png`;
const secondaryWeaponsVultureDMRImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/right/Right Weapon - Vulture DMR.png`;
const secondaryWeaponsMediumAutocannonImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/right/Right Weapon - Medium Autocannon.png`;
const secondaryWeaponsMissilePodImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/right/Right Weapon - Missile Pod.png`;

// GLOW
const glowMimicGunImage = `${BaseURL}/Mechs/SSC/Deaths Head/weapons/Glow - Mimic Gun.png`;

const coreImage = `${BaseURL}/Mechs/SSC/Deaths Head/Death_s Head (by Alrækinn).png`;
const core2Image = `${BaseURL}/Mechs/SSC/Deaths Head/Death_s Head (by CharlieMerllynn).png`;

const previewImage = `${BaseURL}/Mechs/SSC/Deaths Head/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "86px",
    right: "7px",
};

const brimtstoneLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "76px",
    right: "7px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Alrækinn",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerllynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "7",
        "secondaryWeaponValue": "4",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DEATHS HEAD",
            foremostSrc: bodyDeathsHeadImage,
        },
        {
            value: 1,
            label: "BRIMSTONE",
            foremostSrc: bodyBrimstoneImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DEATHS HEAD",
            src: legsDeathsHeadImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "BRIMSTONE",
            src: legsBrimstoneImage,
            shadowDetails: brimtstoneLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "EMPTY MOUNT",
                src: weaponsNoWeaponImage,
            },
            {
                value: 1,
                label: "HUNTER-KILLER NEXUS",
                src: weaponsHunterKillerNexusImage,
            },
            {
                value: 2,
                label: "ANTI-MATERIEL RIFLE",
                src: weaponsAntiMatRifleImage,
            },
            {
                value: 3,
                label: "CYCLONE PULSE RIFLE",
                src: weaponsCyclonePulseRifleImage,
            },
            {
                value: 4,
                label: "MEDIUM AUTOCANNON",
                src: weaponsMediumAutocannonImage,
            },
            {
                value: 5,
                label: "HOWITZER GRANDE",
                src: weaponsHowitzerGrandeImage,
            },
            {
                value: 6,
                label: "PLASMA CANNON",
                src: weaponsPlasmaCannonImage,
            },
            {
                value: 7,
                label: "RAILGUN",
                src: weaponsRailgunImage,
            },
            {
                value: 8,
                label: "MIMIC GUN",
                src: weaponsMimicGunImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "EMPTY MOUNT",
            foremostSrc: secondaryWeaponsNoWeaponImage,
        },
        {
            value: 1,
            label: "TARGETING SYSTEM",
            foremostSrc: secondaryWeaponsTargettingSystemImage,
        },
        {
            value: 2,
            label: "NEXUS",
            foremostSrc: secondaryWeaponsNexusImage,
        },
        {
            value: 3,
            label: "ASSAULT RIFLE",
            foremostSrc: secondaryWeaponsAssaultRifleImage,
        },
        {
            value: 4,
            label: "VULTURE DMR",
            foremostSrc: secondaryWeaponsVultureDMRImage,
        },
        {
            value: 5,
            label: "MEDIUM AUTOCANNON",
            foremostSrc: secondaryWeaponsMediumAutocannonImage,
        },
        {
            value: 6,
            label: "MISSILE POD",
            foremostSrc: secondaryWeaponsMissilePodImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "GLOW - MIMIC GUN",
            frontSrc: glowMimicGunImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "CHASSIS",
            value: "head",
        },
        {
            label: "LEGS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "CHASSIS",
        "chassis": "LEGS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
