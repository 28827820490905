import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Nelson`;

// BODY
const bodyNelsonImage = `${imagePath}/Body Nelson.png`;
const bodyNelsonCoverImage = `${imagePath}/Body Chin Cover Top.png`;

// REAR MOUNT
const rearMountRamjetsImage = `${imagePath}/rear mount/Rear Mount Ramjets.png`;
const rearMountARImage = `${imagePath}/rear mount/Rear Mount AR.png`;
const rearMountBoostersFrontImage = `${imagePath}/rear mount/rear - boosters over.png`;
const rearMountBoostersImage = `${imagePath}/rear mount/rear - boosters.png`;

// HEADS
const headNelsonImage = `${imagePath}/heads/Head Nelson.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
// PREMIUM
const headAImage = `${imagePath}/heads/premium/Head A.png`;
const headBImage = `${imagePath}/heads/premium/Head B.png`;
const headCImage = `${imagePath}/heads/premium/Head C.png`;
const headDImage = `${imagePath}/heads/premium/Head D.png`;
const headEImage = `${imagePath}/heads/premium/Head E.png`;
const headFImage = `${imagePath}/heads/premium/Head F.png`;
const headGImage = `${imagePath}/heads/premium/Head G.png`;
const headHImage = `${imagePath}/heads/premium/Head H.png`;
const headIImage = `${imagePath}/heads/premium/Head I.png`;
const headJImage = `${imagePath}/heads/premium/Head J.png`;
const headKImage = `${imagePath}/heads/premium/Head K.png`;
const headLImage = `${imagePath}/heads/premium/Head L.png`;
const headMImage = `${imagePath}/heads/premium/Head M.png`;
const headTortugaImage = `${imagePath}/heads/premium/Head Tortuga.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;
// LEGENDARY
const headNelsonLegendaryImage = `${imagePath}/heads/legendary/Head L.png`;

// ACCESSORY
const ramImage = `${imagePath}/accessories/Accessory Ram.png`;
const robesBackImage = `${imagePath}/accessories/Accessory Robes Back.png`;
const robesMidImage = `${imagePath}/accessories/Accessory Robes Mid.png`;
const robesFrontImage = `${imagePath}/accessories/Accessory Robes Top.png`;
const lanceSpikesImage = `${imagePath}/accessories/nelson lance spikes.png`;
const lanceSpikesBloodyImage = `${imagePath}/accessories/nelson lance spikes bloody.png`;

// PREMIUM
const cloakTopHoodImage = `${imagePath}/accessories/premium/Accessory Cloak Hood Up Top.png`;
const cloakTopImage = `${imagePath}/accessories/premium/Accessory Cloak Top.png`;
const cloakMidImage = `${imagePath}/accessories/premium/Accessory Cloak Under Head.png`;
const cloakBackImage = `${imagePath}/accessories/premium/Accessory Cloak Back.png`;
const furCloakBackImage = `${imagePath}/accessories/Accessory Fur Cloak back.png`;
const furCloakMidImage = `${imagePath}/accessories/Accessory Fur Cloak mid.png`;
const furCloakFrontImage = `${imagePath}/accessories/Accessory Fur Cloak top.png`;
// LEGENDARY
const eliteKnightBackImage = `${imagePath}/accessories/legendary/Accessory Elite Knight Back.png`;
const eliteKnightFrontImage = `${imagePath}/accessories/legendary/Accessory Elite Knight Top.png`;

// 1H RIGHT
const chainAxeRight1HImage = `${imagePath}/weapons/right/Right Weapon Chain Axe.png`;
const katanaRight1HImage = `${imagePath}/weapons/right/Right Weapon Katana.png`;
const lSwordRight1HImage = `${imagePath}/weapons/right/Right Weapon Longsword.png`;
const spearRight1HImage = `${imagePath}/weapons/right/Right Weapon Spear.png`;
const tBladeRight1HImage = `${imagePath}/weapons/right/Right Weapon Thermal Blade.png`;
const lanceRight1HImage = `${imagePath}/weapons/right/Right Weapon Lance.png`;
const nelsonSwordRight1HImage = `${BaseURL}/Mechs/GMS/weapons/1H/gms nelson sword.png`;
// PREMIUM
const bladeRight1HImage = `${imagePath}/weapons/right/premium/Right Weapon Blade.png`;
const hammerRight1HImage = `${imagePath}/weapons/right/premium/Right Weapon Hammer.png`;
const macheteRight1HImage = `${imagePath}/weapons/right/premium/Right Weapon Machete.png`;
const zweihanderRight1HImage = `${imagePath}/weapons/right/premium/Right Weapon Zweihander.png`;
const gcShieldRight1HImage = `${imagePath}/weapons/right/premium/Right Weapon GC Shield.png`;

// 1H LEFT
const arLeft1HImage = `${imagePath}/weapons/left/Left Weapon AR.png`;
const axeLeft1HImage = `${imagePath}/weapons/left/Left Weapon Axe.png`;
const hammerLeft1HImage = `${imagePath}/weapons/left/Left Weapon Hammer.png`;
const axeHammerHandleLeft1HImage = `${imagePath}/weapons/left/Left Weapon Axe and Hammer handle.png`;
const extenableBladeLeft1HImage = `${imagePath}/weapons/left/Left Weapon Extendable Blade Top.png`;
const extendableBladeBackLeft1HImage = `${imagePath}/weapons/left/Left Weapon Extendable Blade Back.png`;
const knucklesLeft1HImage = `${imagePath}/weapons/left/Left Weapon Knuckles.png`;
const grenadeLauncherLeft1HImage = `${imagePath}/weapons/left/Left Weapon Grenade Launcher.png`;
const lSwordLeft1HImage = `${imagePath}/weapons/left/Left Weapon Longsword.png`;
const lSwordHandleLeft1HImage = `${imagePath}/weapons/left/Left Weapon Longsword handle.png`;
const machinePistolLeft1HImage = `${imagePath}/weapons/left/Left Weapon Machine Pistol.png`;
const nailgunLeft1HImage = `${imagePath}/weapons/left/Left Weapon Nailgun.png`;
const revolverLeft1HImage = `${imagePath}/weapons/left/Left Weapon Revolver.png`;
const rpgLeft1HImage = `${imagePath}/weapons/left/Left Weapon RPG.png`;
const shotgunLeft1HImage = `${imagePath}/weapons/left/Left Weapon Shotgun.png`;
const tBladeLeft1HImage = `${imagePath}/weapons/left/Left Weapon Thermal Blade.png`;
// PREMIUM
const monoSwordLeft1HImage = `${imagePath}/weapons/left/premium/Left Weapon Monofilament Sword.png`;
const sickleLeft1HImage = `${imagePath}/weapons/left/premium/Left Weapon Sickle.png`;
const zweihanderLeft1HImage = `${imagePath}/weapons/left/premium/Left Weapon Zwei.png`;

const coreImage = `${imagePath}/Nelson (by Corvon).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "101px",
    right: "51px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "4",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "PALADIN",
            src: headBImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "OGRE",
            src: headCImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ROGUE",
            src: headDImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "BASTION",
            src: headEImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ICARUS",
            src: headFImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCARAB",
            src: headGImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RAMSHACKLE",
            src: headHImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SLIPSTREAM",
            src: headIImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "BUNKER",
            src: headJImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            src: headKImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "TALON",
            src: headLImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "JUDICATOR",
            src: headMImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "TORTUGA",
            src: headTortugaImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "FACTORY STANDARD",
            src: headAImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "ELITE KNIGHT",
            src: headNelsonLegendaryImage,
            isPremium: true,
            isLegendary: true,
        }
    ],
    chassis: [
        {
            value: 0,
            label: "NELSON",
            src: bodyNelsonImage,
            frontSrc: bodyNelsonCoverImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "NAILGUN",
                src: nailgunLeft1HImage,
            },
            {
                value: 1,
                label: "REVOLVER",
                src: revolverLeft1HImage,
            },
            {
                value: 2,
                label: "MACHINE PISTOL",
                src: machinePistolLeft1HImage,
            },
            {
                value: 3,
                label: "AR",
                src: arLeft1HImage,
            },
            {
                value: 4,
                label: "SHOTGUN",
                src: shotgunLeft1HImage,
            },
            {
                value: 5,
                label: "KNUCKLES",
                src: knucklesLeft1HImage,
            },
            {
                value: 6,
                label: "EXTENDABLE BLADE",
                src: extenableBladeLeft1HImage,
                backSrc: extendableBladeBackLeft1HImage,
            },
            {
                value: 7,
                label: "LONGSWORD",
                src: lSwordLeft1HImage,
                backSrc: lSwordHandleLeft1HImage,
            },
            {
                value: 8,
                label: "HAMMER",
                src: hammerLeft1HImage,
                backSrc: axeHammerHandleLeft1HImage,
            },
            {
                value: 9,
                label: "AXE",
                src: axeLeft1HImage,
                backSrc: axeHammerHandleLeft1HImage,
            },
            {
                value: 10,
                label: "THERMAL BLADE",
                src: tBladeLeft1HImage,
            },
            {
                value: 11,
                label: "GRENADE LAUNCHER",
                src: grenadeLauncherLeft1HImage,
            },
            {
                value: 12,
                label: "RPG",
                src: rpgLeft1HImage,
            },
            {
                value: 13,
                label: "MONOFILAMENT SWORD",
                src: monoSwordLeft1HImage,
                isPremium: true,
            },
            {
                value: 14,
                label: "SICKLE",
                src: sickleLeft1HImage,
                isPremium: true,
            },
            {
                value: 15,
                label: "ZWEIHANDER",
                src: zweihanderLeft1HImage,
                isPremium: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "LONGSWORD",
            src: lSwordRight1HImage,
        },
        {
            value: 1,
            label: "KATANA",
            src: katanaRight1HImage,
        },
        {
            value: 2,
            label: "CHAIN AXE",
            src: chainAxeRight1HImage,
        },
        {
            value: 3,
            label: "THERMAL BLADE",
            src: tBladeRight1HImage,
        },
        {
            value: 4,
            label: "SPEAR",
            src: spearRight1HImage,
        },
        {
            value: 5,
            label: "LANCE",
            src: lanceRight1HImage,
        },
        {
            value: 6,
            label: "GREAT SWORD",
            src: nelsonSwordRight1HImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 7,
            label: "BLADE",
            src: bladeRight1HImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HAMMER",
            src: hammerRight1HImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "MACHETE",
            src: macheteRight1HImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "ZWEIHANDER",
            src: zweihanderRight1HImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "FIELD CREST SHIELD",
            src: gcShieldRight1HImage,
            isPremium: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "RAMJETS",
            src: rearMountRamjetsImage,
        },
        {
            value: 1,
            label: "BACK AR",
            src: rearMountARImage,
        },
        {
            value: 2,
            label: "BOOSTERS",
            src: rearMountBoostersImage,
            frontSrc: rearMountBoostersFrontImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "RAM",
            src: ramImage,
            frontSrc: ramImage,
        },
        {
            value: 1,
            label: "KNUCKLES",
            src: knucklesLeft1HImage,
        },
        {
            value: 2,
            label: "ROBES",
            frontSrc: robesFrontImage,
            src: robesMidImage,
            backSrc: robesBackImage,
        },
        {
            value: 3,
            label: "LANCE SPIKES",
            frontSrc: lanceSpikesImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 4,
            label: "BLOODY LANCE SPIKES",
            frontSrc: lanceSpikesBloodyImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 5,
            label: "CLOAK",
            frontSrc: cloakTopImage,
            src: cloakMidImage,
            backSrc: cloakBackImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "HOODED CLOAK",
            frontSrc: cloakTopHoodImage,
            src: cloakMidImage,
            backSrc: cloakBackImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "FUR CLOAK",
            frontSrc: furCloakFrontImage,
            src: furCloakMidImage,
            backSrc: furCloakBackImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "ELITE KNIGHT",
            frontSrc: cloakTopImage,
            src: eliteKnightFrontImage,
            backSrc: eliteKnightBackImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
