import BaseURL from "./images/Hosting-BaseURL";
import { shadowDetailsNone } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/IPS-N-Bligh-Swabber-Drones`;

// BODY
const bodyCannonImage = `${imagePath}/Swabber Drone - Cannon.png`;
const bodyRifleImage = `${imagePath}/Swabber Drone - Rifle.png`;
const bodySwordImage = `${imagePath}/Swabber Drone - Sword.png`;

// GLOW
const glowImage = `${imagePath}/Swabber - Glow.png`;

const previewImage = `${imagePath}/preview.png`;


const coreImage = `${imagePath}/bligh_core_drone1_by_snipertoaster.png`;
const core2Image = `${imagePath}/bligh_core_drone2_by_snipertoaster.png`;
const core3Image = `${imagePath}/bligh_core_drone3_by_snipertoaster.png`;

const originalArtCredit = {
    prefixText: "Commissioned by Ralf Ziegler. Original design by ",
    title: "Franceso Silva",
    url: "https://twitter.com/francescolobo?lang=en",
};

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core3Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "EYE",
            src: glowImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SWABBER DRONE - CANNON",
            src: bodyCannonImage,
            originalArtCredit,
            shadowDetails,
        },
        {
            value: 1,
            label: "SWABBER DRONE - RIFLE",
            src: bodyRifleImage,
            originalArtCredit,
            shadowDetails,
        },
        {
            value: 2,
            label: "SWABBER DRONE - SWORD",
            src: bodySwordImage,
            originalArtCredit,
            shadowDetails,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
