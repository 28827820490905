import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyDuskWingImage = `${BaseURL}/Mechs/SSC/Dusk Wing/Chassis.png`;

const armsDuskWingImage = `${BaseURL}/Mechs/SSC/Dusk Wing/Tertiary Arms.png`;

// HEADS
const headDuskWingImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/Head - Dusk Wing.png`;
const headKazuImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/Head - Kazu.png`;
// PREMIUM
const headBriarImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Briar.png`;
const headCalibanImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Caliban.png`;
const headHornedImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Horned Hair.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Rain.png`;
const headRainHairImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Rain Hair.png`;
const headVanguardImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Vanguard.png`;
const headVanquisherImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Vanquisher.png`;
const headCockpitImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/SSC/Dusk Wing/heads/premium/Head - Scopehound.png`;

// MAIN WEAPON
const weaponEmptyImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/Primary Arms - Empty.png`;
const weaponKnivesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/Primary Arms - Knives.png`;
const weaponPistolsImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/Primary Arms - Pistols.png`;
const weaponVeilRifleImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/Primary Arms - Veil Rifle.png`;

// SECONDARY WEAPON
const secondaryWeaponEmptyImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/secondary/Secondary Arms - Empty.png`;
const secondaryWeaponKnivesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/secondary/Secondary Arms - Knives.png`;
const secondaryWeaponPistolsImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/secondary/Secondary Arms - Pistols.png`;
const secondaryWeaponEmptyTertiaryImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/secondary/Secondary Arms - Empty and Tertiary.png`;
const secondaryWeaponKnivesTertiaryImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/secondary/Secondary Arms - Knives and Tertiary.png`;
const secondaryWeaponPistolsTertiaryImage = `${BaseURL}/Mechs/SSC/Dusk Wing/weapons/secondary/Secondary Arms - Pistols and Tertiary.png`;
const rearMountGatekeeperFrontImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear - Gatekeeper (over).png`;
const rearMountGatekeeperBackImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear - Gatekeeper (under).png`;

// REAR MOUNTS
const rearMountMissilesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear Weapon - Missiles.png`;
const rearMountDualMissilesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear Weapon - Dual Missiles.png`;
const rearMountNexusImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear Weapon - Nexus.png`;
const rearMountDualNexusImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear Weapon - Dual Nexus.png`;
const rearMountMissilesNexusImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear Weapon - Missiles and Nexus.png`;
const rearMountFlightUnitImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear - Flight Unit.png`;
const rearMountFlightUnitDualMissilesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear - Flight Unit Dual Missiles.png`;
const rearMountFlightUnitDualNexusImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear - Flight Unit Dual Nexus.png`;
const rearMountFlightUnitMissileNexusImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/Rear - Flight Unit Missile Nex.png`;
const rearMountFlightUnitSmallImage = `${BaseURL}/Mechs/SSC/Dusk Wing/rear mounts/duskwing flight unit small.png`;

// ACCESSORY
const accessoryKnifeHolsterImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Accessory - Knife Holsters.png`;
const glowCommandlineImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Commandline.png`;
const glowDuskWingCloakImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Dusk Wing Cloak.png`;
const glowGatekeeperImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper.png`;
const glowGatekeeperOverImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper Over.png`;
const glowGatekeeperSecondaryImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper (Secondary).png`;
const glowGatekeeperSecondaryAndEyesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper (secondary) and Eyes.png`;
const glowGatekeeperAndEyesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper and Eyes.png`;
const glowGatekeeperAndEyesOverImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper and Eyes - over.png`;
const glowGatekeeperEyesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper Eyes.png`;
const glowGatekeeperTrailImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper Trail.png`;
const glowGatekeeperTrailAndEyesImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Gatekeeper Trail and Eyes.png`;
const glowVanquisherImage = `${BaseURL}/Mechs/SSC/Dusk Wing/glow/Glow - Vanquisher Head.png`;

const coreImage = `${BaseURL}/Mechs/SSC/Dusk Wing/Dusk-Wing (by DedLei).png`;
const core2Image = `${BaseURL}/Mechs/SSC/Dusk Wing/Dusk-Wing (by Hit-Monkey).png`;

const previewImage = `${BaseURL}/Mechs/SSC/Dusk Wing/preview.png`;

const hoverShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "29px",
    right: "3px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "DedLei",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Hit-Monkey",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    removeNone: {
        weapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "5",
        "weaponValue": "0",
        "secondaryWeaponValue": "4",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DUSK WING",
            foremostSrc: headDuskWingImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "HORNED",
            foremostSrc: headHornedImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "RAIN",
            foremostSrc: headRainImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "VANGUARD",
            foremostSrc: headVanguardImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "VANQUISHER",
            foremostSrc: headVanquisherImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "COCKPIT",
            foremostSrc: headCockpitImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "BRIAR",
            foremostSrc: headBriarImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DUSK WING",
            src: bodyDuskWingImage,
            shadowDetails: hoverShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "EMPTY",
                src: weaponEmptyImage,
            },
            {
                value: 1,
                label: "KNIVES",
                src: weaponKnivesImage,
            },
            {
                value: 2,
                label: "PISTOLS",
                src: weaponPistolsImage,
            },
            {
                value: 3,
                label: "VEIL RIFLE",
                src: weaponVeilRifleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "EMPTY",
            backSrc: secondaryWeaponEmptyImage,
        },
        {
            value: 1,
            label: "KNIVES",
            backSrc: secondaryWeaponKnivesImage,
        },
        {
            value: 2,
            label: "PISTOLS",
            backSrc: secondaryWeaponPistolsImage,
        },
        {
            value: 3,
            label: "TERTIARY ARMS",
            src: armsDuskWingImage,
        },
        {
            value: 4,
            label: "EMPTY + TERTIARY",
            src: secondaryWeaponEmptyTertiaryImage,
        },
        {
            value: 5,
            label: "KNIVES + TERTIARY",
            src: secondaryWeaponKnivesTertiaryImage,
        },
        {
            value: 6,
            label: "PISTOLS + TERTIARY",
            src: secondaryWeaponPistolsTertiaryImage,
        },
        {
            value: 7,
            label: "EMPTY + GATEKEEPER",
            src: secondaryWeaponEmptyImage,
            backSrc: rearMountGatekeeperBackImage,
            frontSrc: rearMountGatekeeperFrontImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "KNIVES + GATEKEEPER",
            src: secondaryWeaponKnivesImage,
            backSrc: rearMountGatekeeperBackImage,
            frontSrc: rearMountGatekeeperFrontImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 9,
            label: "PISTOLS + GATEKEEPER",
            src: secondaryWeaponPistolsImage,
            backSrc: rearMountGatekeeperBackImage,
            frontSrc: rearMountGatekeeperFrontImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 10,
            label: "GATEKEEPER",
            backSrc: rearMountGatekeeperBackImage,
            frontSrc: rearMountGatekeeperFrontImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "MISSILES",
            src: rearMountMissilesImage,
        },
        {
            value: 1,
            label: "DUAL MISSILES",
            src: rearMountDualMissilesImage,
        },
        {
            value: 2,
            label: "NEXUS",
            src: rearMountNexusImage,
        },
        {
            value: 3,
            label: "DUAL NEXUS",
            src: rearMountDualNexusImage,
        },
        {
            value: 4,
            label: "MISSILES + NEXUS",
            src: rearMountMissilesNexusImage,
        },
        {
            value: 5,
            label: "FLIGHT UNIT",
            src: rearMountFlightUnitImage,
        },
        {
            value: 6,
            label: "FLIGHT UNIT - SMALL",
            src: rearMountFlightUnitSmallImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 7,
            label: "FLIGHT UNIT + DUAL MISSILES",
            src: rearMountFlightUnitDualMissilesImage,
        },
        {
            value: 8,
            label: "FLIGHT UNIT + DUAL NEXUS",
            src: rearMountFlightUnitDualNexusImage,
        },
        {
            value: 9,
            label: "FLIGHT UNIT + MISSILE + NEXUS",
            src: rearMountFlightUnitMissileNexusImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "KNIFE HOLSTER",
            src: accessoryKnifeHolsterImage,
        },
        {
            value: 1,
            label: "GLOW - DUSK WING CLOAK",
            frontSrc: glowDuskWingCloakImage,
        },
        {
            value: 2,
            label: "GLOW - COMMANDLINE",
            foremostSrc: glowCommandlineImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "GLOW - VANQUISHER HEAD",
            foremostSrc: glowVanquisherImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "GLOW - GATEKEEPER",
            src: glowGatekeeperImage,
            foremostSrc: glowGatekeeperOverImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "GLOW - GATEKEEPER EYES",
            foremostSrc: glowGatekeeperEyesImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "GLOW - GATEKEEPER + EYES",
            src: glowGatekeeperAndEyesImage,
            foremostSrc: glowGatekeeperAndEyesOverImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "GLOW - GATEKEEPER SECONDARY",
            src: glowGatekeeperSecondaryImage,
            foremostSrc: glowGatekeeperOverImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "GLOW - GATEKEEPER SECONDARY + EYES",
            src: glowGatekeeperSecondaryAndEyesImage,
            foremostSrc: glowGatekeeperAndEyesOverImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 9,
            label: "GLOW - GATEKEEPER TRAIL",
            src: glowGatekeeperTrailImage,
            foremostSrc: glowGatekeeperOverImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 10,
            label: "GLOW - GATEKEEPER TRAIL + EYES",
            src: glowGatekeeperTrailAndEyesImage,
            foremostSrc: glowGatekeeperAndEyesOverImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN ARMS",
            value: "weapon",
        },
        {
            label: "AUX ARMS",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN ARMS",
        "secondaryWeapon": "AUX ARMS",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
