import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Kaiju/Annihelid`;

// BODY
const bodyImage = `${imagePath}/Annihelid_Body.png`;

// HEADS
const headBeakImage = `${imagePath}/heads/Annihelid_Head_Beak.png`;
const headDeathWormImage = `${imagePath}/heads/Annihelid_Head_Death Worm.png`;
const headDrillerImage = `${imagePath}/heads/Annihelid_Head_Driller.png`;
const headGraboidImage = `${imagePath}/heads/Annihelid_Head_Graboid.png`;
const headPolynoidaeImage = `${imagePath}/heads/Annihelid_Head_Polynoidae.png`;

// ARMS
const armsImage = `${imagePath}/arms/Annihelid_Arms.png`;

// ARMOR
const armorImage = `${imagePath}/armor/Annihelid_Accessories_Armor.png`;
const armorBackImage = `${imagePath}/armor/Annihelid_Accessories Back_Armor.png`;

// REAR MOUNT
const tailRudderImage = `${imagePath}/rearmount/Annihelid_Tail_Rudder.png`;
const tailStingerImage = `${imagePath}/rearmount/Annihelid_Tail_Stinger.png`;

// ACCESSORY
const accessoryCowboyImage = `${imagePath}/accessory/Annihelid_Accessories_Cowboy.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-19px",
    right: "-31px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BEAK",
            foremostSrc: headBeakImage,
        },
        {
            value: 1,
            label: "DEATH WORM",
            foremostSrc: headDeathWormImage,
        },
        {
            value: 2,
            label: "DRILLER",
            foremostSrc: headDrillerImage,
        },
        {
            value: 3,
            label: "GRABOID",
            foremostSrc: headGraboidImage,
        },
        {
            value: 4,
            label: "POLYNOIDAE",
            foremostSrc: headPolynoidaeImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ANNIHELID",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.PINKHYENA,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ARMS",
                src: armsImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARMOR",
            src: armorImage,
            backSrc: armorBackImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "RUDDER",
            frontSrc: tailRudderImage,
        },
        {
            value: 1,
            label: "STINGER",
            frontSrc: tailStingerImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "COWBOY",
            frontSrc: accessoryCowboyImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "BODY",
            value: "chassis",
        },
        {
            label: "TAIL",
            value: "rearMount",
        },
        {
            label: "ARMS",
            value: "weapon",
        },
        {
            label: "ARMOR",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "BODY",
        "rearMount": "TAIL",
        "weapon": "ARMS",
        "secondaryWeapon": "ARMOR",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
