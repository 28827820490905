import { shadowTypes } from "../styles/UnitShadow";
import { removeTintValues } from "./common/settings";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Heroes/Dragon`;

// BODY
const bodyImage = `${imagePath}/Dragon Body.png`;

// HEADS
const headCerinaImage = `${imagePath}/heads/Head - Cerina.png`;
const headNychtImage = `${imagePath}/heads/Head -  Nycht.png`;
const headMalakeetImage = `${imagePath}/heads/Head - Malakeet.png`;

// WINGS
const wingsCerinaImage = `${imagePath}/wings/Wings - Cerina.png`;
const wingsCerinaUnderImage = `${imagePath}/wings/Wings Under - Cerina.png`;
const wingsNychtImage = `${imagePath}/wings/Wings - Nycht.png`;
const wingsNychtUnderImage = `${imagePath}/wings/Wings Under - Nycht.png`;

// ACCESSORIES
const scalesCerinaImage = `${imagePath}/armor/Scales - Cerina.png`;
const scalesCerinaUnderImage = `${imagePath}/armor/Scales - Cerina under.png`;
const scalesCerinaWingsImage = `${imagePath}/armor/Scales - Cerina Wings.png`;
const glowMalakeetEyeImage = `${imagePath}/glows/Glow - Malakeet Eye.png`;
const glowCerinaWingsOverImage = `${imagePath}/glows/Glow - Cerina Wings Over.png`;
const glowCerinaWingsUnderImage = `${imagePath}/glows/Glow - Cerina Wings Under.png`;
const glowNychtWingsOverImage = `${imagePath}/glows/Glow - Nycht Wings Over.png`;
const glowNychtWingsUnderImage = `${imagePath}/glows/Glow - Nycht Wings Under.png`;

// GLOWS
const glowBodyImage = `${imagePath}/glows/Body Glow - Torso.png`;
const glowBodyInternalCombustionImage = `${imagePath}/glows/Glow - Body - Internal Combustion.png`;

const previewImage = `${imagePath}/preview.png`;

const shadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-18px",
    right: "38px",
};

const defaultSelectionValues = {
    "skinValue": "0",
    "faceValue": "0",
    "eyesValue": "NONE",
    "hairValue": "0",
    "facialHairValue": "NONE",
    "headValue": "NONE",
    "chestValue": "NONE",
    "legsValue": "NONE",
    "leftArmValue": "NONE",
    "rightArmValue": "NONE",
    "leftWeaponValue": "NONE",
    "rightWeaponValue": "NONE",
    "accessory1Value": "0",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const defaultValues = Object.assign({}, defaultSelectionValues, removeTintValues);

export default {
    isMegadeusPlus: true,
    previewImg: previewImage,
    defaultValues: JSON.stringify(defaultValues),
    skin: [
        {
            value: 0,
            label: "Body",
            src: bodyImage,
            shadowDetails: shadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Dragongirls Anonymous",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    face: [
        {
            value: 0,
            label: "Cerina",
            src: headCerinaImage,
        },
        {
            value: 1,
            label: "Nycht",
            src: headNychtImage,
        },
        {
            value: 2,
            label: "Malakeet",
            src: headMalakeetImage,
        },
    ],
    eyes: [
        {
            value: 0,
            label: "Malakeet",
            frontSrc: glowMalakeetEyeImage,
        },
    ],
    hair: [
        {
            value: 0,
            label: "Cerina",
            src: wingsCerinaImage,
            backSrc: wingsCerinaUnderImage,
        },
        {
            value: 1,
            label: "Nycht",
            src: wingsNychtImage,
            backSrc: wingsNychtUnderImage,
        },
    ],
    facialHair: [],
    head: [],
    chest: [
        {
            value: 0,
            label: "Cerina",
            frontSrc: scalesCerinaImage,
            backSrc: scalesCerinaUnderImage,
        },
        {
            value: 1,
            label: "Cerina + Wings",
            frontSrc: scalesCerinaWingsImage,
        },
    ],
    legs: [],
    leftArm: [],
    rightArm: [],
    leftWeapon: [],
    rightWeapon: [],
    accessory: [
        {
            value: 0,
            label: "Body - Underbelly",
            src: glowBodyImage,
        },
        {
            value: 1,
            label: "Body - Internal Combustion",
            src: glowBodyInternalCombustionImage,
        },
        {
            value: 2,
            label: "Wings - Cerina",
            src: glowCerinaWingsUnderImage,
            frontSrc: glowCerinaWingsOverImage,
        },
        {
            value: 3,
            label: "Wings - Nycht",
            src: glowNychtWingsUnderImage,
            frontSrc: glowNychtWingsOverImage,
        },
    ],
    partList: [
        {
            label: "All",
            value: "ALL",
        },
        {
            label: "Body",
            value: "skin",
        },
        {
            label: "Head",
            value: "face",
        },
        {
            label: "Eyes",
            value: "eyes",
            isGlow: true,
        },
        {
            label: "Wings",
            value: "hair",
        },
        {
            label: "Wings",
            value: "hair",
        },
        {
            label: "Scales",
            value: "chest",
        },
        {
            label: "Glow 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "Glow 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "Glow 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "Glow 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "skin": "Body",
        "face": "Head",
        "eyes": "Eyes",
        "hair": "Wings",
        "facialHair": "Empty",
        "head": "Empty",
        "chest": "Scales",
        "legs": "Empty",
        "leftArm": "Empty",
        "rightArm": "Empty",
        "leftWeapon": "Empty",
        "rightWeapon": "Empty",
        "accessory1": "Glow 1",
        "accessory2": "Glow 2",
        "accessory3": "Glow 3",
        "accessory4": "Glow 4",
    },
    templateList: [
        {
            label: "Cerina",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
            )),
        },
        {
            label: "Nycht",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "faceValue": "1",
                    "hairValue": "1",
                },
            )),
        },
    ],
};
