import React, { createContext, useState } from "react";

import url from "url";

export const ShowLandingContext = createContext(null);

function ShowLandingContextWrapper ({ ...props }) {
    const startWithLandingCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`startWithLanding=`));
    const [startWithLanding, setStartWithLanding] = useState(startWithLandingCookie.length && startWithLandingCookie[0].split("=")[1] === "false" ? false : true);

    function toggleStartWithLanding() {
        setStartWithLanding(!startWithLanding);

        document.cookie = `startWithLanding=${!startWithLanding}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
    }

    const wasRedirectedFromLogin = !!url.parse(window.location.href, true).query.code;

    let showLandingFlag = startWithLanding;

    if (showLandingFlag && wasRedirectedFromLogin) {
        showLandingFlag = false;
    }

    const [showLanding, setShowLanding] = useState(showLandingFlag);

    return (
        <ShowLandingContext.Provider value={{ showLanding, setShowLanding, startWithLanding, toggleStartWithLanding }}>
            {props.children}
        </ShowLandingContext.Provider>
    );
}

export default ShowLandingContextWrapper;
