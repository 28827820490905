import React, { useState } from "react";

function ZoomWrapper({ ...props }) {
    const [zoomedValue, setZoomedValue] = useState(1);

    const childrenWithExtraProp = React.Children.map(props.children, child => {
        return React.cloneElement(child, {
            zoomedDisplayValue: zoomedValue,
            zoomedFunc: () => setZoomedValue(zoomedValue === 4 ? 1 : zoomedValue + 1),
        });
    });

    return (
        <>
            {childrenWithExtraProp}
        </>
    );
}

export default ZoomWrapper;
