import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Horus/Lich`;

// BODY
const bodyLichImage = `${imagePath}/Body.png`;
const bodyUnravellerImage = `${imagePath}/Body-Unraveller.png`;
const bodyUnravellerHeadOverImage = `${imagePath}/Unraveller Head Over.png`;

// HEADS
const headLichImage = `${imagePath}/heads/Head - Lich.png`;
const headUnravellerImage = `${imagePath}/heads/Head - Lich Unraveller.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_Lich.png`;
// PREMIUM
const headFactoryStandardImage = `${imagePath}/heads/premium/Head - Factory Standard.png`;
const headOgreImage = `${imagePath}/heads/premium/Head - Ogre.png`;
const headRogueImage = `${imagePath}/heads/premium/Head - Rogue.png`;
const headNelsonImage = `${imagePath}/heads/premium/Head - Nelson.png`;
const headJudicatorImage = `${imagePath}/heads/premium/Head - Judicator.png`;
const headBunkerImage = `${imagePath}/heads/premium/Head - Bunker.png`;
const headRumbleImage = `${imagePath}/heads/premium/Head - Rumble.png`;
const headUshabtiImage = `${imagePath}/heads/premium/Head - Ushabti.png`;
const headSlipstreamImage = `${imagePath}/heads/premium/Head - Slipstream.png`;
const headSkullImage = `${imagePath}/heads/premium/Head - Skull.png`;
const headIronSkullImage = `${imagePath}/heads/premium/Head - Iron Skull.png`;
const headIronSkull2Image = `${imagePath}/heads/premium/Head - Iron Skull 2.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;

// ROBES
const robesLichImage = `${imagePath}/accessories/Accessory- Robes.png`;
const robesUnravellerImage = `${imagePath}/accessories/Accessory- Robes Unraveller.png`;

// ACCESSORY
const accessoriesFlavaImage = `${imagePath}/accessories/Accessory- Flava.png`;
const accessoriesMudaFrontImage = `${imagePath}/accessories/Accessory- Muda.png`;
const accessoriesMudaBackImage = `${imagePath}/accessories/Accessory- Muda Behind.png`;

// GLOW
const accessoriesGlowUshtabiImage = `${imagePath}/accessories/Accessory - Ushabti Glow.png`;
const accessoriesUnravellerImage = `${imagePath}/accessories/Accessory- Unraveller Glow.png`;
const accessoriesUnravellerLichImage = `${imagePath}/accessories/Accessory- Unraveller Lich Head Glow.png`;
const accessoriesUnravellerUshabtiImage = `${imagePath}/accessories/Accessory- Unraveller and Ushabti Glow.png`;

const coreImage = `${imagePath}/Lich (by Seamless).png`;
const core2Image = `${imagePath}/Lich (by CharlieMerllynn).png`;

const previewImage = `${imagePath}/preview.png`;

const floatLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "12px",
    right: "18px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerllynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "LICH",
            src: headLichImage,
        },
        {
            value: 1,
            label: "UNRAVELLER",
            src: headUnravellerImage,
        },
        {
            value: 2,
            label: "USHABTI",
            src: headUshabtiImage,
        },
        {
            value: 3,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 4,
            label: "BONEHEAD",
            src: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "OGRE",
            src: headOgreImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ROGUE",
            src: headRogueImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "BUNKER",
            src: headBunkerImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "SKULL",
            src: headSkullImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "IRON SKULL",
            src: headIronSkullImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "IRON SKULL 2",
            src: headIronSkull2Image,
            isPremium: true,
        },
        {
            value: 15,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "LICH",
            src: bodyLichImage,
            shadowDetails: floatLegsShadow,
        },
        {
            value: 1,
            label: "UNRAVELLER",
            src: bodyUnravellerImage,
            frontSrc: bodyUnravellerHeadOverImage,
            shadowDetails: floatLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "LICH",
                src: robesLichImage,
            },
            {
                value: 1,
                label: "UNRAVELLER",
                src: robesUnravellerImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FLAVA",
            src: accessoriesFlavaImage,
            isPremium: true,
        },
        {
            value: 1,
            label: "MUDA",
            src: accessoriesMudaFrontImage,
            backSrc: accessoriesMudaBackImage,
            isPremium: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "UNRAVELLER",
            frontSrc: accessoriesUnravellerImage,
        },
        {
            value: 1,
            label: "USHABTI",
            frontSrc: accessoriesGlowUshtabiImage,
        },
        {
            value: 2,
            label: "UNRAVELLER + USHABTI",
            frontSrc: accessoriesUnravellerUshabtiImage,
        },
        {
            value: 3,
            label: "UNRAVELLER + LICH HEAD",
            frontSrc: accessoriesUnravellerLichImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ROBES",
            value: "weapon",
        },
        {
            label: "ACCESSORY",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "ROBES",
        "secondaryWeapon": "ACCESSORY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
