import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyManticoreImage = `${BaseURL}/Mechs/Horus/Manticore/Chassis.png`;

// HEADS
const headManticoreImage = `${BaseURL}/Mechs/Horus/Manticore/heads/Head - Manticore.png`;
const headKazuImage = `${BaseURL}/Mechs/Horus/Manticore/heads/Head - Kazu.png`;
// PREMIUM
const headAngularImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Angular.png`;
const headFlayerImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Flayer.png`;
const headGrinImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Grin.png`;
const headRhinoImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Rhino.png`;
const headSkullImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Skull.png`;
const headHiImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Hi.png`;
const headAspectAnubisImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Aspect of Anubis.png`;
const headAspectRaImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Aspect of Ra.png`;
const headCockpitImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/Horus/Manticore/heads/premium/Head - Scopehound.png`;

// WEAPONS
const weaponsHandsImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Main - Hands.png`;
const weaponsCatalystPistolImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Main - Catalyst Pistol.png`;
const weaponsDualCatalystPistolImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Main - Dual Catalyst Pistols.png`;
const weaponsShockKnifeCatalystPistolImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Main - Shock Knife _ Catalyst Pistol.png`;
const weaponsDualShockKnivesImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Main - Dual Shock Knives.png`;

// SECONDARY WEAPONS
const secondaryWeaponsArmsImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Heavy - Arms.png`;
const secondaryWeaponsAnnihilatorImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Heavy - Annihilator.png`;
const secondaryWeaponsArcProjectorImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Heavy - Arc Projector.png`;
const secondaryWeaponsArcProjectorAnnihilatorImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Heavy - Arc Projector and Annihilator.png`;
const secondaryWeaponsCombatDrillImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Heavy - Combat Drill.png`;
const secondaryWeaponsDualCombatDrillImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Heavy - Dual Combat Drill.png`;
const secondaryWeaponsPlasmaGauntletsImage = `${BaseURL}/Mechs/Horus/Manticore/weapons/Heavy - Plasma Gauntlets.png`;

// REAR MOUNT
const rearMountExplosiveVentsImage = `${BaseURL}/Mechs/Horus/Manticore/rear mount/Rear - Explosive Vents.png`;
const rearMountSpikesImage = `${BaseURL}/Mechs/Horus/Manticore/rear mount/Rear - Spikes.png`;
// LEGENDARY
const rearMountArmorImage = `${BaseURL}/Mechs/Horus/Manticore/rear mount/legendary/Rear - Armor.png`;
const rearMountArmorSpikesImage = `${BaseURL}/Mechs/Horus/Manticore/rear mount/legendary/Rear - Armor and Spikes.png`;

// ACCESSORIES
const accessoryCatalystPistolImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 1 - Catalyst Pistol.png`;
const accessoryDualCatalystPistolImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 1 - Dual Catalyst Pistols.png`;
const accessoryDualShockKnivesImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 1 - Dual Shock Knives.png`;
const accessoryShockKnifeCatalystPistolImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 1 - Shock Knife and Catalyst Pistol.png`;
const accessoryAnnihilatorArcProjectorImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 2 - Annihilator and Arc Projector.png`;
const accessoryAnnihilatorImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 2 - Annihilator.png`;
const accessoryArcProjectorImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 2 - Arc Projector.png`;
const accessoryExplosiveVentsImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 2 - Explosive Vents.png`;
const accessoryPlasmaGauntletsImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 2 - Plasma Gauntlets.png`;
const accessoryArmorImage = `${BaseURL}/Mechs/Horus/Manticore/accessories/Glow 2 - Armor.png`;

const coreImage = `${BaseURL}/Mechs/Horus/Manticore/Manticore (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/Horus/Manticore/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "48px",
    right: "49px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    removeNone: {
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "2",
        "secondaryWeaponValue": "0",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "MANTICORE",
            src: headManticoreImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "FLAYER",
            src: headFlayerImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "GRIN",
            src: headGrinImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "RHINO",
            src: headRhinoImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "SKULL",
            src: headSkullImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "ASPECT OF ANUBIS",
            src: headAspectAnubisImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "ASPECT OF RA",
            src: headAspectRaImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HI",
            src: headHiImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "ANGULAR",
            src: headAngularImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MANTICORE",
            src: bodyManticoreImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HANDS",
                src: weaponsHandsImage,
            },
            {
                value: 1,
                label: "CATALYST PISTOL",
                src: weaponsCatalystPistolImage,
            },
            {
                value: 2,
                label: "DUAL CATALYST PISTOLS",
                src: weaponsDualCatalystPistolImage,
            },
            {
                value: 3,
                label: "DUAL SHOCK KNIVES",
                src: weaponsDualShockKnivesImage,
            },
            {
                value: 4,
                label: "SHOCK KNIFE + CATALYST PISTOL",
                src: weaponsShockKnifeCatalystPistolImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARMS",
            src: secondaryWeaponsArmsImage,
        },
        {
            value: 1,
            label: "ANNIHILATOR",
            src: secondaryWeaponsAnnihilatorImage,
        },
        {
            value: 2,
            label: "ARC PROJECTOR",
            src: secondaryWeaponsArcProjectorImage,
        },
        {
            value: 3,
            label: "COMBAT DRILL",
            src: secondaryWeaponsCombatDrillImage,
        },
        {
            value: 4,
            label: "DUAL COMBAT DRILL",
            src: secondaryWeaponsDualCombatDrillImage,
        },
        {
            value: 5,
            label: "PLASMA GAUNTLETS",
            src: secondaryWeaponsPlasmaGauntletsImage,
        },
        {
            value: 6,
            label: "ARC PROJECTOR + ANNIHILATOR",
            src: secondaryWeaponsArcProjectorAnnihilatorImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "EXPLOSIVE VENTS",
            frontSrc: rearMountExplosiveVentsImage,
        },
        {
            value: 1,
            label: "SPIKES",
            frontSrc: rearMountSpikesImage,
        },
        {
            value: 2,
            label: "ARMOR",
            frontSrc: rearMountArmorImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "ARMOR + SPIKES",
            frontSrc: rearMountArmorSpikesImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CATALYST PISTOL",
            frontSrc: accessoryCatalystPistolImage,
        },
        {
            value: 1,
            label: "DUAL CATALYST PISTOLS",
            frontSrc: accessoryDualCatalystPistolImage,
        },
        {
            value: 2,
            label: "DUAL SHOCK KNIVES",
            frontSrc: accessoryDualShockKnivesImage,
        },
        {
            value: 3,
            label: "SHOCK KNIFE + CATALYST PISTOL",
            frontSrc: accessoryShockKnifeCatalystPistolImage,
        },
        {
            value: 4,
            label: "ANNIHILATOR",
            frontSrc: accessoryAnnihilatorImage,
        },
        {
            value: 5,
            label: "ARC PROJECTOR",
            frontSrc: accessoryArcProjectorImage,
        },
        {
            value: 6,
            label: "ARC PROJECTOR + ANNIHILATOR",
            frontSrc: accessoryAnnihilatorArcProjectorImage,
        },
        {
            value: 7,
            label: "PLASMA GAUNTLETS",
            frontSrc: accessoryPlasmaGauntletsImage,
        },
        {
            value: 8,
            label: "EXPLOSIVE VENTS",
            frontSrc: accessoryExplosiveVentsImage,
        },
        {
            value: 9,
            label: "ARMOR",
            frontSrc: accessoryArmorImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPONS",
            value: "weapon",
        },
        {
            label: "HEAVY WEAPONS",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPONS",
        "secondaryWeapon": "HEAVY WEAPONS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
