import React from "react";
import styled from "styled-components";

const AppMainWrapper = styled.main`
    background: ${props => `linear-gradient(${props.theme.mainBackground} 0%, ${props.theme.mainBackground} 35%, #000 100%)`};
    min-height: 100vh;
    text-align: center;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        max-height: 100vh;
        max-width: 100vw;
    }
    @media print {
        background: #FFF;
    }
`;

AppMainWrapper.displayName = "AppMainWrapper";

function AppMain({ ...props }) {
    return (
        <AppMainWrapper>
            {props.children}
        </AppMainWrapper>
    );
}

AppMain.displayName = "AppMain";

export default AppMain;
