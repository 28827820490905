import React from "react";
import { bool, func, object, number } from "prop-types";

import MechUnitImage, { MechUnitContainer } from "../../styles/MechUnitImage";

import Mechs from "../../data/Mechs-GMS";

function MechCustomCategoryDisplay({ unit, mirroredDisplayFlag, mirroredFunc, zoomedFunc, zoomedDisplayValue, isInCategory }) {
    let className = mirroredDisplayFlag ? "mirrored" : "";
    className += zoomedDisplayValue ? ` zoomed-${zoomedDisplayValue}` : "";

    return (
        <MechUnitContainer
            onClick={() => {
                if (mirroredFunc) {
                    mirroredFunc();
                }

                if (zoomedFunc) {
                    zoomedFunc();
                }
            }}
            isZoomable={!!zoomedFunc}
        >
            {unit.accessory1Value === "NONE" || !Mechs.accessory[unit.accessory1Value].foremostSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory1TintValue}
                    src={Mechs.accessory[unit.accessory1Value].foremostSrc}
                    alt={`accessory-foremost-${unit.accessory1Value}`}
                    zIndex={"110"}
                />
            }
            {unit.accessory2Value === "NONE" || !Mechs.accessory[unit.accessory2Value].foremostSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory2TintValue}
                    src={Mechs.accessory[unit.accessory2Value].foremostSrc}
                    alt={`accessory-foremost-${unit.accessory2Value}`}
                    zIndex={"110"}
                />
            }
            {unit.accessory3Value === "NONE" || !Mechs.accessory[unit.accessory3Value].foremostSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory3TintValue}
                    src={Mechs.accessory[unit.accessory3Value].foremostSrc}
                    alt={`accessory-foremost-${unit.accessory3Value}`}
                    zIndex={"110"}
                />
            }
            {unit.accessory4Value === "NONE" || !Mechs.accessory[unit.accessory4Value].foremostSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory4TintValue}
                    src={Mechs.accessory[unit.accessory4Value].foremostSrc}
                    alt={`accessory-foremost-${unit.accessory4Value}`}
                    zIndex={"110"}
                />
            }
            {unit.weaponValue === "NONE" ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.weaponTintValue}
                    src={Mechs.weapon[unit.stanceValue][unit.weaponValue].src}
                    alt={`weapon-${unit.weaponValue}`}
                    zIndex={"100"}
                />
            }
            {unit.secondaryWeaponValue === "NONE" ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.secondaryWeaponTintValue}
                    src={Mechs.secondaryWeapon[unit.secondaryWeaponValue].src}
                    alt={`secondary-weapon-${unit.secondaryWeaponValue}`}
                    zIndex={"100"}
                />
            }
            {unit.armLeftValue === "NONE" ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.armLeftTintValue}
                    src={Mechs.arms[unit.armLeftValue].src[unit.stanceValue].left}
                    alt={`arms-left-${unit.armLeftValue}`}
                    zIndex={Mechs.arms[unit.armLeftValue].isMounted || Mechs.arms[unit.armLeftValue].isLeftArmBackRow ? "60" : "90"}
                />
            }
            {unit.armRightValue === "NONE" ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.armRightTintValue}
                    src={Mechs.arms[unit.armRightValue].src[unit.stanceValue].right}
                    alt={`arms-right-${unit.armRightValue}`}
                    zIndex={Mechs.arms[unit.armRightValue].isMounted ? "105" : "90"}
                />
            }
            {unit.accessory1Value === "NONE" || !Mechs.accessory[unit.accessory1Value].frontSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory1TintValue}
                    src={Mechs.accessory[unit.accessory1Value].frontSrc}
                    alt={`accessory-front-${unit.accessory1Value}`}
                    zIndex={"100"}
                />
            }
            {unit.accessory2Value === "NONE" || !Mechs.accessory[unit.accessory2Value].frontSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory2TintValue}
                    src={Mechs.accessory[unit.accessory2Value].frontSrc}
                    alt={`accessory-front-${unit.accessory2Value}`}
                    zIndex={"100"}
                />
            }
            {unit.accessory3Value === "NONE" || !Mechs.accessory[unit.accessory3Value].frontSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory3TintValue}
                    src={Mechs.accessory[unit.accessory3Value].frontSrc}
                    alt={`accessory-front-${unit.accessory3Value}`}
                    zIndex={"100"}
                />
            }
            {unit.accessory4Value === "NONE" || !Mechs.accessory[unit.accessory4Value].frontSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory4TintValue}
                    src={Mechs.accessory[unit.accessory4Value].frontSrc}
                    alt={`accessory-front-${unit.accessory4Value}`}
                    zIndex={"100"}
                />
            }
            {unit.headValue === "NONE" ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.headTintValue}
                    src={Mechs.head[unit.headValue].src}
                    alt={`head-${unit.headValue}`}
                    zIndex={"80"}
                />
            }
            {unit.accessory1Value === "NONE" || !Mechs.accessory[unit.accessory1Value].src ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory1TintValue}
                    src={Mechs.accessory[unit.accessory1Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                        Mechs.accessory[unit.accessory1Value].altSrc :
                        Mechs.accessory[unit.accessory1Value].src
                    }
                    alt={`accessory-front-${unit.accessory1Value}`}
                    zIndex={"70"}
                />
            }
            {unit.accessory2Value === "NONE" || !Mechs.accessory[unit.accessory2Value].src ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory2TintValue}
                    src={Mechs.accessory[unit.accessory2Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                        Mechs.accessory[unit.accessory2Value].altSrc :
                        Mechs.accessory[unit.accessory2Value].src
                    }
                    alt={`accessory-front-${unit.accessory2Value}`}
                    zIndex={"70"}
                />
            }
            {unit.accessory3Value === "NONE" || !Mechs.accessory[unit.accessory3Value].src ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory3TintValue}
                    src={Mechs.accessory[unit.accessory3Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                        Mechs.accessory[unit.accessory3Value].altSrc :
                        Mechs.accessory[unit.accessory3Value].src
                    }
                    alt={`accessory-front-${unit.accessory3Value}`}
                    zIndex={"70"}
                />
            }
            {unit.accessory4Value === "NONE" || !Mechs.accessory[unit.accessory4Value].src ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory4TintValue}
                    src={Mechs.accessory[unit.accessory4Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                        Mechs.accessory[unit.accessory4Value].altSrc :
                        Mechs.accessory[unit.accessory4Value].src
                    }
                    alt={`accessory-front-${unit.accessory4Value}`}
                    zIndex={"70"}
                />
            }
            {unit.rearMountValue === "NONE" || !Mechs.rearMount[unit.rearMountValue].frontSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.rearMountTintValue}
                    src={Mechs.rearMount[unit.rearMountValue].frontSrc}
                    alt={`rear-mount-front-${unit.rearMountValue}`}
                    zIndex={"70"}
                />
            }
            {unit.chassisValue === "NONE" ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.chassisTintValue}
                    src={Mechs.chassis[unit.chassisValue].src}
                    alt={`chassis-${unit.chassisValue}`}
                    zIndex={"60"}
                />
            }
            {unit.armLeftValue === "NONE" || !Mechs.arms[unit.armLeftValue].src[unit.stanceValue].skeleton ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.armLeftTintValue}
                    src={Mechs.arms[unit.armLeftValue].src[unit.stanceValue].skeleton}
                    alt={`arms-skeleton-${unit.armLeftValue}`}
                    zIndex={"50"}
                />
            }
            {unit.legsValue === "NONE" ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.legsTintValue}
                    src={Mechs.legs[unit.legsValue].src}
                    alt={`legs-${unit.legsValue}`}
                    zIndex={"40"}
                />
            }
            {unit.rearMountValue === "NONE" || !Mechs.rearMount[unit.rearMountValue].src ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.rearMountTintValue}
                    src={Mechs.rearMount[unit.rearMountValue].src}
                    alt={`rearMount-${unit.rearMountValue}`}
                    zIndex={"35"}
                />
            }
            {unit.rearMountValue === "NONE" || !Mechs.rearMount[unit.rearMountValue].backSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.rearMountTintValue}
                    src={Mechs.rearMount[unit.rearMountValue].backSrc}
                    alt={`rear-mount-back-${unit.rearMountValue}`}
                    zIndex={"33"}
                />
            }
            {unit.weaponValue === "NONE" || !Mechs.weapon[unit.stanceValue][unit.weaponValue].backSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.weaponTintValue}
                    src={Mechs.weapon[unit.stanceValue][unit.weaponValue].backSrc}
                    alt={`weapon-back-${unit.weaponValue}`}
                    zIndex={"30"}
                />
            }
            {unit.secondaryWeaponValue === "NONE" || !Mechs.secondaryWeapon[unit.secondaryWeaponValue].backSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.secondaryWeaponTintValue}
                    src={Mechs.secondaryWeapon[unit.secondaryWeaponValue].backSrc}
                    alt={`secondary-weapon-back-${unit.secondaryWeaponValue}`}
                    zIndex={"30"}
                />
            }
            {unit.accessory1Value === "NONE" || !Mechs.accessory[unit.accessory1Value].backSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory1TintValue}
                    src={Mechs.accessory[unit.accessory1Value].backSrc}
                    alt={`accessory-front-${unit.accessory1Value}`}
                    zIndex={"20"}
                />
            }
            {unit.accessory2Value === "NONE" || !Mechs.accessory[unit.accessory2Value].backSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory2TintValue}
                    src={Mechs.accessory[unit.accessory2Value].backSrc}
                    alt={`accessory-front-${unit.accessory2Value}`}
                    zIndex={"20"}
                />
            }
            {unit.accessory3Value === "NONE" || !Mechs.accessory[unit.accessory3Value].backSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory3TintValue}
                    src={Mechs.accessory[unit.accessory3Value].backSrc}
                    alt={`accessory-front-${unit.accessory3Value}`}
                    zIndex={"20"}
                />
            }
            {unit.accessory4Value === "NONE" || !Mechs.accessory[unit.accessory4Value].backSrc ? null :
                <MechUnitImage
                    className={className}
                    tintValue={unit.accessory4TintValue}
                    src={Mechs.accessory[unit.accessory4Value].backSrc}
                    alt={`accessory-front-${unit.accessory4Value}`}
                    zIndex={"20"}
                />
            }
        </MechUnitContainer>
    );
}

MechCustomCategoryDisplay.propTypes = {
    unit: object.isRequired,
    mirroredDisplayFlag: bool,
    mirroredFunc: func,
    zoomedDisplayValue: number,
    zoomedFunc: func,
    isInCategory: bool,
};

MechCustomCategoryDisplay.defaultProps = {
    mirroredDisplayFlag: false,
    mirroredFunc: () => {},
    zoomedDisplayValue: 0,
    zoomedFunc: () => {},
    isInCategory: false,
};

export default MechCustomCategoryDisplay;
