import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Dropship`;

const dropshipImage = `${imagePath}/Dropship.png`;

const dropshipGlowImage = `${imagePath}/Glow.png`;

const shadowDetails = {
    size: shadowTypes.MEDIUM,
    bottom: "73px",
    right: "39px",
};

export default {
    previewImg: dropshipImage,
    rearMountsAreChassis: true,
    defaultValues: `{
    "chassisValue": "0",
    "headValue": "0",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "LIGHTS",
            src: dropshipGlowImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DROPSHIP",
            src: dropshipImage,
            shadowDetails,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
