import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Toa`;

// BODY
const bodyImage = `${imagePath}/Modular Toa_Chassis.png`;

// HEADS
const headAkakuImage = `${imagePath}/heads/Modular Toa_Head_Akaku.png`;
const headGaahaImage = `${imagePath}/heads/Modular Toa_Head_Gaaha.png`;
const headHauImage = `${imagePath}/heads/Modular Toa_Head_Hau.png`;
const headKakamaImage = `${imagePath}/heads/Modular Toa_Head_Kakama.png`;
const headKauKauImage = `${imagePath}/heads/Modular Toa_Head_KauKau.png`;
const headKranaImage = `${imagePath}/heads/Modular Toa_Head_Krana.png`;
const headMiruImage = `${imagePath}/heads/Modular Toa_Head_Miru.png`;
const headPakariImage = `${imagePath}/heads/Modular Toa_Head_Pakari.png`;
const headPakariLowImage = `${imagePath}/heads/Modular Toa_Head_Pakari Low.png`;
const headVahiImage = `${imagePath}/heads/Modular Toa_Head_Vahki.png`;

// L WEAPON
const leftClimbingHookImage = `${imagePath}/weapons/left/Modular Toa_Right Arm_Climbing Hook.png`;
const leftDiggingClawImage = `${imagePath}/weapons/left/Modular Toa_Right Arm_Digging Claw.png`;
const leftFlameSwordImage = `${imagePath}/weapons/left/Modular Toa_Right Arm_Flame Sword.png`;
const leftIceSwordImage = `${imagePath}/weapons/left/Modular Toa_Right Arm_Ice Sword.png`;
const leftLightningTridentImage = `${imagePath}/weapons/left/Modular Toa_Right Arm_Lightning Trident.png`;
const leftMatahandImage = `${imagePath}/weapons/left/Modular Toa_Right Arm_Mata hand.png`;
const leftWindAxeImage = `${imagePath}/weapons/left/Modular Toa_Right Arm_Wind Axe.png`;

// R WEAPON
const rightClimbingHookImage = `${imagePath}/weapons/right/Modular Toa_Left Arm_Climbing Hook.png`;
const rightDiggingClawImage = `${imagePath}/weapons/right/Modular Toa_Left Arm_Digging Claw.png`;
const rightMataHandImage = `${imagePath}/weapons/right/Modular Toa_Left Arm_Mata hand.png`;
const rightShieldImage = `${imagePath}/weapons/right/Modular Toa_Left Arm_Shield.png`;
const rightFrontShieldImage = `${imagePath}/weapons/right/Modular Toa_Left Arm Front_Shield.png`;

// HEAD GLOW
const headGlowAkakuImage = `${imagePath}/head-glow/Modular Toa_Accessories Front_Head Glow Akaku.png`;
const headGlowHauImage = `${imagePath}/head-glow/Modular Toa_Accessories Front_Head Glow Hau.png`;
const headGlowKakamaImage = `${imagePath}/head-glow/Modular Toa_Accessories Front_Head Glow Kakama.png`;
const headGlowKauKauImage = `${imagePath}/head-glow/Modular Toa_Accessories Front_Head Glow KauKau.png`;
const headGlowMiruImage = `${imagePath}/head-glow/Modular Toa_Accessories Front_Head Glow Miru.png`;
const headGlowPakariImage = `${imagePath}/head-glow/Modular Toa_Accessories Front_Head Glow Pakari.png`;
const headGlowPakariLowImage = `${imagePath}/head-glow/Modular Toa_Accessories Front_Head Glow Pakari Low.png`;

// ACCESSORY
const accessoryBandolier1Image = `${imagePath}/accessories/Modular Toa_Accessories Middle_Bandolier 1.png`;
const accessoryBandolier2Image = `${imagePath}/accessories/Modular Toa_Accessories Middle_Bandolier 2.png`;
const accessoryBootsImage = `${imagePath}/accessories/Modular Toa_Accessories Middle_Boots.png`;
const accessoryBoulderImage = `${imagePath}/accessories/Modular Toa_Accessories Back_Boulder.png`;
const accessoryCloakImage = `${imagePath}/accessories/Modular Toa_Accessories Middle_Cloak.png`;
const accessoryCloakBackImage = `${imagePath}/accessories/Modular Toa_Accessories Back_Cloak.png`;
const accessoryCoatImage = `${imagePath}/accessories/Modular Toa_Accessories Middle_Coat.png`;
const accessoryDripImage = `${imagePath}/accessories/Modular Toa_Accessories Middle_Drip.png`;
const accessoryHeartlightImage = `${imagePath}/accessories/Modular Toa_Accessories Middle_Heartlight.png`;
const accessorySwordFlameImage = `${imagePath}/accessories/Modular Toa_Accessories Front_Sword Flame.png`;
const accessoryTimbsImage = `${imagePath}/accessories/Modular Toa_Accessories Middle_Timbs.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/Modular_Toa_Tahu_Colored.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "29px",
    right: "37px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: contributorList.PINKHYENA,
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "2",
        "rearMountValue": "1",
        "weaponValue": "2",
        "secondaryWeaponValue": "2",
        "accessory1Value": "8",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AKAKU",
            src: headAkakuImage,
        },
        {
            value: 1,
            label: "GAAHA",
            src: headGaahaImage,
        },
        {
            value: 2,
            label: "HAU",
            src: headHauImage,
        },
        {
            value: 3,
            label: "KAKAMA",
            src: headKakamaImage,
        },
        {
            value: 4,
            label: "KAUKAU",
            src: headKauKauImage,
        },
        {
            value: 5,
            label: "KRANA",
            src: headKranaImage,
        },
        {
            value: 6,
            label: "MIRU",
            src: headMiruImage,
        },
        {
            value: 7,
            label: "PAKARI",
            src: headPakariImage,
        },
        {
            value: 8,
            label: "PAKARI - LOW",
            src: headPakariLowImage,
        },
        {
            value: 9,
            label: "VAHI",
            src: headVahiImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TOA",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.PINKHYENA,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLIMBING HOOK",
                backSrc: leftClimbingHookImage,
            },
            {
                value: 1,
                label: "DIGGING CLAW",
                backSrc: leftDiggingClawImage,
            },
            {
                value: 2,
                label: "FLAME SWORD",
                backSrc: leftFlameSwordImage,
            },
            {
                value: 3,
                label: "ICE SWORD",
                backSrc: leftIceSwordImage,
            },
            {
                value: 4,
                label: "LIGHTNING TRIDENT",
                backSrc: leftLightningTridentImage,
            },
            {
                value: 5,
                label: "MATA HAND",
                backSrc: leftMatahandImage,
            },
            {
                value: 6,
                label: "WIND AXE",
                backSrc: leftWindAxeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CLIMBING HOOK",
            src: rightClimbingHookImage,
        },
        {
            value: 1,
            label: "DIGGING CLAW",
            src: rightDiggingClawImage,
        },
        {
            value: 2,
            label: "MATA HAND",
            src: rightMataHandImage,
        },
        {
            value: 3,
            label: "SHIELD",
            src: rightShieldImage,
            frontSrc: rightFrontShieldImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "AKAKU",
            foremostSrc: headGlowAkakuImage,
        },
        {
            value: 1,
            label: "HAU",
            foremostSrc: headGlowHauImage,
        },
        {
            value: 2,
            label: "KAKAMA",
            foremostSrc: headGlowKakamaImage,
        },
        {
            value: 3,
            label: "KAUKAU",
            foremostSrc: headGlowKauKauImage,
        },
        {
            value: 4,
            label: "MIRU",
            foremostSrc: headGlowMiruImage,
        },
        {
            value: 5,
            label: "PAKARI",
            foremostSrc: headGlowPakariImage,
        },
        {
            value: 6,
            label: "PAKARI - LOW",
            foremostSrc: headGlowPakariLowImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "BANDOLIER 1",
            src: accessoryBandolier1Image,
        },
        {
            value: 1,
            label: "BANDOLIER 2",
            src: accessoryBandolier2Image,
        },
        {
            value: 2,
            label: "BOOTS",
            src: accessoryBootsImage,
        },
        {
            value: 3,
            label: "BOULDER",
            src: accessoryBoulderImage,
        },
        {
            value: 4,
            label: "CLOAK",
            src: accessoryCloakImage,
            backSrc: accessoryCloakBackImage,
        },
        {
            value: 5,
            label: "COAT",
            src: accessoryCoatImage,
        },
        {
            value: 6,
            label: "DRIP",
            src: accessoryDripImage,
        },
        {
            value: 7,
            label: "HEARTLIGHT",
            src: accessoryHeartlightImage,
        },
        {
            value: 8,
            label: "SWORD FLAME - GLOW",
            src: accessorySwordFlameImage,
        },
        {
            value: 9,
            label: "TIMBS",
            src: accessoryTimbsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "HEAD GLOW",
            value: "rearMount",
            isGlow: true,
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "HEAD GLOW",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
