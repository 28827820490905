import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOS Rasputin`;

// BODY
const bodyBlankImage = `${imagePath}/Chassis - Blank.png`;
const bodyDarumaImage = `${imagePath}/Chassis - Daruma.png`;
const bodyFabergeImage = `${imagePath}/Chassis - Faberge.png`;
const bodyMatryoshkaImage = `${imagePath}/Chassis - Matryoshka.png`;
const bodyOculusImage = `${imagePath}/Chassis - Oculus.png`;

// LEGS
const legsFeetImage = `${imagePath}/legs/Legs - Feet.png`;
const legsTalonsImage = `${imagePath}/legs/Legs - Talons.png`;
const legsQuadImage = `${imagePath}/legs/Legs - Quad.png`;
const legsAntigravImage = `${imagePath}/legs/Legs - Antigrav.png`;

// WEAPONS
const weaponsAutocannonsImage = `${imagePath}/weapons/Open Weapons - Autocannons.png`;
const weaponsMissilesImage = `${imagePath}/weapons/Open Weapons - Missiles.png`;

// OPENING
const openingBlankImage = `${imagePath}/opening/Open - Blank.png`;
const openingDarumaImage = `${imagePath}/opening/Open - Daruma.png`;
const openingFabergeImage = `${imagePath}/opening/Open - Faberge.png`;
const openingMatryoshkaImage = `${imagePath}/opening/Open - Matryoshka.png`;
const openingOculusImage = `${imagePath}/opening/Open - Oculus.png`;

// GLOWS
const glowAnetnnaeImage = `${imagePath}/glows/Glow - Antennae.png`;
const glowAntigravImage = `${imagePath}/glows/Glow - Antigrav.png`;
const glowFabergeImage = `${imagePath}/glows/Glow - Faberge.png`;
const glowOculusImage = `${imagePath}/glows/Glow - Oculus.png`;

const coreImage = `${imagePath}/Rasputin-core-MBZach.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-2px",
    right: "-9px",
};

const originalArtCredit = {
    prefixText: "Commissioned by Vox ",
    title: "Link in News",
    url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    isMegadeusPlus: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MBZach",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "3",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "FEET",
            src: legsFeetImage,
        },
        {
            value: 1,
            label: "TALONS",
            src: legsTalonsImage,
        },
        {
            value: 2,
            label: "QUAD",
            src: legsQuadImage,
        },
        {
            value: 3,
            label: "ANTIGRAV",
            src: legsAntigravImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BLANK",
            src: bodyBlankImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "DARUMA",
            src: bodyDarumaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 2,
            label: "FABERGE",
            src: bodyFabergeImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 3,
            label: "MATRYOSHKA",
            src: bodyMatryoshkaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 4,
            label: "OCULUS",
            src: bodyOculusImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AUTOCANNONS",
                src: weaponsAutocannonsImage,
            },
            {
                value: 1,
                label: "MISSILES",
                src: weaponsMissilesImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BLANK",
            src: openingBlankImage,
        },
        {
            value: 1,
            label: "DARUMA",
            src: openingDarumaImage,
        },
        {
            value: 2,
            label: "FABERGE",
            src: openingFabergeImage,
        },
        {
            value: 3,
            label: "MATRYOSHKA",
            src: openingMatryoshkaImage,
        },
        {
            value: 4,
            label: "OCULUS",
            src: openingOculusImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ANTENNAE",
            frontSrc: glowAnetnnaeImage,
        },
        {
            value: 1,
            label: "ANTIGRAV",
            frontSrc: glowAntigravImage,
        },
        {
            value: 2,
            label: "FABERGE",
            frontSrc: glowFabergeImage,
        },
        {
            value: 3,
            label: "OCULUS",
            src: glowOculusImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "LEGS",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "OPENINGS",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "LEGS",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "OPENINGS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
