import React from "react";
import { arrayOf, bool, func, object, string } from "prop-types";
import styled from "styled-components";

import {
    SelectDropdown,
    retroTerminalColor,
    defaultColor,
} from "./components/MechCategoryStyles";
import ExportAllNPCButton from "./components/ExportAllNPCButton";
import { premiumTiersList } from "../PremiumPopup";

const FrameListContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: start;
    align-items: space-around;
    flex-wrap: wrap;

    overflow-y: scroll;
    height: 100%;
    width: 100%;
`;

FrameListContainer.displayName = "FrameListContainer";

const FramePreview = styled(SelectDropdown)`
    cursor: pointer;
    height: 254px;
    width: 254px;
    margin-top: 4px;

    label {
        max-width: 70%;
        padding-right: 20px;
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 250px;
        height: 250px;
        z-index: 1;
    }


    border-color: transparent;
    border-style: solid;
    border-width: 2px;

    &.default {
        :hover {
            border-color: ${props => props.disabled ? '#333' : defaultColor};
        }
    }

    &.retro-terminal {
        :hover {
            border-color: ${props => props.disabled ? '#666' : retroTerminalColor};
        }
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 254px;

        label {
            font-size: 0.9em;
        }
    }
`;

FramePreview.displayName = "FramePreview";

function MechBrandedFrameDisplay({
    setShowPremiumPopupFunc,
    setFrameValueFunc,
    partList,
    isPremiumUser,
    isLegendaryUser,
    npcCategory,
    displayTheme,
    isLegendaryCategory,
}) {
    const framesArray = partList.map(frameData => (
        <FramePreview
            key={`${frameData.value}-${frameData.label}`}
            className={displayTheme ? "retro-terminal" : "default"}
            alt="Select Frame Button"
            disabled={frameData.isLegendary && !isLegendaryUser}
            onClick={() => {
                if (frameData.isLegendary && !isLegendaryUser) {
                    setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                } else {
                    setFrameValueFunc(frameData.value);
                }
            }}
        >
            <label>
                {`${frameData.label} ${frameData.isLegendary ? "●●" : ""} ${frameData.coreImg ? "▣" : ""}`}
            </label>
            <img
                alt={frameData.label}
                src={frameData.isLegendary && !isLegendaryUser ? frameData.restrictedPreviewImg : frameData.previewImg}
            />
        </FramePreview>
    ));

    return (
        <FrameListContainer>
            {npcCategory ?
                <ExportAllNPCButton
                    displayTheme={displayTheme}
                    mechType={npcCategory}
                    setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                    isLegendaryCategory={isLegendaryCategory}
                /> : null
            }
            {framesArray}
        </FrameListContainer>
    );
}

MechBrandedFrameDisplay.propTypes = {
    setShowPremiumPopupFunc: func.isRequired,
    setFrameValueFunc: func.isRequired,
    partList: arrayOf(object).isRequired,
    isPremiumUser: bool,
    isLegendaryUser: bool,
    npcCategory: string,
    displayTheme: bool.isRequired,
    isLegendaryCategory: bool,
};

MechBrandedFrameDisplay.defaultProps = {
    isPremiumUser: false,
    isLegendaryUser: false,
    npcCategory: null,
    isLegendaryCategory: false,
};

export default MechBrandedFrameDisplay;
