import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Raleigh-2`;

// BODY
const bodyRaleighImage = `${imagePath}/Chassis.png`;

// HEADS
const headRaleigh2Image = `${imagePath}/heads/Head - Raleigh 2.png`;
// PREMIUM
const headAngleImage = `${imagePath}/heads/premium/Head - Angle.png`;
const headBlackbeardImage = `${imagePath}/heads/premium/Head - Blackbeard.png`;
const headBlackspotImage = `${imagePath}/heads/premium/Head - Blackspot.png`;
const headBriarImage = `${imagePath}/heads/premium/Head - Briar.png`;
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headDungamImage = `${imagePath}/heads/premium/Head - Dungam.png`;
const headFactoryImage = `${imagePath}/heads/premium/Head - Factory Standard.png`;
const headJudicatorImage = `${imagePath}/heads/premium/Head - Judicator.png`;
const headKazuImage = `${imagePath}/heads/premium/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head - Laborer.png`;
const headLudonautImage = `${imagePath}/heads/premium/Head - Ludonaut.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/premium/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/premium/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/premium/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/premium/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/premium/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/premium/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/premium/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/premium/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/premium/Head - Nelson.png`;
const headOgreImage = `${imagePath}/heads/premium/Head - Ogre.png`;
const headPartisanImage = `${imagePath}/heads/premium/Head - Partisan.png`;
const headPredatorImage = `${imagePath}/heads/premium/Head - Predator.png`;
const headRainImage = `${imagePath}/heads/premium/Head - Rain Transformed.png`;
const headRainHairImage = `${imagePath}/heads/premium/Head - Rain Transformed Hair.png`;
const headRaleighImage = `${imagePath}/heads/premium/Head - Raleigh.png`;
const headScarab2Image = `${imagePath}/heads/premium/Head - Scarab 2.png`;
const headScopedogImage = `${imagePath}/heads/premium/Head - Scopedog.png`;
const headStampedeImage = `${imagePath}/heads/premium/Head - Stampede.png`;
const headTaurusImage = `${imagePath}/heads/premium/Head - Taurus.png`;
const headVisionaryImage = `${imagePath}/heads/premium/Head - Visionary.png`;
// LEGENDARY
const headBlackhatImage = `${imagePath}/heads/legendary/Head - Blackhat.png`;


// ACCESSORY
// LEGENDARY
const accessoryGunnerCloakImage = `${imagePath}/accessories/legendary/Accessory - Gunner Cloak Over.png`;
const accessoryGunnerCloakBackImage = `${imagePath}/accessories/legendary/Accessory - Gunner Cloak Under.png`;

// WEAPONS
// UPPER
const rearMountHandCannonsImage = `${imagePath}/rear mount/Upper Weapons - Hand Cannons.png`;
const rearMountKineticHammerImage = `${imagePath}/rear mount/Upper Weapons - Kinetic Hammer.png`;
const rearMountCatalyticHammersImage = `${imagePath}/rear mount/Upper Weapons - Catalytic Hammers.png`;
const rearMountDualMissilesImage = `${imagePath}/rear mount/Upper Weapons - Dual Missiles.png`;
const rearMountQuadMissilesImage = `${imagePath}/rear mount/Upper Weapons - Quad Missiles.png`;

// MID
const midWepHandCannonsImage = `${imagePath}/weapons/mid/Middle Weapons - Hand Cannons.png`;
const midWepCatalyticHammersImage = `${imagePath}/weapons/mid/Middle Weapons - Catalytic Hammers.png`;
const midWepCyclonePulseRifleImage = `${imagePath}/weapons/mid/Middle Weapons - Cyclone Pulse Rifle.png`;
const midWepAMRImage = `${imagePath}/weapons/mid/Middle Weapons - AMR.png`;
const midWepBoltThrowerImage = `${imagePath}/weapons/mid/Middle Weapons - Bolt Thrower.png`;
const midWepSiegeCannonImage = `${imagePath}/weapons/mid/Middle Weapons - Siege Cannon.png`;

// LOWER
const lowerWepHandCannonImage = `${imagePath}/weapons/lower/Lower Weapons - Hand Cannon.png`;
const lowerWepCannibalShotgunImage = `${imagePath}/weapons/lower/Lower Weapons - Cannibal Shotgun.png`;
const lowerWepCyclonePulseRifleImage = `${imagePath}/weapons/lower/Lower Weapons - Cyclone Pulse Rifle.png`;
const lowerWepAMRImage = `${imagePath}/weapons/lower/Lower Weapons - AMR.png`;

const coreImage = `${imagePath}/CharlieMerlynn_Raleigh_2.png`;
const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "27px",
    right: "54px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerlynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "RALEIGH 2.0",
            foremostSrc: headRaleigh2Image,
        },
        {
            value: 1,
            label: "ANGLE",
            foremostSrc: headAngleImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "BLACKBEARD",
            foremostSrc: headBlackbeardImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            foremostSrc: headBlackspotImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "BRIAR",
            foremostSrc: headBriarImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "DUNGAM",
            foremostSrc: headDungamImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "FACTORY STANDARD",
            foremostSrc: headFactoryImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "JUDICATOR",
            foremostSrc: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "KAZU",
            foremostSrc: headKazuImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "LUDONAUT",
            foremostSrc: headLudonautImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
            isPremium: true,
        },
        {
            value: 14,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
            isPremium: true,
        },
        {
            value: 15,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
            isPremium: true,
        },
        {
            value: 16,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
            isPremium: true,
        },
        {
            value: 17,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
            isPremium: true,
        },
        {
            value: 18,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
            isPremium: true,
        },
        {
            value: 19,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
            isPremium: true,
        },
        {
            value: 20,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
            isPremium: true,
        },
        {
            value: 21,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "NELSON",
            foremostSrc: headNelsonImage,
            isPremium: true,
        },
        {
            value: 24,
            label: "OGRE",
            foremostSrc: headOgreImage,
            isPremium: true,
        },
        {
            value: 25,
            label: "PARTISAN",
            foremostSrc: headPartisanImage,
            isPremium: true,
        },
        {
            value: 26,
            label: "PREDATOR",
            foremostSrc: headPredatorImage,
            isPremium: true,
        },
        {
            value: 27,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainImage,
            isPremium: true,
        },
        {
            value: 28,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainHairImage,
            isPremium: true,
        },
        {
            value: 29,
            label: "RALEIGH",
            foremostSrc: headRaleighImage,
            isPremium: true,
        },
        {
            value: 30,
            label: "SCARAB 2",
            foremostSrc: headScarab2Image,
            isPremium: true,
        },
        {
            value: 31,
            label: "SCOPEDOG",
            foremostSrc: headScopedogImage,
            isPremium: true,
        },
        {
            value: 32,
            label: "STAMPEDE",
            foremostSrc: headStampedeImage,
            isPremium: true,
        },
        {
            value: 33,
            label: "TAURUS",
            foremostSrc: headTaurusImage,
            isPremium: true,
        },
        {
            value: 34,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 35,
            label: "BLACKHAT",
            foremostSrc: headBlackhatImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "RALEIGH 2.0",
            src: bodyRaleighImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND CANNONS",
                src: midWepHandCannonsImage,
            },
            {
                value: 1,
                label: "CATALYTIC HAMMERS",
                src: midWepCatalyticHammersImage,
            },
            {
                value: 2,
                label: "CYCLONE PULSE RIFLE",
                src: midWepCyclonePulseRifleImage,
            },
            {
                value: 3,
                label: "AMR",
                src: midWepAMRImage,
            },
            {
                value: 4,
                label: "BOLT THROWER",
                src: midWepBoltThrowerImage,
            },
            {
                value: 5,
                label: "SEIGE CANNON",
                src: midWepSiegeCannonImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND CANNON",
            src: lowerWepHandCannonImage,
        },
        {
            value: 1,
            label: "CANNIBAL SHOTGUN",
            src: lowerWepCannibalShotgunImage,
        },
        {
            value: 2,
            label: "CYCLONE PULSE RIFLE",
            src: lowerWepCyclonePulseRifleImage,
        },
        {
            value: 3,
            label: "AMR",
            src: lowerWepAMRImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "HAND CANNONS",
            src: rearMountHandCannonsImage,
        },
        {
            value: 1,
            label: "KINETIC HAMMER",
            src: rearMountKineticHammerImage,
        },
        {
            value: 2,
            label: "CATALYTIC HAMMERS",
            src: rearMountCatalyticHammersImage,
        },
        {
            value: 3,
            label: "DUAL MISSILES",
            src: rearMountDualMissilesImage,
        },
        {
            value: 4,
            label: "QUAD MISSILES",
            src: rearMountQuadMissilesImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "GUNNER CLOAK",
            frontSrc: accessoryGunnerCloakImage,
            backSrc: accessoryGunnerCloakBackImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPONS TOP",
            value: "rearMount",
        },
        {
            label: "WEAPONS MID",
            value: "weapon",
        },
        {
            label: "WEAPONS LOW",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "WEAPONS TOP",
        "weapon": "WEAPONS MID",
        "secondaryWeapon": "WEAPONS LOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
