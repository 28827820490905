import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyZhengImage = `${BaseURL}/Mechs/IPS/Zheng/Body.png`;
const headCoverImage = `${BaseURL}/Mechs/IPS/Zheng/Head Over.png`;
const bodyZhengReinforcedImage = `${BaseURL}/Mechs/IPS/Zheng/Body - Reinforced Arms and Legs.png`;
const bodyZhengReinforcedLegsImage = `${BaseURL}/Mechs/IPS/Zheng/Body - Reinforced Legs.png`;
const bodyZhengReinforcedArmsImage = `${BaseURL}/Mechs/IPS/Zheng/Body - Reinforced Arms.png`;

// HEADS
const headZhengImage = `${BaseURL}/Mechs/IPS/Zheng/heads/Head - Zheng.png`;
const headKazuImage = `${BaseURL}/Mechs/IPS/Zheng/heads/Head - Kazu.png`;
// PREMIUM
const headAImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head A.png`;
const headBImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head B.png`;
const headCImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head C.png`;
const headDImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head D(no over).png`;
const headEImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head E.png`;
const headFImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head F.png`;
const headGImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head G.png`;
const headHImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head H.png`;
const headIImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head I.png`;
const headJImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head J.png`;
const headKImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head K.png`;
const headLImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head L.png`;
const headMImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head M.png`;
const headNImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head N(no over).png`;
const headOImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head O.png`;
const headPImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head P.png`;
const headQImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head Q.png`;
const headRaleighImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head - Raleigh.png`;
const headCockpitImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/IPS/Zheng/heads/premium/Head - Scopehound.png`;

// 1H LEFT
const weaponDD288LeftImage = `${BaseURL}/Mechs/IPS/Zheng/weapons/left/Weapon - DD288 Left.png`;
const weaponMoltenWreathLeftImage = `${BaseURL}/Mechs/IPS/Zheng/weapons/left/Weapon - Molten Wreath Left.png`;

// 1H RIGHT
const weaponMoltenWreathRightImage = `${BaseURL}/Mechs/IPS/Zheng/weapons/right/Weapon - Molten Wreath Right.png`;

// GLOWS
const glowMoltenWreathRightImage = `${BaseURL}/Mechs/IPS/Zheng/glows/Right Molten Wreath Glow.png`;
const glowMoltenWreathLeftImage = `${BaseURL}/Mechs/IPS/Zheng/glows/Left Molten Wreath Glow.png`;
const glowMoltenWreathDualImage = `${BaseURL}/Mechs/IPS/Zheng/glows/Dual Molten Wreath Glow.png`;
const glowChassisImage = `${BaseURL}/Mechs/IPS/Zheng/glows/Chassis Glow.png`;

const coreImage = `${BaseURL}/Mechs/IPS/Zheng/Zheng (by Corvon).png`;

const previewImage = `${BaseURL}/Mechs/IPS/Zheng/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "63px",
    right: "41px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "3",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ZHENG",
            src: headZhengImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "FACTORY STANDARD",
            src: headAImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "PALADIN",
            src: headBImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "OGRE",
            src: headCImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "ROGUE",
            src: headDImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 6,
            label: "BASTION",
            src: headEImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCARAB",
            src: headGImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "RAMSHACKLE",
            src: headHImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SLIPSTREAM",
            src: headIImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "BUNKER",
            src: headJImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            src: headKImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "TALON",
            src: headLImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "JUDICATOR",
            src: headMImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RUMBLE",
            src: headNImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 15,
            label: "NELSON",
            src: headOImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "TORTUGA",
            src: headPImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "STAMPEDE",
            src: headQImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "RALEIGH",
            src: headRaleighImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 20,
            label: "ICARUS",
            src: headFImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
            removeBodyCover: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DEFAULT",
            src: bodyZhengImage,
            frontSrc: headCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "REINFORCED ARMS",
            src: bodyZhengReinforcedArmsImage,
            frontSrc: headCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "REINFORCED LEGS",
            src: bodyZhengReinforcedLegsImage,
            frontSrc: headCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "FULLY REINFORCED",
            src: bodyZhengReinforcedImage,
            frontSrc: headCoverImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "MOLTEN WREATH",
                src: weaponMoltenWreathLeftImage,
            },
            {
                value: 1,
                label: "DD288",
                src: weaponDD288LeftImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MOLTEN WREATH",
            src: weaponMoltenWreathRightImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            frontSrc: glowChassisImage,
        },
        {
            value: 1,
            label: "MOLTEN WREATH RIGHT",
            frontSrc: glowMoltenWreathRightImage,
        },
        {
            value: 2,
            label: "MOLTEN WREATH LEFT",
            frontSrc: glowMoltenWreathLeftImage,
        },
        {
            value: 3,
            label: "MOLTEN WREATH DUAL",
            frontSrc: glowMoltenWreathDualImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
