import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Heroes/Dragon`;

// BODY
const bodyImage = `${imagePath}/Dragon Body.png`;

// HEADS
const headCerinaImage = `${imagePath}/heads/Head - Cerina.png`;
const headNychtImage = `${imagePath}/heads/Head -  Nycht.png`;
const headMalakeetImage = `${imagePath}/heads/Head - Malakeet.png`;

// WINGS
const wingsCerinaImage = `${imagePath}/wings/Wings - Cerina.png`;
const wingsCerinaUnderImage = `${imagePath}/wings/Wings Under - Cerina.png`;
const wingsNychtImage = `${imagePath}/wings/Wings - Nycht.png`;
const wingsNychtUnderImage = `${imagePath}/wings/Wings Under - Nycht.png`;

// ACCESSORIES
const scalesCerinaImage = `${imagePath}/armor/Scales - Cerina.png`;
const scalesCerinaUnderImage = `${imagePath}/armor/Scales - Cerina under.png`;
const scalesCerinaWingsImage = `${imagePath}/armor/Scales - Cerina Wings.png`;

// GLOWS
const glowBodyImage = `${imagePath}/glows/Body Glow - Torso.png`;
const glowBodyInternalCombustionImage = `${imagePath}/glows/Glow - Body - Internal Combustion.png`;
const glowMalakeetEyeImage = `${imagePath}/glows/Glow - Malakeet Eye.png`;
const glowCerinaWingsOverImage = `${imagePath}/glows/Glow - Cerina Wings Over.png`;
const glowCerinaWingsUnderImage = `${imagePath}/glows/Glow - Cerina Wings Under.png`;
const glowNychtWingsOverImage = `${imagePath}/glows/Glow - Nycht Wings Over.png`;
const glowNychtWingsUnderImage = `${imagePath}/glows/Glow - Nycht Wings Under.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-18px",
    right: "38px",
};

export default {
    isMegadeusPlus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CERINA",
            src: headCerinaImage,
        },
        {
            value: 1,
            label: "NYCHT",
            src: headNychtImage,
        },
        {
            value: 2,
            label: "MALAKEET",
            src: headMalakeetImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DRAGON",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Dragongirls Anonymous",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CERINA",
                src: wingsCerinaImage,
                backSrc: wingsCerinaUnderImage,
            },
            {
                value: 1,
                label: "NYCHT",
                src: wingsNychtImage,
                backSrc: wingsNychtUnderImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SCALES - CERINA",
            src: scalesCerinaImage,
            backSrc: scalesCerinaUnderImage,
        },
        {
            value: 1,
            label: "SCALES - CERINA + WINGS",
            frontSrc: scalesCerinaWingsImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BODY - UNDERBELLY",
            src: glowBodyImage,
        },
        {
            value: 1,
            label: "BODY - INTERNAL COMBUSTION",
            src: glowBodyInternalCombustionImage,
        },
        {
            value: 2,
            label: "EYE - MALAKEET",
            frontSrc: glowMalakeetEyeImage,
        },
        {
            value: 3,
            label: "WINGS - CERINA",
            src: glowCerinaWingsUnderImage,
            frontSrc: glowCerinaWingsOverImage,
        },
        {
            value: 4,
            label: "WINGS - NYCHT",
            src: glowNychtWingsUnderImage,
            frontSrc: glowNychtWingsOverImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "BODY",
            value: "chassis",
        },
        {
            label: "WINGS",
            value: "weapon",
        },
        {
            label: "SCALES",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "BODY",
        "rearMount": "EMPTY",
        "weapon": "WINGS",
        "secondaryWeapon": "SCALES",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
