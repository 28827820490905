export async function drawImages(ctxObject, images) {
    return new Promise((resolve, reject) => {
        const img = images.shift();
        const imgToDraw = new Image();
        imgToDraw.crossOrigin = "anonymous";

        imgToDraw.src = `${img.src}?cacheblock=true`;
        imgToDraw.onload = () => {
            ctxObject.filter = img.filter === "CUSTOM" ? "none" : img.filter;
            ctxObject.drawImage(imgToDraw, 0, 0);

            if (images.length > 0) {
                // resolve with next iteration so we can await all
                resolve(drawImages(ctxObject, images));
            } else {
                console.log("I - Processing");
                // done
                resolve();
            }
        };

        imgToDraw.onerror = reject;
    });
}

export async function drawZipImages({ ctxObject, canvas, zipObject, images, imageSize }) {
    return new Promise((resolve, reject) => {
        const img = images.shift();
        const imgToDraw = new Image();
        let compositeImage;

        imgToDraw.crossOrigin = "anonymous";

        imgToDraw.src = `${img.src}?cacheblock=true`;
        imgToDraw.onload = () => {
            canvas.height = imageSize;
            canvas.width = imageSize;
            ctxObject.filter = img.filter === "CUSTOM" ? "none" : img.filter;
            ctxObject.drawImage(imgToDraw, 0, 0);

            compositeImage = canvas.toDataURL("image/png");

            zipObject.file(img.name, compositeImage.substr(compositeImage.indexOf(',') + 1), { base64: true });

            ctxObject.clearRect(0, 0, imageSize, imageSize);

            if (images.length > 0) {
                // resolve with next iteration so we can await all
                resolve(drawZipImages({ctxObject, canvas, zipObject, images, imageSize}));
            } else {
                console.log("I - Processing");
                // done
                resolve();
            }
        };

        imgToDraw.onerror = reject;
    });
}
