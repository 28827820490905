import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyBlackmarkImage = `${BaseURL}/Mechs/SSC/Metalmark-2/Chassis - Black Mark.png`;

// WEAPON INNER
const handsGunImage = `${BaseURL}/Mechs/SSC/Metalmark-2/Hands - Gun Stance.png`;

const weaponInnerAssaultRifleImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Assault Rifle.png`;
const weaponInnerRailRifleImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Rail Rifle.png`;
const weaponInnerDeckSweeperImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Deck Sweeper.png`;
const weaponInnerCannibalShotgunImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Cannibal Shotgun.png`;
const weaponInnerHMGImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - HMG.png`;

const handsBladeImage = `${BaseURL}/Mechs/SSC/Metalmark-2/Hands - Blade Stance.png`;

const weaponInnerMachinePistolsImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Machine Pistols.png`;
const weaponInnerKnives1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Knives 1.png`;
const weaponInnerKnives2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Knives 2.png`;
const weaponInnerMachetesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Machetes.png`;
const weaponInnerBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Blades.png`;


// WEAPON OUTER
const weaponOuterMachinePistolsImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Machinepistols.png`;
const weaponOuterKnives1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Knives 1.png`;
const weaponOuterKnives2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Knives 2.png`;
const weaponOuterMachetesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Machetes.png`;
const weaponOuterBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Blades.png`;
const weaponOuterHeavyChargedBlade1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Heavy Charged Blade 1.png`;
const weaponOuterHeavyChargedBlade2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Heavy Charged Blade 2 .png`;
const weaponOuterMantisBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Mantis Blades.png`;
const weaponOuterMantisBlades2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Mantis Blades 2.png`;
const weaponOuterHeavyAxeImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Heavy Axe.png`;
const weaponOuterNanocarbonBladeImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Nanocarbon Blade.png`;
const weaponOuterDD288Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - DD288.png`;

// GLOW
const glowBlackmarkImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 1 - Black Mark.png`;
const glowBlackmarkDecloakingImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 1 - Black Mark Decloaking.png`;
const glowRailRifleImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Rail Rifle.png`;
const glowMantisBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Mantis Blades.png`;
const glowMantisBlades2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Mantis Blades 2.png`;
const glowShockKnivesInnerImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Shock Knives Inner.png`;
const glowShockKnivesOuterImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Shock Knives Outer.png`;
const glowShockKnivesAllImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Shock Knives All.png`;
const glowHCB1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - HCB 1.png`;
const glowHCB2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - HCB 2.png`;
const glowDD288Image = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - DD288.png`;

const previewImage = `${BaseURL}/Mechs/SSC/Metalmark-2/blackmark-preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/SSC/Metalmark-2/blackmark-preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "33px",
    right: "16px",
};

export default {
    restrictedPreviewImg: restrictedPreviewImage,
    previewImg: previewImage,
    removeNone: {
        weapon: true,
    },
    weaponSpecial: "chassisValue",
    defaultValues: `{
        "chassisValue": "6",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "6",
        "secondaryWeaponValue": "1",
        "accessory1Value": "0",
        "accessory2Value": "9",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "BLACKMARK AKIMBO",
            src: bodyBlackmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "BLACKMARK AKIMBO",
            src: bodyBlackmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "BLACKMARK AKIMBO",
            src: bodyBlackmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "BLACKMARK AKIMBO",
            src: bodyBlackmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 4,
            label: "BLACKMARK AKIMBO",
            src: bodyBlackmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 5,
            label: "BLACKMARK 2H",
            src: bodyBlackmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 6,
            label: "BLACKMARK 2H",
            src: bodyBlackmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 7,
            label: "BLACKMARK 2H",
            src: bodyBlackmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 8,
            label: "BLACKMARK 2H",
            src: bodyBlackmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 9,
            label: "BLACKMARK 2H",
            src: bodyBlackmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "MACHINE PISTOLS",
                src: weaponInnerMachinePistolsImage,
            },
            {
                value: 1,
                label: "KNIVES 1",
                src: weaponInnerKnives1Image,
            },
            {
                value: 2,
                label: "KNIVES 2",
                src: weaponInnerKnives2Image,
            },
            {
                value: 3,
                label: "MACHETES",
                src: weaponInnerMachetesImage,
            },
            {
                value: 4,
                label: "BLADES",
                src: weaponInnerBladesImage,
            },
            {
                value: 5,
                label: "ASSAULT RIFLE",
                src: weaponInnerAssaultRifleImage,
            },
            {
                value: 6,
                label: "RAIL RIFLE",
                src: weaponInnerRailRifleImage,
            },
            {
                value: 7,
                label: "DECKSWEEPER",
                src: weaponInnerDeckSweeperImage,
            },
            {
                value: 8,
                label: "CANNIBAL SHOTGUN",
                src: weaponInnerCannibalShotgunImage,
            },
            {
                value: 9,
                label: "HMG",
                src: weaponInnerHMGImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MACHINE PISTOLS",
            src: weaponOuterMachinePistolsImage,
        },
        {
            value: 1,
            label: "KNIVES 1",
            src: weaponOuterKnives1Image,
        },
        {
            value: 2,
            label: "KNIVES 2",
            src: weaponOuterKnives2Image,
        },
        {
            value: 3,
            label: "MACHETES",
            src: weaponOuterMachetesImage,
        },
        {
            value: 4,
            label: "BLADES",
            src: weaponOuterBladesImage,
        },
        {
            value: 5,
            label: "HEAVY CHARGED BLADE 1",
            src: weaponOuterHeavyChargedBlade1Image,
        },
        {
            value: 6,
            label: "HEAVY CHARGED BLADE 2",
            src: weaponOuterHeavyChargedBlade2Image,
        },
        {
            value: 7,
            label: "MANTIS BLADES",
            src: weaponOuterMantisBladesImage,
        },
        {
            value: 8,
            label: "MANTIS BLADES 2",
            src: weaponOuterMantisBlades2Image,
        },
        {
            value: 9,
            label: "HEAVY AXE",
            src: weaponOuterHeavyAxeImage,
        },
        {
            value: 10,
            label: "NANOCARBON BLADE",
            src: weaponOuterNanocarbonBladeImage,
        },
        {
            value: 11,
            label: "DD288",
            src: weaponOuterDD288Image,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BLACKMARK CHASSIS",
            src: glowBlackmarkImage,
        },
        {
            value: 1,
            label: "BLACKMARK DECLOAKING",
            src: glowBlackmarkDecloakingImage,
        },
        {
            value: 2,
            label: "MANTIS BLADES",
            frontSrc: glowMantisBladesImage,
        },
        {
            value: 3,
            label: "MANTIS BLADES 2",
            frontSrc: glowMantisBlades2Image,
        },
        {
            value: 4,
            label: "SHOCK KNIVES INNER",
            frontSrc: glowShockKnivesInnerImage,
        },
        {
            value: 5,
            label: "SHOCK KNIVES OUTER",
            frontSrc: glowShockKnivesOuterImage,
        },
        {
            value: 6,
            label: "SHOCK KNIVES ALL",
            frontSrc: glowShockKnivesAllImage,
        },
        {
            value: 7,
            label: "HEAVY CHARGED BLADE 1",
            frontSrc: glowHCB1Image,
        },
        {
            value: 8,
            label: "HEAVY CHARGED BLADE 2",
            frontSrc: glowHCB2Image,
        },
        {
            value: 9,
            label: "RAIL RIFLE",
            frontSrc: glowRailRifleImage,
        },
        {
            value: 10,
            label: "DD288",
            frontSrc: glowDD288Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "INNER WEAPON",
            value: "weapon",
        },
        {
            label: "OUTER WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "INNER WEAPON",
        "secondaryWeapon": "OUTER WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
