import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/HA/Genghis Sherman`;

// BODY
const bodyGenghisImage = `${imagePath}/Genghis Chassis.png`;

// REAR MOUNT
const rearMountFlightUnitImage = `${imagePath}/rear mount/Rear-Flight Unit.png`;

// HEADS
const headGenghisImage = `${imagePath}/heads/Head - Genghis.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
// PREMIUM
const head8ballImage = `${imagePath}/heads/premium/Head - 8ball.png`;
const headAngularImage = `${imagePath}/heads/premium/Head - Angular.png`;
const headBunkerImage = `${imagePath}/heads/premium/Head - Bunker.png`;
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headHammerheadImage = `${imagePath}/heads/premium/Head - Hammerhead.png`;
const headHiImage = `${imagePath}/heads/premium/Head - Hi.png`;
const headPaladinImage = `${imagePath}/heads/premium/Head - Paladin.png`;
const headRumbleImage = `${imagePath}/heads/premium/Head - Rumble.png`;
const headTaurusImage = `${imagePath}/heads/premium/Head - Taurus.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;

// GLOW
// CHASSIS
const glowChassisGenghisImage = `${imagePath}/glow/chassis/Chassis Glow - Genghis head.png`;
const glowChassisGenghisNoHeadImage = `${imagePath}/glow/chassis/Chassis Glow - Genghis no head.png`;
// LEGENDARY
const glowChassisGenghisEnflammeImage = `${imagePath}/glow/chassis/legendary/Glow - Genghis Enflamme Head.png`;
const glowChassisGenghisEnflammeNoHeadImage = `${imagePath}/glow/chassis/legendary/Chassis Glow - Genghis Enflamme nohead.png`;
// WEAPON
const glowWeaponAndromedaImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Andromeda Pattern Laser.png`;
const glowWeaponAndromedaAndromedaImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Andromeda Pattern Laser Andro right.png`;
const glowWeaponAndromedaFlamerImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Andromeda Pattern Laser Flamer Right.png`;
const glowWeaponAndromedaGBEImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Andromeda Pattern Laser GBE right.png`;
const glowWeaponAndromedaSOLImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Andromeda Pattern Laser Sol left.png`;
const glowWeaponAndromedaTachyonImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Andromeda Pattern Laser Tachyon Right.png`;
const glowWeaponSOLImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Sol Pattern Laser.png`;
const glowWeaponSOLAndromedaImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Sol Pattern Laser Andro right.png`;
const glowWeaponSOLFlamerImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Sol Pattern Laser Flamer Right.png`;
const glowWeaponSOLGBEImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Sol Pattern Laser GBE right.png`;
const glowWeaponSOLSOLImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Sol Pattern Laser Sol left.png`;
const glowWeaponSOLTachyonImage = `${imagePath}/glow/weapon/Weapon Glow - 2H - Sol Pattern Laser Tachyon Right.png`;
const glowWeaponKrakatoaImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Krakatoa Flamethrower.png`;
const glowWeaponKrakatoaFlamerImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Krakatoa Flamethrower Flamer Right.png`;
const glowWeaponKrakatoaSOLImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Krakatoa Flamethrower Sol left.png`;
const glowWeaponPlasmaImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Plasma Caster.png`;
const glowWeaponPlasmaFlamerImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Plasma Caster Flamer Right.png`;
const glowWeaponPlasmaSOLImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Plasma Caster Sol Left.png`;
const glowWeaponTachyonImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Tachyon Lance.png`;
const glowWeaponTachyonAndromedaImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Tachyon Lance Andro Right shoulder.png`;
const glowWeaponTachyonFlamerImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Tachyon Lance Flamer Right shoulder.png`;
const glowWeaponTachyonGBEImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Tachyon Lance GBE Right shoulder.png`;
const glowWeaponTachyonSOLImage = `${imagePath}/glow/weapon/Weapon Glow - 2H Tachyon Lance SOL left shoulder.png`;
// SHOULDER
const glowShoulderAndromedaImage = `${imagePath}/glow/shoulder/Weapon Glow - Andromeda Right Shoulder.png`;
const glowShoulderFlamerImage = `${imagePath}/glow/shoulder/Weapon Glow - Flamer Right Shoulder.png`;
const glowShoulderGBEImage = `${imagePath}/glow/shoulder/Weapon Glow - GBE Right Shoulder.png`;
const glowShoulderSolImage = `${imagePath}/glow/shoulder/Weapon Glow - Sol Left Shoulder.png`;
const glowShoulderTachyonImage = `${imagePath}/glow/shoulder/Weapon Glow - Tachyon Lance Right Shoulder.png`;

// PRIMARY WEAPON
const weaponARImage = `${imagePath}/weapons/primary/Weapon - Assault Rifle.png`;
const weaponHMGImage = `${imagePath}/weapons/primary/Weapon - Heavy Machinegun.png`;
const weaponFlamethrowerImage = `${imagePath}/weapons/primary/Weapon - Flamethrower.png`;
const weaponPlasmaThrowerImage = `${imagePath}/weapons/primary/Weapon - Plasma Thrower.png`;
const weaponTachyonLanceImage = `${imagePath}/weapons/primary/Weapon - Tachyon Lance.png`;
const weaponAndromedaLaserImage = `${imagePath}/weapons/primary/Weapon - Andromeda Pattern Laser.png`;
const weaponSOLLaserImage = `${imagePath}/weapons/primary/Weapon - SOL Pattern Laser.png`;
const weaponSiegeCannonImage = `${imagePath}/weapons/primary/sherman ghengis siege cannon.png`;

// SECONDARY WEAPON
const shoulderLeftMissilesImage = `${imagePath}/weapons/secondary/Left Shoulder - Missiles.png`;
const shoulderLeftSOLLaserImage = `${imagePath}/weapons/secondary/Left Shoulder - Sol Pattern Laser.png`;
const shoulderRightAndromedaLaserImage = `${imagePath}/weapons/secondary/Right Shoulder - Andromeda Pattern Laser.png`;
const shoulderRightFlamerImage = `${imagePath}/weapons/secondary/Right Shoulder - Flamer.png`;
const shoulderRightGravitationalBeamEmitterImage = `${imagePath}/weapons/secondary/Right Shoulder - Gravitational Beam Emitter.png`;
const shoulderRightMissilesImage = `${imagePath}/weapons/secondary/Right Shoulder - Missiles.png`;
const shoulderRightTachyonLanceImage = `${imagePath}/weapons/secondary/Right Shoulder - Tachyon Lance.png`;

const coreImage = `${imagePath}/Genghis (by SwankyTown).png`;

const previewImage = `${imagePath}/preview-genghis.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "68px",
    right: "66px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "SwankyTown",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "1",
        "accessory1Value": "13",
        "accessory2Value": "28",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "GENGHIS",
            foremostSrc: headGenghisImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "ANGULAR",
            foremostSrc: headAngularImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "BUNKER",
            foremostSrc: headBunkerImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "HAMMERHEAD",
            foremostSrc: headHammerheadImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "PALADIN",
            foremostSrc: headPaladinImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "RUMBLE",
            foremostSrc: headRumbleImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "TAURUS",
            foremostSrc: headTaurusImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HI",
            foremostSrc: headHiImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "8 BALL",
            foremostSrc: head8ballImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GENGHIS",
            src: bodyGenghisImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE",
                src: weaponARImage,
            },
            {
                value: 1,
                label: "HEAVY MACHINEGUN",
                src: weaponHMGImage,
            },
            {
                value: 2,
                label: "KRAKATOA FLAMETHROWER",
                src: weaponFlamethrowerImage,
            },
            {
                value: 3,
                label: "PLASMA CASTER",
                src: weaponPlasmaThrowerImage,
            },
            {
                value: 4,
                label: "TACHYON LANCE",
                src: weaponTachyonLanceImage,
            },
            {
                value: 5,
                label: "ANDROMEDA PATTERN LASER",
                src: weaponAndromedaLaserImage,
            },
            {
                value: 6,
                label: "SOL PATTERN LASER",
                src: weaponSOLLaserImage,
            },
            {
                value: 7,
                label: "SIEGE CANNON",
                src: weaponSiegeCannonImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "EMPTY / MISSILES",
            src: shoulderRightMissilesImage,
        },
        {
            value: 1,
            label: "EMPTY / FLAMER",
            src: shoulderRightFlamerImage,
        },
        {
            value: 2,
            label: "EMPTY / GRAVITATIONAL BEAM EMITTER",
            src: shoulderRightGravitationalBeamEmitterImage,
        },
        {
            value: 3,
            label: "EMPTY / TACHYON LANCE",
            src: shoulderRightTachyonLanceImage,
        },
        {
            value: 4,
            label: "EMPTY / ANDROMEDA PATTERN LASER",
            src: shoulderRightAndromedaLaserImage,
        },
        {
            value: 5,
            label: "MISSILES / EMPTY",
            foremostSrc: shoulderLeftMissilesImage,
        },
        {
            value: 6,
            label: "SOL LASER / EMPTY",
            foremostSrc: shoulderLeftSOLLaserImage,
        },
        {
            value: 7,
            label: "MISSILES / MISSILES",
            src: shoulderRightMissilesImage,
            foremostSrc: shoulderLeftMissilesImage,
        },
        {
            value: 8,
            label: "MISSILES / FLAMER",
            src: shoulderRightFlamerImage,
            foremostSrc: shoulderLeftMissilesImage,
        },
        {
            value: 9,
            label: "MISSILES / GRAVITATIONAL BEAM EMITTER",
            src: shoulderRightGravitationalBeamEmitterImage,
            foremostSrc: shoulderLeftMissilesImage,
        },
        {
            value: 10,
            label: "MISSILES / TACHYON LANCE",
            src: shoulderRightTachyonLanceImage,
            foremostSrc: shoulderLeftMissilesImage,
        },
        {
            value: 11,
            label: "MISSILES / ANDROMEDA PATTERN LASER",
            src: shoulderRightAndromedaLaserImage,
            foremostSrc: shoulderLeftMissilesImage,
        },
        {
            value: 12,
            label: "SOL LASER / MISSILES",
            src: shoulderRightMissilesImage,
            foremostSrc: shoulderLeftSOLLaserImage,
        },
        {
            value: 13,
            label: "SOL LASER / FLAMER",
            src: shoulderRightFlamerImage,
            foremostSrc: shoulderLeftSOLLaserImage,
        },
        {
            value: 14,
            label: "SOL LASER / GRAVITATIONAL BEAM EMITTER",
            src: shoulderRightGravitationalBeamEmitterImage,
            foremostSrc: shoulderLeftSOLLaserImage,
        },
        {
            value: 15,
            label: "SOL LASER / TACHYON LANCE",
            src: shoulderRightTachyonLanceImage,
            foremostSrc: shoulderLeftSOLLaserImage,
        },
        {
            value: 16,
            label: "SOL LASER / ANDROMEDA PATTERN LASER",
            src: shoulderRightAndromedaLaserImage,
            foremostSrc: shoulderLeftSOLLaserImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "FLIGHT UNIT",
            src: rearMountFlightUnitImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "WEAPON - ANDROMEDA LASER",
            foremostSrc: glowWeaponAndromedaImage,
        },
        {
            value: 1,
            label: "WEAPON - ANDROMEDA LASER X 2",
            foremostSrc: glowWeaponAndromedaAndromedaImage,
        },
        {
            value: 2,
            label: "WEAPON - ANDROMEDA LASER + GBE",
            foremostSrc: glowWeaponAndromedaGBEImage,
        },
        {
            value: 3,
            label: "WEAPON - ANDROMEDA LASER + FLAMER",
            foremostSrc: glowWeaponAndromedaFlamerImage,
        },
        {
            value: 4,
            label: "WEAPON - ANDROMEDA LASER + SOL LASER",
            foremostSrc: glowWeaponAndromedaSOLImage,
        },
        {
            value: 5,
            label: "WEAPON - ANDROMEDA LASER + TACHYON LANCE",
            foremostSrc: glowWeaponAndromedaTachyonImage,
        },
        {
            value: 6,
            label: "WEAPON - SOL LASER",
            foremostSrc: glowWeaponSOLImage,
        },
        {
            value: 7,
            label: "WEAPON - SOL LASER X 2",
            foremostSrc: glowWeaponSOLSOLImage,
        },
        {
            value: 8,
            label: "WEAPON - SOL LASER + GBE",
            foremostSrc: glowWeaponSOLGBEImage,
        },
        {
            value: 9,
            label: "WEAPON - SOL LASER + FLAMER",
            foremostSrc: glowWeaponSOLFlamerImage,
        },
        {
            value: 10,
            label: "WEAPON - SOL LASER + ANDROMEDA LASER",
            foremostSrc: glowWeaponSOLAndromedaImage,
        },
        {
            value: 11,
            label: "WEAPON - SOL LASER + TACHYON LANCE",
            foremostSrc: glowWeaponSOLTachyonImage,
        },
        {
            value: 12,
            label: "WEAPON - KRAKATOA FLAMETHROWER",
            foremostSrc: glowWeaponKrakatoaImage,
        },
        {
            value: 13,
            label: "WEAPON - KRAKATOA FLAMETHROWER + FLAMER",
            foremostSrc: glowWeaponKrakatoaFlamerImage,
        },
        {
            value: 14,
            label: "WEAPON - KRAKATOA FLAMETHROWER + SOL LASER",
            foremostSrc: glowWeaponKrakatoaSOLImage,
        },
        {
            value: 15,
            label: "WEAPON - PLASMA CASTER",
            foremostSrc: glowWeaponPlasmaImage,
        },
        {
            value: 16,
            label: "WEAPON - PLASMA CASTER + FLAMER",
            foremostSrc: glowWeaponPlasmaFlamerImage,
        },
        {
            value: 17,
            label: "WEAPON - PLASMA CASTER + SOL LASER",
            foremostSrc: glowWeaponPlasmaSOLImage,
        },
        {
            value: 18,
            label: "WEAPON - TACHYON LANCE",
            foremostSrc: glowWeaponTachyonImage,
        },
        {
            value: 19,
            label: "WEAPON - TACHYON LANCE + ANDROMEDA LASER",
            foremostSrc: glowWeaponTachyonAndromedaImage,
        },
        {
            value: 20,
            label: "WEAPON - TACHYON LANCE + FLAMER",
            foremostSrc: glowWeaponTachyonFlamerImage,
        },
        {
            value: 21,
            label: "WEAPON - TACHYON LANCE + GBE",
            foremostSrc: glowWeaponTachyonGBEImage,
        },
        {
            value: 22,
            label: "WEAPON - TACHYON LANCE + SOL LASER",
            foremostSrc: glowWeaponTachyonSOLImage,
        },
        {
            value: 23,
            label: "SHOULDER - FLAMER",
            foremostSrc: glowShoulderFlamerImage,
        },
        {
            value: 24,
            label: "SHOULDER - ANDROMEDA LASER",
            foremostSrc: glowShoulderAndromedaImage,
        },
        {
            value: 25,
            label: "SHOULDER - GBE",
            foremostSrc: glowShoulderGBEImage,
        },
        {
            value: 26,
            label: "SHOULDER - SOL LASER",
            foremostSrc: glowShoulderSolImage,
        },
        {
            value: 27,
            label: "SHOULDER - TACHYON LANCE",
            frontSrc: glowShoulderTachyonImage,
        },
        {
            value: 28,
            label: "CHASSIS - GENGHIS",
            src: glowChassisGenghisNoHeadImage,
            foremostSrc: glowChassisGenghisImage,
        },
        {
            value: 29,
            label: "CHASSIS - GENGHIS - HEADLESS",
            src: glowChassisGenghisNoHeadImage,
        },
        {
            value: 30,
            label: "CHASSIS - GENGHIS ENFLAMME",
            src: glowChassisGenghisEnflammeNoHeadImage,
            foremostSrc: glowChassisGenghisEnflammeImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 31,
            label: "CHASSIS - GENGHIS ENFLAMME - HEADLESS",
            src: glowChassisGenghisEnflammeNoHeadImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "SHOULDER",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "SHOULDER",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
