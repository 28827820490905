import BaseURL from "./images/Hosting-BaseURL";
import { removeTintValues } from "./common/settings";
import { shadowTypes } from "../styles/UnitShadow";

// BODY
const bodyMasculineImage = `${BaseURL}/Heroes/Ranger/body/Body M.png`;
const bodyFeminineImage = `${BaseURL}/Heroes/Ranger/body/Body F.png`;
// PREMIUM
const bodyReptileTailImage = `${BaseURL}/Heroes/Ranger/body/premium/Tail - Reptile Tail Under.png`;
const bodyLupineTailImage = `${BaseURL}/Heroes/Ranger/body/premium/Tail - Wolf Tail Under.png`;
const bodyFelineTailImage = `${BaseURL}/Heroes/Ranger/body/premium/Tail - Cat Tail Under.png`;
const bodyElfEarsFrontImage = `${BaseURL}/Heroes/Ranger/body/premium/Helmet - Elf Ears.png`;
const bodyCatEarsFrontImage = `${BaseURL}/Heroes/Ranger/body/premium/Helmet - Cat Ears.png`;
const bodyCatEarsBackImage = `${BaseURL}/Heroes/Ranger/body/premium/Helmet - Cat Ears Under.png`;

// FACE
const faceMasculineImage = `${BaseURL}/Heroes/Ranger/face/Face - M.png`;
const faceFeminineImage = `${BaseURL}/Heroes/Ranger/face/Face - F.png`;
// PREMIUM
const faceLupineImage = `${BaseURL}/Heroes/Ranger/face/Face - Lupine.png`;
const faceReptilekinImage = `${BaseURL}/Heroes/Ranger/face/Face - Lizard.png`;
const faceVulpineImage = `${BaseURL}/Heroes/Ranger/face/Face - Vulpine.png`;
const faceFennecImage = `${BaseURL}/Heroes/Ranger/face/Face - Fennec.png`;
const faceLepusImage = `${BaseURL}/Heroes/Ranger/face/Face - Lepus.png`;


// EYES
const eyesGlowRegularImage = `${BaseURL}/Heroes/Ranger/eyes/Accessories - Eye Glow 2.png`;
const eyesGlowLargeImage = `${BaseURL}/Heroes/Ranger/eyes/Accessories - Eye Glow.png`;
// const eyesFeminineImage = `${BaseURL}/Heroes/Ranger/eyes/Eyes - Feminine.png`;
// PREMIUM
// const eyesLupineImage = `${BaseURL}/Heroes/Ranger/eyes/premium/Eyes - Lupine.png`;
// const eyesReptilekinImage = `${BaseURL}/Heroes/Ranger/eyes/premium/Eyes - Reptilekin.png`;

// HAIR
const hairShortPartedImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Parted.png`;
const hairMediumBackImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Medium Back.png`;
const hairFauxhawkImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Swept.png`;
const hairWildImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Wild.png`;
const hairFlowingLocksImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Flowing Locks.png`;
const hairGuileImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Guile.png`;
const hairBangsPonytailImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Bangs Ponytail.png`;
const hairAfroImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Afro.png`;
const hairTousledImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Short Tousled.png`;
const hairHiroProtagonistImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Hiro Protagonist.png`;
const hairTheMajorImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Bobbed.png`;
const hairTheMajorPonytailImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Bobbed Ponytail.png`;
const hairLongWavyBangsImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Long Wavy bangs.png`;
const hairLongWavyPartedImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Long Wavy parted.png`;
const hairLongElvish1Image = `${BaseURL}/Heroes/Ranger/hair/Hair- Long, Elvish 1.png`;
const hairLongElvish1UnderImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Long, Elvish 1 Under.png`;
const hairLongElvish2Image = `${BaseURL}/Heroes/Ranger/hair/Hair- Long, Elvish 2.png`;
const hairLongElvish2UnderImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Long, Elvish 2 Under.png`;
const hairShoulderElvishImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Elvish Shoulder Length.png`;
const hairShoulderElvishUnderImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Elvish Shoulder Length Under.png`;
const hairBraidElvishImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Long, Elvish Braid.png`;
const hairBraidElvishUnderImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Long, Elvish Braid Under.png`;
const hairFringeHoodOnlyImage = `${BaseURL}/Heroes/Ranger/hair/Hair- Fringe Only (for hood).png`;

// FACIAL HAIR
const facialHairBubImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Bub.png`;
const facialHairBubHandlebarImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Bub Handlebar.png`;
const facialHairHalfMaskImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Half Mask.png`;
const facialHairHandlebarMoustacheImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Handlebar Moustache.png`;
const facialHairHandlebarMoustacheBeardImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Handlebar Moustache Beard.png`;
const facialHairShortBeard1Image = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Short Beard 1.png`;
const facialHairShortBeard2Image = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Short Beard 2.png`;
const facialHairMediumBeard1Image = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Medium Beard.png`;
const facialHairMediumBeard2Image = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Medium Beard 2.png`;
const facialHairLongSideburnMoustacheImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Long Sideburn Moustache.png`;
const facialHairLongGoateeImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Long Goatee.png`;
const facialHairLongBeardImage = `${BaseURL}/Heroes/Ranger/hair/facial/Facial Hair - Long Beard.png`;

// HEAD
const headHunterImage = `${BaseURL}/Heroes/Ranger/head/Helmet - Hunter.png`;
const headFlynn1Image = `${BaseURL}/Heroes/Ranger/head/Helmet - Flynn 1.png`;
const headFlynn2Image = `${BaseURL}/Heroes/Ranger/head/Helmet - Flynn 2.png`;
const headHalfMaskImage = `${BaseURL}/Heroes/Ranger/head/Helmet - Half Mask.png`;
const headArgentKnightImage = `${BaseURL}/Heroes/Ranger/head/Helmet - Argent Knight.png`;
const headWolfKnightImage = `${BaseURL}/Heroes/Ranger/head/Helmet - Wolf Knight.png`;
const headHowdyImage = `${BaseURL}/Heroes/Ranger/head/Helmet - Howdy.png`;
// PREMIUM
const headHorns1Image = `${BaseURL}/Heroes/Ranger/head/Helmet - Horns 1.png`;
const headHorns2Image = `${BaseURL}/Heroes/Ranger/head/Helmet - Horns 2.png`;
const headHorns3Image = `${BaseURL}/Heroes/Ranger/head/Helmet - Horns 3.png`;
const headAntlersImage = `${BaseURL}/Heroes/Ranger/head/Helmet - Antlers.png`;
// LEGENDARY
const headInfernalImage = `${BaseURL}/Heroes/Ranger/head/Helmet - Infernal.png`;

// CHEST
const chestRanger1Image = `${BaseURL}/Heroes/Ranger/chest/Outfit - Ranger 1.png`;
const chestRanger2Image = `${BaseURL}/Heroes/Ranger/chest/Outfit - Ranger 2.png`;
const chestHunterImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Hunter.png`;
const chestElvishImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Elvish.png`;
const chestElvishFImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Elvish Armor F.png`;
const chestTildeedImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Tildeed.png`;
const chestRogueImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Rogue.png`;
const chestKyudoImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Kyudo.png`;
const chestPlatemailImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Platemail.png`;
const chestArgentKnightImage = `${BaseURL}/Heroes/Ranger/chest/Outfit - Argent Knight.png`;

// WEAPONS
const leftWeaponShortBowImage = `${BaseURL}/Heroes/Ranger/weapons/Bow - Shortbow.png`;
const leftWeaponHeavyRecurveBowImage = `${BaseURL}/Heroes/Ranger/weapons/Bow - Heavy Recurve Bow.png`;
const leftWeaponUltraheavyBowImage = `${BaseURL}/Heroes/Ranger/weapons/Bow - Ultraheavy Bow.png`;
const leftWeaponKyudoBowImage = `${BaseURL}/Heroes/Ranger/weapons/Bow - Kyudo.png`;
const leftWeaponDrakesbaneBowImage = `${BaseURL}/Heroes/Ranger/weapons/Bow - Drakesbane.png`;
const leftWeaponCrossbowImage = `${BaseURL}/Heroes/Ranger/weapons/Bow - Crossbow.png`;
const leftWeaponMusketImage = `${BaseURL}/Heroes/Ranger/weapons/Bow - Musket.png`;

// AMMO
const rightWeaponArrowImage = `${BaseURL}/Heroes/Ranger/weapons/Arrow - Arrow.png`;
const rightWeaponHeavyArrowImage = `${BaseURL}/Heroes/Ranger/weapons/Arrow - Heavy Arrow.png`;
const rightWeaponDrakesbaneBoltImage = `${BaseURL}/Heroes/Ranger/weapons/Arrow - Drakesbane Bolt.png`;

// ACCESSORIES
const accessoryRightShoulderQuiverStrapImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Right Shoulder - Quiver Strap.png`;
const accessoryRightShoulderQuiverImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Right Shoulder - Quiver.png`;
const accessoryRightShoulderSwordStrapImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Right Shoulder - Sword Strap.png`;
const accessoryRightShoulderSwordImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Right Shoulder - Sword.png`;

const accessoryWaistQuiverStrapImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Quiver Strap.png`;
const accessoryWaistQuiverImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Quiver.png`;
const accessoryWaistDrakesbaneQuiverStrapImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Drakesbane Quiver Strap.png`;
const accessoryWaistDrakesbaneQuiverImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Drakesbane Quiver.png`;
const accessoryWaistLongsword1StrapImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Longsword 1 Strap.png`;
const accessoryWaistLongsword1Image = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Longsword 1 .png`;
const accessoryWaistLongsword2StrapImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Longsword 2 Strap.png`;
const accessoryWaistLongsword2Image = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Longsword 2.png`;
const accessoryWaistRapierImage = `${BaseURL}/Heroes/Ranger/accessories/Accessories - Waist - Rapier.png`;

// CLOAKS
const cloak1UnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Cloak 1 Under.png`;
const cloak1Image = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Cloak 1.png`;
const cloak2HoodedImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Cloak 2 Hooded.png`;
const cloak2UnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Cloak 2 Under.png`;
const cloak2Image = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Cloak 2.png`;
const cloak1HoodedImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Cloak Hooded.png`;
const cloakFurMantleHoodedImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Fur Mantle Hooded.png`;
const cloakFurMantleUnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Fur Mantle Under.png`;
const cloakFurMantleImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Fur Mantle.png`;
const cloakGhillieHoodedImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Ghillie Cloak Hooded.png`;
const cloakGhillieUnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Ghillie Cloak Under.png`;
const cloakGhillieImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Ghillie Cloak.png`;
const cloakKnightOverImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Knight Cloak Over.png`;
const cloakKnightUnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Knight Cloak Under.png`;
const cloakRaggedMantleUnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Ragged Mantle Under.png`;
const cloakRaggedMantleImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Ragged Mantle.png`;
const cloakScarfWrapImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Scarf Wrap.png`;
const cloakScarf1UnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Scarf 1 Under.png`;
const cloakScarf1Image = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Scarf 1.png`;
const cloakScarf2Image = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Scarf 2.png`;
const cloakScarf3UnderImage = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Scarf 3 Under.png`;
const cloakScarf3Image = `${BaseURL}/Heroes/Ranger/accessories/cloaks/Cloak - Scarf 3.png`;

const previewImage = `${BaseURL}/Heroes/Ranger/preview.png`;

const shadow = {
    size: shadowTypes.SMALL,
    bottom: "100px",
    right: "69px",
};

const defaultSelectionValues = {
    "skinValue": "0",
    "faceValue": "0",
    "eyesValue": "NONE",
    "hairValue": "RANDOM",
    "facialHairValue": "RANDOM",
    "headValue": "2",
    "chestValue": "1",
    "legsValue": "0",
    "leftArmValue": "0",
    "rightArmValue": "NONE",
    "leftWeaponValue": "0",
    "rightWeaponValue": "NONE",
    "accessory1Value": "0",
    "accessory2Value": "17",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const emptySelectionValues = {
    "skinValue": "0",
    "faceValue": "NONE",
    "eyesValue": "NONE",
    "hairValue": "NONE",
    "facialHairValue": "NONE",
    "headValue": "NONE",
    "chestValue": "NONE",
    "legsValue": "NONE",
    "leftArmValue": "NONE",
    "rightArmValue": "NONE",
    "leftWeaponValue": "NONE",
    "rightWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const defaultValues = Object.assign({}, defaultSelectionValues, removeTintValues);

export default {
    previewImg: previewImage,
    isExtraLarge: true,
    defaultValues: JSON.stringify(defaultValues),
    skin: [
        {
            value: 0,
            label: "Masculine",
            src: bodyMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 1,
            label: "Feminine",
            src: bodyFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 2,
            label: "Elven Masculine",
            src: bodyMasculineImage,
            frontSrc: bodyElfEarsFrontImage,
            shadowDetails: shadow,
        },
        {
            value: 3,
            label: "Elven Feminine",
            src: bodyFeminineImage,
            frontSrc: bodyElfEarsFrontImage,
            shadowDetails: shadow,
        },
        {
            value: 4,
            label: "Reptilekin Masculine",
            src: bodyMasculineImage,
            backSrc: bodyReptileTailImage,
            shadowDetails: shadow,
        },
        {
            value: 5,
            label: "Reptilekin Feminine",
            src: bodyFeminineImage,
            backSrc: bodyReptileTailImage,
            shadowDetails: shadow,
        },
        {
            value: 6,
            label: "Lupine Masculine",
            src: bodyMasculineImage,
            backSrc: bodyLupineTailImage,
            shadowDetails: shadow,
        },
        {
            value: 7,
            label: "Lupine Feminine",
            src: bodyFeminineImage,
            backSrc: bodyLupineTailImage,
            shadowDetails: shadow,
        },
        {
            value: 8,
            label: "Feline Masculine",
            src: bodyMasculineImage,
            backSrc: bodyFelineTailImage,
            shadowDetails: shadow,
        },
        {
            value: 9,
            label: "Feline Feminine",
            src: bodyFeminineImage,
            backSrc: bodyFelineTailImage,
            shadowDetails: shadow,
        },
    ],
    face: [
        {
            value: 0,
            label: "Masculine",
            src: faceMasculineImage,
        },
        {
            value: 1,
            label: "Feminine",
            src: faceFeminineImage,
        },
        {
            value: 2,
            label: "Elven Masculine",
            src: faceMasculineImage,
            frontSrc: bodyElfEarsFrontImage,
        },
        {
            value: 3,
            label: "Elven Feminine",
            src: faceFeminineImage,
            frontSrc: bodyElfEarsFrontImage,
        },
        {
            value: 4,
            label: "Reptilekin",
            src: faceReptilekinImage,
        },
        {
            value: 5,
            label: "Lupine",
            src: faceLupineImage,
        },
        {
            value: 6,
            label: "Vulpine",
            src: faceVulpineImage,
        },
        {
            value: 7,
            label: "Fennec",
            src: faceFennecImage,
        },
        {
            value: 8,
            label: "Lepus",
            src: faceLepusImage,
        },
        {
            value: 9,
            label: "Cat Ears Masculine",
            src: faceMasculineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
        },
        {
            value: 10,
            label: "Cat Ears Feminine",
            src: faceFeminineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
        },
    ],
    eyes: [
        {
            value: 0,
            label: "Glow 1",
            src: eyesGlowRegularImage,
        },
        {
            value: 1,
            label: "Glow 2",
            src: eyesGlowLargeImage,
        },
        // {
        //     value: 2,
        //     label: "Lupine",
        //     src: eyesLupineImage,
        //     isPremium: true,
        // },
        // {
        //     value: 3,
        //     label: "Reptilekin",
        //     src: eyesReptilekinImage,
        //     isPremium: true,
        // },
    ],
    hair: [
        {
            value: 0,
            label: "Short Parted",
            src: hairShortPartedImage,
        },
        {
            value: 1,
            label: "Medium Back",
            src: hairMediumBackImage,
        },
        {
            value: 2,
            label: "Fauxhawk",
            src: hairFauxhawkImage,
        },
        {
            value: 3,
            label: "Wild",
            src: hairWildImage,
        },
        {
            value: 4,
            label: "Afro",
            src: hairAfroImage,
        },
        {
            value: 5,
            label: "Flowing Locks",
            src: hairFlowingLocksImage,
        },
        {
            value: 6,
            label: "Bangs + Ponytail",
            src: hairBangsPonytailImage,
        },
        {
            value: 7,
            label: "Long Wavy Bangs",
            src: hairLongWavyBangsImage,
        },
        {
            value: 8,
            label: "Long Wavy Parted",
            src: hairLongWavyPartedImage,
        },
        {
            value: 9,
            label: "Tousled",
            src: hairTousledImage,
        },
        {
            value: 10,
            label: "Guile",
            src: hairGuileImage,
        },
        {
            value: 11,
            label: "The Major",
            src: hairTheMajorImage,
        },
        {
            value: 12,
            label: "The Major + Ponytail",
            src: hairTheMajorPonytailImage,
        },
        {
            value: 13,
            label: "Hiro Protagonist",
            src: hairHiroProtagonistImage,
        },
        {
            value: 14,
            label: "Shoulder Length Elvish",
            src: hairShoulderElvishImage,
            backSrc: hairShoulderElvishUnderImage,
        },
        {
            value: 15,
            label: "Long Elvish 1",
            src: hairLongElvish1Image,
            backSrc: hairLongElvish1UnderImage,
        },
        {
            value: 16,
            label: "Long Elvish 2",
            src: hairLongElvish2Image,
            backSrc: hairLongElvish2UnderImage,
        },
        {
            value: 17,
            label: "Long Elvish Braid",
            src: hairBraidElvishImage,
            backSrc: hairBraidElvishUnderImage,
        },
        {
            value: 18,
            label: "Fringe - Hood Only",
            src: hairFringeHoodOnlyImage,
        },
    ],
    facialHair: [
        {
            value: 0,
            label: "Half Mask",
            src: facialHairHalfMaskImage,
        },
        {
            value: 1,
            label: "Bub",
            src: facialHairBubImage,
        },
        {
            value: 2,
            label: "Handlebar Moustache",
            src: facialHairHandlebarMoustacheImage,
        },
        {
            value: 3,
            label: "Handlebar + Bub",
            src: facialHairBubHandlebarImage,
        },
        {
            value: 4,
            label: "Handlebar + Beard",
            src: facialHairHandlebarMoustacheBeardImage,
        },
        {
            value: 5,
            label: "Short Beard 1",
            src: facialHairShortBeard1Image,
        },
        {
            value: 6,
            label: "Short Beard 2",
            src: facialHairShortBeard2Image,
        },
        {
            value: 7,
            label: "Medium Beard 1",
            src: facialHairMediumBeard1Image,
        },
        {
            value: 8,
            label: "Medium Beard 2",
            src: facialHairMediumBeard2Image,
        },
        {
            value: 9,
            label: "Long Goatee",
            src: facialHairLongGoateeImage,
        },
        {
            value: 10,
            label: "Long Sideburn Moustache",
            src: facialHairLongSideburnMoustacheImage,
        },
        {
            value: 11,
            label: "Long Beard",
            src: facialHairLongBeardImage,
        },
    ],
    head: [
        {
            value: 0,
            label: "Hunter",
            src: headHunterImage,
        },
        {
            value: 1,
            label: "Flynn 1",
            src: headFlynn1Image,
        },
        {
            value: 2,
            label: "Flynn 2",
            src: headFlynn2Image,
        },
        {
            value: 3,
            label: "Half Mask",
            src: headHalfMaskImage,
        },
        {
            value: 4,
            label: "Howdy",
            src: headHowdyImage,
        },
        {
            value: 5,
            label: "Wolf Knight",
            src: headWolfKnightImage,
        },
        {
            value: 6,
            label: "Antlers",
            src: headAntlersImage,
        },
        {
            value: 7,
            label: "Horns 1",
            src: headHorns1Image,
        },
        {
            value: 8,
            label: "Horns 2",
            src: headHorns2Image,
        },
        {
            value: 9,
            label: "Horns 3",
            src: headHorns3Image,
        },
        {
            value: 10,
            label: "Infernal",
            src: headInfernalImage,
        },
        {
            value: 11,
            label: "Argent Knight",
            src: headArgentKnightImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chest: [
        {
            value: 0,
            label: "Strap",
            src: accessoryWaistQuiverStrapImage,
        },
        {
            value: 1,
            label: "Quiver + Strap",
            src: accessoryWaistQuiverStrapImage,
            backSrc: accessoryWaistQuiverImage,
        },
        {
            value: 2,
            label: "Quiver",
            backSrc: accessoryWaistQuiverImage,
        },
        {
            value: 3,
            label: "Drakesbane Quiver + Strap",
            backSrc: accessoryWaistDrakesbaneQuiverImage,
        },
        {
            value: 4,
            label: "Drakesbane Quiver",
            src: accessoryWaistDrakesbaneQuiverStrapImage,
            backSrc: accessoryWaistDrakesbaneQuiverImage,
        },
        {
            value: 5,
            label: "Longsword 1 + Strap",
            src: accessoryWaistLongsword1StrapImage,
            backSrc: accessoryWaistLongsword1Image,
        },
        {
            value: 6,
            label: "Longsword 1",
            backSrc: accessoryWaistLongsword1Image,
        },
        {
            value: 7,
            label: "Longsword 2 + Strap",
            src: accessoryWaistLongsword2StrapImage,
            backSrc: accessoryWaistLongsword2Image,
        },
        {
            value: 8,
            label: "Longsword 2",
            backSrc: accessoryWaistLongsword2Image,
        },
        {
            value: 9,
            label: "Rapier + Strap",
            src: accessoryWaistLongsword2StrapImage,
            backSrc: accessoryWaistRapierImage,
        },
        {
            value: 10,
            label: "Rapier",
            backSrc: accessoryWaistRapierImage,
        },
    ],
    legs: [
        {
            value: 0,
            label: "Ranger 1",
            src: chestRanger1Image,
        },
        {
            value: 1,
            label: "Ranger 2",
            src: chestRanger2Image,
        },
        {
            value: 2,
            label: "Hunter",
            src: chestHunterImage,
        },
        {
            value: 3,
            label: "Rogue",
            src: chestRogueImage,
        },
        {
            value: 4,
            label: "Elvish",
            src: chestElvishImage,
        },
        {
            value: 5,
            label: "Elvish - Feminine",
            src: chestElvishFImage,
        },
        {
            value: 6,
            label: "Platemail",
            src: chestPlatemailImage,
        },
        {
            value: 7,
            label: "Tildeed",
            src: chestTildeedImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "Kyudo",
            src: chestKyudoImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 9,
            label: "Argent Knight",
            src: chestArgentKnightImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    leftArm: [
        {
            value: 0,
            label: "Short Bow",
            src: leftWeaponShortBowImage,
        },
        {
            value: 1,
            label: "Heavy Recurve Bow",
            src: leftWeaponHeavyRecurveBowImage,
        },
        {
            value: 2,
            label: "Crossbow",
            src: leftWeaponCrossbowImage,
        },
        {
            value: 3,
            label: "Musket",
            src: leftWeaponMusketImage,
        },
        {
            value: 4,
            label: "Ultraheavy Bow",
            src: leftWeaponUltraheavyBowImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "Kyudo Bow",
            src: leftWeaponKyudoBowImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "Drakesbane Bow",
            src: leftWeaponDrakesbaneBowImage,
            isPremium: true,
        },
    ],
    rightArm: [],
    leftWeapon: [
        {
            value: 0,
            label: "Arrow",
            frontSrc: rightWeaponArrowImage,
        },
        {
            value: 1,
            label: "Heavy Arrow",
            frontSrc: rightWeaponHeavyArrowImage,
        },
        {
            value: 2,
            label: "Drakesbane Bolt",
            frontSrc: rightWeaponDrakesbaneBoltImage,
        },
    ],
    rightWeapon: [],
    accessory: [
        {
            value: 0,
            label: "Cloak 1",
            src: cloak1Image,
            backSrc: cloak1UnderImage,
        },
        {
            value: 1,
            label: "Hooded Cloak 1",
            frontSrc: cloak1HoodedImage,
            backSrc: cloak1UnderImage,
        },
        {
            value: 2,
            label: "Cloak 2",
            src: cloak2Image,
            backSrc: cloak2UnderImage,
        },
        {
            value: 3,
            label: "Hooded Cloak 2",
            frontSrc: cloak2HoodedImage,
            backSrc: cloak2UnderImage,
        },
        {
            value: 4,
            label: "Fur Mantle",
            src: cloakFurMantleImage,
            backSrc: cloakFurMantleUnderImage,
        },
        {
            value: 5,
            label: "Hooded Fur Mantle",
            frontSrc: cloakFurMantleHoodedImage,
            backSrc: cloakFurMantleUnderImage,
        },
        {
            value: 6,
            label: "Ghillie",
            src: cloakGhillieImage,
            backSrc: cloakGhillieUnderImage,
        },
        {
            value: 7,
            label: "Hooded Ghillie",
            frontSrc: cloakGhillieHoodedImage,
            backSrc: cloakGhillieUnderImage,
        },
        {
            value: 8,
            label: "Knight Cloak",
            src: cloakKnightOverImage,
            backSrc: cloakKnightUnderImage,
        },
        {
            value: 9,
            label: "Ragged Mantle",
            src: cloakRaggedMantleImage,
            backSrc: cloakRaggedMantleUnderImage,
        },
        {
            value: 10,
            label: "Scarf 1",
            frontSrc: cloakScarf1Image,
            backSrc: cloakScarf1UnderImage,
        },
        {
            value: 11,
            label: "Scarf 2",
            frontSrc: cloakScarf2Image,
        },
        {
            value: 12,
            label: "Scarf 3",
            frontSrc: cloakScarf3Image,
            backSrc: cloakScarf3UnderImage,
        },
        {
            value: 13,
            label: "Scarf 4",
            frontSrc: cloakScarfWrapImage,
        },
        {
            value: 14,
            label: "Cross Strap",
            src: accessoryRightShoulderSwordStrapImage,
        },
        {
            value: 15,
            label: "Back Quiver + Strap",
            src: accessoryRightShoulderQuiverStrapImage,
            backSrc: accessoryRightShoulderQuiverImage,
        },
        {
            value: 16,
            label: "Back Quiver",
            backSrc: accessoryRightShoulderQuiverImage,
        },
        {
            value: 17,
            label: "Back Sword + Strap",
            src: accessoryRightShoulderSwordStrapImage,
            backSrc: accessoryRightShoulderSwordImage,
        },
        {
            value: 18,
            label: "Back Sword",
            backSrc: accessoryRightShoulderSwordImage,
        },

    ],
    partList: [
        {
            label: "All",
            value: "ALL",
        },
        {
            label: "Skin Tone",
            value: "BODY",
        },
        {
            label: "All Hair",
            value: "ALLHAIR",
        },
        {
            label: "Accessories",
            value: "ACCESSORIES",
        },
        {
            label: "Body",
            value: "skin",
        },
        {
            label: "Face",
            value: "face",
        },
        {
            label: "Eyes",
            value: "eyes",
            isGlow: true,
        },
        {
            label: "Hair",
            value: "hair",
        },
        {
            label: "Facial Hair",
            value: "facialHair",
        },
        {
            label: "Head",
            value: "head",
        },
        {
            label: "Waist",
            value: "chest",
        },
        {
            label: "Armor",
            value: "legs",
        },
        {
            label: "Weapon",
            value: "leftArm",
        },
        {
            label: "Ammo",
            value: "leftWeapon",
        },
        {
            label: "Accessory 1",
            value: "accessory1",
        },
        {
            label: "Accessory 2",
            value: "accessory2",
        },
        {
            label: "Accessory 3",
            value: "accessory3",
        },
        {
            label: "Accessory 4",
            value: "accessory4",
        },
    ],
    labels: {
        "skin": "Body",
        "face": "Face",
        "eyes": "Eyes",
        "hair": "Hair",
        "facialHair": "Facial Hair",
        "head": "Head",
        "chest": "Waist",
        "legs": "Armor",
        "leftArm": "Weapon",
        "rightArm": "Empty",
        "leftWeapon": "Ammo",
        "rightWeapon": "Empty",
        "accessory1": "Accessory 1",
        "accessory2": "Accessory 2",
        "accessory3": "Accessory 3",
        "accessory4": "Accessory 4",
    },
    templateList: [
        {
            label: "Buff",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
            )),
        },
        {
            label: "Elven F",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "skinValue": "3",
                    "faceValue": "3",
                    "legsValue": "5",
                    "leftArmValue": "1",
                    "leftWeaponValue": "1",
                },
            )),
        },
        {
            label: "Elven M",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                    "skinValue": "2",
                    "faceValue": "2",
                    "legsValue": "4",
                    "leftArmValue": "1",
                    "leftWeaponValue": "1",
                },
            )),
        },
        {
            label: "Wild",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "skinValue": "0",
                    "faceValue": "0",
                    "headValue": "0",
                    "legsValue": "2",
                    "leftArmValue": "0",
                    "leftWeaponValue": "0",
                },
            )),
        },
        {
            label: "Rogue",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "headValue": "3",
                    "chestValue": "9",
                    "legsValue": "3",
                    "leftArmValue": "0",
                    "leftWeaponValue": "0",
                    "accessory1Value": "12",
                },
            )),
        },
        {
            label: "Knight",
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "headValue": "5",
                    "legsValue": "6",
                    "leftArmValue": "2",
                    "accessory1Value": "8",
                    "accessory2Value": "17",
                },
            )),
        },
        {
            label: "Guerilla",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "18",
                    "skinValue": "1",
                    "faceValue": "1",
                    "legsValue": "7",
                    "leftArmValue": "0",
                    "leftWeaponValue": "0",
                    "accessory1Value": "7",
                },
            )),
        },
        {
            label: "Kyudo",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "hairValue": "RANDOM",
                    "skinValue": "1",
                    "faceValue": "1",
                    "chestValue": "2",
                    "legsValue": "8",
                    "leftArmValue": "5",
                    "leftWeaponValue": "0",
                },
            )),
        },
        {
            label: "Argent Knight",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                emptySelectionValues,
                {
                    "headValue": "11",
                    "chestValue": "3",
                    "legsValue": "9",
                    "leftArmValue": "6",
                    "leftWeaponValue": "2",
                },
            )),
        },
    ],
};
