import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowDetailsNone, shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Drones`;

const canineImage = `${imagePath}/Canine Drone.png`;
const flyingImage = `${imagePath}/Flying Drone.png`;
const projectorImage = `${imagePath}/Projector Drone.png`;
const walkerImage = `${imagePath}/Walker Drone.png`;
const walkerPilotImage = `${imagePath}/Walker Drone Pilot.png`;
const subalternPipecleanerImage = `${imagePath}/Subalterns_Pipecleaner.png`;
const subalternLampreyImage = `${imagePath}/Subalterns_Lamprey.png`;

const canineShadowDetails = {
    size: shadowTypes.SMALL,
    bottom: "143px",
    right: "73px",
};

const projectorShadowDetails = {
    size: shadowTypes.SMALL,
    bottom: "129px",
    right: "73px",
};

const walkerShadowDetails = {
    size: shadowTypes.SMALL,
    bottom: "120px",
    right: "73px",
};

const pipecleanerShadowDetails = {
    size: shadowTypes.SMALL,
    bottom: "127px",
    right: "73px",
};

export default {
    previewImg: walkerImage,
    rearMountsAreChassis: true,
    defaultValues: `{
    "chassisValue": "3",
    "headValue": "NONE",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "CANINE",
            src: canineImage,
            shadowDetails: canineShadowDetails,
        },
        {
            value: 1,
            label: "FLYING",
            src: flyingImage,
            shadowDetails: shadowDetailsNone,
        },
        {
            value: 2,
            label: "PROJECTOR",
            src: projectorImage,
            shadowDetails: projectorShadowDetails,
        },
        {
            value: 3,
            label: "WALKER",
            src: walkerImage,
            shadowDetails: walkerShadowDetails,
        },
        {
            value: 4,
            label: "WALKER + PILOT",
            src: walkerPilotImage,
            shadowDetails: walkerShadowDetails,
        },
        {
            value: 5,
            label: "SUBALTERN LAMPREY",
            src: subalternLampreyImage,
            shadowDetails: walkerShadowDetails,
            isPlusTwo: true,
            originalArtCredit: {
                isContributorAsset: true,
                title: "Pinkhyena based on original designs by @LymphOwned",
                url: "https://twitter.com/LymphOwned",
            },
        },
        {
            value: 6,
            label: "SUBALTERN PIPECLEANER",
            src: subalternPipecleanerImage,
            shadowDetails: pipecleanerShadowDetails,
            isPlusTwo: true,
            originalArtCredit: {
                isContributorAsset: true,
                title: "Pinkhyena based on original designs by @LymphOwned",
                url: "https://twitter.com/LymphOwned",
            },
        },

    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "DRONE",
            value: "chassis",
        },
    ],
    labels: {
        "head": "EMPTY",
        "chassis": "DRONE",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
