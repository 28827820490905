import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyNoShouldersFeet1Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - No Shoulders, Feet 1.png`;
const bodyNoShouldersFeet2Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - No Shoulders, Feet 2.png`;
const bodyDockedFeet1Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - Shoulders Docked, Feet 1.png`;
const bodyDockedFeet2Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - Shoulders Docked, Feet 2.png`;
const bodyDeployedFeet1Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - Shoulders Deployed, Feet 1.png`;
const bodyDeployedFeet2Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - Shoulders Deployed, Feet 2.png`;
const bodyHorusFeet1Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - Horus Shoulders Deployed, Feet 1.png`;
const bodyHorusFeet2Image = `${BaseURL}/Mechs/Custom/Zahhak/chassis/Chassis - Horus Shoulders Deployed, Feet 2.png`;

// HEADS
const headZahhakDockedImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Zahhak Docked.png`;
const headZahhakDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Zahhak Deployed.png`;
const head8BallImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - 8 Ball.png`;
const headBlackspotImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Blackspot.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Caliban.png`;
const headCheddahImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Cheddah.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Chimera.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Cyclops.png`;
const headDungamImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Dungam.png`;
const headEnkiduImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Enkidu.png`;
const headFTNelsonImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Flight Type Nelson.png`;
const headHiImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Hi.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Horned Hair.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Malice.png`;
const headMecha1Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 1.png`;
const headMecha2Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 2.png`;
const headMecha3Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 3.png`;
const headMecha4Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 4.png`;
const headMecha5Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 5.png`;
const headMecha6Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 6.png`;
const headMecha7Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 7.png`;
const headMecha8Image = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Mecha 8.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Nelson.png`;
const headNemesisImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Nemesis.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Rain.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Rain Hair.png`;
const headSkullImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Skull.png`;
const headTalonImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Talon.png`;
const headTokugawaImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Tokugawa.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - Visionary.png`;
const headWhiteWitchImage = `${BaseURL}/Mechs/Custom/Zahhak/heads/Heads - White Witch.png`;

// WEAPONS
// INNER
const innerWeaponDockedImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/inner/Inner Arms - Docked.png`;
const innerWeaponDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/inner/Inner Arms - Deployed.png`;
const innerWeaponCarvingKnivesImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/inner/Inner Arms - Carving Knives.png`;
const innerWeaponCleaversImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/inner/Inner Arms - Cleavers.png`;
const innerWeaponCombatBladesImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/inner/Inner Arms - Combat Blades.png`;
const innerWeaponSMGImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/inner/Inner Arms - SMG.png`;
const innerWeaponAssaultRifleImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/inner/Inner Arms - Assault Rifle.png`;

// OUTER
const outerWeaponDockedImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/outer/Outer Arms - Docked.png`;
const outerWeaponDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/outer/Outer Arms - Deployed.png`;
const outerWeaponBladesCablesImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/outer/Outer Arms - Blades.png`;
const outerWeaponBladesImage = `${BaseURL}/Mechs/Custom/Zahhak/weapons/outer/Outer Arms - Blades no cables.png`;

// ACCESSORIES
const accessoryDronesDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Top - Drones Deployed.png`;
const accessoryDronesDockedImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Top - Drones Docked.png`;
const accessoryTorsoPlatingImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Torso - Plating.png`;
const accessoryTorsoDronesImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Torso - Drones.png`;
const accessoryWhipsImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Accessory - Whips Out Arms Docked.png`;
const accessoryWhipsUnderImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Accessory - Whips Under Out Arms Docked.png`;
const accessoryWhipsDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Accessory - Whips Out Arms Deployed.png`;
const accessoryWhipsDeployedUnderImage = `${BaseURL}/Mechs/Custom/Zahhak/accessory/Accessory - Whips Out Arms Deployed - Under.png`;

// GLOWS
const glowZahhakHeadDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Zahhak Head Deployed.png`;
const glowZahhakHeadDockedImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Zahhak Head Docked.png`;
const glowInnerArmCombatBladesImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Inner Arm Combat Blades.png`;
const glowInnerArmsDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Inner Arms Deployed.png`;
const glowOuterArmsBladesImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Outer Arm Blades.png`;
const glowOuterArmsBladesNoCablesImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Out Arm Blades Arcs no cable.png`;
const glowOuterArmsBladesArcsImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Outer Arm Blades + Arcs.png`;
const glowAllDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - All Deployed.png`;
const glowAllDeployedArcsImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - All Deployed + Arcs.png`;
const glowAllDeployedNoInnerImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - All Deployed No Inner Arms.png`;
const glowAllDeployedNoHeadImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - All Deployed No Head.png`;
const glowDronesImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Drones.png`;
const glowHiveImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Torso - Hive Glow.png`;
const glowHiveSpikesImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Torso - Hive Glow + Spikes.png`;
const glowShoulderDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Shoulders Deployed.png`;
const glowNanitesImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Nanites.png`;
const glowNanitesUnderImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Nanites Under.png`;
const glowWhipsImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Whips.png`;
const glowWhipsUnderImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Whips Under.png`;
const glowWhipsDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Whips Deployed.png`;
const glowWhipsDeployedUnderImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Whips Deployed Under.png`;
const glowTopDronesDeployedImage = `${BaseURL}/Mechs/Custom/Zahhak/glows/Glow - Top Drones Deployed.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Zahhak/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Zahhak/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "27px",
    right: "45px",
};

const originalArtCredit = {
    prefixText: "Commissioned by ",
    title: "Veritas",
    url: "https://discord.gg/JUF47G5",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "2",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "10",
        "accessory2Value": "NONE",
        "accessory3Value": "0",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ZAHHAK DOCKED",
            src: headZahhakDockedImage,
        },
        {
            value: 1,
            label: "ZAHHAK DEPLOYED",
            src: headZahhakDeployedImage,
        },
        {
            value: 2,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 10,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 11,
            label: "HI",
            src: headHiImage,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 15,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 16,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 17,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 18,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 19,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 20,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 21,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 22,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 23,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 24,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 25,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 26,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 27,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 28,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 29,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 30,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 31,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 32,
            label: "TALON",
            src: headTalonImage,
        },
        {
            value: 33,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 34,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 35,
            label: "WHITE WITCH",
            src: headWhiteWitchImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "NO SHOULDERS + FEET CLAWS",
            src: bodyNoShouldersFeet1Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "NO SHOULDERS + FEET RETRACTABLE",
            src: bodyNoShouldersFeet2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 2,
            label: "DOCKED SHOULDERS + FEET CLAWS",
            src: bodyDockedFeet1Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 3,
            label: "DOCKED SHOULDERS + FEET RETRACTABLE",
            src: bodyDockedFeet2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 4,
            label: "DEPLOYED SHOULDERS + FEET CLAWS",
            src: bodyDeployedFeet1Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 5,
            label: "DEPLOYED SHOULDERS + FEET RETRACTABLE",
            src: bodyDeployedFeet2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 6,
            label: "HORUS DEPLOYED SHOULDERS + FEET CLAWS",
            src: bodyHorusFeet1Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 7,
            label: "HORUS DEPLOYED SHOULDERS + FEET RETRACTABLE",
            src: bodyHorusFeet2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "DOCKED",
                src: innerWeaponDockedImage,
            },
            {
                value: 1,
                label: "DEPLOYED",
                src: innerWeaponDeployedImage,
            },
            {
                value: 2,
                label: "CARVING KNIVES",
                foremostSrc: innerWeaponCarvingKnivesImage,
            },
            {
                value: 3,
                label: "CLEAVERS",
                foremostSrc: innerWeaponCleaversImage,
            },
            {
                value: 4,
                label: "COMBAT BLADES",
                foremostSrc: innerWeaponCombatBladesImage,
            },
            {
                value: 5,
                label: "SMG",
                foremostSrc: innerWeaponSMGImage,
            },
            {
                value: 6,
                label: "ASSAULT RIFLE",
                foremostSrc: innerWeaponAssaultRifleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DOCKED",
            backSrc: outerWeaponDockedImage,
        },
        {
            value: 1,
            label: "DEPLOYED",
            backSrc: outerWeaponDeployedImage,
        },
        {
            value: 2,
            label: "BLADES",
            backSrc: outerWeaponBladesImage,
        },
        {
            value: 3,
            label: "BLADES + CABLES",
            backSrc: outerWeaponBladesCablesImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "DRONES - CLOSED",
            src: accessoryTorsoPlatingImage,
        },
        {
            value: 1,
            label: "DRONES - DEPLOYED",
            src: accessoryTorsoDronesImage,
        },
        {
            value: 2,
            label: "DRONES VENT - DOCKED",
            frontSrc: accessoryDronesDockedImage,
        },
        {
            value: 3,
            label: "DRONES VENT - DEPLOYED",
            frontSrc: accessoryDronesDeployedImage,
        },
        {
            value: 4,
            label: "DRONES ALL - DOCKED",
            frontSrc: accessoryDronesDockedImage,
            src: accessoryTorsoPlatingImage,
        },
        {
            value: 5,
            label: "DRONES ALL - DEPLOYED",
            frontSrc: accessoryDronesDeployedImage,
            src: accessoryTorsoDronesImage,
        },
        {
            value: 6,
            label: "WHIPS - OUTER ARMS DOCKED",
            src: accessoryWhipsImage,
            backSrc: accessoryWhipsUnderImage,
        },
        {
            value: 7,
            label: "WHIPS - OUTER ARMS DEPLOYED",
            src: accessoryWhipsDeployedImage,
            backSrc: accessoryWhipsDeployedUnderImage,
        },
        {
            value: 8,
            label: "GLOW - WHIPS DOCKED",
            frontSrc: glowWhipsImage,
            backSrc: glowWhipsUnderImage,
        },
        {
            value: 9,
            label: "GLOW - WHIPS DEPLOYED",
            frontSrc: glowWhipsDeployedImage,
            backSrc: glowWhipsDeployedUnderImage,
        },
        {
            value: 10,
            label: "GLOW - ZAHHAK HEAD DOCKED",
            frontSrc: glowZahhakHeadDockedImage,
        },
        {
            value: 11,
            label: "GLOW - ZAHHAK HEAD DEPLOYED",
            frontSrc: glowZahhakHeadDeployedImage,
        },
        {
            value: 12,
            label: "GLOW - SHOULDER DEPLOYED",
            frontSrc: glowShoulderDeployedImage,
        },
        {
            value: 13,
            label: "GLOW - INNER ARMS DEPLOYED",
            frontSrc: glowInnerArmsDeployedImage,
        },
        {
            value: 14,
            label: "GLOW - INNER ARMS COMBAT BLADES",
            foremostSrc: glowInnerArmCombatBladesImage,
        },
        {
            value: 15,
            label: "GLOW - OUTER ARMS BLADES",
            frontSrc: glowOuterArmsBladesImage,
        },
        {
            value: 16,
            label: "GLOW - OUTER ARMS BLADES + ARCS",
            frontSrc: glowOuterArmsBladesArcsImage,
        },
        {
            value: 17,
            label: "GLOW - OUTER ARMS BLADES NO CABLES",
            frontSrc: glowOuterArmsBladesNoCablesImage,
        },
        {
            value: 18,
            label: "GLOW - ALL DEPLOYED",
            frontSrc: glowAllDeployedImage,
        },
        {
            value: 19,
            label: "GLOW - ALL DEPLOYED + ARCS",
            frontSrc: glowAllDeployedArcsImage,
        },
        {
            value: 20,
            label: "GLOW - ALL DEPLOYED - NO INNER ARMS",
            frontSrc: glowAllDeployedNoInnerImage,
        },
        {
            value: 21,
            label: "GLOW - ALL DEPLOYED - NO HEAD",
            frontSrc: glowAllDeployedNoHeadImage,
        },
        {
            value: 22,
            label: "GLOW - DRONES",
            frontSrc: glowDronesImage,
        },
        {
            value: 23,
            label: "GLOW - HIVE",
            frontSrc: glowHiveImage,
        },
        {
            value: 24,
            label: "GLOW - HIVE + SPIKES",
            frontSrc: glowHiveSpikesImage,
        },
        {
            value: 25,
            label: "GLOW - DRONES VENT DEPLOYED",
            frontSrc: glowTopDronesDeployedImage,
        },
        {
            value: 26,
            label: "GLOW - NANITES",
            frontSrc: glowNanitesImage,
            backSrc: glowNanitesUnderImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "ARMS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "INNER ARMS",
            value: "weapon",
        },
        {
            label: "OUTER ARMS",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "SHOULDERS",
        "weapon": "INNER ARMS",
        "secondaryWeapon": "OUTER ARMS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
