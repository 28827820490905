import styled from "styled-components";

const Unit = styled.div`
    align-self: auto;
    align-items: center;
    align-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.isExtraSmall ? "column" : "unset"};
    position: relative;
    min-height: ${props => props.isExtraLarge ? 300 : 200}px;
    min-width: ${props => props.isExtraLarge ? 300 : 200}px;
    max-width: ${props => props.isExtraLarge ? props.isMegadeusPlus || props.isMegadeusPlusPlus || props.isMegadeusOmega ? 360 : 320 : 200}px;

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        margin-top: 15px;
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        min-width: ${props => props.isExtraLarge ? 300 : 200}px;
        margin-top: 30px;
    }
`;

Unit.displayName = "Unit";

export default Unit;
