import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Simurgh`

// BODY
const bodySimurghImage = `${imagePath}/Chassis.png`;

// HEADS
const headSimurghImage = `${imagePath}/heads/Simurgh_Head_Simurgh.png`;
const headAspectOfAnubisImage = `${imagePath}/heads/Simurgh_Head_Aspect of Anubis.png`;
const headAspectOfRaImage = `${imagePath}/heads/Simurgh_Head_Aspect of Ra.png`;
const headCalibanImage = `${imagePath}/heads/Simurgh_Head_Caliban.png`;
const headChimeraImage = `${imagePath}/heads/Simurgh_Head_Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Simurgh_Head_Cyclops.png`;
const headFlayerImage = `${imagePath}/heads/Simurgh_Head_Flayer.png`;
const headHornedImage = `${imagePath}/heads/Simurgh_Head_Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Simurgh_Head_Horned Hair.png`;
const headIronSkullImage = `${imagePath}/heads/Simurgh_Head_Iron Skull.png`;
const headKazuImage = `${imagePath}/heads/Simurgh_Head_Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Simurgh_Head_Laborer.png`;
const headLichImage = `${imagePath}/heads/Simurgh_Head_Lich.png`;
const headMaliceImage = `${imagePath}/heads/Simurgh_Head_Malice.png`;
const headNaturalLawImage = `${imagePath}/heads/Simurgh_Head_Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Simurgh_Head_Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Simurgh_Head_Nelson.png`;
const headProtectorImage = `${imagePath}/heads/Simurgh_Head_Protector.png`;
const headRhinoImage = `${imagePath}/heads/Simurgh_Head_Rhino.png`;
const headScarabImage = `${imagePath}/heads/Simurgh_Head_Scarab.png`;
const headScopeDogImage = `${imagePath}/heads/Simurgh_Head_Scope Dog.png`;
const headUshabtiImage = `${imagePath}/heads/Simurgh_Head_Ushabti.png`;
const headWhiteWitchImage = `${imagePath}/heads/Simurgh_Head_White Witch.png`;

// WEAPONS
// LEFT
const weaponsLeftInductionNexusImage = `${imagePath}/weapons/Simurgh_Weapons Left_ Induction Nexus.png`;
const weaponsLeftSwordOfLahatDormantImage = `${imagePath}/weapons/Simurgh_Weapons Left_ Sword of Lahat Dormant.png`;
const weaponsLeftSwordOfLahatActiveImage = `${imagePath}/weapons/Simurgh_Weapons Left_ Sword of Lahat Active.png`;

// RIGHT
const weaponsRightInductionNexusImage = `${imagePath}/weapons/Simurgh_Weapons Right_ Induction Nexus.png`;
const weaponsRightSwordOfLahatDormantImage = `${imagePath}/weapons/Simurgh_Weapons Right_ Sword of Lahat Dormant.png`;
const weaponsRightSwordOfLahatActiveImage = `${imagePath}/weapons/Simurgh_Weapons Right_ Sword of Lahat Active.png`;

// REAR MOUNT
const rearMountWingsInBackImage = `${imagePath}/rear mount/Simurgh_Wings In_Bottom.png`;
const rearMountWingsOutBackImage = `${imagePath}/rear mount/Simurgh_Wings Out_Bottom.png`;
const rearMountWingsOutFrontImage = `${imagePath}/rear mount/Simurgh_Wings Out_Top.png`;

// GLOWS
const glowSwordOfLahatFrontImage = `${imagePath}/glows/Simurgh_Accesories_Sword of Lahat Active_Top.png`;
const glowSwordOfLahatImage = `${imagePath}/glows/Simurgh_Accesories_ Sword of Lahat Active_Middle.png`;
const glowInductionNexusFrontImage = `${imagePath}/glows/Simurgh_Accesories_Induction Nexus_Top.png`;
const glowInductionNexusImage = `${imagePath}/glows/Simurgh_Accesories_ Induction Nexus_Middle.png`;
const glowMesmerizingPlumageInImage = `${imagePath}/glows/Simurgh_Accesories_Mesmerizing Plumage in_Bottom.png`;
const glowMesmerizingPlumageOutImage = `${imagePath}/glows/Simurgh_Accesories_Mesmerizing Plumage Out_Upper Middle.png`;
const glowMesmerizingPlumageOutBackImage = `${imagePath}/glows/Simurgh_Accesories_Mesmerizing Plumage out_Bottom.png`;

// LEGS
const legsSmallImage = `${imagePath}/legs/Simurgh_Legs_Small.png`;
const legsLargeImage = `${imagePath}/legs/Simurgh_Legs_Large.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "20px",
    right: "54px",
};

export default {
    previewImg: previewImage,
    removeNone: {
        rearMount: true,
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "0",
        "accessory1Value": "2",
        "accessory2Value": "0",
        "accessory3Value": "6",
        "accessory4Value": "5",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SIMURGH",
            foremostSrc: headSimurghImage,
        },
        {
            value: 1,
            label: "ASPECT OF ANUBIS",
            src: headAspectOfAnubisImage,
        },
        {
            value: 2,
            label: "ASPECT OF RA",
            src: headAspectOfRaImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 4,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 6,
            label: "FLAYER",
            src: headFlayerImage,
        },
        {
            value: 7,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 8,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 9,
            label: "IRON SKULL",
            src: headIronSkullImage,
        },
        {
            value: 10,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 11,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 12,
            label: "LICH",
            src: headLichImage,
        },
        {
            value: 13,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 14,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 15,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 16,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 17,
            label: "PROTECTOR",
            src: headProtectorImage,
        },
        {
            value: 18,
            label: "RHINO",
            src: headRhinoImage,
        },
        {
            value: 19,
            label: "SCARAB",
            src: headScarabImage,
        },
        {
            value: 20,
            label: "SCOPEDOG",
            src: headScopeDogImage,
        },
        {
            value: 21,
            label: "USHABTI",
            src: headUshabtiImage,
        },
        {
            value: 22,
            label: "WHITE WITCH",
            src: headWhiteWitchImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SIMURGH",
            src: bodySimurghImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Commissioned by `,
                title: "Tau",
                url: "https://t.co/VuaRv2OhVB",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "INDUCTION NEXUS",
                src: weaponsLeftInductionNexusImage,
            },
            {
                value: 1,
                label: "SWORD OF LAHAT - DORMANT",
                src: weaponsLeftSwordOfLahatDormantImage,
            },
            {
                value: 2,
                label: "SWORD OF LAHAT - ACTIVE",
                src: weaponsLeftSwordOfLahatActiveImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "INDUCTION NEXUS",
            frontSrc: weaponsRightInductionNexusImage,
        },
        {
            value: 1,
            label: "SWORD OF LAHAT - DORMANT",
            frontSrc: weaponsRightSwordOfLahatDormantImage,
        },
        {
            value: 2,
            label: "SWORD OF LAHAT - ACTIVE",
            frontSrc: weaponsRightSwordOfLahatActiveImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SMALL",
            frontSrc: legsSmallImage,
        },
        {
            value: 1,
            label: "LARGE",
            frontSrc: legsLargeImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "WINGS - IN",
            backSrc: rearMountWingsOutBackImage,
        },
        {
            value: 1,
            label: "WINGS - OUT",
            src: rearMountWingsOutFrontImage,
            backSrc: rearMountWingsInBackImage,
        },
        {
            value: 2,
            label: "MESMERIZING PLUMAGE - IN - GLOW",
            backSrc: glowMesmerizingPlumageOutBackImage,
        },
        {
            value: 3,
            label: "MESMERIZING PLUMAGE - OUT - GLOW",
            src: glowMesmerizingPlumageOutImage,
            backSrc: glowMesmerizingPlumageInImage,
        },
        {
            value: 4,
            label: "INDUCTION NEXUS LEFT - GLOW",
            frontSrc: glowInductionNexusImage,
        },
        {
            value: 5,
            label: "INDUCTION NEXUS RIGHT - GLOW",
            frontSrc: glowInductionNexusFrontImage,
        },
        {
            value: 6,
            label: "SWORD OF LAHAT LEFT - GLOW",
            frontSrc: glowSwordOfLahatImage,
        },
        {
            value: 7,
            label: "SWORD OF LAHAT RIGHT - GLOW",
            frontSrc: glowSwordOfLahatFrontImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "LEGS",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "LEGS",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
