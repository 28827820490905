import React, {useState} from "react";
import styled from "styled-components";
import SpriteImage, { SPRITE_KEYS } from "../../../common/styles/SpriteImage";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.width};
    height: ${props => props.height};
`;

const BlurredCoverDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(6px);
`;

BlurredCoverDiv.displayName = "BlurredCoverDiv";

const WarningIcon = styled(SpriteImage)`
    z-index: 1;

    :hover {
        transform: scale(1.01)
    }
`;

WarningIcon.displayName = "WarningIcon";

function ContentWarningCover({ isRestricted = false, height, width, children }) {
    const [isCoverActive, setIsCoverActive] = useState(isRestricted);

    return (isCoverActive ?
        <Container height={height} width={width}>
            <WarningIcon
                className={`${SPRITE_KEYS.warning} clickable`}
                onClick={() => setIsCoverActive(false)}
                alt="Content Warning Icon"
                title="Content Warning! Click to view."
            />
            <BlurredCoverDiv>
                {children}
            </BlurredCoverDiv>
        </Container> : children
    );
}

export default ContentWarningCover;

ContentWarningCover.displayName = "ContentWarningCover";
