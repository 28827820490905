import BaseURL from "./images/Hosting-BaseURL";
import { removeTintValues } from "./common/settings";
import { shadowTypes } from "../styles/UnitShadow";

// BODY
const bodyMasculineImage = `${BaseURL}/Heroes/Tank/body/Body Masculine.png`;
const bodyFeminineImage = `${BaseURL}/Heroes/Tank/body/Body Feminine.png`;
// PREMIUM
const bodyLupineMasculineImage = `${BaseURL}/Heroes/Tank/body/premium/Body - Lupine Masculine.png`;
const bodyLupineFeminineImage = `${BaseURL}/Heroes/Tank/body/premium/Body - Lupine Feminine.png`;
const bodyReptileTailImage = `${BaseURL}/Heroes/Tank/body/premium/Body Sublayer - Reptile Tail.png`;
const bodyElfEarsFrontImage = `${BaseURL}/Heroes/Tank/body/premium/Head - Elf Ears Front.png`;
const bodyElfEarsBackImage = `${BaseURL}/Heroes/Tank/body/premium/Head - Elf Ears Back.png`;
const bodyCatEarsFrontImage = `${BaseURL}/Heroes/Tank/body/premium/Helmet - Cat Ears.png`;
const bodyCatEarsBackImage = `${BaseURL}/Heroes/Tank/body/premium/Helmet - Cat Ears Under.png`;

// FACE
const faceMasculineImage = `${BaseURL}/Heroes/Tank/face/Face - Masculine.png`;
const faceFeminineImage = `${BaseURL}/Heroes/Tank/face/Face - Feminine.png`;
// PREMIUM
const faceOrcishMasculineImage = `${BaseURL}/Heroes/Tank/face/premium/Face - Orcish Masculine.png`;
const faceOrcishFeminineImage = `${BaseURL}/Heroes/Tank/face/premium/Face - Orcish Feminine.png`;
const faceLupineImage = `${BaseURL}/Heroes/Tank/face/premium/Face - Lupine.png`;
const faceLupineSubImage = `${BaseURL}/Heroes/Tank/face/premium/Face - Lupine Sublayer.png`;
const faceReptilekinImage = `${BaseURL}/Heroes/Tank/face/premium/Face - Reptilekin.png`;

// EYES
const eyesMasculineImage = `${BaseURL}/Heroes/Tank/eyes/Eyes - Masculine.png`;
const eyesFeminineImage = `${BaseURL}/Heroes/Tank/eyes/Eyes - Feminine.png`;
// PREMIUM
const eyesLupineImage = `${BaseURL}/Heroes/Tank/eyes/premium/Eyes - Lupine.png`;
const eyesReptilekinImage = `${BaseURL}/Heroes/Tank/eyes/premium/Eyes - Reptilekin.png`;

// HAIR
const hairShortPartedImage = `${BaseURL}/Heroes/Tank/hair/Hair - Short Parted.png`;
const hairMediumBackImage = `${BaseURL}/Heroes/Tank/hair/Hair - Medium back.png`;
const hairFauxhawkImage = `${BaseURL}/Heroes/Tank/hair/Hair - Fauxhawk.png`;
const hairWildImage = `${BaseURL}/Heroes/Tank/hair/Hair - Wild.png`;
const hairFlowingLocksImage = `${BaseURL}/Heroes/Tank/hair/Hair - Flowing Locks.png`;
const hairGuileImage = `${BaseURL}/Heroes/Tank/hair/Hair - Guile.png`;
const hairBangsPonytailImage = `${BaseURL}/Heroes/Tank/hair/Hair - Bangs Ponytail.png`;
const hairAfroImage = `${BaseURL}/Heroes/Tank/hair/Hair - Afro.png`;
const hairTousledImage = `${BaseURL}/Heroes/Tank/hair/Hair - Tousled.png`;
const hairHiroProtagonistImage = `${BaseURL}/Heroes/Tank/hair/Hair - Hiro Protagonist.png`;
const hairTheMajorImage = `${BaseURL}/Heroes/Tank/hair/Hair - The Major.png`;
const hairTheMajorPonytailImage = `${BaseURL}/Heroes/Tank/hair/Hair - The Major Ponytail.png`;
const hairLongWavyBangsImage = `${BaseURL}/Heroes/Tank/hair/Hair - Long, Wavy, Bangs.png`;
const hairLongWavyPartedImage = `${BaseURL}/Heroes/Tank/hair/Hair - Long, Wavy, Parted.png`;

// FACIAL HAIR
const facialHairBubImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Bub.png`;
const facialHairHandlebarBubImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Handlebar Bub.png`;
const facialHairHandlebarStacheImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Handlebar Moustache.png`;
const facialHairHandlebarStacheBeardImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Handlebar Moustache Beard.png`;
const facialHairBraidedBeardImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Braided Beard.png`;
const facialHairShortStacheImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Short Beard Moustache.png`;
const facialHairMediumStacheImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Medium Beard Moustache.png`;
const facialHairLongStacheImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Long Beard Moustache.png`;
const facialHairShortGoateeImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Short Goatee.png`;
const facialHairMediumGoateeImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Medium Goatee.png`;
const facialHairLongGoateeImage = `${BaseURL}/Heroes/Tank/hair/facial/Facial Hair - Long Goatee.png`;

// HEAD
const headCloakHoodImage = `${BaseURL}/Heroes/Tank/head/Head - Cloak Hood.png`;
const headWizardImage = `${BaseURL}/Heroes/Tank/head/Head - Wizard Hat.png`;
const headWizardSubImage = `${BaseURL}/Heroes/Tank/head/Head - Wizard Hat Sublayer.png`;
const headHalfHelmImage = `${BaseURL}/Heroes/Tank/head/Head - Half-Helm.png`;
const headSalletImage = `${BaseURL}/Heroes/Tank/head/Head - Sallet.png`;
const headVanguardHelmImage = `${BaseURL}/Heroes/Tank/head/Head - Vanguard Helm.png`;
const headFlutedHelmImage = `${BaseURL}/Heroes/Tank/head/Head - Fluted Helm.png`;
const headCrownImage = `${BaseURL}/Heroes/Tank/head/Head - Crown.png`;
// PREMIUM
const headHorns1Image = `${BaseURL}/Heroes/Tank/head/premium/Head - Horns 1.png`;
const headHorns2Image = `${BaseURL}/Heroes/Tank/head/premium/Head - Horns 2.png`;
const headHorns3Image = `${BaseURL}/Heroes/Tank/head/premium/Head - Horns 3.png`;
const headHorns1ReptilekinImage = `${BaseURL}/Heroes/Tank/head/premium/Head - Horns 1 - Reptilekin.png`;
const headHorns2ReptilekinImage = `${BaseURL}/Heroes/Tank/head/premium/Head - Horns 2 - Reptilekin.png`;
const headHorns3ReptilekinImage = `${BaseURL}/Heroes/Tank/head/premium/Head - Horns 3 - Reptilekin.png`;
const headCloakHoodLupineImage = `${BaseURL}/Heroes/Tank/head/premium/Head - Cloak Hood - Lupine.png`;
// LEGENDARY
const headInfernalImage = `${BaseURL}/Heroes/Tank/head/legendary/Head - Infernal.png`;

// CHEST
const chestBarbarianImage = `${BaseURL}/Heroes/Tank/chest/Chest - Barbarian Masculine.png`;
const chestBarbarianFemImage = `${BaseURL}/Heroes/Tank/chest/Chest - Barbarian Feminine.png`;
const chestGambesonImage = `${BaseURL}/Heroes/Tank/chest/Chest - Gambeson Nosleeves.png`;
const chestPlatemailImage = `${BaseURL}/Heroes/Tank/chest/Chest - Platemail.png`;
const chestPlatemailSubImage = `${BaseURL}/Heroes/Tank/chest/Chest - Platemail Sublayer.png`;
const chestBraImage = `${BaseURL}/Heroes/Tank/chest/Chest - Bra.png`;
const chestMonkImage = `${BaseURL}/Heroes/Tank/chest/Chest - Monk Masculine.png`;
const chestMonkFemImage = `${BaseURL}/Heroes/Tank/chest/Chest - Monk Feminine.png`;
const chestMonkSubImage = `${BaseURL}/Heroes/Tank/chest/Chest - Monk Sublayer.png`;
const chestWizardImage = `${BaseURL}/Heroes/Tank/chest/Chest - Wizard.png`;
const chestWizardSubImage = `${BaseURL}/Heroes/Tank/chest/Chest - Wizard Sublayer.png`;
const chestBarkeepImage = `${BaseURL}/Heroes/Tank/chest/Chest - Barkeep M.png`;
const chestBarkeepFemImage = `${BaseURL}/Heroes/Tank/chest/Chest - Barkeep F.png`;
const chestBarkeepSleevelessImage = `${BaseURL}/Heroes/Tank/chest/Chest - Barkeep M nosleeves.png`;
const chestBarkeepSleevelessFemImage = `${BaseURL}/Heroes/Tank/chest/Chest - Barkeep F nosleeves.png`;
// LEGENDARY
const chestEleEmblemImage = `${BaseURL}/Heroes/Tank/chest/legendary/Chest - Elemental Emblem.png`;

// LEGS
const legsBarbarianImage = `${BaseURL}/Heroes/Tank/legs/Legs - Barbarian.png`;
const legsTrousersImage = `${BaseURL}/Heroes/Tank/legs/Legs - Gambeson.png`;
const legsGambesonImage = `${BaseURL}/Heroes/Tank/legs/Legs - Leather Armor.png`;
const legsPlatemailImage = `${BaseURL}/Heroes/Tank/legs/Legs - Platemail.png`;
const legsMonkImage = `${BaseURL}/Heroes/Tank/legs/Legs - Monk.png`;
const legsBoxersImage = `${BaseURL}/Heroes/Tank/legs/Legs - Boxers.png`;
const legsWizardImage = `${BaseURL}/Heroes/Tank/legs/Legs - Wizard.png`;
// LEGENDARY
const legsEleEmblemImage = `${BaseURL}/Heroes/Tank/legs/legendary/Legs - Elemental Emblem.png`;

// LEFT ARM
const leftArmBarbarianBracerImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Barbarian Bracer.png`;
const leftArmBarbarianImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Barbarian.png`;
const leftArmGambesonImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Gambeson.png`;
const leftArmPlatemailImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Platemail.png`;
const leftArmMonkImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Monk.png`;
const leftArmWizardImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Wizard.png`;
const leftArmWizardSublayerImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Wizard Sublayer.png`;
const leftArmWizardBracerImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Wizard Bracer.png`;
const leftArmGloveImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Glove.png`;
const leftArmSleeveGloveImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Sleeve Glove.png`;
const leftArmSleeveSublayerImage = `${BaseURL}/Heroes/Tank/arms/left/Left Arm - Sleeve Sublayer.png`;
// LEGENDARY
const leftArmEleEmblemImage = `${BaseURL}/Heroes/Tank/arms/left/legendary/Left Arm - Elemental Emblem.png`;

// RIGHT ARM
const rightArmBarbarianImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Barbarian.png`;
const rightArmGambesonImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Gambeson.png`;
const rightArmPlatemailImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Platemail.png`;
const rightArmMonkImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Monk.png`;
const rightArmWizardImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Wizard.png`;
const rightArmWizardBracerImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Wizard Bracer.png`;
const rightArmGloveImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Glove.png`;
const rightArmSleeveGloveImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Sleeve Glove.png`;
const rightArmSleeveImage = `${BaseURL}/Heroes/Tank/arms/right/Right Arm - Sleeve.png`;
// LEGENDARY
const rightArmEleEmblemImage = `${BaseURL}/Heroes/Tank/arms/right/legendary/Right Arm - Elemental Emblem.png`;

// LEFT WEAPON
const leftWeaponDaggerImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Dagger.png`;
const leftWeaponFencingDaggerImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Fencing Dagger.png`;
const leftWeaponShortSwordImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Shortsword.png`;
const leftWeaponSwordImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Sword.png`;
const leftWeaponAxeImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Axe.png`;
const leftWeaponHammerImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Hammer.png`;
const leftWeaponBucklerImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Buckler.png`;
const leftWeaponKiteShieldImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Shield.png`;
const leftWeaponKiteShieldBlankImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Shield - Blank.png`;
const leftWeaponRoundShieldImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Round Shield.png`;
const leftWeaponRoundShieldBlankImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Round Shield Blank.png`;
const leftWeaponTowerShieldImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Tower Shield.png`;
const leftWeaponTowerShieldBlankImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Tower Shield Blank.png`;
const leftWeaponWizardStaffImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Wizard Staff.png`;
const leftWeaponFlailImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Flail.png`;
const leftWeaponMisericordeImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Misericorde.png`;
const leftWeaponSteinImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Stein.png`;
const leftWeaponMaceImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Mace.png`;
const leftWeaponBastardSwordImage = `${BaseURL}/Heroes/Tank/weapons/left/Left Weapon - Bastard Sword.png`;

// RIGHT WEAPON
const rightWeaponShortSwordImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Shortsword.png`;
const rightWeaponSwordImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Sword.png`;
const rightWeaponAxeImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Axe.png`;
const rightWeaponAxeSubImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Axe Sublayer.png`;
const rightWeaponHammerImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Hammer.png`;
const rightWeaponShieldImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Shield.png`;
const rightWeaponShieldSubImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Shield Sublayer.png`;
const rightWeaponGreatswordImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Greatsword.png`;
const rightWeaponGreatswordSubImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Greatsword Sublayer.png`;
const rightWeaponGreataxeImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Greataxe.png`;
const rightWeaponGreataxeSubImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Greataxe Sublayer.png`;
const rightWeaponGreathammerImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Greathammer.png`;
const rightWeaponSpearImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Spear.png`;
const rightWeaponSpearSubImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Spear Sublayer.png`;
const rightWeaponBoStaffImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Bo staff.png`;
const rightWeaponBoStaffSubImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Bo staff Sublayer.png`;
const rightWeaponWizardStaffImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Wizard Staff.png`;
const rightWeaponScabbardImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Scabbard.png`;
const rightWeaponFlailImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Flail.png`;
const rightWeaponWoodenClubImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Wooden Club.png`;
const rightWeaponMaceImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Mace.png`;
const rightWeaponHalberdImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Halberd.png`;
const rightWeaponBastardSwordImage = `${BaseURL}/Heroes/Tank/weapons/right/Right Weapon - Bastard Sword.png`;

// ACCESSORIES
const accessoryWizardBracerLeftGlowImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Wizard Bracer Glow Left.png`;
const accessoryWizardBracerRightGlowImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Wizard Bracer Glow Right.png`;
const accessoryWizardBracersGlowImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Wizard Bracers Glow.png`;
const accessoryWizardStaffLeftGlowImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Wizard Staff Glow Left.png`;
const accessoryWizardStaffRightGlowImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Wizard Staff Glow Right.png`;
const accessoryCloak1Image = `${BaseURL}/Heroes/Tank/accessories/Accessory - Cloak 1.png`;
const accessoryCloak1SubImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Cloak 1 Sublayer.png`;
const accessoryCloak2Image = `${BaseURL}/Heroes/Tank/accessories/Accessory - Cloak 2.png`;
const accessoryCloak2SubImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Cloak 2 Sublayer.png`;
const accessoryCloak3Image = `${BaseURL}/Heroes/Tank/accessories/Accessory - Cloak 3.png`;
const accessoryCloak3SubImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Cloak 3 Sublayer.png`;
const accessoryRearStrapImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Rear Sword.png`;
const accessoryRearSwordSubImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Rear Sword Sublayer.png`;
const accessoryRearScabbardSubImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Rear Scabbard Sublayer.png`;
const accessoryRearKiteShieldSubImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Rear Kite Shield sublayer.png`;
const accessoryRearRoundShieldSubImage = `${BaseURL}/Heroes/Tank/accessories/Accessory - Rear Round Shield sublayer.png`;
const accessoryChestTattooSubImage = `${BaseURL}/Heroes/Tank/accessories/Chest - Tattoos Sublayer.png`;
// LEGENDARY
const accessoryEleEmblemArmorGlowImage = `${BaseURL}/Heroes/Tank/accessories/legendary/Accessory - Elemental Inferno.png`;

const previewImage = `${BaseURL}/Heroes/Tank/preview.png`;

const shadow = {
    size: shadowTypes.MEDIUM,
    bottom: "76px",
    right: "84px",
};

const defaultSelectionValues = {
    "skinValue": "0",
    "faceValue": "0",
    "eyesValue": "NONE",
    "hairValue": "RANDOM",
    "facialHairValue": "RANDOM",
    "headValue": "NONE",
    "chestValue": "11",
    "legsValue": "6",
    "leftArmValue": "9",
    "rightArmValue": "8",
    "leftWeaponValue": "5",
    "rightWeaponValue": "16",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE"
};

const defaultValues = Object.assign({}, defaultSelectionValues, removeTintValues);

export default {
    previewImg: previewImage,
    isExtraLarge: true,
    defaultValues: JSON.stringify(defaultValues),
    skin: [
        {
            value: 0,
            label: "Masculine",
            src: bodyMasculineImage,
            shadowDetails: shadow,
        },
        {
            value: 1,
            label: "Feminine",
            src: bodyFeminineImage,
            shadowDetails: shadow,
        },
        {
            value: 2,
            label: "Elven Masculine",
            src: bodyMasculineImage,
            frontSrc: bodyElfEarsFrontImage,
            backSrc: bodyElfEarsBackImage,
            shadowDetails: shadow,
            isPremium: true,
        },
        {
            value: 3,
            label: "Elven Feminine",
            src: bodyFeminineImage,
            frontSrc: bodyElfEarsFrontImage,
            backSrc: bodyElfEarsBackImage,
            shadowDetails: shadow,
            isPremium: true,
        },
        {
            value: 4,
            label: "Reptilekin Masculine",
            src: bodyMasculineImage,
            backSrc: bodyReptileTailImage,
            isPremium: true,
            shadowDetails: shadow,
        },
        {
            value: 5,
            label: "Reptilekin Feminine",
            src: bodyFeminineImage,
            backSrc: bodyReptileTailImage,
            isPremium: true,
            shadowDetails: shadow,
        },
        {
            value: 6,
            label: "Lupine Masculine",
            src: bodyLupineMasculineImage,
            isPremium: true,
            shadowDetails: shadow,
        },
        {
            value: 7,
            label: "Lupine Feminine",
            src: bodyLupineFeminineImage,
            isPremium: true,
            shadowDetails: shadow,
        },
        {
            value: 8,
            label: "Feline Masculine",
            src: bodyMasculineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
            shadowDetails: shadow,
            isPremium: true,
        },
        {
            value: 9,
            label: "Feline Feminine",
            src: bodyFeminineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
            shadowDetails: shadow,
            isPremium: true,
        },
    ],
    face: [
        {
            value: 0,
            label: "Masculine",
            src: faceMasculineImage,
        },
        {
            value: 1,
            label: "Feminine",
            src: faceFeminineImage,
        },
        {
            value: 2,
            label: "Elven Masculine",
            src: faceMasculineImage,
            frontSrc: bodyElfEarsFrontImage,
            backSrc: bodyElfEarsBackImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "Elven Feminine",
            src: faceFeminineImage,
            frontSrc: bodyElfEarsFrontImage,
            backSrc: bodyElfEarsBackImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "Orcish Masculine",
            src: faceOrcishMasculineImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "Orcish Feminine",
            src: faceOrcishFeminineImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "Reptilekin",
            src: faceReptilekinImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "Lupine",
            src: faceLupineImage,
            backSrc: faceLupineSubImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "Feline Masculine",
            src: faceMasculineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "Feline Feminine",
            src: faceFeminineImage,
            frontSrc: bodyCatEarsFrontImage,
            backSrc: bodyCatEarsBackImage,
            isPremium: true,
        },
    ],
    eyes: [
        {
            value: 0,
            label: "Masculine",
            src: eyesMasculineImage,
        },
        {
            value: 1,
            label: "Feminine",
            src: eyesFeminineImage,
        },
        {
            value: 2,
            label: "Lupine",
            src: eyesLupineImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "Reptilekin",
            src: eyesReptilekinImage,
            isPremium: true,
        },
    ],
    hair: [
        {
            value: 0,
            label: "Short Parted",
            src: hairShortPartedImage,
        },
        {
            value: 1,
            label: "Medium Back",
            src: hairMediumBackImage,
        },
        {
            value: 2,
            label: "Fauxhawk",
            src: hairFauxhawkImage,
        },
        {
            value: 3,
            label: "Wild",
            src: hairWildImage,
        },
        {
            value: 4,
            label: "Afro",
            src: hairAfroImage,
        },
        {
            value: 5,
            label: "Flowing Locks",
            src: hairFlowingLocksImage,
        },
        {
            value: 6,
            label: "Bangs + Ponytail",
            src: hairBangsPonytailImage,
        },
        {
            value: 7,
            label: "Long Wavy Bangs",
            src: hairLongWavyBangsImage,
        },
        {
            value: 8,
            label: "Long Wavy Parted",
            src: hairLongWavyPartedImage,
        },
        {
            value: 9,
            label: "Tousled",
            src: hairTousledImage,
        },
        {
            value: 10,
            label: "Guile",
            src: hairGuileImage,
        },
        {
            value: 11,
            label: "The Major",
            src: hairTheMajorImage,
        },
        {
            value: 12,
            label: "The Major + Ponytail",
            src: hairTheMajorPonytailImage,
        },
        {
            value: 13,
            label: "Hiro Protagonist",
            src: hairHiroProtagonistImage,
        },
    ],
    facialHair: [
        {
            value: 0,
            label: "Bub",
            src: facialHairBubImage,
        },
        {
            value: 1,
            label: "Short Goatee",
            src: facialHairShortGoateeImage,
        },
        {
            value: 2,
            label: "Medium Goatee",
            src: facialHairMediumGoateeImage,
        },
        {
            value: 3,
            label: "Long Goatee",
            src: facialHairLongGoateeImage,
        },
        {
            value: 4,
            label: "Handlebar Stache",
            src: facialHairHandlebarStacheImage,
        },
        {
            value: 5,
            label: "Handlebar + Bub",
            src: facialHairHandlebarBubImage,
        },
        {
            value: 6,
            label: "Handlebar + Beard",
            src: facialHairHandlebarStacheBeardImage,
        },
        {
            value: 7,
            label: "Short Stache",
            src: facialHairShortStacheImage,
        },
        {
            value: 8,
            label: "Medium Stache",
            src: facialHairMediumStacheImage,
        },
        {
            value: 9,
            label: "Long Stache",
            src: facialHairLongStacheImage,
        },
        {
            value: 10,
            label: "Short Beard",
            frontSrc: facialHairShortStacheImage,
            src: facialHairShortGoateeImage,
        },
        {
            value: 11,
            label: "Medium Beard",
            frontSrc: facialHairMediumStacheImage,
            src: facialHairMediumGoateeImage,
        },
        {
            value: 12,
            label: "Long Beard",
            frontSrc: facialHairLongStacheImage,
            src: facialHairLongGoateeImage,
        },
        {
            value: 13,
            label: "Braided Beard",
            src: facialHairBraidedBeardImage,
        },
    ],
    head: [
        {
            value: 0,
            label: "Cloak Hood",
            src: headCloakHoodImage,
        },
        {
            value: 1,
            label: "Wizard Hat",
            src: headWizardImage,
            backSrc: headWizardSubImage,
        },
        {
            value: 2,
            label: "Sallet",
            src: headSalletImage,
        },
        {
            value: 3,
            label: "Half-Helm",
            src: headHalfHelmImage,
        },
        {
            value: 4,
            label: "Fluted Helm",
            src: headFlutedHelmImage,
        },
        {
            value: 5,
            label: "Vanguard Helm",
            src: headVanguardHelmImage,
        },
        {
            value: 6,
            label: "Crown",
            src: headCrownImage,
        },
        {
            value: 7,
            label: "Horns 1",
            src: headHorns1Image,
            isPremium: true,
        },
        {
            value: 8,
            label: "Horns 2",
            src: headHorns2Image,
            isPremium: true,
        },
        {
            value: 9,
            label: "Horns 3",
            src: headHorns3Image,
            isPremium: true,
        },
        {
            value: 10,
            label: "Horns 1 - Reptilekin",
            src: headHorns1ReptilekinImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "Horns 2 - Reptilekin",
            src: headHorns2ReptilekinImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "Horns 3 - Reptilekin",
            src: headHorns3ReptilekinImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "Cloak Hood - Lupine",
            src: headCloakHoodLupineImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "Infernal",
            src: headInfernalImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chest: [
        {
            value: 0,
            label: "Bra",
            src: chestBraImage,
        },
        {
            value: 1,
            label: "Barkeep Feminine",
            src: chestBarkeepFemImage,
        },
        {
            value: 2,
            label: "Barkeep Masculine",
            src: chestBarkeepImage,
        },
        {
            value: 3,
            label: "Barkeep Feminine Sleeveless",
            src: chestBarkeepSleevelessFemImage,
        },
        {
            value: 4,
            label: "Barkeep Masculine Sleeveless",
            src: chestBarkeepSleevelessImage,
        },
        {
            value: 5,
            label: "Barbarian Feminine",
            src: chestBarbarianFemImage,
        },
        {
            value: 6,
            label: "Barbarian Masculine",
            src: chestBarbarianImage,
        },
        {
            value: 7,
            label: "Monk Feminine",
            src: chestMonkFemImage,
            backSrc: chestMonkSubImage,
        },
        {
            value: 8,
            label: "Monk Masculine",
            src: chestMonkImage,
            backSrc: chestMonkSubImage,
        },
        {
            value: 9,
            label: "Wizard",
            src: chestWizardImage,
            backSrc: chestWizardSubImage,
        },
        {
            value: 10,
            label: "Leather Gambeson",
            src: chestGambesonImage,
        },
        {
            value: 11,
            label: "Platemail",
            src: chestPlatemailImage,
            backSrc: chestPlatemailSubImage,
        },
        {
            value: 12,
            label: "Elemental Emblem",
            src: chestEleEmblemImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    legs: [
        {
            value: 0,
            label: "Boxers",
            src: legsBoxersImage,
        },
        {
            value: 1,
            label: "Trousers",
            src: legsTrousersImage,
        },
        {
            value: 2,
            label: "Barbarian",
            src: legsBarbarianImage,
        },
        {
            value: 3,
            label: "Monk",
            src: legsMonkImage,
        },
        {
            value: 4,
            label: "Wizard",
            src: legsWizardImage,
        },
        {
            value: 5,
            label: "Leather Armor",
            src: legsGambesonImage,
        },
        {
            value: 6,
            label: "Platemail",
            src: legsPlatemailImage,
        },
        {
            value: 7,
            label: "Elemental Emblem",
            src: legsEleEmblemImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    leftArm: [
        {
            value: 0,
            label: "Sleeve",
            backSrc: leftArmSleeveSublayerImage,
        },
        {
            value: 1,
            label: "Glove",
            src: leftArmGloveImage,
        },
        {
            value: 2,
            label: "Sleeve + Glove",
            src: leftArmSleeveGloveImage,
            backSrc: leftArmSleeveSublayerImage,
        },
        {
            value: 3,
            label: "Barbarian",
            src: leftArmBarbarianImage,
        },
        {
            value: 4,
            label: "Barbarian Bracer",
            src: leftArmBarbarianBracerImage,
        },
        {
            value: 5,
            label: "Monk",
            backSrc: leftArmMonkImage,
        },
        {
            value: 6,
            label: "Wizard",
            src: leftArmWizardImage,
            backSrc: leftArmWizardSublayerImage,
        },
        {
            value: 7,
            label: "Wizard Bracer",
            src: leftArmWizardBracerImage,
        },
        {
            value: 8,
            label: "Leather Gambeson",
            src: leftArmGambesonImage,
        },
        {
            value: 9,
            label: "Platemail",
            src: leftArmPlatemailImage,
        },
        {
            value: 10,
            label: "Elemental Emblem",
            src: leftArmEleEmblemImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rightArm: [
        {
            value: 0,
            label: "Sleeve",
            src: rightArmSleeveImage,
        },
        {
            value: 1,
            label: "Glove",
            src: rightArmGloveImage,
        },
        {
            value: 2,
            label: "Sleeve + Glove",
            src: rightArmSleeveGloveImage,
        },
        {
            value: 3,
            label: "Barbarian",
            src: rightArmBarbarianImage,
        },
        {
            value: 4,
            label: "Monk",
            src: rightArmMonkImage,
        },
        {
            value: 5,
            label: "Wizard",
            src: rightArmWizardImage,
        },
        {
            value: 6,
            label: "Wizard Bracer",
            src: rightArmWizardBracerImage,
        },
        {
            value: 7,
            label: "Leather Gambeson",
            src: rightArmGambesonImage,
        },
        {
            value: 8,
            label: "Platemail",
            src: rightArmPlatemailImage,
        },
        {
            value: 9,
            label: "Elemental Emblem",
            src: rightArmEleEmblemImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    leftWeapon: [
        {
            value: 0,
            label: "Stein",
            src: leftWeaponSteinImage,
        },
        {
            value: 1,
            label: "Dagger",
            src: leftWeaponDaggerImage,
        },
        {
            value: 2,
            label: "Fencing Dagger",
            src: leftWeaponFencingDaggerImage,
        },
        {
            value: 3,
            label: "Misericorde",
            src: leftWeaponMisericordeImage,
        },
        {
            value: 4,
            label: "Short Sword",
            src: leftWeaponShortSwordImage,
        },
        {
            value: 5,
            label: "Sword",
            src: leftWeaponSwordImage,
        },
        {
            value: 6,
            label: "Bastard Sword",
            src: leftWeaponBastardSwordImage,
        },
        {
            value: 7,
            label: "Axe",
            src: leftWeaponAxeImage,
        },
        {
            value: 8,
            label: "Hammer",
            src: leftWeaponHammerImage,
        },
        {
            value: 9,
            label: "Flail",
            src: leftWeaponFlailImage,
        },
        {
            value: 10,
            label: "Mace",
            src: leftWeaponMaceImage,
        },
        {
            value: 11,
            label: "Wizard Staff",
            src: leftWeaponWizardStaffImage,
        },
        {
            value: 12,
            label: "Buckler",
            src: leftWeaponBucklerImage,
        },
        {
            value: 13,
            label: "Kite Shield",
            src: leftWeaponKiteShieldImage,
        },
        {
            value: 14,
            label: "Kite Shield Blank",
            src: leftWeaponKiteShieldBlankImage,
        },
        {
            value: 15,
            label: "Round Shield",
            src: leftWeaponRoundShieldImage,
        },
        {
            value: 16,
            label: "Round Shield Blank",
            src: leftWeaponRoundShieldBlankImage,
        },
        {
            value: 17,
            label: "Tower Shield",
            src: leftWeaponTowerShieldImage,
        },
        {
            value: 18,
            label: "Tower Shield Blank",
            src: leftWeaponTowerShieldBlankImage,
        },
    ],
    rightWeapon: [
        {
            value: 0,
            label: "Scabbard",
            src: rightWeaponScabbardImage,
        },
        {
            value: 1,
            label: "Short Sword",
            src: rightWeaponShortSwordImage,
        },
        {
            value: 2,
            label: "Sword",
            src: rightWeaponSwordImage,
        },
        {
            value: 3,
            label: "Bastard Sword",
            src: rightWeaponBastardSwordImage,
        },
        {
            value: 4,
            label: "Greatsword",
            src: rightWeaponGreatswordImage,
            backSrc: rightWeaponGreatswordSubImage,
        },
        {
            value: 5,
            label: "Axe",
            src: rightWeaponAxeImage,
            backSrc: rightWeaponAxeSubImage,
        },
        {
            value: 6,
            label: "Greataxe",
            src: rightWeaponGreataxeImage,
            backSrc: rightWeaponGreataxeSubImage,
        },
        {
            value: 7,
            label: "Wooden Club",
            src: rightWeaponWoodenClubImage,
        },
        {
            value: 8,
            label: "Mace",
            src: rightWeaponMaceImage,
        },
        {
            value: 9,
            label: "Flail",
            src: rightWeaponFlailImage,
        },
        {
            value: 10,
            label: "Hammer",
            src: rightWeaponHammerImage,
        },
        {
            value: 11,
            label: "Greathammer",
            src: rightWeaponGreathammerImage,
        },
        {
            value: 12,
            label: "Spear",
            src: rightWeaponSpearImage,
            backSrc: rightWeaponSpearSubImage,
        },
        {
            value: 13,
            label: "Halberd",
            src: rightWeaponHalberdImage,
            backSrc: rightWeaponSpearSubImage,
        },
        {
            value: 14,
            label: "Bo Staff",
            src: rightWeaponBoStaffImage,
            backSrc: rightWeaponBoStaffSubImage,
        },
        {
            value: 15,
            label: "Wizard Staff",
            src: rightWeaponWizardStaffImage,
        },
        {
            value: 16,
            label: "Shield",
            src: rightWeaponShieldImage,
            backSrc: rightWeaponShieldSubImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "Wizard Bracer Left - Glow",
            src: accessoryWizardBracerLeftGlowImage,
        },
        {
            value: 1,
            label: "Wizard Bracer Right - Glow",
            src: accessoryWizardBracerRightGlowImage,
        },
        {
            value: 2,
            label: "Wizard Bracers - Glow",
            src: accessoryWizardBracersGlowImage,
        },
        {
            value: 3,
            label: "Wizard Staff Left - Glow",
            frontSrc: accessoryWizardStaffLeftGlowImage,
        },
        {
            value: 4,
            label: "Wizard Staff Right - Glow",
            src: accessoryWizardStaffRightGlowImage,
        },
        {
            value: 5,
            label: "Cloak 1",
            src: accessoryCloak1Image,
            backSrc: accessoryCloak1SubImage,
        },
        {
            value: 6,
            label: "Cloak 2",
            src: accessoryCloak2Image,
            backSrc: accessoryCloak2SubImage,
        },
        {
            value: 7,
            label: "Cloak 3",
            src: accessoryCloak3Image,
            backSrc: accessoryCloak3SubImage,
        },
        {
            value: 8,
            label: "Leather Strap",
            src: accessoryRearStrapImage,
        },
        {
            value: 9,
            label: "Rear Scabbard",
            src: accessoryRearStrapImage,
            backSrc: accessoryRearScabbardSubImage,
        },
        {
            value: 10,
            label: "Rear Sword",
            src: accessoryRearStrapImage,
            backSrc: accessoryRearSwordSubImage,
        },
        {
            value: 11,
            label: "Rear Kite Shield",
            src: accessoryRearStrapImage,
            backSrc: accessoryRearKiteShieldSubImage,
        },
        {
            value: 12,
            label: "Rear Round Shield",
            src: accessoryRearStrapImage,
            backSrc: accessoryRearRoundShieldSubImage,
        },
        {
            value: 13,
            label: "Tattoos",
            tattooSrc: accessoryChestTattooSubImage,
        },
        {
            value: 14,
            label: "Elemental Inferno - Glow",
            src: accessoryEleEmblemArmorGlowImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "All",
            value: "ALL",
        },
        {
            label: "Skin Tone",
            value: "BODY",
        },
        {
            label: "Armor",
            value: "ARMOR",
        },
        {
            label: "Weapons",
            value: "WEAPONS",
        },
        {
            label: "All Hair",
            value: "ALLHAIR",
        },
        {
            label: "Accessories",
            value: "ACCESSORIES",
        },
        {
            label: "Body",
            value: "skin",
        },
        {
            label: "Face",
            value: "face",
        },
        {
            label: "Eyes",
            value: "eyes",
            isGlow: true,
        },
        {
            label: "Hair",
            value: "hair",
        },
        {
            label: "Facial Hair",
            value: "facialHair",
        },
        {
            label: "Head",
            value: "head",
        },
        {
            label: "Chest",
            value: "chest",
        },
        {
            label: "Legs",
            value: "legs",
        },
        {
            label: "Left Arm",
            value: "leftArm",
        },
        {
            label: "Right Arm",
            value: "rightArm",
        },
        {
            label: "Left Weapon",
            value: "leftWeapon",
        },
        {
            label: "Right Weapon",
            value: "rightWeapon",
        },
        {
            label: "Accessory 1",
            value: "accessory1",
        },
        {
            label: "Accessory 2",
            value: "accessory2",
        },
        {
            label: "Accessory 3",
            value: "accessory3",
        },
        {
            label: "Accessory 4",
            value: "accessory4",
        },
    ],
    labels: {
        "skin": "Body",
        "face": "Face",
        "eyes": "Eyes",
        "hair": "Hair",
        "facialHair": "Facial Hair",
        "head": "Head",
        "chest": "Chest",
        "legs": "Legs",
        "leftArm": "Left Arm",
        "rightArm": "Right Arm",
        "leftWeapon": "Left Weapon",
        "rightWeapon": "Right Weapon",
        "accessory1": "Accessory 1",
        "accessory2": "Accessory 2",
        "accessory3": "Accessory 3",
        "accessory4": "Accessory 4",
    },
    templateList: [
        {
            label: "Buff",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "skinValue": "0",
                    "faceValue": "NONE",
                    "eyesValue": "NONE",
                    "hairValue": "NONE",
                    "facialHairValue": "NONE",
                    "headValue": "NONE",
                    "chestValue": "NONE",
                    "legsValue": "NONE",
                    "leftArmValue": "NONE",
                    "rightArmValue": "NONE",
                    "leftWeaponValue": "NONE",
                    "rightWeaponValue": "NONE",
                },
            )),
        },
        {
            label: "Tattoos",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "NONE",
                    "facialHairValue": "RANDOM",
                    "chestValue": "NONE",
                    "legsValue": "2",
                    "leftArmValue": "NONE",
                    "rightArmValue": "NONE",
                    "leftWeaponValue": "NONE",
                    "rightWeaponValue": "NONE",
                    "accessory1Value": "13",
                },
            )),
        },
        {
            label: "Barkeep F",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "NONE",
                    "skinValue": "1",
                    "faceValue": "1",
                    "chestValue": "1",
                    "legsValue": "1",
                    "leftArmValue": "NONE",
                    "rightArmValue": "NONE",
                    "leftWeaponValue": "0",
                    "rightWeaponValue": "7",
                },
            )),
        },
        {
            label: "Barkeep M",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                    "skinValue": "0",
                    "faceValue": "0",
                    "chestValue": "2",
                    "legsValue": "1",
                    "leftArmValue": "NONE",
                    "rightArmValue": "NONE",
                    "leftWeaponValue": "0",
                    "rightWeaponValue": "7",
                },
            )),
        },
        {
            label: "Barbarian F",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "NONE",
                    "skinValue": "1",
                    "faceValue": "1",
                    "chestValue": "5",
                    "legsValue": "2",
                    "leftArmValue": "3",
                    "rightArmValue": "3",
                    "leftWeaponValue": "7",
                    "rightWeaponValue": "5",
                },
            )),
        },
        {
            label: "Barbarian M",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                    "skinValue": "0",
                    "faceValue": "0",
                    "chestValue": "6",
                    "legsValue": "2",
                    "leftArmValue": "3",
                    "rightArmValue": "3",
                    "leftWeaponValue": "7",
                    "rightWeaponValue": "5",
                },
            )),
        },
        {
            label: "Monk F",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "NONE",
                    "skinValue": "1",
                    "faceValue": "1",
                    "chestValue": "7",
                    "legsValue": "3",
                    "leftArmValue": "5",
                    "rightArmValue": "4",
                    "leftWeaponValue": "NONE",
                    "rightWeaponValue": "14",
                },
            )),
        },
        {
            label: "Monk M",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                    "skinValue": "0",
                    "faceValue": "0",
                    "chestValue": "8",
                    "legsValue": "3",
                    "leftArmValue": "5",
                    "rightArmValue": "4",
                    "leftWeaponValue": "NONE",
                    "rightWeaponValue": "14",
                },
            )),
        },
        {
            label: "Wizard",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "NONE",
                    "facialHairValue": "RANDOM",
                    "headValue": "1",
                    "chestValue": "9",
                    "legsValue": "4",
                    "leftArmValue": "6",
                    "rightArmValue": "5",
                    "leftWeaponValue": "11",
                    "rightWeaponValue": "NONE",
                    "accessory1Value": "3",
                },
            )),
        },
        {
            label: "Leather Gambeson",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "chestValue": "10",
                    "legsValue": "5",
                    "leftArmValue": "8",
                    "rightArmValue": "7",
                    "leftWeaponValue": "12",
                    "rightWeaponValue": "1",
                },
            )),
        },
        {
            label: "Platemail",
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "chestValue": "11",
                    "legsValue": "6",
                    "leftArmValue": "9",
                    "rightArmValue": "8",
                    "leftWeaponValue": "15",
                    "rightWeaponValue": "2",
                },
            )),
        },
        {
            label: "Elemental Emblem",
            isPremium: true,
            isLegendary: true,
            value: JSON.stringify(Object.assign(
                {},
                defaultSelectionValues,
                {
                    "hairValue": "RANDOM",
                    "facialHairValue": "RANDOM",
                    "chestValue": "12",
                    "legsValue": "7",
                    "leftArmValue": "10",
                    "rightArmValue": "9",
                    "leftWeaponValue": "17",
                    "rightWeaponValue": "6",
                },
            )),
        },
    ],
};
