import React, { useContext, useState } from "react";
import { bool } from "prop-types";
import styled from "styled-components";

import {
    MenuContentHeader,
    CloseButton,
} from "./../../../../Menu/MenuContentContainer";

import {
    SelectDropdown,
    defaultColor,
    retroTerminalColor,
} from "./MechCategoryStyles";

import {
    InputRange as SharedInputRange,
    ColorSelectorOption,
    CustomColorControlsWrapper,
    ColorSaveContainer,
    ColorSaveButton,
    ColorSelectorButton,
} from "../../components/CustomColorControls";

import OpaqueWindowContainerWrapper from "./../../../../common/styles/OpaqueWindowContainer";
import OpaqueWindowContent from "./../../../../common/styles/OpaqueWindowContent";
import BaseURL from "./../../../data/images/Hosting-BaseURL";
import { CustomColorContext } from "../../../CustomColorContextWrapper";
import { SPRITE_KEYS } from "../../../../common/styles/SpriteImage";
import { hexToRgb } from "../../components/Converter/converter";

const colorSelectIcon = `${BaseURL}/buttons/Color-Select-Icon.png`;
const colorSampleSquareGlowImage = `${BaseURL}/buttons/Color-Sample-Square-Glow.png`;
const colorSampleSquareMetalImage = `${BaseURL}/buttons/Color-Sample-Square.png`;
const savedColorIcon1Image = `${BaseURL}/buttons/saveicon1.png`;
const savedColorIcon2Image = `${BaseURL}/buttons/saveicon2.png`;
const savedColorIcon3Image = `${BaseURL}/buttons/saveicon3.png`;
const savedColorIcon4Image = `${BaseURL}/buttons/saveicon4.png`;

const InputRangeSelect = styled(SelectDropdown)`
    .range-wrapper {
        width: 186px;
        padding: 7px 0;
    }

    @media only screen and (max-width: ${({theme}) => theme.mobileCutoffWidth}px) {
        .range-wrapper {
            width: 146px;
        }
    }

    span.input-button {
        color: ${({disable}) => disable ? "#333" : defaultColor};
    }

    &.retro-terminal {
        span.input-button {
            color: ${({disable}) => disable ? "#666" : retroTerminalColor};
        }

        .range-wrapper {
            border-style: solid;
        }

        @media only screen and (max-width: ${({theme}) => theme.tabletCutoffWidth}px) {
            margin-top: 10px;
        }
    }
`;

InputRangeSelect.displayName = "InputRangeSelect";

const InputRange = styled(SharedInputRange)`
    &.default {
        &[type=range]::-webkit-slider-runnable-track {
            background-color: ${defaultColor};
        }

        &[type=range]::-moz-range-track {
            background-color: ${defaultColor};
        }

        &[type=range]::-ms-track {
            background-color: ${defaultColor};
        }
    }
`;

function CustomColorControls({ displayTheme, targetPartIsGlow }) {
    const [showColorSelector, setShowColorSelector] = useState(null);

    const {
        customColorIndex,
        selectorMin,
        sepiaMax,
        saturationMax,
        brightnessMax,
        hueMin,
        hueMax,
        invertMax,
        contrastMax,
        setColorValue,
        sepiaValue,
        setSepiaValue,
        saturationValue,
        setSaturationValue,
        brightnessValue,
        setBrightnessValue,
        hueValue,
        setHueValue,
        invertValue,
        setInvertValue,
        contrastValue,
        setContrastValue,
        tintValue,
        savedTint1Value,
        savedTint2Value,
        savedTint3Value,
        savedTint4Value,
        saveCustomColorValues,
        hexInputValue,
        setHexInputValue,
        isHexInputValid,
        setIsHexInputValid,
        shouldConvertGlowValue,
        setShouldConvertGlowValue,
        getFilterFromHex,
    } = useContext(CustomColorContext);

    let colorOptionsArray = [];
    let rangeMin = selectorMin;
    let rangeMax;
    let currentValue;
    let setFunction;
    let returnFilterFunction;

    if (showColorSelector !== null) {
        switch (showColorSelector) {
            case 'SEPIA':
                rangeMax = sepiaMax;
                currentValue = sepiaValue;
                setFunction = setSepiaValue;
                returnFilterFunction = (value) => {
                    return `sepia(${value}%) saturate(${saturationValue}%) brightness(${brightnessValue}%) hue-rotate(${hueValue}deg) invert(${invertValue}%) contrast(${contrastValue}%)`;
                };
                break;

            case 'BRIGHTNESS':
                rangeMax = brightnessMax;
                currentValue = brightnessValue;
                setFunction = setBrightnessValue;
                returnFilterFunction = (value) => {
                    return `sepia(${sepiaValue}%) saturate(${saturationValue}%) brightness(${value}%) hue-rotate(${hueValue}deg) invert(${invertValue}%) contrast(${contrastValue}%)`;
                };
                break;

            case 'SATURATION':
                rangeMax = saturationMax;
                currentValue = saturationValue;
                setFunction = setSaturationValue;
                returnFilterFunction = (value) => {
                    return `sepia(${sepiaValue}%) saturate(${value}%) brightness(${brightnessValue}%) hue-rotate(${hueValue}deg) invert(${invertValue}%) contrast(${contrastValue}%)`;
                };
                break;

            case 'HUE':
                rangeMin = hueMin;
                rangeMax = hueMax;
                currentValue = hueValue;
                setFunction = setHueValue;
                returnFilterFunction = (value) => {
                    return `sepia(${sepiaValue}%) saturate(${saturationValue}%) brightness(${brightnessValue}%) hue-rotate(${value}deg) invert(${invertValue}%) contrast(${contrastValue}%)`;
                };
                break;

            case 'INVERT':
                rangeMax = invertMax;
                currentValue = invertValue;
                setFunction = setInvertValue;
                returnFilterFunction = (value) => {
                    return `sepia(${sepiaValue}%) saturate(${saturationValue}%) brightness(${brightnessValue}%) hue-rotate(${hueValue}deg) invert(${value}%) contrast(${contrastValue}%)`;
                };
                break;

            case 'CONTRAST':
                rangeMax = contrastMax;
                currentValue = contrastValue;
                setFunction = setContrastValue;
                returnFilterFunction = (value) => {
                    return `sepia(${sepiaValue}%) saturate(${saturationValue}%) brightness(${brightnessValue}%) hue-rotate(${hueValue}deg) invert(${invertValue}%) contrast(${value}%)`;
                };
                break;

            default:
                break;
        }

        for (let i = rangeMin; i <= rangeMax; i += 1) {
            colorOptionsArray.push((
                <ColorSelectorOption
                    key={`${showColorSelector}-${i}`}
                    alt={`${showColorSelector} Value ${i}`}
                    src={targetPartIsGlow ? colorSampleSquareGlowImage : colorSampleSquareMetalImage}
                    className={i === currentValue ? "is-selected" : ""}
                    onClick={() => {
                        setShowColorSelector(null);
                        setFunction(i);
                        setColorValue(customColorIndex);
                    }}
                    title={i}
                    style={{
                        filter: returnFilterFunction(i),
                    }}
                />
            ));
        }
    }

    return (
        <CustomColorControlsWrapper className={displayTheme ? "retro-terminal" : "default"}>
            {showColorSelector !== null ?
                <OpaqueWindowContainerWrapper style={{ zIndex: 1000 }}>
                    <MenuContentHeader>
                        <h2>SELECT {showColorSelector}</h2>
                        <CloseButton
                            className={SPRITE_KEYS.xButton}
                            alt="Close"
                            onClick={() => setShowColorSelector(null)}
                        />
                    </MenuContentHeader>

                    <OpaqueWindowContent>
                        {colorOptionsArray}
                    </OpaqueWindowContent>
                </OpaqueWindowContainerWrapper>
                : null
            }

            <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", marginLeft: "10px" }}>
                <ColorSaveContainer>
                    <ColorSaveButton
                        aria-label="Save Values to 'SAVED CUSTOM 1' slot"
                        title="Save Values to 'SAVED CUSTOM 1' slot"
                        src={savedColorIcon1Image}
                        onClick={() => saveCustomColorValues(1)}
                        tintValue={savedTint1Value}
                    />
                    <ColorSaveButton
                        aria-label="Save Values to 'SAVED CUSTOM 2' slot"
                        title="Save Values to 'SAVED CUSTOM 2' slot"
                        src={savedColorIcon2Image}
                        onClick={() => saveCustomColorValues(2)}
                        tintValue={savedTint2Value}
                    />
                    <ColorSaveButton
                        aria-label="Save Values to 'SAVED CUSTOM 3' slot"
                        title="Save Values to 'SAVED CUSTOM 3' slot"
                        src={savedColorIcon3Image}
                        onClick={() => saveCustomColorValues(3)}
                        tintValue={savedTint3Value}
                    />
                    <ColorSaveButton
                        aria-label="Save Values to 'SAVED CUSTOM 4' slot"
                        title="Save Values to 'SAVED CUSTOM 4' slot"
                        src={savedColorIcon4Image}
                        onClick={() => saveCustomColorValues(4)}
                        tintValue={savedTint4Value}
                    />
                </ColorSaveContainer>

                <InputRangeSelect
                    className={displayTheme ? "retro-terminal" : "default"}
                    disable={!isHexInputValid}
                    title="Harness Math Magic to ATTEMPT to convert a hex color code to base or glow color filter values. Try repeatedly until desired result is achieved."
                >
                    <label htmlFor="convert-hex-input">
                        CONVERT HEX
                    </label>
                    <label>
                        COLOR TYPE:
                        <img
                            alt="Color Source"
                            src={shouldConvertGlowValue ? colorSampleSquareGlowImage : colorSampleSquareMetalImage}
                            className="part-color-preview"
                            onClick={() => setShouldConvertGlowValue(!shouldConvertGlowValue)}
                            style={{ cursor: "pointer" }}
                        />
                    </label>
                    <div className="range-wrapper" style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                        <div>
                            Target: #
                            <InputRange
                                value={hexInputValue}
                                onChange={(event) => {
                                    setHexInputValue(event.target.value);
                                    setIsHexInputValid(hexToRgb(event.target.value) !== null);
                                }}
                                type="text"
                                minLength={3}
                                maxLength={6}
                                placeholder="000000"
                                style={{ width: "35%" }}
                            />
                        </div>
                        <span className="input-button" onClick={() => isHexInputValid ? getFilterFromHex() : null}>CONVERT</span>
                    </div>
                </InputRangeSelect>
            </div>

            <div style={{ display: "flex", flexDirection: "row", rowGap: "5px", justifyContent: "space-around", flexWrap: "wrap" }}>
                <InputRangeSelect className={displayTheme ? "retro-terminal" : "default"}>
                    <label htmlFor="sepia-select">
                        SEPIA
                        <ColorSelectorButton
                            alt="CHOOSE COLOR OPTION"
                            src={colorSelectIcon}
                            onClick={() => {
                                setShowColorSelector('SEPIA');
                            }}
                        />
                    </label>
                    <div className="range-wrapper">
                        <InputRange
                            className={displayTheme ? "retro-terminal" : "default"}
                            value={sepiaValue}
                            onChange={(event) => {
                                setSepiaValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="range"
                            targetPartIsGlow={targetPartIsGlow}
                            min={selectorMin}
                            max={sepiaMax}
                            tintValue={tintValue}
                        />
                        <InputRange
                            value={sepiaValue}
                            onChange={(event) => {
                                setSepiaValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="number"
                            min={selectorMin}
                            max={sepiaMax}
                        />
                    </div>
                </InputRangeSelect>

                <InputRangeSelect className={displayTheme ? "retro-terminal" : "default"}>
                    <label htmlFor="saturation-select">
                        SATURATION
                        <ColorSelectorButton
                            alt="CHOOSE COLOR OPTION"
                            src={colorSelectIcon}
                            onClick={() => {
                                setShowColorSelector('SATURATION');
                            }}
                        />
                    </label>
                    <div className="range-wrapper">
                        <InputRange
                            className={displayTheme ? "retro-terminal" : "default"}
                            value={saturationValue}
                            onChange={(event) => {
                                setSaturationValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="range"
                            targetPartIsGlow={targetPartIsGlow}
                            min={selectorMin}
                            max={saturationMax}
                            tintValue={tintValue}
                        />
                        <InputRange
                            value={saturationValue}
                            onChange={(event) => {
                                setSaturationValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="number"
                            min={selectorMin}
                            max={saturationMax}
                        />
                    </div>
                </InputRangeSelect>

                <InputRangeSelect className={displayTheme ? "retro-terminal" : "default"}>
                    <label htmlFor="brightness-select">
                        BRIGHTNESS
                        <ColorSelectorButton
                            alt="CHOOSE COLOR OPTION"
                            src={colorSelectIcon}
                            onClick={() => {
                                setShowColorSelector('BRIGHTNESS');
                            }}
                        />
                    </label>
                    <div className="range-wrapper">
                        <InputRange
                            className={displayTheme ? "retro-terminal" : "default"}
                            value={brightnessValue}
                            onChange={(event) => {
                                setBrightnessValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="range"
                            targetPartIsGlow={targetPartIsGlow}
                            min={selectorMin}
                            max={brightnessMax}
                            tintValue={tintValue}
                        />
                        <InputRange
                            value={brightnessValue}
                            onChange={(event) => {
                                setBrightnessValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="number"
                            min={selectorMin}
                            max={brightnessMax}
                        />
                    </div>
                </InputRangeSelect>

                <InputRangeSelect className={displayTheme ? "retro-terminal" : "default"}>
                    <label htmlFor="hue-select">
                        HUE
                        <ColorSelectorButton
                            alt="CHOOSE COLOR OPTION"
                            src={colorSelectIcon}
                            onClick={() => {
                                setShowColorSelector('HUE');
                            }}
                        />
                    </label>
                    <div className="range-wrapper">
                        <InputRange
                            className={displayTheme ? "retro-terminal" : "default"}
                            value={hueValue}
                            onChange={(event) => {
                                setColorValue(customColorIndex);
                                setHueValue(event.target.value);
                            }}
                            type="range"
                            targetPartIsGlow={targetPartIsGlow}
                            min={hueMin}
                            max={hueMax}
                            tintValue={tintValue}
                        />
                        <InputRange
                            value={hueValue}
                            onChange={(event) => {
                                setColorValue(customColorIndex);
                                setHueValue(event.target.value);
                            }}
                            type="number"
                            min={hueMin}
                            max={hueMax}
                        />
                    </div>
                </InputRangeSelect>

                <InputRangeSelect className={displayTheme ? "retro-terminal" : "default"}>
                    <label htmlFor="invert-select">
                        INVERT
                        <ColorSelectorButton
                            alt="CHOOSE COLOR OPTION"
                            src={colorSelectIcon}
                            onClick={() => {
                                setShowColorSelector('INVERT');
                            }}
                        />
                    </label>
                    <div className="range-wrapper">
                        <InputRange
                            className={displayTheme ? "retro-terminal" : "default"}
                            value={invertValue}
                            onChange={(event) => {
                                setInvertValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="range"
                            targetPartIsGlow={targetPartIsGlow}
                            min={selectorMin}
                            max={invertMax}
                            tintValue={tintValue}
                        />
                        <InputRange
                            value={invertValue}
                            onChange={(event) => {
                                setInvertValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="number"
                            min={selectorMin}
                            max={invertMax}
                        />
                    </div>
                </InputRangeSelect>

                <InputRangeSelect className={displayTheme ? "retro-terminal" : "default"}>
                    <label htmlFor="contrast-select">
                        CONTRAST
                        <ColorSelectorButton
                            alt="CHOOSE COLOR OPTION"
                            src={colorSelectIcon}
                            onClick={() => {
                                setShowColorSelector('CONTRAST');
                            }}
                        />
                    </label>
                    <div className="range-wrapper">
                        <InputRange
                            className={displayTheme ? "retro-terminal" : "default"}
                            value={contrastValue}
                            onChange={(event) => {
                                setContrastValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="range"
                            targetPartIsGlow={targetPartIsGlow}
                            min={selectorMin}
                            max={contrastMax}
                            tintValue={tintValue}
                        />
                        <InputRange
                            value={contrastValue}
                            onChange={(event) => {
                                setContrastValue(event.target.value);
                                setColorValue(customColorIndex);
                            }}
                            type="number"
                            min={selectorMin}
                            max={contrastMax}
                        />
                    </div>
                </InputRangeSelect>
            </div>
        </CustomColorControlsWrapper>
    );
}

CustomColorControls.propTypes = {
    displayTheme: bool,
    targetPartIsGlow: bool,
};

export default CustomColorControls;
