export default [
    {
        value: "0",
        label: "WHITE",
        hex: "#FFFFFF",
    },
    {
        value: "1",
        label: "BLACK",
        hex: "#000000",
    },
    {
        value: "2",
        label: "TAN",
        hex: "#CDC799",
    },
    {
        value: "3",
        label: "BROWN",
        hex: "#6A6048",
    },
    {
        value: "4",
        label: "RED",
        hex: "#861A18",
    },
    {
        value: "5",
        label: "DARK RED",
        hex: "#520200",
    },
    {
        value: "6",
        label: "YELLOW",
        hex: "#E4CB68",
    },
    {
        value: "7",
        label: "DARK YELLOW",
        hex: "#9B7E0B",
    },
    {
        value: "8",
        label: "LIGHT BLUE",
        hex: "#466EAA",
    },
    {
        value: "9",
        label: "DARK BLUE",
        hex: "#0B2D5F",
    },
    {
        value: "10",
        label: "LIGHT GREEN",
        hex: "#588056",
    },
    {
        value: "11",
        label: "DARK GREEN",
        hex: "#2D5B2B",
    },
];

export const OpacityList = [
    {
        value: "0",
        label: "0% (Transparent)",
        opacity: "00",
    },
    {
        value: "1",
        label: "25%",
        opacity: "40",
    },
    {
        value: "2",
        label: "50%",
        opacity: "80",
    },
    {
        value: "3",
        label: "75%",
        opacity: "BF",
    },
    {
        value: "4",
        label: "100% (Solid)",
        opacity: "FF",
    },
];
