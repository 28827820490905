import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Drake`;

// BODY
const bodyDrakeImage = `${imagePath}/Body Drake.png`;
const headDrakeCoverImage = `${imagePath}/Head Over.png`;

// HEADS
const headDrakeImage = `${imagePath}/heads/Head Drake.png`;
const headBearImage = `${imagePath}/heads/Head - Ursine mortus.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headParatrooperImage = `${imagePath}/heads/Drake_Head_Paratrooper.png`;
const headAnarchistImage = `${imagePath}/heads/Drake_Head_Anarchist.png`;
const headDragonImage = `${imagePath}/heads/Drake_Head_Dragon.png`;
const headMaoiImage = `${imagePath}/heads/Drake_Head_Maoi.png`;
// PREMIUM
const headAImage = `${imagePath}/heads/premium/Head A.png`;
const headBImage = `${imagePath}/heads/premium/Head B.png`;
const headCImage = `${imagePath}/heads/premium/Head C.png`;
const headDImage = `${imagePath}/heads/premium/Head D.png`;
const headEImage = `${imagePath}/heads/premium/Head E.png`;
const headFImage = `${imagePath}/heads/premium/Head F.png`;
const headGImage = `${imagePath}/heads/premium/Head G.png`;
const headHImage = `${imagePath}/heads/premium/Head H.png`;
const headIImage = `${imagePath}/heads/premium/Head I.png`;
const headJImage = `${imagePath}/heads/premium/Head J.png`;
const headKImage = `${imagePath}/heads/premium/Head K.png`;
const headLImage = `${imagePath}/heads/premium/Head L.png`;
const headMImage = `${imagePath}/heads/premium/Head M.png`;
const headNImage = `${imagePath}/heads/premium/Head N.png`;
const headOImage = `${imagePath}/heads/premium/Head O.png`;
const headClosedImage = `${imagePath}/heads/premium/Head Closed.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head - Cockpit closed.png`;
const headRumbleImage = `${imagePath}/heads/premium/Head - Rumble.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;

// REAR MOUNT
const rearMountLeftACImage = `${imagePath}/rear mount/Rear Left Assault Cannon.png`;
const rearMountLeftMissilesImage = `${imagePath}/rear mount/Rear Right Concussion Missiles.png`;
const rearMountACMissilesImage = `${imagePath}/rear mount/Rear Weapons AC Missiles.png`;

// 1H RIGHT
const assaultCannonRight1HImage = `${imagePath}/weapons/right/Right Weapon Assault Cannon.png`;
const shieldRight1HImage = `${imagePath}/weapons/right/Right Weapon Shield.png`;
const runicShieldRight1HImage = `${imagePath}/weapons/right/Weapon - Runic Shield.png`;
const riotShieldRight1HImage = `${imagePath}/weapons/right/Drake_Secondary_Riot Shield.png`;
const magCannonRight1HImage = `${imagePath}/weapons/right/Drake_Secondary_Mag Cannon.png`;

// 1H LEFT
const assaultCannonLeft1HImage = `${imagePath}/weapons/left/Left Weapon Assault Cannon.png`;
const assaultCannonDoubleLeft1HImage = `${imagePath}/weapons/left/Left Weapon Double Assault Cannon.png`;
const leviathanCannonLeft1HImage = `${imagePath}/weapons/left/Left Weapon Leviathan Cannon.png`;
const leviathanCannonBearLeft1HImage = `${imagePath}/weapons/left/Weapon - Leviathan Ursine.png`;
const flamethrowerLeft1HImage = `${imagePath}/weapons/left/Drake_Weapon_Flamethrower.png`;
const broadsiderLeft1HImage = `${imagePath}/weapons/left/Drake_Weapon_Broadsider.png`;
const dragonFlamethrowerLeft1HImage = `${imagePath}/weapons/left/Drake_Weapon_Dragon Flamethrower.png`;
const entrenchmentToolLeft1HImage = `${imagePath}/weapons/left/Drake_Weapon_Entrenchment Tool.png`;

// ACCESSORY
const accessoryBearCloakImage = `${imagePath}/accessory/Accessory - Bear Cloak.png`;
const accessoryBearFeetImage = `${imagePath}/accessory/Accessory - Bear Feet.png`;
const accessoryMagCannonGlowImage = `${imagePath}/accessory/Drake_Accessories_Mag Cannon Glow.png`;
const accessoryChassisDetail1Image = `${imagePath}/accessory/chassis-DRAKE-mid1.png`;
const accessoryChassisDetail2Image = `${imagePath}/accessory/chassis-DRAKE-mid2.png`;
const accessoryChassisDetailBothImage = `${imagePath}/accessory/chassis-DRAKE-mid12.png`;

const coreImage = `${imagePath}/Drake (by Corvon).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "56px",
    right: "30px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Alrækinn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "1",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DRAKE",
            src: headDrakeImage,
        },
        {
            value: 1,
            label: "URSINE MORTUS",
            src: headBearImage,
            removeBodyCover: true,
            originalArtCredit: {
                title: "Theo",
                url: "https://twitter.com/TheotimeGM",
            },
        },
        {
            value: 2,
            label: "ANARCHIST",
            src: headAnarchistImage,
            removeBodyCover: true,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "DRAGON",
            foremostSrc: headDragonImage,
            removeBodyCover: true,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "MAOI",
            src: headMaoiImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "PARATROOPER",
            src: headParatrooperImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 6,
            label: "KAZU",
            src: headKazuImage,
            removeBodyCover: true,
        },
        {
            value: 7,
            label: "FACTORY STANDARD",
            src: headAImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "PALADIN",
            src: headBImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "OGRE",
            src: headCImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "ROGUE",
            src: headDImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "BASTION",
            src: headEImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "SCARAB",
            src: headGImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "RAMSHACKLE",
            src: headHImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "SLIPSTREAM",
            src: headIImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "BUNKER",
            src: headJImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "DUNGAM",
            src: headKImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "TALON",
            src: headLImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "TORTUGA",
            src: headMImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "JUDICATOR",
            src: headNImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "RUMBLE",
            src: headOImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "CLOSED",
            src: headClosedImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 22,
            label: "COCKPIT - CLOSED",
            src: headCockpitImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 23,
            label: "RUMBLE - CLOSED",
            src: headRumbleImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 24,
            label: "ICARUS",
            src: headFImage,
            isPremium: true,
        },
        {
            value: 25,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DRAKE",
            src: bodyDrakeImage,
            frontSrc: headDrakeCoverImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT CANNON",
                src: assaultCannonLeft1HImage,
            },
            {
                value: 1,
                label: "DOUBLE AC",
                src: assaultCannonDoubleLeft1HImage,
            },
            {
                value: 2,
                label: "LEVIATHAN CANNON",
                src: leviathanCannonLeft1HImage,
            },
            {
                value: 3,
                label: "LEVIATHAN CANNON - URSINE",
                src: leviathanCannonBearLeft1HImage,
                originalArtCredit: {
                    title: "Theo",
                    url: "https://twitter.com/TheotimeGM",
                },
            },
            {
                value: 4,
                label: "FLAMETHROWER",
                src: flamethrowerLeft1HImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 5,
                label: "DRAGON FLAMETHROWER",
                src: dragonFlamethrowerLeft1HImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 6,
                label: "BROADSIDER",
                src: broadsiderLeft1HImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 7,
                label: "ENTRENCHMENT TOOL",
                src: entrenchmentToolLeft1HImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ASSAULT CANNON",
            src: assaultCannonRight1HImage,
        },
        {
            value: 1,
            label: "SHIELD",
            src: shieldRight1HImage,
        },
        {
            value: 2,
            label: "RUNIC SHIELD",
            src: runicShieldRight1HImage,
            originalArtCredit: {
                title: "Theo",
                url: "https://twitter.com/TheotimeGM",
            },
        },
        {
            value: 3,
            label: "RIOT SHIELD",
            src: riotShieldRight1HImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "MAG CANNON",
            src: magCannonRight1HImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "ASSAULT CANNON",
            frontSrc: rearMountLeftACImage,
        },
        {
            value: 1,
            label: "MISSILES",
            frontSrc: rearMountLeftMissilesImage,
        },
        {
            value: 2,
            label: "AC + MISSILES",
            frontSrc: rearMountACMissilesImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "URSINE CLOAK",
            src: accessoryBearCloakImage,
            originalArtCredit: {
                title: "Theo",
                url: "https://twitter.com/TheotimeGM",
            },
        },
        {
            value: 1,
            label: "URSINE FEET",
            src: accessoryBearFeetImage,
            originalArtCredit: {
                title: "Theo",
                url: "https://twitter.com/TheotimeGM",
            },
        },
        {
            value: 2,
            label: "MAG CANNON - GLOW",
            frontSrc: accessoryMagCannonGlowImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "COLOR - CHASSIS DETAILS 1",
            src: accessoryChassisDetail1Image,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "COLOR - CHASSIS DETAILS 2",
            src: accessoryChassisDetail2Image,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "COLOR - CHASSIS DETAILS 1 + 2",
            src: accessoryChassisDetailBothImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
