import React, { useContext, useEffect, useState } from "react";
import { func, object } from "prop-types";
import styled from "styled-components";

import {
    UnitCategoryWrapper,
    UnitCategoryHeader,
    UnitCategoryHeaderText,
    UnitContainer,
} from "./../UnitCategory";
import {
    SelectDropdown,
    IndexSelectors,
    IndexSelector,
    CustomMechForm,
    InterfaceButton,
    InterfaceControls,
} from "./components/MechCategoryStyles";
import ContainerWrapper from "../components/ContainerWrapper";
import Unit from "../../styles/Unit";
import MechCustomCategoryDisplay from "./MechCustomCategoryDisplay";
import CustomColorControls from "./components/CustomColorControls";

import { AnalyticsContext } from "./../../../Analytics/AnalyticsContextWrapper";
import { UserAuthContext } from "./../../../User/UserAuthContextWrapper";
import { CurrentThemeContext } from "./../../../common/ThemeContextWrapper";
import { CustomColorContext } from "../../CustomColorContextWrapper";
import { UnitContext } from "./../../UnitContextWrapper";
import { safeName } from "./../../Print/PrintAnalytics";

import Mechs from "./../../data/Mechs-GMS";
import CustomMechTemplates from "./components/CustomMechTemplates";
import ColorList from "../components/ColorList";

import MembershipTierImages from "./../../../common/MembershipTierImages";
import ExportMechButton from "./components/ExportMechButton";
import ExportMechPartsButton from "./components/ExportMechPartsButton";
import ExportAllMechPartsButton from "./components/ExportAllMechPartsButton";
import ExportAllMechPartsAndTintsButton from "./components/ExportAllMechPartsAndTintsButton";

import MechUnitImage from "./../../styles/MechUnitImage";
import ZoomWrapper from "../components/ZoomWrapper";
import BaseURL from "./../../../Unit/data/images/Hosting-BaseURL";
import ArtCreditLink from "../components/ArtCreditLink";
import MechsGMS from "./../../data/Mechs-GMS";
import { MechLogoSprite } from "./MechBrandedCategory";
import SpriteImage, { SPRITE_KEYS } from "../../../common/styles/SpriteImage";

const previewImage = `${BaseURL}/buttons/Color-Sample-Orb.png`;
const colorSampleSquareMetalImage = `${BaseURL}/buttons/Color-Sample-Square-18.png`;

const MechUnitContainer = styled(UnitContainer)`
    padding: 5px 0 10px;
    block-size: fit-content;
    align-items: center;
    overflow-y: unset;
    justify-content: space-around;
    margin-right: 10px;
`;

MechUnitContainer.displayName = "MechGMSUnitContainer";

export const FlexBreak = styled.div`
    flex-basis: 100%;
    height: 0;
`;

FlexBreak.displayName = "FlexBreak";

const categoryTierImage = MembershipTierImages.mundaneTier.orb
const categoryTierImageAlt = MembershipTierImages.mundaneTier.alt;
const categoryClassname = "seasonal-category mech-category";
const stances = [
    "1H",
    "2H",
    "heavy",
];

const templateList = [
    {
        label: "FACTORY STANDARD",
        value: CustomMechTemplates.unitDefaultValues,
    },
    {
        label: "VANGUARD",
        value: CustomMechTemplates.unitVanguardValues,
    },
    {
        label: "ASSAULT",
        value: CustomMechTemplates.unitAssaultValues,
    },
    {
        label: "SNIPER",
        value: CustomMechTemplates.unitSniperValues,
    },
    {
        label: "HACKER",
        value: CustomMechTemplates.unitHackerValues,
    },
    {
        label: "SUPPORT",
        value: CustomMechTemplates.unitSupportValues,
    },
    {
        label: "PALADIN",
        value: CustomMechTemplates.makeTemplateValues(1),
    },
    {
        label: "OGRE",
        value: CustomMechTemplates.makeTemplateValues(2),
    },
    {
        label: "ROGUE",
        value: CustomMechTemplates.makeTemplateValues(3),
    },
    {
        label: "BASTION",
        value: CustomMechTemplates.makeTemplateValues(4),
    },
    {
        label: "ICARUS",
        value: CustomMechTemplates.makeTemplateValues(5),
    },
    {
        label: "SCARAB",
        value: CustomMechTemplates.makeTemplateValues(6),
    },
    {
        label: "RAMSHACKLE",
        value: CustomMechTemplates.makeTemplateValues(7),
    },
    {
        label: "FRONTLINE",
        value: CustomMechTemplates.unitFrontlineValues,
    },
    {
        label: "TANK",
        value: CustomMechTemplates.unitTankValues,
    },
    {
        label: "TURRET",
        value: CustomMechTemplates.unitTurretValues,
    },
    {
        label: "LUMBER PUPPY",
        value: CustomMechTemplates.unitLumberPuppyValues,
    },
    {
        label: "SCOUT",
        value: CustomMechTemplates.unitScoutValues,
    },
    {
        label: "PANGOLIN",
        value: CustomMechTemplates.unitPangolinValues,
    },
];

export function getRandomIntInclusive(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function MechCustomCategory({ setShowPremiumPopupFunc, unitCategory }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const [randomLockChassis, setRandomLockChassis] = useState(false);
    const [randomLockLeg, setRandomLockLeg] = useState(false);
    const [randomLockArmLeft, setRandomLockArmLeft] = useState(false);
    const [randomLockArmRight, setRandomLockArmRight] = useState(false);
    const [randomLockHead, setRandomLockHead] = useState(false);
    const [randomLockWeapon, setRandomLockWeapon] = useState(false);
    const [randomLockSecondaryWeapon, setRandomLockSecondaryWeapon] = useState(false);
    const [randomLockRearMount, setRandomLockRearMount] = useState(false);
    const [randomLockAccessory1, setRandomLockAccessory1] = useState(false);
    const [randomLockAccessory2, setRandomLockAccessory2] = useState(false);
    const [randomLockAccessory3, setRandomLockAccessory3] = useState(false);
    const [randomLockAccessory4, setRandomLockAccessory4] = useState(false);

    const [templateValue, setTemplateValue] = useState(0);
    const [targetPartValue, setTargetPartValue] = useState(0);

    const [currentUnitIndex, setCurrentUnitIndex] = useState(0);
    const [unitState, setUnitState] = useState([
        JSON.parse(CustomMechTemplates.unitDefaultValues),
        JSON.parse(CustomMechTemplates.unitDefaultValues),
        JSON.parse(CustomMechTemplates.unitDefaultValues),
        JSON.parse(CustomMechTemplates.unitDefaultValues),
        JSON.parse(CustomMechTemplates.unitDefaultValues),
    ]);

    const ReactGA = useContext(AnalyticsContext);
    const { user } = useContext(UserAuthContext);
    const { addUnit } = useContext(UnitContext);
    const { displayTheme } = useContext(CurrentThemeContext);
    const {
        colorValue,
        displayCustomControls,
        tintValue,
        handleColorOptionChange,
        getColorTint,
    } = useContext(CustomColorContext);

    const isPremiumUser = user && user.isPremiumUser;
    // const isLegendaryUser = user && user.isLegendaryUser;

    useEffect(() => {
        if (isExpanded) {
            ReactGA.event({
                category: "Unit Category",
                action: "View Category",
                label: unitCategory.title
            });
        }
    }, [isExpanded, ReactGA, unitCategory.title]);

    function randomFunction() {
        const stanceValue = stances[getRandomIntInclusive(0, stances.length - 1)];

        let weaponValue = getRandomIntInclusive(0, Mechs.weapon[stanceValue].length - 1);
        let weaponSelectionValue = Mechs.weapon[stanceValue][weaponValue].value;

        let armLeftValue = 0;
        let armRightValue = 0;

        if (stanceValue === "1H") {
            armLeftValue = getRandomIntInclusive(0, Mechs.arms.length - 1);
            armRightValue = getRandomIntInclusive(0, Mechs.arms.length - 1);
        } else {
            armLeftValue = getRandomIntInclusive(0, Mechs.arms.filter(arm => !arm.isMounted).length - 1);
            armRightValue = getRandomIntInclusive(0, Mechs.arms.filter(arm => !arm.isMounted).length - 1);
        }

        if (Mechs.arms[armRightValue].disabled) {
            armRightValue += 1;
        }

        if (Mechs.arms[armLeftValue].disabled) {
            armLeftValue += 1;
        }

        if (Mechs.arms[armRightValue].isMounted) {
            weaponValue = "NONE";
            weaponSelectionValue = "NONE-1H";
        }

        if (weaponSelectionValue === "0") {
            weaponValue = "1";
            weaponSelectionValue = `1-${stanceValue}`;
        }

        const secondaryWeaponValue = stanceValue === "1H" && !Mechs.arms[armLeftValue].isMounted ?
            getRandomIntInclusive(0, Mechs.secondaryWeapon.length - 1) : "NONE";

        const randomUnitValues = {
            chassisValue: getRandomIntInclusive(0, Mechs.chassis.length - 1),
            legsValue: getRandomIntInclusive(0, Mechs.legs.length - 1),
            armLeftValue,
            armRightValue,
            stanceValue,
            headValue: getRandomIntInclusive(0, Mechs.head.length - 1),
            rearMountValue: getRandomIntInclusive(0, Mechs.rearMount.length - 1),
            weaponValue,
            secondaryWeaponValue,
            weaponSelectionValue,
            accessory1Value: getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, Mechs.accessory.length - 1) : "NONE",
            accessory2Value: getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, Mechs.accessory.length - 1) : "NONE",
            accessory3Value: getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, Mechs.accessory.length - 1) : "NONE",
            accessory4Value: getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, Mechs.accessory.length - 1) : "NONE",
        };

        if (randomLockChassis) {
            delete randomUnitValues.chassisValue;
        }

        if (randomLockHead) {
            delete randomUnitValues.headValue;
        }

        if (randomLockLeg) {
            delete randomUnitValues.legsValue;
        }

        if (randomLockArmLeft) {
            delete randomUnitValues.armLeftValue;
        }

        if (randomLockArmRight) {
            delete randomUnitValues.armRightValue;
        }

        if (randomLockWeapon) {
            delete randomUnitValues.weaponValue;
            delete randomUnitValues.stanceValue;
            delete randomUnitValues.weaponSelectionValue;

            if (unitState[currentUnitIndex].stanceValue === "2H" || unitState[currentUnitIndex].stanceValue === "heavy") {
                delete randomUnitValues.secondaryWeaponValue;

                if (Mechs.arms[armRightValue].isMounted) {
                    delete randomUnitValues.armRightValue;
                }

                if (Mechs.arms[armLeftValue].isMounted) {
                    delete randomUnitValues.armLeftValue;
                }
            }
        }

        if (randomLockSecondaryWeapon) {
            delete randomUnitValues.secondaryWeaponValue;
        }

        if (randomLockRearMount) {
            delete randomUnitValues.rearMountValue;
        }

        if (randomLockAccessory1) {
            delete randomUnitValues.accessory1Value;
        }

        if (randomLockAccessory2) {
            delete randomUnitValues.accessory2Value;
        }

        if (randomLockAccessory3) {
            delete randomUnitValues.accessory3Value;
        }

        if (randomLockAccessory4) {
            delete randomUnitValues.accessory4Value;
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, randomUnitValues);
            }

            return unit;
        }));
    }

    function paintFunction() {
        const updatedValues = {};
        const targetPart = Mechs.partList[targetPartValue].value;
        let color = getColorTint();

        switch (targetPart) {
            case "BODY":
                updatedValues.chassisTintValue = color;
                updatedValues.legsTintValue = color;
                updatedValues.armLeftTintValue = color;
                updatedValues.armRightTintValue = color;
                updatedValues.headTintValue = color;
                break;

            case "WEAPONS":
                updatedValues.weaponTintValue = color;
                updatedValues.secondaryWeaponTintValue = color;
                break;

            case "ACCESSORIES":
                updatedValues.accessory1TintValue = color;
                updatedValues.accessory2TintValue = color;
                updatedValues.accessory3TintValue = color;
                updatedValues.accessory4TintValue = color;
                break;

            case "ALL":
                updatedValues.chassisTintValue = color;
                updatedValues.legsTintValue = color;
                updatedValues.armLeftTintValue = color;
                updatedValues.armRightTintValue = color;
                updatedValues.headTintValue = color;
                updatedValues.rearMountTintValue = color;
                updatedValues.weaponTintValue = color;
                updatedValues.secondaryWeaponTintValue = color;
                updatedValues.accessory1TintValue = color;
                updatedValues.accessory2TintValue = color;
                updatedValues.accessory3TintValue = color;
                updatedValues.accessory4TintValue = color;
                break;

            default:
                updatedValues[`${targetPart}TintValue`] = color;
                break;
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, updatedValues);
            }

            return unit;
        }));
    }

    function handleChange(value, valueName) {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, {
                    [valueName]: value,
                });
            }

            return unit;
        }));
    }

    function handleWeaponChange(event) {
        const values = event.target.value.split("-");

        const updatedValues = {
            weaponValue: values[0],
            stanceValue: values[1],
            weaponSelectionValue: event.target.value,
        };

        if (values[1] !== "1H") {
            updatedValues.secondaryWeaponValue = "NONE";
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, updatedValues);
            }

            return unit;
        }));
    }

    function handleLeftArmChange(event) {
        const updatedValues = {
            armLeftValue: event.target.value,
        };

        if (event.target.value === "NONE" || Mechs.arms[event.target.value].isMounted) {
            updatedValues.weaponValue = "NONE";
            updatedValues.secondaryWeaponValue = "NONE";
            updatedValues.weaponSelectionValue = "NONE-1H";
            updatedValues.stanceValue = "1H";
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, updatedValues);
            }

            return unit;
        }));
    }

    function handleRightArmChange(event) {
        const updatedValues = {
            armRightValue: event.target.value,
        };

        if (event.target.value === "NONE" || Mechs.arms[event.target.value].isMounted) {
            updatedValues.weaponValue = "NONE";
            updatedValues.secondaryWeaponValue = "NONE";
            updatedValues.weaponSelectionValue = "NONE-1H";
            updatedValues.stanceValue = "1H";
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, updatedValues);
            }

            return unit;
        }));
    }

    function resetFunction() {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                setRandomLockChassis(false);
                setRandomLockLeg(false);
                setRandomLockArmLeft(false);
                setRandomLockArmRight(false);
                setRandomLockHead(false);
                setRandomLockWeapon(false);
                setRandomLockSecondaryWeapon(false);
                setRandomLockRearMount(false);
                setRandomLockAccessory1(false);
                setRandomLockAccessory2(false);
                setRandomLockAccessory3(false);
                setRandomLockAccessory4(false);

                return JSON.parse(CustomMechTemplates.unitDefaultValues);
            }

            return unit;
        }));
    }

    function handleTemplateChange(unitValues) {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return JSON.parse(unitValues);
            }

            return unit;
        }));
    }

    function addUnitFunction() {
        const mechLabel = `
            MECH
            C-${Mechs.chassis[unitState[currentUnitIndex].chassisValue].label}
            H-${unitState[currentUnitIndex].headValue !== "NONE" ? Mechs.head[unitState[currentUnitIndex].headValue].label : "NONE"}
            LA-${unitState[currentUnitIndex].armLeftValue !== "NONE" ? Mechs.arms[unitState[currentUnitIndex].armLeftValue].label : "NONE"}
            RA-${unitState[currentUnitIndex].armRightValue !== "NONE" ? Mechs.arms[unitState[currentUnitIndex].armRightValue].label : "NONE"}
            L-${Mechs.legs[unitState[currentUnitIndex].legsValue].label}
            R-${unitState[currentUnitIndex].rearMountValue !== "NONE" ? Mechs.rearMount[unitState[currentUnitIndex].rearMountValue].label : "NONE"}
            P-${unitState[currentUnitIndex].weaponSelectionValue}
            S-${unitState[currentUnitIndex].secondaryWeaponValue !== "NONE" ? Mechs.secondaryWeapon[unitState[currentUnitIndex].secondaryWeaponValue].label : "NONE"}
            A1-${unitState[currentUnitIndex].accessory1Value !== "NONE" ? Mechs.accessory[unitState[currentUnitIndex].accessory1Value].label : "NONE"}
            A2-${unitState[currentUnitIndex].accessory2Value !== "NONE" ? Mechs.accessory[unitState[currentUnitIndex].accessory2Value].label : "NONE"}
            A3-${unitState[currentUnitIndex].accessory3Value !== "NONE" ? Mechs.accessory[unitState[currentUnitIndex].accessory3Value].label : "NONE"}
            A4-${unitState[currentUnitIndex].accessory4Value !== "NONE" ? Mechs.accessory[unitState[currentUnitIndex].accessory4Value].label : "NONE"}
        `;

        const variantsArray = [Object.assign({}, unitState[currentUnitIndex], { isExtraLarge: true })];

        ReactGA.event({
            category: "Unit",
            action: "Add Unit",
            label: safeName(mechLabel),
        });

        return addUnit(Object.assign({ variantsArray, currentVariant: 0, id: `${mechLabel}-${Date.now()}`, shadowDetails: Mechs.legs[unitState[currentUnitIndex].legsValue].shadowDetails, mechUnit: true, mechLabel }));
    };

    const chassisOptions = Mechs.chassis.map((chassisOption) => (
        <option
            key={`${chassisOption.value}-${chassisOption.label}`}
            value={chassisOption.value}
            disabled={chassisOption.isPremium && !isPremiumUser}
        >
            {`${chassisOption.label} ${chassisOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const headOptions = Mechs.head.map((headOption) => (
        <option
            key={`${headOption.value}-${headOption.label}`}
            value={headOption.value}
            disabled={headOption.isPremium && !isPremiumUser}
        >
            {`${headOption.label} ${headOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const legOptions = Mechs.legs.map((legOption) => (
        <option
            key={`${legOption.value}-${legOption.label}`}
            value={legOption.value}
            disabled={legOption.isPremium && !isPremiumUser}
        >
            {`${legOption.label} ${legOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const armOptions = Mechs.arms.map((armOption) => (
        <option
            key={`${armOption.value}-${armOption.label}`}
            value={armOption.value}
            disabled={(armOption.isPremium && !isPremiumUser) || armOption.disabled}
        >
            {`${armOption.label} ${armOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const rearMountOptions = Mechs.rearMount.map((rearMountOption) => (
        <option
            key={`${rearMountOption.value}-${rearMountOption.label}`}
            value={rearMountOption.value}
            disabled={rearMountOption.isPremium && !isPremiumUser}
        >
            {`${rearMountOption.label} ${rearMountOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const weaponOptions = [].concat(Mechs.weapon["1H"], Mechs.weapon["2H"], Mechs.weapon.heavy).map((weaponOption) => (
        <option
            key={`${weaponOption.value}-${weaponOption.label}`}
            value={weaponOption.value}
            disabled={
                (weaponOption.isPremium && !isPremiumUser) ||
                weaponOption.disabled ||
                (unitState[currentUnitIndex].armLeftValue === "NONE" && weaponOption.value.split("-")[1] !== "1H") ||
                (unitState[currentUnitIndex].armLeftValue !== "NONE" && Mechs.arms[unitState[currentUnitIndex].armLeftValue].isMounted && weaponOption.value.split("-")[1] !== "1H")
            }
        >
            {`${weaponOption.label} ${weaponOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const secondaryWeaponOptions = Mechs.secondaryWeapon.map((weaponOption) => (
        <option
            key={`${weaponOption.value}-${weaponOption.label}`}
            value={weaponOption.value}
            disabled={weaponOption.isPremium && !isPremiumUser}
        >
            {`${weaponOption.label} ${weaponOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const accessoryOptions = Mechs.accessory.map((accessoryOption) => (
        <option
            key={`${accessoryOption.value}-${accessoryOption.label}`}
            value={accessoryOption.value}
            disabled={accessoryOption.isPremium && !isPremiumUser}
        >
            {`${accessoryOption.label} ${accessoryOption.isPlusTwo ? "*" : ""}`}
        </option>
    ));

    const unitIndexSelectors = unitState.map((unit, index) => (
        <IndexSelector
            key={`unit-index-selector-${index}`}
            className={displayTheme ? "retro-terminal" : "default"}
            onClick={() => setCurrentUnitIndex(index)}
            isActive={currentUnitIndex === index}
            title={`Temporary Config ${index + 1}`}
        >
            {index + 1}
        </IndexSelector>
    ));

    const targetPartListOptions = Mechs.partList.map((part, index) => (
        <option
            key={`paint-${part.value}-${part.label}`}
            value={index}
        >
            {part.label}
        </option>
    ));

    const colorListOptions = ColorList.map((color, index) => (
        <option
            key={`${color.value}-${color.label}`}
            value={index}
        >
            {color.label}
        </option>
    ));

    const templateListOptions = templateList.map((template, index) => (
        <option
            key={`template--${template.label}`}
            value={index}
        >
            {template.label}
        </option>
    ));

    return (
        <UnitCategoryWrapper
            className={categoryClassname}
            isExpanded={isExpanded}
            numColumnsVisible={4}
            borderPrimaryColor={unitCategory.borderPrimaryColor}
            borderSecondaryColor={unitCategory.borderSecondaryColor}
        >
            <UnitCategoryHeader
                className={categoryClassname}
                onClick={() => setIsExpanded(!isExpanded)}
                title={unitCategory.description}
            >
                <MechLogoSprite
                    className={`${categoryClassname} ${unitCategory.logoImage || categoryTierImage}`}
                    alt="Units Rarity"
                    title={categoryTierImageAlt}
                />
                <UnitCategoryHeaderText alt={unitCategory.label} className={categoryClassname}>{unitCategory.label}</UnitCategoryHeaderText>
            </UnitCategoryHeader>
            <ContainerWrapper>
                <MechUnitContainer
                    className={categoryClassname}
                    themeBackgroundPrimaryColor={displayTheme ? unitCategory.retroTerminalBackgroundColor : unitCategory.themeBackgroundPrimaryColor}
                    themeBackgroundSecondaryColor={displayTheme ? unitCategory.retroTerminalBackgroundSecondaryColor : unitCategory.themeBackgroundSecondaryColor}
                    isExpanded={isExpanded}
                    numColumnsVisible={4}
                    borderPrimaryColor={unitCategory.borderPrimaryColor}
                    borderSecondaryColor={unitCategory.borderSecondaryColor}
                    displayCustomControls={displayCustomControls}
                >
                    {isExpanded ? (
                        <CustomMechForm>
                            <div className="options-selectors">
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="template-select">
                                        TEMPLATE
                                    </label>
                                    <select
                                        id="template-select"
                                        name="template"
                                        onChange={(event) => {
                                            handleTemplateChange(templateList[event.target.value].value);

                                            return setTemplateValue(event.target.value)
                                        }}
                                        value={templateValue}
                                    >
                                        {templateListOptions}
                                    </select>
                                </SelectDropdown>
                                <ArtCreditLink unit={unitState[currentUnitIndex]} brandList={MechsGMS} isCustomCategory />

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="head-select">
                                        <span>HEAD</span>
                                        <img
                                            alt="HEAD Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].headTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockHead ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockHead ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockHead(!randomLockHead)}
                                        />
                                    </label>
                                    <select
                                        id="head-select"
                                        name="head"
                                        onChange={(event) => handleChange(event.target.value, "headValue")}
                                        value={unitState[currentUnitIndex].headValue}
                                    >
                                        <option value="NONE">NONE</option>
                                        {headOptions}
                                    </select>
                                </SelectDropdown>
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={unitState[currentUnitIndex].armRightValue === "NONE" || Mechs.arms[unitState[currentUnitIndex].armRightValue].isMounted}
                                >
                                    <label htmlFor="weapon-select">
                                        <span>MAIN WEAPON</span>
                                        <img
                                            alt="WEAPON Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].weaponTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockWeapon ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockWeapon ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockWeapon(!randomLockWeapon)}
                                        />
                                    </label>
                                    <select
                                        id="weapon-select"
                                        name="weapon"
                                        onChange={handleWeaponChange}
                                        value={unitState[currentUnitIndex].weaponSelectionValue}
                                        disabled={unitState[currentUnitIndex].armRightValue === "NONE" || Mechs.arms[unitState[currentUnitIndex].armRightValue].isMounted}
                                    >
                                        <option value="NONE-1H">NONE</option>
                                        {weaponOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="chassis-select">
                                        <span>CHASSIS</span>
                                        <img
                                            alt="CHASSIS Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].chassisTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockChassis ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockChassis ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockChassis(!randomLockChassis)}
                                        />
                                    </label>
                                    <select
                                        id="chassis-select"
                                        name="chassis"
                                        onChange={(event) => handleChange(event.target.value, "chassisValue")}
                                        value={unitState[currentUnitIndex].chassisValue}
                                    >
                                        <option value="NONE">NONE</option>
                                        {chassisOptions}
                                    </select>
                                </SelectDropdown>
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={unitState[currentUnitIndex].stanceValue !== "1H" ||
                                        unitState[currentUnitIndex].armLeftValue === "NONE" ||
                                        Mechs.arms[unitState[currentUnitIndex].armLeftValue].isMounted
                                    }
                                >
                                    <label htmlFor="secondary-weapon-select">
                                        <span>AUX WEAPON</span>
                                        <img
                                            alt="AUX WEAPON Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].secondaryWeaponTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockSecondaryWeapon ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockSecondaryWeapon ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockSecondaryWeapon(!randomLockSecondaryWeapon)}
                                        />
                                    </label>
                                    <select
                                        id="secondary-weapon-select"
                                        name="secondary weapon"
                                        onChange={(event) => handleChange(event.target.value, "secondaryWeaponValue")}
                                        value={unitState[currentUnitIndex].secondaryWeaponValue}
                                        disabled={unitState[currentUnitIndex].stanceValue !== "1H" ||
                                            unitState[currentUnitIndex].armLeftValue === "NONE" ||
                                            Mechs.arms[unitState[currentUnitIndex].armLeftValue].isMounted
                                        }
                                    >
                                        <option value="NONE">NONE</option>
                                        {secondaryWeaponOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="left-arm-select">
                                        <span>LEFT ARM</span>
                                        <img
                                            alt="LEFT ARM Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].armLeftTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockArmLeft ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockArmLeft ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockArmLeft(!randomLockArmLeft)}
                                        />
                                    </label>
                                    <select
                                        id="left-arm-select"
                                        name="arm"
                                        onChange={handleLeftArmChange}
                                        value={unitState[currentUnitIndex].armLeftValue}
                                    >
                                        <option value="NONE">NONE</option>
                                        {armOptions}
                                    </select>
                                </SelectDropdown>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="right-arm-select">
                                        <span>RIGHT ARM</span>
                                        <img
                                            alt="RIGHT ARM Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].armRightTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockArmRight ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockArmRight ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockArmRight(!randomLockArmRight)}
                                        />
                                    </label>
                                    <select
                                        id="right-arm-select"
                                        name="arm"
                                        onChange={handleRightArmChange}
                                        value={unitState[currentUnitIndex].armRightValue}
                                    >
                                        <option value="NONE">NONE</option>
                                        {armOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="legs-select">
                                        <span>LEGS</span>
                                        <img
                                            alt="LEGS Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].legsTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockLeg ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockLeg ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockLeg(!randomLockLeg)}
                                        />
                                    </label>
                                    <select
                                        id="legs-select"
                                        name="legs"
                                        onChange={(event) => handleChange(event.target.value, "legsValue")}
                                        value={unitState[currentUnitIndex].legsValue}
                                    >
                                        {legOptions}
                                    </select>
                                </SelectDropdown>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="rear-mount-select">
                                        <span>REAR MOUNT</span>
                                        <img
                                            alt="REAR MOUNT Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].rearMountTintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockRearMount ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockRearMount ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockRearMount(!randomLockRearMount)}
                                        />
                                    </label>
                                    <select
                                        id="rear-mount-select"
                                        name="rear mount"
                                        onChange={(event) => handleChange(event.target.value, "rearMountValue")}
                                        value={unitState[currentUnitIndex].rearMountValue}
                                    >
                                        <option value="NONE">NONE</option>
                                        {rearMountOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="accessory-1-select">
                                        <span>ACCESSORY 1</span>
                                        <img
                                            alt="ACCESSORY 1 Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].accessory1TintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockAccessory1 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockAccessory1 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockAccessory1(!randomLockAccessory1)}
                                        />
                                    </label>
                                    <select
                                        id="accessory-1-select"
                                        name="accessory1"
                                        onChange={(event) => handleChange(event.target.value, "accessory1Value")}
                                        value={unitState[currentUnitIndex].accessory1Value}
                                    >
                                        <option value="NONE">NONE</option>
                                        {accessoryOptions}
                                    </select>
                                </SelectDropdown>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="accessory-2-select">
                                        <span>ACCESSORY 2</span>
                                        <img
                                            alt="ACCESSORY 2 Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].accessory2TintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockAccessory2 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockAccessory2 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockAccessory2(!randomLockAccessory2)}
                                        />
                                    </label>
                                    <select
                                        id="accessory-2-select"
                                        name="accessory2"
                                        onChange={(event) => handleChange(event.target.value, "accessory2Value")}
                                        value={unitState[currentUnitIndex].accessory2Value}
                                    >
                                        <option value="NONE">NONE</option>
                                        {accessoryOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="accessory-3-select">
                                        <span>ACCESSORY 3</span>
                                        <img
                                            alt="ACCESSORY 3 Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].accessory3TintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockAccessory3 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockAccessory3 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockAccessory3(!randomLockAccessory3)}
                                        />
                                    </label>
                                    <select
                                        id="accessory-3-select"
                                        name="accessory3"
                                        onChange={(event) => handleChange(event.target.value, "accessory3Value")}
                                        value={unitState[currentUnitIndex].accessory3Value}
                                    >
                                        <option value="NONE">NONE</option>
                                        {accessoryOptions}
                                    </select>
                                </SelectDropdown>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="accessory-4-select">
                                        <span>ACCESSORY 4</span>
                                        <img
                                            alt="ACCESSORY 4 Color Preview"
                                            src={colorSampleSquareMetalImage}
                                            style={{
                                                filter: unitState[currentUnitIndex].accessory4TintValue,
                                            }}
                                        />
                                        <SpriteImage
                                            className={randomLockAccessory4 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockAccessory4 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockAccessory4(!randomLockAccessory4)}
                                        />
                                    </label>
                                    <select
                                        id="accessory-4-select"
                                        name="accessory4"
                                        onChange={(event) => handleChange(event.target.value, "accessory4Value")}
                                        value={unitState[currentUnitIndex].accessory4Value}
                                    >
                                        <option value="NONE">NONE</option>
                                        {accessoryOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="color-select">
                                        COLOR
                                    </label>
                                    <select
                                        id="color-select"
                                        name="color"
                                        onChange={handleColorOptionChange}
                                        value={colorValue}
                                    >
                                        {colorListOptions}
                                    </select>
                                    <MechUnitImage
                                        style={{ top: "30px", left: "25px", width: "21px", height: "21px", border: "none" }}
                                        tintValue={tintValue}
                                        src={previewImage}
                                        alt="paint-preview"
                                        zIndex={"10"}
                                    />
                                </SelectDropdown>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label htmlFor="part-select">
                                        COLOR TARGET
                                    </label>
                                    <select
                                        id="part-select"
                                        name="part"
                                        onChange={(event) => setTargetPartValue(event.target.value)}
                                        value={targetPartValue}
                                    >
                                        {targetPartListOptions}
                                    </select>
                                </SelectDropdown>
                            </div>
                            <div className="display-controls">
                                <Unit
                                    onContextMenu={(e) => e.preventDefault()}
                                    isExtraLarge
                                >
                                    <ZoomWrapper>
                                        <MechCustomCategoryDisplay
                                            unit={unitState[currentUnitIndex]}
                                            isInCategory
                                        />
                                    </ZoomWrapper>
                                </Unit>
                                <IndexSelectors>
                                    {unitIndexSelectors}
                                </IndexSelectors>
                                <InterfaceControls>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={resetFunction}
                                    >
                                        RESET
                                    </InterfaceButton>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={randomFunction}
                                    >
                                        RANDOM
                                    </InterfaceButton>

                                    <ExportMechButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={unitCategory.title}
                                        mechType={"CUSTOM"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <ExportMechPartsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={unitCategory.title}
                                        mechType={"CUSTOM"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <ExportAllMechPartsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={unitCategory.title}
                                        mechType={"CUSTOM"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <ExportAllMechPartsAndTintsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={unitCategory.title}
                                        mechType={"CUSTOM"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                    />

                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={paintFunction}
                                    >
                                        APPLY COLOR
                                    </InterfaceButton>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={addUnitFunction}
                                    >
                                        FABRICATE
                                    </InterfaceButton>
                                </InterfaceControls>
                            </div>

                            {displayCustomControls ?
                                <CustomColorControls
                                    displayTheme={displayTheme}
                                /> : null
                            }
                        </CustomMechForm>
                    ) : null}
                </MechUnitContainer>
            </ContainerWrapper>
        </UnitCategoryWrapper>
    )
}

MechCustomCategory.propTypes = {
    setShowPremiumPopupFunc: func.isRequired,
    unitCategory: object.isRequired,
};

export default MechCustomCategory;
