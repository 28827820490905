import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// CENTER
const centerAntiArmorImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/Center - Anti-Armor.png`;
const centerEnergyWeaponsImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/Center - Energy Weapons.png`;
const centerMarksmenImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/Center - Marksmen.png`;
const centerRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/Center - Riflemen A.png`;
const centerRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/Center - Riflemen B.png`;
const centerRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/Center - Riflemen C.png`;

// EAST
const eastAntiArmorImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/E - Anti-Armor.png`;
const eastEnergyWeaponsImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/E - Energy Weapons.png`;
const eastMarksmenImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/E - Marksmen.png`;
const eastRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/E - Riflemen A.png`;
const eastRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/E - Riflemen B.png`;
const eastRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/E - Riflemen C.png`;

// SW
const swAntiArmorImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/SW - Anti-Armor.png`;
const swEnergyWeaponsImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/SW - Energy Weapons.png`;
const swMarksmenImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/SW - Marksmen.png`;
const swRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/SW - Riflemen A.png`;
const swRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/SW - Riflemen B.png`;
const swRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/SW - Riflemen C.png`;

// NORTH
const northAntiArmorImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/North - Anti-Armor.png`;
const northEnergyWeaponsImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/North - Energy Weapons.png`;
const northMarksmenImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/North - Marksmen.png`;
const northRifleAImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/North - Riflemen A.png`;
const northRifleBImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/North - Riflemen B.png`;
const northRifleCImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/North - Riflemen C.png`;

const previewImage = `${BaseURL}/Mechs/Misc/Infantry/10 Man Squad/preview.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    chassisHasNone: true,
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "5",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "3",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "RIFLE A",
            src: northRifleAImage,
        },
        {
            value: 1,
            label: "RIFLE B",
            src: northRifleBImage,
        },
        {
            value: 2,
            label: "RIFLE C",
            src: northRifleCImage,
        },
        {
            value: 3,
            label: "ANTI-ARMOR",
            src: northAntiArmorImage,
        },
        {
            value: 4,
            label: "ENERGY WEAPONS",
            src: northEnergyWeaponsImage,
        },
        {
            value: 5,
            label: "MARKSMEN",
            src: northMarksmenImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "RIFLE A",
            src: eastRifleAImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 1,
            label: "RIFLE B",
            src: eastRifleBImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 2,
            label: "RIFLE C",
            src: eastRifleCImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 3,
            label: "ANTI-ARMOR",
            src: eastAntiArmorImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 4,
            label: "ENERGY WEAPONS",
            src: eastEnergyWeaponsImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 5,
            label: "MARKSMEN",
            src: eastMarksmenImage,
            shadowDetails: shadowDetails,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "RIFLE A",
                src: swRifleAImage,
            },
            {
                value: 1,
                label: "RIFLE B",
                src: swRifleBImage,
            },
            {
                value: 2,
                label: "RIFLE C",
                src: swRifleCImage,
            },
            {
                value: 3,
                label: "ANTI-ARMOR",
                src: swAntiArmorImage,
            },
            {
                value: 4,
                label: "ENERGY WEAPONS",
                src: swEnergyWeaponsImage,
            },
            {
                value: 5,
                label: "MARKSMEN",
                src: swMarksmenImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "RIFLE A",
            src: centerRifleAImage,
        },
        {
            value: 1,
            label: "RIFLE B",
            src: centerRifleBImage,
        },
        {
            value: 2,
            label: "RIFLE C",
            src: centerRifleCImage,
        },
        {
            value: 3,
            label: "ANTI-ARMOR",
            src: centerAntiArmorImage,
        },
        {
            value: 4,
            label: "ENERGY WEAPONS",
            src: centerEnergyWeaponsImage,
        },
        {
            value: 5,
            label: "MARKSMEN",
            src: centerMarksmenImage,
        },
    ],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "NORTH",
            value: "head",
        },
        {
            label: "CENTER",
            value: "secondaryWeapon",
        },
        {
            label: "SW",
            value: "weapon",
        },
        {
            label: "EAST",
            value: "chassis",
        },
    ],
    labels: {
        "head": "NORTH",
        "chassis": "EAST",
        "rearMount": "REAR MOUNT",
        "weapon": "SW",
        "secondaryWeapon": "CENTER",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
