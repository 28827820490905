import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyWhiteWitchImage = `${BaseURL}/Mechs/SSC/White Witch/Chassis.png`;

// HEADS
const headWhiteWitchImage = `${BaseURL}/Mechs/SSC/White Witch/heads/Head - White Witch.png`;
const headKazuImage = `${BaseURL}/Mechs/SSC/White Witch/heads/Head - Kazu.png`;
// PREMIUM
const headChimeraImage = `${BaseURL}/Mechs/SSC/White Witch/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${BaseURL}/Mechs/SSC/White Witch/heads/premium/Head - Cyclops.png`;
const headKrakenImage = `${BaseURL}/Mechs/SSC/White Witch/heads/premium/Head - Kraken.png`;
const headLaborerImage = `${BaseURL}/Mechs/SSC/White Witch/heads/premium/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/SSC/White Witch/heads/premium/Head - Malice.png`;
const headCockpitImage = `${BaseURL}/Mechs/SSC/White Witch/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/SSC/White Witch/heads/premium/Head - Scopehound.png`;

// WEAPONS
const weaponsShieldLanceImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/primary/Weapon - Shield _ Lance.png`;
const weaponsShieldACImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/primary/Weapon - Shield _ Assault Cannon.png`;
const weaponsShieldFlameImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/primary/Weapon - Shield and Flamethrower.png`;
// LEGENDARY
const weaponsAdaptiveAssaultImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/primary/legendary/Weapon - Adaptive Assault.png`;

// FERROFLUID
const secondaryWeaponsShieldLanceImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/secondary/Weapon - Shield _ Lance Ferrofluid.png`;
const secondaryWeaponsShieldACImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/secondary/Weapon - Shield _ Assault Cannon Ferrofluid.png`;
const secondaryWeaponsShieldFlameImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/secondary/Weapon - Shield and Flamethrower Ferrofluid.png`;
// LEGENDARY
const secondaryWeaponsAdaptiveAssaultImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/secondary/legendary/Weapon - Adaptive Assault Ferrofluid.png`;

// REAR MOUNT
const rearMountRetortLoopImage = `${BaseURL}/Mechs/SSC/White Witch/weapons/Heavy Weapon - Retort Loop.png`;

// ACCESSORIES
// PREMIUM
const accessoryFlyKicksImage = `${BaseURL}/Mechs/SSC/White Witch/accessories/premium/Accessory - Fly Kicks.png`;

const coreImage = `${BaseURL}/Mechs/SSC/White Witch/White Witch (by Hit-Monkey).png`;

const previewImage = `${BaseURL}/Mechs/SSC/White Witch/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "32px",
    right: "70px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Hit-Monkey",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weaponSpecial: "secondaryWeaponValue",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "WHITE WITCH",
            foremostSrc: headWhiteWitchImage,
        },
        {
            value: 1,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 2,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "KRAKEN",
            foremostSrc: headKrakenImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "COCKPIT",
            foremostSrc: headCockpitImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "WHITE WITCH",
            src: bodyWhiteWitchImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SHIELD + LANCE",
                src: weaponsShieldLanceImage,
            },
            {
                value: 1,
                label: "SHIELD + ASSAULT CANNON",
                src: weaponsShieldACImage,
            },
            {
                value: 2,
                label: "SHIELD + FLAMETHROWER",
                src: weaponsShieldFlameImage,
            },
            {
                value: 3,
                label: "ADAPTIVE ASSAULT",
                src: weaponsAdaptiveAssaultImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SHIELD + LANCE",
            src: secondaryWeaponsShieldLanceImage,
        },
        {
            value: 1,
            label: "SHIELD + ASSAULT CANNON",
            src: secondaryWeaponsShieldACImage,
        },
        {
            value: 2,
            label: "SHIELD + FLAMETHROWER",
            src: secondaryWeaponsShieldFlameImage,
        },
        {
            value: 3,
            label: "ADAPTIVE ASSAULT",
            src: secondaryWeaponsAdaptiveAssaultImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "RETORT LOOP",
            foremostSrc: rearMountRetortLoopImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FLY KICKS",
            src: accessoryFlyKicksImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "FERROFLUID",
            value: "secondaryWeapon",
        },
        {
            label: "RETORT LOOP",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "RETORT LOOP",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "FERROFLUID",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
