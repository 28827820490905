import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyGnollImage = `${BaseURL}/Mechs/Custom/Gnoll/Chassis - Gray.png`;
const bodyGnollColoredImage = `${BaseURL}/Mechs/Custom/Gnoll/Chassis - Colored.png`;

// ACCESSORIES
const shoulderSkullImage = `${BaseURL}/Mechs/Custom/Gnoll/accessories/Accessory - Skull.png`;
const shoulderCloakImage = `${BaseURL}/Mechs/Custom/Gnoll/accessories/Accessory - Cloak Gray.png`;

// GLOWS
const glowDefaultImage = `${BaseURL}/Mechs/Custom/Gnoll/glows/Glow - Default.png`;
const glowBaseImage = `${BaseURL}/Mechs/Custom/Gnoll/glows/Glow - Base.png`;
const glowBluePurpImage = `${BaseURL}/Mechs/Custom/Gnoll/glows/Glow - Blue Purp.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Gnoll/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "63px",
    right: "58px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SKULL",
            foremostSrc: shoulderSkullImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GNOLL",
            src: bodyGnollImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Flintwyrm + Tianyi Liu",
                url: "https://www.artstation.com/drazelic",
            },
        },
        {
            value: 1,
            label: "GNOLL - COLORED",
            src: bodyGnollColoredImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Flintwyrm + Tianyi Liu",
                url: "https://www.artstation.com/drazelic",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLOAK - COLOR",
                src: shoulderCloakImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DEFAULT",
            frontSrc: glowDefaultImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BASE",
            frontSrc: glowBaseImage,
        },
        {
            value: 1,
            label: "BLUE + PURPLE",
            frontSrc: glowBluePurpImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "SHOULDER",
            value: "head",
        },
        {
            label: "CLOAK",
            value: "weapon",
        },
        {
            label: "BASE GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "SHOULDER",
        "chassis": "CHASSIS",
        "rearMount": "CLOAK",
        "weapon": "CLOAK",
        "secondaryWeapon": "BASE GLOW",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
