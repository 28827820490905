import React, { useContext, useEffect, useState } from "react";
import { func, object } from "prop-types";
import styled from "styled-components";

import {
    UnitCategoryWrapper,
    UnitCategoryHeader,
    UnitCategoryHeaderText,
    UnitContainer,
} from "./../UnitCategory";
import {
    SelectDropdown,
    IndexSelectors,
    IndexSelector,
    MechForm,
    InterfaceButton,
    InterfaceControls,
} from "./components/MechCategoryStyles";
import ContainerWrapper from "../components/ContainerWrapper";
import Unit from "./../../styles/Unit";
import MechBrandedCategoryDisplay from "./MechBrandedCategoryDisplay";
import MechBrandedFrameDisplay from "./MechBrandedFrameDisplay";
import CustomColorControls from "./components/CustomColorControls";
import ExportMechButton from "./components/ExportMechButton";
import ExportMechPartsButton from "./components/ExportMechPartsButton";
import ExportAllMechPartsButton from "./components/ExportAllMechPartsButton";
import ExportAllMechPartsAndTintsButton from "./components/ExportAllMechPartsAndTintsButton";
import { brandList } from "../../LayerData/MechBrandedLayerOrder";

import { AnalyticsContext } from "./../../../Analytics/AnalyticsContextWrapper";
import { UserAuthContext } from "./../../../User/UserAuthContextWrapper";
import { CurrentThemeContext } from "./../../../common/ThemeContextWrapper";
import { UnitContext } from "./../../UnitContextWrapper";
import { CustomColorContext } from "../../CustomColorContextWrapper";
import { safeName } from "./../../Print/PrintAnalytics";

import ColorList from "../components/ColorList";
import { getRandomIntInclusive } from "./MechCustomCategory";

import MembershipTierImages from "./../../../common/MembershipTierImages";

import MechUnitImage from "./../../styles/MechUnitImage";
import ZoomWrapper from "../components/ZoomWrapper";
import BaseURL from "./../../../Unit/data/images/Hosting-BaseURL";
import { shadowTypes } from "../../styles/UnitShadow";
import { premiumTiersList } from "../PremiumPopup";
import ArtCreditLink from "../components/ArtCreditLink";
import SpriteImage, { SPRITE_KEYS } from "../../../common/styles/SpriteImage";

const backArrowWhiteImage = `${BaseURL}/buttons/back_arrow_white.png`;
const glowPreviewImage = `${BaseURL}/buttons/Color-Sample-Orb-Glow.png`;
const metalPreviewImage = `${BaseURL}/buttons/Color-Sample-Orb.png`;
const colorSampleSquareGlowImage = `${BaseURL}/buttons/Color-Sample-Square-Glow-18.png`;
const colorSampleSquareMetalImage = `${BaseURL}/buttons/Color-Sample-Square-18.png`;

const MechUnitContainer = styled(UnitContainer)`
    align-items: center;
    overflow-y: unset;
    justify-content: space-around;
    margin-right: 10px;

    ${({noFrameSelected}) => noFrameSelected ? `
        height: 500px;

        @media only screen and (max-width: ${({theme}) => theme.tabletCutoffWidth}px) {
            height: 946px;
        }

        @media only screen and (max-width: ${({theme}) => theme.mobileCutoffWidth}px) {
            height: 1177px;
        }
    ` : `
        padding: 5px 0 10px;
        block-size: fit-content;
    `}
`;

MechUnitContainer.displayName = "MechBrandedUnitContainer";

export const MechLogoSprite = styled(SpriteImage)`
    max-width: 100%;
    margin-right: 15px;
    cursor: pointer;

    &.mech-category {
        margin-left: 10px;
    }

    &.hide-image {
        visibility: hidden;
    }
`;

MechLogoSprite.displayName = "MechLogoSprite";

const categoryTierImage = MembershipTierImages.mundaneTier.orb
const categoryTierImageAlt = MembershipTierImages.mundaneTier.alt;
const categoryClassname = "seasonal-category mech-category";

function MechBrandedCategory({ setShowPremiumPopupFunc, unitCategory }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const [isCoreImageView, setIsCoreImageView] = useState(false);

    const [randomLockChassis, setRandomLockChassis] = useState(false);
    const [randomLockHead, setRandomLockHead] = useState(false);
    const [randomLockWeapon, setRandomLockWeapon] = useState(false);
    const [randomLockSecondaryWeapon, setRandomLockSecondaryWeapon] = useState(false);
    const [randomLockRearMount, setRandomLockRearMount] = useState(false);
    const [randomLockAccessory1, setRandomLockAccessory1] = useState(false);
    const [randomLockAccessory2, setRandomLockAccessory2] = useState(false);
    const [randomLockAccessory3, setRandomLockAccessory3] = useState(false);
    const [randomLockAccessory4, setRandomLockAccessory4] = useState(false);

    const [targetPartValue, setTargetPartValue] = useState(0);

    const [currentUnitIndex, setCurrentUnitIndex] = useState(0);
    const [unitState, setUnitState] = useState([
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
        Object.assign({}, JSON.parse(unitCategory.partList[0].defaultValues), { frameValue: null, brandValue: unitCategory.title }),
    ]);

    const ReactGA = useContext(AnalyticsContext);
    const { user } = useContext(UserAuthContext);
    const { addUnit } = useContext(UnitContext);
    const { displayTheme } = useContext(CurrentThemeContext);
    const {
        colorValue,
        displayCustomControls,
        tintValue,
        handleColorOptionChange,
        getColorTint,
    } = useContext(CustomColorContext);

    const isPremiumUser = user && user.isPremiumUser;
    const isLegendaryUser = user && user.isLegendaryUser;

    useEffect(() => {
        if (isExpanded) {
            ReactGA.event({
                category: "Unit Category",
                action: "View Category",
                label: unitCategory.title
            });
        }
    }, [isExpanded, ReactGA, unitCategory.title]);

    if (unitState[currentUnitIndex].frameValue === null) {
        return (
            <UnitCategoryWrapper
                className={categoryClassname}
                isExpanded={isExpanded}
                numColumnsVisible={4}
                isExtraTall={false}
                borderPrimaryColor={unitCategory.borderPrimaryColor}
                borderSecondaryColor={unitCategory.borderSecondaryColor}
            >
                <UnitCategoryHeader
                    className={categoryClassname}
                    onClick={() => setIsExpanded(!isExpanded)}
                    title={unitCategory.description}
                >
                    <MechLogoSprite
                        className={`${categoryClassname} ${unitCategory.logoImage || `${categoryTierImage} hide-image`}`}
                        alt="Units Rarity"
                        title={categoryTierImageAlt}
                    />
                    <UnitCategoryHeaderText alt={unitCategory.label} className={categoryClassname}>{unitCategory.label}</UnitCategoryHeaderText>
                </UnitCategoryHeader>
                <ContainerWrapper>
                    <MechUnitContainer
                        className={categoryClassname}
                        themeBackgroundPrimaryColor={displayTheme ? unitCategory.retroTerminalBackgroundColor : unitCategory.themeBackgroundPrimaryColor}
                        themeBackgroundSecondaryColor={displayTheme ? unitCategory.retroTerminalBackgroundSecondaryColor : unitCategory.themeBackgroundSecondaryColor}
                        isExpanded={isExpanded}
                        numColumnsVisible={4}
                        isExtraTall={false}
                        borderPrimaryColor={unitCategory.borderPrimaryColor}
                        borderSecondaryColor={unitCategory.borderSecondaryColor}
                        displayCustomControls={displayCustomControls}
                        noFrameSelected={true}
                    >
                        <MechBrandedFrameDisplay
                            setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                            setFrameValueFunc={(value) => handleChange(value, "frameValue")}
                            partList={unitCategory.partList}
                            isPremiumUser={isPremiumUser}
                            isLegendaryUser={isLegendaryUser}
                            displayTheme={displayTheme}
                        />
                    </MechUnitContainer>
                </ContainerWrapper>
            </UnitCategoryWrapper>
        );
    }

    function iterateCoreImageView() {
        if (isCoreImageView === false) {
            setIsCoreImageView(0);
        } else if (isCoreImageView + 1 < unitCategory.partList[unitState[currentUnitIndex].frameValue].coreImg.length) {
            setIsCoreImageView(isCoreImageView + 1)
        } else {
            setIsCoreImageView(false);
        }
    }

    function randomFunction() {
        const isStanceSpecial = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeaponIsChassisStanceSpecial;
        const hasMultipleStances = unitCategory.partList[unitState[currentUnitIndex].frameValue].hasMultipleStances;

        let stanceValue = "1H";
        let chassisAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.filter(item => !item.isPremium).length;
        let chassisValue;

        if (isStanceSpecial) {
            chassisValue = getRandomIntInclusive(0, chassisAmount - 1);
            stanceValue = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis[chassisValue].label;
        }

        if (hasMultipleStances) {
            stanceValue = getRandomIntInclusive(0, Object.keys(unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon).length - 1);
        }

        let headAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].head.filter(item => !item.isPremium).length;
        let rearMountAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMount.filter(item => !item.isPremium).length;
        let weaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon[stanceValue].filter(item => !item.isPremium).length;
        let secondaryWeaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.filter(item => !item.isPremium).length;
        let accessoryAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].accessory.filter(item => !item.isPremium).length;

        if (isPremiumUser) {
            chassisAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.filter(item => !item.isLegendary).length;
            headAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].head.filter(item => !item.isLegendary).length;
            rearMountAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMount.filter(item => !item.isLegendary).length;
            weaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon[stanceValue].filter(item => !item.isLegendary).length;
            secondaryWeaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.filter(item => !item.isLegendary).length;
            accessoryAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].accessory.filter(item => !item.isLegendary).length;
        }

        if (isLegendaryUser) {
            chassisAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.length;
            headAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].head.length;
            rearMountAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMount.length;
            weaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon[stanceValue].length;
            secondaryWeaponAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.length;
            accessoryAmount = unitCategory.partList[unitState[currentUnitIndex].frameValue].accessory.length;
        }

        if (!isStanceSpecial) {
            chassisValue = getRandomIntInclusive(0, chassisAmount - 1);
        }

        const randomUnitValues = {
            chassisValue,
            headValue: headAmount ? getRandomIntInclusive(0, headAmount - 1) : "NONE",
            rearMountValue: rearMountAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, rearMountAmount - 1) : "NONE" : "NONE",
            weaponValue: weaponAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, weaponAmount - 1) : "NONE" : "NONE",
            secondaryWeaponValue: secondaryWeaponAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, secondaryWeaponAmount - 1) : "NONE" : "NONE",
            accessory1Value: accessoryAmount ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, accessoryAmount - 1) : "NONE" : "NONE",
            accessory2Value: accessoryAmount > 1 ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, accessoryAmount - 1) : "NONE" : "NONE",
            accessory3Value: accessoryAmount > 1 ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, accessoryAmount - 1) : "NONE" : "NONE",
            accessory4Value: accessoryAmount > 1 ? getRandomIntInclusive(0, 1) ? getRandomIntInclusive(0, accessoryAmount - 1) : "NONE" : "NONE",
            stanceValue,
            brandValue: unitCategory.title,
        };

        if (unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone) {
            if (randomUnitValues.chassisValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.chassis) {
                randomUnitValues.chassisValue = 0;
            }

            if (randomUnitValues.headValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.head) {
                randomUnitValues.headValue = 0;
            }

            if (randomUnitValues.rearMountValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.rearMount) {
                randomUnitValues.rearMountValue = 0;
            }

            if (randomUnitValues.weaponValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.weapon) {
                randomUnitValues.weaponValue = 0;
            }

            if (randomUnitValues.secondaryWeaponValue === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.secondaryWeapon) {
                randomUnitValues.secondaryWeaponValue = 0;
            }

            if (randomUnitValues.accessory1Value === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory) {
                randomUnitValues.accessory1Value = 0;
            }

            if (randomUnitValues.accessory2Value === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory) {
                randomUnitValues.accessory2Value = 0;
            }

            if (randomUnitValues.accessory3Value === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory) {
                randomUnitValues.accessory3Value = 0;
            }

            if (randomUnitValues.accessory4Value === "NONE" && unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory) {
                randomUnitValues.accessory4Value = 0;
            }
        }

        if (randomLockChassis) {
            delete randomUnitValues.chassisValue;
        }

        if (randomLockHead) {
            delete randomUnitValues.headValue;
        }

        if (randomLockWeapon) {
            delete randomUnitValues.weaponValue;
        }

        if (randomLockSecondaryWeapon) {
            delete randomUnitValues.secondaryWeaponValue;
        }

        if (randomLockRearMount) {
            delete randomUnitValues.rearMountValue;
        }

        if (randomLockAccessory1) {
            delete randomUnitValues.accessory1Value;
        }

        if (randomLockAccessory2) {
            delete randomUnitValues.accessory2Value;
        }

        if (randomLockAccessory3) {
            delete randomUnitValues.accessory3Value;
        }

        if (randomLockAccessory4) {
            delete randomUnitValues.accessory4Value;
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, randomUnitValues);
            }

            return unit;
        }));
    }

    function paintFunction() {
        const updatedValues = {};
        const targetPart = unitCategory.partList[unitState[currentUnitIndex].frameValue].partList[targetPartValue].value;
        let color = getColorTint();

        switch (targetPart) {
            case "BODY":
                updatedValues.chassisTintValue = color;
                updatedValues.headTintValue = color;
                break;

            case "WEAPONS":
                updatedValues.weaponTintValue = color;
                updatedValues.secondaryWeaponTintValue = color;
                break;

            case "ACCESSORIES":
                updatedValues.accessory1TintValue = color;
                updatedValues.accessory2TintValue = color;
                updatedValues.accessory3TintValue = color;
                updatedValues.accessory4TintValue = color;
                break;

            case "ALL":
                updatedValues.chassisTintValue = color;
                updatedValues.headTintValue = color;
                updatedValues.rearMountTintValue = color;
                updatedValues.weaponTintValue = color;
                updatedValues.secondaryWeaponTintValue = color;
                updatedValues.accessory1TintValue = color;
                updatedValues.accessory2TintValue = color;
                updatedValues.accessory3TintValue = color;
                updatedValues.accessory4TintValue = color;
                break;

            default:
                updatedValues[`${targetPart}TintValue`] = color;
                break;
        }

        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, updatedValues);
            }

            return unit;
        }));
    }

    function handleChange(value, valueName) {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                if (valueName === "frameValue") {
                    if (value !== null) {
                        return Object.assign({}, JSON.parse(unitCategory.partList[value].defaultValues), { frameValue: value, brandValue: unitCategory.title });
                    } else {
                        return Object.assign({}, { frameValue: value, brandValue: unitCategory.title });
                    }
                }

                return Object.assign({}, unit, {
                    [valueName]: value,
                });
            }

            return unit;
        }));
    }

    function handleMultipleChanges(valuesObject) {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                return Object.assign({}, unit, valuesObject);
            }

            return unit;
        }));
    }

    function resetFunction() {
        return setUnitState(unitState.map((unit, index) => {
            if (index === currentUnitIndex) {
                setRandomLockChassis(false);
                setRandomLockHead(false);
                setRandomLockWeapon(false);
                setRandomLockSecondaryWeapon(false);
                setRandomLockRearMount(false);
                setRandomLockAccessory1(false);
                setRandomLockAccessory2(false);
                setRandomLockAccessory3(false);
                setRandomLockAccessory4(false);
                setIsCoreImageView(false);

                return Object.assign(
                    {},
                    JSON.parse(unitCategory.partList[unitState[currentUnitIndex].frameValue].defaultValues),
                    {
                        frameValue: unitState[currentUnitIndex].frameValue,
                        brandValue: unitCategory.title
                    },
                );
            }

            return unit;
        }));
    }

    function addUnitFunction() {
        const mechLabel = `MECH ${unitCategory.title} ${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`;

        const variantsArray = [
            Object.assign(
                {},
                unitState[currentUnitIndex],
                {
                    isExtraLarge: true,
                    isMegadeus: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus,
                    isMegadeusPlus: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus,
                    isMegadeusPlusPlus: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus,
                    isMegadeusOmega: unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega,
                    viewCoreImage: isCoreImageView,
                }
            )
        ];

        ReactGA.event({
            category: "Unit",
            action: "Add Unit",
            label: safeName(mechLabel),
        });

        let shadowDetails = unitState[currentUnitIndex].chassisValue !== "NONE" ?
            unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis[unitState[currentUnitIndex].chassisValue].shadowDetails :
            {
                size: shadowTypes.NONE,
                bottom: "0px",
                right: "0px",
            };

        if (unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMountsAreLegs) {
            shadowDetails = unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMount[unitState[currentUnitIndex].rearMountValue].shadowDetails;
        }

        return addUnit(Object.assign({
            variantsArray,
            currentVariant: 0,
            id: `${mechLabel}-${Date.now()}`,
            shadowDetails,
            brandedMechUnit: true,
            mechLabel,
        }));
    };

    const chassisOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis.map((chassisOption) => (
        <option
            key={`${chassisOption.value}-${chassisOption.label}`}
            value={chassisOption.value}
            disabled={
                (chassisOption.isPremium && !isPremiumUser) ||
                (chassisOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${chassisOption.label} ${chassisOption.isPlusTwo ? "*" : ""}${chassisOption.isPremium ? "●" : ""}${chassisOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const headOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].head.map((headOption) => (
        <option
            key={`${headOption.value}-${headOption.label}`}
            value={headOption.value}
            disabled={
                (headOption.isPremium && !isPremiumUser) ||
                (headOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${headOption.label} ${headOption.isPlusTwo ? "*" : ""}${headOption.isPremium ? "●" : ""}${headOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const rearMountOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMount.map((rearMountOption) => (
        <option
            key={`${rearMountOption.value}-${rearMountOption.label}`}
            value={rearMountOption.value}
            disabled={
                (rearMountOption.isPremium && !isPremiumUser) ||
                (rearMountOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${rearMountOption.label} ${rearMountOption.isPlusTwo ? "*" : ""}${rearMountOption.isPremium ? "●" : ""}${rearMountOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const weaponOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].weapon[unitState[currentUnitIndex].stanceValue].map((weaponOption) => (
        <option
            key={`${weaponOption.value}-${weaponOption.label}`}
            value={weaponOption.value}
            disabled={
                (weaponOption.isPremium && !isPremiumUser) ||
                (weaponOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${weaponOption.label} ${weaponOption.isPlusTwo ? "*" : ""}${weaponOption.isPremium ? "●" : ""}${weaponOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const secondaryWeaponOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeapon.map((weaponOption) => (
        <option
            key={`${weaponOption.value}-${weaponOption.label}`}
            value={weaponOption.value}
            disabled={
                (weaponOption.isPremium && !isPremiumUser) ||
                (weaponOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${weaponOption.label} ${weaponOption.isPlusTwo ? "*" : ""}${weaponOption.isPremium ? "●" : ""}${weaponOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const accessoryOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].accessory.map((accessoryOption) => (
        <option
            key={`${accessoryOption.value}-${accessoryOption.label}`}
            value={accessoryOption.value}
            disabled={
                (accessoryOption.isPremium && !isPremiumUser) ||
                (accessoryOption.isLegendary && !isLegendaryUser)
            }
        >
            {`${accessoryOption.label} ${accessoryOption.isPlusTwo ? "*" : ""}${accessoryOption.isPremium ? "●" : ""}${accessoryOption.isLegendary ? "●" : ""}`}
        </option>
    ));

    const unitIndexSelectors = unitState.map((unit, index) => (
        <IndexSelector
            key={`unit-index-selector-${index}`}
            className={displayTheme ? "retro-terminal" : "default"}
            onClick={() => {
                setIsCoreImageView(false);
                setTargetPartValue(0);
                setCurrentUnitIndex(index)
            }}
            isActive={currentUnitIndex === index}
            title={`Temporary Config ${index + 1}`}
        >
            {index + 1}
        </IndexSelector>
    ));

    const targetPartListOptions = unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.map((part, index) => (
        <option
            key={`paint-${part.value}-${part.label}`}
            value={index}
        >
            {part.label}
        </option>
    ));

    const colorListOptions = ColorList.map((color, index) => (
        <option
            key={`${color.value}-${color.label}`}
            value={index}
        >
            {color.label}
        </option>
    ));

    const targetPartIsGlow = !!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList[targetPartValue].isGlow;

    return (
        <UnitCategoryWrapper
            className={categoryClassname}
            isExpanded={isExpanded}
            numColumnsVisible={4}
            isExtraTall={
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus ||
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus ||
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus ||
                unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega
            }
            borderPrimaryColor={unitCategory.borderPrimaryColor}
            borderSecondaryColor={unitCategory.borderSecondaryColor}
        >
            <UnitCategoryHeader
                className={categoryClassname}
                onClick={() => setIsExpanded(!isExpanded)}
                title={unitCategory.description}
            >
                <MechLogoSprite
                    className={`${categoryClassname} ${unitCategory.logoImage || "hide-image"}`}
                    alt="Units Rarity"
                    title={categoryTierImageAlt}
                />
                <UnitCategoryHeaderText alt={unitCategory.label} className={categoryClassname}>{unitCategory.label}</UnitCategoryHeaderText>
            </UnitCategoryHeader>
            <ContainerWrapper>
                <MechUnitContainer
                    className={categoryClassname}
                    themeBackgroundPrimaryColor={displayTheme ? unitCategory.retroTerminalBackgroundColor : unitCategory.themeBackgroundPrimaryColor}
                    themeBackgroundSecondaryColor={displayTheme ? unitCategory.retroTerminalBackgroundSecondaryColor : unitCategory.themeBackgroundSecondaryColor}
                    isExpanded={isExpanded}
                    numColumnsVisible={4}
                    isExtraTall={
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus ||
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus ||
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus ||
                        unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega
                    }
                    borderPrimaryColor={unitCategory.borderPrimaryColor}
                    borderSecondaryColor={unitCategory.borderSecondaryColor}
                    displayCustomControls={displayCustomControls}
                >
                    {isExpanded ? (
                        <MechForm>
                            <div className="options-selectors">
                                <div>
                                    <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                        <label
                                            htmlFor="frame-select"
                                            onClick={() => {
                                                setIsCoreImageView(false);
                                                handleChange(null, "frameValue");
                                                setTargetPartValue(0);
                                            }}
                                            style={{ cursor: "pointer" }}
                                        >
                                            <img
                                                className="back"
                                                alt="Back"
                                                src={backArrowWhiteImage}
                                            />
                                            &nbsp;FRAME SELECT
                                        </label>
                                    </SelectDropdown>
                                    &nbsp;
                                    <ArtCreditLink unit={unitState[currentUnitIndex]} brandList={brandList} isCoreImageView={isCoreImageView} />
                                </div>
                                <SelectDropdown className={displayTheme ? "retro-terminal" : "default"}>
                                    <label style={{ cursor: "pointer" }} onClick={() => setShowPremiumPopupFunc(premiumTiersList.PLUS_ONE)}>
                                        ●&nbsp;&nbsp;&nbsp;+1 TIER
                                    </label>
                                    <label style={{ cursor: "pointer" }} onClick={() => setShowPremiumPopupFunc(premiumTiersList.PLUS_TWO)}>
                                        *&nbsp;&nbsp;&nbsp;+2 TIER
                                    </label>
                                    <label style={{ cursor: "pointer" }} onClick={() => setShowPremiumPopupFunc(premiumTiersList.LEGENDARY)}>
                                        ●●&nbsp;LEGEND TIER
                                    </label>
                                </SelectDropdown>
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!headOptions.length || typeof isCoreImageView === "number"}
                                >
                                    <label htmlFor="head-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["head"]}</span>
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "head").length < 1 || !headOptions.length || typeof isCoreImageView === "number"
                                            ? null
                                            : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["head"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "head")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].headTintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockHead ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockHead ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockHead(!randomLockHead)}
                                        />
                                    </label>
                                    <select
                                        id="head-select"
                                        name="head"
                                        onChange={(event) => {
                                            if (unitCategory.partList[unitState[currentUnitIndex].frameValue].headSpecial) {
                                                return handleMultipleChanges({
                                                    [unitCategory.partList[unitState[currentUnitIndex].frameValue].headSpecial]: event.target.value,
                                                    headValue: event.target.value,
                                                });
                                            }

                                            if (unitCategory.partList[unitState[currentUnitIndex].frameValue].headTemplateSpecial) {
                                                let returnObject = {};

                                                unitCategory.partList[unitState[currentUnitIndex].frameValue].headTemplateSpecial.forEach(templateValue => {
                                                    returnObject[templateValue] = event.target.value;
                                                })

                                                returnObject.headValue = event.target.value;

                                                return handleMultipleChanges(returnObject);
                                            }

                                            return handleChange(event.target.value, "headValue");
                                        }}
                                        value={unitState[currentUnitIndex].headValue}
                                        disabled={!headOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.head ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {headOptions}
                                    </select>
                                </SelectDropdown>

                                {unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMountsAreChassis ?
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!chassisOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        <label htmlFor="chassis-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]}</span>
                                            {!chassisOptions.length || typeof isCoreImageView === "number"
                                                ? null
                                                : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "chassis")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].chassisTintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockChassis ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockChassis ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockChassis(!randomLockChassis)}
                                            />
                                        </label>
                                        <select
                                            id="chassis-select"
                                            name="chassis"
                                            onChange={unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMountsAreChassisSpecial ?
                                                (event) => handleMultipleChanges({
                                                    [unitCategory.partList[unitState[currentUnitIndex].frameValue].rearMountsAreChassisSpecial]: event.target.value,
                                                    weaponValue: "NONE",
                                                    chassisValue: event.target.value,
                                                }) :
                                                (event) => handleChange(event.target.value, "chassisValue")
                                            }
                                            value={unitState[currentUnitIndex].chassisValue}
                                            disabled={!chassisOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].chassisHasNone ?
                                                <option value="NONE">NONE</option> : null
                                            }
                                            {chassisOptions}
                                        </select>
                                    </SelectDropdown> :
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!rearMountOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        <label htmlFor="rear-mount-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["rearMount"]}</span>
                                            {!rearMountOptions.length || typeof isCoreImageView === "number"
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["rearMount"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "rearMount")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].rearMountTintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockRearMount ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockRearMount ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockRearMount(!randomLockRearMount)}
                                            />
                                        </label>
                                        <select
                                            id="rear-mount-select"
                                            name="rear mount"
                                            onChange={(event) => handleChange(event.target.value, "rearMountValue")}
                                            value={unitState[currentUnitIndex].rearMountValue}
                                            disabled={!rearMountOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.rearMount ?
                                                null : <option value="NONE">NONE</option>
                                            }
                                            {rearMountOptions}
                                        </select>
                                    </SelectDropdown>
                                }

                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!weaponOptions.length || typeof isCoreImageView === "number"}
                                >
                                    <label htmlFor="weapon-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["weapon"]}</span>
                                        {!weaponOptions.length || typeof isCoreImageView === "number"
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["weapon"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "weapon")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].weaponTintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockWeapon ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockWeapon ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockWeapon(!randomLockWeapon)}
                                        />
                                    </label>
                                    <select
                                        id="weapon-select"
                                        name="weapon"
                                        onChange={unitCategory.partList[unitState[currentUnitIndex].frameValue].weaponSpecial ?
                                            (event) => handleMultipleChanges({
                                                [unitCategory.partList[unitState[currentUnitIndex].frameValue].weaponSpecial]: event.target.value,
                                                weaponValue: event.target.value,
                                            }) :
                                            (event) => handleChange(event.target.value, "weaponValue")
                                        }
                                        value={unitState[currentUnitIndex].weaponValue}
                                        disabled={!weaponOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.weapon ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {weaponOptions}
                                    </select>
                                </SelectDropdown>

                                {unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeaponIsChassisStanceSpecial ?
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!chassisOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        <label htmlFor="chassis-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]}</span>
                                            {!chassisOptions.length || typeof isCoreImageView === "number"
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "chassis")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].chassisTintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockChassis ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockChassis ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockChassis(!randomLockChassis)}
                                            />
                                        </label>
                                        <select
                                            id="chassis-select"
                                            name="chassis"
                                            onChange={(event) => handleMultipleChanges({
                                                weaponValue: "0",
                                                stanceValue: unitCategory.partList[unitState[currentUnitIndex].frameValue].chassis[event.target.value].label,
                                                chassisValue: event.target.value,
                                            })}
                                            value={unitState[currentUnitIndex].chassisValue}
                                            disabled={!chassisOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].chassisHasNone ?
                                                <option value="NONE">NONE</option> : null
                                            }
                                            {chassisOptions}
                                        </select>
                                    </SelectDropdown> :
                                    unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeaponIsAccessory1 ?
                                        <SelectDropdown
                                            className={displayTheme ? "retro-terminal" : "default"}
                                            disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            <label htmlFor="accessory-1-select">
                                                <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory1"]}</span>
                                                {!accessoryOptions.length || typeof isCoreImageView === "number"
                                                    ? null : <img
                                                        alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory1"]} Color Preview`}
                                                        src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "accessory1")[0].isGlow
                                                            ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                        }
                                                        className="part-color-preview"
                                                        style={{
                                                            filter: unitState[currentUnitIndex].accessory1TintValue,
                                                        }}
                                                    />
                                                }
                                                <SpriteImage
                                                    className={randomLockAccessory1 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                    alt={`Option ${randomLockAccessory1 ? "Locked" : "Unlocked"}`}
                                                    onClick={() => setRandomLockAccessory1(!randomLockAccessory1)}
                                                />
                                            </label>
                                            <select
                                                id="accessory-1-select"
                                                name="accessory1"
                                                onChange={(event) => handleChange(event.target.value, "accessory1Value")}
                                                value={unitState[currentUnitIndex].accessory1Value}
                                                disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                            >
                                                {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                    unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory1 ?
                                                    null : <option value="NONE">NONE</option>
                                                }
                                                {accessoryOptions}
                                            </select>
                                        </SelectDropdown>
                                        :
                                        <SelectDropdown
                                            className={displayTheme ? "retro-terminal" : "default"}
                                            disabled={!secondaryWeaponOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            <label htmlFor="secondary-weapon-select">
                                                <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["secondaryWeapon"]}</span>
                                                {!secondaryWeaponOptions.length || typeof isCoreImageView === "number"
                                                    ? null : <img
                                                        alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["secondaryWeapon"]} Color Preview`}
                                                        src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "secondaryWeapon")[0].isGlow
                                                            ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                        }
                                                        className="part-color-preview"
                                                        style={{
                                                            filter: unitState[currentUnitIndex].secondaryWeaponTintValue,
                                                        }}
                                                    />
                                                }
                                                <SpriteImage
                                                    className={randomLockSecondaryWeapon ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                    alt={`Option ${randomLockSecondaryWeapon ? "Locked" : "Unlocked"}`}
                                                    onClick={() => setRandomLockSecondaryWeapon(!randomLockSecondaryWeapon)}
                                                />
                                            </label>
                                            <select
                                                id="secondary-weapon-select"
                                                name="secondary weapon"
                                                onChange={(event) => {
                                                    if (unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeaponSpecial) {
                                                        handleMultipleChanges({
                                                            [unitCategory.partList[unitState[currentUnitIndex].frameValue].secondaryWeaponSpecial]: event.target.value,
                                                            secondaryWeaponValue: event.target.value,
                                                        });
                                                    } else {
                                                        handleChange(event.target.value, "secondaryWeaponValue");
                                                    }
                                                }}
                                                value={unitState[currentUnitIndex].secondaryWeaponValue}
                                                disabled={!secondaryWeaponOptions.length || typeof isCoreImageView === "number"}
                                            >
                                                {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                    unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.secondaryWeapon ?
                                                    null : <option value="NONE">NONE</option>
                                                }
                                                {secondaryWeaponOptions}
                                            </select>
                                        </SelectDropdown>
                                }

                                {unitCategory.partList[unitState[currentUnitIndex].frameValue].accessory1IsSecondaryWeapon ?
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!secondaryWeaponOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        <label htmlFor="secondary-weapon-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["secondaryWeapon"]}</span>
                                            {!secondaryWeaponOptions.length || typeof isCoreImageView === "number"
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["secondaryWeapon"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "secondaryWeapon")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].secondaryWeaponTintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockSecondaryWeapon ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockSecondaryWeapon ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockSecondaryWeapon(!randomLockSecondaryWeapon)}
                                            />
                                        </label>
                                        <select
                                            id="secondary-weapon-select"
                                            name="secondary weapon"
                                            onChange={(event) => handleChange(event.target.value, "secondaryWeaponValue")}
                                            value={unitState[currentUnitIndex].secondaryWeaponValue}
                                            disabled={!secondaryWeaponOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.secondaryWeapon ?
                                                null : <option value="NONE">NONE</option>
                                            }
                                            {secondaryWeaponOptions}
                                        </select>
                                    </SelectDropdown> :
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        <label htmlFor="accessory-1-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory1"]}</span>
                                            {!accessoryOptions.length || typeof isCoreImageView === "number"
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory1"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "accessory1")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].accessory1TintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockAccessory1 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockAccessory1 ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockAccessory1(!randomLockAccessory1)}
                                            />
                                        </label>
                                        <select
                                            id="accessory-1-select"
                                            name="accessory1"
                                            onChange={(event) => handleChange(event.target.value, "accessory1Value")}
                                            value={unitState[currentUnitIndex].accessory1Value}
                                            disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory1 ?
                                                null : <option value="NONE">NONE</option>
                                            }
                                            {accessoryOptions}
                                        </select>
                                    </SelectDropdown>
                                }
                                {unitCategory.partList[unitState[currentUnitIndex].frameValue].accessory2IsChassis ?
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!chassisOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        <label htmlFor="chassis-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]}</span>
                                            {!chassisOptions.length || typeof isCoreImageView === "number"
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["chassis"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "chassis")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].chassisTintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockChassis ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockChassis ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockChassis(!randomLockChassis)}
                                            />
                                        </label>
                                        <select
                                            id="chassis-select"
                                            name="chassis-select"
                                            onChange={(event) => handleChange(event.target.value, "chassisValue")}
                                            value={unitState[currentUnitIndex].chassisValue}
                                            disabled={!chassisOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].chassisHasNone ?
                                                <option value="NONE">NONE</option> : null
                                            }
                                            {chassisOptions}
                                        </select>
                                    </SelectDropdown> :
                                    <SelectDropdown
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        <label htmlFor="accessory-2-select">
                                            <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory2"]}</span>
                                            {!accessoryOptions.length || typeof isCoreImageView === "number"
                                                ? null : <img
                                                    alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory2"]} Color Preview`}
                                                    src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "accessory2")[0].isGlow
                                                        ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                    }
                                                    className="part-color-preview"
                                                    style={{
                                                        filter: unitState[currentUnitIndex].accessory2TintValue,
                                                    }}
                                                />
                                            }
                                            <SpriteImage
                                                className={randomLockAccessory2 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                                alt={`Option ${randomLockAccessory2 ? "Locked" : "Unlocked"}`}
                                                onClick={() => setRandomLockAccessory2(!randomLockAccessory2)}
                                            />
                                        </label>
                                        <select
                                            id="accessory-2-select"
                                            name="accessory2"
                                            onChange={(event) => handleChange(event.target.value, "accessory2Value")}
                                            value={unitState[currentUnitIndex].accessory2Value}
                                            disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                        >
                                            {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                                unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory2 ?
                                                null : <option value="NONE">NONE</option>
                                            }
                                            {accessoryOptions}
                                        </select>
                                    </SelectDropdown>
                                }

                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                >
                                    <label htmlFor="accessory-3-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory3"]}</span>
                                        {!accessoryOptions.length || typeof isCoreImageView === "number"
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory3"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "accessory3")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].accessory3TintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockAccessory3 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockAccessory3 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockAccessory3(!randomLockAccessory3)}
                                        />
                                    </label>
                                    <select
                                        id="accessory-3-select"
                                        name="accessory3"
                                        onChange={(event) => handleChange(event.target.value, "accessory3Value")}
                                        value={unitState[currentUnitIndex].accessory3Value}
                                        disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory3 ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {accessoryOptions}
                                    </select>
                                </SelectDropdown>
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                >
                                    <label htmlFor="accessory-4-select">
                                        <span>{unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory4"]}</span>
                                        {!accessoryOptions.length || typeof isCoreImageView === "number"
                                            ? null : <img
                                                alt={`${unitCategory.partList[unitState[currentUnitIndex].frameValue].labels["accessory4"]} Color Preview`}
                                                src={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].partList.filter(part => part.value === "accessory4")[0].isGlow
                                                    ? colorSampleSquareGlowImage : colorSampleSquareMetalImage
                                                }
                                                className="part-color-preview"
                                                style={{
                                                    filter: unitState[currentUnitIndex].accessory4TintValue,
                                                }}
                                            />
                                        }
                                        <SpriteImage
                                            className={randomLockAccessory4 ? SPRITE_KEYS.locked : SPRITE_KEYS.unlocked}
                                            alt={`Option ${randomLockAccessory4 ? "Locked" : "Unlocked"}`}
                                            onClick={() => setRandomLockAccessory4(!randomLockAccessory4)}
                                        />
                                    </label>
                                    <select
                                        id="accessory-4-select"
                                        name="accessory4"
                                        onChange={(event) => handleChange(event.target.value, "accessory4Value")}
                                        value={unitState[currentUnitIndex].accessory4Value}
                                        disabled={!accessoryOptions.length || typeof isCoreImageView === "number"}
                                    >
                                        {unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone &&
                                            unitCategory.partList[unitState[currentUnitIndex].frameValue].removeNone.accessory4 ?
                                            null : <option value="NONE">NONE</option>
                                        }
                                        {accessoryOptions}
                                    </select>
                                </SelectDropdown>

                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={typeof isCoreImageView === "number"}
                                >
                                    <label htmlFor="color-select">
                                        COLOR
                                    </label>
                                    <select
                                        id="color-select"
                                        name="color"
                                        onChange={handleColorOptionChange}
                                        value={colorValue}
                                        disabled={typeof isCoreImageView === "number"}
                                    >
                                        {colorListOptions}
                                    </select>
                                    <MechUnitImage
                                        style={{ top: "30px", left: "25px", width: "21px", height: "21px", border: "none" }}
                                        tintValue={tintValue}
                                        src={targetPartIsGlow ? glowPreviewImage : metalPreviewImage}
                                        alt="paint-preview"
                                        zIndex={"10"}
                                    />
                                </SelectDropdown>
                                <SelectDropdown
                                    className={displayTheme ? "retro-terminal" : "default"}
                                    disabled={typeof isCoreImageView === "number"}
                                >
                                    <label htmlFor="part-select">
                                        COLOR TARGET
                                    </label>
                                    <select
                                        id="part-select"
                                        name="part"
                                        onChange={(event) => setTargetPartValue(event.target.value)}
                                        value={targetPartValue}
                                        disabled={typeof isCoreImageView === "number"}
                                    >
                                        {targetPartListOptions}
                                    </select>
                                </SelectDropdown>
                            </div>
                            <div className="display-controls">
                                <Unit
                                    onContextMenu={(e) => e.preventDefault()}
                                    isExtraLarge
                                    isMegadeus={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeus}
                                    isMegadeusPlus={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlus}
                                    isMegadeusPlusPlus={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusPlusPlus}
                                    isMegadeusOmega={unitCategory.partList[unitState[currentUnitIndex].frameValue].isMegadeusOmega}
                                >
                                    <ZoomWrapper>
                                        <MechBrandedCategoryDisplay
                                            unit={typeof isCoreImageView === "number" ? Object.assign({}, unitState[currentUnitIndex], { viewCoreImage: isCoreImageView }) : unitState[currentUnitIndex]}
                                            isInCategory
                                        />
                                    </ZoomWrapper>
                                </Unit>

                                <IndexSelectors>
                                    {unitIndexSelectors}
                                </IndexSelectors>
                                <InterfaceControls>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={resetFunction}
                                    >
                                        RESET
                                    </InterfaceButton>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={randomFunction}
                                        disabled={typeof isCoreImageView === "number"}
                                    >
                                        RANDOM
                                    </InterfaceButton>

                                    <ExportMechButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                        mechType={"BRANDED"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                        singleImageType={isCoreImageView}
                                        isButtonThird={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].coreImg}
                                    />

                                    <ExportMechPartsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        unit={unitState[currentUnitIndex]}
                                        name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                        mechType={"BRANDED"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                        disabled={typeof isCoreImageView === "number"}
                                        isButtonThird={!!unitCategory.partList[unitState[currentUnitIndex].frameValue].coreImg}
                                    />

                                    {unitCategory.partList[unitState[currentUnitIndex].frameValue].coreImg ?
                                        <ExportAllMechPartsButton
                                            displayTheme={displayTheme ? "retro-terminal" : "default"}
                                            style={{}}
                                            unit={unitState[currentUnitIndex]}
                                            name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                            mechType={"BRANDED"}
                                            setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                            disabled={typeof isCoreImageView === "number"}
                                            isButtonThird
                                        /> : null
                                    }

                                    {unitCategory.partList[unitState[currentUnitIndex].frameValue].coreImg ?
                                        <InterfaceButton
                                            className={displayTheme ? "retro-terminal" : "default"}
                                            onClick={() => iterateCoreImageView()}
                                        >
                                            {`CORE VER. ${unitCategory.partList[unitState[currentUnitIndex].frameValue].coreImg.map((coreImg, index) => (isCoreImageView === index ? "▣" : "☐")).join(" ")}`}
                                        </InterfaceButton> :
                                        <ExportAllMechPartsButton
                                            displayTheme={displayTheme ? "retro-terminal" : "default"}
                                            style={{}}
                                            unit={unitState[currentUnitIndex]}
                                            name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                            mechType={"BRANDED"}
                                            setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                            disabled={typeof isCoreImageView === "number"}
                                        />
                                    }

                                    <ExportAllMechPartsAndTintsButton
                                        displayTheme={displayTheme ? "retro-terminal" : "default"}
                                        style={{}}
                                        unit={unitState[currentUnitIndex]}
                                        name={`${unitCategory.title}-${unitCategory.partList[unitState[currentUnitIndex].frameValue].label}`}
                                        mechType={"BRANDED"}
                                        setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                                        disabled={typeof isCoreImageView === "number"}
                                    />

                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={paintFunction}
                                        disabled={typeof isCoreImageView === "number"}
                                    >
                                        APPLY COLOR
                                    </InterfaceButton>
                                    <InterfaceButton
                                        className={displayTheme ? "retro-terminal" : "default"}
                                        onClick={addUnitFunction}
                                    >
                                        FABRICATE
                                    </InterfaceButton>
                                </InterfaceControls>
                            </div>

                            {displayCustomControls ?
                                <CustomColorControls
                                    displayTheme={displayTheme}
                                    targetPartIsGlow={targetPartIsGlow}
                                /> : null
                            }
                        </MechForm>
                    ) : null}
                </MechUnitContainer>
            </ContainerWrapper>
        </UnitCategoryWrapper>
    )
}

MechBrandedCategory.propTypes = {
    setShowPremiumPopupFunc: func.isRequired,
    unitCategory: object.isRequired,
};

export default MechBrandedCategory;
