import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyBlackWitchImage = `${BaseURL}/Mechs/SSC/Black Witch/Chassis.png`;

// HEADS
const headBlackWitchImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/Head - Black Witch.png`;
const headKazuImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/Head - Kazu.png`;
// PREMIUM
const headFactoryStandardImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Factory Standard.png`;
const headCircletImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Circlet.png`;
const headCircumImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Circum.png`;
const headJudicatorImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Judicator.png`;
const headMagnesImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Magnes.png`;
const headPolarizedImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Polarized.png`;
const headMaguminImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Magumin.png`;
const headCalibanImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Caliban.png`;
const headCyclopsImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Cyclops.png`;
const headHornedImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Malice.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Natural Law Hair.png`;
const headPredatorImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Predator.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Rain Transformed Hair.png`;
const headVanguardImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Vanguard.png`;
const headCockpitImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/SSC/Black Witch/heads/premium/Head - Scopehound.png`;

// WEAPONS
// LEFT
const weaponsPistolImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/left/Weapon - Left - Pistol.png`;
const weaponsRifleImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/left/Weapon - Left - Rifle.png`;
const weaponsDecksweeperImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/left/Weapon - Left - Decksweeper.png`;
const weaponsStaffImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/left/Weapon - Left - Staff.png`;
const weaponsTerashimaBladeImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/left/Weapon - Left - Terashima Blade.png`;
const weaponsFerrousLashImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/left/Weapon - Left - Ferrous Lash.png`;

// RIGHT
const secondaryWeaponsHandImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/right/Weapon - Right - Hand.png`;
const secondaryWeaponsPistolImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/right/Weapon - Right - Pistol.png`;
const secondaryWeaponsRifleImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/right/Weapon - Right - Rifle.png`;
const secondaryWeaponsDecksweeperImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/right/Weapon - Right - Decksweeper.png`;
const secondaryWeaponsPCPDiscImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/right/Weapon - Right - PCP disc.png`;
const secondaryWeaponsMagneticCannonImage = `${BaseURL}/Mechs/SSC/Black Witch/weapons/right/Weapon - Right - Magnetic Cannon.png`;

// REAR MOUNT
const rearMountRifleImage = `${BaseURL}/Mechs/SSC/Black Witch/rear mount/Rear - Rifle.png`;
const rearMountTBladeImage = `${BaseURL}/Mechs/SSC/Black Witch/rear mount/Rear - Terashima Blade.png`;

// ACCESSORIES
const accessoryRobesImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Robes.png`;
const accessoryRobesRifleImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Robes and Rifle.png`;
const accessoryRobesTBladeImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Robes and Terashima.png`;
const accessoryApostateCloakBackImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Apostate Cloak Back.png`;
const accessoryApostateCloakHeadLeftArmUpImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Apostate Cloak Top, Head Left, Arm Up.png`;
const accessoryApostateCloakHeadLeftArmDownImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Apostate Cloak Top, Head Left, Arm Down.png`;
const accessoryApostateCloakHeadRightArmUpImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Apostate Cloak Top, Head Right, Arm Up.png`;
const accessoryApostateCloakHeadRightArmDownImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Accessory - Apostate Cloak Top, Head Right, Arm Down.png`;
// PREMIUM
const accessoryFlyKicksImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Accessory - Fly Kicks.png`;
const accessoryFlyKicksRobesImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Accessory - Fly Kicks Robes 1.png`;
// LEGENDARY
const accessoryFlyKicksRobes2Image = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Accessory - Fly Kicks Robes 2.png`;
const accessoryRobes2Image = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Accessory - Robes 2.png`;
const accessoryRobes2RifleImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Accessory - Robes 2 and Rifle.png`;
const accessoryRobes2TBladeImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Accessory - Robes 2 and Terashima.png`;
const accessoryPolarizedArmorImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Accessory - Polarized Armor.png`;

// GLOW
const glowBlackWitchImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - Black Witch.png`;
const glowBulletsLocusImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - Bullets Locus.png`;
const glowFerrousLashBulletsImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - Ferrous Lash and Bullets.png`;
const glowFerrousLashImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - Ferrous Lash.png`;
const glowFerrousLashGravityCannonImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - Ferrous Lash and Gravity Cannon.png`;
const glowFerrousLashPCPDiscImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - Ferrous Lash and PCP disc.png`;
const glowPCPDiscImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - PCP disc.png`;
const glowMagneticCannonImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/Glow - Magnetic Cannon.png`;
// PREMIUM
const glowCircletImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Glow - Circlet.png`;
const glowCircumImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Glow - Circum.png`;
const glowMagnesImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Glow - Magnes.png`;
const glowMaguminImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Glow - Magumin.png`;
const glowPolarizedImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/premium/Glow - Polarized Head.png`;
// LEGENDARY
const glowPolarizedArmorImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Glow - Polarized Armor.png`;
const glowPolarizedArmorBlackWitchImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Glow - Polarized Armor and Black Witch.png`;
const glowPolarizedArmorHeadImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Glow - Polarized Armor and Head.png`;
const glowPolarizedArmorCircletImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Glow - Polarized Armor and Circlet.png`;
const glowPolarizedArmorCircumImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Glow - Polarized Armor and Circum.png`;
const glowPolarizedArmorMagnesImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Glow - Polarized Armor and Magnes.png`;
const glowPolarizedArmorMaguminImage = `${BaseURL}/Mechs/SSC/Black Witch/glow/legendary/Glow - Polarized Armor and Magumin.png`;

const coreImage = `${BaseURL}/Mechs/SSC/Black Witch/Black Witch (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/SSC/Black Witch/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "28px",
    right: "38px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    removeNone: {
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "2",
        "weaponValue": "3",
        "secondaryWeaponValue": "0",
        "accessory1Value": "7",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BLACK WITCH",
            src: headBlackWitchImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CIRCLET",
            src: headCircletImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CIRCUM",
            src: headCircumImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "MAGNES",
            src: headMagnesImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "POLARIZED",
            src: headPolarizedImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "MAGUMIN",
            src: headMaguminImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "PREDATOR",
            src: headPredatorImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "VANGUARD",
            src: headVanguardImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BLACK WITCH",
            src: bodyBlackWitchImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "PISTOL",
                backSrc: weaponsPistolImage,
            },
            {
                value: 1,
                label: "RIFLE",
                frontSrc: weaponsRifleImage,
            },
            {
                value: 2,
                label: "DECKSWEEPER",
                frontSrc: weaponsDecksweeperImage,
            },
            {
                value: 3,
                label: "STAFF",
                frontSrc: weaponsStaffImage,
            },
            {
                value: 4,
                label: "TERASHIMA BLADE",
                frontSrc: weaponsTerashimaBladeImage,
            },
            {
                value: 5,
                label: "FERROUS LASH",
                frontSrc: weaponsFerrousLashImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND",
            src: secondaryWeaponsHandImage,
        },
        {
            value: 1,
            label: "PISTOL",
            src: secondaryWeaponsPistolImage,
        },
        {
            value: 2,
            label: "RIFLE",
            src: secondaryWeaponsRifleImage,
        },
        {
            value: 3,
            label: "DECKSWEEPER",
            src: secondaryWeaponsDecksweeperImage,
        },
        {
            value: 4,
            label: "PCP DISC",
            src: secondaryWeaponsPCPDiscImage,
        },
        {
            value: 5,
            label: "MAGNETIC CANNON",
            src: secondaryWeaponsMagneticCannonImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "RIFLE",
            src: rearMountRifleImage,
        },
        {
            value: 1,
            label: "TERASHIMA BLADE",
            src: rearMountTBladeImage,
        },
        {
            value: 2,
            label: "ROBES",
            frontSrc: accessoryRobesImage,
        },
        {
            value: 3,
            label: "ROBES + RIFLE",
            frontSrc: accessoryRobesRifleImage,
        },
        {
            value: 4,
            label: "ROBES + TERASHIMA BLADE",
            frontSrc: accessoryRobesTBladeImage,
        },
        {
            value: 5,
            label: "APOSTATE CLOAK - HEAD LEFT ARM UP",
            foremostSrc: accessoryApostateCloakHeadLeftArmUpImage,
            src: accessoryApostateCloakBackImage,
        },
        {
            value: 6,
            label: "APOSTATE CLOAK - HEAD LEFT ARM DOWN",
            foremostSrc: accessoryApostateCloakHeadLeftArmDownImage,
            src: accessoryApostateCloakBackImage,
        },
        {
            value: 7,
            label: "APOSTATE CLOAK - HEAD RIGHT ARM UP",
            foremostSrc: accessoryApostateCloakHeadRightArmUpImage,
            src: accessoryApostateCloakBackImage,
        },
        {
            value: 8,
            label: "APOSTATE CLOAK - HEAD RIGHT ARM DOWN",
            foremostSrc: accessoryApostateCloakHeadRightArmDownImage,
            src: accessoryApostateCloakBackImage,
        },
        {
            value: 9,
            label: "FLY KICKS",
            frontSrc: accessoryFlyKicksImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "FLY KICKS + ROBES",
            frontSrc: accessoryFlyKicksRobesImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "FLY KICKS + MAGE ROBES",
            frontSrc: accessoryFlyKicksRobes2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 12,
            label: "MAGE ROBES",
            frontSrc: accessoryRobes2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 13,
            label: "MAGE ROBES + RIFLE",
            frontSrc: accessoryRobes2RifleImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 14,
            label: "MAGE ROBES + TERASHIMA BLADE",
            frontSrc: accessoryRobes2TBladeImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 15,
            label: "POLARIZED ARMOR",
            frontSrc: accessoryPolarizedArmorImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "PCP DISC",
            frontSrc: glowPCPDiscImage,
        },
        {
            value: 1,
            label: "MAGNETIC CANNON",
            frontSrc: glowMagneticCannonImage,
        },
        {
            value: 2,
            label: "BULLETS LOCUS",
            frontSrc: glowBulletsLocusImage,
        },
        {
            value: 3,
            label: "FERROUS LASH",
            frontSrc: glowFerrousLashImage,
        },
        {
            value: 4,
            label: "FERROUS LASH + BULLETS",
            frontSrc: glowFerrousLashBulletsImage,
        },
        {
            value: 5,
            label: "FERROUS LASH + MAGNETIC CANNON",
            frontSrc: glowFerrousLashGravityCannonImage,
        },
        {
            value: 6,
            label: "FERROUS LASH + PCP DISC",
            frontSrc: glowFerrousLashPCPDiscImage,
        },
        {
            value: 7,
            label: "BLACK WITCH HEAD",
            frontSrc: glowBlackWitchImage,
        },
        {
            value: 8,
            label: "CIRCLET",
            frontSrc: glowCircletImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "CIRCUM",
            frontSrc: glowCircumImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MAGNES",
            frontSrc: glowMagnesImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "NECK",
            frontSrc: glowMaguminImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "POLARIZED",
            frontSrc: glowPolarizedImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "POLARIZED ARMOR",
            frontSrc: glowPolarizedArmorImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 14,
            label: "POLARIZED ARMOR + BLACK WITCH HEAD",
            frontSrc: glowPolarizedArmorBlackWitchImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 15,
            label: "POLARIZED ARMOR + HEAD",
            frontSrc: glowPolarizedArmorHeadImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 16,
            label: "POLARIZED ARMOR + CIRCLET",
            frontSrc: glowPolarizedArmorCircletImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 17,
            label: "POLARIZED ARMOR + CIRCUM",
            frontSrc: glowPolarizedArmorCircumImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 18,
            label: "POLARIZED ARMOR + MAGNES",
            frontSrc: glowPolarizedArmorMagnesImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 19,
            label: "POLARIZED ARMOR + NECK",
            frontSrc: glowPolarizedArmorMaguminImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON LEFT",
            value: "weapon",
        },
        {
            label: "WEAPON RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ACCESSORY",
        "weapon": "WEAPON LEFT",
        "secondaryWeapon": "WEAPON RIGHT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
