import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyAssassinImage = `${BaseURL}/Mechs/Horus/Drones/Assassin Drone.png`;
const bodyGhastImage = `${BaseURL}/Mechs/Horus/Drones/Ghast Drone.png`;
const bodyTempestImage = `${BaseURL}/Mechs/Horus/Drones/Tempest Drone.png`;
const bodyTurretImage = `${BaseURL}/Mechs/Horus/Drones/Turret.png`;
const bodyOrochiGuardianImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Guardian Drone.png`;
const bodyOrochiHunterImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Hunter Drone.png`;
const bodyOrochiShredderImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Shredder Drone.png`;
const bodyOrochiSnareImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Snare Drone.png`;

// HEADS
const headAssassinGlowImage = `${BaseURL}/Mechs/Horus/Drones/Assassin Drone Glow.png`;
const headGhastGlowImage = `${BaseURL}/Mechs/Horus/Drones/Ghast Drone Glow.png`;
const headTempestGlowImage = `${BaseURL}/Mechs/Horus/Drones/Tempest Drone Glow.png`;
const headTurretGlowImage = `${BaseURL}/Mechs/Horus/Drones/Turret Glow.png`;
const headOrochiGuardianGlowImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Guardian Drone Glow.png`;
const headOrochiHunterGlowImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Hunter Drone Glow.png`;
const headOrochiShredderGlowImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Shredder Drone Glow.png`;
const headOrochiSnareGlowImage = `${BaseURL}/Mechs/Horus/Drones/Orochi - Snare Drone Glow.png`;

const previewImage = `${BaseURL}/Mechs/Horus/Drones/preview.png`;

const turretShadow = {
    size: shadowTypes.SMALL,
    bottom: "125px",
    right: "74px",
};

const hoverShadow = {
    size: shadowTypes.SMALL,
    bottom: "70px",
    right: "58px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "headValue",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ASSASSIN",
            src: headAssassinGlowImage,
        },
        {
            value: 1,
            label: "GHAST",
            src: headGhastGlowImage,
        },
        {
            value: 2,
            label: "TEMPEST",
            src: headTempestGlowImage,
        },
        {
            value: 3,
            label: "TURRET",
            src: headTurretGlowImage,
        },
        {
            value: 4,
            label: "OROCHI - GUARDIAN",
            src: headOrochiGuardianGlowImage,
        },
        {
            value: 5,
            label: "OROCHI - HUNTER",
            src: headOrochiHunterGlowImage,
        },
        {
            value: 6,
            label: "OROCHI - SHREDDER",
            src: headOrochiShredderGlowImage,
        },
        {
            value: 7,
            label: "OROCHI - SNARE",
            src: headOrochiSnareGlowImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ASSASSIN",
            src: bodyAssassinImage,
            shadowDetails: hoverShadow,
        },
        {
            value: 1,
            label: "GHAST",
            src: bodyGhastImage,
            shadowDetails: hoverShadow,
        },
        {
            value: 2,
            label: "TEMPEST",
            src: bodyTempestImage,
            shadowDetails: hoverShadow,
        },
        {
            value: 3,
            label: "TURRET",
            src: bodyTurretImage,
            shadowDetails: turretShadow,
        },
        {
            value: 4,
            label: "OROCHI - GUARDIAN",
            src: bodyOrochiGuardianImage,
            shadowDetails: hoverShadow,
        },
        {
            value: 5,
            label: "OROCHI - HUNTER",
            src: bodyOrochiHunterImage,
            shadowDetails: hoverShadow,
        },
        {
            value: 6,
            label: "OROCHI - SHREDDER",
            src: bodyOrochiShredderImage,
            shadowDetails: hoverShadow,
        },
        {
            value: 7,
            label: "OROCHI - SNARE",
            src: bodyOrochiSnareImage,
            shadowDetails: hoverShadow,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
