/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useState } from "react";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

import {
    InterfaceButton,
} from "./MechCategoryStyles";
import { drawZipImages } from "./../../ExportImages";
import ExportWrapper from "../../components/ExportWrapper";

import { UserAuthContext } from "./../../../../User/UserAuthContextWrapper";
import { AnalyticsContext } from "./../../../../Analytics/AnalyticsContextWrapper";
import { CustomColorContext } from "../../../CustomColorContextWrapper";
import { safeName, nameRegex } from "./../../../Print/PrintAnalytics";

import {
    brandList as mechNPCBrandList,
    npcOptionList,
    npcLayerList,
    npcLayerOrder,
} from "../../../LayerData/MechNPCLayerOrder";

import {
    brandList as mechBrandedBrandList,
    brandedOptionList,
    brandedLayerList,
    brandedLayerOrder,
} from "../../../LayerData/MechBrandedLayerOrder";

import { createExportLayerOptionsForAllPartsForColor } from "../../../LayerData/CreateLayerFunctions";

import Mechs from "./../../../data/Mechs-GMS";
import MechsCustomPlus from "./../../../data/Mechs-622";

import colorList from "../../components/ColorList";
import { premiumTiersList } from "../../PremiumPopup";

const filteredColorList = JSON.parse(JSON.stringify(colorList)).filter(color => color.label !== "RANDOM" && color.label !== "CUSTOM");

const brandList = Object.assign({},
    mechNPCBrandList,
    mechBrandedBrandList,
);

const canvasRef = createRef();

function ExportAllMechPartsAndTintsButton({ displayTheme, unit, name, mechType, setShowPremiumPopupFunc, disabled = false }) {
    const { user } = useContext(UserAuthContext);
    const ReactGA = useContext(AnalyticsContext);
    const { getTintFromColor } = useContext(CustomColorContext);

    const isLegendaryUser = user && user.isLegendaryUser;

    const [isLoading, setIsLoading] = useState(false);

    let imageSize = 250;

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeus) {
        imageSize = 300;
    }

    if (
        mechType === "CUSTOM-PLUS"
        || (mechType !== "CUSTOM" && brandList[unit.brandValue][unit.frameValue].isMegadeusPlus)
    ) {
        imageSize = 350;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusPlusPlus) {
        imageSize = 400;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusOmega) {
        imageSize = 450;
    }

    async function printCustomMech(color, zip) {
        return new Promise(async (resolve, reject) => {
            let imageLayersArray = [];

            function addAccessories(accessoryList) {
                accessoryList.forEach((accessory) => {
                    if (accessory.backSrc) {
                        imageLayersArray.push({
                            src: accessory.backSrc,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (accessory.src) {
                        imageLayersArray.push({
                            src: accessory.src,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-mid.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (accessory.frontSrc) {
                        imageLayersArray.push({
                            src: accessory.frontSrc,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (accessory.foremostSrc) {
                        imageLayersArray.push({
                            src: accessory.foremostSrc,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-foremost.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (Mechs.accessory.length) {
                addAccessories(Mechs.accessory);
            }

            Object.entries(Mechs.weapon).forEach((stance) => {
                Mechs.weapon[stance[0]].forEach((weapon) => {
                    if (!weapon.disabled && weapon.backSrc) {
                        imageLayersArray.push({
                            src: weapon.backSrc,
                            filter: getTintFromColor(color),
                            name: `weapon-${stance[0]}-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (!weapon.disabled && weapon.src) {
                        imageLayersArray.push({
                            src: weapon.src,
                            filter: getTintFromColor(color),
                            name: `weapon-${stance[0]}-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            });

            if (Mechs.secondaryWeapon.length) {
                    Mechs.secondaryWeapon.forEach((weapon) => {
                        if (!weapon.disabled && weapon.backSrc) {
                            imageLayersArray.push({
                                src: weapon.backSrc,
                                filter: getTintFromColor(color),
                                name: `secondary-weapon-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                            });
                        }

                        if (!weapon.disabled && weapon.src) {
                            imageLayersArray.push({
                                src: weapon.src,
                                filter: getTintFromColor(color),
                                name: `secondary-weapon-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                            });
                        }
                    });
            }

            if (Mechs.rearMount.length) {
                Mechs.rearMount.forEach((rearMount) => {
                    if (rearMount.backSrc) {
                        imageLayersArray.push({
                            src: rearMount.backSrc,
                            filter: getTintFromColor(color),
                            name: `rear-mount-${rearMount.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (rearMount.src) {
                        imageLayersArray.push({
                            src: rearMount.src,
                            filter: getTintFromColor(color),
                            name: `rear-mount-${rearMount.label}-mid.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (rearMount.frontSrc) {
                        imageLayersArray.push({
                            src: rearMount.frontSrc,
                            filter: getTintFromColor(color),
                            name: `rear-mount-${rearMount.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (Mechs.legs.length) {
                Mechs.legs.forEach((legs) => {
                    if (legs.src) {
                        imageLayersArray.push({
                            src: legs.src,
                            filter: getTintFromColor(color),
                            name: `legs-${legs.label}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (Mechs.arms.length) {
                Mechs.arms.forEach((arm) => {
                    if (arm.isMounted) {
                        if (arm.src["1H"].left) {
                            imageLayersArray.push({
                                src: arm.src["1H"].left,
                                filter: getTintFromColor(color),
                                name: `arm-mounted-${arm.label}-left.png`.replaceAll(nameRegex, "_"),
                            });
                        }

                        if (arm.src["1H"].right) {
                            imageLayersArray.push({
                                src: arm.src["1H"].right,
                                filter: getTintFromColor(color),
                                name: `arm-mounted-${arm.label}-right.png`.replaceAll(nameRegex, "_"),
                            });
                        }
                    } else if (!arm.disabled) {
                        Object.entries(arm.src).forEach((stance) => {
                            if (stance[1].skeleton) {
                                imageLayersArray.push({
                                    src: stance[1].skeleton,
                                    filter: getTintFromColor(color),
                                    name: `arm-${stance[0]}-${arm.label}-skeleton.png`.replaceAll(nameRegex, "_"),
                                });
                            }

                            if (stance[1].left) {
                                imageLayersArray.push({
                                    src: stance[1].left,
                                    filter: getTintFromColor(color),
                                    name: `arm-${stance[0]}-${arm.label}-left.png`.replaceAll(nameRegex, "_"),
                                });
                            }

                            if (stance[1].right) {
                                imageLayersArray.push({
                                    src: stance[1].right,
                                    filter: getTintFromColor(color),
                                    name: `arm-${stance[0]}-${arm.label}-right.png`.replaceAll(nameRegex, "_"),
                                });
                            }
                        });
                    }
                });
            }

            if (Mechs.chassis.length) {
                Mechs.chassis.forEach((chassis) => {
                    if (chassis.src) {
                        imageLayersArray.push({
                            src: chassis.src,
                            filter: getTintFromColor(color),
                            name: `chassis-${chassis.label}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (Mechs.head.length) {
                Mechs.head.forEach((head) => {
                    if (head.src) {
                        imageLayersArray.push({
                            src: head.src,
                            filter: getTintFromColor(color),
                            name: `head-${head.label}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            const zipFolder = zip.folder(`${safeName(name)}/${color.label}`);

            const canvas = canvasRef.current;
            let ctx = canvas.getContext('2d');

            await drawZipImages({
                ctxObject: ctx,
                canvas,
                zipObject: zipFolder,
                images: imageLayersArray,
                imageSize,
            });

            setTimeout(async () => {
                ctx.clearRect(0, 0, imageSize, imageSize);

                resolve();
            }, 0);
        });
    }


    async function printCustomPlusMech(color, zip) {
        return new Promise(async (resolve, reject) => {
            let imageLayersArray = [];

            function addAccessories(accessoryList) {
                accessoryList.forEach((accessory) => {
                    if (accessory.backSrc) {
                        imageLayersArray.push({
                            src: accessory.backSrc,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (accessory.src) {
                        imageLayersArray.push({
                            src: accessory.src,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-mid.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (accessory.frontSrc) {
                        imageLayersArray.push({
                            src: accessory.frontSrc,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (accessory.foremostSrc) {
                        imageLayersArray.push({
                            src: accessory.foremostSrc,
                            filter: getTintFromColor(color),
                            name: `accessory-${accessory.label}-foremost.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (MechsCustomPlus.accessory.length) {
                addAccessories(MechsCustomPlus.accessory);
            }

            Object.entries(MechsCustomPlus.weapon).forEach((stance) => {
                MechsCustomPlus.weapon[stance[0]].forEach((weapon) => {
                    if (!weapon.disabled && weapon.backSrc) {
                        imageLayersArray.push({
                            src: weapon.backSrc,
                            filter: getTintFromColor(color),
                            name: `weapon-${stance[0]}-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (!weapon.disabled && weapon.src) {
                        imageLayersArray.push({
                            src: weapon.src,
                            filter: getTintFromColor(color),
                            name: `weapon-${stance[0]}-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            });

            if (MechsCustomPlus.secondaryWeapon.length) {
                    MechsCustomPlus.secondaryWeapon.forEach((weapon) => {
                        if (!weapon.disabled && weapon.backSrc) {
                            imageLayersArray.push({
                                src: weapon.backSrc,
                                filter: getTintFromColor(color),
                                name: `secondary-weapon-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                            });
                        }

                        if (!weapon.disabled && weapon.src) {
                            imageLayersArray.push({
                                src: weapon.src,
                                filter: getTintFromColor(color),
                                name: `secondary-weapon-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                            });
                        }
                    });
            }

            if (MechsCustomPlus.rearMount.length) {
                MechsCustomPlus.rearMount.forEach((rearMount) => {
                    if (rearMount.backSrc) {
                        imageLayersArray.push({
                            src: rearMount.backSrc,
                            filter: getTintFromColor(color),
                            name: `rear-mount-${rearMount.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (rearMount.src) {
                        imageLayersArray.push({
                            src: rearMount.src,
                            filter: getTintFromColor(color),
                            name: `rear-mount-${rearMount.label}-mid.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (rearMount.frontSrc) {
                        imageLayersArray.push({
                            src: rearMount.frontSrc,
                            filter: getTintFromColor(color),
                            name: `rear-mount-${rearMount.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (MechsCustomPlus.legs.length) {
                MechsCustomPlus.legs.forEach((legs) => {
                    if (legs.src) {
                        imageLayersArray.push({
                            src: legs.src,
                            filter: getTintFromColor(color),
                            name: `legs-${legs.label}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (MechsCustomPlus.leftArm.length) {
                MechsCustomPlus.leftArm.forEach((arm) => {
                    imageLayersArray.push({
                        src: arm.src,
                        filter: getTintFromColor(color),
                        name: `arm-${arm.label}-left.png`.replaceAll(nameRegex, "_"),
                    });
                });
            }

            if (MechsCustomPlus.rightArm.length) {
                MechsCustomPlus.rightArm.forEach((arm) => {
                    imageLayersArray.push({
                        src: arm.src,
                        filter: getTintFromColor(color),
                        name: `arm-${arm.label}-right.png`.replaceAll(nameRegex, "_"),
                    });
                });
            }

            if (MechsCustomPlus.chassis.length) {
                MechsCustomPlus.chassis.forEach((chassis) => {
                    if (chassis.src) {
                        imageLayersArray.push({
                            src: chassis.src,
                            filter: getTintFromColor(color),
                            name: `chassis-${chassis.label}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            if (MechsCustomPlus.head.length) {
                MechsCustomPlus.head.forEach((head) => {
                    if (head.src) {
                        imageLayersArray.push({
                            src: head.src,
                            filter: getTintFromColor(color),
                            name: `head-${head.label}.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
            }

            const zipFolder = zip.folder(`${safeName(name)}/${color.label}`);

            const canvas = canvasRef.current;
            let ctx = canvas.getContext('2d');

            await drawZipImages({
                ctxObject: ctx,
                canvas,
                zipObject: zipFolder,
                images: imageLayersArray,
                imageSize,
            });

            setTimeout(async () => {
                ctx.clearRect(0, 0, imageSize, imageSize);

                resolve();
            }, 0);
        });
    }

    async function printBrandedMech(unit, color, zip) {
        return new Promise(async (resolve, reject) => {
            let imageLayersArray = createExportLayerOptionsForAllPartsForColor({
                unit,
                color: getTintFromColor(color),
                brandList: mechBrandedBrandList,
                optionList: brandedOptionList,
                layerList: brandedLayerList,
                layerOrder: brandedLayerOrder,
            });

            const zipFolder = zip.folder(`${safeName(name)}/${color.label}`);

            const canvas = canvasRef.current;
            let ctx = canvas.getContext('2d');

            await drawZipImages({
                ctxObject: ctx,
                canvas,
                zipObject: zipFolder,
                images: imageLayersArray,
                imageSize,
            });

            setTimeout(async () => {
                ctx.clearRect(0, 0, imageSize, imageSize);

                resolve();
            }, 0);
        });
    }

    async function printNPCMech(unit, color, zip) {
        return new Promise(async (resolve, reject) => {
            let imageLayersArray = createExportLayerOptionsForAllPartsForColor({
                unit,
                color: getTintFromColor(color),
                brandList: mechNPCBrandList,
                optionList: npcOptionList,
                layerList: npcLayerList,
                layerOrder: npcLayerOrder,
            });

            const zipFolder = zip.folder(`${safeName(name)}/${color.label}`);

            const canvas = canvasRef.current;
            let ctx = canvas.getContext('2d');

            await drawZipImages({
                ctxObject: ctx,
                canvas,
                zipObject: zipFolder,
                images: imageLayersArray,
                imageSize,
            });

            setTimeout(async () => {
                ctx.clearRect(0, 0, imageSize, imageSize);

                resolve();
            }, 0);
        });
    }

    async function printAllTintedMechParts(unit, mechType) {
        let zip = new JSZip();

        let colorListPromiseArray = filteredColorList.map((color) => () => {
            switch(mechType) {
                case "BRANDED":
                    return printBrandedMech(unit, color, zip);
                case "CUSTOM":
                    return printCustomMech(color, zip);
                case "CUSTOM-PLUS":
                    return printCustomPlusMech(color, zip);
                case "NPC":
                    return printNPCMech(unit, color, zip);
                default:
                    break;
            }
        });

        function executeSequentially(promiseFactories) {
            var result = Promise.resolve();

            promiseFactories.forEach((promiseFactory) => {
                result = result.then(promiseFactory);
            });

            return result;
        }

        await executeSequentially(colorListPromiseArray);

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}-Tinted-Parts.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export All Tinted Mech Parts",
            label: `${safeName(name)}`,
        });

        setIsLoading(false);
    }

    return (
        <ExportWrapper>
            <InterfaceButton
                className={displayTheme}
                disabled={disabled}
                title={"Export ALL Possible Mech Parts for this Frame as individual PNGs with ALL color tints This may take some time..."}
                onClick={disabled || isLoading ? () => {} : () => {
                    if (!isLegendaryUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                    } else {
                        setIsLoading(true);
                        return printAllTintedMechParts(unit, mechType);
                    }
                }}
            >
                {isLoading ? "LOADING" : "⇣ ALL++ ●●"}
            </InterfaceButton>
            <canvas ref={canvasRef} height={imageSize} width={imageSize} />
        </ExportWrapper>
    );
}

export default ExportAllMechPartsAndTintsButton;
