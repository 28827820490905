import React, { createContext, useContext, useState } from "react";

import { SFXContext } from "./../Music/SFXContextWrapper";

export const UnitContext = createContext(null);

function UnitContextWrapper({ ...props }) {
    const [unitsSelected, setUnitsSelected] = useState(Array(12).fill({ id: "empty" }));

    const [unitBackgroundColor, setUnitBackgroundColor] = useState("0");
    const [unitBackgroundOpacity, setUnitBackgroundOpacity] = useState("0");

    const { playSFXMusic } = useContext(SFXContext);

    const addUnit = (unitObject) => {
        const newArray = JSON.parse(JSON.stringify(unitsSelected));

        if (newArray.filter(unit => unit.id !== "empty").length <= 11) {
            newArray.splice(newArray.findIndex((unit) => unit.id === "empty"), 1, unitObject);

            playSFXMusic();

            return setUnitsSelected(newArray);
        }
    };

    const removeUnit = (unitID) => {
        const newArray = JSON.parse(JSON.stringify(unitsSelected));

        newArray.splice(newArray.findIndex((arrayItem) => arrayItem.id === unitID), 1, { id: "empty" });

        return setUnitsSelected(newArray);
    };

    const removeAllUnits = () => {
        setUnitsSelected(unitsSelected.map((unit) => {
            unit.toBeRemoved = true;

            return unit;
        }));

        return setTimeout(setUnitsSelected, 750, Array(12).fill({ id: "empty" }));
    };

    const updateVariant = (unitID, variant) => (
        setUnitsSelected(unitsSelected.map(unit => {
            if (unit.id === unitID) {
                unit.currentVariant = variant;
            }

            return unit;
        }))
    );

    return (
        <UnitContext.Provider value={{
            unitsSelected,
            addUnit,
            removeUnit,
            removeAllUnits,
            updateVariant,
            unitBackgroundColor,
            unitBackgroundOpacity,
            setUnitBackgroundColor,
            setUnitBackgroundOpacity,
        }}>
            {props.children}
        </UnitContext.Provider>
    );
}

export default UnitContextWrapper;
