import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Caliban`;

// BODY
const bodyCaliban1HImage = `${imagePath}/Body 1H.png`;
const bodyCaliban2HImage = `${imagePath}/Body 2H Melee.png`;
const bodyCalibanShotgunImage = `${imagePath}/Body 2H Shotgun.png`;
const bodyCalibanCoverImage = `${imagePath}/Head Over.png`;

// HEADS
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headAtomicImage = `${imagePath}/heads/Head - Atomic.png`;
const headRNTImage = `${imagePath}/heads/Head - RIP AND TEAR.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
// PREMIUM
const headAImage = `${imagePath}/heads/premium/Head A.png`;
const headBImage = `${imagePath}/heads/premium/Head B.png`;
const headCImage = `${imagePath}/heads/premium/Head C.png`;
const headDImage = `${imagePath}/heads/premium/Head D(no over).png`;
const headEImage = `${imagePath}/heads/premium/Head E.png`;
const headFImage = `${imagePath}/heads/premium/Head F.png`;
const headHImage = `${imagePath}/heads/premium/Head H.png`;
const headJImage = `${imagePath}/heads/premium/Head J.png`;
const headKImage = `${imagePath}/heads/premium/Head K.png`;
const headMImage = `${imagePath}/heads/premium/Head M.png`;
const headNImage = `${imagePath}/heads/premium/Head N.png`;
const headOImage = `${imagePath}/heads/premium/Head O.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head-Cockpit.png`;
const headDwarfImage = `${imagePath}/heads/premium/Head-Dwarf.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;

// REAR MOUNT
const rearMountRapidManeuverJetsImage = `${imagePath}/rear mount/Rear Mount - Rapid Maneuver Jets.png`;

// ACCESSORY
const accessoryHammerLeftImage = `${imagePath}/accessories/Accessory - Hammer U-RPL Left.png`;
const accessoryHammerRightImage = `${imagePath}/accessories/Accessory - Hammer U-RPL Right.png`;
const accessoryHammerDualImage = `${imagePath}/accessories/Accessory - Hammer U-RPL Dual.png`;
const accessoryChassisDetailShotgunImage = `${imagePath}/accessories/chassis-SHOTGUN-mid.png`;
const accessoryChassisDetail1HImage = `${imagePath}/accessories/chassis-1HAND-mid.png`;
const accessoryChassisDetail2HImage = `${imagePath}/accessories/chassis-2HAND-mid.png`;

// 1H
const weaponAxe1HImage = `${imagePath}/weapons/1H/Weapon 1H Axe.png`;
const weaponKatana1HImage = `${imagePath}/weapons/1H/Weapon 1H Katana.png`;
const weaponDrakesbane1HImage = `${imagePath}/weapons/1H/Weapon 1H Drakesbane.png`;
const weaponDD2881HImage = `${imagePath}/weapons/1H/Weapon 1H DD288.png`;
const weaponSSG1HImage = `${imagePath}/weapons/1H/Weapon 1H E1M1 SSG.png`;
const weaponMachete1HImage = `${imagePath}/weapons/1H/1H Weapon - Machete.png`;
const weaponHCB1HImage = `${imagePath}/weapons/1H/1H Weapon - HCB.png`;
const weaponNanocarbonBlade1HImage = `${imagePath}/weapons/1H/Weapon 1H - Nanocarbon Blade.png`;
const weaponFusionRifle1HImage = `${imagePath}/weapons/1H/caliban fusion rifle.png`;
const weaponFusionRifleGlow1HImage = `${imagePath}/weapons/1H/caliban fusion rifle glow.png`;

// 2H
const weaponAxe2HImage = `${imagePath}/weapons/2H/Weapon 2H Axe.png`;
const weaponHammer2HImage = `${imagePath}/weapons/2H/Weapon 2H Hammer.png`;

// SHOTGUN
const weaponSSGShotgunImage = `${imagePath}/weapons/Shotgun/Weapon 2H Shotgun E1M1 SSG.png`;
const weaponDecksweeperShotgunImage = `${imagePath}/weapons/Shotgun/Weapon 2H Shotgun Decksweeper.png`;
const weaponCannibalShotgunImage = `${imagePath}/weapons/Shotgun/Weapon 2H Shotgun HHS-155 Cannibal.png`;
const weaponGatlingGunImage = `${imagePath}/weapons/Shotgun/caliban gatling.png`;

const coreImage = `${imagePath}/Caliban (by Corvon).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SMALL,
    bottom: "105px",
    right: "78px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    secondaryWeaponIsChassisStanceSpecial: true,
    defaultValues: `{
        "chassisValue": "2",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "SHOTGUN",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 1,
            label: "ATOMIC",
            foremostSrc: headAtomicImage,
        },
        {
            value: 2,
            label: "RIP AND TEAR",
            foremostSrc: headRNTImage,
        },
        {
            value: 3,
            label: "KAZU",
            foremostSrc: headKazuImage,
        },
        {
            value: 4,
            label: "FACTORY STANDARD",
            foremostSrc: headAImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "PALADIN",
            foremostSrc: headBImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "OGRE",
            foremostSrc: headCImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "ROGUE",
            foremostSrc: headDImage,
            isPremium: true,
            removeBodyCover: true,
        },
        {
            value: 8,
            label: "BASTION",
            foremostSrc: headEImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "ICARUS",
            foremostSrc: headFImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "RAMSHACKLE",
            foremostSrc: headHImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "DUNGAM",
            foremostSrc: headKImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "JUDICATOR",
            foremostSrc: headMImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "RUMBLE",
            foremostSrc: headNImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NELSON",
            foremostSrc: headOImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "COCKPIT",
            foremostSrc: headCockpitImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "DWARF",
            foremostSrc: headDwarfImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "BUNKER",
            foremostSrc: headJImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "1H",
            src: bodyCaliban1HImage,
            frontSrc: bodyCalibanCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "2H",
            src: bodyCaliban2HImage,
            frontSrc: bodyCalibanCoverImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "SHOTGUN",
            src: bodyCalibanShotgunImage,
            frontSrc: bodyCalibanCoverImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AXE",
                src: weaponAxe1HImage,
            },
            {
                value: 1,
                label: "KATANA",
                src: weaponKatana1HImage,
            },
            {
                value: 2,
                label: "DRAKESBANE",
                src: weaponDrakesbane1HImage,
            },
            {
                value: 3,
                label: "DD288",
                src: weaponDD2881HImage,
            },
            {
                value: 4,
                label: "E1M1 SSG",
                src: weaponSSG1HImage,
            },
            {
                value: 5,
                label: "MACHETE",
                src: weaponMachete1HImage,
            },
            {
                value: 6,
                label: "HCB",
                src: weaponHCB1HImage,
            },
            {
                value: 7,
                label: "NANOCARBON BLADE",
                src: weaponNanocarbonBlade1HImage,
            },
            {
                value: 8,
                label: "FUSION RIFLE",
                src: weaponFusionRifle1HImage,
            },
        ],
        "2H": [
            {
                value: 0,
                label: "AXE",
                src: weaponAxe2HImage,
            },
            {
                value: 1,
                label: "HAMMER",
                src: weaponHammer2HImage,
            },
        ],
        "SHOTGUN": [
            {
                value: 0,
                label: "HHS-155 CANNIBAL",
                src: weaponCannibalShotgunImage,
            },
            {
                value: 1,
                label: "DECKSWEEPER",
                src: weaponDecksweeperShotgunImage,
            },
            {
                value: 2,
                label: "E1M1 SSG",
                src: weaponSSGShotgunImage,
            },
            {
                value: 3,
                label: "GATLING GUN",
                src: weaponGatlingGunImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },

        ],
    },
    secondaryWeapon: [],
    rearMount: [
        {
            value: 0,
            label: "RAPID MANEUVER JETS",
            frontSrc: rearMountRapidManeuverJetsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "HAMMER U-RPL LEFT",
            frontSrc: accessoryHammerLeftImage,
        },
        {
            value: 1,
            label: "HAMMER U-RPL RIGHT",
            frontSrc: accessoryHammerRightImage,
        },
        {
            value: 2,
            label: "HAMMER U-RPL DUAL",
            frontSrc: accessoryHammerDualImage,
        },
        {
            value: 3,
            label: "COLOR - CHASSIS DETAILS - 1H",
            frontSrc: accessoryChassisDetail1HImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "COLOR - CHASSIS DETAILS - 2H",
            frontSrc: accessoryChassisDetail2HImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "COLOR - CHASSIS DETAILS - SHOTGUN",
            frontSrc: accessoryChassisDetailShotgunImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
        {
            value: 6,
            label: "GLOW - 1H - FUSION RIFLE",
            frontSrc: weaponFusionRifleGlow1HImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "STANCE",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
