import { LAYERS, OPTIONS } from "./Layers";

import heroesPartList from "../data/Heroes";

export const brandList = {
    "Heroes": heroesPartList,
};

export const heroOptionList = [
    OPTIONS.head,
    OPTIONS.eyes,
    OPTIONS.face,
    OPTIONS.facialHair,
    OPTIONS.hair,
    OPTIONS.skin,
    OPTIONS.leftArm,
    OPTIONS.rightArm,
    OPTIONS.chest,
    OPTIONS.legs,
    OPTIONS.rightWeapon,
    OPTIONS.leftWeapon,
    OPTIONS.accessory,
];

export const heroLayerList = [
    LAYERS.foremostSrc,
    LAYERS.frontSrc,
    LAYERS.src,
    LAYERS.backSrc,
    LAYERS.tattooSrc,
];

export const heroLayerOrder = [
    {
        name: "headValue",
        slot: OPTIONS.head,
        tintValue: "headTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 400,
            },
            {
                name: LAYERS.src,
                zIndex: 370,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 110,
            },
        ],
    },
    {
        name: "eyesValue",
        slot: OPTIONS.eyes,
        tintValue: "eyesTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 390,
            },
            {
                name: LAYERS.src,
                zIndex: 380,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 290,
            },
        ],
    },
    {
        name: "faceValue",
        slot: OPTIONS.face,
        tintValue: "faceTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 320,
            },
            {
                name: LAYERS.src,
                zIndex: 280,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 270,
            },
        ],
    },
    {
        name: "facialHairValue",
        slot: OPTIONS.facialHair,
        tintValue: "facialHairTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 340,
            },
            {
                name: LAYERS.src,
                zIndex: 330,
            },
        ],
    },
    {
        name: "hairValue",
        slot: OPTIONS.hair,
        tintValue: "hairTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 350,
            },
            {
                name: LAYERS.src,
                zIndex: 300,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 30,
            },
        ],
    },
    {
        name: "skinValue",
        slot: OPTIONS.skin,
        tintValue: "skinTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 310,
            },
            {
                name: LAYERS.src,
                zIndex: 120,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 10,
            },
        ],
    },
    {
        name: "chestValue",
        slot: OPTIONS.chest,
        tintValue: "chestTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 360,
            },
            {
                name: LAYERS.src,
                zIndex: 210,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 100,
            },
        ],
    },
    {
        name: "legsValue",
        slot: OPTIONS.legs,
        tintValue: "legsTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 200,
            },
            {
                name: LAYERS.src,
                zIndex: 190,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 40,
            },
        ],
    },
    {
        name: "leftArmValue",
        slot: OPTIONS.leftArm,
        tintValue: "leftArmTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 410,
            },
            {
                name: LAYERS.src,
                zIndex: 220,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 130,
            },
        ],
    },
    {
        name: "rightArmValue",
        slot: OPTIONS.rightArm,
        tintValue: "rightArmTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 160,
            },
            {
                name: LAYERS.src,
                zIndex: 150,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 20,
            },
        ],
    },
    {
        name: "rightWeaponValue",
        slot: OPTIONS.rightWeapon,
        tintValue: "rightWeaponTintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 425,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 180,
            },
            {
                name: LAYERS.src,
                zIndex: 170,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 90,
            },
        ],
    },
    {
        name: "leftWeaponValue",
        slot: OPTIONS.leftWeapon,
        tintValue: "leftWeaponTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 470,
            },
            {
                name: LAYERS.src,
                zIndex: 420,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 140,
            },
        ],
    },
    {
        name: "accessory1Value",
        number: 1,
        slot: OPTIONS.accessory,
        tintValue: "accessory1TintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 460,
            },
            {
                name: LAYERS.src,
                zIndex: 260,
            },
            {
                name: LAYERS.tattooSrc,
                zIndex: 128,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 80,
            },
        ],
    },
    {
        name: "accessory2Value",
        number: 2,
        slot: OPTIONS.accessory,
        tintValue: "accessory2TintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 450,
            },
            {
                name: LAYERS.src,
                zIndex: 250,
            },
            {
                name: LAYERS.tattooSrc,
                zIndex: 126,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 70,
            },
        ],
    },
    {
        name: "accessory3Value",
        number: 3,
        slot: OPTIONS.accessory,
        tintValue: "accessory3TintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 440,
            },
            {
                name: LAYERS.src,
                zIndex: 240,
            },
            {
                name: LAYERS.tattooSrc,
                zIndex: 124,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 60,
            },
        ],
    },
    {
        name: "accessory4Value",
        number: 4,
        slot: OPTIONS.accessory,
        tintValue: "accessory4TintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 430,
            },
            {
                name: LAYERS.src,
                zIndex: 230,
            },
            {
                name: LAYERS.tattooSrc,
                zIndex: 122,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 50,
            },
        ],
    },
];
