import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/DFG-Sloth`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis.png`;

// HEADS
const headSlothImage = `${imagePath}/heads/Head - Sloth.png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;
const headBastionImage = `${imagePath}/heads/Head - Bastion.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBulkheadImage = `${imagePath}/heads/Head - Bulkhead.png`;
const headBunkerImage = `${imagePath}/heads/Head - Bunker.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDeadboltImage = `${imagePath}/heads/Head - Deadbolt.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headRainImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headSlipstreamImage = `${imagePath}/heads/Head - Slipstream.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponLeftGrandAxeImage = `${imagePath}/weapons/Weapon - Grand Axe.png`;
const weaponLeftGrandAxeUnderImage = `${imagePath}/weapons/Weapon Under - Grand Axe.png`;
const weaponLeftGrandHammerImage = `${imagePath}/weapons/Weapon - Grand Hammer.png`;
const weaponLeftGrandHammerUnderImage = `${imagePath}/weapons/Weapon Under - Grand Hammer.png`;

// RIGHT
const weaponRightFistImage = `${imagePath}/weapons/Right Arm - Fist.png`;
const weaponRightGravityImage = `${imagePath}/weapons/Right Arm - Gravity.png`;
const weaponRightGravityDetritusImage = `${imagePath}/weapons/Right Arm - Gravity Detritus.png`;

// REAR MOUNT
const armorDetritusImage = `${imagePath}/rear/Armor - Detritus.png`;

// GLOWS
const glowSlothEyeImage = `${imagePath}/glows/Glow - Sloth Eye.png`;
const glowGrandAxeImage = `${imagePath}/glows/Glow - Grand Axe.png`;
const glowGravityOrbImage = `${imagePath}/glows/Glow - Black Orb.png`;
const glowGravityOrbDetritusImage = `${imagePath}/glows/Glow - Black Orb - Detritus.png`;
const glowGravityImage = `${imagePath}/glows/Glow - Gravity.png`;
const glowGravityDetritusImage = `${imagePath}/glows/Glow - Gravity Detritus.png`;
const glowGravityOrbOnlyImage = `${imagePath}/glows/Glow - Gravity - Orb Only.png`;
const glowGravityDetritusOrbOnlyImage = `${imagePath}/glows/Glow - Gravity Detritus - Orb Only.png`;
const glowGravityArcsImage = `${imagePath}/glows/Glow - Gravity Arcs.png`;
const glowGravityArcsDetritusImage = `${imagePath}/glows/Glow - Gravity Arcs Detritus.png`;
const glowSecondaryImage = `${imagePath}/glows/Glow - Secondary.png`;
const glowDetritusSecondaryImage = `${imagePath}/glows/Glow - Detritus Secondary.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/Sloth_by_gummies139.png`;
const core2Image = `${imagePath}/CORE2SLOTH-AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-10px",
    right: "12px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "1",
        "accessory1Value": "0",
        "accessory2Value": "3",
        "accessory3Value": "NONE",
        "accessory4Value": "2",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SLOTH",
            src: headSlothImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "BASTION",
            src: headBastionImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "BULKHEAD",
            src: headBulkheadImage,
        },
        {
            value: 5,
            label: "BUNKER",
            src: headBunkerImage,
        },
        {
            value: 6,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 7,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 8,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 9,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 10,
            label: "DEADBOLT",
            src: headDeadboltImage,
        },
        {
            value: 11,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 12,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 13,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 14,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 15,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 16,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 17,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 18,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 19,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 20,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 21,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 22,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 23,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 24,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 25,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 26,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 27,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 28,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 29,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 30,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 31,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 32,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 33,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 34,
            label: "SLIPTSTREAM",
            src: headSlipstreamImage,
        },
        {
            value: 35,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 36,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 37,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 38,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SLOTH",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "GRAND AXE",
                src: weaponLeftGrandAxeImage,
                backSrc: weaponLeftGrandAxeUnderImage,
            },
            {
                value: 1,
                label: "GRAND HAMMER",
                src: weaponLeftGrandHammerImage,
                backSrc: weaponLeftGrandHammerUnderImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FIST",
            src: weaponRightFistImage,
        },
        {
            value: 1,
            label: "GRAVITY",
            src: weaponRightGravityImage,
        },
        {
            value: 2,
            label: "GRAVITY - DETRITUS",
            src: weaponRightGravityDetritusImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "DETRITUS",
            frontSrc: armorDetritusImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "SLOTH - EYE",
            frontSrc: glowSlothEyeImage,
        },
        {
            value: 1,
            label: "GRAND AXE",
            frontSrc: glowGrandAxeImage,
        },
        {
            value: 2,
            label: "GRAVITY - BLACK ORB",
            frontSrc: glowGravityOrbImage,
        },
        {
            value: 3,
            label: "GRAVITY",
            frontSrc: glowGravityImage,
        },
        {
            value: 4,
            label: "GRAVITY - SECONDARY",
            frontSrc: glowSecondaryImage,
        },
        {
            value: 5,
            label: "GRAVITY - ORB ONLY",
            frontSrc: glowGravityOrbOnlyImage,
        },
        {
            value: 6,
            label: "GRAVITY + ARCS",
            frontSrc: glowGravityArcsImage,
        },
        {
            value: 7,
            label: "GRAVITY - DETRITUS",
            frontSrc: glowGravityDetritusImage,
        },
        {
            value: 8,
            label: "GRAVITY - BLACK ORB - DETRITUS",
            frontSrc: glowGravityOrbDetritusImage,
        },
        {
            value: 9,
            label: "GRAVITY - SECONDARY - DETRITUS",
            frontSrc: glowDetritusSecondaryImage,
        },
        {
            value: 10,
            label: "GRAVITY - DETRITUS - ORB ONLY",
            frontSrc: glowGravityDetritusOrbOnlyImage,
        },
        {
            value: 11,
            label: "GRAVITY + ARCS - DETRITUS",
            frontSrc: glowGravityArcsDetritusImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "ARMOR",
            value: "rearMount",
        },
        {
            label: "L HAND",
            value: "weapon",
        },
        {
            label: "R HAND",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ARMOR",
        "weapon": "L HAND",
        "secondaryWeapon": "R HAND",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
