import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Ibutho`;

// BODY
const bodyChassisStockImage = `${imagePath}/Chassis - Stock Standard.png`;
const bodyChassis1Image = `${imagePath}/Chassis - Mk I.png`;
const bodyChassis2Image = `${imagePath}/Chassis - Mk II.png`;
const bodyChassis3Image = `${imagePath}/Chassis - Mk III.png`;

// HEADS
const headMk1Image = `${imagePath}/heads/Head - Mk I.png`;
const headMk1FeathersImage = `${imagePath}/heads/Head - Mk I - Feathers.png`;
const headMk2Image = `${imagePath}/heads/Head - Mk II.png`;
const headMk2FeathersImage = `${imagePath}/heads/Head - Mk II - Feathers.png`;
const headMk3EndleImage = `${imagePath}/heads/Head - Mk III - Endle.png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDeadboltImage = `${imagePath}/heads/Head - Deadbolt.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headZahhakImage = `${imagePath}/heads/Head - Zahhak.png`;

// WEAPONS
// LEFT
const leftWeaponShieldImage = `${imagePath}/weapons/left/Weapons - Left - Shield.png`;
const leftWeaponShieldMaceImage = `${imagePath}/weapons/left/Weapons - Left - Shield + Mace.png`;
const leftWeaponMambeleImage = `${imagePath}/weapons/left/Weapons - Left - Mambele.png`;
const leftWeaponPistolImage = `${imagePath}/weapons/left/Weapons - Left - Pistol.png`;
const leftWeaponARImage = `${imagePath}/weapons/left/Weapons - Left - Assault Rifle.png`;
const leftWeaponShotgunImage = `${imagePath}/weapons/left/Weapons - Left - Shotgun.png`;

// RIGHT
const rightWeaponIklwaImage = `${imagePath}/weapons/right/Weapons - Right - Iklwa.png`;
const rightWeaponIwisaImage = `${imagePath}/weapons/right/Weapons - Right - Iwisa.png`;
const rightWeaponKukriImage = `${imagePath}/weapons/right/Weapons - Right - Kukri.png`;
const rightWeaponMambeleImage = `${imagePath}/weapons/right/Weapons - Right - Mambele.png`;
const rightWeaponBladeImage = `${imagePath}/weapons/right/Weapons - Right - Blade.png`;
const rightWeaponARImage = `${imagePath}/weapons/right/Weapons - Right - Assault Rifle.png`;

// ACCESSORY
const accessoryARStrapImage = `${imagePath}/accessory/Accessory - Assault Rifle Strap.png`;

// GLOWS
const glowChassisStandardImage = `${imagePath}/glows/Glow - Chassis - Standard.png`;
const glowChassisMk1Image = `${imagePath}/glows/Glow - Chassis - Mk I.png`;
const glowChassisMk2Image = `${imagePath}/glows/Glow - Chassis - Mk II.png`;
const glowChassisMk3Image = `${imagePath}/glows/Glow - Chassis - Mk III.png`;
const glowHeadMk1Image = `${imagePath}/glows/Glow - Head -  Mk I.png`;
const glowHeadMk2Image = `${imagePath}/glows/Glow - Head - Mk II.png`;
const glowHeadMk3Image = `${imagePath}/glows/Glow - Head - Mk III.png`;
const glowWeaponLeftShieldImage = `${imagePath}/glows/Glow - Weapons - Left Shield.png`;
const glowWeaponRightIklwaImage = `${imagePath}/glows/Glow - Weapon - Right Iklwa.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/core.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "63px",
    right: "70px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gambaina",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "2",
        "headValue": "3",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "4",
        "accessory2Value": "0",
        "accessory3Value": "1",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "Mk I",
            src: headMk1Image,
        },
        {
            value: 1,
            label: "Mk I - FEATHERS",
            src: headMk1FeathersImage,
        },
        {
            value: 2,
            label: "Mk II",
            src: headMk2Image,
        },
        {
            value: 3,
            label: "Mk II - FEATHERS",
            src: headMk2FeathersImage,
        },
        {
            value: 4,
            label: "Mk III - ENDLE",
            src: headMk3EndleImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "8 BALL",
            src: head8BallImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "BLACKSPOT",
            src: headBlackspotImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "CHEDDAH",
            src: headCheddahImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "DEADBOLT",
            src: headDeadboltImage,
            isPremium: true,
        },

        {
            value: 10,
            label: "DUNGAM",
            src: headDungamImage,
            isPremium: true,
        },

        {
            value: 11,
            label: "HOOD",
            src: headHoodImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "MECHA 1",
            src: headMecha1Image,
            isPremium: true,
        },
        {
            value: 18,
            label: "MECHA 2",
            src: headMecha2Image,
            isPremium: true,
        },
        {
            value: 19,
            label: "MECHA 3",
            src: headMecha3Image,
            isPremium: true,
        },
        {
            value: 20,
            label: "MECHA 4",
            src: headMecha4Image,
            isPremium: true,
        },
        {
            value: 21,
            label: "MECHA 5",
            src: headMecha5Image,
            isPremium: true,
        },
        {
            value: 22,
            label: "MECHA 6",
            src: headMecha6Image,
            isPremium: true,
        },
        {
            value: 23,
            label: "MECHA 7",
            src: headMecha7Image,
            isPremium: true,
        },
        {
            value: 24,
            label: "MECHA 8",
            src: headMecha8Image,
            isPremium: true,
        },
        {
            value: 25,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 26,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 27,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 28,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 29,
            label: "SKULL",
            src: headSkullImage,
            isPremium: true,
        },
        {
            value: 30,
            label: "VANQUISHER",
            src: headVanquisherImage,
            isPremium: true,
        },
        {
            value: 31,
            label: "VICEROY",
            src: headViceroyImage,
            isPremium: true,
        },
        {
            value: 32,
            label: "VISIONARY",
            src: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 33,
            label: "ZAHHAK",
            src: headZahhakImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "STOCK STANDARD",
            src: bodyChassisStockImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by @StationUkuwa, original by",
                title: "@siegefault",
                url: "https://twitter.com/siegefault/status/1480571064849772552",
            },
        },
        {
            value: 1,
            label: "Mk I",
            src: bodyChassis1Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "Mk II",
            src: bodyChassis2Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "Mk III",
            src: bodyChassis3Image,
            isPremium: true,
            isLegendary: true,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SHIELD",
                src: leftWeaponShieldImage,
            },
            {
                value: 1,
                label: "SHIELD + MACE",
                src: leftWeaponShieldMaceImage,
                isPremium: true,
            },
            {
                value: 2,
                label: "MAMBELE",
                foremostSrc: leftWeaponMambeleImage,
                isPremium: true,
            },
            {
                value: 3,
                label: "PISTOL",
                src: leftWeaponPistolImage,
                isPremium: true,
            },
            {
                value: 4,
                label: "AR",
                src: leftWeaponARImage,
                isPremium: true,
            },
            {
                value: 5,
                label: "SHOTGUN",
                src: leftWeaponShotgunImage,
                isPremium: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "IKLWA",
            src: rightWeaponIklwaImage,
        },
        {
            value: 1,
            label: "IWISA",
            src: rightWeaponIwisaImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "KUKRI",
            src: rightWeaponKukriImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "MAMBELE",
            src: rightWeaponMambeleImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "BLADE",
            src: rightWeaponBladeImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "AR",
            src: rightWeaponARImage,
            isPremium: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "WEAPON - SHIELD",
            frontSrc: glowWeaponLeftShieldImage,
        },
        {
            value: 1,
            label: "WEAPON - IKLWA",
            frontSrc: glowWeaponRightIklwaImage,
        },
        {
            value: 2,
            label: "CHASSIS - STANDARD",
            src: glowChassisStandardImage,
        },
        {
            value: 3,
            label: "CHASSIS - MK I",
            src: glowChassisMk1Image,
        },
        {
            value: 4,
            label: "CHASSIS - MK II",
            src: glowChassisMk2Image,
        },
        {
            value: 5,
            label: "CHASSIS - MK III",
            src: glowChassisMk3Image,
            isPremium: true,
        },
        {
            value: 6,
            label: "HEAD - MK I",
            frontSrc: glowHeadMk1Image,
        },
        {
            value: 7,
            label: "HEAD - MK II",
            frontSrc: glowHeadMk2Image,
        },
        {
            value: 8,
            label: "HEAD - MK III",
            frontSrc: glowHeadMk3Image,
            isPremium: true,
        },
        {
            value: 9,
            label: "ACCESSORY - STRAPPED AR",
            frontSrc: accessoryARStrapImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS / ACCESSORY",
            value: "ACCESSORIES",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "ACCESSORY",
    },
};
