import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Autochthon-Makhana-Chrysoprase`;

// BODY
const bodyChrysopraseImage = `${imagePath}/Body Chrysoprase.png`;
const legsChrysopraseImage = `${imagePath}/legs/Legs Chrysoprase.png`;

// HEADS
const headChrysopraseImage = `${imagePath}/head/Head Chrysoprase.png`;

// ARMS
const rightArmImage = `${imagePath}/arms/Right Arm.png`;
const leftArmImage = `${imagePath}/arms/Left Arm.png`;

// WEAPONS
const weaponAxeImage = `${imagePath}/weapon/Weapon Axe.png`;
const weaponInversionBladeImage = `${imagePath}/weapon/Weapon Inversion Blade.png`;

// REAR MOUNT
const rearJetsImage = `${imagePath}/rear/Back Jets.png`;
const rearMantisClawsImage = `${imagePath}/rear/Back Mantis Claws.png`;

// GLOWS
const glowAxeImage = `${imagePath}/glows/Glow Axe.png`;
const glowInversionBladeImage = `${imagePath}/glows/Glow Inversion Blade.png`;
const glowHeadImage = `${imagePath}/glows/Glow Chrysoprase Head.png`;

// ACCESSORY
const accessoryTorsoMissilePodsImage = `${imagePath}/accessory/Torso Missile Pods.png`;
const accessoryTorsoSignalBolterImage = `${imagePath}/accessory/Torso Signal Bolter.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SMALL,
    bottom: "79px",
    right: "102px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "1",
        "secondaryWeaponValue": "0",
        "accessory1Value": "2",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CHRYSOPRASE",
            src: headChrysopraseImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CHRYSOPRASE",
            src: bodyChrysopraseImage,
            backSrc: legsChrysopraseImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Umbral Reaver",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AXE",
                src: weaponAxeImage,
            },
            {
                value: 1,
                label: "INVERSION BLADE",
                src: weaponInversionBladeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ARMS",
            src: leftArmImage,
            backSrc: rightArmImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "JETS",
            src: rearJetsImage,
        },
        {
            value: 1,
            label: "MANTIS CLAWS",
            src: rearMantisClawsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "AXE - GLOW",
            frontSrc: glowAxeImage,
        },
        {
            value: 1,
            label: "INVERSION BLADE - GLOW",
            frontSrc: glowInversionBladeImage,
        },
        {
            value: 2,
            label: "CHRYSOPRASE HEAD - GLOW",
            frontSrc: glowHeadImage,
        },
        {
            value: 3,
            label: "TORSO MISSILE PODS",
            src: accessoryTorsoMissilePodsImage,
        },
        {
            value: 4,
            label: "TORSO SIGNAL BOLTER",
            src: accessoryTorsoSignalBolterImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS / ACCESSORY",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "ARMS",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "ARMS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "ACCESSORY",
    },
};
