import BaseURL from "./images/Hosting-BaseURL";
import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/IPS/Lancaster`;

// BODY
const bodyLancasterImage = `${imagePath}/Body Lancaster.png`;

// LEGS
const legs4LegsFrontImage = `${imagePath}/legs/Legs - 4 Legs Top.png`;
const legs4LegsBackImage = `${imagePath}/legs/Legs - 4 Legs Back.png`;
const legsQuadFrontImage = `${imagePath}/legs/Legs - Quad Top.png`;
const legsQuadBackImage = `${imagePath}/legs/Legs - Quad Back.png`;
const legsTreadsImage = `${imagePath}/legs/Legs - Treads.png`;
// PREMIUM
const legs2LegsImage = `${imagePath}/legs/Legs - 2 Legs.png`;
const legsWheelsFrontImage = `${imagePath}/legs/Legs - Wheels Top.png`;
const legsWheelsBackImage = `${imagePath}/legs/Legs - Wheels Back.png`;
const legsBigWheelsFrontImage = `${imagePath}/legs/Legs - Big Wheels Top.png`;
const legsBigWheelsBackImage = `${imagePath}/legs/Legs - Big Wheels Back.png`;

// ACCESSORIES
const accessoryDronesImage = `${imagePath}/accessories/Accessory - Drones.png`;
const accessoryTurretsImage = `${imagePath}/accessories/Accessory - Turrets.png`;
const accessoryManipulatorsImage = `${imagePath}/accessories/Accessory - Manipulators Back.png`;
const accessoryManipulatorsFrontImage = `${imagePath}/accessories/Accessory - Manipulators Top.png`;
const accessoryManipulatorsFrontShieldImage = `${imagePath}/accessories/Accessory - Manipulators Top with Shield.png`;
const accessorySoxImage = `${imagePath}/accessories/Sox.png`;
const accessorySoxShoesSkirtImage = `${imagePath}/accessories/Sox Shoes Skirt.png`;
const accessorySoxSkirtImage = `${imagePath}/accessories/Sox Skirt.png`;
const accChassisDetailImage = `${imagePath}/accessories/chassis-LANCASTER-mid.png`;

// 1H LEFT
const assaultCannonLeftImage = `${imagePath}/weapons/left/Main Weapon - Assault Cannon Left.png`;
const assaultCannonLeftFront = `${imagePath}/weapons/left/Main Weapon - Assault Cannon Left Over.png`;
const assaultCannonTopImage = `${imagePath}/weapons/left/Main Weapon - Assault Cannon Top.png`;
const assaultCannonUnderImage = `${imagePath}/weapons/left/Main Weapon - Assault Cannon Underslung.png`;
const assaultRifleLeftImage = `${imagePath}/weapons/left/Main Weapon - Assault Rifle Left.png`;
const assaultRifleRightImage = `${imagePath}/weapons/left/Main Weapon - Assault Rifle Right.png`;
const doubleMissilePodsImage = `${imagePath}/weapons/left/Main Weapon - Double Missile Pods.png`;

const coreImage = `${imagePath}/Lancaster (by Corvon).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "74px",
    right: "37px",
};

const quadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "86px",
    right: "12px",
};

const wheelLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "102px",
    right: "51px",
};

const wheelBigLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "95px",
    right: "38px",
};

const treadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "108px",
    right: "27px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Corvon",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreLegs: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "0",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "LANCASTER",
            src: bodyLancasterImage,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT CANNON - LEFT",
                backSrc: assaultCannonLeftImage,
                src: assaultCannonLeftFront,
            },
            {
                value: 1,
                label: "ASSAULT CANNON - TOP",
                src: assaultCannonTopImage,
            },
            {
                value: 2,
                label: "ASSAULT CANNON - UNDER",
                backSrc: assaultCannonUnderImage,
            },
            {
                value: 3,
                label: "ASSAULT RIFLE - LEFT",
                backSrc: assaultRifleLeftImage,
            },
            {
                value: 4,
                label: "ASSAULT RIFLE - RIGHT",
                src: assaultRifleRightImage,
            },
            {
                value: 5,
                label: "MISSILE PODS",
                src: doubleMissilePodsImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MANIPULATORS",
            backSrc: accessoryManipulatorsImage,
            src: accessoryManipulatorsFrontImage,
        },
        {
            value: 1,
            label: "MANIPULATORS + SHIELD",
            backSrc: accessoryManipulatorsImage,
            src: accessoryManipulatorsFrontShieldImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "4 LEGS",
            backSrc: legs4LegsBackImage,
            frontSrc: legs4LegsFrontImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "QUAD",
            backSrc: legsQuadBackImage,
            frontSrc: legsQuadFrontImage,
            shadowDetails: quadLegsShadow,
        },
        {
            value: 2,
            label: "TREADS",
            backSrc: legsTreadsImage,
            shadowDetails: treadLegsShadow,
        },
        {
            value: 3,
            label: "2 LEGS",
            backSrc: legs2LegsImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
        },
        {
            value: 4,
            label: "WHEELS",
            backSrc: legsWheelsBackImage,
            frontSrc: legsWheelsFrontImage,
            shadowDetails: wheelLegsShadow,
            isPremium: true,
        },
        {
            value: 5,
            label: "BIG WHEELS",
            backSrc: legsBigWheelsBackImage,
            frontSrc: legsBigWheelsFrontImage,
            shadowDetails: wheelBigLegsShadow,
            isPremium: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "DRONES",
            src: accessoryDronesImage,
        },
        {
            value: 1,
            label: "TURRETS",
            frontSrc: accessoryTurretsImage,
        },
        {
            value: 2,
            label: "SOX",
            src: accessorySoxImage,
        },
        {
            value: 3,
            label: "SOX + SKIRT",
            src: accessorySoxSkirtImage,
        },
        {
            value: 4,
            label: "SOX + SKIRT + SHOES",
            src: accessorySoxShoesSkirtImage,
        },
        {
            value: 5,
            label: "COLOR - CHASSIS DETAILS",
            src: accChassisDetailImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEGS",
            value: "rearMount",
        },
        {
            label: "MAIN WEAPON",
            value: "weapon",
        },
        {
            label: "AUX WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "LEGS",
        "weapon": "MAIN WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
