import styled from "styled-components";

import UnitVariantControl from "./UnitVariantControl";

const UnitCenterButton = styled(UnitVariantControl)`
    align-self: flex-end;
    margin-left: auto;
    margin-right: auto;
`;

UnitCenterButton.displayName = "UnitCenterButton";

export const UnitDualCenterButton = styled(UnitVariantControl)`
    align-self: flex-end;

    margin-left: ${props => props.isFirst ? "auto" : "15px"};
    margin-right: ${props => props.isFirst ? "15px" : "auto"};
`;

UnitDualCenterButton.displayName = "UnitDualCenterButton";

export default UnitCenterButton;
