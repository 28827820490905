import styled from "styled-components";

export const UIText = styled.div`
    color: #FFF;
    font-weight: bold;
    font-size: 17px;
    text-align: left;
    text-shadow: 2px 2px #000;

    &.mech {
        font-weight: normal;
        font-family: Arial, Helvetica, sans-serif;
        text-shadow: unset;
    }

    @media print {
        display: none;
    }
`;

UIText.displayName = "UIText";

export const UITextButton = styled(UIText)`
    cursor: pointer;
`;

UITextButton.displayName = "UITextButton";
