import React from "react";
import { bool, func, object, number } from "prop-types";

import MechUnitImage, { MechUnitContainer } from "../../styles/MechUnitImage";

import { brandList, npcLayerOrder } from "../../LayerData/MechNPCLayerOrder";
import { createLayerOptions } from "../../LayerData/CreateLayerFunctions";

function MechNPCCategoryDisplay({ unit, mirroredDisplayFlag, mirroredFunc, isInCategory, zoomedFunc, zoomedDisplayValue }) {
    let className = mirroredDisplayFlag ? "mirrored" : "";
    className += zoomedDisplayValue ? ` zoomed-${zoomedDisplayValue}` : "";

    return (
        <MechUnitContainer
            onClick={() => {
                if (mirroredFunc) {
                    mirroredFunc();
                }

                if (zoomedFunc) {
                    zoomedFunc();
                }
            }}
            isZoomable={!!zoomedFunc}
            isMegadeusPlus={brandList[unit.brandValue][unit.frameValue].isMegadeusPlus}
            isMegadeusPlusPlus={brandList[unit.brandValue][unit.frameValue].isMegadeusPlusPlus}
            isMegadeusOmega={brandList[unit.brandValue][unit.frameValue].isMegadeusOmega}
        >
            {createLayerOptions({unit, brandList, layerOrder: npcLayerOrder}).map((layerOptions) => (
                <MechUnitImage
                    className={className}
                    {...layerOptions}
                />
            ))}
        </MechUnitContainer>
    );
}

MechNPCCategoryDisplay.propTypes = {
    unit: object.isRequired,
    mirroredDisplayFlag: bool,
    mirroredFunc: func,
    isInCategory: bool,
    zoomedDisplayValue: number,
    zoomedFunc: func,
};

MechNPCCategoryDisplay.defaultProps = {
    mirroredDisplayFlag: false,
    isInCategory: false,
    mirroredFunc: () => { },
    zoomedDisplayValue: 0,
    zoomedFunc: () => {},
};

export default MechNPCCategoryDisplay;
