import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Dark-Glint`;

// BODY
const bodyChassisImage = `${imagePath}/dark glint_chassis.png`;

// HEADS
const headDarkGlintImage = `${imagePath}/heads/dark glint_head_dark glint.png`;
const headBlastardImage = `${imagePath}/heads/dark glint_head_blastard.png`;
const headCheddahImage = `${imagePath}/heads/dark glint_head_cheddah.png`;
const headChimeraImage = `${imagePath}/heads/dark glint_head_chimera.png`;
const headDarkspotImage = `${imagePath}/heads/dark glint_head_darkspot.png`;
const headEnkiduImage = `${imagePath}/heads/dark glint_head_enkidu.png`;
const headKazuImage = `${imagePath}/heads/dark glint_head_Kazu.png`;
const headL10Image = `${imagePath}/heads/dark glint_head_L-10.png`;
const headLaborerImage = `${imagePath}/heads/dark glint_head_laborer.png`;
const headMaliceImage = `${imagePath}/heads/dark glint_head_malice.png`;
const headMonarchImage = `${imagePath}/heads/dark glint_head_monarch.png`;
const headScopehoundImage = `${imagePath}/heads/dark glint_head_scopehound.png`;
const headSilvaImage = `${imagePath}/heads/dark glint_head_silva.png`;
const headWitchImage = `${imagePath}/heads/dark glint_head_witch.png`;

// WEAPONS
// LEFT
const leftWeaponEnergyBladeImage = `${imagePath}/weapons/dark glint_weapons left_energy blade.png`;
const leftWeaponRifleImage = `${imagePath}/weapons/dark glint_weapons left_rifle.png`;

// RIGHT
const rightWeaponEnergyBladeImage = `${imagePath}/weapons/dark glint_weapons right_energy blade.png`;
const rightWeaponRifleImage = `${imagePath}/weapons/dark glint_weapons right_rifle.png`;

// REAR MOUNT
const rearMountCoolantCablesImage = `${imagePath}/rearmount/dark glint_rearmount_Coolant Cables.png`;
const rearMountWingpackImage = `${imagePath}/rearmount/dark glint_rearmount_wingpack.png`;

// ACCESSORIES
const emblemSSCImage = `${imagePath}/accessory/dark glint_emblem_SSC.png`;
const emblemDarkGlintImage = `${imagePath}/accessory/dark glint_emblem_dark glint.png`;
const emblemHarrisonArmoryImage = `${imagePath}/accessory/dark glint_emblem_Harrison Armory.png`;
const emblemHorusImage = `${imagePath}/accessory/dark glint_emblem_horus.png`;
const emblemIPSNImage = `${imagePath}/accessory/dark glint_emblem_IPS-N.png`;

// GLOWS
const glowCablesImage = `${imagePath}/glows/dark glint_accessories mid_Cables Glow.png`;
const glowDarkGlintEyesImage = `${imagePath}/glows/dark glint_accessories front_dark glint eyes.png`;
const glowEnergyBladeBothImage = `${imagePath}/glows/dark glint_accessories front_energy blade both.png`;
const glowEnergyBladeLeftImage = `${imagePath}/glows/dark glint_accessories front_energy blade left.png`;
const glowEnergyBladeRightImage = `${imagePath}/glows/dark glint_accessories front_energy blade right.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-34px",
    right: "26px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "2",
        "weaponValue": "1",
        "secondaryWeaponValue": "1",
        "accessory1Value": "1",
        "accessory2Value": "0",
        "accessory3Value": "NONE",
        "accessory4Value": "5",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "DARK GLINT",
            src: headDarkGlintImage,
        },
        {
            value: 1,
            label: "BLASTARD",
            src: headBlastardImage,
        },
        {
            value: 2,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 3,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 4,
            label: "DARKSPOT",
            src: headDarkspotImage,
        },
        {
            value: 5,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 6,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 7,
            label: "L-10",
            src: headL10Image,
        },
        {
            value: 8,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 9,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 10,
            label: "MONARCH",
            src: headMonarchImage,
        },
        {
            value: 11,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
        },
        {
            value: 12,
            label: "SILVA",
            src: headSilvaImage,
        },
        {
            value: 13,
            label: "WITCH",
            src: headWitchImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "DARK GLINT",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Pinkhyena",
                prefixText: "Support with +2 patron tier. Commissioned by Sebmaster777. Created by ",
                url: "https://twitter.com/LuckyTyphlo",
            },
            isPlusTwo: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ENERGY BLADE",
                src: leftWeaponEnergyBladeImage,
            },
            {
                value: 1,
                label: "RIFLE",
                src: leftWeaponRifleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ENERGY BLADE",
            src: rightWeaponEnergyBladeImage,
        },
        {
            value: 1,
            label: "RIFLE",
            src: rightWeaponRifleImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "COOLANT CABLES",
            src: rearMountCoolantCablesImage,
        },
        {
            value: 1,
            label: "WINGPACK",
            src: rearMountWingpackImage,
        },
        {
            value: 2,
            label: "COOLANT CABLES + WINGPACK",
            src: rearMountCoolantCablesImage,
            backSrc: rearMountWingpackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CABLES",
            src: glowCablesImage,
        },
        {
            value: 1,
            label: "DARK GLINT EYES",
            frontSrc: glowDarkGlintEyesImage,
        },
        {
            value: 2,
            label: "ENERGY BLADE - LEFT",
            frontSrc: glowEnergyBladeLeftImage,
        },
        {
            value: 3,
            label: "ENERGY BLADE - RIGHT",
            frontSrc: glowEnergyBladeRightImage,
        },
        {
            value: 4,
            label: "ENERGY BLADE - BOTH",
            frontSrc: glowEnergyBladeBothImage,
        },
        {
            value: 5,
            label: "EMBLEM - DARK GLINT",
            src: emblemDarkGlintImage,
        },
        {
            value: 6,
            label: "EMBLEM - HARRISON ARMORY",
            src: emblemHarrisonArmoryImage,
        },
        {
            value: 7,
            label: "EMBLEM - HORUS",
            src: emblemHorusImage,
        },
        {
            value: 8,
            label: "EMBLEM - IPS-N",
            src: emblemIPSNImage,
        },
        {
            value: 9,
            label: "EMBLEM - SSC",
            src: emblemSSCImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS / ACCESSORY",
            value: "ACCESSORIES",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "ACCESSORY",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "ACCESSORY",
    },
};
