import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/Chassis.png`;

// ARMS
const armsHandsImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/heads/Arms - Hands.png`;
const armsARsImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/heads/Arms - ARs.png`;

// SHOULDERS
// LEFT
const accessoryLeftShoulderClosedImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/left/Left Shoulder - Closed.png`;
const accessoryLeftShoulderMicromissilesImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/left/Left Shoulder - Micromissiles.png`;
const accessoryLeftShoulderMissiles1Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/left/Left Shoulder - Missiles 1.png`;
const accessoryLeftShoulderMissiles2Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/left/Left Shoulder - Missiles 2.png`;
const accessoryLeftShoulderRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/left/Left Shoulder - Railgun.png`;
const accessoryLeftShoulderLargeRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/left/Left Railgun.png`;
const accessoryLeftShoulderHackingArrayImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/left/Left Hacking Array.png`;
// RIGHT
const accessoryRightShoulderClosedImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/right/Right Shoulder - Closed.png`;
const accessoryRightShoulderMicromissilesImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/right/Right Shoulder - Micromissiles.png`;
const accessoryRightShoulderMissiles1Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/right/Right Shoulder - Missiles 1.png`;
const accessoryRightShoulderMissiles2Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/right/Right Shoulder - Missiles 2.png`;
const accessoryRightShoulderRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/right/Right Shoulder - Railgun.png`;
const accessoryRightShoulderLargeRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/right/Right Railgun.png`;
const accessoryRightShoulderHackingArrayImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/weapons/right/Right Hacking Array.png`;

// REAR MOUNT
const rearMountTargettingSystemImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Targetting System.png`;
const rearMountMissilePod1UnderImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Missiles Under.png`;
const rearMountMissilePod1Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Missiles.png`;
const rearMountMissilePod2UnderImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Missiles 2 Under.png`;
const rearMountMissilePod2Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Missiles 2.png`;
const rearMountMissileOlothontorUnderImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Missiles Olothontor Rear.png`;
const rearMountMissileOlothontorImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Missiles Olothontor.png`;
const rearMountLargeMissileUnderImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Large Missiles Under.png`;
const rearMountLargeMissileImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/rear mount/Rear - Large Missiles.png`;

// GLOWS
const glowLeftHackingArray1Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/glows/Glow - Left Hacking Array.png`;
const glowLeftHackingArray2Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/glows/Glow - Left Hacking Array 2.png`;
const glowLeftHackingArray3Image = `${BaseURL}/Mechs/Custom/Cambiare Quad/glows/Glow - Left Hacking Array 3.png`;
const glowLeftHackingArrayHackImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/glows/Glow - Left Hacking Array Hack Effect.png`;
const glowLeftRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/glows/Glow - Left Railgun.png`;
const glowRightHackingArrayImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/glows/Glow - Right Hacking Array.png`;
const glowRightRailgunImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/glows/Glow - Right Railgun.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Cambiare Quad/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "63px",
    right: "14px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HAND",
            src: armsHandsImage,
        },
        {
            value: 1,
            label: "AR",
            src: armsARsImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CAMBIARE QUAD",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Tamrielo",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CLOSED",
                src: accessoryLeftShoulderClosedImage,
            },
            {
                value: 1,
                label: "MICROMISSILES",
                src: accessoryLeftShoulderMicromissilesImage,
            },
            {
                value: 2,
                label: "MISSILES 1",
                src: accessoryLeftShoulderMissiles1Image,
            },
            {
                value: 3,
                label: "MISSILES 2",
                src: accessoryLeftShoulderMissiles2Image,
            },
            {
                value: 4,
                label: "CANNON",
                src: accessoryLeftShoulderRailgunImage,
            },
            {
                value: 5,
                label: "RAILGUN",
                src: accessoryLeftShoulderLargeRailgunImage,
            },
            {
                value: 6,
                label: "HACKING ARRAY",
                src: accessoryLeftShoulderHackingArrayImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CLOSED",
            frontSrc: accessoryRightShoulderClosedImage,
        },
        {
            value: 1,
            label: "MICROMISSILES",
            frontSrc: accessoryRightShoulderMicromissilesImage,
        },
        {
            value: 2,
            label: "MISSILES 1",
            frontSrc: accessoryRightShoulderMissiles1Image,
        },
        {
            value: 3,
            label: "MISSILES 2",
            frontSrc: accessoryRightShoulderMissiles2Image,
        },
        {
            value: 4,
            label: "CANNON",
            frontSrc: accessoryRightShoulderRailgunImage,
        },
        {
            value: 5,
            label: "RAILGUN",
            frontSrc: accessoryRightShoulderLargeRailgunImage,
        },
        {
            value: 6,
            label: "HACKING ARRAY",
            frontSrc: accessoryRightShoulderHackingArrayImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "TARGETTING SYSTEM",
            foremostSrc: rearMountTargettingSystemImage,
        },
        {
            value: 1,
            label: "MISSILE POD 1",
            foremostSrc: rearMountMissilePod1Image,
            backSrc: rearMountMissilePod1UnderImage,
        },
        {
            value: 2,
            label: "MISSILE POD 2",
            foremostSrc: rearMountMissilePod2Image,
            backSrc: rearMountMissilePod2UnderImage,
        },
        {
            value: 3,
            label: "MISSILES - OLOTHONTOR",
            foremostSrc: rearMountMissileOlothontorImage,
            backSrc: rearMountMissileOlothontorUnderImage,
        },
        {
            value: 4,
            label: "LARGE MISSILES",
            foremostSrc: rearMountLargeMissileImage,
            backSrc: rearMountLargeMissileUnderImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "HACKING ARRAY 1 - LEFT",
            frontSrc: glowLeftHackingArray1Image,
        },
        {
            value: 1,
            label: "HACKING ARRAY 2 - LEFT",
            frontSrc: glowLeftHackingArray2Image,
        },
        {
            value: 2,
            label: "HACKING ARRAY 3 - LEFT",
            frontSrc: glowLeftHackingArray3Image,
        },
        {
            value: 3,
            label: "HACKING ARRAY EFFECT - LEFT",
            frontSrc: glowLeftHackingArrayHackImage,
        },
        {
            value: 4,
            label: "RAILGUN - LEFT",
            frontSrc: glowLeftRailgunImage,
        },
        {
            value: 5,
            label: "HACKING ARRAY - RIGHT",
            frontSrc: glowRightHackingArrayImage,
        },
        {
            value: 6,
            label: "RAILGUN - RIGHT",
            frontSrc: glowRightRailgunImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "SHOULDERS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "ARMS",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L SHOULDER",
            value: "weapon",
        },
        {
            label: "R SHOULDER",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "ARMS",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "L SHOULDER",
        "secondaryWeapon": "R SHOULDER",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
