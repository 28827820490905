import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// CHASSIS
const chassisWreck1Image = `${BaseURL}/Mechs/Misc/Wrecks/chassis/Wreck 1.png`;
const chassisWreck2Image = `${BaseURL}/Mechs/Misc/Wrecks/chassis/Wreck 2.png`;
const chassisWreck3Image = `${BaseURL}/Mechs/Misc/Wrecks/chassis/Wreck 3.png`;
const chassisWreck4Image = `${BaseURL}/Mechs/Misc/Wrecks/chassis/Size 2 Wreck 1.png`;
const chassisWreck5Image = `${BaseURL}/Mechs/Misc/Wrecks/chassis/Size 3 Wreck.png`;

// CRATER
const craterImage = `${BaseURL}/Mechs/Misc/Wrecks/accessory/Crater.png`;

// GLOWS
const glowWreck1Image = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Wreck 1.png`;
const glowWreck1FlamesImage = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Wreck 1 Flames.png`;
const glowWreck2Image = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Wreck 2.png`;
const glowWreck2FlamesImage = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Wreck 2 Flames.png`;
const glowWreck3Image = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Wreck 3.png`;
const glowWreck3FlamesImage = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Wreck 3 Flames.png`;
const glowWreck4Image = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Size 2 Wreck 1.png`;
const glowWreck4FlamesImage = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - Size 2 Wreck Flames.png`;
const glowWreck5Image = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - S3 Wreck.png`;
const glowWreck5FlamesImage = `${BaseURL}/Mechs/Misc/Wrecks/glows/Glow - S3 Wreck Flames.png`;

const previewImage = `${BaseURL}/Mechs/Misc/Wrecks/preview.png`;

const chassisShadow = shadowDetailsNone;

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "accessory1Value",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CRATER",
            backSrc: craterImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "WRECK 1",
            src: chassisWreck1Image,
            shadowDetails: chassisShadow,
        },
        {
            value: 1,
            label: "WRECK 2",
            src: chassisWreck2Image,
            shadowDetails: chassisShadow,
        },
        {
            value: 2,
            label: "WRECK 3",
            src: chassisWreck3Image,
            shadowDetails: chassisShadow,
        },
        {
            value: 3,
            label: "WRECK 4 - SIZE 2",
            src: chassisWreck4Image,
            shadowDetails: chassisShadow,
        },
        {
            value: 4,
            label: "WRECK 5 - SIZE 3",
            src: chassisWreck5Image,
            shadowDetails: chassisShadow,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "WRECK 1",
            src: glowWreck1Image,
        },
        {
            value: 1,
            label: "WRECK 2",
            src: glowWreck2Image,
        },
        {
            value: 2,
            label: "WRECK 3",
            src: glowWreck3Image,
        },
        {
            value: 3,
            label: "WRECK 4",
            src: glowWreck4Image,
        },
        {
            value: 4,
            label: "WRECK 5",
            src: glowWreck5Image,
        },
        {
            value: 5,
            label: "WRECK 1 FLAMES",
            src: glowWreck1FlamesImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "WRECK 2 FLAMES",
            src: glowWreck2FlamesImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "WRECK 3 FLAMES",
            src: glowWreck3FlamesImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "WRECK 4 FLAMES",
            src: glowWreck4FlamesImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "WRECK 5 FLAMES",
            src: glowWreck5FlamesImage,
            isPremium: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "CRATER",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "CRATER",
        "chassis": "CHASSIS",
        "rearMount": "CARGO",
        "weapon": "WEAPON",
        "secondaryWeapon": "SECONDARY WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
