import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyAlkaImage = `${BaseURL}/Mechs/Custom/Alka/Chassis - Alka.png`;

// HEADS
const headAlkaImage = `${BaseURL}/Mechs/Custom/Alka/heads/Head - Alka.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Alka/heads/Head - Kazu.png`;
// PREMIUM
const headCalibanImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Cyclops.png`;
const headFactoryStandardImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Factory Standard.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Horned Hair.png`;
const headJudicatorImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Judicator.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Malice.png`;
const headMetalmarkImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Metalmark.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Nelson.png`;
const headPredatorImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Predator.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Rain Transformed Hair.png`;
const headScarabImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Scarab.png`;
const headScopedogImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Scopedog.png`;
const headTalonImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Talon.png`;
const headVanguardImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Vanguard.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - Visionary.png`;
const headWhiteWitchImage = `${BaseURL}/Mechs/Custom/Alka/heads/premium/Head - White Witch.png`;

// SHOULDER
const shoulderWingsDeployedImage = `${BaseURL}/Mechs/Custom/Alka/rear mount/Wings - Deployed.png`;

// WEAPONS
const weaponSpearsImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 1 - Spears.png`;

// ACCESSORY
const accessoryFeathersImage = `${BaseURL}/Mechs/Custom/Alka/accessories/Accessory - Feathers.png`;

// GLOW
const glowEnginesImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 1 - Engines.png`;
const glowCalibanHeadImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 2 - Caliban Head.png`;
const glowDeployedImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 2 - Deployed Glow.png`;
const glowFeathersImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 1 - Feathers.png`;
const glowAlkaHeadImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 2 - Alka Head.png`;
const glowAlkaChassisImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 1 - Alka Chassis.png`;
const glowAlkaAllImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 1 - Alka All.png`;
const glowHaloOverImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 2 - Halo Over.png`;
const glowHaloUnderImage = `${BaseURL}/Mechs/Custom/Alka/glows/Glow 1 - Halo Under.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Alka/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-40px",
    right: "-61px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "accessory1Value": "7",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ALKA",
            src: headAlkaImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "METALMARK",
            src: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "PREDATOR",
            src: headPredatorImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "SCARAB",
            src: headScarabImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "SCOPEDOG",
            src: headScopedogImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "TALON",
            src: headTalonImage,
            isPremium: true,
        },
        {
            value: 21,
            label: "VANGUARD",
            src: headVanguardImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "VISIONARY",
            src: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 23,
            label: "WHITE WITCH",
            src: headWhiteWitchImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ALKA",
            src: bodyAlkaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Designed for ",
                title: "Alka",
                url: "http://www.magicksquares.com/gate/",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SPEARS",
                src: weaponSpearsImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FEATHERS - COLOR",
            src: accessoryFeathersImage,
        },
        {
            value: 1,
            label: "FEATHERS FOREGROUND - COLOR",
            frontSrc: accessoryFeathersImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "WINGS DEPLOYED",
            frontSrc: shoulderWingsDeployedImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "ENGINES",
            src: glowEnginesImage,
        },
        {
            value: 1,
            label: "DEPLOYED WINGS",
            src: glowDeployedImage,
        },
        {
            value: 2,
            label: "FEATHERS",
            src: glowFeathersImage,
        },
        {
            value: 3,
            label: "HALO",
            frontSrc: glowHaloOverImage,
            src: glowHaloUnderImage,
        },
        {
            value: 4,
            label: "CALIBAN HEAD",
            frontSrc: glowCalibanHeadImage,
        },
        {
            value: 5,
            label: "ALKA HEAD",
            frontSrc: glowAlkaHeadImage,
        },
        {
            value: 6,
            label: "ALKA CHASSIS",
            src: glowAlkaChassisImage,
        },
        {
            value: 7,
            label: "ALKA ALL",
            frontSrc: glowAlkaAllImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "SHOULDER",
            value: "rearMount",
        },
        {
            label: "WEAPONS",
            value: "weapon",
            isGlow: true,
        },
        {
            label: "FEATHERS",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "SHOULDER",
        "weapon": "WEAPONS",
        "secondaryWeapon": "FEATHERS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
