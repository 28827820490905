import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyMinotaur4LImage = `${BaseURL}/Mechs/Horus/Minotaur/4L Chassis.png`;
const bodyMinotaur2LImage = `${BaseURL}/Mechs/Horus/Minotaur/2L Chassis.png`;
const bodyNagaImage = `${BaseURL}/Mechs/Horus/Minotaur/Chassis - Naga.png`;

// HEADS
const headBalorImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/Head - Balor.png`;
const headKazuImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/Head - Kazu.png`;
// PREMIUM
const headTaurusImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Taurus.png`;
const headDomeImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Dome.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Visionary.png`;
const headManticoreImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Manticore.png`;
const headAngularImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Angle.png`;
const headGrinImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Grin.png`;
const headSkullImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Skull.png`;
const headHiImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Hi.png`;
const headFactoryStandardImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Factory Standard.png`;
const headPaladinImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Paladin.png`;
const headOgreImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Ogre.png`;
const headDungamImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Dungam.png`;
const headBunkerImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Bunker.png`;
const headJudicatorImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Judicator.png`;
const headRumbleImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Rumble.png`;
const headNelsonImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Nelson.png`;
const headScopehoundImage = `${BaseURL}/Mechs/Horus/Minotaur/heads/premium/Head - Scopehound.png`;

// WEAPONS
const weaponsAxeImage = `${BaseURL}/Mechs/Horus/Minotaur/weapons/Main Weapon - Axe.png`;
const weaponsLanceImage = `${BaseURL}/Mechs/Horus/Minotaur/weapons/Main Weapon - Lance.png`;
const weaponsARImage = `${BaseURL}/Mechs/Horus/Minotaur/weapons/Main Weapon - AR.png`;
const weaponsNailgunImage = `${BaseURL}/Mechs/Horus/Minotaur/weapons/Main Weapon - Nailgun.png`;
const weaponsAutoshotgunImage = `${BaseURL}/Mechs/Horus/Minotaur/weapons/Main Weapon - Autoshotgun.png`;
const weaponsMortarImage = `${BaseURL}/Mechs/Horus/Minotaur/weapons/Main Weapon - Mortar.png`;

// REAR MOUNT
const rearMountMissilesImage = `${BaseURL}/Mechs/Horus/Minotaur/rear mount/Rear - Missiles.png`;
const rearMountNexusImage = `${BaseURL}/Mechs/Horus/Minotaur/rear mount/Rear - Nexus.png`;
const accessoryRobesFrontImage = `${BaseURL}/Mechs/Horus/Minotaur/rear mount/Accessory - Robes (over arms).png`;
const accessoryRobesBackImage = `${BaseURL}/Mechs/Horus/Minotaur/rear mount/Accessory - Robes (under arms).png`;

// ACCESSORIES
const accessoryNeckImage = `${BaseURL}/Mechs/Horus/Minotaur/accessories/Glow - Neck.png`;
const accessoryNexusImage = `${BaseURL}/Mechs/Horus/Minotaur/accessories/Glow - Nexus.png`;
const accessoryNeckAndNexusImage = `${BaseURL}/Mechs/Horus/Minotaur/accessories/Glow - Neck and Nexus.png`;
const accessoryBalorImage = `${BaseURL}/Mechs/Horus/Minotaur/accessories/Glow - Balor Nanites.png`;

const coreImage = `${BaseURL}/Mechs/Horus/Minotaur/Minotaur (by BlindDuke).png`;

const previewImage = `${BaseURL}/Mechs/Horus/Minotaur/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "32px",
    right: "32px",
};

const quadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "64px",
    right: "71px",
};

const serpentLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "44px",
    right: "26px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "BlindDuke",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    removeNone: {
        weapon: true,
    },
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BALOR",
            src: headBalorImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "PALADIN",
            src: headPaladinImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "OGRE",
            src: headOgreImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "DUNGAM",
            src: headDungamImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "BUNKER",
            src: headBunkerImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "RUMBLE",
            src: headRumbleImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "TAURUS",
            src: headTaurusImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "DOME",
            src: headDomeImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "VISIONARY",
            src: headVisionaryImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MANTICORE",
            src: headManticoreImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "ANGULAR",
            src: headAngularImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "GRIN",
            src: headGrinImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "SKULL",
            src: headSkullImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "HI",
            src: headHiImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "FACTORY STANDARD",
            src: headFactoryStandardImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "CENTAUR",
            src: bodyMinotaur4LImage,
            shadowDetails: quadLegsShadow,
        },
        {
            value: 1,
            label: "MINOTAUR",
            src: bodyMinotaur2LImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "NAGA",
            src: bodyNagaImage,
            shadowDetails: serpentLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AXE",
                frontSrc: weaponsAxeImage,
            },
            {
                value: 1,
                label: "LANCE",
                frontSrc: weaponsLanceImage,
            },
            {
                value: 2,
                label: "AR",
                frontSrc: weaponsARImage,
            },
            {
                value: 3,
                label: "NAILGUN",
                frontSrc: weaponsNailgunImage,
            },
            {
                value: 4,
                label: "AUTOSHOTGUN",
                frontSrc: weaponsAutoshotgunImage,
            },
            {
                value: 5,
                label: "MORTAR",
                frontSrc: weaponsMortarImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HEADLESS",
            frontSrc: accessoryNeckImage,
        },
        {
            value: 1,
            label: "NEXUS",
            frontSrc: accessoryNexusImage,
        },
        {
            value: 2,
            label: "HEADLESS + NEXUS",
            frontSrc: accessoryNeckAndNexusImage,
        },
        {
            value: 3,
            label: "BALOR NANITES",
            frontSrc: accessoryBalorImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "MISSILES",
            src: rearMountMissilesImage,
        },
        {
            value: 1,
            label: "NEXUS",
            src: rearMountNexusImage,
        },
        {
            value: 2,
            label: "ROBES",
            frontSrc: accessoryRobesFrontImage,
            src: accessoryRobesBackImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "MAIN WEAPONS",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "MAIN WEAPONS",
        "secondaryWeapon": "GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
