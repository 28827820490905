import React, { useContext, useEffect, useState } from "react";
import { bool, func, object } from "prop-types";
import styled, { keyframes } from "styled-components";

import UnitCategoryDisplay from "./UnitCategoryDisplay";
import MechSinglesUnitCategoryDisplay from "./Mechs/MechSinglesUnitCategoryDisplay";
import { AnalyticsContext } from "./../../Analytics/AnalyticsContextWrapper";

import { defaultColor, defaultParentColor } from "./Mechs/components/MechCategoryStyles";
import ContainerWrapper from "./components/ContainerWrapper";
import MembershipTierImages from "./../../common/MembershipTierImages";
import BaseURL from "./../../Unit/data/images/Hosting-BaseURL";
import UnitCategoryHeaderImage from "./components/UnitCategoryHeaderImage";
import SpriteImage, { SPRITE_KEYS } from "../../common/styles/SpriteImage";

const newCategoryTierImage = `${BaseURL}/buttons/orb-red.png`;

const maxColumnsVisible = 6;
let minColumnsVisible = 2;

const entrance = keyframes`
    from {
        margin-left: -100px;
    }

    to {
        margin-left: 0px;
    }
`;

const mobileEntrance = keyframes`
    from {
        margin-left: 100px;
    }

    to {
        margin-left: 0px;
    }
`;

export const UnitCategoryWrapper = styled.div`
    animation: ${entrance} 1 0.35s ease-in-out;
    background: ${props => `linear-gradient(to bottom right, ${props.theme.categoryPrimaryColor} 0%, ${props.theme.categorySecondaryColor} 100%)`};
    border-color: ${props => props.theme.categoryPrimaryColor};
    border-width: 4px 4px 4px 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-style: outset;
    box-shadow: 0 0 2px 1px #000;
    margin-top: 4px;
    width: ${props => props.isExpanded ? `${(props.numColumnsVisible * 210) + 50}px` : "230px"};
    min-width: 230px;
    transition-duration: 0.15s;
    transition-property: width;

    &.parent-category {
        background: ${props => `linear-gradient(to bottom right, ${props.theme.parentCategoryPrimaryColor} 0%, ${props.theme.parentCategorySecondaryColor} 100%)`};
        border-color: ${props => props.theme.parentCategoryPrimaryColor};
        width: 245px;
    }

    &.sub-category {
        background: ${props => `linear-gradient(to bottom right, ${props.theme.subCategoryPrimaryColor} 0%, ${props.theme.subCategorySecondaryColor} 100%)`};
        border-color: ${props => props.theme.subCategoryPrimaryColor};
    }

    &.seasonal-category {
        background: ${props => `linear-gradient(to bottom right, ${props.borderPrimaryColor || props.theme.categoryPrimaryColor} 0%, ${props.borderSecondaryColor || props.theme.categorySecondaryColor} 100%)`};
        border-color: ${props => props.borderPrimaryColor};
    }

    &.mech-category {
        padding: 10px;
        border-radius: 0;
        border-style: none;
        border-color: transparent;
        background: ${defaultColor};
        box-shadow: unset;
        //Clip Bottom Right Corner
        clip-path: ${props => props.isExpanded ? "unset" : `polygon(calc(100% + 1px) 0, calc(100% + 1px) calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0)`};

        &.parent-category {
            background: ${defaultParentColor};
            //Clip Bottom Right & Top Right Corner
            clip-path: polygon(calc(100% - 10px) 0%,100% 10px,100% calc(100% - 10px),calc(100% - 10px) 100%,0% 100%,0% 0%);
        }
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        width: ${props => props.isExpanded ? "470px" : "230px"};
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        animation: ${mobileEntrance} 1 0.35s ease-in-out;
        border-width: 4px 0 4px 4px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: 95%;
        margin-right: 0;
        margin-left: auto;

        &.parent-category {
            width: 95%;
        }
    }
`;

UnitCategoryWrapper.displayName = "UnitCategoryWrapper";

export const UnitCategoryHeader = styled.div`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    width: 230px;
    margin-right: auto;

    &.mech-category {
        justify-content: flex-start;
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        padding-top: 10px;
        padding-bottom: 10px;
        margin-left: auto;
    }
`;

UnitCategoryHeader.displayName = "UnitCategoryHeader";

export const UnitCategoryHeaderText = styled.h2`
    display: none;

    &.mech-category {
        display: block;
        font-family: Arial, Helvetica, sans-serif;
        color: #FFF;
        font-size: 16px;
        margin-top: 0px;
        margin-bottom: 0px;
        text-align: left;
    }
`;

UnitCategoryHeaderText.displayName = "UnitCategoryHeaderText";

export const PremiumIndicatorImage = styled.img`
    max-width: 100%;
    margin-right: 15px;
    cursor: pointer;

    &.mech-category {
        margin-left: 10px;
    }

    &.hide-image {
        visibility: hidden;
    }
`;

PremiumIndicatorImage.displayName = "PremiumIndicatorImage";

export const UnitContainer = styled.div`
    align-content: flex-start;
    align-items: stretch;
    background: ${props => props.isExpanded ? `linear-gradient(${props.themeBackgroundPrimaryColor}, ${props.themeBackgroundSecondaryColor})` : props.theme.categoryPrimaryColor};
    border-color: ${props => props.theme.categoryPrimaryColor};
    border-style: inset;
    border-width: 4px;
    display: ${props => props.isExpanded ? "flex" : "none"};
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 10px;
    margin-right: 0;
    overflow-y: ${props => props.isExpanded ? "scroll" : "hidden"};
    padding: ${props => props.isExpanded ? "4px" : 0};
    text-align: center;
    height: 340px;
    min-height: 340px;
    width: ${props => props.numColumnsVisible * 210}px;
    min-width: 420px;
    resize: ${props => props.isExpanded ? "vertical" : "none"};
    transition-duration: 0.15s;
    transition-property: width;

    /* Firefox styling  */
    scrollbar-color: ${props => `${props.theme.categoryPrimaryColor} ${props.themeBackgroundSecondaryColor}`};

    /* All the Rest */
    ::-webkit-scrollbar {
        width: 20px;
    }
    ::-webkit-scrollbar-track {
        background: ${props => `linear-gradient(${props.themeBackgroundPrimaryColor}, ${props.themeBackgroundSecondaryColor})`};

        &:horizontal {
            display: none;
        }
    }
    ::-webkit-scrollbar-track-piece {
        color: ${props => props.theme.categoryPrimaryColor};
        box-shadow: inset 10px ${props => props.theme.categoryPrimaryColor};
    }
    ::-webkit-scrollbar-thumb {
        background: ${props => props.theme.categoryPrimaryColor};

        &:vertical {
            border: 2px outset ${props => props.borderSecondaryColor || props.theme.categorySecondaryColor};
        }
        &:horizontal {
            border: 2px outset ${props => props.borderSecondaryColor || props.theme.categorySecondaryColor};
        }
    }
    ::-webkit-scrollbar-corner {
        background: ${props => props.themeBackgroundSecondaryColor};
    }
    ::-webkit-resizer {
        border-top: 20px solid transparent;
        border-left: 20px solid transparent;
        border-right: 20px solid #808FA0;
        border-bottom: 20px solid #808FA0;
    }

    &.sub-category {
        border-color: ${props => props.theme.subCategoryPrimaryColor};
    }

    &.seasonal-category {
        border-color: ${props => props.borderPrimaryColor || props.theme.categoryPrimaryColor};

        ::-webkit-scrollbar-thumb {
            background: ${props => props.borderPrimaryColor || props.theme.categoryPrimaryColor};
        }

        ::-webkit-scrollbar-track-piece {
            color: ${props => props.borderPrimaryColor || props.theme.categoryPrimaryColor};
            box-shadow: inset 10px ${props => props.borderPrimaryColor || props.theme.categoryPrimaryColor};
        }

        /* Firefox styling  */
        scrollbar-color: ${props => `${props.borderPrimaryColor || props.theme.categoryPrimaryColor} ${props.themeBackgroundSecondaryColor}`};
    }

    &.mech-category {
        border: unset;
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        width: 420px;
        min-width: 420px;
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        min-width: 90%;
        justify-content: space-around;
    }
`;

UnitContainer.displayName = "UnitContainer";

export const ContainerExpandButton = styled(SpriteImage)`
    cursor: pointer;
    display: ${props => props.isExpanded ? "block" : "none"};
    filter: opacity(0.5);
    margin-left: 5px;
    margin-right: 5px;
    width: 14px;
    height: 20px;

    :active {
        transform: translateY(2px);
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        display: none;
    }
`;

ContainerExpandButton.displayName = "ContainerExpandButton";

function UnitCategory({ isSubCategory, setShowPremiumPopupFunc, unitCategory }) {
    if (unitCategory.mechSinglesCategory) {
        minColumnsVisible = 3;
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const [numColumnsVisible, setNumColumnsVisible] = useState(minColumnsVisible);

    const ReactGA = useContext(AnalyticsContext);

    useEffect(() => {
        if (isExpanded) {
            ReactGA.event({
                category: "Unit Category",
                action: "View Category",
                label: unitCategory.title
            });
        }
    }, [isExpanded, ReactGA, unitCategory.title]);

    const unitsEl = unitCategory.unitList.map((unit, index) => (
        unitCategory.mechSinglesCategory ?
            <MechSinglesUnitCategoryDisplay
                key={`${unitCategory.title}-${index}`}
                setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                shadowDetails={unit.shadowDetails}
                variantsArray={unit.variants}
            /> :
            <UnitCategoryDisplay
                key={`${unitCategory.title}-${index}`}
                setShowPremiumPopupFunc={setShowPremiumPopupFunc}
                shadowDetails={unit.shadowDetails}
                variantsArray={unit.variants}
            />
    ));

    let categoryTierImage = MembershipTierImages.mundaneTier.orb
    let categoryTierImageAlt = MembershipTierImages.mundaneTier.alt;
    let categoryClassname = "";

    if (unitCategory.premium) {
        categoryTierImage = MembershipTierImages.themepackTier.orb;
        categoryTierImageAlt = MembershipTierImages.themepackTier.alt;
    }

    if (unitCategory.seasonal) {
        categoryTierImage = MembershipTierImages.legendaryTier.orb;
        categoryTierImageAlt = "Seasonal Units";
        categoryClassname += "seasonal-category";
    }

    if (unitCategory.title === "New") {
        categoryTierImage = newCategoryTierImage;
        categoryTierImageAlt = "Newly Added Units";
    }

    if (isSubCategory) {
        categoryClassname += " sub-category";
    }

    if (unitCategory.mechSinglesCategory || unitCategory.mechNPCCategory || unitCategory.mechBrandedCategory || unitCategory.mechParentCategory) {
        categoryClassname += " seasonal-category mech-category";

        return (
            <UnitCategoryWrapper
                className={categoryClassname}
                isExpanded={isExpanded}
                numColumnsVisible={numColumnsVisible}
                borderPrimaryColor={unitCategory.borderPrimaryColor}
                borderSecondaryColor={unitCategory.borderSecondaryColor}
            >
                <UnitCategoryHeader
                    className={categoryClassname}
                    onClick={() => setIsExpanded(!isExpanded)}
                    title={unitCategory.description}
                >
                    <PremiumIndicatorImage
                        className={`${categoryClassname} hide-image`}
                        src={categoryTierImage}
                        alt="Units Rarity"
                        title={categoryTierImageAlt}
                    />
                    <UnitCategoryHeaderText alt={unitCategory.label} className={categoryClassname}>{unitCategory.label}</UnitCategoryHeaderText>
                </UnitCategoryHeader>
                <ContainerWrapper>
                    <UnitContainer
                        className={categoryClassname}
                        themeBackgroundPrimaryColor={unitCategory.themeBackgroundPrimaryColor}
                        themeBackgroundSecondaryColor={unitCategory.themeBackgroundSecondaryColor}
                        isExpanded={isExpanded}
                        numColumnsVisible={numColumnsVisible}
                        borderPrimaryColor={unitCategory.borderPrimaryColor}
                        borderSecondaryColor={unitCategory.borderSecondaryColor}
                    >
                        {isExpanded ? unitsEl : null}
                    </UnitContainer>
                    <ContainerExpandButton
                        onClick={() => numColumnsVisible === maxColumnsVisible ? setNumColumnsVisible(minColumnsVisible) : setNumColumnsVisible(numColumnsVisible + 1)}
                        isExpanded={isExpanded}
                        className={numColumnsVisible === maxColumnsVisible ? SPRITE_KEYS.lArrow : SPRITE_KEYS.rArrow}
                        alt={numColumnsVisible === maxColumnsVisible ? "Reset View Size" : "Expand View Size"}
                        title={numColumnsVisible === maxColumnsVisible ? "Reset View Size" : "Expand View Size"}
                    />
                </ContainerWrapper>
            </UnitCategoryWrapper>
        );
    }

    return (
        <UnitCategoryWrapper
            className={categoryClassname}
            isExpanded={isExpanded}
            numColumnsVisible={numColumnsVisible}
            borderPrimaryColor={unitCategory.borderPrimaryColor}
            borderSecondaryColor={unitCategory.borderSecondaryColor}
        >
            <UnitCategoryHeader
                className={categoryClassname}
                onClick={() => setIsExpanded(!isExpanded)}
                title={unitCategory.description}
            >
                <UnitCategoryHeaderText className={categoryClassname}>{unitCategory.title}</UnitCategoryHeaderText>
                <UnitCategoryHeaderImage
                    className={categoryClassname}
                    isExpanded={isExpanded}
                    borderSecondaryColor={unitCategory.borderSecondaryColor}
                    image={isExpanded ? unitCategory.activeImage : unitCategory.inactiveImage}
                    alt={unitCategory.title}
                />

                <PremiumIndicatorImage
                    className={categoryClassname}
                    src={categoryTierImage}
                    alt="Units Rarity"
                    title={categoryTierImageAlt}
                />
            </UnitCategoryHeader>
            <ContainerWrapper>
                <UnitContainer
                    className={categoryClassname}
                    themeBackgroundPrimaryColor={unitCategory.themeBackgroundPrimaryColor}
                    themeBackgroundSecondaryColor={unitCategory.themeBackgroundSecondaryColor}
                    isExpanded={isExpanded}
                    numColumnsVisible={numColumnsVisible}
                    borderPrimaryColor={unitCategory.borderPrimaryColor}
                    borderSecondaryColor={unitCategory.borderSecondaryColor}
                >
                    {isExpanded ? unitsEl : null}
                </UnitContainer>
                <ContainerExpandButton
                    onClick={() => numColumnsVisible === maxColumnsVisible ? setNumColumnsVisible(minColumnsVisible) : setNumColumnsVisible(numColumnsVisible + 1)}
                    isExpanded={isExpanded}
                    className={numColumnsVisible === maxColumnsVisible ? SPRITE_KEYS.lArrow : SPRITE_KEYS.rArrow}
                    alt={numColumnsVisible === maxColumnsVisible ? "Reset View Size" : "Expand View Size"}
                    title={numColumnsVisible === maxColumnsVisible ? "Reset View Size" : "Expand View Size"}
                />
            </ContainerWrapper>
        </UnitCategoryWrapper>
    )
}

UnitCategory.propTypes = {
    isSubCategory: bool,
    setShowPremiumPopupFunc: func.isRequired,
    unitCategory: object.isRequired,
};

UnitCategory.defaultProps = {
    isSubCategory: false,
};

export default UnitCategory;
