import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyChassisImage = `${BaseURL}/Mechs/Custom/Bellamy/Chassis.png`;

// HEADS
const headBellamyImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Bellamy.png`;
const head8BallImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- 8 Ball.png`;
const headBlackspotImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Blackspot.png`;
const headCheddahImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Cheddah.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Chimaera.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Cyclops.png`;
const headDungamImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Dungam.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Horned Hair.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Kazu.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Malice.png`;
const headMecha1Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 1.png`;
const headMecha2Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 2.png`;
const headMecha3Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 3.png`;
const headMecha4Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 4.png`;
const headMecha5Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 5.png`;
const headMecha6Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 6.png`;
const headMecha7Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 7.png`;
const headMecha8Image = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Mecha 8.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Natural Law Hair.png`;
const headNelsonImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Nelson.png`;
const headRainImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Rain.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Rain Hair.png`;
const headSkullImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Skull.png`;
const headVanquisherImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Vanquisher.png`;
const headViceroyImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Viceroy.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Visionary.png`;
const headZahhakImage = `${BaseURL}/Mechs/Custom/Bellamy/heads/Head- Zahhak.png`;

// WEAPONS
// LEFT
const leftWeaponAxeImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/left/Weapons - Left - Axe.png`;
const leftWeaponChargedBladeImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/left/Weapons - Left - Charged Blade.png`;
const leftWeaponLauncherImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/left/Weapons - Left - Launcher.png`;
const leftWeaponWinchImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/left/Weapons - Left - Winch.png`;

// RIGHT
const rightWeaponAxeImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/right/Weapons - Right - Axe.png`;
const rightWeaponChargedBladeImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/right/Weapons - Right - Charged Blade.png`;
const rightWeaponHarpoonImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/right/Weapons - Right - Harpoon.png`;
const rightWeaponLauncherImage = `${BaseURL}/Mechs/Custom/Bellamy/weapons/right/Weapons - Right - Launcher.png`;

// REAR MOUNT
const boardImage = `${BaseURL}/Mechs/Custom/Bellamy/rear mount/Board.png`;

// GLOWS
const glowBoardImage = `${BaseURL}/Mechs/Custom/Bellamy/glows/Glow - Board.png`;
const glowTrailImage = `${BaseURL}/Mechs/Custom/Bellamy/glows/Glow - Board Trail.png`;
const glowBoardTrailImage = `${BaseURL}/Mechs/Custom/Bellamy/glows/Glow - Board + Trail.png`;
const glowChargedBladeLeftImage = `${BaseURL}/Mechs/Custom/Bellamy/glows/Glow - Left Charged Blade.png`;
const glowChargedBladeRightImage = `${BaseURL}/Mechs/Custom/Bellamy/glows/Glow - Right Charged Blade.png`;
const glowChargedBladeDualImage = `${BaseURL}/Mechs/Custom/Bellamy/glows/Glow - Dual Charged Blades.png`;
const glowWinchLineImage = `${BaseURL}/Mechs/Custom/Bellamy/glows/Glow - Winch Line.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Bellamy/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Bellamy/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "33px",
    right: "11px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "3",
        "secondaryWeaponValue": "2",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "6",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BELLAMY",
            src: headBellamyImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 4,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 6,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 7,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 8,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 9,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 10,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 11,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 12,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 13,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 14,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 15,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 16,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 17,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 18,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 19,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 20,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 21,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 22,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 23,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 24,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 25,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 26,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 27,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 28,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 29,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 30,
            label: "ZAHHAK",
            src: headZahhakImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "BELLAMY",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AXE",
                src: leftWeaponAxeImage,
            },
            {
                value: 1,
                label: "CHARGED BLADE",
                src: leftWeaponChargedBladeImage,
            },
            {
                value: 2,
                label: "LAUNCHER",
                src: leftWeaponLauncherImage,
            },
            {
                value: 3,
                label: "WINCH",
                src: leftWeaponWinchImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "AXE",
            src: rightWeaponAxeImage,
        },
        {
            value: 1,
            label: "CHARGED BLADE",
            src: rightWeaponChargedBladeImage,
        },
        {
            value: 2,
            label: "HARPOON",
            src: rightWeaponHarpoonImage,
        },
        {
            value: 3,
            label: "LAUNCHER",
            src: rightWeaponLauncherImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: 'BELLAMY BOARD',
            backSrc: boardImage,
        }
    ],
    accessory: [
        {
            value: 0,
            label: "BOARD",
            src: glowBoardImage,
        },
        {
            value: 1,
            label: "BOARD TRAIL",
            src: glowTrailImage,
        },
        {
            value: 2,
            label: "BOARD + TRAIL",
            src: glowBoardTrailImage,
        },
        {
            value: 3,
            label: "CHARGED BLADE - LEFT",
            frontSrc: glowChargedBladeLeftImage,
        },
        {
            value: 4,
            label: "CHARGED BLADE - RIGHT",
            frontSrc: glowChargedBladeRightImage,
        },
        {
            value: 5,
            label: "CHARGED BLADE - DUAL",
            frontSrc: glowChargedBladeDualImage,
        },
        {
            value: 6,
            label: "WINCH LINE",
            frontSrc: glowWinchLineImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "BOARD",
            value: "rearMount",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "BOARD",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
