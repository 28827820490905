import BaseURL from "./../Unit/data/images/Hosting-BaseURL";
import { SPRITE_KEYS } from "./styles/SpriteImage";

const mundaneTierImage = `${BaseURL}/buttons/orb-green.png`;
const mundaneEmblemGif = `${BaseURL}/buttons/mundane-emblem.gif`;

const plusOneTierImage = `${BaseURL}/buttons/orb-blue.png`;
const plusOneEmblemGif = `${BaseURL}/buttons/plus-one-emblem.gif`;

const plusOneTierEyeCatchImage = `${BaseURL}/buttons/orb-blue-pulse.gif`;

const legendaryTierImage = `${BaseURL}/buttons/orb-rainbow.gif`;
const legendaryEmblemGif = `${BaseURL}/buttons/legendary-emblem.gif`;

const themepackTierImage = `${BaseURL}/buttons/orb-purple.png`;

const themePackTierEyeCatchImage = `${BaseURL}/buttons/orb-purple-pulse.gif`;

export default {
    mundaneTier: {
        textSprite: SPRITE_KEYS.mundaneMembership,
        emblem: mundaneEmblemGif,
        orb: mundaneTierImage,
        orbSprite: SPRITE_KEYS.orbGreen,
        alt: "Mundane Tier",
    },
    plusOneTier: {
        textSprite: SPRITE_KEYS.plusOneMembership,
        emblem: plusOneEmblemGif,
        orb: plusOneTierImage,
        orbSprite: SPRITE_KEYS.orbBlue,
        alt: "+1 Tier",
    },
    plusOneTierEyeCatch: {
        orb: plusOneTierEyeCatchImage,
        alt: "Become a +1 Tier Member",
    },
    legendaryTier: {
        textSprite: SPRITE_KEYS.legendaryMembership,
        emblem: legendaryEmblemGif,
        orb: legendaryTierImage,
        orbSprite: SPRITE_KEYS.orbPurple,
        alt: "Legendary Tier",
    },
    themepackTier: {
        orb: themepackTierImage,
        orbSprite: SPRITE_KEYS.orbPurple,
        alt: "Theme Pack Tier",
    },
    themepackTierEyeCatch: {
        orb: themePackTierEyeCatchImage,
        alt: "Become a Legendary Member",
    },
    plusTwoTier: {
        textSprite: SPRITE_KEYS.plusOneMembership,
        emblem: plusOneEmblemGif,
        orb: plusOneTierImage,
        orbSprite: SPRITE_KEYS.orbBlue,
        alt: "+2 Tier",
    },
};
