import React, { useContext } from "react";
import { bool, func, object } from "prop-types";
import styled from "styled-components";

import { ShowLandingContext } from "./../../Landing/ShowLandingContextWrapper";
import { CurrentThemeContext } from "./../../common/ThemeContextWrapper";
import SpriteImage, { SPRITE_KEYS } from "../../common/styles/SpriteImage";

import MembershipTierImages from "./../../common/MembershipTierImages";

import { latestNewsDate } from "./../Content/News/newsItems";

export const MenuTabWrapper = styled.div`
    border-width: 4px 0 4px 4px;
    margin-top: 4px;
    padding: 5px 0 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    position: relative;
    width: 225px;
    cursor: pointer;
    border-color: ${props => props.theme.categoryPrimaryColor};

    &.fantasy {
        background: ${props => `linear-gradient(to bottom right, ${props.theme.categoryPrimaryColor} 0%, ${props.theme.categorySecondaryColor} 100%)`};
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        box-shadow: 0 0 2px 1px #000;
        border-style: outset;
    }

    &.mech {
        background: ${props => props.theme.categoryPrimaryColor};
        border-style: solid;
        justify-content: center;
        // Clip Bottom Left Corner
        clip-path: polygon(0% 0%,100% 0%,100% 100%,20px 100%,0% calc(100% - 20px));
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        display: ${props => props.isPromoCodeTab ? "none" : "flex"};
    }
`;

MenuTabWrapper.displayName = "MenuTabWrapper";

const MenuTabText = styled.h2`
    display: none;

    &.mech {
        display: initial;
        font-size: 18px;
        font-family: Arial, Helvetica, sans-serif;
        color: ${props => props.isActive ? props.theme.categorySecondaryColor : "#FFF"};
    }
`;

MenuTabText.displayName = "MenuTabText";

const MenuTabImageWrapper = styled.div`
    background-color: ${props => props.isActive ? props.theme.categorySecondaryColor : "transparent"};
    border-style: inset;
    border-color: ${props => props.isActive ? props.theme.categorySecondaryColor : "transparent"};
    border-width: 4px;
    border-radius: 10px;
    padding: 5px 10px;
    transition-property: background-color, border, opacity;
    transition-duration: 0.25s;

    &.mech {
        display: none;
    }
`;

MenuTabImageWrapper.displayName = "MenuTabImageWrapper";

const AdditionalTabImages = styled.div`
    padding-left: 2px;
    > div {
        margin: 3px 2px 0 2px;
    }
`;

AdditionalTabImages.displayName = "AdditionalTabImages";

function MenuTab({ isActive, menuTabObject, setActiveMenuTabFunc }) {
    const { showLanding } = useContext(ShowLandingContext);
    const { currentSide } = useContext(CurrentThemeContext);

    const readLatestNewsCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`latestReadNewsDate=`));
    const hasReadLatestNews = readLatestNewsCookie.length && readLatestNewsCookie[0].split("=")[1] === latestNewsDate;

    return (
        <MenuTabWrapper
            className={currentSide}
            onClick={() => setActiveMenuTabFunc(isActive ? "None" : menuTabObject.title)}
            showLanding={showLanding}
            isPromoCodeTab={menuTabObject.title === "Promo Codes"}
        >
            <MenuTabText
                className={currentSide}
                isActive={isActive}
            >
                {menuTabObject.title}
            </MenuTabText>
            <MenuTabImageWrapper className={currentSide} isActive={isActive}>
                <SpriteImage
                    className={isActive ? menuTabObject.activeImage : menuTabObject.inactiveImage}
                    alt={menuTabObject.title}
                />
            </MenuTabImageWrapper>
            {
                menuTabObject.title === "News" && !hasReadLatestNews ?
                <AdditionalTabImages>
                    <SpriteImage className={SPRITE_KEYS.orbRed} alt="New News!" />
                </AdditionalTabImages> : null
            }
            {
                menuTabObject.title === "Membership" && currentSide !== "mech" ?
                <AdditionalTabImages>
                    <img src={MembershipTierImages.mundaneTier.orb} alt={MembershipTierImages.mundaneTier.alt} />
                    <img src={MembershipTierImages.plusOneTier.orb} alt={MembershipTierImages.plusOneTier.alt} />
                    <img src={MembershipTierImages.legendaryTier.orb} alt={MembershipTierImages.legendaryTier.alt} />
                </AdditionalTabImages> : null
            }
        </MenuTabWrapper>
    )
}

MenuTab.propTypes = {
    isActive: bool.isRequired,
    menuTabObject: object.isRequired,
    setActiveMenuTabFunc: func.isRequired,
};

export default MenuTab;
