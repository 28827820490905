import { shadowTypes } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Kalista`;

// BODY
const bodyImage = `${imagePath}/Kalista_Chassis.png`;

// HEADS
const headKalistaImage = `${imagePath}/heads/Kalista_Heads_Kalista.png`;
const headBlastardImage = `${imagePath}/heads/Kalista_Heads_Blastard.png`;
const headChimeraImage = `${imagePath}/heads/Kalista_Heads_Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Kalista_Heads_Cyclops.png`;
const headHansenImage = `${imagePath}/heads/Kalista_Heads_Hansen.png`;
const headJaguarWarriorImage = `${imagePath}/heads/Kalista_Heads_Jaguar Warrior.png`;
const headKazuImage = `${imagePath}/heads/Kalista_Heads_Kazu.png`;
const headKinglyImage = `${imagePath}/heads/Kalista_Heads_Kingly.png`;
const headLaborerImage = `${imagePath}/heads/Kalista_Heads_Laborer.png`;
const headMaliceImage = `${imagePath}/heads/Kalista_Heads_Malice.png`;
const headOniImage = `${imagePath}/heads/Kalista_Heads_Oni.png`;
const headOniHairImage = `${imagePath}/heads/Kalista_Heads_Oni Hair.png`;
const headPrototypeImage = `${imagePath}/heads/Kalista_Heads_Prototype.png`;

// WEAPONS
// LEFT
const weaponLeftChainswordImage = `${imagePath}/weapons/left/Kalista_Weapons Left_Chainsword.png`;
const weaponLeftClawhandImage = `${imagePath}/weapons/left/Kalista_Weapons Left_Claw hand.png`;
const weaponLeftCleaverImage = `${imagePath}/weapons/left/Kalista_Weapons Left_Cleaver.png`;
const weaponLeftDemonClaw1Image = `${imagePath}/weapons/left/Kalista_Weapons Left_Demon Claw 1.png`;
const weaponLeftDemonClaw2Image = `${imagePath}/weapons/left/Kalista_Weapons Left_Demon Claw 2.png`;
const weaponLeftfistImage = `${imagePath}/weapons/left/Kalista_Weapons Left_fist.png`;
const weaponLeftHookImage = `${imagePath}/weapons/left/Kalista_Weapons Left_Hook.png`;
const weaponLeftTigerClawImage = `${imagePath}/weapons/left/Kalista_Weapons Left_Tiger Claw.png`;

// RIGHT
const weaponRightCatchoftheDayImage = `${imagePath}/weapons/right/Kalista_Weapons Right_Catch of the Day.png`;
const weaponRightGuillotineBladeImage = `${imagePath}/weapons/right/Kalista_Weapons Right_Guillotine Blade.png`;
const weaponRightHorsecutterImage = `${imagePath}/weapons/right/Kalista_Weapons Right_Horsecutter.png`;
const weaponRightJitteImage = `${imagePath}/weapons/right/Kalista_Weapons Right_Jitte.png`;
const weaponRightKonaboImage = `${imagePath}/weapons/right/Kalista_Weapons Right_Konabo.png`;
const weaponRightMacuahuitlImage = `${imagePath}/weapons/right/Kalista_Weapons Right_Macuahuitl.png`;
const weaponRightMotorizedTetsuboImage = `${imagePath}/weapons/right/Kalista_Weapons Right_Motorized Tetsubo.png`;

// ARMOR
const armorMiddleApronImage = `${imagePath}/armor/Kalista_Accessories Middle_Apron.png`;
const armorMiddleERAArmorImage = `${imagePath}/armor/Kalista_Accessories Middle_ERA Armor.png`;
const armorRearERAArmorImage = `${imagePath}/armor/Kalista_Accessories Rear_ERA Armor.png`;
const armorMiddleJaguarWarriorImage = `${imagePath}/armor/Kalista_Accessories Middle_Jaguar Warrior.png`;
const armorRearJaguarWarriorImage = `${imagePath}/armor/Kalista_Accessories Rear_Jaguar Warrior.png`;
const armorMiddleOniImage = `${imagePath}/armor/Kalista_Accessories Middle_Oni.png`;

// ACCESSORIES
const accChefHatImage = `${imagePath}/accessories/Kalista_Accessories top_Chef Hat.png`;
const accHeadClothImage = `${imagePath}/accessories/Kalista_Accessories High_Head Cloth.png`;
const accHeadClothRearImage = `${imagePath}/accessories/Kalista_Accessories Rear_Head Cloth.png`;

// GLOWS
const glowBodyFlamesMidImage = `${imagePath}/accessories/Kalista_Accessories High_Body Flames.png`;
const glowBodyFlamesRearImage = `${imagePath}/accessories/Kalista_Accessories Rear_Body Flame.png`;
const glowBodyFlamesFrontImage = `${imagePath}/accessories/Kalista_Accessories top_Body Flames.png`;
const glowMacuahuitlImage = `${imagePath}/accessories/Kalista_Accessories High_Macuahuitl Glow.png`;
const glowTetsuboFlamesImage = `${imagePath}/accessories/Kalista_Accessories High_Tetsubo Flames.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-14px",
    right: "16px",
};

export default {
    isMegadeus: true,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "7",
        "secondaryWeaponValue": "6",
        "accessory1Value": "0",
        "accessory2Value": "2",
        "accessory3Value": "4",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "KALISTA",
            frontSrc: headKalistaImage,
        },
        {
            value: 1,
            label: "BLASTARD",
            frontSrc: headBlastardImage,
        },
        {
            value: 2,
            label: "CHIMERA",
            frontSrc: headChimeraImage,
        },
        {
            value: 3,
            label: "CYCLOPS",
            frontSrc: headCyclopsImage,
        },
        {
            value: 4,
            label: "HANSEN",
            frontSrc: headHansenImage,
        },
        {
            value: 5,
            label: "JAGUAR WARRIOR",
            frontSrc: headJaguarWarriorImage,
        },
        {
            value: 6,
            label: "KAZU",
            frontSrc: headKazuImage,
        },
        {
            value: 7,
            label: "KINGLY",
            frontSrc: headKinglyImage,
        },
        {
            value: 8,
            label: "LABORER",
            frontSrc: headLaborerImage,
        },
        {
            value: 9,
            label: "MALICE",
            frontSrc: headMaliceImage,
        },
        {
            value: 10,
            label: "ONI",
            frontSrc: headOniImage,
        },
        {
            value: 11,
            label: "ONI_HAIR",
            frontSrc: headOniHairImage,
        },
        {
            value: 12,
            label: "PROTOTYPE",
            frontSrc: headPrototypeImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "KALISTA",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: `Support with +2 patron tier. Created by ${contributorList.PINKHYENA.title}. Homebrew by Kai Tave. Commissioned by Terrance Turner. Designed by `,
                title: "Magnus",
                url: "https://twitter.com/Magnus_TheMage",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CHAINSWORD",
                src: weaponLeftChainswordImage,
            },
            {
                value: 1,
                label: "CLAWHAND",
                src: weaponLeftClawhandImage,
            },
            {
                value: 2,
                label: "CLEAVER",
                src: weaponLeftCleaverImage,
            },
            {
                value: 3,
                label: "DEMON CLAW 1",
                src: weaponLeftDemonClaw1Image,
            },
            {
                value: 4,
                label: "DEMON CLAW 2",
                src: weaponLeftDemonClaw2Image,
            },
            {
                value: 5,
                label: "FIST",
                src: weaponLeftfistImage,
            },
            {
                value: 6,
                label: "HOOK",
                src: weaponLeftHookImage,
            },
            {
                value: 7,
                label: "TIGER CLAW",
                src: weaponLeftTigerClawImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CATCH OF THE DAY",
            frontSrc: weaponRightCatchoftheDayImage,
        },
        {
            value: 1,
            label: "GUILLOTINE BLADE",
            frontSrc: weaponRightGuillotineBladeImage,
        },
        {
            value: 2,
            label: "HORSECUTTER",
            frontSrc: weaponRightHorsecutterImage,
        },
        {
            value: 3,
            label: "JITTE",
            frontSrc: weaponRightJitteImage,
        },
        {
            value: 4,
            label: "KONABO",
            frontSrc: weaponRightKonaboImage,
        },
        {
            value: 5,
            label: "MACUAHUITL",
            frontSrc: weaponRightMacuahuitlImage,
        },
        {
            value: 6,
            label: "MOTORIZED TETSUBO",
            frontSrc: weaponRightMotorizedTetsuboImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "APRON",
            frontSrc: armorMiddleApronImage,
        },
        {
            value: 1,
            label: "ERA",
            src: armorRearERAArmorImage,
            frontSrc: armorMiddleERAArmorImage,
        },
        {
            value: 2,
            label: "JAGUAR WARRIOR",
            src: armorRearJaguarWarriorImage,
            frontSrc: armorMiddleJaguarWarriorImage,
        },
        {
            value: 3,
            label: "ONI",
            frontSrc: armorMiddleOniImage,
        },

    ],
    accessory: [
        {
            value: 0,
            label: "GLOW - BODY FLAMES",
            src: glowBodyFlamesRearImage,
            frontSrc: glowBodyFlamesMidImage,
            foremostSrc: glowBodyFlamesFrontImage,
        },
        {
            value: 1,
            label: "GLOW - MACUAHUITL",
            frontSrc: glowMacuahuitlImage,
        },
        {
            value: 2,
            label: "GLOW - TETSUBO FLAMES",
            frontSrc: glowTetsuboFlamesImage,
        },
        {
            value: 3,
            label: "CHEF HAT",
            frontSrc: accChefHatImage,
        },
        {
            value: 4,
            label: "HEAD CLOTH",
            src: accHeadClothRearImage,
            frontSrc: accHeadClothImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS / ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L WEAPON",
            value: "weapon",
        },
        {
            label: "R WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ARMOR",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ARMOR",
        "weapon": "L WEAPON",
        "secondaryWeapon": "R WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "ACCESSORY 1",
        "accessory4": "ACCESSORY 2",
    },
};
