import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Horus/Salamander`;

// BODY
const bodySalamanderImage = `${imagePath}/Chassis.png`;
const bodySlagLowImage = `${imagePath}/Slag Bomb Low.png`;
const bodySlagTallImage = `${imagePath}/Slag Bomb Tall.png`;

// HEADS
const headSalamanderImage = `${imagePath}/heads/Head - Salamander.png`;
const head8BallImage = `${imagePath}/heads/Head - 8Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headVisionary2Image = `${imagePath}/heads/Head - Visionary 2.png`;

// WEAPONS
const weaponLeftHandImage = `${imagePath}/weapon/Left Arm - Hand.png`;
const weaponLeftDrillImage = `${imagePath}/weapon/Left Arm - Drill.png`;
const weaponLeftFusionRifleImage = `${imagePath}/weapon/Left Arm - Fusion Rifle.png`;

// ACCESSORIES
const accessoryAntennaeImage = `${imagePath}/accessory/Accessory - Antennae.png`;
const accessoryAntennaeNoHeadImage = `${imagePath}/accessory/Accessory - Antennae no head.png`;

// GLOWS
const glowChassisImage = `${imagePath}/glows/Glow - Chassis.png`;
const glowChassisAntennaeImage = `${imagePath}/glows/Glow - Chassis Antennae.png`;
const glowChassisAntennaeNoHeadImage = `${imagePath}/glows/Glow - Chassis Antennae no head.png`;
const glowDrillImage = `${imagePath}/glows/Glow - Drill.png`;
const glowFusionRifleImage = `${imagePath}/glows/Glow - Fusion Rifle.png`;
const glowSlagBombLow1Image = `${imagePath}/glows/Glow - Slag Bomb Low 1.png`;
const glowSlagBombLow2Image = `${imagePath}/glows/Glow - Slag Bomb Low 2.png`;
const glowSlagBombTall1Image = `${imagePath}/glows/Glow - Slag Bomb Tall 1.png`;
const glowSlagBombTall2Image = `${imagePath}/glows/Glow - Slag Bomb Tall 2.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/core_salamander-Toast.png`;
const core2Image = `${imagePath}/core-slag-1-toast.png`;
const core3Image = `${imagePath}/core-slag-2-toast.png`;
const core4Image = `${imagePath}/core-slag-3-toast.png`;
const core5Image = `${imagePath}/core-slag-4-toast.png`;
const core6Image = `${imagePath}/core_salamander-MechaMaria64.png`;
const core7Image = `${imagePath}/core-slag-1-MechaMaria64.png`;
const core8Image = `${imagePath}/core-slag-2-MechaMaria64.png`;
const core9Image = `${imagePath}/core-slag-3-MechaMaria64.png`;
const core10Image = `${imagePath}/core-slag-4-MechaMaria64.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "60px",
    right: "20px",
};

const slagShadow = {
    size: shadowTypes.LARGE,
    bottom: "93px",
    right: "56px",
};

const slagTallShadow = {
    size: shadowTypes.LARGE,
    bottom: "81px",
    right: "36px",
};

const originalArtCredit = {
    prefixText: "Commissioned by Ralf Ziegler. Original design by ",
    title: "Franceso Silva",
    url: "https://twitter.com/francescolobo?lang=en",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core3Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core4Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core5Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core6Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core7Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core8Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core9Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core10Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "MechaMaria64",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "1",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SALAMANDER",
            src: headSalamanderImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 6,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 7,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 9,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 10,
            label: "HOOD",
            src: headHoodImage,
        },
        {
            value: 11,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 12,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 13,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 15,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 16,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 17,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 18,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 19,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 20,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 21,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 22,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 23,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 24,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 25,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 26,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 27,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 28,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 29,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 30,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 31,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 32,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 33,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 34,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 35,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 36,
            label: "VISIONARY 2",
            src: headVisionary2Image,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SALAMANDER",
            src: bodySalamanderImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "SLAG BOMB - LOW",
            src: bodySlagLowImage,
            shadowDetails: slagShadow,
            originalArtCredit,
        },
        {
            value: 2,
            label: "SLAG BOMB - TALL",
            src: bodySlagTallImage,
            shadowDetails: slagTallShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                src: weaponLeftHandImage,
            },
            {
                value: 1,
                label: "DRILL",
                src: weaponLeftDrillImage,
            },
            {
                value: 2,
                label: "FUSION RIFLE",
                src: weaponLeftFusionRifleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ANTENNAE",
            src: accessoryAntennaeImage,
        },
        {
            value: 1,
            label: "ANTENNAE (NO HEAD)",
            src: accessoryAntennaeNoHeadImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS + ANTENNAE",
            frontSrc: glowChassisAntennaeImage,
        },
        {
            value: 2,
            label: "CHASSIS + ANTENNAE (NO HEAD)",
            frontSrc: glowChassisAntennaeNoHeadImage,
        },
        {
            value: 3,
            label: "DRILL",
            frontSrc: glowDrillImage,
        },
        {
            value: 4,
            label: "FUSION RIFLE",
            frontSrc: glowFusionRifleImage,
        },
        {
            value: 5,
            label: "SLAG BOMB LOW 1",
            src: glowSlagBombLow1Image,
        },
        {
            value: 6,
            label: "SLAG BOMB LOW 2",
            src: glowSlagBombLow2Image,
        },
        {
            value: 7,
            label: "SLAG BOMB TALL 1",
            src: glowSlagBombTall1Image,
        },
        {
            value: 8,
            label: "SLAG BOMB TALL 2",
            src: glowSlagBombTall2Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON LEFT",
            value: "weapon",
        },
        {
            label: "ACCESSORY",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "WEAPON LEFT",
        "secondaryWeapon": "ACCESSORY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
