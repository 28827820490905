import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyMetalmarkImage = `${BaseURL}/Mechs/SSC/Metalmark/Chassis.png`;

// HEADS
const headMetalmarkImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/Head - Metalmark.png`;
// PREMIUM
const headChimeraImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Chimera.png`;
const headCockpitClosedImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Cockpit Closed.png`;
const headCyclopsImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Cyclops.png`;
const headLaborerImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Malice.png`;
const headWitchImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Witch.png`;
const headCockpitImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${BaseURL}/Mechs/SSC/Metalmark/heads/premium/Head - Scopehound.png`;

// WEAPON FRONT
const weaponFront1ShockKnivesImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 1 - Shock Knives.png`;
const weaponFront2AntiMatRifleImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 2 - Anti-Material Rifle.png`;
const weaponFront2DecksweeperImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 2 - Decksweeper.png`;
const weaponFront2DMRImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 2 - DMR.png`;
const weaponFront2HMGImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 2 - HMG.png`;
const weaponFront2RailRifleImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 2 - Rail Rifle.png`;
const weaponFront2Rifle1Image = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 2 - Rifle 1.png`;
const weaponFront2Rifle2Image = `${BaseURL}/Mechs/SSC/Metalmark/weapons/front/Weapon - Front Arms 2 - Rifle 2.png`;

const frontArms1Image = `${BaseURL}/Mechs/SSC/Metalmark/Front Arms 1.png`;
const frontArms2Image = `${BaseURL}/Mechs/SSC/Metalmark/Front Arms 2.png`;

// WEAPON BACK
const weaponBack1ShockKnivesImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/back/Weapon - Back Arms 1 - Knife Knife.png`;
const weaponBack1TerashimaBladeKnifeImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/back/Weapon - Back Arms 1 - Terashima Blade Knife.png`;
const weaponBack2HeavyAxeKnifeImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/back/Weapon - Back Arms 2 - Heavy Axe Knife.png`;
const weaponBack2HeavyBladeKnifeImage = `${BaseURL}/Mechs/SSC/Metalmark/weapons/back/Weapon - Back Arms 2 - Heavy Blade Knife.png`;

const backArms1Image = `${BaseURL}/Mechs/SSC/Metalmark/Back Arms 1.png`;
const backArms2Image = `${BaseURL}/Mechs/SSC/Metalmark/Back Arms 2.png`;

// ACCESSORY
const accessoryPistolImage = `${BaseURL}/Mechs/SSC/Metalmark/accessories/Accessories - Pistol.png`;
const accessoryDualPistolsImage = `${BaseURL}/Mechs/SSC/Metalmark/accessories/Accessories - Dual Pistols.png`;
const accessoryMissilesImage = `${BaseURL}/Mechs/SSC/Metalmark/accessories/Accessories - Missiles.png`;

const previewImage = `${BaseURL}/Mechs/SSC/Metalmark/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "30px",
    right: "37px",
};

export default {
    previewImg: previewImage,
    removeNone: {
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "METALMARK",
            src: headMetalmarkImage,
        },
        {
            value: 1,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "COCKPIT - CLOSED",
            src: headCockpitClosedImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "WITCH",
            src: headWitchImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "METALMARK",
            src: bodyMetalmarkImage,
            shadowDetails: bipedLegsShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SHOCK KNIVES",
                frontSrc: weaponFront1ShockKnivesImage,
                src: frontArms1Image,
            },
            {
                value: 1,
                label: "ANTI-MATERIEL RIFLE",
                frontSrc: weaponFront2AntiMatRifleImage,
                src: frontArms2Image,
            },
            {
                value: 2,
                label: "DECKSWEEPER",
                frontSrc: weaponFront2DecksweeperImage,
                src: frontArms2Image,
            },
            {
                value: 3,
                label: "DMR",
                frontSrc: weaponFront2DMRImage,
                src: frontArms2Image,
            },
            {
                value: 4,
                label: "HMG",
                frontSrc: weaponFront2HMGImage,
                src: frontArms2Image,
            },
            {
                value: 5,
                label: "RAIL RIFLE",
                frontSrc: weaponFront2RailRifleImage,
                src: frontArms2Image,
            },
            {
                value: 6,
                label: "RIFLE I",
                frontSrc: weaponFront2Rifle1Image,
                src: frontArms2Image,
            },
            {
                value: 7,
                label: "RIFLE II",
                frontSrc: weaponFront2Rifle2Image,
                src: frontArms2Image,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SHOCK KNIVES",
            frontSrc: weaponBack1ShockKnivesImage,
            src: backArms1Image,
        },
        {
            value: 1,
            label: "TERASHIMA BLADE + KNIFE",
            frontSrc: weaponBack1TerashimaBladeKnifeImage,
            src: backArms1Image,
        },
        {
            value: 2,
            label: "HEAVY AXE + KNIFE",
            frontSrc: weaponBack2HeavyAxeKnifeImage,
            src: backArms2Image,
        },
        {
            value: 3,
            label: "HEAVY BLADE + KNIFE",
            frontSrc: weaponBack2HeavyBladeKnifeImage,
            src: backArms2Image,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "PISTOL",
            src: accessoryPistolImage,
        },
        {
            value: 1,
            label: "DUAL PISTOLS",
            src: accessoryDualPistolsImage,
        },
        {
            value: 2,
            label: "MISSILES",
            frontSrc: accessoryMissilesImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "FRONT WEAPON",
            value: "weapon",
        },
        {
            label: "REAR WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "FRONT WEAPON",
        "secondaryWeapon": "REAR WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
