import styled from "styled-components";

const OpaqueWindowContainerWrapper = styled.div`
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.9);
    border-color: #333;
    border-radius: 10px;
    border-style: outset;
    border-width: 4px;
    box-shadow: 0 0 2px 1px #000;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 200px;
    padding: 20px;
    right: 0;
    left: 0;
    top: 0;
    position: fixed;
    text-align: center;
    z-index: 30;
    max-width: 50%;
    max-height: 60%;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        max-width: 85%;
        margin-top: 150px;
    }
`;

OpaqueWindowContainerWrapper.displayName = "OpaqueWindowContainerWrapper";

export default OpaqueWindowContainerWrapper;
