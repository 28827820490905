import styled from "styled-components";

const ExportWrapper = styled.div`
    canvas, a, input {
        display: none;
    }

    @media print {
        display: none;
    }
`;

ExportWrapper.displayName = "ExportWrapper";

export default ExportWrapper;
