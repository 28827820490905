import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Egregorian 1`;

const body1Image =  `${imagePath}/Egregorian 1.png`;

// HEADS
const head1Image = `${imagePath}/heads/Head - Head 1.png`;
const head2Image = `${imagePath}/heads/Head - Head 2.png`;
const head3Image = `${imagePath}/heads/Head - Head 3.png`;
const head4Image = `${imagePath}/heads/Head - Head 4.png`;
const head5Image = `${imagePath}/heads/Head - Head 5.png`;
const head6Image = `${imagePath}/heads/Head - Head 6.png`;
const head7Image = `${imagePath}/heads/Head_7.png`;
const head8Image = `${imagePath}/heads/Head_8.png`;
const head9Image = `${imagePath}/heads/Head_9.png`;
const headBobRossmanImage = `${imagePath}/heads/Head_bob_rossman.png`;

// TORSO
const torsoMantle1Image = `${imagePath}/torso/Torso - Mantle 1.png`;
const torsoMantle2Image = `${imagePath}/torso/Torso - Mantle 2.png`;
const torsoBigMantleImage = `${imagePath}/torso/big_mantle_.png`;
const torsoSkirtImage = `${imagePath}/torso/Torso - Skirt.png`;
const torsoMantle1SkirtImage = `${imagePath}/torso/Torso - Mantle 1 Skirt.png`;
const torsoMantle2SkirtImage = `${imagePath}/torso/Torso - Mantle 2 Skirt.png`;

// WINGS
const wings1Image = `${imagePath}/wings/Wings - Wings 1.png`;
const wings2Image = `${imagePath}/wings/Wings - Wings 2.png`;
const wings3Image = `${imagePath}/wings/Wings - Wings 3.png`;
const wings4Image = `${imagePath}/wings/Wings - Wings 4.png`;
const wings5Image = `${imagePath}/wings/Wings - Wings 5.png`;

// ARMS
const armsDefaultImage = `${imagePath}/arms/Arms - Default.png`;
const armsSwordImage = `${imagePath}/arms/Arms - Sword.png`;
const armsAssaultRifleImage = `${imagePath}/arms/Arms - Assault Rifle.png`;

// ACCESSORIES
const accessoryKneepadsImage = `${imagePath}/accessories/Accessories - Kneepads.png`;
const accessoryScarfImage = `${imagePath}/accessories/Accessories - Scarf.png`;
const accessorySpareMagsImage = `${imagePath}/accessories/Accessories - Spare mags.png`;
const accessoryStrapsImage = `${imagePath}/accessories/Accessories - Straps.png`;
const accessoryStraps2Image = `${imagePath}/accessories/Accessories - Straps 2.png`;
const accessoryAntennaeGlowImage = `${imagePath}/accessories/Accessory 2 - Antennae Glow.png`;
const accessoryEyeGlowImage = `${imagePath}/accessories/Accessory 2 - Eye Glow.png`;
const accessoryHelmet1Image = `${imagePath}/accessories/Accessory 2 - Helmet 1.png`;
const accessoryHelmet2Image = `${imagePath}/accessories/Accessory 2 - Helmet 2.png`;
const accessoryHelmet3Image = `${imagePath}/accessories/Accessory 2 - Helmet 3.png`;
const accessoryMusicalNotesImage = `${imagePath}/accessories/Accessory 2 - Musical Notes.png`;
const accessoryNBPImage = `${imagePath}/accessories/non-binary_pride.png`;
const accessoryPPImage = `${imagePath}/accessories/pan_pride.png`;
const accessoryTPImage = `${imagePath}/accessories/trans_pride.png`;
const accessoryRadarPackImage = `${imagePath}/accessories/Radar_pack.png`;
const accessoryRadarPackGlowImage = `${imagePath}/accessories/Radar_pack_glow.png`;

const previewImage = `${imagePath}/preview.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: previewImage,
    defaultValues: `{
    "chassisValue": "0",
    "headValue": "0",
    "rearMountValue": "NONE",
    "weaponValue": "0",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "HEAD 1",
            foremostSrc: head1Image,
        },
        {
            value: 1,
            label: "HEAD 2",
            foremostSrc: head2Image,
        },
        {
            value: 2,
            label: "HEAD 3",
            foremostSrc: head3Image,
        },
        {
            value: 3,
            label: "HEAD 4",
            foremostSrc: head4Image,
        },
        {
            value: 4,
            label: "HEAD 5",
            foremostSrc: head5Image,
        },
        {
            value: 5,
            label: "HEAD 6",
            foremostSrc: head6Image,
        },
        {
            value: 6,
            label: "HEAD 7",
            foremostSrc: head7Image,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 7,
            label: "HEAD 8",
            foremostSrc: head8Image,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 8,
            label: "HEAD 9",
            foremostSrc: head9Image,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 9,
            label: "BOB ROSSMAN",
            foremostSrc: headBobRossmanImage,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    chassis: [
        {
            value: 0,
            label: "EGREGORIAN 1",
            src: body1Image,
            shadowDetails: shadowDetails,
            originalArtCredit: {
                title: "Steve_From_Accounting",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "DEFAULT",
                frontSrc: armsDefaultImage,
            },
            {
                value: 1,
                label: "SWORD",
                frontSrc: armsSwordImage,
            },
            {
                value: 2,
                label: "ASSAULT RIFLE",
                frontSrc: armsAssaultRifleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MANTLE 1",
            frontSrc: torsoMantle1Image,
        },
        {
            value: 1,
            label: "MANTLE 2",
            frontSrc: torsoMantle2Image,
        },
        {
            value: 2,
            label: "SKIRT",
            frontSrc: torsoSkirtImage,
        },
        {
            value: 3,
            label: "MANTLE 1 + SKIRT",
            frontSrc: torsoMantle1SkirtImage,
        },
        {
            value: 4,
            label: "MANTLE 2 + SKIRT",
            frontSrc: torsoMantle2SkirtImage,
        },
        {
            value: 5,
            label: "BIG MANTLE",
            frontSrc: torsoBigMantleImage,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "WINGS 1",
            frontSrc: wings1Image,
        },
        {
            value: 1,
            label: "WINGS 2",
            frontSrc: wings2Image,
        },
        {
            value: 2,
            label: "WINGS 3",
            frontSrc: wings3Image,
        },
        {
            value: 3,
            label: "WINGS 4",
            frontSrc: wings4Image,
        },
        {
            value: 4,
            label: "WINGS 5",
            frontSrc: wings5Image,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "KNEEPADS",
            frontSrc: accessoryKneepadsImage,
        },
        {
            value: 1,
            label: "SCARF",
            frontSrc: accessoryScarfImage,
        },
        {
            value: 2,
            label: "SPARE MAGS",
            frontSrc: accessorySpareMagsImage,
        },
        {
            value: 3,
            label: "STRAPS",
            frontSrc: accessoryStrapsImage,
        },
        {
            value: 4,
            label: "STRAPS 2",
            frontSrc: accessoryStraps2Image,
        },
        {
            value: 5,
            label: "ANTENNAE GLOW",
            foremostSrc: accessoryAntennaeGlowImage,
        },
        {
            value: 6,
            label: "EYE GLOW",
            foremostSrc: accessoryEyeGlowImage,
        },
        {
            value: 7,
            label: "HELMET 1",
            foremostSrc: accessoryHelmet1Image,
        },
        {
            value: 8,
            label: "HELMET 2",
            foremostSrc: accessoryHelmet2Image,
        },
        {
            value: 9,
            label: "HELMET 3",
            foremostSrc: accessoryHelmet3Image,
        },
        {
            value: 10,
            label: "MUSICAL NOTES",
            src: accessoryMusicalNotesImage,
        },
        {
            value: 11,
            label: "NON-BINARY PRIDE",
            src: accessoryNBPImage,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 12,
            label: "PAN PRIDE",
            src: accessoryPPImage,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 13,
            label: "TRANS PRIDE",
            src: accessoryTPImage,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 14,
            label: "RADAR PACK",
            src: accessoryRadarPackImage,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 15,
            label: "RADAR PACK - GLOW",
            frontSrc: accessoryRadarPackGlowImage,
            originalArtCredit: {
                title: "Kellofkells",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "BODY",
            value: "chassis",
        },
        {
            label: "WINGS",
            value: "rearMount",
        },
        {
            label: "ARMS",
            value: "weapon",
        },
        {
            label: "TORSO",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "BODY",
        "rearMount": "WINGS",
        "weapon": "ARMS",
        "secondaryWeapon": "TORSO",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
