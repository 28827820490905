import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyGenosImage = `${BaseURL}/Mechs/Custom/Genos Aran/Chassis.png`;
const bodyGenosDZImage = `${BaseURL}/Mechs/Custom/Genos Aran/Chassis - Dangerzone.png`;

// HEADS
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Natural Law.png`;
// PREMIUM
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Natural Law Hair.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Caliban.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Cyclops.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Horned Hair.png`;
const headJudicatorImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Judicator.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Laborer.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Malice.png`;
const headPredatorImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Predator.png`;
const headRainImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Rain Transformed.png`;
const headRainHairImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Rain Transformed Hair.png`;
const headVanguardImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Vanguard.png`;
const headBlackbeardImage = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Blackbeard.png`;
const headBlackbeard2Image = `${BaseURL}/Mechs/Custom/Genos Aran/heads/Head - Blackbeard 2.png`;

// WEAPONS
const weaponsFistImage = `${BaseURL}/Mechs/Custom/Genos Aran/weapons/Right Weapon - Fist.png`;
const weaponsBladeImage = `${BaseURL}/Mechs/Custom/Genos Aran/weapons/Right Weapon - Blade.png`;
const weaponsSpearImage = `${BaseURL}/Mechs/Custom/Genos Aran/weapons/Right Weapon - Spear.png`;

const glowsBladeImage = `${BaseURL}/Mechs/Custom/Genos Aran/glow/Glow - Blade.png`;
const glowsSpearImage = `${BaseURL}/Mechs/Custom/Genos Aran/glow/Glow - Spear.png`;

// ACCESSORY
const glowDZImage = `${BaseURL}/Mechs/Custom/Genos Aran/glow/Glow - Dangerzone.png`;
const glowDZArcsImage = `${BaseURL}/Mechs/Custom/Genos Aran/glow/Glow - Dangerzone Arcs.png`;
const accessoryNelsonRobeImage = `${BaseURL}/Mechs/Custom/Genos Aran/Accessory - Nelson Robes.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Genos Aran/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "50px",
    right: "70px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 1,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "JUDICATOR",
            src: headJudicatorImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "RAIN HAIR",
            src: headRainHairImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 9,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 10,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 11,
            label: "PREDATOR",
            src: headPredatorImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 12,
            label: "VANGUARD",
            src: headVanguardImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 13,
            label: "BLACKBEARD",
            src: headBlackbeardImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 14,
            label: "BLACKBEARD II",
            src: headBlackbeard2Image,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GENOS ARAN",
            src: bodyGenosImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "DANGERZONE",
            src: bodyGenosDZImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "FIST",
                backSrc: weaponsFistImage,
            },
            {
                value: 1,
                label: "BLADE",
                backSrc: weaponsBladeImage,
                isPremium: true,
                isLegendary: true,
            },
            {
                value: 2,
                label: "SPEAR",
                backSrc: weaponsSpearImage,
                isPremium: true,
                isLegendary: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BLADE",
            src: glowsBladeImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "SPEAR",
            src: glowsSpearImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "GLOW - DANGERZONE",
            src: glowDZImage,
        },
        {
            value: 1,
            label: "GLOW - DANGERZONE + ARCS",
            src: glowDZArcsImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "NELSON ROBES",
            src: accessoryNelsonRobeImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "WEAPON GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "WEAPON GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
