import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Galiban`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis_Galiban.png`;

// HEADS
const headGalibanImage = `${imagePath}/heads/Head_Galiban.png`;
const headDesperadoImage = `${imagePath}/heads/Head_Desperado.png`;
const headNyalibanImage = `${imagePath}/heads/Head_Nyaliban.png`;

// WEAPONS
// LEFT
const leftWeaponFlayerImage = `${imagePath}/weapons/left/Weapon_Left_Flayer.png`;
const leftWeaponKittyClawsImage = `${imagePath}/weapons/left/Weapon_Left_Kitty Claws.png`;
const leftWeaponRevolverImage = `${imagePath}/weapons/left/Weapon_Left_Revolver.png`;
const leftWeaponShotgunImage = `${imagePath}/weapons/left/Weapon_Left_Shotgun.png`;

// RIGHT
const rightWeaponFlayerImage = `${imagePath}/weapons/right/Weapon_Right_Flayer.png`;
const rightWeaponKatanaImage = `${imagePath}/weapons/right/Weapon_Right_Katana.png`;
const rightWeaponKittyClawsImage = `${imagePath}/weapons/right/Weapon_Right_Kitty Claws.png`;
const rightWeaponNanocarbonBladeImage = `${imagePath}/weapons/right/Weapon_Right_Nanocarbon Blade.png`;

// ACCESSORIES
const accessoryBlazerFrontImage = `${imagePath}/accessories/Accesories_Front_Blazer.png`;
const accessoryBlazerMidImage = `${imagePath}/accessories/Accesories_mid_Blazer.png`;
const accessoryBlazerBackImage = `${imagePath}/accessories/Accesories_back_blazer.png`;
const accessoryDesperadoFrontImage = `${imagePath}/accessories/Accesories_Front_Desperado.png`;
const accessoryDesperadoMidImage = `${imagePath}/accessories/Accesories_mid_Desperado.png`;
const accessoryDesperadoBackImage = `${imagePath}/accessories/Accesories_back_Desperado.png`;
const accessoryFeetPawsImage = `${imagePath}/accessories/Accesories_mid_Feet Paws.png`;

// GLOWS
const glowDesperadoHeadImage = `${imagePath}/accessories/Accesories_Front_Desperado Head Glow.png`;
const glowFeetPawsImage = `${imagePath}/accessories/Accesories_Front_Feet Paws Glow.png`;
const glowGalibanHeadImage = `${imagePath}/accessories/Accesories_Front_Galiban Head Glow.png`;
const glowNyalibanHeadImage = `${imagePath}/accessories/Accesories_Front_Nyaliban Head Glow.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "80px",
    right: "31px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "3",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "GALIBAN",
            src: headGalibanImage,
        },
        {
            value: 1,
            label: "DESPERADO",
            src: headDesperadoImage,
        },
        {
            value: 2,
            label: "NYALIBAN",
            src: headNyalibanImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "GALIBAN",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "FLAYER",
                foremostSrc: rightWeaponFlayerImage,
            },
            {
                value: 1,
                label: "KATANA",
                foremostSrc: rightWeaponKatanaImage,
            },
            {
                value: 2,
                label: "KITTY CLAWS",
                foremostSrc: rightWeaponKittyClawsImage,
            },
            {
                value: 3,
                label: "NANOCARBON BLADE",
                foremostSrc: rightWeaponNanocarbonBladeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FLAYER",
            foremostSrc: leftWeaponFlayerImage,
        },
        {
            value: 1,
            label: "KITTY CLAWS",
            foremostSrc: leftWeaponKittyClawsImage,
        },
        {
            value: 2,
            label: "REVOLVER",
            foremostSrc: leftWeaponRevolverImage,
        },
        {
            value: 3,
            label: "SHOTGUN",
            foremostSrc: leftWeaponShotgunImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BLAZER",
            frontSrc: accessoryBlazerFrontImage,
            src: accessoryBlazerMidImage,
            backSrc: accessoryBlazerBackImage,
        },
        {
            value: 1,
            label: "DESPERADO",
            frontSrc: accessoryDesperadoFrontImage,
            src: accessoryDesperadoMidImage,
            backSrc: accessoryDesperadoBackImage,
        },
        {
            value: 2,
            label: "FEET PAWS",
            src: accessoryFeetPawsImage,
        },
        {
            value: 3,
            label: "GLOW - GALIBAN HEAD",
            frontSrc: glowGalibanHeadImage,
        },
        {
            value: 4,
            label: "GLOW - DESPERADO HEAD",
            frontSrc: glowDesperadoHeadImage,
        },
        {
            value: 5,
            label: "GLOW - NYALIBAN HEAD",
            frontSrc: glowNyalibanHeadImage,
        },
        {
            value: 6,
            label: "GLOW - FEET PAWS",
            frontSrc: glowFeetPawsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
