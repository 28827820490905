import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const unitPath = `${BaseURL}/Mechs/NPC/Corpro/Priest`;

// BODY
const bodyPriestImage = `${unitPath}/Priest.png`;

// MAIN WEAPON
const haloImage = `${unitPath}/weapons/Aux - Halo.png`;
const tetherImage = `${unitPath}/weapons/Weapon - Tether.png`;
const shieldDronesImage = `${unitPath}/weapons/Weapon - Shield Drones.png`;
const shieldDronesTetherImage = `${unitPath}/weapons/Weapon - Shield Drones Tether.png`;
const shieldDronesTetherHaloImage = `${unitPath}/weapons/Weapon - Shield Drones Tether Ring.png`;

// GLOW
const priestGlowImage = `${unitPath}/glow/Glow - Priest.png`;
const priestDronesGlowImage = `${unitPath}/glow/Glow - Priest Drones.png`;
const priestTetherGlowImage = `${unitPath}/glow/Glow - Halo Tether.png`;
const priestDronesTetherGlowImage = `${unitPath}/glow/Glow - Priest Drones Tether.png`;
const eyeGlowImage = `${unitPath}/glow/Priest Eye Glow.png`;
const eyePriestGlowImage = `${unitPath}/glow/Priest Chassis Eye Glow.png`;
const eyePriestDronesGlowImage = `${unitPath}/glow/Priest Chassis Eye Glow Drones.png`;
const eyePriestTetherGlowImage = `${unitPath}/glow/Priest Chassis Eye Glow Tether.png`;
const eyePriestDronesTetherGlowImage = `${unitPath}/glow/Priest Glow - All.png`;
const simCamoImage = `${unitPath}/camo/Camo - Sim.png`;

// CAMO
const corproCamo1Image = `${unitPath}/camo/Camo - Corpro 1.png`;
const corproCamo2Image = `${unitPath}/camo/Camo - Corpro 2.png`;
const forestCamo1Image = `${unitPath}/camo/Camo - Forest 1.png`;
const forestCamo2Image = `${unitPath}/camo/Camo - Forest 2.png`;
const urbanCamo1Image = `${unitPath}/camo/Camo - Urban 1.png`;
const urbanCamo2Image = `${unitPath}/camo/Camo - Urban 2.png`;
const headCamoImage = `${unitPath}/camo/Camo - Head Only.png`;
const tigerCamoImage = `${unitPath}/camo/Camo - Tiger.png`;
const halftoneVertCamoImage = `${unitPath}/camo/Camo - Halftone Vert.png`;
const halftoneDiagCamoImage = `${unitPath}/camo/Camo - Halftone Diag.png`;
const survivorCamoImage = `${unitPath}/camo/Camo - Survivor.png`;

const previewImage = `${unitPath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "48px",
    right: "30px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "4",
        "secondaryWeaponValue": "8",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    defaultInactiveValues: `{
        "chassisValue": "0",
        "weaponValue": "4",
        "secondaryWeaponValue": "3",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "PRIEST",
            src: bodyPriestImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: [
        {
            value: 0,
            label: "HALO - COLOR",
            src: haloImage,
        },
        {
            value: 1,
            label: "TETHER",
            src: tetherImage,
        },
        {
            value: 2,
            label: "SHIELD DRONES",
            src: shieldDronesImage,
        },
        {
            value: 3,
            label: "SHIELD DRONES + TETHER",
            src: shieldDronesTetherImage,
        },
        {
            value: 4,
            label: "SHIELD DRONES + TETHER + HALO",
            src: shieldDronesTetherHaloImage,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "HALO - COLOR",
            src: priestGlowImage,
        },
        {
            value: 1,
            label: "HALO + DRONES",
            src: priestDronesGlowImage,
        },
        {
            value: 2,
            label: "HALO + TETHER",
            src: priestTetherGlowImage,
        },
        {
            value: 3,
            label: "HALO + DRONES + TETHER",
            src: priestDronesTetherGlowImage,
        },
        {
            value: 4,
            label: "EYE",
            src: eyeGlowImage,
        },
        {
            value: 5,
            label: "EYE + HALO",
            src: eyePriestGlowImage,
        },
        {
            value: 6,
            label: "EYE + HALO + DRONES",
            src: eyePriestDronesGlowImage,
        },
        {
            value: 7,
            label: "EYE + HALO + TETHER",
            src: eyePriestTetherGlowImage,
        },
        {
            value: 8,
            label: "EYE + HALO + DRONES + TETHER",
            src: eyePriestDronesTetherGlowImage,
        },
        {
            value: 9,
            label: "SIM",
            src: simCamoImage,
            originalArtCredit: contributorList.CATOFMANYFACES,
            isPlusTwo: true,
        },
    ],
    camo: [
        {
            value: 0,
            label: "HEAD",
            src: headCamoImage,
        },
        {
            value: 1,
            label: "CORPRO 1",
            src: corproCamo1Image,
        },
        {
            value: 2,
            label: "CORPRO 2",
            src: corproCamo2Image,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
        },
        {
            value: 5,
            label: "URBAN 1",
            src: urbanCamo1Image,
            isPremium: true,
        },
        {
            value: 6,
            label: "URBAN 2",
            src: urbanCamo2Image,
            isPremium: true,
        },
        {
            value: 7,
            label: "TIGER",
            src: tigerCamoImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HALFTONE VERT",
            src: halftoneVertCamoImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HALFTONE DIAG",
            src: halftoneDiagCamoImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "SURVIVOR",
            src: survivorCamoImage,
            originalArtCredit: contributorList.CORVON,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
