import { shadowDetailsNone } from "../styles/UnitShadow";
import { contributorList } from "../Category/components/ArtCreditLink";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Kidd-Deployables`;

// UNITS
const warchildX3Image = `${imagePath}/Kidd x3.png`;
const warchildX6Image = `${imagePath}/Kidd x6.png`;
const marauderX3Image = `${imagePath}/Marauder x3.png`;
const marauderX6Image = `${imagePath}/Marauder x6.png`;
const grapeshotX3Image = `${imagePath}/Grapeshot x3.png`;
const grapeshotX6Image = `${imagePath}/Grapeshot x6.png`;
const repairbotX3Image = `${imagePath}/Repairbot x3.png`;
const repairbotX6Image = `${imagePath}/Repairbot x6.png`;
const smokestackImage = `${imagePath}/Smokestack.png`;
const accChassisDetailImage = `${imagePath}/chassis-WARCHILD X6-mid.png`;

// GLOW
const smokestackGlowImage = `${imagePath}/Smokestack Glow.png`;

const shadowDetails = shadowDetailsNone;

export default {
    previewImg: warchildX6Image,
    rearMountsAreChassis: true,
    defaultValues: `{
    "chassisValue": "1",
    "headValue": "NONE",
    "rearMountValue": "NONE",
    "weaponValue": "NONE",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "NONE",
    "accessory2Value": "NONE",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SMOKESTACK",
            src: smokestackGlowImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "WARCHILD X3",
            src: warchildX3Image,
            shadowDetails: shadowDetails,
        },
        {
            value: 1,
            label: "WARCHILD X6",
            src: warchildX6Image,
            shadowDetails: shadowDetails,
        },
        {
            value: 2,
            label: "SMOKESTACK",
            src: smokestackImage,
            shadowDetails: shadowDetails,
        },
        {
            value: 3,
            label: "MARAUDER X3",
            src: marauderX3Image,
            shadowDetails: shadowDetails,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "MARAUDER X6",
            src: marauderX6Image,
            shadowDetails: shadowDetails,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "GRAPESHOT X3",
            src: grapeshotX3Image,
            shadowDetails: shadowDetails,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "GRAPESHOT X6",
            src: grapeshotX6Image,
            shadowDetails: shadowDetails,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "REPAIRBOT X3",
            src: repairbotX3Image,
            shadowDetails: shadowDetails,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "REPAIRBOT X6",
            src: repairbotX6Image,
            shadowDetails: shadowDetails,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "WARCHILD X6 DETAILS",
                src: accChassisDetailImage,
                originalArtCredit: contributorList.CORVON,
                isPlusTwo: true,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "UNITS",
            value: "chassis",
        },
        {
            label: "DETAILS",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "UNITS",
        "rearMount": "EAST",
        "weapon": "DETAILS",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
