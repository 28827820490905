import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI Solomon/Solomon`;

// BODY
const bodySolomonImage = `${imagePath}/Chassis.png`;

// HEADS
const headSolomonHaloImage = `${imagePath}/heads/Head - Solomon.png`;
const headSolomonImage = `${imagePath}/heads/Head - Solomon (no halo).png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headFTNelsonImage = `${imagePath}/heads/Head - Flight Type Nelson.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNelson2Image = `${imagePath}/heads/Head - Nelson 2.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headWhiteWitchImage = `${imagePath}/heads/Head - White Witch.png`;

// WEAPONS
const weaponHexRingImage = `${imagePath}/weapons/Rings - Hex Formation.png`;
const weaponOffensiveRingImage = `${imagePath}/weapons/Rings - Offensive Formation.png`;

// GLOWS
const glowChassisImage = `${imagePath}/glows/Glow 1 - Chassis.png`;
const glowChassisHeadImage = `${imagePath}/glows/Glow 1 - Chassis + Head.png`;
const glowChassisHeadHaloImage = `${imagePath}/glows/Glow 1 - Chassis + Head + Halo.png`;
const glowHexFormationImage = `${imagePath}/glows/Glow 2 - Hex Formation.png`;
const glowHexFormationBindingRingImage = `${imagePath}/glows/Glow 2 - Hex Formation + Binding Ring.png`;
const glowHexFormationChainsImage = `${imagePath}/glows/Glow 2 - Hex Formation + Chains.png`;
const glowHexFormationHandsImage = `${imagePath}/glows/Glow 2 - Hex Formation + Hands.png`;
const glowOffensiveImage = `${imagePath}/glows/Glow 2 - Offensive.png`;
const glowOffensiveHandsImage = `${imagePath}/glows/Glow 2 - Offensive + Hands.png`;
const glowOffensiveHandsSpearsImage = `${imagePath}/glows/Glow 2 - Offensive + Hands + Spears.png`;
const glowSpearsImage = `${imagePath}/glows/Glow 2 - Spears.png`;

const previewImage = `${imagePath}/preview.png`;
const core1Image = `${imagePath}/core-Solomon-AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "-5px",
    right: "-24px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "6",
        "accessory2Value": "2",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SOLOMON + HALO",
            backSrc: headSolomonHaloImage,
        },
        {
            value: 1,
            label: "SOLOMON",
            backSrc: headSolomonImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            backSrc: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            backSrc: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            backSrc: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            backSrc: headChimeraImage,
        },
        {
            value: 6,
            label: "CYCLOPS",
            backSrc: headCyclopsImage,
        },
        {
            value: 7,
            label: "DUNGAM",
            backSrc: headDungamImage,
        },
        {
            value: 8,
            label: "HOOD",
            backSrc: headHoodImage,
        },
        {
            value: 9,
            label: "HORNED",
            backSrc: headHornedImage,
        },
        {
            value: 10,
            label: "HORNED HAIR",
            backSrc: headHornedHairImage,
        },
        {
            value: 11,
            label: "KAZU",
            backSrc: headKazuImage,
        },
        {
            value: 12,
            label: "MAG",
            backSrc: headMagImage,
        },
        {
            value: 13,
            label: "MALICE",
            backSrc: headMaliceImage,
        },
        {
            value: 14,
            label: "MECHA 1",
            backSrc: headMecha1Image,
        },
        {
            value: 15,
            label: "MECHA 2",
            backSrc: headMecha2Image,
        },
        {
            value: 16,
            label: "MECHA 3",
            backSrc: headMecha3Image,
        },
        {
            value: 17,
            label: "MECHA 4",
            backSrc: headMecha4Image,
        },
        {
            value: 18,
            label: "MECHA 5",
            backSrc: headMecha5Image,
        },
        {
            value: 19,
            label: "MECHA 6",
            backSrc: headMecha6Image,
        },
        {
            value: 20,
            label: "MECHA 7",
            backSrc: headMecha7Image,
        },
        {
            value: 21,
            label: "MECHA 8",
            backSrc: headMecha8Image,
        },
        {
            value: 22,
            label: "NATURAL LAW",
            backSrc: headNaturalLawImage,
        },
        {
            value: 23,
            label: "NATURAL LAW HAIR",
            backSrc: headNaturalLawHairImage,
        },
        {
            value: 24,
            label: "NELSON",
            backSrc: headNelsonImage,
        },
        {
            value: 25,
            label: "NELSON 2",
            backSrc: headNelson2Image,
        },
        {
            value: 26,
            label: "NELSON FT",
            backSrc: headFTNelsonImage,
        },
        {
            value: 27,
            label: "NEMESIS",
            backSrc: headNemesisImage,
        },
        {
            value: 28,
            label: "RAIN",
            backSrc: headRainImage,
        },
        {
            value: 29,
            label: "RAIN HAIR",
            backSrc: headRainHairImage,
        },
        {
            value: 30,
            label: "SKULL",
            backSrc: headSkullImage,
        },
        {
            value: 31,
            label: "VANQUISHER",
            backSrc: headVanquisherImage,
        },
        {
            value: 32,
            label: "VICEROY",
            backSrc: headViceroyImage,
        },
        {
            value: 33,
            label: "VISIONARY",
            backSrc: headVisionaryImage,
        },
        {
            value: 34,
            label: "WHITE WITCH",
            backSrc: headWhiteWitchImage,
        },
        {
            value: 35,
            label: "8 BALL",
            backSrc: head8BallImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MOI SOLOMON",
            backSrc: bodySolomonImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HEX FORMATION",
                src: weaponHexRingImage,
            },
            {
                value: 1,
                label: "OFFENSIVE FORMATION",
                src: weaponOffensiveRingImage,
            },

        ],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS + HEAD",
            src: glowChassisHeadImage,
        },
        {
            value: 2,
            label: "CHASSIS + HEAD + HALO",
            src: glowChassisHeadHaloImage,
        },
        {
            value: 3,
            label: "HEX FORMATION",
            frontSrc: glowHexFormationImage,
        },
        {
            value: 4,
            label: "HEX FORMATION - BINDING RINGS",
            frontSrc: glowHexFormationBindingRingImage,
        },
        {
            value: 5,
            label: "HEX FORMATION - CHAINS",
            frontSrc: glowHexFormationChainsImage,
        },
        {
            value: 6,
            label: "HEX FORMATION - HANDS",
            frontSrc: glowHexFormationHandsImage,
        },
        {
            value: 7,
            label: "OFFENSIVE FORMATION",
            frontSrc: glowOffensiveImage,
        },
        {
            value: 8,
            label: "OFFENSIVE FORMATION - HANDS",
            frontSrc: glowOffensiveHandsImage,
        },
        {
            value: 9,
            label: "OFFENSIVE FORMATION - SPEARS",
            frontSrc: glowSpearsImage,
        },
        {
            value: 10,
            label: "OFFENSIVE FORMATION - HANDS + SPEARS",
            frontSrc: glowOffensiveHandsSpearsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "RING WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "RING WEAPON",
        "secondaryWeapon": "EMPTY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
