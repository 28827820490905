import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI-Amakusa`;

// BODY
const bodyAmakusaImage = `${imagePath}/Chassis - Amakusa.png`;
const bodyGeistRunner1Image = `${imagePath}/Chassis - GeistRunner 1.png`;
const bodyGeistRunner2Image = `${imagePath}/Chassis - GeistRunner 2.png`;

// HEADS
const headAmakusaImage = `${imagePath}/heads/Head - Amakusa.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamAeliarImage = `${imagePath}/heads/Head - Dungam Aeliar.png`;
const headGeistrunnerHoodImage = `${imagePath}/heads/Head - Geistrunner Hood.png`;
const headGeistrunnerImage = `${imagePath}/heads/Head - Geistrunner.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMech1Image = `${imagePath}/heads/Head - Mech 1.png`;
const headMech3Image = `${imagePath}/heads/Head - Mech 3.png`;
const headMech4Image = `${imagePath}/heads/Head - Mech 4.png`;
const headMech5Image = `${imagePath}/heads/Head - Mech 5.png`;
const headMech6Image = `${imagePath}/heads/Head - Mech 6.png`;
const headMech7Image = `${imagePath}/heads/Head - Mech 7.png`;
const headMech8Image = `${imagePath}/heads/Head - Mech 8.png`;
const headMugenImage = `${imagePath}/heads/Head - Mugen.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
const weaponChargedBladeImage = `${imagePath}/weapon/Weapon - Charged Blade.png`;
const weaponDD2881Image = `${imagePath}/weapon/Weapon - DD288 1.png`;
const weaponDD2882Image = `${imagePath}/weapon/Weapon - DD288 2.png`;
const weaponMacheteImage = `${imagePath}/weapon/Weapon - Machete.png`;
const weaponSpearImage = `${imagePath}/weapon/Weapon - Spear.png`;
const weaponWakizashiImage = `${imagePath}/weapon/Weapon - Wakizashi.png`;
const weaponWakizashiSheathedImage = `${imagePath}/weapon/Under - Wakizashi Sheathed.png`;
const weaponWraithImage = `${imagePath}/weapon/Weapon - Wraith.png`;
const weaponZanbatoImage = `${imagePath}/weapon/Weapon - Zanbato.png`;

// ARMOR
const armorPauldronsImage = `${imagePath}/armor/Armor - Amakusa Pauldrons.png`;
const armorLegsImage = `${imagePath}/armor/Armor - Amakusa Legs.png`;
const armorAllImage = `${imagePath}/armor/Armor - Amakusa All.png`;

// GLOWS
const glowChargedBladeImage = `${imagePath}/glows/Glow - Charged Blade.png`;
const glowDD2881Image = `${imagePath}/glows/Glow - DD288 1.png`;
const glowDD2882Image = `${imagePath}/glows/Glow - DD288 2.png`;
const glowMacheteImage = `${imagePath}/glows/Glow - Machete.png`;
const glowSpearImage = `${imagePath}/glows/Glow - Spear.png`;
const glowWakizashiImage = `${imagePath}/glows/Glow - Wakizashi.png`;
const glowWraithImage = `${imagePath}/glows/Glow - Wraith.png`;
const glowZanbatoImage = `${imagePath}/glows/Glow - Zanbato.png`;
const glowAmakusaBannerImage = `${imagePath}/glows/Mid Glow - Amakusa Banner.png`;
const glowHaloImage = `${imagePath}/glows/Mid Glow - Halo.png`;
const glowHaloBackImage = `${imagePath}/glows/Under - Halo.png`;
const glowPortalAmakusaImage = `${imagePath}/glows/Mid Glow - Portal - Amakusa .png`;
const glowPortalGeistrunnerImage = `${imagePath}/glows/Mid Glow - Portal - Geistrunner .png`;
const glowPortalGeistrunner2Image = `${imagePath}/glows/Mid Glow - Portal - Geistrunner 2.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/MOI_AMAKUSA_core_AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-7px",
    right: "-10px",
};

export default {
    rearMountsAreChassis: true,
    isMegadeus: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "4",
        "secondaryWeaponValue": "0",
        "accessory1Value": "4",
        "accessory2Value": "8",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AMAKUSA",
            foremostSrc: headAmakusaImage,
        },
        {
            value: 1,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 2,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 3,
            label: "DUNGAM AELIAR",
            foremostSrc: headDungamAeliarImage,
        },
        {
            value: 4,
            label: "GEISTRUNNER HOOD",
            foremostSrc: headGeistrunnerHoodImage,
        },
        {
            value: 5,
            label: "GEISTRUNNER",
            foremostSrc: headGeistrunnerImage,
        },
        {
            value: 6,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 8,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 9,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 10,
            label: "MECH 1",
            foremostSrc: headMech1Image,
        },
        {
            value: 11,
            label: "MECH 3",
            foremostSrc: headMech3Image,
        },
        {
            value: 12,
            label: "MECH 4",
            foremostSrc: headMech4Image,
        },
        {
            value: 13,
            label: "MECH 5",
            foremostSrc: headMech5Image,
        },
        {
            value: 14,
            label: "MECH 6",
            foremostSrc: headMech6Image,
        },
        {
            value: 15,
            label: "MECH 7",
            foremostSrc: headMech7Image,
        },
        {
            value: 16,
            label: "MECH 8",
            foremostSrc: headMech8Image,
        },
        {
            value: 17,
            label: "MUGEN",
            foremostSrc: headMugenImage,
        },
        {
            value: 18,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 19,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 20,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
        },
        {
            value: 21,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedHairImage,
        },
        {
            value: 22,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 23,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "AMAKUSA",
            src: bodyAmakusaImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
        {
            value: 1,
            label: "GEIST RUNNER 1",
            src: bodyGeistRunner1Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
        {
            value: 2,
            label: "GEIST RUNNER 2",
            src: bodyGeistRunner2Image,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CHARGED BLADE",
                src: weaponChargedBladeImage,
            },
            {
                value: 1,
                label: "DD288 1",
                src: weaponDD2881Image,
            },
            {
                value: 2,
                label: "DD288 2",
                src: weaponDD2882Image,
            },
            {
                value: 3,
                label: "MACHETE",
                src: weaponMacheteImage,
            },
            {
                value: 4,
                label: "SPEAR",
                src: weaponSpearImage,
            },
            {
                value: 5,
                label: "WAKIZASHI",
                src: weaponWakizashiImage,
            },
            {
                value: 6,
                label: "WRAITH",
                src: weaponWraithImage,
            },
            {
                value: 7,
                label: "ZANBATO",
                src: weaponZanbatoImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ALL",
            src: armorAllImage,
        },
        {
            value: 1,
            label: "LEGS",
            src: armorLegsImage,
        },
        {
            value: 2,
            label: "PAULDRONS",
            src: armorPauldronsImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CHARGED BLADE",
            frontSrc: glowChargedBladeImage,
        },
        {
            value: 1,
            label: "DD288 1",
            frontSrc: glowDD2881Image,
        },
        {
            value: 2,
            label: "DD288 2",
            frontSrc: glowDD2882Image,
        },
        {
            value: 3,
            label: "MACHETE",
            frontSrc: glowMacheteImage,
        },
        {
            value: 4,
            label: "SPEAR",
            frontSrc: glowSpearImage,
        },
        {
            value: 5,
            label: "WAKIZASHI",
            frontSrc: glowWakizashiImage,
        },
        {
            value: 6,
            label: "WRAITH",
            frontSrc: glowWraithImage,
        },
        {
            value: 7,
            label: "ZANBATO",
            frontSrc: glowZanbatoImage,
        },
        {
            value: 8,
            label: "AMAKUSA BANNER",
            src: glowAmakusaBannerImage,
        },
        {
            value: 9,
            label: "HALO",
            frontSrc: glowHaloImage,
            backSrc: glowHaloBackImage,
        },
        {
            value: 10,
            label: "PORTAL - AMAKUSA",
            frontSrc: glowPortalAmakusaImage,
        },
        {
            value: 11,
            label: "PORTAL - GEIST RUNNER 1",
            frontSrc: glowPortalGeistrunnerImage,
        },
        {
            value: 12,
            label: "PORTAL - GEIST RUNNER 2",
            frontSrc: glowPortalGeistrunner2Image,
        },
        {
            value: 13,
            label: "ACCESSORY - WAKIZASHI SHEATHED",
            backSrc: weaponWakizashiSheathedImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "ARMOR",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "ARMOR",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
