import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyPriestImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/Priest.png`;

// MAIN WEAPON
const weaponStaffImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/weapons/Weapon - Staff.png`;
const weaponNexiiImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/weapons/Weapon - Nexii.png`;
const weaponStaffNexiiImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/weapons/Weapon - Staff Nexii.png`;

// GLOWS
const glowEyeImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/glows/Glow - Eye.png`;
const glowNexiiImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/glows/Glow - Nexii.png`;
const glowBodyImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/glows/Glow - Chassis.png`;
const glowAllImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/glows/Glow - All.png`;

// CAMO
const chitinCamo1Image = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Chitin 1.png`;
const chitinCamo2Image = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Chitin 2.png`;
const forestCamo1Image = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Forest 1.png`;
const forestCamo2Image = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Forest 2.png`;
const stingerCamo1Image = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Stinger 1.png`;
const stingerCamo2Image = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Stinger 2.png`;
const eliteCamoImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Elite.png`;
const underbellyCamoImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Underbelly.png`;
const halftoneCamoImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/camo/Camo - Halftone.png`;

const previewImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/NPC/Biomecha/Priest/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "20px",
    right: "12px",
};

export default {
    restrictedPreviewImg: restrictedPreviewImage,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "3",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "PRIEST",
            src: bodyPriestImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Mykell Pledger",
                url: "https://twitter.com/mykedsgn/status/1369515701501845505",
            },
        },
    ],
    weapon: [
        {
            value: 0,
            label: "STAFF - COLOR",
            src: weaponStaffImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "NEXII - COLOR",
            src: weaponNexiiImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "STAFF + NEXII - COLOR",
            src: weaponStaffNexiiImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "EYE",
            src: glowEyeImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "NEXII",
            src: glowNexiiImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "BODY",
            src: glowBodyImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "ALL",
            src: glowAllImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    camo: [
        {
            value: 0,
            label: "ELITE",
            src: eliteCamoImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "CHITIN 1",
            src: chitinCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "CHITIN 2",
            src: chitinCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "STINGER 1",
            src: stingerCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "STINGER 2",
            src: stingerCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "UNDERBELLY",
            src: underbellyCamoImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "HALFTONE",
            src: halftoneCamoImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
