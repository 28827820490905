import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/NPC/Biomecha/Demolisher`;

// BODY
const bodyImage = `${imagePath}/Demolisher Chassis.png`;

// WEAPONS
const weaponHammerImage = `${imagePath}/weapons/Weapon- Hammer.png`;
const weaponBladeImage = `${imagePath}/weapons/Weapon- Blade.png`;

// GLOWS
const glowImage = `${imagePath}/glows/Glow.png`;

// CAMO
const chitinCamo1Image = `${imagePath}/camo/Camo - Chitin 1.png`;
const chitinCamo2Image = `${imagePath}/camo/Camo - Chitin 2.png`;
const forestCamo1Image = `${imagePath}/camo/Camo - Forest 1.png`;
const forestCamo2Image = `${imagePath}/camo/Camo - Forest 2.png`;
const stingerCamo1Image = `${imagePath}/camo/Camo - Stinger 1.png`;
const stingerCamo2Image = `${imagePath}/camo/Camo - Stinger 2.png`;
const eliteCamoImage = `${imagePath}/camo/Camo - Elite.png`;
const underbellyCamoImage = `${imagePath}/camo/Camo - Underbelly.png`;
const halftoneCamoImage = `${imagePath}/camo/Camo - Halftone.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "15px",
    right: "29px",
};

export default {
    restrictedPreviewImg: restrictedPreviewImage,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "DEMOLISHER",
            src: bodyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Mykell Pledger",
                url: "https://twitter.com/mykellpledger/status/1480266882682871808",
            },
        },
    ],
    weapon: [
        {
            value: 0,
            label: "HAMMER",
            frontSrc: weaponHammerImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "BLADE",
            frontSrc: weaponBladeImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    camo: [
        {
            value: 0,
            label: "ELITE",
            src: eliteCamoImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "CHITIN 1",
            src: chitinCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "CHITIN 2",
            src: chitinCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "STINGER 1",
            src: stingerCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "STINGER 2",
            src: stingerCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "UNDERBELLY",
            src: underbellyCamoImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "HALFTONE",
            src: halftoneCamoImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
