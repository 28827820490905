import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyMetalmarkImage = `${BaseURL}/Mechs/SSC/Metalmark-2/Metalmark Chassis.png`;

// HEADS
const headMetalmarkImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/Head - Metalmark.png`;
// PREMIUM
const headCalibanImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Cyclops.png`;
const headHornedImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Malice.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Rain.png`;
const headRainHairImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Rain Hair.png`;
const headScopehoundImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Scopedog.png`;
const headTokugawaImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - Tokugawa.png`;
const headWhiteWitchImage = `${BaseURL}/Mechs/SSC/Metalmark-2/heads/premium/Head - White Witch.png`;

// WEAPON INNER
const handsGunImage = `${BaseURL}/Mechs/SSC/Metalmark-2/Hands - Gun Stance.png`;

const weaponInnerAssaultRifleImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Assault Rifle.png`;
const weaponInnerRailRifleImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Rail Rifle.png`;
const weaponInnerDeckSweeperImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Deck Sweeper.png`;
const weaponInnerCannibalShotgunImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Cannibal Shotgun.png`;
const weaponInnerHMGImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - HMG.png`;

const handsBladeImage = `${BaseURL}/Mechs/SSC/Metalmark-2/Hands - Blade Stance.png`;

const weaponInnerMachinePistolsImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Machine Pistols.png`;
const weaponInnerKnives1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Knives 1.png`;
const weaponInnerKnives2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Knives 2.png`;
const weaponInnerMachetesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Machetes.png`;
const weaponInnerBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/inner/Inner Weapon - Blades.png`;


// WEAPON OUTER
const weaponOuterMachinePistolsImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Machinepistols.png`;
const weaponOuterKnives1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Knives 1.png`;
const weaponOuterKnives2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Knives 2.png`;
const weaponOuterMachetesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Machetes.png`;
const weaponOuterBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Blades.png`;
const weaponOuterHeavyChargedBlade1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Heavy Charged Blade 1.png`;
const weaponOuterHeavyChargedBlade2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Heavy Charged Blade 2 .png`;
const weaponOuterMantisBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Mantis Blades.png`;
const weaponOuterHeavyAxeImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Heavy Axe.png`;
const weaponOuterNanocarbonBladeImage = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - Nanocarbon Blade.png`;
const weaponOuterDD288Image = `${BaseURL}/Mechs/SSC/Metalmark-2/weapons/outer/Outer Weapon - DD288.png`;

// GLOW
const glowMetalmarkImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 1 - Metalmark Glow.png`;
const glowMetalmarkDecloakingImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 1 - Metalmark Decloaking.png`;
const glowMetalmarkHeadImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Metalmark Head.png`;
const glowCalibanHeadImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Caliban Head.png`;
const glowTokugawaHeadImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Tokugawa Head.png`;
const glowRailRifleImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Rail Rifle.png`;
const glowMantisBladesImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Mantis Blades.png`;
const glowShockKnivesInnerImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Shock Knives Inner.png`;
const glowShockKnivesOuterImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Shock Knives Outer.png`;
const glowShockKnivesAllImage = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - Shock Knives All.png`;
const glowHCB1Image = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - HCB 1.png`;
const glowHCB2Image = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - HCB 2.png`;
const glowDD288Image = `${BaseURL}/Mechs/SSC/Metalmark-2/glows/Glow 2 - DD288.png`;

const coreImage = `${BaseURL}/Mechs/SSC/Metalmark-2/Metalmark (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/SSC/Metalmark-2/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "33px",
    right: "16px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    removeNone: {
        weapon: true,
    },
    weaponSpecial: "chassisValue",
    defaultValues: `{
        "chassisValue": "6",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "6",
        "secondaryWeaponValue": "1",
        "accessory1Value": "2",
        "accessory2Value": "0",
        "accessory3Value": "11",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "METALMARK",
            src: headMetalmarkImage,
        },
        {
            value: 1,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "RAIN HAIR",
            src: headRainHairImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "TOKUGAWA",
            src: headTokugawaImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "WHITE WITCH",
            src: headWhiteWitchImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "METALMARK AKIMBO",
            src: bodyMetalmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "METALMARK AKIMBO",
            src: bodyMetalmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "METALMARK AKIMBO",
            src: bodyMetalmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "METALMARK AKIMBO",
            src: bodyMetalmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 4,
            label: "METALMARK AKIMBO",
            src: bodyMetalmarkImage,
            frontSrc: handsBladeImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 5,
            label: "METALMARK 2H",
            src: bodyMetalmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 6,
            label: "METALMARK 2H",
            src: bodyMetalmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 7,
            label: "METALMARK 2H",
            src: bodyMetalmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 8,
            label: "METALMARK 2H",
            src: bodyMetalmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 9,
            label: "METALMARK 2H",
            src: bodyMetalmarkImage,
            frontSrc: handsGunImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "MACHINE PISTOLS",
                src: weaponInnerMachinePistolsImage,
            },
            {
                value: 1,
                label: "KNIVES 1",
                src: weaponInnerKnives1Image,
            },
            {
                value: 2,
                label: "KNIVES 2",
                src: weaponInnerKnives2Image,
            },
            {
                value: 3,
                label: "MACHETES",
                src: weaponInnerMachetesImage,
            },
            {
                value: 4,
                label: "BLADES",
                src: weaponInnerBladesImage,
            },
            {
                value: 5,
                label: "ASSAULT RIFLE",
                src: weaponInnerAssaultRifleImage,
            },
            {
                value: 6,
                label: "RAIL RIFLE",
                src: weaponInnerRailRifleImage,
            },
            {
                value: 7,
                label: "DECKSWEEPER",
                src: weaponInnerDeckSweeperImage,
            },
            {
                value: 8,
                label: "CANNIBAL SHOTGUN",
                src: weaponInnerCannibalShotgunImage,
            },
            {
                value: 9,
                label: "HMG",
                src: weaponInnerHMGImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "MACHINE PISTOLS",
            src: weaponOuterMachinePistolsImage,
        },
        {
            value: 1,
            label: "KNIVES 1",
            src: weaponOuterKnives1Image,
        },
        {
            value: 2,
            label: "KNIVES 2",
            src: weaponOuterKnives2Image,
        },
        {
            value: 3,
            label: "MACHETES",
            src: weaponOuterMachetesImage,
        },
        {
            value: 4,
            label: "BLADES",
            src: weaponOuterBladesImage,
        },
        {
            value: 5,
            label: "HEAVY CHARGED BLADE 1",
            src: weaponOuterHeavyChargedBlade1Image,
        },
        {
            value: 6,
            label: "HEAVY CHARGED BLADE 2",
            src: weaponOuterHeavyChargedBlade2Image,
        },
        {
            value: 7,
            label: "MANTIS BLADES",
            src: weaponOuterMantisBladesImage,
        },
        {
            value: 8,
            label: "HEAVY AXE",
            src: weaponOuterHeavyAxeImage,
        },
        {
            value: 9,
            label: "NANOCARBON BLADE",
            src: weaponOuterNanocarbonBladeImage,
        },
        {
            value: 10,
            label: "DD288",
            src: weaponOuterDD288Image,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "METALMARK CHASSIS",
            src: glowMetalmarkImage,
        },
        {
            value: 1,
            label: "METALMARK DECLOAKING",
            src: glowMetalmarkDecloakingImage,
        },
        {
            value: 2,
            label: "METALMARK HEAD",
            frontSrc: glowMetalmarkHeadImage,
        },
        {
            value: 3,
            label: "CALIBAN HEAD",
            frontSrc: glowCalibanHeadImage,
        },
        {
            value: 4,
            label: "TOKUGAWA HEAD",
            frontSrc: glowTokugawaHeadImage,
        },
        {
            value: 5,
            label: "MANTIS BLADES",
            frontSrc: glowMantisBladesImage,
        },
        {
            value: 6,
            label: "SHOCK KNIVES INNER",
            frontSrc: glowShockKnivesInnerImage,
        },
        {
            value: 7,
            label: "SHOCK KNIVES OUTER",
            frontSrc: glowShockKnivesOuterImage,
        },
        {
            value: 8,
            label: "SHOCK KNIVES ALL",
            frontSrc: glowShockKnivesAllImage,
        },
        {
            value: 9,
            label: "HEAVY CHARGED BLADE 1",
            frontSrc: glowHCB1Image,
        },
        {
            value: 10,
            label: "HEAVY CHARGED BLADE 2",
            frontSrc: glowHCB2Image,
        },
        {
            value: 11,
            label: "RAIL RIFLE",
            frontSrc: glowRailRifleImage,
        },
        {
            value: 12,
            label: "DD288",
            frontSrc: glowDD288Image,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "INNER WEAPON",
            value: "weapon",
        },
        {
            label: "OUTER WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "INNER WEAPON",
        "secondaryWeapon": "OUTER WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
