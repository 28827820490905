import React, { useContext } from "react";
import { func, string } from "prop-types";
import styled from "styled-components";

import { AnalyticsContext } from "./../../Analytics/AnalyticsContextWrapper";
import { UserAuthContext } from "./../../User/UserAuthContextWrapper";

import MembershipTierImages from "./../../common/MembershipTierImages";

import BorderWindowContainerWrapper from "./../../common/styles/BorderWindowContainer";
import BorderWindowContent from "./../../common/styles/BorderWindowContent";
import BaseURL from "./../../Unit/data/images/Hosting-BaseURL";
import SpriteImage, { SPRITE_KEYS } from "../../common/styles/SpriteImage";

const becomeAPatronImage = `${BaseURL}/buttons/become-a-patron.png`;

const PremiumPopupWrapper = styled(BorderWindowContainerWrapper)`
    h2, h3, h4, p, a {
        color: #FFF;
        text-align: center;
    }

    h3, h4 {
        margin: 0;
    }

    h3, h4, a {
        margin-left: auto;
        margin-right: auto;
    }

    max-width: 650px;
    padding-bottom: 20px;

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) and (min-width: ${props => props.theme.mobileCutoffWidth + 1}px) {
        margin-top: 270px;
        max-width: 425px;
        margin-left: 30px;
    }
`;

PremiumPopupWrapper.displayName = "PremiumPopupWrapper";

const MenuContentHeader = styled.div`
    display: flex;
    align-content: center;
    justify-content: flex-end;
`;

MenuContentHeader.displayName = "MenuContentHeader";

const PopupContent = styled.div`
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    margin-left: auto;
    margin-right: auto;

    h3 {
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: center;

        margin-bottom: 20px;

        div, img {
            margin-right: 10px;
        }

        @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
            display: none;
        }
    }

    h4 {
        display: none;
        align-content: center;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        max-width: 100%;

        :nth-of-type(2) {
            margin-bottom: 10px;
        }
        :nth-of-type(3), :nth-of-type(4) {
            margin-bottom: 20px;
        }

        @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
            display: flex;
        }
    }

    a img {
        width: 50%;
        border-width: 4px;
        border-style: outset;
        border-color: #E3523E;

        :active {
            border-style: inset;
        }


        @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
            width: 70vw;
        }
    }
`;

PopupContent.displayName = "PopupContent";

const OrbImage = styled.img`
    flex-shrink: 0;
    align-self: flex-start;
    width: 16px;
`;

OrbImage.displayName = "OrbImage";

const CloseButton = styled(SpriteImage)`
    margin-right: 0;
    cursor: pointer;

    :active {
        transform: translateY(2px);
    }
`;

CloseButton.displayName = "CloseButton";

export const premiumTiersList = {
    LEGENDARY: "legendary",
    PLUS_ONE: "plusOne",
    PLUS_TWO: "plusTwo",
    MUNDANE: "mundane",
};

function PremiumPopup({ closePremiumPopupFunc, version }) {
    const ReactGA = useContext(AnalyticsContext);
    const { user } = useContext(UserAuthContext);

    const isPremiumUser = user && user.isPremiumUser;
    const isLegendaryUser = user && user.isLegendaryUser;

    let displayButton = true;
    let isRequired = true;

    let mainText = "Variants and PNG downloads are available for +1 Members.";

    if (isPremiumUser) {
        mainText = "Thank you for your support! +1 Features are marked on the dropdowns with ●";
        displayButton = false;
    }

    if (version === premiumTiersList.LEGENDARY) {
        mainText = "Variants are reserved exclusively for Legendary Members, which includes full access to the entire Retrograde catalog.";

        if (isLegendaryUser) {
            mainText = "Thank you for your support! Legendary Features are marked on the dropdowns with ●●";
            displayButton = false;
        }
    }

    if (version === premiumTiersList.PLUS_TWO) {
        mainText = "+2 Features are marked on the dropdowns with *. +2 Membership is identical to +1 Membership, but the extra $1 will be divided amongst our contributors. Their assets will always be free for everyone on the site, so if you'd like to kick them a little tip, this is a nice way to do so.";
        isRequired = false;
        displayButton = true;
    }

    ReactGA.modalview(`${version} Premium Popup`);

    return (
        <PremiumPopupWrapper>
            <MenuContentHeader>
                <CloseButton
                    className={SPRITE_KEYS.xButton}
                    alt="Close"
                    onClick={closePremiumPopupFunc}
                />
            </MenuContentHeader>
            <BorderWindowContent>
                <PopupContent>
                        <h3>
                            <img src={MembershipTierImages[`${version}Tier`].emblem} alt={MembershipTierImages[`${version}Tier`].alt} />
                            <SpriteImage className={MembershipTierImages[`${version}Tier`].textSprite} alt={MembershipTierImages[`${version}Tier`].alt} />
                            {isRequired && " Required!"}
                        </h3>

                    <h4>
                        <img src={MembershipTierImages[`${version}Tier`].emblem} alt={MembershipTierImages[`${version}Tier`].alt} />
                    </h4>
                    <h4>
                        <SpriteImage className={MembershipTierImages[`${version}Tier`].textSprite} alt={MembershipTierImages[`${version}Tier`].alt} />
                    </h4>
                    <h4>
                        {isRequired && " Required!"}
                    </h4>

                    <h3>
                        <OrbImage src={MembershipTierImages[`${version === premiumTiersList.LEGENDARY ? "themepack" : version}Tier`].orb} alt={MembershipTierImages[`${version}Tier`].alt} />
                        {mainText}
                    </h3>

                    <h4>
                        <OrbImage src={MembershipTierImages[`${version === premiumTiersList.LEGENDARY ? "themepack" : version}Tier`].orb} alt={MembershipTierImages[`${version}Tier`].alt} />
                        {mainText}
                    </h4>

                    {displayButton && (
                        <a
                            href="https://www.patreon.com/RetrogradeMinis"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => ReactGA.event({
                                category: "Patreon Link",
                                action: "Click Become a Patron Link",
                                label: `${version} Premium Popup`,
                            })}
                        >
                            <img src={becomeAPatronImage} alt="Become a Patron" />
                        </a>
                    )}
                </PopupContent>
            </BorderWindowContent>
        </PremiumPopupWrapper>
    );
}

PremiumPopup.propTypes = {
    closePremiumPopupFunc: func.isRequired,
    version: string.isRequired,
};

export default PremiumPopup;
