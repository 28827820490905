import React, { createContext, useContext, useState } from "react";
import styled from "styled-components";

import { ShowLandingContext } from "./../Landing/ShowLandingContextWrapper";
import { ContainerExpandButton } from "./../Unit/Category/UnitCategory";
import { CurrentThemeContext } from "./../common/ThemeContextWrapper";
import MusicController from "./../Music/MusicController";
import SFXController from "./../Music/SFXController";
import BackgroundController from "./BackgroundController";
import { UITextButton } from "./styles/UITextButton";
import { SPRITE_KEYS } from "./styles/SpriteImage";

const WebsiteUIControlContainer = styled.div`
    position: fixed;
    z-index: 1;
    bottom: 20px;
    right: ${props => props.isMinimized ? "-260px" : "0px"};

    border-width: 4px 0 4px 4px;

    color: #FFF;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-content: space-around;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0px;
    width: 280px;
    height: 150px;

    transition-delay: ${props => props.theme.landingTransitionDuration}s;
    transition-duration: 0.75s;
    transition-property: margin-top, width, height;
    transition-timing-function: linear;

    h3 {
        margin-bottom: 3px;
        margin-top: 3px;
    }

    &.fantasy {
        background: linear-gradient(to bottom right, #666 0%, #333 100%);
        border-color: #5A5A5A;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        border-style: outset;
        box-shadow: 0 0 2px 1px #000;
    }

    &.mech {
        background: #666;
        border-color: #666;
        border-style: solid;

        //Clip Top Left Corner
        clip-path: polygon(20px 0%,100% 0%,100% 100%,0% 100%,0% 20px);
    }

    @media print {
        display: none;
    }
`;

WebsiteUIControlContainer.displayName = "WebsiteUIControlContainer";

const ToggleMinButton = styled(ContainerExpandButton)`
    z-index: 15;
    width: 14px;
    height: 20px;

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        display: initial;
    }
`;

ToggleMinButton.displayName = "ToggleMinButton";

const ControlsContainer = styled.div`
    display: ${props => props.isMinimized ? "none" : "flex"};
    flex-direction: column;
    justify-content: space-between;
    align-content: flex-start;
    height: 100%;
`;

ControlsContainer.displayName = "ControlsContainer";

export const HalfWrapper = styled.span`
    width: 50%;
`;

HalfWrapper.displayName = "HalfWrapper";

export const WholeWrapper = styled.span`
    width: 100%;
`;

WholeWrapper.displayName = "WholeWrapper";

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
`;

RowWrapper.displayName = "RowWrapper";

export const HideUIContext = createContext(null);

function ToggleHideUIContextWrapper({ ...props }) {
    const [hideUI, setHideUI] = useState(false);
    const [isMinimized, setIsMinimized] = useState(false);
    const { showLanding, startWithLanding, toggleStartWithLanding } = useContext(ShowLandingContext);
    const { currentSide, displayTheme, toggleDisplayTheme } = useContext(CurrentThemeContext);

    function toggleHideUI() {
        return setHideUI(!hideUI);
    }

    return (
        <HideUIContext.Provider value={hideUI}>
            {props.children}
            <WebsiteUIControlContainer
                className={currentSide}
                showLanding={showLanding}
                isMinimized={isMinimized}
            >
                <ToggleMinButton
                    onClick={() => setIsMinimized(!isMinimized)}
                    isExpanded={true}
                    className={isMinimized ? SPRITE_KEYS.lArrow : SPRITE_KEYS.rArrow}
                    alt={isMinimized ? "Expand Controls" : "Minimize Controls"}
                    title={isMinimized ? "Expand Controls" : "Minimize Controls"}
                />

                <ControlsContainer isMinimized={isMinimized}>
                    <UITextButton
                        className={currentSide}
                        title="Toggle Hide/Show UI"
                        onClick={toggleHideUI}
                    >
                        HIDE UI
                    </UITextButton>

                    <UITextButton
                        className={currentSide}
                        onClick={toggleStartWithLanding}
                        title="Skip the 'Click to Start' screen"
                    >
                        SKIP START VIEW: {startWithLanding ? "OFF" : "ON"}
                    </UITextButton>

                    <UITextButton
                        className={currentSide}
                        onClick={toggleDisplayTheme}
                        title="Change the theme of the customizable units options screens"
                    >
                        TERMINAL STYLE: {!displayTheme ? "OFF" : "ON"}
                    </UITextButton>

                    <BackgroundController className={currentSide} />

                    <SFXController className={currentSide} />

                    <MusicController className={currentSide} />
                </ControlsContainer>
            </WebsiteUIControlContainer>
        </HideUIContext.Provider>
    );
}

export default ToggleHideUIContextWrapper;
