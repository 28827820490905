/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useState } from "react";

import { SelectDropdown as MechSelectDropdown } from "./MechCategoryStyles";
import { SelectDropdown as FantasySelectDropdown } from "../../Heroes/components/FantasyHeroCategoryStyles";
import ExportWrapper from "../../components/ExportWrapper";

import { AnalyticsContext } from "./../../../../Analytics/AnalyticsContextWrapper";

const anchorRef = createRef();

function ExportNPCCamoSettingsButton({ displayTheme, camoSettings, npcCategory, isFantasyTheme }) {
    const ReactGA = useContext(AnalyticsContext);

    const [isLoading, setIsLoading] = useState(false);

    const loadingText = isFantasyTheme ? "Loading" : "LOADING";
    const buttonText = isFantasyTheme ? "Export Settings File" : "EXPORT CAMO SETTINGS FILE";

    const SelectDropdown = isFantasyTheme ? FantasySelectDropdown : MechSelectDropdown;

    async function exportNPCCamoSettings(data) {
        const json = JSON.stringify(data);

        const blob = new Blob([json],{type:'application/json'});

        anchorRef.current.download = `Retrograde-Minis-${npcCategory}-NPC-Camo-Settings.json`;
        anchorRef.current.href = await URL.createObjectURL(blob);
        anchorRef.current.click();

        ReactGA.event({
            category: "Print",
            action: "NPC Camo Export",
            label: npcCategory,
        });

        setIsLoading(false);
    }

    return (
        <ExportWrapper>
            <SelectDropdown
                className={displayTheme}
                onClick={isLoading ? () => {} : () => {
                    return exportNPCCamoSettings(camoSettings);
                }}
            >
                <label className={"clickable"}>
                    {isLoading ? loadingText : buttonText}
                </label>
            </SelectDropdown>
            <a ref={anchorRef} target="_blank" download />
        </ExportWrapper>
    );
}

export default ExportNPCCamoSettingsButton;
