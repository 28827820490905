import React, { createContext, useState } from "react";

import ColorList from "./Category/components/ColorList";
import { getRandomIntInclusive } from "./Category/Mechs/MechCustomCategory";
import { convertHexToFilter } from "./Category/components/Converter/converter";

export const CustomColorContext = createContext(null);

const customColorIndex = ColorList.length - 2;

function CustomColorContextWrapper({ ...props }) {
    const savedCustom1SepiaCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom1Sepia=`));
    const savedCustom1SaturateCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom1Saturate=`));
    const savedCustom1BrightnessCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom1Brightness=`));
    const savedCustom1HueCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom1Hue=`));
    const savedCustom1InvertCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom1Invert=`));
    const savedCustom1ContrastCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom1Contrast=`));

    const savedCustom2SepiaCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom2Sepia=`));
    const savedCustom2SaturateCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom2Saturate=`));
    const savedCustom2BrightnessCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom2Brightness=`));
    const savedCustom2HueCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom2Hue=`));
    const savedCustom2InvertCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom2Invert=`));
    const savedCustom2ContrastCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom2Contrast=`));

    const savedCustom3SepiaCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom3Sepia=`));
    const savedCustom3SaturateCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom3Saturate=`));
    const savedCustom3BrightnessCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom3Brightness=`));
    const savedCustom3HueCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom3Hue=`));
    const savedCustom3InvertCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom3Invert=`));
    const savedCustom3ContrastCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom3Contrast=`));

    const savedCustom4SepiaCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom4Sepia=`));
    const savedCustom4SaturateCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom4Saturate=`));
    const savedCustom4BrightnessCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom4Brightness=`));
    const savedCustom4HueCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom4Hue=`));
    const savedCustom4InvertCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom4Invert=`));
    const savedCustom4ContrastCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`savedCustom4Contrast=`));

    const [savedSepia1Value, setSavedSepia1Value] = useState(savedCustom1SepiaCookie.length ? (savedCustom1SepiaCookie[0].split("=")[1]) : 100);
    const [savedSaturation1Value, setSavedSaturation1Value] = useState(savedCustom1SaturateCookie.length ? (savedCustom1SaturateCookie[0].split("=")[1]) : 300);
    const [savedBrightness1Value, setSavedBrightness1Value] = useState(savedCustom1BrightnessCookie.length ? (savedCustom1BrightnessCookie[0].split("=")[1]) : 70);
    const [savedHue1Value, setSavedHue1Value] = useState(savedCustom1HueCookie.length ? (savedCustom1HueCookie[0].split("=")[1]) : 300);
    const [savedInvert1Value, setSavedInvert1Value] = useState(savedCustom1InvertCookie.length ? (savedCustom1InvertCookie[0].split("=")[1]) : 0);
    const [savedContrast1Value, setSavedContrast1Value] = useState(savedCustom1ContrastCookie.length ? (savedCustom1ContrastCookie[0].split("=")[1]) : 100);

    const [savedSepia2Value, setSavedSepia2Value] = useState(savedCustom2SepiaCookie.length ? (savedCustom2SepiaCookie[0].split("=")[1]) : 100);
    const [savedSaturation2Value, setSavedSaturation2Value] = useState(savedCustom2SaturateCookie.length ? (savedCustom2SaturateCookie[0].split("=")[1]) : 300);
    const [savedBrightness2Value, setSavedBrightness2Value] = useState(savedCustom2BrightnessCookie.length ? (savedCustom2BrightnessCookie[0].split("=")[1]) : 70);
    const [savedHue2Value, setSavedHue2Value] = useState(savedCustom2HueCookie.length ? (savedCustom2HueCookie[0].split("=")[1]) : 300);
    const [savedInvert2Value, setSavedInvert2Value] = useState(savedCustom2InvertCookie.length ? (savedCustom2InvertCookie[0].split("=")[1]) : 0);
    const [savedContrast2Value, setSavedContrast2Value] = useState(savedCustom2ContrastCookie.length ? (savedCustom2ContrastCookie[0].split("=")[1]) : 100);

    const [savedSepia3Value, setSavedSepia3Value] = useState(savedCustom3SepiaCookie.length ? (savedCustom3SepiaCookie[0].split("=")[1]) : 100);
    const [savedSaturation3Value, setSavedSaturation3Value] = useState(savedCustom3SaturateCookie.length ? (savedCustom3SaturateCookie[0].split("=")[1]) : 300);
    const [savedBrightness3Value, setSavedBrightness3Value] = useState(savedCustom3BrightnessCookie.length ? (savedCustom3BrightnessCookie[0].split("=")[1]) : 70);
    const [savedHue3Value, setSavedHue3Value] = useState(savedCustom3HueCookie.length ? (savedCustom3HueCookie[0].split("=")[1]) : 300);
    const [savedInvert3Value, setSavedInvert3Value] = useState(savedCustom3InvertCookie.length ? (savedCustom3InvertCookie[0].split("=")[1]) : 0);
    const [savedContrast3Value, setSavedContrast3Value] = useState(savedCustom3ContrastCookie.length ? (savedCustom3ContrastCookie[0].split("=")[1]) : 100);

    const [savedSepia4Value, setSavedSepia4Value] = useState(savedCustom4SepiaCookie.length ? (savedCustom4SepiaCookie[0].split("=")[1]) : 100);
    const [savedSaturation4Value, setSavedSaturation4Value] = useState(savedCustom4SaturateCookie.length ? (savedCustom4SaturateCookie[0].split("=")[1]) : 300);
    const [savedBrightness4Value, setSavedBrightness4Value] = useState(savedCustom4BrightnessCookie.length ? (savedCustom4BrightnessCookie[0].split("=")[1]) : 70);
    const [savedHue4Value, setSavedHue4Value] = useState(savedCustom4HueCookie.length ? (savedCustom4HueCookie[0].split("=")[1]) : 300);
    const [savedInvert4Value, setSavedInvert4Value] = useState(savedCustom4InvertCookie.length ? (savedCustom4InvertCookie[0].split("=")[1]) : 0);
    const [savedContrast4Value, setSavedContrast4Value] = useState(savedCustom4ContrastCookie.length ? (savedCustom4ContrastCookie[0].split("=")[1]) : 100);

    const [colorValue, setColorValue] = useState(customColorIndex);
    const [sepiaValue, setSepiaValue] = useState(100);
    const [saturationValue, setSaturationValue] = useState(300);
    const [brightnessValue, setBrightnessValue] = useState(70);
    const [hueValue, setHueValue] = useState(300);
    const [invertValue, setInvertValue] = useState(0);
    const [contrastValue, setContrastValue] = useState(100);

    const [hexInputValue, setHexInputValue] = useState();
    const [isHexInputValid, setIsHexInputValid] = useState(false);
    const [shouldConvertGlowValue, setShouldConvertGlowValue] = useState(false);

    const selectorMin = 0;
    const sepiaMax = 100;
    const invertMax = 100;
    const saturationMax = 500;
    const brightnessMax = 200;
    const contrastMax = 200;
    const hueMin = 1;
    const hueMax = 360;

    const setSavedValuesMap = {
        1: {
            setSavedSepiaValue: setSavedSepia1Value,
            setSavedSaturationValue: setSavedSaturation1Value,
            setSavedBrightnessValue: setSavedBrightness1Value,
            setSavedHueValue: setSavedHue1Value,
            setSavedInvertValue: setSavedInvert1Value,
            setSavedContrastValue: setSavedContrast1Value,

            savedSepiaValue: savedSepia1Value,
            savedSaturationValue: savedSaturation1Value,
            savedBrightnessValue: savedBrightness1Value,
            savedHueValue: savedHue1Value,
            savedInvertValue: savedInvert1Value,
            savedContrastValue: savedContrast1Value,
        },
        2: {
            setSavedSepiaValue: setSavedSepia2Value,
            setSavedSaturationValue: setSavedSaturation2Value,
            setSavedBrightnessValue: setSavedBrightness2Value,
            setSavedHueValue: setSavedHue2Value,
            setSavedInvertValue: setSavedInvert2Value,
            setSavedContrastValue: setSavedContrast2Value,

            savedSepiaValue: savedSepia2Value,
            savedSaturationValue: savedSaturation2Value,
            savedBrightnessValue: savedBrightness2Value,
            savedHueValue: savedHue2Value,
            savedInvertValue: savedInvert2Value,
            savedContrastValue: savedContrast2Value,

        },
        3: {
            setSavedSepiaValue: setSavedSepia3Value,
            setSavedSaturationValue: setSavedSaturation3Value,
            setSavedBrightnessValue: setSavedBrightness3Value,
            setSavedHueValue: setSavedHue3Value,
            setSavedInvertValue: setSavedInvert3Value,
            setSavedContrastValue: setSavedContrast3Value,

            savedSepiaValue: savedSepia3Value,
            savedSaturationValue: savedSaturation3Value,
            savedBrightnessValue: savedBrightness3Value,
            savedHueValue: savedHue3Value,
            savedInvertValue: savedInvert3Value,
            savedContrastValue: savedContrast3Value,
        },
        4: {
            setSavedSepiaValue: setSavedSepia4Value,
            setSavedSaturationValue: setSavedSaturation4Value,
            setSavedBrightnessValue: setSavedBrightness4Value,
            setSavedHueValue: setSavedHue4Value,
            setSavedInvertValue: setSavedInvert4Value,
            setSavedContrastValue: setSavedContrast4Value,

            savedSepiaValue: savedSepia4Value,
            savedSaturationValue: savedSaturation4Value,
            savedBrightnessValue: savedBrightness4Value,
            savedHueValue: savedHue4Value,
            savedInvertValue: savedInvert4Value,
            savedContrastValue: savedContrast4Value,
        },
    };

    const getFilterFromHex = () => {
        const filter = convertHexToFilter(hexInputValue, shouldConvertGlowValue);

        setSepiaValue(filter.sepia);
        setSaturationValue(filter.saturate);
        setBrightnessValue(filter.brightness);
        setHueValue(filter.hueRotate);
        setInvertValue(filter.invert);
        setContrastValue(filter.contrast);
    }

    const saveCustomColorValues = (index) => {
        document.cookie = `savedCustom${index}Sepia=${sepiaValue}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        document.cookie = `savedCustom${index}Saturate=${saturationValue}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        document.cookie = `savedCustom${index}Brightness=${brightnessValue}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        document.cookie = `savedCustom${index}Hue=${hueValue}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        document.cookie = `savedCustom${index}Invert=${invertValue}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        document.cookie = `savedCustom${index}Contrast=${contrastValue}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;

        setSavedValuesMap[index].setSavedSepiaValue(sepiaValue);
        setSavedValuesMap[index].setSavedSaturationValue(saturationValue);
        setSavedValuesMap[index].setSavedBrightnessValue(brightnessValue);
        setSavedValuesMap[index].setSavedHueValue(hueValue);
        setSavedValuesMap[index].setSavedInvertValue(invertValue);
        setSavedValuesMap[index].setSavedContrastValue(contrastValue);
    };

    const handleColorOptionChange = (event) => {
        if (ColorList[event.target.value].hueRotate) {
            setSepiaValue(ColorList[event.target.value].sepia);
            setSaturationValue(ColorList[event.target.value].saturate);
            setBrightnessValue(ColorList[event.target.value].brightness);
            setHueValue(ColorList[event.target.value].hueRotate);
            setInvertValue(ColorList[event.target.value].invert);
            setContrastValue(ColorList[event.target.value].contrast);
        }

        if (ColorList[event.target.value].value.includes("SAVED CUSTOM")) {
            const index = ColorList[event.target.value].value.split("-")[1];
            setSepiaValue(setSavedValuesMap[index].savedSepiaValue);
            setSaturationValue(setSavedValuesMap[index].savedSaturationValue);
            setBrightnessValue(setSavedValuesMap[index].savedBrightnessValue);
            setHueValue(setSavedValuesMap[index].savedHueValue);
            setInvertValue(setSavedValuesMap[index].savedInvertValue);
            setContrastValue(setSavedValuesMap[index].savedContrastValue);
        }

        setColorValue(event.target.value);
    };

    const getColorTint = () => {
        let color = ColorList[colorValue].value;
        let savedIndex;

        if (color.includes("SAVED CUSTOM")) {
            savedIndex = color.split("-")[1];

            color = `sepia(${setSavedValuesMap[savedIndex].savedSepiaValue}%) saturate(${setSavedValuesMap[savedIndex].savedSaturationValue}%) brightness(${setSavedValuesMap[savedIndex].savedBrightnessValue}%) hue-rotate(${setSavedValuesMap[savedIndex].savedHueValue}deg) invert(${setSavedValuesMap[savedIndex].savedInvertValue}%) contrast(${setSavedValuesMap[savedIndex].savedContrastValue}%)`;
        }

        if (color === "CUSTOM") {
            color = `sepia(${sepiaValue}%) saturate(${saturationValue}%) brightness(${brightnessValue}%) hue-rotate(${hueValue}deg) invert(${invertValue}%) contrast(${contrastValue}%)`;
        }

        if (color === "RANDOM") {
            const randomSepia = getRandomIntInclusive(25, sepiaMax);
            const randomSaturation = getRandomIntInclusive(1, saturationMax);
            const randomBrightness = getRandomIntInclusive(60, 100);
            const randomHue = getRandomIntInclusive(hueMin, hueMax);
            const randomInvert = 0; // getRandomIntInclusive(1, invertMax);
            const randomContrast = 100; // getRandomIntInclusive(75, contrastMax);

            color = `sepia(${randomSepia}%) saturate(${randomSaturation}%) brightness(${randomBrightness}%) hue-rotate(${randomHue}deg) invert(${randomInvert}%) contrast(${randomContrast}%)`;

            setSepiaValue(randomSepia);
            setSaturationValue(randomSaturation);
            setBrightnessValue(randomBrightness);
            setHueValue(randomHue);
            setInvertValue(randomInvert);
            setContrastValue(randomContrast);
        }

        return color;
    };

    const getTintFromColor = (color) => {
        let returnTintValue;

        switch(color.value) {
            case "CUSTOM":
                returnTintValue =  `sepia(${color.sepia}%) saturate(${color.saturate}%) brightness(${color.brightness}%) hue-rotate(${color.hueRotate}deg) invert(${color.invert}%) contrast(${color.contrast}%)`;
                break;

            case "SAVED CUSTOM-1":
                returnTintValue = savedTint1Value;
                break;
            case "SAVED CUSTOM-2":
                returnTintValue = savedTint2Value;
                break;
            case "SAVED CUSTOM-3":
                returnTintValue = savedTint3Value;
                break;
            case "SAVED CUSTOM-4":
                returnTintValue = savedTint4Value;
                break;

            default:
                returnTintValue = color.value;
                break;
        }

        return returnTintValue;
    };

    const displayCustomControls = ColorList[colorValue].value.includes("SAVED CUSTOM") || ColorList[colorValue].value === "CUSTOM" || ColorList[colorValue].value === "RANDOM";

    let tintValue;

    switch(ColorList[colorValue].value) {
        case "CUSTOM":
        case "RANDOM":
            tintValue = `sepia(${sepiaValue}%) saturate(${saturationValue}%) brightness(${brightnessValue}%) hue-rotate(${hueValue}deg) invert(${invertValue}%) contrast(${contrastValue}%)`;
            break;

        case "SAVED CUSTOM-1":
            tintValue = `sepia(${savedSepia1Value}%) saturate(${savedSaturation1Value}%) brightness(${savedBrightness1Value}%) hue-rotate(${savedHue1Value}deg) invert(${savedInvert1Value}%) contrast(${savedContrast1Value}%)`;
            break;
        case "SAVED CUSTOM-2":
            tintValue = `sepia(${savedSepia2Value}%) saturate(${savedSaturation2Value}%) brightness(${savedBrightness2Value}%) hue-rotate(${savedHue2Value}deg) invert(${savedInvert2Value}%) contrast(${savedContrast2Value}%)`;
            break;
        case "SAVED CUSTOM-3":
            tintValue = `sepia(${savedSepia3Value}%) saturate(${savedSaturation3Value}%) brightness(${savedBrightness3Value}%) hue-rotate(${savedHue3Value}deg) invert(${savedInvert3Value}%) contrast(${savedContrast3Value}%)`;
            break;
        case "SAVED CUSTOM-4":
            tintValue = `sepia(${savedSepia4Value}%) saturate(${savedSaturation4Value}%) brightness(${savedBrightness4Value}%) hue-rotate(${savedHue4Value}deg) invert(${savedInvert4Value}%) contrast(${savedContrast4Value}%)`;
            break;

        default:
            tintValue = ColorList[colorValue].value;
            break;
    }

    const savedTint1Value = `sepia(${savedSepia1Value}%) saturate(${savedSaturation1Value}%) brightness(${savedBrightness1Value}%) hue-rotate(${savedHue1Value}deg) invert(${savedInvert1Value}%) contrast(${savedContrast1Value}%)`;
    const savedTint2Value = `sepia(${savedSepia2Value}%) saturate(${savedSaturation2Value}%) brightness(${savedBrightness2Value}%) hue-rotate(${savedHue2Value}deg) invert(${savedInvert2Value}%) contrast(${savedContrast2Value}%)`;
    const savedTint3Value = `sepia(${savedSepia3Value}%) saturate(${savedSaturation3Value}%) brightness(${savedBrightness3Value}%) hue-rotate(${savedHue3Value}deg) invert(${savedInvert3Value}%) contrast(${savedContrast3Value}%)`;
    const savedTint4Value = `sepia(${savedSepia4Value}%) saturate(${savedSaturation4Value}%) brightness(${savedBrightness4Value}%) hue-rotate(${savedHue4Value}deg) invert(${savedInvert4Value}%) contrast(${savedContrast4Value}%)`;

    return (
        <CustomColorContext.Provider value={{
            customColorIndex,

            selectorMin,
            sepiaMax,
            saturationMax,
            brightnessMax,
            hueMin,
            hueMax,
            invertMax,
            contrastMax,

            colorValue,
            setColorValue,

            sepiaValue,
            setSepiaValue,

            saturationValue,
            setSaturationValue,

            brightnessValue,
            setBrightnessValue,

            hueValue,
            setHueValue,

            invertValue,
            setInvertValue,

            contrastValue,
            setContrastValue,

            saveCustomColorValues,
            displayCustomControls,
            tintValue,

            savedTint1Value,
            savedTint2Value,
            savedTint3Value,
            savedTint4Value,

            handleColorOptionChange,
            getColorTint,
            getTintFromColor,

            hexInputValue,
            setHexInputValue,
            isHexInputValid,
            setIsHexInputValid,
            shouldConvertGlowValue,
            setShouldConvertGlowValue,
            getFilterFromHex,
        }}>
            {props.children}
        </CustomColorContext.Provider>
    );
}

export default CustomColorContextWrapper;
