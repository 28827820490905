import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/SSC/Mourning Cloak`;

// BODY
const bodyMourningCloakImage = `${imagePath}/Chassis.png`;

// HEADS
const headMourningCloakImage = `${imagePath}/heads/Head - Mourning Cloak.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_morning cloak.png`;
const headViolenceImage = `${imagePath}/heads/Head - Violence.png`;
// PREMIUM
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${imagePath}/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/premium/Head - Cyclops.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head - Laborer.png`;
const headLOrealImage = `${imagePath}/heads/premium/Head - L_Oreal.png`;
const headMagImage = `${imagePath}/heads/premium/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head - Malice.png`;
const headMetalmarkImage = `${imagePath}/heads/premium/Head - Metalmark.png`;
const headMonarchImage = `${imagePath}/heads/premium/Head - Monarch.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headRainTransformedHairImage = `${imagePath}/heads/premium/Head - Rain Transformed Hair.png`;
const headRainTransformedImage = `${imagePath}/heads/premium/Head - Rain Transformed.png`;
const headWitchImage = `${imagePath}/heads/premium/Head - Witch.png`;
const headCockpitImage = `${imagePath}/heads/premium/Head - Cockpit.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;

// MAIN WEAPON
const weaponHaladieImage = `${imagePath}/weapons/left/Weapon - Left - Haladie.png`;
const weaponKatanaImage = `${imagePath}/weapons/left/Weapon - Left - Katana.png`;
const weaponKatarImage = `${imagePath}/weapons/left/Weapon - Left - Katar.png`;
const weaponKnifeReverseGripImage = `${imagePath}/weapons/left/Weapon - Left - Knife - Reverse Grip.png`;
const weaponKnifeImage = `${imagePath}/weapons/left/Weapon - Left - Knife.png`;
const weaponVariableBladeHandleImage = `${imagePath}/weapons/left/Weapon - Left - Variable Blade Handle.png`;

// SECONDARY WEAPON
const secondaryWeaponAxeImage = `${imagePath}/weapons/right/Weapon - Right - Axe.png`;
const secondaryWeaponChainAxeImage = `${imagePath}/weapons/right/Weapon - Right - Chain Axe.png`;
const secondaryWeaponGreataxeImage = `${imagePath}/weapons/right/Weapon - Right - Greataxe.png`;
const secondaryWeaponHaladieImage = `${imagePath}/weapons/right/Weapon - Right - Haladie.png`;
const secondaryWeaponHCBImage = `${imagePath}/weapons/right/Weapon - Right - HCB.png`;
const secondaryWeaponKatarImage = `${imagePath}/weapons/right/Weapon - Right - Katar.png`;
const secondaryWeaponKnifeReverseGripImage = `${imagePath}/weapons/right/Weapon - Right - Knife Reverse Grip.png`;
const secondaryWeaponKnifeImage = `${imagePath}/weapons/right/Weapon - Right - Knife.png`;
const secondaryWeaponRevolverImage = `${imagePath}/weapons/right/Weapon - Right - Revolver.png`;
const secondaryWeaponVijayaRocketsImage = `${imagePath}/weapons/right/Weapon - Right - Vijaya Rockets.png`;

// ACCESSORY
const rearMountCloakImage = `${imagePath}/accessories/Accessory - Cloak.png`;
const rearMountFlyKicksImage = `${imagePath}/accessories/premium/Accessory - Fly Kicks.png`;
const rearMountFlyKicksCloakImage = `${imagePath}/accessories/premium/Accessory - Fly Kicks Cloak.png`;

// GLOW
const glowChainAxeImage = `${imagePath}/glow/Glow - Chain Axe.png`;
const glowKatanaAndKnifeImage = `${imagePath}/glow/Glow - Katana and Knife.png`;
const glowLooseVariableBladeAndKnifeImage = `${imagePath}/glow/Glow - Loose Variable Blade and Knife.png`;
const glowRigidVariableBladeAndKnifeImage = `${imagePath}/glow/Glow - Rigid Variable Blade and Knife.png`;
const glowKatanaImage = `${imagePath}/glow/Glow - Katana.png`;
const glowLeftKnifeImage = `${imagePath}/glow/Glow - Left Knife Reverse.png`;
const glowLooseVariableBladeImage = `${imagePath}/glow/Glow - Variable Blade - Loose.png`;
const glowRigidVariableBladeImage = `${imagePath}/glow/Glow - Variable Blade - Rigid.png`;

const coreImage = `${imagePath}/Mourning Cloak (by Dr. Ta13oo).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "41px",
    right: "2px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Dr. Ta13oo",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    removeNone: {
        weapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "5",
        "secondaryWeaponValue": "1",
        "accessory1Value": "6",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "MOURNING CLOAK",
            foremostSrc: headMourningCloakImage,
        },
        {
            value: 1,
            label: "L'OREAL",
            foremostSrc: headLOrealImage,
        },
        {
            value: 2,
            label: "BONEHEAD",
            foremostSrc: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "VIOLENCE",
            foremostSrc: headViolenceImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Maboi000",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 4,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "LABORER",
            foremostSrc: headLaborerImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "MAG",
            foremostSrc: headMagImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "MALICE",
            foremostSrc: headMaliceImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "METALMARK",
            foremostSrc: headMetalmarkImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "MONARCH",
            foremostSrc: headMonarchImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "WITCH",
            foremostSrc: headWitchImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "COCKPIT",
            foremostSrc: headCockpitImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "SCOPEHOUND",
            foremostSrc: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "MOURNING CLOAK",
            src: bodyMourningCloakImage,
            shadowDetails: bipedLegsShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "KNIFE",
                src: weaponKnifeImage,
            },
            {
                value: 1,
                label: "KNIFE REVERSE GRIP",
                src: weaponKnifeReverseGripImage,
            },
            {
                value: 2,
                label: "KATANA",
                src: weaponKatanaImage,
            },
            {
                value: 3,
                label: "KATAR",
                src: weaponKatarImage,
            },
            {
                value: 4,
                label: "HALADIE",
                src: weaponHaladieImage,
            },
            {
                value: 5,
                label: "VARIABLE BLADE HANDLE",
                src: weaponVariableBladeHandleImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "KNIFE",
            src: secondaryWeaponKnifeImage,
        },
        {
            value: 1,
            label: "KNIFE REVERSE GRIP",
            src: secondaryWeaponKnifeReverseGripImage,
        },
        {
            value: 2,
            label: "AXE",
            src: secondaryWeaponAxeImage,
        },
        {
            value: 3,
            label: "CHAIN AXE",
            src: secondaryWeaponChainAxeImage,
        },
        {
            value: 4,
            label: "GREATAXE",
            src: secondaryWeaponGreataxeImage,
        },
        {
            value: 5,
            label: "KATAR",
            src: secondaryWeaponKatarImage,
        },
        {
            value: 6,
            label: "HALADIE",
            src: secondaryWeaponHaladieImage,
        },
        {
            value: 7,
            label: "HCB",
            src: secondaryWeaponHCBImage,
        },
        {
            value: 8,
            label: "REVOLVER",
            src: secondaryWeaponRevolverImage,
        },
        {
            value: 9,
            label: "VIJAYA ROCKETS",
            foremostSrc: secondaryWeaponVijayaRocketsImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "CLOAK",
            frontSrc: rearMountCloakImage,
        },
        {
            value: 1,
            label: "FLY KICKS",
            frontSrc: rearMountFlyKicksImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "FLY KICKS + CLOAK",
            frontSrc: rearMountFlyKicksCloakImage,
            isPremium: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "RIGHT KNIFE REVERSE",
            frontSrc: glowLeftKnifeImage,
        },
        {
            value: 1,
            label: "KATANA",
            frontSrc: glowKatanaImage,
        },
        {
            value: 2,
            label: "CHAIN AXE",
            frontSrc: glowChainAxeImage,
        },
        {
            value: 3,
            label: "VARIABLE BLADE - LOOSE",
            frontSrc: glowLooseVariableBladeImage,
        },
        {
            value: 4,
            label: "VARIABLE BLADE - RIGID",
            frontSrc: glowRigidVariableBladeImage,
        },
        {
            value: 5,
            label: "VARIABLE BLADE - LOOSE + KNIFE",
            frontSrc: glowLooseVariableBladeAndKnifeImage,
        },
        {
            value: 6,
            label: "VARIABLE BLADE - RIGID + KNIFE",
            frontSrc: glowRigidVariableBladeAndKnifeImage,
        },
        {
            value: 7,
            label: "KATANA + KNIFE",
            frontSrc: glowKatanaAndKnifeImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ACCESSORY",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
