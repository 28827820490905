import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/BDF-Akinaka`;

// BODY
const bodyAkinakaImage = `${imagePath}/Chassis.png`;

// HEADS
const headAkinakaImage = `${imagePath}/heads/Head - Akinaka.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHoodedImage = `${imagePath}/heads/Head - Hooded.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// ARMS
const armsLimpetLauncherImage = `${imagePath}/arms/Arms Rifle - Limpet Launcher.png`;
const armsStaffImage = `${imagePath}/arms/Arms Staff - Staff.png`;
const armsTwinbladeImage = `${imagePath}/arms/Arms Reverse Grip - Twinblade.png`;
const armsYazataImage = `${imagePath}/arms/Arms Rifle - Yazata.png`;

// WEAPONS
const weaponLimpetLauncherImage = `${imagePath}/weapons/Weapon - Limpet Launcher.png`;
const weaponStaffImage = `${imagePath}/weapons/Weapon - Staff.png`;
const weaponTwinbladesImage = `${imagePath}/weapons/Weapon - Twinblades.png`;
const weaponWhipswordImage = `${imagePath}/weapons/Weapon - Whipsword.png`;
const weaponYazataImage = `${imagePath}/weapons/Weapon - Yazata.png`;

// REAR MOUNT
const rearMountAkinakaWingsImage = `${imagePath}/rear/Cowl Over - Akinaka Wings.png`;
const rearMountAkinakaWingsBackImage = `${imagePath}/rear/Cowl Under - Akinaka Wings.png`;
const rearMountBlastShieldImage = `${imagePath}/rear/Cowl Over - Blast Shield.png`;
const rearMountBlastShieldBackImage = `${imagePath}/rear/Cowl Under - Blast Shield.png`;
const rearMountCloakImage = `${imagePath}/rear/Cowl Over - Cloak.png`;
const rearMountCloakBackImage = `${imagePath}/rear/Cowl Under - Cloak.png`;

// GLOW
const glowAkinakaHeadImage = `${imagePath}/glows/Glow - Akinaka Head.png`;
const glowSaturationDroneImage = `${imagePath}/glows/Glow - Saturation Drone.png`;
const glowYazataDroneImage = `${imagePath}/glows/Glow - Yazata Drone.png`;
const glowLimpetLauncherImage = `${imagePath}/glows/Glow - Limpet Launcher.png`;
const glowStaffImage = `${imagePath}/glows/Glow - Staff.png`;
const glowTwinbladeImage = `${imagePath}/glows/Glow - Twinblade.png`;
const glowTwinbladeArcsImage = `${imagePath}/glows/Glow - Twinblade Arcs.png`;

// ACCESSORY
const accDroneYazataImage = `${imagePath}/Drone - Yazata Drone.png`;
const accDroneSaturationImage = `${imagePath}/Drone - Saturation Drone.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const core1Image =  `${imagePath}/Akinaka_by_gummies139.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-11px",
    right: "4px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    secondaryWeaponIsChassisStanceSpecial: true,
    accessory1IsSecondaryWeapon: true,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "0",
        "accessory3Value": "3",
        "accessory4Value": "NONE",
        "stanceValue": "LIMPET LAUNCHER",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "AKINAKA",
            src: headAkinakaImage,
        },
        {
            value: 1,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 2,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 3,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 4,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 5,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 6,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 7,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 8,
            label: "HOODED",
            src: headHoodedImage,
        },
        {
            value: 9,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 10,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 11,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 12,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 13,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 14,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 15,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 16,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 17,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 18,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 19,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 20,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 21,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 22,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 23,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 24,
            label: "NELSON",
            src: headNelsonImage,
        },
        {
            value: 25,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 26,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 27,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 28,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 29,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 30,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 31,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "LIMPET LAUNCHER",
            src: bodyAkinakaImage,
            midSrc: armsLimpetLauncherImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Clem Cuddlecakes, original by",
                title: "Zerovirus",
                url: "https://www.artstation.com/drazelic",
            },
        },
        {
            value: 1,
            label: "STAFF",
            src: bodyAkinakaImage,
            midSrc: armsStaffImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Clem Cuddlecakes, original by",
                title: "Zerovirus",
                url: "https://www.artstation.com/drazelic",
            },
        },
        {
            value: 2,
            label: "TWINBLADES",
            src: bodyAkinakaImage,
            midSrc: armsTwinbladeImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Clem Cuddlecakes, original by",
                title: "Zerovirus",
                url: "https://www.artstation.com/drazelic",
            },
        },
        {
            value: 3,
            label: "WHIPSWORD",
            src: bodyAkinakaImage,
            midSrc: armsStaffImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Clem Cuddlecakes, original by",
                title: "Zerovirus",
                url: "https://www.artstation.com/drazelic",
            },
        },
        {
            value: 4,
            label: "YAZATA",
            src: bodyAkinakaImage,
            midSrc: armsYazataImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Clem Cuddlecakes, original by",
                title: "Zerovirus",
                url: "https://www.artstation.com/drazelic",
            },
        },
    ],
    weapon: {
        "LIMPET LAUNCHER": [
            {
                value: 0,
                label: "LIMPET LAUNCHER",
                src: weaponLimpetLauncherImage,
            },
        ],
        "STAFF": [
            {
                value: 0,
                label: "STAFF",
                src: weaponStaffImage,
            },
        ],
        "TWINBLADES": [
            {
                value: 0,
                label: "TWINBLADES",
                src: weaponTwinbladesImage,
            },
        ],
        "WHIPSWORD": [
            {
                value: 0,
                label: "WHIPSWORD",
                src: weaponWhipswordImage,
            },
        ],
        "YAZATA": [
            {
                value: 0,
                label: "YAZATA",
                src: weaponYazataImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SATURATION",
            src: accDroneSaturationImage,
        },
        {
            value: 1,
            label: "YAZATA",
            src: accDroneYazataImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "AKINAKA WINGS",
            frontSrc: rearMountAkinakaWingsImage,
            backSrc: rearMountAkinakaWingsBackImage,
        },
        {
            value: 1,
            label: "BLAST SHIELD",
            frontSrc: rearMountBlastShieldImage,
            backSrc: rearMountBlastShieldBackImage,
        },
        {
            value: 2,
            label: "CLOAK",
            frontSrc: rearMountCloakImage,
            backSrc: rearMountCloakBackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "AKINAKA HEAD",
            frontSrc: glowAkinakaHeadImage,
        },
        {
            value: 1,
            label: "DRONE - SATURATION",
            frontSrc: glowSaturationDroneImage,
        },
        {
            value: 2,
            label: "DRONE - YAZATA",
            frontSrc: glowYazataDroneImage,
        },
        {
            value: 3,
            label: "LIMPET LAUNCHER",
            frontSrc: glowLimpetLauncherImage,
        },
        {
            value: 4,
            label: "STAFF",
            frontSrc: glowStaffImage,
        },
        {
            value: 5,
            label: "TWINBLADES",
            frontSrc: glowTwinbladeImage,
        },
        {
            value: 6,
            label: "TWINBLADES + ARCS",
            frontSrc: glowTwinbladeArcsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "DRONE",
            value: "secondaryWeapon",
        },
        {
            label: "COWL",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "STANCE",
        "rearMount": "COWL",
        "weapon": "WEAPON",
        "secondaryWeapon": "DRONE",
        "accessory1": "EMPTY",
        "accessory2": "GLOW 1",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
