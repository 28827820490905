import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Rakshasa`;

// BODY
const bodyRakshasaMImage = `${imagePath}/Chassis - M.png`;
const bodyRakshasaFImage = `${imagePath}/Chassis - F.png`;

// HEADS
const headRakshasaImage = `${imagePath}/heads/Head - Rakshasa.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headHoodedImage = `${imagePath}/heads/Head - Hooded.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headInuzakaImage = `${imagePath}/heads/Head - Inuzaka.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headMonoeyeImage = `${imagePath}/heads/Head - Monoeye.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headRaleighImage = `${imagePath}/heads/Head - Raleigh.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVagrantImage = `${imagePath}/heads/Head - Vagrant.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headZatoichiImage = `${imagePath}/heads/Head - Zatoichi.png`;

// WEAPONS
const weaponAxesImage = `${imagePath}/weapons/Weapons - Axes.png`;
const weaponChakramDaggerSwordImage = `${imagePath}/weapons/Weapons - Chakram Dagger Sword.png`;
const weaponChargedBladesImage = `${imagePath}/weapons/Weapons - Charged Blades.png`;
const weaponUnderChargedBladesImage = `${imagePath}/weapons/Weapons Under - Charged Blades.png`;
const weaponHandCannonsImage = `${imagePath}/weapons/Weapons - Hand Cannons.png`;
const weaponHeavyBladeHeavyChakramDaggerImage = `${imagePath}/weapons/Weapons - Heavy Blade Heavy Chakram Dagger.png`;
const weaponKatarsDaggerImage = `${imagePath}/weapons/Weapons - Katars Dagger.png`;
const weaponKatarsQuadImage = `${imagePath}/weapons/Weapons - Katars Quad.png`;
const weaponRakshasaImage = `${imagePath}/weapons/Weapons - Rakshasa.png`;
const weaponUnderRakshasaWeaponsImage = `${imagePath}/weapons/Weapons Under - Rakshasa Weapons.png`;
const weaponUnarmedImage = `${imagePath}/weapons/Weapons - Unarmed.png`;
const weaponUndulBladesImage = `${imagePath}/weapons/Weapons - Undul Blades.png`;
const weaponUnderUndulBladesImage = `${imagePath}/weapons/Weapons Under - Undul Blades.png`;
const weaponWhipswordsDaggerImage = `${imagePath}/weapons/Weapons - Whipswords Dagger.png`;
const weaponUnderWhipswordsDaggerImage = `${imagePath}/weapons/Weapons Under - Whipswords Dagger.png`;

// OUTFITS
const outfitInuzakaImage = `${imagePath}/outfits/Outfit - Inuzaka.png`;
const outfitManusAurumImage = `${imagePath}/outfits/Outfit - Manus Aurum.png`;
const outfitRakshasaImage = `${imagePath}/outfits/Outfit - Rakshasa.png`;
const outfitRaleighImage = `${imagePath}/outfits/Outfit - Raleigh.png`;

// GLOWS
const glowChakramDaggerSwordImage = `${imagePath}/glows/Glow - Chakram Dagger Sword.png`;
const glowChakramDaggerSword2Image = `${imagePath}/glows/Glow - Chakram Dagger Sword 2.png`;
const glowChargedBladesImage = `${imagePath}/glows/Glow - Charged Blades.png`;
const glowChargedBlades2Image = `${imagePath}/glows/Glow - Charged Blades 2.png`;
const glowUnderChargedBladesImage = `${imagePath}/glows/Glow Under - Charged Blades.png`;
const glowHeavyBladeHeavyChakramDaggerImage = `${imagePath}/glows/Glow - Heavy Blade Heavy Chakram Dagger.png`;
const glowHeavyBladeHeavyChakramDagger2Image = `${imagePath}/glows/Glow - Heavy Blade Heavy Chakram Dagger 2.png`;
const glowKatarsQuad2Image = `${imagePath}/glows/Glow - Katars Quad 2.png`;
const glowKatarsQuadImage = `${imagePath}/glows/Glow - Katars Quad.png`;
const glowKatarsDagger2Image = `${imagePath}/glows/Glow - Katars Dagger 2.png`;
const glowKatarsDaggerImage = `${imagePath}/glows/Glow - Katars Dagger.png`;
const glowRakshasaWeapons2Image = `${imagePath}/glows/Glow - Rakshasa Weapons 2.png`;
const glowRakshasaWeaponsImage = `${imagePath}/glows/Glow - Rakshasa Weapons.png`;
const glowUnarmedImage = `${imagePath}/glows/Glow - Unarmed.png`;
const glowUndulImage = `${imagePath}/glows/Glow - Undul.png`;
const glowUndul2Image = `${imagePath}/glows/Glow - Undul 2.png`;
const glowUnderUndulImage = `${imagePath}/glows/Glow Under - Undul.png`;
const glowWhipswordsDaggerImage = `${imagePath}/glows/Glow - Whipswords Dagger.png`;
const glowUnderWhipswordsDaggerImage = `${imagePath}/glows/Glow Under - Whipswords Dagger.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "70px",
    right: "70px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "11",
        "secondaryWeaponValue": "7",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "RAKSHASA",
            foremostSrc: headRakshasaImage,
        },
        {
            value: 1,
            label: "CALIBAN",
            foremostSrc: headCalibanImage,
        },
        {
            value: 2,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 3,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 4,
            label: "HOODED",
            foremostSrc: headHoodedImage,
        },
        {
            value: 5,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 6,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 7,
            label: "INUZAKA",
            foremostSrc: headInuzakaImage,
        },
        {
            value: 8,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 9,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 10,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 11,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 12,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 13,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 14,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 15,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 16,
            label: "MONOEYE",
            foremostSrc: headMonoeyeImage,
        },
        {
            value: 17,
            label: "NATURAL LAW",
            foremostSrc: headNaturalLawImage,
        },
        {
            value: 18,
            label: "NATURAL LAW HAIR",
            foremostSrc: headNaturalLawHairImage,
        },
        {
            value: 19,
            label: "RAIN",
            foremostSrc: headRainImage,
        },
        {
            value: 20,
            label: "RAIN HAIR",
            foremostSrc: headRainHairImage,
        },
        {
            value: 21,
            label: "RALEIGH",
            foremostSrc: headRaleighImage,
        },
        {
            value: 22,
            label: "SKULL",
            foremostSrc: headSkullImage,
        },
        {
            value: 23,
            label: "VAGRANT",
            foremostSrc: headVagrantImage,
        },
        {
            value: 24,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 25,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
        {
            value: 26,
            label: "ZATOICHI",
            foremostSrc: headZatoichiImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "M",
            src: bodyRakshasaMImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "F",
            src: bodyRakshasaFImage,
            shadowDetails: bipedLegsShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CHAKRAM + DAGGER + SWORD",
                src: glowChakramDaggerSwordImage,
            },
            {
                value: 1,
                label: "CHAKRAM + DAGGER + SWORD 2",
                src: glowChakramDaggerSword2Image,
            },
            {
                value: 2,
                label: "CHARGED BLADES",
                src: glowChargedBladesImage,
                backSrc: glowUnderChargedBladesImage,
            },
            {
                value: 3,
                label: "CHARGED BLADES 2",
                src: glowChargedBlades2Image,
                backSrc: glowUnderChargedBladesImage,
            },
            {
                value: 4,
                label: "HEAVY BLADE + HEAVY CHAKRAM + DAGGER",
                src: glowHeavyBladeHeavyChakramDaggerImage,
            },
            {
                value: 5,
                label: "HEAVY BLADE + HEAVY CHAKRAM + DAGGER 2",
                src: glowHeavyBladeHeavyChakramDagger2Image,
            },
            {
                value: 6,
                label: "KATARS + DAGGER",
                src: glowKatarsDaggerImage,
            },
            {
                value: 7,
                label: "KATARS + DAGGER 2",
                src: glowKatarsDagger2Image,
            },
            {
                value: 8,
                label: "KATARS X4",
                src: glowKatarsQuadImage,
            },
            {
                value: 9,
                label: "KATARS X4 2",
                src: glowKatarsQuad2Image,
            },
            {
                value: 10,
                label: "RAKSHASA WEAPONS",
                src: glowRakshasaWeaponsImage,
            },
            {
                value: 11,
                label: "RAKSHASA WEAPONS 2",
                src: glowRakshasaWeapons2Image,
            },
            {
                value: 12,
                label: "UNARMED",
                src: glowUnarmedImage,
            },
            {
                value: 13,
                label: "UNDUL BLADES",
                src: glowUndulImage,
                backSrc: glowUnderUndulImage,
            },
            {
                value: 14,
                label: "UNDUL BLADES 2",
                src: glowUndul2Image,
                backSrc: glowUnderUndulImage,
            },
            {
                value: 15,
                label: "WHIPSOWRDS + DAGGER",
                src: glowWhipswordsDaggerImage,
                backSrc: glowUnderWhipswordsDaggerImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "AXES",
            src: weaponAxesImage,
        },
        {
            value: 1,
            label: "CHAKRAM + DAGGER + SWORD",
            src: weaponChakramDaggerSwordImage,
        },
        {
            value: 2,
            label: "CHARGED BLADES",
            src: weaponChargedBladesImage,
            backSrc: weaponUnderChargedBladesImage,
        },
        {
            value: 3,
            label: "HAND CANNONS",
            src: weaponHandCannonsImage,
        },
        {
            value: 4,
            label: "HEAVY BLADE + HEAVY CHAKRAM + DAGGER",
            src: weaponHeavyBladeHeavyChakramDaggerImage,
        },
        {
            value: 5,
            label: "KATARS + DAGGER",
            src: weaponKatarsDaggerImage,
        },
        {
            value: 6,
            label: "KATARS X4",
            src: weaponKatarsQuadImage,
        },
        {
            value: 7,
            label: "RAKSHASA",
            src: weaponRakshasaImage,
            backSrc: weaponUnderRakshasaWeaponsImage,
        },
        {
            value: 8,
            label: "UNARMED",
            src: weaponUnarmedImage,
        },
        {
            value: 9,
            label: "UNDUL BLADES",
            src: weaponUndulBladesImage,
            backSrc: weaponUnderUndulBladesImage,
        },
        {
            value: 10,
            label: "WHIPSWORDS + DAGGER",
            src: weaponWhipswordsDaggerImage,
            backSrc: weaponUnderWhipswordsDaggerImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "INUZAKA",
            src: outfitInuzakaImage,
        },
        {
            value: 1,
            label: "MANUS AURUM",
            src: outfitManusAurumImage,
        },
        {
            value: 2,
            label: "RAKSHASA",
            src: outfitRakshasaImage,
        },
        {
            value: 3,
            label: "RALEIGH",
            src: outfitRaleighImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "GLOW",
            value: "weapon",
            isGlow: true,
        },
        {
            label: "WEAPONS",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "GLOW",
        "secondaryWeapon": "WEAPONS",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
