import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodySwallowtailImage = `${BaseURL}/Mechs/SSC/Swallowtail/Turret - Swallowtail.png`;
// LEGENDARY
const bodyTusbameImage = `${BaseURL}/Mechs/SSC/Swallowtail/Turret - Tsubame.png`;

// LEGS
const legsSwallowtailImage = `${BaseURL}/Mechs/SSC/Swallowtail/Legs - Swallowtail.png`;
// PREMIUM
const legsQuadImage = `${BaseURL}/Mechs/SSC/Swallowtail/Legs - Quad.png`;
// LEGENDARY
const legsTsubameUpImage = `${BaseURL}/Mechs/SSC/Swallowtail/Legs - Tsubame - Wheels Up.png`;
const legsTsubameDownImage = `${BaseURL}/Mechs/SSC/Swallowtail/Legs - Tsubame - Wheels Down.png`;

// WEAPONS
// LEFT
const weaponsAssaultRifleImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/left/Weapon - Left - Assault Rifle.png`;
const weaponsBlackSpotImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/left/Weapon - Left - Black Spot.png`;
const weaponsDualNexusImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/left/Weapon - Left - Dual Nexus.png`;
const weaponsHunterKillerNexusImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/left/Weapon - Left - Hunter-Killer Nexus.png`;
const weaponsLongGunImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/left/Weapon - Left - Long Gun.png`;
const weaponsMissilesImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/left/Weapon - Left - Missiles.png`;
const weaponsOracleLMGImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/left/Weapon - Left - Oracle LMG.png`;

// RIGHT
const secondaryWeaponsAssaultRifleImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/right/Weapon - Right - Assault Rifle.png`;
const secondaryWeaponsDualNexusImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/right/Weapon - Right - Dual Nexus.png`;
const secondaryWeaponsMissilesImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/right/Weapon - Right - Missiles.png`;

// GLOW
const glowBlackSpotImage = `${BaseURL}/Mechs/SSC/Swallowtail/weapons/Glow - Black Spot.png`;
const glowSwallowtailImage = `${BaseURL}/Mechs/SSC/Swallowtail/Glow - Swallowtail Cloaking.png`;
const glowTsubameUpImage = `${BaseURL}/Mechs/SSC/Swallowtail/Glow - Tsubame - Wheels Up Cloaking.png`;
const glowTsubameDownImage = `${BaseURL}/Mechs/SSC/Swallowtail/Glow - Tsubame - Wheels Down Cloaking.png`;

const coreImage = `${BaseURL}/Mechs/SSC/Swallowtail/Swallowtail A (by Seamless).png`;
const Core2Image = `${BaseURL}/Mechs/SSC/Swallowtail/Swallowtail B (by Seamless).png`;

const previewImage = `${BaseURL}/Mechs/SSC/Swallowtail/preview.png`;

const swallowtailLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "86px",
    right: "7px",
};

const quadLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "90px",
    right: "6px",
};

const tsubameLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "95px",
    right: "3px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: Core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "4",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SWALLOWTAIL",
            foremostSrc: bodySwallowtailImage,
        },
        {
            value: 1,
            label: "TSUBAME",
            foremostSrc: bodyTusbameImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SWALLOWTAIL",
            src: legsSwallowtailImage,
            shadowDetails: swallowtailLegsShadow,
        },
        {
            value: 1,
            label: "QUAD",
            src: legsQuadImage,
            shadowDetails: quadLegsShadow,
            isPremium: true,
        },
        {
            value: 2,
            label: "TSUBAME - WHEELS UP",
            src: legsTsubameUpImage,
            shadowDetails: tsubameLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "TSUBAME - WHEELS DOWN",
            src: legsTsubameDownImage,
            shadowDetails: tsubameLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE",
                src: weaponsAssaultRifleImage,
            },
            {
                value: 1,
                label: "BLACK SPOT",
                src: weaponsBlackSpotImage,
            },
            {
                value: 2,
                label: "DUAL NEXUS",
                src: weaponsDualNexusImage,
            },
            {
                value: 3,
                label: "HUNTER-KILLER NEXUS",
                src: weaponsHunterKillerNexusImage,
            },
            {
                value: 4,
                label: "LONG GUN",
                src: weaponsLongGunImage,
            },
            {
                value: 5,
                label: "MISSILES",
                src: weaponsMissilesImage,
            },
            {
                value: 6,
                label: "ORACLE LMG",
                src: weaponsOracleLMGImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ASSAULT RIFLE",
            foremostSrc: secondaryWeaponsAssaultRifleImage,
        },
        {
            value: 1,
            label: "DUAL NEXUS",
            foremostSrc: secondaryWeaponsDualNexusImage,
        },
        {
            value: 2,
            label: "MISSILES",
            foremostSrc: secondaryWeaponsMissilesImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "BLACK SPOT",
            frontSrc: glowBlackSpotImage,
        },
        {
            value: 1,
            label: "CLOAKING SWALLOWTAIL",
            frontSrc: glowSwallowtailImage,
        },
        {
            value: 2,
            label: "CLOAKING TSUBAME UP",
            frontSrc: glowTsubameUpImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "CLOAKING TSUBAME DOWN",
            frontSrc: glowTsubameDownImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "head",
        },
        {
            label: "LEGS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "CHASSIS",
        "chassis": "LEGS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
