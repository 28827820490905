import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Philippides`;

// BODY
const bodyPhilippidesImage = `${imagePath}/chassis.png`;
const bodyBackArmImage = `${imagePath}/back_arm.png`;

// HEADS
const headPhilippidesImage = `${imagePath}/HEADS/Philippides.png`;
const headPhilippidesAltImage = `${imagePath}/HEADS/Philippides Alt.png`;
const head8BallImage = `${imagePath}/HEADS/8 Ball.png`;
const headBriarImage = `${imagePath}/HEADS/Briar.png`;
const headCalibanImage = `${imagePath}/HEADS/Caliban.png`;
const headRacingCoverImage = `${imagePath}/HEADS/Racing Cover.png`;

// WEAPONS
const weaponNoneImage = `${imagePath}/WEAPONS/No Weapon.png`;
const weaponG18KOPISImage = `${imagePath}/WEAPONS/G18 KOPIS.png`;
const weaponM14DORYImage = `${imagePath}/WEAPONS/M14 DORY.png`;

// REAR MOUNT
const rearMountSparthatlonSubroutineImage = `${imagePath}/REAR MOUNT/Sparthatlon Subroutine.png`;

// ACCESSORIES
const accessorySideBoostersFrontImage = `${imagePath}/ACCESSORIES/Side-Boosters_front.png`;
const accessorySideBoostersBackImage = `${imagePath}/ACCESSORIES/Side-Boosters_back.png`;

// GLOWS
const glowChassisImage = `${imagePath}/GLOWS/Chassis.png`;
const glowHeadPhilippidesImage = `${imagePath}/GLOWS/Head Philippides.png`;
const glowHeadRacingCoverImage = `${imagePath}/GLOWS/Head Racing-Cover.png`;
const glowWeaponG18KOPISImage = `${imagePath}/GLOWS/Weapon-G18 KOPIS.png`;
const glowWeaponM14DORYImage = `${imagePath}/GLOWS/Weapon-M14 DORY.png`;

// BOOSTERS
const glowFullBoostImage = `${imagePath}/GLOWS BOOSTERS/Full Boost.png`;
const glowSaintRunnerColouredImage = `${imagePath}/GLOWS BOOSTERS/SaintRunner Coloured.png`;
const glowSaintRunnerImage = `${imagePath}/GLOWS BOOSTERS/SaintRunner.png`;
const glowSideBSSBImage = `${imagePath}/GLOWS BOOSTERS/Side B SSB.png`;
const glowSideBWingedBImage = `${imagePath}/GLOWS BOOSTERS/Side B Winged B.png`;
const glowSideBoostersImage = `${imagePath}/GLOWS BOOSTERS/Side Boosters.png`;
const glowSparthatlonSubroutineBoosterImage = `${imagePath}/GLOWS BOOSTERS/Sparthatlon Subroutine Booster.png`;
const glowWingedBSSBImage = `${imagePath}/GLOWS BOOSTERS/Winged B SSB.png`;
const glowWingedBootsImage = `${imagePath}/GLOWS BOOSTERS/Winged Boots.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/PHILIPPIDES_Corebook_Paintjob.png`;

const bipedLegsShadow = {
    size: shadowTypes.SMALL,
    bottom: "61px",
    right: "47px",
};

const originalArtCredit = {
    prefixText: "Colored by ",
    title: "Fliren",
    url: "https://linktr.ee/fliren",
}

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit,
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "1",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "3",
        "accessory4Value": "5",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "PHILIPPIDES",
            src: headPhilippidesImage,
        },
        {
            value: 1,
            label: "PHILIPPIDES ALT",
            src: headPhilippidesAltImage,
        },
        {
            value: 2,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 3,
            label: "BRIAR",
            src: headBriarImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "RACING COVER",
            src: headRacingCoverImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SCARLET ARMORY PHILIPPIDES",
            src: bodyPhilippidesImage,
            backSrc: bodyBackArmImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "UNARMED",
                src: weaponNoneImage,
            },
            {
                value: 1,
                label: "G18 KOPIS",
                src: weaponG18KOPISImage,
            },
            {
                value: 2,
                label: "M14 DORY",
                src: weaponM14DORYImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SIDE-BOOSTERS",
            src: accessorySideBoostersFrontImage,
            backSrc: accessorySideBoostersBackImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "SPARTHATLON SUBROUTINE",
            src: rearMountSparthatlonSubroutineImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "HEAD - PHILIPPIDES",
            frontSrc: glowHeadPhilippidesImage,
        },
        {
            value: 2,
            label: "HEAD - RACING COVER",
            frontSrc: glowHeadRacingCoverImage,
        },
        {
            value: 3,
            label: "WEAPON - G18 KOPIS",
            frontSrc: glowWeaponG18KOPISImage,
        },
        {
            value: 4,
            label: "WEAPON - M14 DORY",
            frontSrc: glowWeaponM14DORYImage,
        },
        {
            value: 5,
            label: "FULL BOOST",
            src: glowFullBoostImage,
        },
        {
            value: 6,
            label: "SAINT RUNNER - COLOURED",
            src: glowSaintRunnerColouredImage,
        },
        {
            value: 7,
            label: "SAINT RUNNER",
            src: glowSaintRunnerImage,
        },
        {
            value: 8,
            label: "SIDE B + SSB",
            src: glowSideBSSBImage,
        },
        {
            value: 9,
            label: "SIDE B + WINGED B",
            src: glowSideBWingedBImage,
        },
        {
            value: 10,
            label: "SIDE BOOSTERS",
            src: glowSideBoostersImage,
        },
        {
            value: 11,
            label: "SPARTHATLON SUBROUTINE BOOSTER",
            src: glowSparthatlonSubroutineBoosterImage,
        },
        {
            value: 12,
            label: "WINGED B + SSB",
            src: glowWingedBSSBImage,
        },
        {
            value: 13,
            label: "WINGED BOOTS",
            src: glowWingedBootsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "ACCESSORIES",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPONS",
        "secondaryWeapon": "ACCESSORIES",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
