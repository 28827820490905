import React, { useCallback, useEffect, useState, createContext } from "react";

import BaseURL from "./../Unit/data/images/Hosting-BaseURL";

const unitSFXFile = `${BaseURL}/music/SFX1.wav`;

const sfxIsMutedCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`sfxIsMuted=`));
const sfxVolumeCookie = document.cookie.split(";").filter((item) => item.trim().startsWith(`sfxVolume=`));

const defaultVolume = sfxVolumeCookie.length ? (sfxVolumeCookie[0].split("=")[1] / 100) : 0.25;

export const SFXContext = createContext(null);

function SFXContextWrapper({ ...props }) {
    const [isMuted, setIsMuted] = useState(sfxIsMutedCookie.length && sfxIsMutedCookie[0].split("=")[1] === "true" ? true : false);
    const [volumeValue, setVolumeValue] = useState(defaultVolume * 100);

    const [sfxMusic] = useState(new Audio(unitSFXFile));

    const playSFXMusic = useCallback(() => {
        if (!isMuted) {
            if (sfxMusic.readyState !== 0) {
                sfxMusic.pause();
                sfxMusic.currentTime = 0;
            }

            sfxMusic.play();
        }
    }, [sfxMusic, isMuted]);

    useEffect(() => {
        sfxMusic.volume = volumeValue / 100;
        sfxMusic.loop = false;
        sfxMusic.muted = false;

    }, [sfxMusic, volumeValue]);

    useEffect(() => {
        sfxMusic.muted = isMuted;
    }, [isMuted, sfxMusic]);

    function handleVolumeChange(event) {
        setVolumeValue(event.target.value);
        document.cookie = `sfxVolume=${event.target.value}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;

        sfxMusic.volume = event.target.value / 100;
    }

    return (
        <SFXContext.Provider value={{
            playSFXMusic,
            isMuted,
            setIsMuted,
            handleVolumeChange,
            volumeValue,
        }}>
            {props.children}
        </SFXContext.Provider>
    );
}

export default SFXContextWrapper;
