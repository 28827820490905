import React, { useContext } from "react";
import { bool, number, string } from "prop-types";
import styled from "styled-components";

import MembershipTierImages from "./../../common/MembershipTierImages";
import { UserAuthContext } from "./../../User/UserAuthContextWrapper";
import SpriteImage, { SPRITE_KEYS } from "../../common/styles/SpriteImage";
import Unit from "./Unit";

const UnitVariantWrapper = styled.div`
    ${Unit}:hover & {
        visibility: ${props => props.displayControl ? "visible" : "hidden"};
    }

    :active {
        transform: translateY(2px);
    }

    cursor: pointer;
    visibility: hidden;

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        visibility: ${props => props.displayControl ? "visible" : "hidden"};
    }

    align-self: flex-start;
    cursor: default;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    :active {
        transform: none;
    }
`;

UnitVariantWrapper.displayName = "UnitVariantWrapper";

const UnitVariantIndicatorImage = styled.img`
    margin-left: 1px;
    margin-right: 1px;
`;

const UnitVariantInactiveIndicatorImage = styled(SpriteImage)`
    margin-left: 1px;
    margin-right: 1px;
`;

UnitVariantIndicatorImage.displayName = "UnitVariantIndicatorImage";

function UnitVariantIndicator({ currentVariant, isPremium, isLegendary, numVariants, unitID }) {
    const { user } = useContext(UserAuthContext);

    const variantImages = [];

    let activeIndicator = MembershipTierImages.mundaneTier.orb;
    let activeTitle = MembershipTierImages.mundaneTier.alt;

    if (isPremium) {
        activeTitle = MembershipTierImages.plusOneTier.alt;

        if (user && user.isPremiumUser) {
            activeIndicator = MembershipTierImages.plusOneTier.orb;
        } else {
            activeIndicator = MembershipTierImages.plusOneTierEyeCatch.orb;
        }
    }

    if (isLegendary) {
        activeTitle = MembershipTierImages.legendaryTier.alt;

        if (user && user.isLegendaryUser) {
            activeIndicator = MembershipTierImages.themepackTier.orb;
        } else {
            activeIndicator = MembershipTierImages.themepackTierEyeCatch.orb;
        }
    }

    for (let i = 0; i < numVariants; i += 1) {
        if (currentVariant === i) {
            variantImages.push(
                <UnitVariantIndicatorImage
                    key={`${unitID}-${i}`}
                    src={activeIndicator}
                    alt="Current Variant"
                    title={`${activeTitle} Variant`}
                />
            );
        } else {
            variantImages.push(
                <UnitVariantInactiveIndicatorImage
                    key={`${unitID}-${i}`}
                    className={SPRITE_KEYS.variantInactive}
                    alt="Inactive Variant"
                />
            );
        }
    }

    return (
        <UnitVariantWrapper
            as="div"
            displayControl
        >
            {variantImages}
        </UnitVariantWrapper>
    )
}

UnitVariantIndicator.propTypes = {
    currentVariant: number.isRequired,
    isPremium: bool.isRequired,
    isLegendary: bool,
    numVariants: number.isRequired,
    unitID: string.isRequired,
};

UnitVariantIndicator.defaultProps = {
    isLegendary: false,
};

export default UnitVariantIndicator;
