import BaseURL from "./images/Hosting-BaseURL";
import { shadowTypes } from "../styles/UnitShadow";

const imagePath = `${BaseURL}/Mechs/Custom/MOI Solomon/Solomon Drones`;

// BODY
const bodyAndromaliusImage = `${imagePath}/Andromalius Drone.png`;
const bodyBelialShardImage = `${imagePath}/Belial Shard.png`;
const bodyFlaurosTurretImage = `${imagePath}/Flauros Turret.png`;
const bodyMalphasImage = `${imagePath}/Malphas Drone.png`;
const bodyPhenexImage = `${imagePath}/Phenex Drone.png`;

// GLOWS
const glowAndromaliusImage = `${imagePath}/Andromalius Drone Glow.png`;
const glowBelialShardImage = `${imagePath}/Belial Shard Glow.png`;
const glowFlaurosTurretImage = `${imagePath}/Flauros Turret Glow.png`;
const glowMalphasImage = `${imagePath}/Malphas Drone Glow.png`;
const glowPhenexImage = `${imagePath}/Phenex Drone Glow.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/core-Solomon-drone-AzMiLion-1.png`;
const core2Image = `${imagePath}/core-Solomon-drone-AzMiLion-2.png`;
const core3Image = `${imagePath}/core-Solomon-drone-AzMiLion-3.png`;
const core4Image = `${imagePath}/core-Solomon-drone-AzMiLion-4.png`;
const core5Image = `${imagePath}/core-Solomon-drone-AzMiLion-5.png`;

const originalArtCredit = {
    prefixText: "Commissioned by Pysbomb ",
    title: "Link in News",
    url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
};

export default {
    previewImg: previewImage,
    isMegadeusPlus: true,
    rearMountsAreChassis: true,
    rearMountsAreChassisSpecial: "headValue",
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core3Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core4Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core5Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ANDROMALIUS DRONE",
            src: glowAndromaliusImage,
        },
        {
            value: 1,
            label: "BELIAL SHARD",
            src: glowBelialShardImage,
        },
        {
            value: 2,
            label: "FLAUROS TURRET",
            src: glowFlaurosTurretImage,
        },
        {
            value: 3,
            label: "MALPHAS DRONE",
            src: glowMalphasImage,
        },
        {
            value: 4,
            label: "PHENEX DRONE",
            src: glowPhenexImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ANDROMALIUS DRONE",
            src: bodyAndromaliusImage,
            shadowDetails: {
                size: shadowTypes.MEDIUM,
                bottom: "55px",
                right: "31px",
            },
            originalArtCredit,
        },
        {
            value: 1,
            label: "BELIAL SHARD",
            src: bodyBelialShardImage,
            shadowDetails: {
                size: shadowTypes.LARGE,
                bottom: "-18px",
                right: "-29px",
            },
            originalArtCredit,
        },
        {
            value: 2,
            label: "FLAUROS TURRET",
            src: bodyFlaurosTurretImage,
            shadowDetails: {
                size: shadowTypes.SMALL,
                bottom: "63px",
                right: "42px",
            },
            originalArtCredit,
        },
        {
            value: 3,
            label: "MALPHAS DRONE",
            src: bodyMalphasImage,
            shadowDetails: {
                size: shadowTypes.NONE,
                bottom: "63px",
                right: "42px",
            },
            originalArtCredit,
        },
        {
            value: 4,
            label: "PHENEX DRONE",
            src: bodyPhenexImage,
            shadowDetails: {
                size: shadowTypes.SMALL,
                bottom: "62px",
                right: "26px",
            },
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
