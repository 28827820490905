import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyWorldkillerImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/Chassis.png`;
const bodyPristineImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/Chassis - Pristine.png`;
const bodyTBKImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/Chassis - Total Biome Kill.png`;

// REAR MOUNT
const rearMountMissilesImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/rear mounts/Shoulders - Missiles.png`;

// HEADS
const decalsTeethImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Teeth.png`;
const decalsSkullImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Skull.png`;
const decalsKillCountImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Kill Count.png`;
const decalsFlamesImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Flames.png`;
const decalsFlatImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Flat.png`;
const decalGurrenImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Gurren.png`;
const decalsJawsImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Jaws.png`;
const decalsBigBruddaImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Big Brudda.png`;
const decalsBigPoppaImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Big Poppa.png`;
const decalsSenpai0w0Image = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Senpai 0w0.png`;
const decalsSenpaiEyesImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Senpai Eyes.png`;
const decalsSmileyHighImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Smiley High.png`;
const decalsSmileyMidImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/heads/Decals - Smiley Mid.png`;
// PREMIUM

// GLOW
// CHASSIS
const glowChassisVentsImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Vents.png`;
const glowChassisTubesImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Tubes.png`;
const glowChassisPlatingImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Plating.png`;
const glowChassisAllImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - All.png`;
const glowChassisAllFlatImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - All, Flat.png`;
const glowChassisTBKImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Total Biome Kill.png`;
const glowChassisTBKAllImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Total Biome Kill Flames.png`;
const glowChassisTBKFlamesImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Total Biome Kill - Flames Only.png`;
const glowChassisGurrenImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Gurren.png`;
const glowChassisGurrenAllImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Gurren All.png`;
const glowChassisJawsImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Jaws.png`;
const glowChassisSenpai0w0Image = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Senpai 0w0.png`;
const glowChassisSenpaiImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Senpai.png`;
const glowChassisSmileyHighImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Smiley High.png`;
const glowChassisSmileyMidImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/chassis/Chassis Glow - Smiley Mid.png`;
// WEAPON
const glowWeaponRightDualCannonImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Right Dual Cannon.png`;
const glowWeaponRightTachyonLanceImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Right Tachyon Lance.png`;
const glowWeaponRightDisplacerImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Right Displacer.png`;
const glowWeaponLeftFlamethrowerImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Left Flamethrower.png`;
const glowWeaponRightFlamethrowerImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Right Flamethrower.png`;
const glowWeaponDualFlamethrowersImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Dual Flamethrowers.png`;
const glowWeaponLeftAnnihilatorImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Left Annihilator.png`;
const glowWeaponRightAnnihilatorImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Right Annihilator.png`;
const glowWeaponDualAnnihilatorsImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/glows/weapons/Weapon Glow - Dual Annihilators.png`;

// LEFT WEAPON
const leftWeaponFlamethrowerImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/left/Left Weapon - Flamethrower.png`;
const leftWeaponAnnihilatorImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/left/Left Weapon - Annihilator.png`;
const leftWeaponChainsawImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/left/Left Weapon - Chainsaw.png`;

// RIGHT WEAPON
const rightWeaponDualCannonImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/right/Right Weapon - Dual Cannon.png`;
const rightWeaponTachyonLanceImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/right/Right Weapon - Tachyon Lance.png`;
const rightWeaponDisplacerImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/right/Right Weapon - Displacer.png`;
const rightWeaponAnnihilatorImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/right/Right Weapon - Annihilator.png`;
const rightWeaponFlamethrowerImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/right/Right Weapon - Flamethrower.png`;
const rightWeaponGatlingCannonImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/weapons/right/Right Weapon - Gatling Cannon.png`;

const coreImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/CharlieMerlynn - Genghis_Worldkiller.png`;
const previewImage = `${BaseURL}/Mechs/HA/Genghis Mk I Worldkiller/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "17px",
    right: "20px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerlynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "0",
        "accessory1Value": "12",
        "accessory2Value": "1",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SKULL",
            src: decalsSkullImage,
        },
        {
            value: 1,
            label: "FLAT",
            src: decalsFlatImage,
        },
        {
            value: 2,
            label: "JAWS",
            src: decalsJawsImage,
            originalArtCredit: {
                title: "Umbral Reaver",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 3,
            label: "BIG BRUDDA",
            src: decalsBigBruddaImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "BIG POPPA",
            src: decalsBigPoppaImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "TEETH",
            src: decalsTeethImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "KILL COUNT",
            src: decalsKillCountImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "FLAMES",
            src: decalsFlamesImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "GURREN",
            src: decalGurrenImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "SENPAI 0w0",
            src: decalsSenpai0w0Image,
            isPremium: true,
        },
        {
            value: 10,
            label: "SENPAI EYES",
            src: decalsSenpaiEyesImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "SMILEY MID",
            src: decalsSmileyMidImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "SMILEY HIGH",
            src: decalsSmileyHighImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "WORLDKILLER",
            src: bodyWorldkillerImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "PRISTINE",
            src: bodyPristineImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "TOTAL BIOME KILL",
            src: bodyTBKImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "FLAMETHROWER",
                src: leftWeaponFlamethrowerImage,
            },
            {
                value: 1,
                label: "ANNIHILATOR",
                src: leftWeaponAnnihilatorImage,
            },
            {
                value: 2,
                label: "CHAINSAW",
                src: leftWeaponChainsawImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "DUAL CANNON",
            src: rightWeaponDualCannonImage,
        },
        {
            value: 1,
            label: "GATLING CANNON",
            src: rightWeaponGatlingCannonImage,
        },
        {
            value: 2,
            label: "FLAMETHROWER",
            src: rightWeaponFlamethrowerImage,
        },
        {
            value: 3,
            label: "TACHYON LANCE",
            src: rightWeaponTachyonLanceImage,
        },
        {
            value: 4,
            label: "DISPLACER",
            src: rightWeaponDisplacerImage,
        },
        {
            value: 5,
            label: "ANNIHILATOR",
            src: rightWeaponAnnihilatorImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "SHOULDER MISSILES",
            src: rearMountMissilesImage,
        },
        {
            value: 1,
            label: "WEAPON - DUAL CANNON",
            frontSrc: glowWeaponRightDualCannonImage,
        },
        {
            value: 2,
            label: "WEAPON - TACHYON LANCE",
            frontSrc: glowWeaponRightTachyonLanceImage,
        },
        {
            value: 3,
            label: "WEAPON - DISPLACER",
            frontSrc: glowWeaponRightDisplacerImage,
        },
        {
            value: 4,
            label: "WEAPON - LEFT FLAMETHROWER",
            frontSrc: glowWeaponLeftFlamethrowerImage,
        },
        {
            value: 5,
            label: "WEAPON - RIGHT FLAMETHROWER",
            frontSrc: glowWeaponRightFlamethrowerImage,
        },
        {
            value: 6,
            label: "WEAPON - DUAL FLAMETHROWERS",
            frontSrc: glowWeaponDualFlamethrowersImage,
        },
        {
            value: 7,
            label: "WEAPON - LEFT ANNIHILATOR",
            frontSrc: glowWeaponLeftAnnihilatorImage,
        },
        {
            value: 8,
            label: "WEAPON - RIGHT ANNIHILATOR",
            frontSrc: glowWeaponRightAnnihilatorImage,
        },
        {
            value: 9,
            label: "WEAPON - DUAL ANNIHILATORs",
            frontSrc: glowWeaponDualAnnihilatorsImage,
        },
        {
            value: 10,
            label: "CHASSIS - VENTS",
            src: glowChassisVentsImage,
        },
        {
            value: 11,
            label: "CHASSIS - TUBES",
            src: glowChassisTubesImage,
        },
        {
            value: 12,
            label: "CHASSIS - ALL",
            src: glowChassisAllImage,
        },
        {
            value: 13,
            label: "CHASSIS - PLATING",
            src: glowChassisPlatingImage,
        },
        {
            value: 14,
            label: "CHASSIS - FLAT",
            src: glowChassisAllFlatImage,
        },
        {
            value: 15,
            label: "DECAL - JAWS",
            frontSrc: glowChassisJawsImage,
        },
        {
            value: 16,
            label: "CHASSIS - GURREN ALL",
            frontSrc: glowChassisGurrenAllImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "DECAL - GURREN",
            frontSrc: glowChassisGurrenImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "DECAL - SMILEY HIGH",
            frontSrc: glowChassisSmileyHighImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "DECAL - SMILEY MID",
            frontSrc: glowChassisSmileyMidImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "DECAL - SENPAI 0w0",
            frontSrc: glowChassisSenpai0w0Image,
            isPremium: true,
        },
        {
            value: 21,
            label: "DECAL - SENPAI EYES",
            frontSrc: glowChassisSenpaiImage,
            isPremium: true,
        },
        {
            value: 22,
            label: "CHASSIS - TBK",
            src: glowChassisTBKImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 23,
            label: "CHASSIS - TBK FLAMES",
            src: glowChassisTBKFlamesImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 24,
            label: "CHASSIS - TBK ALL",
            src: glowChassisTBKAllImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "DECAL",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "DECAL",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
