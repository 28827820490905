import React, { lazy, Suspense } from "react";

import AppMain from "./AppMain";
import MenuContainer from "./components/Menu/MenuContainer";
import UserAuthContextWrapper from "./components/User/UserAuthContextWrapper";
import AnalyticsContextWrapper from "./components/Analytics/AnalyticsContextWrapper";
import ToggleHideUIContextWrapper from "./components/common/ToggleHideUIContextWrapper";
import UnitContextWrapper from "./components/Unit/UnitContextWrapper";
import ShowLandingContextWrapper from "./components/Landing/ShowLandingContextWrapper";
import GlobalStyleProvider from "./components/common/GlobalStyleProvider";
import AppMainBackgroundProvider from "./components/common/AppMainBackgroundProvider";
import ThemeContextWrapper from "./components/common/ThemeContextWrapper";
import SFXContextWrapper from "./components/Music/SFXContextWrapper";
import CustomColorContextWrapper from "./components/Unit/CustomColorContextWrapper";

const MainBackgroundContainer = lazy(() => import("./components/Main/MainBackgroundContainer"));
const MainHeader = lazy(() => import("./components/Main/MainHeader"));
const Copyright = lazy(() => import("./components/Main/Copyright"));
const UnitControlContainer = lazy(() => import("./components/Unit/UnitControlContainer"));

function App() {
    return (
        <ShowLandingContextWrapper>
            <ThemeContextWrapper>
                <AnalyticsContextWrapper>
                    <UserAuthContextWrapper>
                        <SFXContextWrapper>
                            <ToggleHideUIContextWrapper>
                                <AppMain>
                                    <Suspense fallback={null}>
                                        <MainHeader />
                                    </Suspense>

                                    <UnitContextWrapper>
                                        <CustomColorContextWrapper>
                                            <Suspense fallback={null}>
                                                <MainBackgroundContainer />
                                            </Suspense>

                                            <Suspense fallback={null}>
                                                <UnitControlContainer />
                                            </Suspense>

                                            <MenuContainer />
                                        </CustomColorContextWrapper>
                                    </UnitContextWrapper>

                                    <AppMainBackgroundProvider />

                                    <Suspense fallback={null}>
                                        <Copyright />
                                    </Suspense>

                                    <GlobalStyleProvider />
                                </AppMain>
                            </ToggleHideUIContextWrapper>
                        </SFXContextWrapper>
                    </UserAuthContextWrapper>
                </AnalyticsContextWrapper>
            </ThemeContextWrapper>
        </ShowLandingContextWrapper>
    );
}

export default App;
