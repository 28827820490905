import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/IPS/Vlad-2`;

// BODY
const bodyVladImage = `${imagePath}/Chassis - Vlad.png`;
const bodyAgonistImage = `${imagePath}/Chassis - Agonist.png`;
const bodySmoothImage = `${imagePath}/Chassis - Smooth Vlad.png`;

// HEADS
const headVladImage = `${imagePath}/heads/Head - Vlad.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_vlad 2.png`;
const headAgonistImage = `${imagePath}/heads/premium/Head - Agonist.png`;
// PREMIUM
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headChimeraImage = `${imagePath}/heads/premium/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/premium/Head - Cyclops.png`;
const headEnkiduImage = `${imagePath}/heads/premium/Head - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/premium/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/premium/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/premium/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/premium/Head - Malice.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headRainImage = `${imagePath}/heads/premium/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/premium/Head - Rain Hair.png`;
const headScopedogImage = `${imagePath}/heads/premium/Head - Scopedog.png`;
const headTokugawaImage = `${imagePath}/heads/premium/Head - Tokugawa.png`;

// 1H LEFT
const weaponFistLeft1HImage = `${imagePath}/weapons/left/Left Arm - Fist.png`;
const weaponNailgunLeft1HImage = `${imagePath}/weapons/left/Left Arm - Nailgun.png`;
const weaponCombatDrillLeft1HImage = `${imagePath}/weapons/left/Left Arm - Combat Drill.png`;
const weaponCombatDrillLinesLeft1HImage = `${imagePath}/weapons/left/Left Arm - Combat Drill - Lines.png`;
const weaponAgonistClawLeft1HImage = `${imagePath}/weapons/left/Left Arm - Agonist Claw.png`;

// 1H RIGHT
const weaponFistRight1HImage = `${imagePath}/weapons/right/Right Arm - Fist.png`;
const weaponNailgunRight1HImage = `${imagePath}/weapons/right/Right Arm - Nailgun.png`;
const weaponDD288Right1HImage = `${imagePath}/weapons/right/Right Arm - DD288.png`;
const weaponChargedStakeRight1HImage = `${imagePath}/weapons/right/Right Arm - Charged Stake.png`;
const weaponChargedStake2Right1HImage = `${imagePath}/weapons/right/Right Arm - Charged Stake 2.png`;
const weaponCombatDrillRight1HImage = `${imagePath}/weapons/right/Right Arm - Combat Drill.png`;
const weaponCombatDrillLinesRight1HImage = `${imagePath}/weapons/right/Right Arm - Combat Drill - Lines.png`;
const weaponAgonistClawRight1HImage = `${imagePath}/weapons/right/Right Arm - Agonist Claw.png`;

// INNER ARMS
const innerFistsImage = `${imagePath}/inner/Inner Arms - Vlad Hands.png`;
const innerNailgunImage = `${imagePath}/inner/Inner Arms - Nailgun.png`;
const innerSpearImage = `${imagePath}/inner/Inner Arms - Spear.png`;
const innerWebjawSnareImage = `${imagePath}/inner/Inner Arms - Webjaw Snare.png`;
const innerAgonistFistsImage = `${imagePath}/inner/Inner Arms - Agonist Hands.png`;

// GLOW
const accessoryShoulderWebjawsImage = `${imagePath}/rear mount/Accessory - Shoulder Webjaws.png`;
const glowVladEyeImage = `${imagePath}/glows/Glow 1 - Vlad Eye.png`;
const glowLeftChargedStakeImage = `${imagePath}/glows/Glow 2 - Left Charged Stake.png`;
const glowRightChargedStakeImage = `${imagePath}/glows/Glow 2 - Right Charged Stake.png`;
const glowRightChargedStake2Image = `${imagePath}/glows/Glow 2 - Right Charged Stake 2.png`;
const glowLeftCombatDrillImage = `${imagePath}/glows/Glow 2 - Left Combat Drill.png`;
const glowRightCombatDrillImage = `${imagePath}/glows/Glow 2 - Right Combat Drill.png`;
const glowDD288Image = `${imagePath}/glows/Glow 2 - DD288.png`;
const glowShoulderWebjawsImage = `${imagePath}/glows/Glow 2 - Shoulder Webjaws.png`;
const glowWebjawSnareImage = `${imagePath}/glows/Glow 2 - Webjaw Snare.png`;
const glowInnerAgonistClawsImage = `${imagePath}/glows/premium/Glow 1 - Inner Agonist Claws.png`;
const glowLeftAgonistClawsImage = `${imagePath}/glows/premium/Glow 2 - Left Agonist Claws.png`;
const glowRightAgonistClawsImage = `${imagePath}/glows/premium/Glow 2 - Right Agonist Claw.png`;
const glowAgonistHeadImage = `${imagePath}/glows/premium/Glow 1 - Agonist Head.png`;
const glowAgonistSpikesImage = `${imagePath}/glows/premium/Glow 1 - Agonist Spikes.png`;
const glowAgonistEnergyImage = `${imagePath}/glows/premium/Glow 1 - Agonist Energy.png`;
const glowAgonistAllImage = `${imagePath}/glows/premium/Glow 1 - Agonist All.png`;

const coreImage = `${imagePath}/CharlieMerlynn - Vlad_Agonist.png`;
const core2Image = `${imagePath}/CharlieMerlynn_Vlad_2.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "45px",
    right: "27px",
};

export default {
    previewImg: previewImage,
    accessory2IsChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerlynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerlynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "11",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "VLAD",
            src: headVladImage,
        },
        {
            value: 1,
            label: "AGONIST",
            src: headAgonistImage,
        },
        {
            value: 2,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 3,
            label: "BONEHEAD",
            src: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "ENKIDU",
            src: headEnkiduImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "RAIN HAIR",
            src: headRainHairImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "SCOPEDOG",
            src: headScopedogImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "TOKUGAWA",
            src: headTokugawaImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "VLAD",
            src: bodyVladImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "AGONIST",
            src: bodyAgonistImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Juan Gee",
                url: "https://twitter.com/juangeedraws",
            },
        },
        {
            value: 2,
            label: "SMOOTH VLAD",
            src: bodySmoothImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "FIST",
                src: weaponFistLeft1HImage,
            },
            {
                value: 1,
                label: "NAILGUN",
                src: weaponNailgunLeft1HImage,
            },
            {
                value: 2,
                label: "COMBAT SPIKE",
                src: weaponCombatDrillLeft1HImage,
            },
            {
                value: 3,
                label: "COMBAT DRILL",
                src: weaponCombatDrillLinesLeft1HImage,
            },
            {
                value: 4,
                label: "AGONIST CLAW",
                src: weaponAgonistClawLeft1HImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FIST",
            foremostSrc: weaponFistRight1HImage,
        },
        {
            value: 1,
            label: "NAILGUN",
            foremostSrc: weaponNailgunRight1HImage,
        },
        {
            value: 2,
            label: "DD288",
            foremostSrc: weaponDD288Right1HImage,
        },
        {
            value: 3,
            label: "CHARGED STAKE",
            foremostSrc: weaponChargedStakeRight1HImage,
        },
        {
            value: 4,
            label: "CHARGED STAKE 2",
            foremostSrc: weaponChargedStake2Right1HImage,
        },
        {
            value: 5,
            label: "COMBAT SPIKE",
            foremostSrc: weaponCombatDrillRight1HImage,
        },
        {
            value: 6,
            label: "COMBAT DRILL",
            foremostSrc: weaponCombatDrillLinesRight1HImage,
        },
        {
            value: 7,
            label: "AGONIST CLAW",
            foremostSrc: weaponAgonistClawRight1HImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "FISTS",
            foremostSrc: innerFistsImage,
        },
        {
            value: 1,
            label: "NAILGUN",
            foremostSrc: innerNailgunImage,
        },
        {
            value: 2,
            label: "SPEAR",
            foremostSrc: innerSpearImage,
        },
        {
            value: 3,
            label: "WEBJAW SNARE",
            foremostSrc: innerWebjawSnareImage,
        },
        {
            value: 4,
            label: "AGONIST FISTS",
            foremostSrc: innerAgonistFistsImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "SHOULDER WEBJAWS - ACCESSORY",
            src: accessoryShoulderWebjawsImage,
        },
        {
            value: 1,
            label: "CHARGED STAKE LEFT",
            frontSrc: glowLeftChargedStakeImage,
        },
        {
            value: 2,
            label: "CHARGED STAKE RIGHT",
            foremostSrc: glowRightChargedStakeImage,
        },
        {
            value: 3,
            label: "CHARGED STAKE RIGHT 2",
            foremostSrc: glowRightChargedStake2Image,
        },
        {
            value: 4,
            label: "DUAL CHARGED STAKES",
            frontSrc: glowLeftChargedStakeImage,
            foremostSrc: glowRightChargedStakeImage,
        },
        {
            value: 5,
            label: "COMBAT DRILL LEFT",
            frontSrc: glowLeftCombatDrillImage,
        },
        {
            value: 6,
            label: "COMBAT DRILL RIGHT",
            foremostSrc: glowRightCombatDrillImage,
        },
        {
            value: 7,
            label: "DUAL COMBAT DRILLS",
            frontSrc: glowLeftCombatDrillImage,
            foremostSrc: glowRightCombatDrillImage,
        },
        {
            value: 8,
            label: "WEBJAW SNARE",
            foremostSrc: glowWebjawSnareImage,
        },
        {
            value: 9,
            label: "SHOULDER WEBJAWS",
            frontSrc: glowShoulderWebjawsImage,
        },
        {
            value: 10,
            label: "DD288",
            foremostSrc: glowDD288Image,
        },
        {
            value: 11,
            label: "VLAD EYE",
            frontSrc: glowVladEyeImage,
        },
        {
            value: 12,
            label: "AGONIST CLAWS INNER",
            foremostSrc: glowInnerAgonistClawsImage,
        },
        {
            value: 13,
            label: "AGONIST CLAWS LEFT",
            frontSrc: glowLeftAgonistClawsImage,
        },
        {
            value: 14,
            label: "AGONIST CLAWS RIGHT",
            foremostSrc: glowRightAgonistClawsImage,
        },
        {
            value: 15,
            label: "DUAL AGONIST CLAWS",
            frontSrc: glowLeftAgonistClawsImage,
            foremostSrc: glowRightAgonistClawsImage,
        },
        {
            value: 16,
            label: "AGONIST HEAD",
            frontSrc: glowAgonistHeadImage,
        },
        {
            value: 17,
            label: "AGONIST SPIKES",
            src: glowAgonistSpikesImage,
        },
        {
            value: 18,
            label: "AGONIST ENERGY",
            src: glowAgonistEnergyImage,
        },
        {
            value: 19,
            label: "AGONIST ALL",
            src: glowAgonistAllImage,
            frontSrc: glowLeftAgonistClawsImage,
            foremostSrc: glowRightAgonistClawsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR LEFT",
            value: "weapon",
        },
        {
            label: "REAR RIGHT",
            value: "secondaryWeapon",
        },
        {
            label: "INNER",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "INNER",
        "weapon": "REAR LEFT",
        "secondaryWeapon": "REAR RIGHT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 2",
        "accessory4": "GLOW 3",
    },
};
