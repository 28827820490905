import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyPegasusExtrudedImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/chassis.png`;

// WEAPONS
// LEFT
const weaponLeftCarnivoreCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/left/Left Weapon - Carnivore Cannon.png`;
const weaponLeftHeavyCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/left/Left Weapon - Heavy Cannon.png`;
const weaponLeftJaegerCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/left/Left Weapon - Jaeger Cannon.png`;
const weaponLeftPenetratorCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/left/Left Weapon - Penetrator Cannon.png`;
const weaponLeftTempestCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/left/Left Weapon - Tempest Cannon.png`;

// RIGHT
const weaponRightCarnivoreCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/right/Right Weapon - Carnivore Cannon.png`;
const weaponRightHeavyCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/right/Right Weapon - Heavy Cannon.png`;
const weaponRightJaegerCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/right/Right Weapon - Jaeger Cannon Cannon.png`;
const weaponRightPenetratorCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/right/Right Weapon - Penetrator Cannon.png`;
const weaponRightTempestCannonImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/weapons/right/Right Weapon - Tempest Cannon.png`;

// HEAVY
const weaponHeavyMimicGunImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/accessories/Mimic Gun.png`;

// GLOWS
const glowOrbChassisImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/glows/Glows.png`;

// ACCESSORIES
const accessoriesOrbImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/accessories/ORB.png`;

const previewImage = `${BaseURL}/Mechs/Horus/Pegasus-2-Extruded/preview.png`;

const quadLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "64px",
    right: "26px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "0",
        "weaponValue": "2",
        "secondaryWeaponValue": "2",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "1",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "PEGASUS 2.0 EXTRUDED",
            src: bodyPegasusExtrudedImage,
            shadowDetails: quadLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CARNIVORE CANNON",
                backSrc: weaponLeftCarnivoreCannonImage,
            },
            {
                value: 1,
                label: "HEAVY CANNON",
                backSrc: weaponLeftHeavyCannonImage,
            },
            {
                value: 2,
                label: "JAEGER CANNON",
                backSrc: weaponLeftJaegerCannonImage,
            },
            {
                value: 3,
                label: "PENETRATOR CANNON",
                backSrc: weaponLeftPenetratorCannonImage,
            },
            {
                value: 4,
                label: "TEMPEST CANNON",
                backSrc: weaponLeftTempestCannonImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CARNIVORE CANNON",
            frontSrc: weaponRightCarnivoreCannonImage,
        },
        {
            value: 1,
            label: "HEAVY CANNON",
            frontSrc: weaponRightHeavyCannonImage,
        },
        {
            value: 2,
            label: "JAEGER CANNON",
            frontSrc: weaponRightJaegerCannonImage,
        },
        {
            value: 3,
            label: "PENETRATOR CANNON",
            frontSrc: weaponRightPenetratorCannonImage,
        },
        {
            value: 4,
            label: "TEMPEST CANNON",
            frontSrc: weaponRightTempestCannonImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "MIMIC GUN",
            frontSrc: weaponHeavyMimicGunImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "ORB",
            src: accessoriesOrbImage,
        },
        {
            value: 1,
            label: "GLOW ORB + CHASSIS",
            src: glowOrbChassisImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "HEAVY WEAPON",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "HEAVY WEAPON",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
