import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/DFG Wrath`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis.png`;

// HEADS
const headWrathImage = `${imagePath}/heads/Head - Wrath.png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;
const headBastionImage = `${imagePath}/heads/Head - 8 Bastion.png`;
const headBlackspotImage = `${imagePath}/heads/Head - 8 Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCockpitImage = `${imagePath}/heads/Head - Cockpit.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNelson2Image = `${imagePath}/heads/Head - Nelson 2.png`;
const headNelsonFTImage = `${imagePath}/heads/Head - Nelson Flight Type.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSlipstreamImage = `${imagePath}/heads/Head - Slipstream.png`;
const headTokugawaImage = `${imagePath}/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// LEFT
const weaponLeftHMGImage = `${imagePath}/weapons/Left Arm - HMG.png`;
const weaponLeftHMGUnderImage = `${imagePath}/weapons/Left Arm - HMG under.png`;
const weaponLeftWhirlwindBladeImage = `${imagePath}/weapons/Left Arm - Whirlwind Blade.png`;

// RIGHT
const weaponRightHMGImage = `${imagePath}/weapons/Right Arm - HMG.png`;
const weaponRightHMGUnderImage = `${imagePath}/weapons/Right Arm - HMG under.png`;
const weaponRightFirebrandLaserImage = `${imagePath}/weapons/Right Arm - Firebrand Laser.png`;
const weaponRightWhirlwindBladeImage = `${imagePath}/weapons/Right Arm - Whirlwind Blade.png`;
const weaponRightWhirlwindBladeReverseImage = `${imagePath}/weapons/Right Arm - Whirlwind Blade Reverse.png`;
const weaponBothHMGUnderImage = `${imagePath}/weapons/Both Arm - HMG under.png`;

// REAR MOUNT
// LEFT
const shoulderLeftHMGImage = `${imagePath}/rear mounts/Left Shoulder - HMG.png`;
const shoulderLeftLaserCannonImage = `${imagePath}/rear mounts/Left Shoulder - Laser Cannon.png`;
const shoulderLeftShockwaveCannonImage = `${imagePath}/rear mounts/Left Shoulder - Shockwave Cannon.png`;
const shoulderLeftWrathArmorImage = `${imagePath}/rear mounts/Left Shoulder - Wrath Armor.png`;
const shoulderLeftWrathArmorBothImage = `${imagePath}/rear mounts/Left Shoulder - Wrath Armor both.png`;

// RIGHT
const shoulderRightHMGImage = `${imagePath}/rear mounts/Right Shoulder - HMG.png`;
const shoulderRightBladeImage = `${imagePath}/rear mounts/Right Shoulder - Blade.png`;
const shoulderRightDualBladesEmptyImage = `${imagePath}/rear mounts/Right Shoulder - Dual Blades - 1 empty.png`;
const shoulderRightDualBladesImage = `${imagePath}/rear mounts/Right Shoulder - Dual Blades.png`;
const shoulderRightDualScabbardsImage = `${imagePath}/rear mounts/Right Shoulder - Dual scabbards.png`;
const shoulderRightLaserCannonImage = `${imagePath}/rear mounts/Right Shoulder - Laser Cannon.png`;
const shoulderRightShockwaveCannonImage = `${imagePath}/rear mounts/Right Shoulder - Shockwave Cannon.png`;
const shoulderRightWrathArmorImage = `${imagePath}/rear mounts/Right Shoulder - Wrath Armor.png`;
const shoulderRightWrathArmorBothImage = `${imagePath}/rear mounts/Right Shoulder - Wrath Armor both.png`;

// GLOWS
const glowChassisImage = `${imagePath}/glows/Glow Mid - Chassis.png`;
const glowEyeImage = `${imagePath}/glows/Glow Top - Eye.png`;
const glowFirebrandLaserImage = `${imagePath}/glows/Glow Top - Firebrand Laser.png`;
const glowLaserCannonleftImage = `${imagePath}/glows/Glow Top - L Laser Cannon.png`;
const glowLaserCannonRightImage = `${imagePath}/glows/Glow Top - R Laser Cannon.png`;
// const glowLaserCannonsDualImage = `${imagePath}/glows/Glow Top - Dual Laser Cannons.png`;
const glowShockwaveCannonLeftImage = `${imagePath}/glows/Glow Top - L Shockwave Cannon.png`;
const glowShockwaveCannonRightImage = `${imagePath}/glows/Glow Top - R Shockwave Cannon.png`;
// const glowShockwaveCannonsDualImage = `${imagePath}/glows/Glow Top - Dual Shockwave Cannons.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image = `${imagePath}/DFG_WRATH_core_AzMiLion.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "27px",
    right: "-16px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "3",
        "weaponValue": "13",
        "secondaryWeaponValue": "7",
        "accessory1Value": "1",
        "accessory2Value": "0",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "WRATH",
            src: headWrathImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
            isPremium: true,
        },
        {
            value: 2,
            label: "BASTION",
            src: headBastionImage,
            isPremium: true,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CHEDDAH",
            src: headCheddahImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "COCKPIT",
            src: headCockpitImage,
            isPremium: true,
        },
        {
            value: 8,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 9,
            label: "DUNGAM",
            src: headDungamImage,
            isPremium: true,
        },
        {
            value: 10,
            label: "ENKIDU",
            src: headEnkiduImage,
            isPremium: true,
        },
        {
            value: 11,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 12,
            label: "HORNED HAIR",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "KAZU",
            src: headKazuImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "MAG",
            src: headMagImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "MECHA 1",
            src: headMecha1Image,
            isPremium: true,
        },
        {
            value: 18,
            label: "MECHA 2",
            src: headMecha2Image,
            isPremium: true,
        },
        {
            value: 19,
            label: "MECHA 3",
            src: headMecha3Image,
            isPremium: true,
        },
        {
            value: 20,
            label: "MECHA 4",
            src: headMecha4Image,
            isPremium: true,
        },
        {
            value: 21,
            label: "MECHA 5",
            src: headMecha5Image,
            isPremium: true,
        },
        {
            value: 22,
            label: "MECHA 6",
            src: headMecha6Image,
            isPremium: true,
        },
        {
            value: 23,
            label: "MECHA 7",
            src: headMecha7Image,
            isPremium: true,
        },
        {
            value: 24,
            label: "MECHA 8",
            src: headMecha8Image,
            isPremium: true,
        },
        {
            value: 25,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 26,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 27,
            label: "NELSON",
            src: headNelsonImage,
            isPremium: true,
        },
        {
            value: 28,
            label: "NELSON 2.0",
            src: headNelson2Image,
            isPremium: true,
        },
        {
            value: 29,
            label: "NELSON FLIGHT-TYPE",
            src: headNelsonFTImage,
            isPremium: true,
        },
        {
            value: 30,
            label: "NEMESIS",
            src: headNemesisImage,
            isPremium: true,
        },
        {
            value: 31,
            label: "RAIN",
            src: headRainImage,
            isPremium: true,
        },
        {
            value: 32,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 33,
            label: "SLIPTSTREAM",
            src: headSlipstreamImage,
            isPremium: true,
        },
        {
            value: 34,
            label: "TOKUGAWA",
            src: headTokugawaImage,
            isPremium: true,
        },
        {
            value: 35,
            label: "VANQUISHER",
            src: headVanquisherImage,
            isPremium: true,
        },
        {
            value: 36,
            label: "VICEROY",
            src: headViceroyImage,
            isPremium: true,
        },
        {
            value: 37,
            label: "VISIONARY",
            src: headVisionaryImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "WRATH",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by ",
                title: "Pysbomb",
                url: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "NONE - HMG",
                foremostSrc: weaponRightHMGImage,
                backSrc: weaponRightHMGUnderImage,
            },
            {
                value: 1,
                label: "NONE - FIREBRAND LASER",
                frontSrc: weaponRightFirebrandLaserImage,
            },
            {
                value: 2,
                label: "NONE - WHIRLWIND BLADE",
                frontSrc: weaponRightWhirlwindBladeImage,
            },
            {
                value: 3,
                label: "NONE - WHIRLWIND BLADE REVERSE",
                frontSrc: weaponRightWhirlwindBladeReverseImage,
            },
            {
                value: 4,
                label: "HMG - NONE",
                frontSrc: weaponLeftHMGImage,
                backSrc: weaponBothHMGUnderImage,
            },
            {
                value: 5,
                label: "HMG - HMG",
                foremostSrc: weaponRightHMGImage,
                frontSrc: weaponLeftHMGImage,
                backSrc: weaponBothHMGUnderImage,
            },
            {
                value: 6,
                label: "HMG - FIREBRAND LASER",
                frontSrc: weaponRightFirebrandLaserImage,
                src: weaponLeftHMGImage,
                backsrc: weaponLeftHMGUnderImage,
            },
            {
                value: 7,
                label: "HMG - WHIRLWIND BLADE",
                frontSrc: weaponRightWhirlwindBladeImage,
                src: weaponLeftHMGImage,
                backSrc: weaponLeftHMGUnderImage,
            },
            {
                value: 8,
                label: "HMG - WHIRLWIND BLADE REVERSE",
                frontSrc: weaponRightWhirlwindBladeReverseImage,
                src: weaponLeftHMGImage,
                backSrc: weaponLeftHMGUnderImage,
            },
            {
                value: 9,
                label: "WHIRLWIND BLADE - NONE",
                src: weaponLeftWhirlwindBladeImage,
                backSrc: weaponRightHMGUnderImage,
            },
            {
                value: 10,
                label: "WHIRLWIND BLADE - HMG",
                frontSrc: weaponRightHMGImage,
                src: weaponLeftWhirlwindBladeImage,
                backSrc: weaponRightHMGUnderImage,
            },
            {
                value: 11,
                label: "WHIRLWIND BLADE - FIREBRAND LASER",
                src: weaponRightFirebrandLaserImage,
                backSrc: weaponLeftWhirlwindBladeImage,
            },
            {
                value: 12,
                label: "WHIRLWIND BLADE - WHIRLWIND BLADE",
                src: weaponRightWhirlwindBladeImage,
                backSrc: weaponLeftWhirlwindBladeImage,
            },
            {
                value: 13,
                label: "WHIRLWIND BLADE - WHIRLWIND BLADE REVERSE",
                src: weaponRightWhirlwindBladeReverseImage,
                backSrc: weaponLeftWhirlwindBladeImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HMG",
            src: shoulderRightHMGImage,
        },
        {
            value: 1,
            label: "BLADE",
            src: shoulderRightBladeImage,
        },
        {
            value: 2,
            label: "DUAL BLADES",
            src: shoulderRightDualBladesImage,
        },
        {
            value: 3,
            label: "DUAL BLADES - 1 EMPTY",
            src: shoulderRightDualBladesEmptyImage,
        },
        {
            value: 4,
            label: "DUAL SCABBARDS",
            src: shoulderRightDualScabbardsImage,
        },
        {
            value: 5,
            label: "LASER CANNON",
            src: shoulderRightLaserCannonImage,
        },
        {
            value: 6,
            label: "SHOCKWAVE CANNON",
            src: shoulderRightShockwaveCannonImage,
        },
        {
            value: 7,
            label: "WRATH ARMOR",
            frontSrc: shoulderRightWrathArmorImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: 'HMG',
            frontSrc: shoulderLeftHMGImage,
        },
        {
            value: 1,
            label: 'LASER CANNON',
            frontSrc: shoulderLeftLaserCannonImage,
        },
        {
            value: 2,
            label: 'SHOCKWAVE CANNON',
            frontSrc: shoulderLeftShockwaveCannonImage,
        },
        {
            value: 3,
            label: 'WRATH ARMOR',
            frontSrc: shoulderLeftWrathArmorImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "WRATH EYE",
            frontSrc: glowEyeImage,
        },
        {
            value: 2,
            label: "FIREBRAND LASER",
            frontSrc: glowFirebrandLaserImage,
        },
        {
            value: 3,
            label: "LASER CANNON - LEFT",
            frontSrc: glowLaserCannonleftImage,
        },
        {
            value: 4,
            label: "LASER CANNON - RIGHT",
            foremostSrc: glowLaserCannonRightImage,
        },
        {
            value: 5,
            label: "LASER CANNON - DUAL",
            foremostSrc: glowLaserCannonRightImage,
            frontSrc: glowLaserCannonleftImage,
        },
        {
            value: 6,
            label: "SHOCKWAVE CANNON - LEFT",
            frontSrc: glowShockwaveCannonLeftImage,
        },
        {
            value: 7,
            label: "SHOCKWAVE CANNON - RIGHT",
            foremostSrc: glowShockwaveCannonRightImage,
        },
        {
            value: 8,
            label: "SHOCKWAVE CANNON - DUAL",
            foremostSrc: glowShockwaveCannonRightImage,
            frontSrc: glowShockwaveCannonLeftImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L SHOULDER",
            value: "rearMount",
        },
        {
            label: "WEAPONS",
            value: "weapon",
        },
        {
            label: "R SHOULDER",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "L SHOULDER",
        "weapon": "WEAPONS",
        "secondaryWeapon": "R SHOULDER",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
