export const OPTIONS = {
    // Branded / GMS / NPC Common
    "weapon": "weapon",
    "secondaryWeapon": "secondaryWeapon",
    "chassis": "chassis",

    // NPC Specific
    "camo": "camo",

    // Branded / GMS / Hero Common
    "head": "head",
    "accessory": "accessory",

    // Branded / GMS Common
    "rearMount": "rearMount",

    // Hero / GMS Common
    "legs": "legs",

    // GMS Specific
    "arms": "arms",
    "armLeft": "armLeft",
    "armRight": "armRight",

    // Hero Specific
    "skin": "skin",
    "face": "face",
    "eyes": "eyes",
    "hair": "hair",
    "facialHair": "facialHair",
    "chest": "chest",
    "leftArm": "leftArm",
    "rightArm": "rightArm",
    "leftWeapon": "leftWeapon",
    "rightWeapon": "rightWeapon",
};

export const LAYERS = {
    "foremostSrc": "foremostSrc",
    "frontSrc": "frontSrc",
    "midSrc": "midSrc",
    "src": "src",
    "altSrc": "altSrc", // GMS only
    "tattooSrc": "tattooSrc", // Hero only
    "backSrc": "backSrc",
    "backmostSrc": "backmostSrc",
};
