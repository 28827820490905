import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Pink-Witch`;

// BODY
const bodyChassisImage = `${imagePath}/Pink Witch_Chassis.png`;

// HEADS
const headPinkWitchImage = `${imagePath}/heads/Pink Witch_Head_Pink Witch.png`;
const headBalorImage = `${imagePath}/heads/Pink Witch_Head_Balor.png`;
const headBlackWitchImage = `${imagePath}/heads/Pink Witch_Head_Black Witch.png`;
const headCircletImage = `${imagePath}/heads/Pink Witch_Head_Circlet.png`;
const headCyclopsImage = `${imagePath}/heads/Pink Witch_Head_Cyclops.png`;
const headHornedHairImage = `${imagePath}/heads/Pink Witch_Head_Horned Hair.png`;
const headHornedImage = `${imagePath}/heads/Pink Witch_Head_Horned.png`;
const headJudicatorImage = `${imagePath}/heads/Pink Witch_Head_Judicator.png`;
const headKazuImage = `${imagePath}/heads/Pink Witch_Head_Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Pink Witch_Head_Laborer.png`;
const headMagnesImage = `${imagePath}/heads/Pink Witch_Head_Magnes.png`;
const headMaliceImage = `${imagePath}/heads/Pink Witch_Head_Malice.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Pink Witch_Head_Natural Law Hair.png`;
const headNaturalLawImage = `${imagePath}/heads/Pink Witch_Head_Natural Law.png`;
const headOni1HairImage = `${imagePath}/heads/Pink Witch_Head_Oni 1 Hair.png`;
const headOni1Image = `${imagePath}/heads/Pink Witch_Head_Oni 1.png`;
const headOni2HairImage = `${imagePath}/heads/Pink Witch_Head_Oni 2 Hair.png`;
const headOni2Image = `${imagePath}/heads/Pink Witch_Head_Oni 2.png`;
const headPredatorImage = `${imagePath}/heads/Pink Witch_Head_Predator.png`;
const headRainTransformedImage = `${imagePath}/heads/Pink Witch_Head_Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Pink Witch_Head_Rain Transformed Hair.png`;
const headSuccubusImage = `${imagePath}/heads/Pink Witch_Head_Succubus.png`;
const headTwintailsBackImage = `${imagePath}/heads/Pink Witch_Head_Twintails Back.png`;
const headTwintailsLongBackImage = `${imagePath}/heads/Pink Witch_Head_Twintails Long Back.png`;
const headTwintailsLongImage = `${imagePath}/heads/Pink Witch_Head_Twintails Long.png`;
const headTwintailsImage = `${imagePath}/heads/Pink Witch_Head_Twintails.png`;
const headUrchinImage = `${imagePath}/heads/Pink Witch_Head_Urchin.png`;
const headVanguardImage = `${imagePath}/heads/Pink Witch_Head_Vanguard.png`;
const headWhiteWitchImage = `${imagePath}/heads/Pink Witch_Head_ White Witch.png`;

// WEAPONS
// LEFT
const leftWeaponHandOnHipImage = `${imagePath}/weapons/Pink Witch_Left Arm_ Hand on Hip.png`;
const leftWeaponClawsImage = `${imagePath}/weapons/Pink Witch_Left Arm_ Claws.png`;
const leftWeaponClawsExtendedImage = `${imagePath}/weapons/Pink Witch_Left Arm_ Claws Extended.png`;
const leftWeaponLasherImage = `${imagePath}/weapons/Pink Witch_Left Arm_ Lasher.png`;
const leftWeaponMorningStarImage = `${imagePath}/weapons/Pink Witch_Left Arm_ Morning Star.png`;
const leftWeaponRetortLoopImage = `${imagePath}/weapons/Pink Witch_Left Arm_ Retort Loop.png`;

// RIGHT
const rightWeaponAssaultRifleImage = `${imagePath}/weapons/Pink Witch_Right Arm_Assault Rifle.png`;
const rightWeaponFerrousGlaiveImage = `${imagePath}/weapons/Pink Witch_Right Arm_Ferrous Glaive.png`;
const rightWeaponFerrousLashImage = `${imagePath}/weapons/Pink Witch_Right Arm_Ferrous Lash.png`;
const rightWeaponFerrousPoleImage = `${imagePath}/weapons/Pink Witch_Right Arm_Ferrous Pole.png`;
const rightWeaponHammerImage = `${imagePath}/weapons/Pink Witch_Right Arm_Hammer.png`;
const rightWeaponLasherImage = `${imagePath}/weapons/Pink Witch_Right Arm_Lasher.png`;
const rightWeaponLeakImage = `${imagePath}/weapons/Pink Witch_Right Arm_Leak.png`;
const rightWeaponNanowhipImage = `${imagePath}/weapons/Pink Witch_Right Arm_Nanowhip.png`;
const rightWeaponNanowhipBackImage = `${imagePath}/weapons/Pink Witch_Right Arm_Nanowhip Back.png`;

// REAR MOUNT
const rearMountFerrousLocksImage = `${imagePath}/rear-mount/Pink Witch_Accessories Front_Ferrous Locks.png`;
const rearMountFerrousLocksOniImage = `${imagePath}/rear-mount/Pink Witch_Accessories Front_Ferrous Locks Oni.png`;
const rearMountFerrousLocksSuccubusImage = `${imagePath}/rear-mount/Pink Witch_Accessories Front_Ferrous Locks Succubus.png`;
const rearMounterrousLocksBackImage = `${imagePath}/rear-mount/Pink Witch_Accessories Back_Ferrous Locks.png`;

// ACCESSORIES
const accessoryMidNaniteCloakImage = `${imagePath}/accessories/Pink Witch_Accessories Mid_Nanite Cloak.png`;
const accessoryFrontFerrofluidReservesImage = `${imagePath}/accessories/Pink Witch_Accessories Front_Ferrofluid Reserves.png`;
const accessoryMidBattleSkirtImage = `${imagePath}/accessories/Pink Witch_Accessories Mid_Battle Skirt.png`;
const accessoryMidFerrousArmorImage = `${imagePath}/accessories/Pink Witch_Accessories Mid_Ferrous Armor.png`;
const accessoryMidMiniskirtImage = `${imagePath}/accessories/Pink Witch_Accessories Mid_Miniskirt.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "35px",
    right: "74px",
};

export default {
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "3",
        "secondaryWeaponValue": "0",
        "accessory1Value": "2",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "PINK WITCH",
            src: headPinkWitchImage,
        },
        {
            value: 1,
            label: "BALOR",
            src: headBalorImage,
        },
        {
            value: 2,
            label: "BLACK WITCH",
            src: headBlackWitchImage,
        },
        {
            value: 3,
            label: "CIRCLET",
            src: headCircletImage,
        },
        {
            value: 4,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 5,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 6,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 7,
            label: "JUDICATOR",
            src: headJudicatorImage,
        },
        {
            value: 8,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 9,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 10,
            label: "MAGNES",
            src: headMagnesImage,
        },
        {
            value: 11,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 12,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 13,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 14,
            label: "ONI 1",
            src: headOni1Image,
        },
        {
            value: 15,
            label: "ONI 1 HAIR",
            src: headOni1HairImage,
        },
        {
            value: 16,
            label: "ONI 2",
            src: headOni2Image,
        },
        {
            value: 17,
            label: "ONI 2 HAIR",
            src: headOni2HairImage,
        },
        {
            value: 18,
            label: "PREDATOR",
            src: headPredatorImage,
        },
        {
            value: 19,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 20,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 21,
            label: "SUCCUBUS",
            src: headSuccubusImage,
        },
        {
            value: 22,
            label: "TWINTAILS",
            src: headTwintailsImage,
            backSrc: headTwintailsBackImage,
        },
        {
            value: 23,
            label: "TWINTAILS LONG",
            src: headTwintailsLongImage,
            backSrc: headTwintailsLongBackImage,
        },
        {
            value: 24,
            label: "URCHIN",
            src: headUrchinImage,
        },
        {
            value: 25,
            label: "VANGUARD",
            src: headVanguardImage,
        },
        {
            value: 26,
            label: "WHITE WITCH",
            src: headWhiteWitchImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "PINK WITCH",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ASSAULT RIFLE",
                src: rightWeaponAssaultRifleImage,
            },
            {
                value: 1,
                label: "FERROUS GLAIVE",
                src: rightWeaponFerrousGlaiveImage,
            },
            {
                value: 2,
                label: "FERROUS LASH",
                src: rightWeaponFerrousLashImage,
            },
            {
                value: 3,
                label: "FERROUS POLE",
                src: rightWeaponFerrousPoleImage,
            },
            {
                value: 4,
                label: "HAMMER",
                src: rightWeaponHammerImage,
            },
            {
                value: 5,
                label: "LASHER",
                src: rightWeaponLasherImage,
            },
            {
                value: 6,
                label: "LEEK",
                src: rightWeaponLeakImage,
            },
            {
                value: 7,
                label: "NANOWHIP",
                src: rightWeaponNanowhipImage,
                backSrc: rightWeaponNanowhipBackImage
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND ON HIP",
            src: leftWeaponHandOnHipImage,
        },
        {
            value: 1,
            label: "CLAWS",
            src: leftWeaponClawsImage,
        },
        {
            value: 2,
            label: "CLAWS EXTENDED",
            src: leftWeaponClawsExtendedImage,
        },
        {
            value: 3,
            label: "LASHER",
            src: leftWeaponLasherImage,
        },
        {
            value: 4,
            label: "MORNING STAR",
            src: leftWeaponMorningStarImage,
        },
        {
            value: 5,
            label: "RETORT LOOP",
            src: leftWeaponRetortLoopImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "PINK WITCH",
            foremostSrc: rearMountFerrousLocksImage,
        },
        {
            value: 1,
            label: "PINK WITCH - FULL",
            foremostSrc: rearMountFerrousLocksImage,
            backSrc: rearMounterrousLocksBackImage,
        },
        {
            value: 2,
            label: "ONI",
            foremostSrc: rearMountFerrousLocksOniImage,
        },
        {
            value: 3,
            label: "ONI - FULL",
            foremostSrc: rearMountFerrousLocksOniImage,
            backSrc: rearMounterrousLocksBackImage,
        },
        {
            value: 4,
            label: "SUCCUBUS",
            foremostSrc: rearMountFerrousLocksSuccubusImage,
        },
        {
            value: 5,
            label: "SUCCUBUS - FULL",
            foremostSrc: rearMountFerrousLocksSuccubusImage,
            backSrc: rearMounterrousLocksBackImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FERROFLUID RESERVES",
            frontSrc: accessoryFrontFerrofluidReservesImage,
        },
        {
            value: 1,
            label: "BATTLE SKIRT",
            src: accessoryMidBattleSkirtImage,
        },
        {
            value: 2,
            label: "FERROUS ARMOR",
            src: accessoryMidFerrousArmorImage,
        },
        {
            value: 3,
            label: "MINISKIRT",
            src: accessoryMidMiniskirtImage,
        },
        {
            value: 4,
            label: "NANITE CLOAK",
            src: accessoryMidNaniteCloakImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "FERROUS LOCKS",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "FERROUS LOCKS",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
