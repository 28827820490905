import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/IPS-N-Bligh`;

// BODY
const bodyShipImage = `${imagePath}/Chassis 1 - Ship.png`;
const bodySubImage = `${imagePath}/Chassis 1 - Sub.png`;

// LEGS
const legsBipedImage = `${imagePath}/legs/Legs - Biped.png`;
const legsHexapodImage = `${imagePath}/legs/Legs - Hexapod.png`;
const legsHexapodUnderImage = `${imagePath}/legs/Legs - Hexapod Under.png`;
const legsHoverImage = `${imagePath}/legs/Legs - Hover.png`;
const legsHover2Image = `${imagePath}/legs/Legs - Hover 2.png`;
const legsWheelsOffroadImage = `${imagePath}/legs/Legs - Wheels - Offroad.png`;
const legsWheelsSmoothImage = `${imagePath}/legs/Legs - Wheels - Smooth.png`;

// MOUNTS
// LEFT
const mountLeftCannonImage = `${imagePath}/mounts/left/Left Mount - Cannon.png`;
const mountLeftCraneImage = `${imagePath}/mounts/left/Left Mount - Crane.png`;
const mountLeftGatlingImage = `${imagePath}/mounts/left/Left Mount - Gatling.png`;
const mountLeftMissilesImage = `${imagePath}/mounts/left/Left Mount - Missiles.png`;
const mountLeftSmokeLauncherImage = `${imagePath}/mounts/left/Left Mount - Smoke Launcher.png`;

// RIGHT
const mountRightCannonImage = `${imagePath}/mounts/right/Right Mount - Cannon.png`;
const mountRightCraneHighImage = `${imagePath}/mounts/right/Right Mount - Crane High.png`;
const mountRightCraneLowImage = `${imagePath}/mounts/right/Right Mount - Crane Low.png`;
const mountRightGatlingImage = `${imagePath}/mounts/right/Right Mount - Gatling.png`;
const mountRightMissilesImage = `${imagePath}/mounts/right/Right Mount - Missiles.png`;
const mountRightSmokeLaunchersImage = `${imagePath}/mounts/right/Right Mount - Smoke Launchers.png`;

// ACCESSORIES / CREW
const accessoryPirateSkullLiveryImage = `${imagePath}/accessory/Livery - Pirate Skull.png`;
const crewWheelsImage = `${imagePath}/crew/Crew - Wheels.png`;
const crewBipedImage = `${imagePath}/crew/Crew - Biped.png`;
const crewHexapodImage = `${imagePath}/crew/Crew - Hexapod.png`;
const crewShipImage = `${imagePath}/crew/Crew - Ship.png`;

// GLOWS
const glowCraneHighImage = `${imagePath}/glows/Glow - Crane High.png`;
const glowCraneLowImage = `${imagePath}/glows/Glow - Crane Low.png`;
const glowCrewBipedImage = `${imagePath}/glows/Glow - Crew - Biped.png`;
const glowCrewHexapodImage = `${imagePath}/glows/Glow - Crew - Hexapod.png`;
const glowCrewShipImage = `${imagePath}/glows/Glow - Crew - Ship.png`;
const glowCrewWheelsImage = `${imagePath}/glows/Glow - Crew - Wheels.png`;
const glowHoverImage = `${imagePath}/glows/Glow - Hover.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/bligh_core_paintjob_by_snipertoaster.png`;
const core2Image = `${imagePath}/bligh_one_drone_by_snipertoaster.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "69px",
    right: "39px",
};

const originalArtCredit = {
    prefixText: "Commissioned by Ralf Ziegler. Original design by ",
    title: "Franceso Silva",
    url: "https://twitter.com/francescolobo?lang=en",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "1",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "2",
        "accessory1Value": "2",
        "accessory2Value": "1",
        "accessory3Value": "8",
        "accessory4Value": "7",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BIPED",
            src: legsBipedImage,
        },
        {
            value: 1,
            label: "HEXAPOD",
            src: legsHexapodImage,
            backSrc: legsHexapodUnderImage,
        },
        {
            value: 2,
            label: "HOVER",
            src: legsHoverImage,
        },
        {
            value: 3,
            label: "HOVER 2",
            src: legsHover2Image,
        },
        {
            value: 4,
            label: "WHEELS - OFFROAD",
            src: legsWheelsOffroadImage,
        },
        {
            value: 5,
            label: "WHEELS - SMOOTH",
            src: legsWheelsSmoothImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "IPS-N BLIGH - SHIP",
            src: bodyShipImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
        {
            value: 1,
            label: "IPS-N BLIGH - SUB",
            src: bodySubImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CANNON",
                src: mountLeftCannonImage,
            },
            {
                value: 1,
                label: "CRANE",
                src: mountLeftCraneImage,
            },
            {
                value: 2,
                label: "GATLING",
                src: mountLeftGatlingImage,
            },
            {
                value: 3,
                label: "MISSILES",
                src: mountLeftMissilesImage,
            },
            {
                value: 4,
                label: "SMOKE LAUNCHER",
                src: mountLeftSmokeLauncherImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "CANNON",
            src: mountRightCannonImage,
        },
        {
            value: 1,
            label: "CRANE - HIGH",
            frontSrc: mountRightCraneHighImage,
        },
        {
            value: 2,
            label: "CRANE - LOW",
            frontSrc: mountRightCraneLowImage,
        },
        {
            value: 3,
            label: "GATLING",
            src: mountRightGatlingImage,
        },
        {
            value: 4,
            label: "MISSILES",
            src: mountRightMissilesImage,
        },
        {
            value: 5,
            label: "SMOKE LAUNCHERS",
            src: mountRightSmokeLaunchersImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "GLOW - CRANE HIGH",
            frontSrc: glowCraneHighImage,
        },
        {
            value: 1,
            label: "GLOW - CRANE LOW",
            frontSrc: glowCraneLowImage,
        },
        {
            value: 2,
            label: "GLOW - CREW - BIPED",
            frontSrc: glowCrewBipedImage,
        },
        {
            value: 3,
            label: "GLOW - CREW - HEXAPOD",
            frontSrc: glowCrewHexapodImage,
        },
        {
            value: 4,
            label: "GLOW - CREW - SHIP",
            frontSrc: glowCrewShipImage,
        },
        {
            value: 5,
            label: "GLOW - CREW - WHEELS",
            frontSrc: glowCrewWheelsImage,
        },
        {
            value: 6,
            label: "GLOW - HOVER",
            frontSrc: glowHoverImage,
        },
        {
            value: 7,
            label: "LIVERY - PIRATE SKULL",
            src: accessoryPirateSkullLiveryImage,
        },
        {
            value: 8,
            label: "CREW - BIPED",
            frontSrc: crewBipedImage,
        },
        {
            value: 9,
            label: "CREW - HEXAPOD",
            frontSrc: crewHexapodImage,
        },
        {
            value: 10,
            label: "CREW - SHIP",
            frontSrc: crewShipImage,
        },
        {
            value: 11,
            label: "CREW - WHEELS",
            frontSrc: crewWheelsImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "MOUNTS",
            value: "WEAPONS",
        },
        {
            label: "GLOWS / CREW",
            value: "ACCESSORIES",
        },
        {
            label: "LEGS",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L MOUNT",
            value: "weapon",
        },
        {
            label: "R MOUNT",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "CREW 1",
            value: "accessory3",
        },
        {
            label: "CREW 2",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "LEGS",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "L MOUNT",
        "secondaryWeapon": "R MOUNT",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "CREW 1",
        "accessory4": "CREW 2",
    },
};
