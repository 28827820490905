import styled from "styled-components";

import Unit from "./Unit";
import SpriteImage from "../../common/styles/SpriteImage";

const UnitVariantControl = styled(SpriteImage)`
    ${Unit}:hover & {
        visibility: ${props => props.displayControl ? "visible" : "hidden"};
    }

    :active {
        transform: translateY(2px);
    }

    cursor: pointer;
    visibility: hidden;

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        visibility: ${props => props.displayControl ? "visible" : "hidden"};
    }
`;

UnitVariantControl.displayName = "UnitVariantControl";

export default UnitVariantControl;
