import React from "react";
import { bool, string } from "prop-types";
import styled from "styled-components";

import SpriteImage from "../../../common/styles/SpriteImage";

const UnitCategoryHeaderImageWrapper = styled.div`
    background-color: ${props => props.isExpanded ? props.borderSecondaryColor || props.theme.categorySecondaryColor : "transparent"};
    border-style: inset;
    border-color: ${props => props.isExpanded ? props.borderSecondaryColor || props.theme.categorySecondaryColor : "transparent"};
    border-width: 4px;
    border-radius: 10px;
    margin: 0px auto;
    padding: 0px 10px;
    transition-property: background-color, border, opacity;
    transition-duration: 0.25s;

    &.mech-category {
        display: none;
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        padding-top: 5px;
        padding-bottom: 5px;
    }
`;

UnitCategoryHeaderImageWrapper.displayName = "UnitCategoryHeaderImageWrapper";

function UnitCategoryHeaderImage({
    className,
    isExpanded,
    borderSecondaryColor,
    image,
    alt,
}) {
    return (
        <UnitCategoryHeaderImageWrapper
            className={className}
            isExpanded={isExpanded}
            borderSecondaryColor={borderSecondaryColor}
        >
            <SpriteImage className={image} alt={alt} />
        </UnitCategoryHeaderImageWrapper>
    )
}

UnitCategoryHeaderImage.propTypes = {
    className: string,
    isExpanded: bool,
    borderSecondaryColor: string,
    image: string,
    alt: string,
};

export default UnitCategoryHeaderImage;
