import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/SSC/Atlas`;

// BODY
const bodyAtlasSwordImage = `${imagePath}/Chassis - Sword Stance.png`;
const bodyAtlasGunImage = `${imagePath}/Chassis - Gun Stance.png`;
const bodyAtlasHorseImage = `${imagePath}/Chassis - Horse Stance.png`;
const bodyPendragonSwordImage = `${imagePath}/Chassis - Pendragon Sword Stance.png`;
// LEGENDARY
const bodyVanquisherSwordImage = `${imagePath}/Chassis - Vanquisher Sword Stance.png`;
const bodyVanquisherGunImage = `${imagePath}/Chassis - Vanquisher Gun Stance.png`;
const bodyVanquisherHorseImage = `${imagePath}/Chassis - Vanquisher Horse Stance.png`;

// HEADS
const headAtlasImage = `${imagePath}/heads/Head - Atlas.png`;
const headAtlasClassicImage = `${imagePath}/heads/Head - Atlas Classic.png`;
const headAtomsKImage = `${imagePath}/heads/Head - Atoms K.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headPendragonImage = `${imagePath}/heads/Head - Pendragon.png`;
const headDarkHunterImage = `${imagePath}/heads/Atlas _Head_Dark Hunter.png`;
const headDevilImage = `${imagePath}/heads/Atlas _Head_Devil.png`;
const headRiderImage = `${imagePath}/heads/Atlas _Head_Rider.png`;
const headVastHunterImage = `${imagePath}/heads/Atlas _Head_Vast Hunter.png`;
const headWortWortImage = `${imagePath}/heads/Atlas _Head_Wort Wort.png`;
const headBoneheadImage = `${imagePath}/heads/Bonehead_atlas.png`;
// PREMIUM
const headCalibanImage = `${imagePath}/heads/premium/Head - Caliban.png`;
const headHornedImage = `${imagePath}/heads/premium/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/premium/Head - Horned Hair.png`;
const headNaturalLawImage = `${imagePath}/heads/premium/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/premium/Head - Natural Law Hair.png`;
const headRainTransformedImage = `${imagePath}/heads/premium/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/premium/Head - Rain Transformed Hair.png`;
const headScopehoundImage = `${imagePath}/heads/premium/Head - Scopehound.png`;
// LEGENDARY
const headVanquisherImage = `${imagePath}/heads/legendary/Head - Vanquisher.png`;

// WEAPONS
// SWORD
const weaponsBalgusGSwordImage = `${imagePath}/weapons/Weapon - Blade - Balgus Greatsword.png`;
const weaponsBalorAxeImage = `${imagePath}/weapons/Weapon - Blade - Balor Axe.png`;
const weaponsButterflyBladeImage = `${imagePath}/weapons/Weapon - Blade - Butterfly Blade.png`;
const weaponsChainswordImage = `${imagePath}/weapons/Weapon - Blade - Chainsword.png`;
const weaponsDotanukiImage = `${imagePath}/weapons/Weapon - Blade - Dotanuki.png`;
const weaponsDragonsbaneImage = `${imagePath}/weapons/Weapon - Blade - Dragonsbane.png`;
const weaponsEnergySwordImage = `${imagePath}/weapons/Weapon - Blade - Energy Sword.png`;
const weaponsLunarGSwordImage = `${imagePath}/weapons/Weapon - Blade - Lunar Greatsword.png`;
const weaponsMasamuneImage = `${imagePath}/weapons/Weapon - Blade - Masamune.png`;
const weaponsOdachiImage = `${imagePath}/weapons/Weapon - Blade - Odachi.png`;
const weaponsRenardGrisImage = `${imagePath}/weapons/Weapon - Blade - Renard Gris.png`;
const weaponsScimitarImage = `${imagePath}/weapons/Weapon - Blade - Scimitar.png`;
const weaponsTechKatanaImage = `${imagePath}/weapons/Weapon - Blade - Tech Katana.png`;
const weaponsWolfBladeImage = `${imagePath}/weapons/Weapon - Blade - Wolf Blade.png`;
const weaponsRickenbackerImage = `${imagePath}/weapons/Weapon - Rickenbacker.png`;
const weaponsKonaboImage = `${imagePath}/weapons/Atlas _Weapons_Konabo.png`;
const weaponsVastBoneSwordImage = `${imagePath}/weapons/Atlas _Weapons_Vast Bone Sword.png`;

// GUN
const weaponsCannibalImage = `${imagePath}/weapons/Weapon - Gun - Cannibal.png`;
const weaponsDecksweeperImage = `${imagePath}/weapons/Weapon - Gun - Decksweeper.png`;
const weaponsKraulRifleImage = `${imagePath}/weapons/Weapon - Gun - Kraul Rifle.png`;
const weaponsAlienCarbineImage = `${imagePath}/weapons/Atlas _Weapons_Alien Carbine.png`;
const weaponsKusarigamaImage = `${imagePath}/weapons/Atlas _Weapons_Kusarigama.png`;

// HORSE
const weaponsPlasmaGauntletImage = `${imagePath}/weapons/Weapon - Horse Stance - Plasma Gauntlets.png`;
const weaponsArmBladeImage = `${imagePath}/weapons/Atlas _Weapons_Arm Blade.png`;
const weaponsEnergySword2Image = `${imagePath}/weapons/Atlas _Weapons_Energy Sword.png`;
const weaponsHookImage = `${imagePath}/weapons/Atlas _Weapons_Hook.png`;
const weaponsRicochetBladeImage = `${imagePath}/weapons/Atlas _Weapons_Ricochet Blade.png`;

// REAR MOUNT
const rearMountKraulRifleImage = `${imagePath}/rear mount/Rear - Kraul Rifle.png`;
const rearMountSwordBackImage = `${imagePath}/rear mount/Rear - Sword (back).png`;
const rearMountSwordWaistImage = `${imagePath}/rear mount/Rear - Sword (waist).png`;
const rearMountSwordSheathBackImage = `${imagePath}/rear mount/Rear - Sword Sheath (back).png`;
const rearMountSwordSheathWaistImage = `${imagePath}/rear mount/Rear - Sword Sheath (waist).png`;

// ACCESSORIES
const accessoryFurCloakGunImage = `${imagePath}/accessories/Accessory - Fur Cloak (Gun Stance).png`;
const accessoryFurCloakSwordImage = `${imagePath}/accessories/Accessory - Fur Cloak (Sword Stance).png`;
const accessoryFurCloakHorseImage = `${imagePath}/accessories/Accessory - Fur Cloak (Horse Stance).png`;
const accessoryJacketAtomsKSwordGunImage = `${imagePath}/accessories/Accessory - Jacket Atoms K (Sword Stance).png`;
const accessoryJacketAtomsKHorseImage = `${imagePath}/accessories/Accessory - Jacket Atoms K (Horse Stance).png`;
// PREMIUM
const accessoryFlyKicksImage = `${imagePath}/accessories/Accessory - Fly Kicks.png`;
// LEGENDARY
const accessoryFlyKicksJacketSwordGunImage = `${imagePath}/accessories/Accessory - Fly Kicks Jacket.png`;
const accessoryFurCloakGunVanqImage = `${imagePath}/accessories/Accessory - Fur Cloak Gun Stance Vanq.png`;
const accessoryFurCloakSwordVanqImage = `${imagePath}/accessories/Accessory - Fur Cloak Sword Stance Vanq.png`;
const accessoryFurCloakHorseVanqImage = `${imagePath}/accessories/Accessory - Fur Cloak Horse Stance Vanq.png`;
const accessoryJacketSwordGunImage = `${imagePath}/accessories/Accessory - Jacket (Sword _ Gun Stance).png`;
const accessoryJacketHorseImage = `${imagePath}/accessories/Accessory - Jacket (Horse Stance).png`;
const accessoryMGMSBackImage = `${imagePath}/accessories/Atlas _Accesories Back_MGMS.png`;
const accessoryRidersScarfImage = `${imagePath}/accessories/Atlas _Accesories Mid_Rider_s Scarf.png`;
const accessoryRidersScarfBackImage = `${imagePath}/accessories/Atlas _Accesories Back_Rider_s Scarf.png`;
const accessoryHuntersPauldronsGunImage = `${imagePath}/accessories/Atlas _Accesories Mid_Hunter_s Pauldrons Gun Stance.png`;
const accessoryHuntersPauldronsHorseImage = `${imagePath}/accessories/Atlas _Accesories Mid_Hunter_s Pauldrons Horse Stance.png`;
const accessoryHuntersPauldronsSwordImage = `${imagePath}/accessories/Atlas _Accesories Mid_Hunter_s Pauldrons Sword Stance.png`;
const accessoryPonchoFrontImage = `${imagePath}/accessories/Atlas _Accesories Top_Poncho.png`;
const accessoryPonchoGunImage = `${imagePath}/accessories/Atlas _Accesories Mid_Poncho gun stance.png`;
const accessoryPonchoHorseImage = `${imagePath}/accessories/Atlas _Accesories Mid_Poncho horse stance.png`;
const accessoryPonchoSwordImage = `${imagePath}/accessories/Atlas _Accesories Mid_Poncho sword stance.png`;
const accessorySantoryuFrontImage = `${imagePath}/accessories/Atlas _Accesories Top_Santoryu.png`;
const accessorySantoryuGunImage = `${imagePath}/accessories/Atlas _Accesories Mid_Santoryu gun stance.png`;
const accessorySantoryuHorseImage = `${imagePath}/accessories/Atlas _Accesories Mid_Santoryu horse stance.png`;
const accessorySantoryuSwordImage = `${imagePath}/accessories/Atlas _Accesories Mid_Santoryu sword stance.png`;


// GLOW
const glowAtlasImage = `${imagePath}/glow/Glow - Atlas.png`;
const glowAtlasClassicImage = `${imagePath}/glow/Glow - Atlas Classic.png`;
const glowAtomsKImage = `${imagePath}/glow/Glow - Atoms K.png`;
const glowKazuImage = `${imagePath}/glow/Glow - Kazu.png`;
const glowPendragonHeadImage = `${imagePath}/glow/Glow - Pendragon Head Glow.png`;
const glowPendragonImage = `${imagePath}/glow/Glow - Pendragon Full Glow.png`;
// PREMIUM
const glowCalibanImage = `${imagePath}/glow/premium/Glow - Caliban.png`;
const glowHornedImage = `${imagePath}/glow/premium/Glow - Horned.png`;
const glowNaturalLawImage = `${imagePath}/glow/premium/Glow - Natural Law.png`;
const glowRainTransformedImage = `${imagePath}/glow/premium/Glow - Rain Transformed.png`;
const glowScopehoundImage = `${imagePath}/glow/premium/Glow - Scopehound.png`;
// LEGENDARY
const glowVanquisherImage = `${imagePath}/glow/legendary/Glow - Vanquisher.png`;

const coreImage = `${imagePath}/Atlas (by Seamless).png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SMALL,
    bottom: "93px",
    right: "114px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Seamless",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    secondaryWeaponIsChassisStanceSpecial: true,
    accessory1IsSecondaryWeapon: true,
    headSpecial: "secondaryWeaponValue",
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "SWORD STANCE",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ATLAS",
            src: headAtlasImage,
        },
        {
            value: 1,
            label: "ATLAS CLASSIC",
            src: headAtlasClassicImage,
        },
        {
            value: 2,
            label: "ATOMS K",
            src: headAtomsKImage,
        },
        {
            value: 3,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 4,
            label: "PENDRAGON",
            src: headPendragonImage,
        },
        {
            value: 5,
            label: "PENDRAGON - FULL",
            src: headPendragonImage,
        },
        {
            value: 6,
            label: "DARK HUNTER",
            src: headDarkHunterImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 7,
            label: "VAST HUNTER",
            src: headVastHunterImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 8,
            label: "DEVIL",
            src: headDevilImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 9,
            label: "RIDER",
            src: headRiderImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 10,
            label: "WORT WORT",
            src: headWortWortImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 11,
            label: "BONEHEAD",
            src: headBoneheadImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 12,
            label: "HORNED",
            src: headHornedImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "HORNED (HAIR)",
            src: headHornedHairImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RAIN TRANSFORMED (HAIR)",
            src: headRainTransformedHairImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "NATURAL LAW (HAIR)",
            src: headNaturalLawHairImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "CALIBAN",
            src: headCalibanImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "VANQUISHER",
            src: headVanquisherImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SWORD STANCE",
            src: bodyAtlasSwordImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "GUN STANCE",
            src: bodyAtlasGunImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "HORSE STANCE",
            src: bodyAtlasHorseImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 3,
            label: "PENDRAGON SWORD STANCE",
            src: bodyPendragonSwordImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 4,
            label: "VANQUISHER SWORD STANCE",
            src: bodyVanquisherSwordImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "VANQUISHER GUN STANCE",
            src: bodyVanquisherGunImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "VANQUISHER HORSE STANCE",
            src: bodyVanquisherHorseImage,
            shadowDetails: bipedLegsShadow,
            isPremium: true,
            isLegendary: true,
        },
    ],
    weapon: {
        "SWORD STANCE": [
            {
                value: 0,
                label: "TECH KATANA",
                frontSrc: weaponsTechKatanaImage,
            },
            {
                value: 1,
                label: "BUTTERFLY BLADE",
                frontSrc: weaponsButterflyBladeImage,
            },
            {
                value: 2,
                label: "SCIMITAR",
                frontSrc: weaponsScimitarImage,
            },
            {
                value: 3,
                label: "CHAINSWORD",
                frontSrc: weaponsChainswordImage,
            },
            {
                value: 4,
                label: "BALOR AXE",
                frontSrc: weaponsBalorAxeImage,
            },
            {
                value: 5,
                label: "ENERGY SWORD",
                frontSrc: weaponsEnergySwordImage,
            },
            {
                value: 6,
                label: "ODACHI",
                frontSrc: weaponsOdachiImage,
            },
            {
                value: 7,
                label: "DOTANUKI",
                frontSrc: weaponsDotanukiImage,
            },
            {
                value: 8,
                label: "RENARD GRIS",
                frontSrc: weaponsRenardGrisImage,
            },
            {
                value: 9,
                label: "BALGUS GREATSWORD",
                frontSrc: weaponsBalgusGSwordImage,
            },
            {
                value: 10,
                label: "LUNAR GREATSWORD",
                frontSrc: weaponsLunarGSwordImage,
            },
            {
                value: 11,
                label: "MASAMUNE",
                frontSrc: weaponsMasamuneImage,
            },
            {
                value: 12,
                label: "DRAGONSBANE",
                frontSrc: weaponsDragonsbaneImage,
            },
            {
                value: 13,
                label: "WOLF BLADE",
                frontSrc: weaponsWolfBladeImage,
            },
            {
                value: 14,
                label: "RICKENBACKER",
                frontSrc: weaponsRickenbackerImage,
            },
            {
                value: 15,
                label: "KONABO",
                frontSrc: weaponsKonaboImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 16,
                label: "VAST BONE SWORD",
                frontSrc: weaponsVastBoneSwordImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
        "GUN STANCE": [
            {
                value: 0,
                label: "KRAUL RIFLE",
                src: weaponsKraulRifleImage,
            },
            {
                value: 1,
                label: "CANNIBAL SHOTGUN",
                src: weaponsCannibalImage,
            },
            {
                value: 2,
                label: "DECKSWEEPER SHOTGUN",
                src: weaponsDecksweeperImage,
            },
            {
                value: 3,
                label: "ALIEN CARBINE",
                src: weaponsAlienCarbineImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 4,
                label: "KUSARIGAMA",
                src: weaponsKusarigamaImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 5,
                label: "HOOK",
                src: weaponsHookImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
        "HORSE STANCE": [
            {
                value: 0,
                label: "PLASMA GAUNTLET",
                src: weaponsPlasmaGauntletImage,
            },
            {
                value: 1,
                label: "ARM BLADE",
                src: weaponsArmBladeImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 2,
                label: "ENERGY SWORD",
                foremostSrc: weaponsEnergySword2Image,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 3,
                label: "RICOCHET BLADE",
                src: weaponsRicochetBladeImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
        "PENDRAGON SWORD STANCE": [
            {
                value: 0,
                label: "TECH KATANA",
                frontSrc: weaponsTechKatanaImage,
            },
            {
                value: 1,
                label: "BUTTERFLY BLADE",
                frontSrc: weaponsButterflyBladeImage,
            },
            {
                value: 2,
                label: "SCIMITAR",
                frontSrc: weaponsScimitarImage,
            },
            {
                value: 3,
                label: "CHAINSWORD",
                frontSrc: weaponsChainswordImage,
            },
            {
                value: 4,
                label: "BALOR AXE",
                frontSrc: weaponsBalorAxeImage,
            },
            {
                value: 5,
                label: "ENERGY SWORD",
                frontSrc: weaponsEnergySwordImage,
            },
            {
                value: 6,
                label: "ODACHI",
                frontSrc: weaponsOdachiImage,
            },
            {
                value: 7,
                label: "DOTANUKI",
                frontSrc: weaponsDotanukiImage,
            },
            {
                value: 8,
                label: "RENARD GRIS",
                frontSrc: weaponsRenardGrisImage,
            },
            {
                value: 9,
                label: "BALGUS GREATSWORD",
                frontSrc: weaponsBalgusGSwordImage,
            },
            {
                value: 10,
                label: "LUNAR GREATSWORD",
                frontSrc: weaponsLunarGSwordImage,
            },
            {
                value: 11,
                label: "MASAMUNE",
                frontSrc: weaponsMasamuneImage,
            },
            {
                value: 12,
                label: "DRAGONSBANE",
                frontSrc: weaponsDragonsbaneImage,
            },
            {
                value: 13,
                label: "WOLF BLADE",
                frontSrc: weaponsWolfBladeImage,
            },
            {
                value: 14,
                label: "RICKENBACKER",
                frontSrc: weaponsRickenbackerImage,
            },
            {
                value: 15,
                label: "KONABO",
                frontSrc: weaponsKonaboImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 16,
                label: "VAST BONE SWORD",
                frontSrc: weaponsVastBoneSwordImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
        "VANQUISHER SWORD STANCE": [
            {
                value: 0,
                label: "TECH KATANA",
                frontSrc: weaponsTechKatanaImage,
            },
            {
                value: 1,
                label: "BUTTERFLY BLADE",
                frontSrc: weaponsButterflyBladeImage,
            },
            {
                value: 2,
                label: "SCIMITAR",
                frontSrc: weaponsScimitarImage,
            },
            {
                value: 3,
                label: "CHAINSWORD",
                frontSrc: weaponsChainswordImage,
            },
            {
                value: 4,
                label: "BALOR AXE",
                frontSrc: weaponsBalorAxeImage,
            },
            {
                value: 5,
                label: "ENERGY SWORD",
                frontSrc: weaponsEnergySwordImage,
            },
            {
                value: 6,
                label: "ODACHI",
                frontSrc: weaponsOdachiImage,
            },
            {
                value: 7,
                label: "DOTANUKI",
                frontSrc: weaponsDotanukiImage,
            },
            {
                value: 8,
                label: "RENARD GRIS",
                frontSrc: weaponsRenardGrisImage,
            },
            {
                value: 9,
                label: "BALGUS GREATSWORD",
                frontSrc: weaponsBalgusGSwordImage,
            },
            {
                value: 10,
                label: "LUNAR GREATSWORD",
                frontSrc: weaponsLunarGSwordImage,
            },
            {
                value: 11,
                label: "MASAMUNE",
                frontSrc: weaponsMasamuneImage,
            },
            {
                value: 12,
                label: "DRAGONSBANE",
                frontSrc: weaponsDragonsbaneImage,
            },
            {
                value: 13,
                label: "WOLF BLADE",
                frontSrc: weaponsWolfBladeImage,
            },
            {
                value: 14,
                label: "RICKENBACKER",
                frontSrc: weaponsRickenbackerImage,
            },
            {
                value: 15,
                label: "KONABO",
                frontSrc: weaponsKonaboImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 16,
                label: "VAST BONE SWORD",
                frontSrc: weaponsVastBoneSwordImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
        "VANQUISHER GUN STANCE": [
            {
                value: 0,
                label: "KRAUL RIFLE",
                src: weaponsKraulRifleImage,
            },
            {
                value: 1,
                label: "CANNIBAL SHOTGUN",
                src: weaponsCannibalImage,
            },
            {
                value: 2,
                label: "DECKSWEEPER SHOTGUN",
                src: weaponsDecksweeperImage,
            },
            {
                value: 3,
                label: "ALIEN CARBINE",
                src: weaponsAlienCarbineImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 4,
                label: "KUSARIGAMA",
                src: weaponsKusarigamaImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 5,
                label: "HOOK",
                src: weaponsHookImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
        "VANQUISHER HORSE STANCE": [
            {
                value: 0,
                label: "PLASMA GAUNTLET",
                src: weaponsPlasmaGauntletImage,
            },
            {
                value: 1,
                label: "ARM BLADE",
                src: weaponsArmBladeImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 2,
                label: "ENERGY SWORD",
                foremostSrc: weaponsEnergySword2Image,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 3,
                label: "RICOCHET BLADE",
                src: weaponsRicochetBladeImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "ATLAS",
            src: glowAtlasImage,
        },
        {
            value: 1,
            label: "ATLAS CLASSIC",
            src: glowAtlasClassicImage,
        },
        {
            value: 2,
            label: "ATOMS K",
            src: glowAtomsKImage,
        },
        {
            value: 3,
            label: "KAZU",
            src: glowKazuImage,
        },
        {
            value: 4,
            label: "PENDRAGON - HEAD",
            src: glowPendragonHeadImage,
        },
        {
            value: 5,
            label: "PENDRAGON - FULL",
            src: glowPendragonImage,
        },
        {
            value: 6,
            label: "DARK HUNTER (EMPTY)",
        },
        {
            value: 7,
            label: "VAST HUNTER (EMPTY)",
        },
        {
            value: 8,
            label: "DEVIL (EMPTY)",
        },
        {
            value: 9,
            label: "RIDER (EMPTY)",
        },
        {
            value: 10,
            label: "WORT WORT (EMPTY)",
        },
        {
            value: 11,
            label: "BONEHEAD (EMPTY)",
        },
        {
            value: 12,
            label: "HORNED",
            src: glowHornedImage,
            isPremium: true,
        },
        {
            value: 13,
            label: "HORNED (HAIR)",
            src: glowHornedImage,
            isPremium: true,
        },
        {
            value: 14,
            label: "RAIN TRANSFORMED",
            src: glowRainTransformedImage,
            isPremium: true,
        },
        {
            value: 15,
            label: "RAIN TRANSFORMED (HAIR)",
            src: glowRainTransformedImage,
            isPremium: true,
        },
        {
            value: 16,
            label: "NATURAL LAW",
            src: glowNaturalLawImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "NATURAL LAW (HAIR)",
            src: glowNaturalLawImage,
            isPremium: true,
        },
        {
            value: 18,
            label: "CALIBAN",
            src: glowCalibanImage,
            isPremium: true,
        },
        {
            value: 19,
            label: "SCOPEHOUND",
            src: glowScopehoundImage,
            isPremium: true,
        },
        {
            value: 20,
            label: "VANQUISHER",
            src: glowVanquisherImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "KRAUL RIFLE",
            src: rearMountKraulRifleImage,
        },
        {
            value: 1,
            label: "SWORD (BACK)",
            src: rearMountSwordBackImage,
        },
        {
            value: 2,
            label: "SWORD (WAIST)",
            src: rearMountSwordWaistImage,
        },
        {
            value: 3,
            label: "SCABBARD (BACK)",
            src: rearMountSwordSheathBackImage,
        },
        {
            value: 4,
            label: "SCABBARD (WAIST)",
            src: rearMountSwordSheathWaistImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FUR CLOAK (SWORD STANCE)",
            src: accessoryFurCloakSwordImage,
        },
        {
            value: 1,
            label: "FUR CLOAK (GUN STANCE)",
            src: accessoryFurCloakGunImage,
        },
        {
            value: 2,
            label: "FUR CLOAK (HORSE STANCE)",
            src: accessoryFurCloakHorseImage,
        },
        {
            value: 3,
            label: "JACKET ATOMS K (SWORD/GUN STANCE)",
            src: accessoryJacketAtomsKSwordGunImage,
        },
        {
            value: 4,
            label: "JACKET ATOMS K (HORSE STANCE)",
            src: accessoryJacketAtomsKHorseImage,
        },
        {
            value: 5,
            label: "MGMS",
            backSrc: accessoryMGMSBackImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 6,
            label: "RIDER'S SCARF",
            src: accessoryRidersScarfImage,
            backSrc: accessoryRidersScarfBackImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 7,
            label: "HUNTER'S PAULDRON (HORSE STANCE)",
            src: accessoryHuntersPauldronsHorseImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 8,
            label: "HUNTER'S PAULDRON (GUN STANCE)",
            src: accessoryHuntersPauldronsGunImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 9,
            label: "HUNTER'S PAULDRON (SWORD STANCE)",
            src: accessoryHuntersPauldronsSwordImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 10,
            label: "PONCHO (HORSE STANCE)",
            frontSrc: accessoryPonchoFrontImage,
            src: accessoryPonchoHorseImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 11,
            label: "PONCHO (GUN STANCE)",
            frontSrc: accessoryPonchoFrontImage,
            src: accessoryPonchoGunImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 12,
            label: "PONCHO (SWORD STANCE)",
            frontSrc: accessoryPonchoFrontImage,
            src: accessoryPonchoSwordImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 13,
            label: "SANTORYU (HORSE STANCE)",
            frontSrc: accessorySantoryuFrontImage,
            src: accessorySantoryuHorseImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 14,
            label: "SANTORYU (GUN STANCE)",
            frontSrc: accessorySantoryuFrontImage,
            src: accessorySantoryuGunImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 15,
            label: "SANTORYU (SWORD STANCE)",
            frontSrc: accessorySantoryuFrontImage,
            src: accessorySantoryuSwordImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 16,
            label: "FLY KICKS",
            src: accessoryFlyKicksImage,
            isPremium: true,
        },
        {
            value: 17,
            label: "FLY KICKS + JACKET (SWORD/GUN STANCE)",
            src: accessoryFlyKicksJacketSwordGunImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 18,
            label: "JACKET (SWORD/GUN STANCE)",
            src: accessoryJacketSwordGunImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 19,
            label: "JACKET (HORSE STANCE)",
            src: accessoryJacketHorseImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 20,
            label: "FUR CLOAK (VANQ. SWORD STANCE)",
            src: accessoryFurCloakSwordVanqImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 21,
            label: "FUR CLOAK (VANQ. GUN STANCE)",
            src: accessoryFurCloakGunVanqImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 22,
            label: "FUR CLOAK (VANQ. HORSE STANCE)",
            src: accessoryFurCloakHorseVanqImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "HEAD GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "STANCE",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "HEAD GLOW",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 1",
        "accessory3": "ACCESSORY 2",
        "accessory4": "ACCESSORY 3",
    },
};
