import React, { useContext } from "react";
import { createGlobalStyle } from "styled-components";

import { ShowLandingContext } from "./../Landing/ShowLandingContextWrapper";

const GlobalStyle = createGlobalStyle`
    html, body {
        background: ${props => props.showLanding ? props.theme.landingBackground : "#000"};
        font-family: monospace, monospace;
        font-size: 16px;
        line-height: 1.2em;
        margin: 0;

        @media print {
            background: #FFF;
            overflow: visible;
        }
    }

    h1, h2, h3, h4 {
        font-size: 120%;
    }

    ul, ol {
        line-height: 170%;
    }

    a {
        text-decoration: none;

        :focus {
            outline:none;
        }
        ::-moz-focus-inner {
            border:0;
        }
    }

    * {
        user-select: none;
        @media print {
            float: none;
        }
    }

    input {
        user-select: text;
    }

    .clickable {
        cursor: pointer;

        &:active {
            transform: translateY(2px);
        }
    }

    .hide-ui {
        @media only screen and (min-width: ${props => props.theme.tabletCutoffWidth}px) {
            display: none;
        }
    }
`;

function GlobalStyleProvider() {
    const { showLanding } = useContext(ShowLandingContext);

    return (
        <GlobalStyle showLanding={showLanding} />
    );
}

export default GlobalStyleProvider;
