import styled from "styled-components";

export const backgroundColor = "#F9F9F7";
export const defaultColor = "#B6322A";
export const defaultParentColor = "#991E2A";
export const disabledDefaultColor = "#D8918D";

export const retroTerminalBackgroundColor = "#282828";
export const retroTerminalColor = "#3F3";

export const SelectDropdown = styled.div`
    width: 190px;
    font-family: arial;
    position: relative;

    label {
        position: relative;
        display: flex;
        font-weight: bolder;
        gap: 2px;
        text-align: left;
        padding: 2px 15px;
        max-width: 100%;
        justify-content: space-between;
        align-items: center;

        span {
            margin-right: auto;
        }
    }

    select, option, .range-wrapper {
        width: 190px;
        display: block;
        padding: 7px 10px;
        height: auto;
        background-color: transparent;
        font-size: 0.9em;
        font-weight: bolder;
        appearance: none;
        text-align: center;
        text-align-last: center;

        :hover {
            cursor: pointer;
        }

        :focus {
            outline: none;
        }
    }


    &.default {
        :-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 ${defaultColor};
        }
        :-moz-focusring * {
            color: ${defaultColor};
            text-shadow: none;
        }

        label {
            background: ${props => props.disabled ? "#333" : defaultColor};
            color: ${backgroundColor};

            &.long-text-display {
                margin-right: 10px;
                padding-right: 10px;
            }

            :first-of-type {
                //Clip Top Right Corner
                clip-path: polygon(calc(100% - 10px) 0%,100% 10px,100% 100%,0% 100%,0% 0%);
            }

            :nth-of-type(2) {
                padding-top: 10px;
                padding-bottom: 5px;
            }
        }

        select, option, .range-wrapper {
            color: ${defaultColor};
            border-color: ${defaultColor};
            border-style: solid;
            border-width: 2px;
            border-top-width: 0px;

            :disabled {
                border-color: #333;
                color: #333;
            }
        }

        input {
            color: ${defaultColor};
        }

        option {
            background-color: ${backgroundColor};
        }
    }

    &.fantasy {
        :-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 ${defaultColor};
        }
        :-moz-focusring * {
            color: ${defaultColor};
            text-shadow: none;
        }

        label {
            background-color: #000;
            color: #FFF;
            text-align: center;
            padding: 10px;
            font-family: arial;

            :first-of-type {
                //Clip Rabbet style
                clip-path: polygon(0% 5px,5px 5px,5px 0%,calc(100% - 5px) 0%,calc(100% - 5px) 5px,100% 5px,100% calc(100% - 5px),calc(100% - 5px) calc(100% - 5px),calc(100% - 5px) 100%,5px 100%,5px calc(100% - 5px),0% calc(100% - 5px));
            }
        }
    }

    &.retro-terminal {
        :-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 ${retroTerminalColor};
        }
        :-moz-focusring * {
            color: ${retroTerminalColor};
            text-shadow: none;
        }

        label {
            background: transparent;
            color: ${props => props.disabled ? "#666" : retroTerminalColor};
        }

        select, option, .range-wrapper {
            color: ${retroTerminalColor};
            border-color: ${retroTerminalColor};
            border-style: dashed solid;
            border-width: 2px;

            :disabled {
                border-color: #666;
                color: #666;
            }
        }

        input {
            color: ${retroTerminalColor};
        }

        option {
            background-color: ${retroTerminalBackgroundColor};
        }
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        margin-top: 15px;
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 150px;

        select, option, .range-wrapper {
            width: 150px;
        }

        select {
            font-size: 0.7em;
        }

        label {
            font-size: 0.9em;
        }
    }
`;

SelectDropdown.displayName = "SelectDropdown";

export const InterfaceButton = styled.div`
    width: 150px;
    display: block;
    font-family: arial;
    padding: 7px 20px 7px 13px;
    font-size: 1.05em;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    position: relative;

    :active {
        transform: translateY(2px);
    }

    &.no-active {
        :active {
            transform: none;
        }
    }

    :hover {
        cursor: pointer;
    }

    &.default {
        color: ${backgroundColor};
            background: ${props => props.disabled ? "#333" : defaultColor};

        //Clip Top Left & Bottom Right Corner
        clip-path: polygon(10px 0%,100% 0%,100% calc(100% - 10px),calc(100% - 10px) 100%,0% 100%,0% 10px);

        :disabled {
            border-color: #333;
            color: #333;
        }
    }

    &.retro-terminal {
        border-color:${props => props.disabled ? "#666" : retroTerminalColor};
        border-style: solid;
        border-width: 2px;
        color: ${props => props.disabled ? "#666" : retroTerminalColor};

        :hover {
            color: ${props => props.disabled ? "#666" : retroTerminalBackgroundColor};
            background-color: ${props => props.disabled ? "transparent" : retroTerminalColor};
        }
    }

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 110px;
        margin-top: 20px;
    }
`;

InterfaceButton.displayName = "InterfaceButton";

export const InterfaceThirdButton = styled(InterfaceButton)`
    width: 80px;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 75px;
    }
`;

InterfaceThirdButton.displayName = "InterfaceThirdButton";

export const InterfacePlaceholder = styled.div`
    width: 150px;
    display: block;
    font-family: arial;
    padding: 7px 20px 7px 13px;
    font-size: 1.05em;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    position: relative;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        width: 110px;
        margin-top: 20px;
    }
`;

InterfacePlaceholder.displayName = "InterfacePlaceholder";

export const MechForm = styled.form`
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;

    .display-controls {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        flex-direction: row;
    }

    .options-selectors {
        width: 50%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-content: space-around;
        flex-direction: row;
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        .options-selectors, .display-controls {
            width: 100%;
        }
    }
`;

MechForm.displayName = "MechForm";

export const CustomMechForm = styled(MechForm)`
    .options-selectors {
        height: 485px;
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        .options-selectors, .display-controls {
            height: unset;
        }
    }
`;

CustomMechForm.displayName = "CustomMechForm";

export const InterfaceControls = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    flex-direction: row;
    align-content: flex-end;
    align-items: flex-end;

    :first-child {
        align-self: flex-start;
    }
`;

InterfaceControls.displayName = "InterfaceControls";

export const IndexSelectors = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-content: space-around;
    flex-direction: column;

    @media only screen and (max-width: ${props => props.theme.mobileCutoffWidth}px) {
        flex-direction: row;

        div {
            margin: 0 10px;
        }
    }
`;

IndexSelectors.displayName = "IndexSelectors";

export const IndexSelector = styled.div`
    width: 20px;
    display: block;
    font-family: arial;
    padding: 5px;
    font-size: 1.05em;
    font-weight: bolder;
    text-align: center;
    margin-top: 10px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    border-width: 2px;

    :active {
        transform: translateY(2px);
    }

    :hover {
        cursor: pointer;
    }

    &.default {
        border-color: ${defaultColor};
        color: ${props => props.isActive ? backgroundColor : defaultColor};
        background-color: ${props => props.isActive ? defaultColor : backgroundColor};
        border-style: ${props => props.isActive ? "hidden" : "hidden solid"};

        //Clip Top Left & Bottom Right Corner
        clip-path: ${props => props.isActive ? "polygon(7px 0%,100% 0%,100% calc(100% - 7px),calc(100% - 7px) 100%,0% 100%,0% 7px)" : "unset"};
    }

    &.retro-terminal {
        border-color: ${retroTerminalColor};
        color: ${props => props.isActive ? retroTerminalBackgroundColor : retroTerminalColor};
        background-color: ${props => props.isActive ? retroTerminalColor : "transparent"};
        border-style: hidden solid;
    }
`;

IndexSelector.displayName = "IndexSelector";
