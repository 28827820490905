import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyViceroyImage = `${BaseURL}/Mechs/Custom/Viceroy/Chassis.png`;

// HEADS
const headViceroyImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Viceroy.png`;
const headKazuImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Kazu.png`;
const headBlackspotImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Blackspot.png`;
const headCalibanImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Caliban.png`;
const headCheddahImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Cheddah.png`;
const headChimeraImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Chimera.png`;
const headCyclopsImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Cyclops.png`;
const headEnkiduImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Enkidu.png`;
const headFTNelsonImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - FT Nelson.png`;
const headHornedImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Horned.png`;
const headHornedHairImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Horned Hair.png`;
const headLaborerImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Laborer.png`;
const headMagImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Mag.png`;
const headMaliceImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Malice.png`;
const headNaturalLawImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Natural Hair.png`;
const headRainTransformedImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Rain.png`;
const headRainTransformedHairImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Rain Hair.png`;
const headSlipstreamImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Slipstream.png`;
const headTokugawaImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Tokugawa.png`;
const headVanquisherImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Vanquisher.png`;
const headVisionaryImage = `${BaseURL}/Mechs/Custom/Viceroy/heads/Head - Visionary.png`;

// REAR MOUNTS
const rear8RackImage = `${BaseURL}/Mechs/Custom/Viceroy/rear mounts/Rear - 8 Rack.png`;
const rear12RackImage = `${BaseURL}/Mechs/Custom/Viceroy/rear mounts/Rear - 12 Rack.png`;
const rear18RackImage = `${BaseURL}/Mechs/Custom/Viceroy/rear mounts/Rear - 18 Rack.png`;
const rearBoostersImage = `${BaseURL}/Mechs/Custom/Viceroy/rear mounts/Rear - Boosters.png`;
const rearMissilePodsImage = `${BaseURL}/Mechs/Custom/Viceroy/rear mounts/Rear - Missile Pods.png`;
const rearScabbardImage = `${BaseURL}/Mechs/Custom/Viceroy/rear mounts/Rear - Scabbard.png`;

// WEAPONS
// LEFT
const leftWeaponFistImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/left/Weapon - Fist L.png`;
const leftWeaponKnifeImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/left/Weapon - Knife L.png`;
const leftWeaponRapierImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/left/Weapon - Rapier L.png`;
const leftWeaponAxeImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/left/Weapon - Axe L.png`;
const leftWeaponLauncherImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/left/Weapon - Launcher L.png`;
const leftWeaponLauncherDrumImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/left/Weapon - Launcher + Drum L.png`;

// RIGHT
const rightWeaponFistImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Fist R.png`;
const rightWeaponKnifeImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Knife R.png`;
const rightWeaponFencingKnifeImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Fencing Knife R.png`;
const rightWeaponAxeImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Axe R.png`;
const rightWeaponMarksmanRifleImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Marksman Rifle R.png`;
const rightWeaponBeamRifleImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Beam Rifle R.png`;
const rightWeaponLauncherImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Launcher R.png`;
const rightWeaponLauncherDrumImage = `${BaseURL}/Mechs/Custom/Viceroy/weapons/right/Weapon - Launcher + Drum R.png`;

// ACCESSORIES
const accessoryFeetHoovesImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Feet - Hooves.png`;
const accessoryFeetTalonsImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Feet - Talons.png`;
const accessoryCloakImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Accessory - Cloak Over.png`;
const accessoryCloakUnderImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Accessory - Cloak Under.png`;
const accessorySecondaryBoostersImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Accessory - Secondary Boosters.png`;
const accessoryShoulderMissilesImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Accessory - Shoulder Missiles.png`;
const accessoryRoseImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Accessory - Rose.png`;
const accessoryCloakRImage = `${BaseURL}/Mechs/Custom/Viceroy/accessories/Weapon - Cloak R.png`;

// GLOWS
const glowBodyImage = `${BaseURL}/Mechs/Custom/Viceroy/glows/Glow - Body.png`;
const glowViceroyHeadImage = `${BaseURL}/Mechs/Custom/Viceroy/glows/Glow - Viceroy Head.png`;
const glowBoostersImage = `${BaseURL}/Mechs/Custom/Viceroy/glows/Glow - Boosters.png`;
const glowAfterburnersImage = `${BaseURL}/Mechs/Custom/Viceroy/glows/Glow - Afterburners.png`;
const glowAfterburnersBoostersImage = `${BaseURL}/Mechs/Custom/Viceroy/glows/Glow - Afterburners + Boosters.png`;
const glowAfterburnersBoostersSecondariesImage = `${BaseURL}/Mechs/Custom/Viceroy/glows/Glow - Afterburners + Boosters + Secondaries.png`;

const previewImage = `${BaseURL}/Mechs/Custom/Viceroy/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/Custom/Viceroy/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "27px",
    right: "45px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "1",
        "secondaryWeaponValue": "4",
        "accessory1Value": "0",
        "accessory2Value": "2",
        "accessory3Value": "7",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "SILVA",
            foremostSrc: headViceroyImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 4,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 5,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 6,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 7,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 8,
            label: "FT NELSON",
            src: headFTNelsonImage,
        },
        {
            value: 9,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 10,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 11,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 12,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 13,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 14,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 15,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 16,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 17,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 18,
            label: "SLIPSTREAM",
            src: headSlipstreamImage,
        },
        {
            value: 19,
            label: "TOKUGAWA",
            src: headTokugawaImage,
        },
        {
            value: 20,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 21,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "VICEROY",
            src: bodyViceroyImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Commissioned by Alrækinn, original by",
                title: "Shadefish",
                url: "https://twitter.com/ShadeFish1",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "FIST",
                src: leftWeaponFistImage,
            },
            {
                value: 1,
                label: "KNIFE",
                src: leftWeaponKnifeImage,
            },
            {
                value: 2,
                label: "RAPIER",
                foremostSrc: leftWeaponRapierImage,
            },
            {
                value: 3,
                label: "AXE",
                src: leftWeaponAxeImage,
            },
            {
                value: 4,
                label: "LAUNCHER",
                src: leftWeaponLauncherImage,
            },
            {
                value: 5,
                label: "LAUNCHER + DRUM",
                src: leftWeaponLauncherDrumImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FIST",
            src: rightWeaponFistImage,
        },
        {
            value: 1,
            label: "KNIFE",
            src: rightWeaponKnifeImage,
        },
        {
            value: 2,
            label: "FENCING KNIFE",
            src: rightWeaponFencingKnifeImage,
        },
        {
            value: 3,
            label: "AXE",
            src: rightWeaponAxeImage,
        },
        {
            value: 4,
            label: "MK79 DMR",
            src: rightWeaponMarksmanRifleImage,
        },
        {
            value: 5,
            label: "SM-83 BEAM RIFLE",
            src: rightWeaponBeamRifleImage,
        },
        {
            value: 6,
            label: "LAUNCHER",
            src: rightWeaponLauncherImage,
        },
        {
            value: 7,
            label: "LAUNCHER + DRUM",
            src: rightWeaponLauncherDrumImage,
        },
        {
            value: 8,
            label: "RING 1 RUNAWAY CLOAK",
            src: accessoryCloakRImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "8 RACK",
            src: rear8RackImage,
        },
        {
            value: 1,
            label: "12 RACK",
            src: rear12RackImage,
        },
        {
            value: 2,
            label: "18 RACK",
            src: rear18RackImage,
        },
        {
            value: 3,
            label: "BOOSTERS",
            src: rearBoostersImage,
        },
        {
            value: 4,
            label: "MISSILE PODS",
            src: rearMissilePodsImage,
        },
        {
            value: 5,
            label: "SCABBARD",
            frontSrc: rearScabbardImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "FEET - HOOVES",
            src: accessoryFeetHoovesImage,
        },
        {
            value: 1,
            label: "FEET - TALONS",
            src: accessoryFeetTalonsImage,
        },
        {
            value: 2,
            label: "CLOAK",
            frontSrc: accessoryCloakImage,
            backSrc: accessoryCloakUnderImage,
        },
        {
            value: 3,
            label: "SECONDARY BOOSTERS",
            src: accessorySecondaryBoostersImage,
        },
        {
            value: 4,
            label: "SHOULDER MISSILES",
            src: accessoryShoulderMissilesImage,
        },
        {
            value: 5,
            label: "SILVA'S ROSE",
            foremostSrc: accessoryRoseImage,
        },
        {
            value: 6,
            label: "GLOW - BODY",
            src: glowBodyImage,
        },
        {
            value: 7,
            label: "GLOW - SILVA HEAD",
            foremostSrc: glowViceroyHeadImage,
        },
        {
            value: 8,
            label: "GLOW - ENGINE INTAKE",
            src: glowBoostersImage,
        },
        {
            value: 9,
            label: "GLOW - AFTERBURNERS",
            src: glowAfterburnersImage,
        },
        {
            value: 10,
            label: "GLOW - AFTERBURNERS + ENGINE INTAKE",
            src: glowAfterburnersBoostersImage,
        },
        {
            value: 11,
            label: "GLOW - AFTERBURNERS + ENGINE INTAKE + SECONDARIES",
            src: glowAfterburnersBoostersSecondariesImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
