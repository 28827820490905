import React, { useContext } from "react";

import { MusicWrapper, InputRange } from "./MusicController";
import { SFXContext } from "./SFXContextWrapper";
import { HalfWrapper } from "./../common/ToggleHideUIContextWrapper";
import { UITextButton } from "./../common/styles/UITextButton";

function SFXController({ className }) {
    const {
        isMuted,
        setIsMuted,
        volumeValue,
        handleVolumeChange,
    } = useContext(SFXContext);

    return (
        <MusicWrapper>
            <HalfWrapper>
                <UITextButton
                    className={className}
                    onClick={() => {
                        setIsMuted(!isMuted);

                        document.cookie = `sfxIsMuted=${!isMuted}; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
                    }}
                    title="Toggle SFX"
                >
                    SFX: {isMuted ? "OFF" : "ON"}
                </UITextButton>
            </HalfWrapper>
            <HalfWrapper>
                <InputRange
                    id="sfxVolume"
                    type="range"
                    min="0"
                    max="100"
                    value={volumeValue}
                    onChange={handleVolumeChange}
                    isMuted={isMuted}
                />
            </HalfWrapper>
        </MusicWrapper>
    );
}

export default SFXController;
