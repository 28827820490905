import { contributorList } from "../Category/components/ArtCreditLink";
import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/SSC/Orchis`;

// BODY
const bodyOrchisImage = `${imagePath}/Chassis.png`;

// HEADS
const headOrchisImage = `${imagePath}/heads/Head - Orchis.png`;
const head8BallImage = `${imagePath}/heads/Head - 8 Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headBunkerImage = `${imagePath}/heads/Head - Bunker.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headHoodImage = `${imagePath}/heads/Head - Hood.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNelsonFTImage = `${imagePath}/heads/Head - Nelson Flight Type.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainImage = `${imagePath}/heads/Head - Rain.png`;
const headRainHairImage = `${imagePath}/heads/Head - Rain Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headWhiteWitchImage = `${imagePath}/heads/Head - White Witch.png`;
const headGladiatorImage = `${imagePath}/heads/Head_Gladiator.png`;
const headHelmOfShadowFrontImage = `${imagePath}/heads/Head_Front_Helm of Shadow.png`;
const headHelmOfShadowImage = `${imagePath}/heads/Head_Helm of Shadow.png`;
const headLeonidusImage = `${imagePath}/heads/Head_Leonidus.png`;
const headSpartanImage = `${imagePath}/heads/Head_Spartan.png`;
const headTheHawkImage = `${imagePath}/heads/Head_The Hawk.png`;

// MAIN WEAPON
const weaponOrchisBladeImage = `${imagePath}/weapons/right/Right Arm - Orchis Blade.png`;
const weaponStraightBladeImage = `${imagePath}/weapons/right/Right Arm - Straight Blade.png`;
const weaponLanceImage = `${imagePath}/weapons/right/Right Arm - Lance.png`;
const weaponXiphosImage = `${imagePath}/weapons/right/Main Weapon_Xiphos.png`;
const weaponSwordBreakerImage = `${imagePath}/weapons/right/Aux Weapon_Sword Breaker.png`;
const weaponFerrofluidLanceImage = `${imagePath}/weapons/right/Main Weapon_Ferrofluid Lance.png`;
const weaponHalfSwordImage = `${imagePath}/weapons/right/Main Weapon_Half Sword.png`;
const weaponHeadHalberdImage = `${imagePath}/weapons/right/Aux Weapon_Polearm Head Halberd.png`;
const weaponHeadNaginataImage = `${imagePath}/weapons/right/Aux Weapon_Polearm Head Naginata.png`;
const weaponHeadRamcannonImage = `${imagePath}/weapons/right/Aux Weapon_Polearm Head Ramcannon.png`;
const weaponPolearmShaftImage = `${imagePath}/weapons/right/Main Weapon_Polearm Shaft.png`;
const weaponTridentHeadImage = `${imagePath}/weapons/right/Aux Weapon_Trident Head.png`;
const weaponTridentShaftImage = `${imagePath}/weapons/right/Main Weapon_Trident Shaft.png`;
const weaponDragonslayerImage = `${imagePath}/weapons/right/Main Weapon_Dragonslayer.png`;

// SECONDARY WEAPON
const secondaryWeaponShieldImage = `${imagePath}/weapons/left/Left Arm - Shield.png`;
const secondaryWeaponShieldHeraldryImage = `${imagePath}/weapons/left/Left Arm - Shield Heraldry.png`;
const secondaryWeaponNetImage = `${imagePath}/weapons/left/Aux Weapon_Net.png`;
const secondaryWeaponSpartanShieldImage = `${imagePath}/weapons/left/Aux Weapon_Spartan Shield.png`;
const secondaryWeaponTargetShieldImage = `${imagePath}/weapons/left/Aux Weapon_Target Shield.png`;

// ACCESSORY
const accessoryChassisRibbonsImage = `${imagePath}/accessories/Accessories - Chassis Ribbons.png`;
const accessoryShoulderLaunchersImage = `${imagePath}/accessories/Accessory_Shoulder Launchers.png`;

// GLOW
const glowBladeImage = `${imagePath}/glows/Glow - Straight Blade.png`;
const glowBladeShieldImage = `${imagePath}/glows/Glow - Straight Blade Shield.png`;
const glowDragonslayerImage = `${imagePath}/glows/Accessory_Dragonslayer Glow.png`;

const coreImage = `${imagePath}/CharlieMerlynn_Orchis.png`;
const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "32px",
    right: "13px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "CharlieMerlynn",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ORCHIS",
            src: headOrchisImage,
        },
        {
            value: 1,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 2,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 3,
            label: "BUNKER",
            src: headBunkerImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 8,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 9,
            label: "HOOD",
            src: headHoodImage,
        },
        {
            value: 10,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 11,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 12,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 13,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 14,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 15,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 16,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 17,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 18,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 19,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 20,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 21,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 22,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 23,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 24,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 25,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 26,
            label: "NELSON FLIGHT TYPE",
            src: headNelsonFTImage,
        },
        {
            value: 27,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 28,
            label: "RAIN",
            src: headRainImage,
        },
        {
            value: 29,
            label: "RAIN HAIR",
            src: headRainHairImage,
        },
        {
            value: 30,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 31,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 32,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 33,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
        {
            value: 34,
            label: "WHITE WITCH",
            src: headWhiteWitchImage,
        },
        {
            value: 35,
            label: "GLADIATOR",
            src: headGladiatorImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 36,
            label: "HELM OF SHADOW",
            src: headHelmOfShadowImage,
            frontSrc: headHelmOfShadowFrontImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 37,
            label: "LEONIDUS",
            src: headLeonidusImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 38,
            label: "SPARTAN",
            src: headSpartanImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 39,
            label: "THE HAWK",
            src: headTheHawkImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "ORCHIS",
            src: bodyOrchisImage,
            shadowDetails: bipedLegsShadow,
        }
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "ORCHIS BLADE",
                src: weaponOrchisBladeImage,
            },
            {
                value: 1,
                label: "STRAIGHT BLADE",
                src: weaponStraightBladeImage,
            },
            {
                value: 2,
                label: "LANCE",
                src: weaponLanceImage,
            },
            {
                value: 3,
                label: "FERROFLUID LANCE",
                src: weaponFerrofluidLanceImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 4,
                label: "HALF SWORD",
                src: weaponHalfSwordImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 5,
                label: "XIPHOS",
                src: weaponXiphosImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 6,
                label: "HALBERD",
                src: weaponPolearmShaftImage,
                frontSrc: weaponHeadHalberdImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 7,
                label: "NAGINATA",
                src: weaponPolearmShaftImage,
                frontSrc: weaponHeadNaginataImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 8,
                label: "RAM CANNON",
                src: weaponPolearmShaftImage,
                frontSrc: weaponHeadRamcannonImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 9,
                label: "TRIDENT",
                src: weaponTridentShaftImage,
                frontSrc: weaponTridentHeadImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
            {
                value: 10,
                label: "DRAGONSLAYER",
                src: weaponDragonslayerImage,
                originalArtCredit: contributorList.PINKHYENA,
                isPlusTwo: true,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "SHIELD",
            src: secondaryWeaponShieldImage,
        },
        {
            value: 1,
            label: "SHIELD + HERALDRY",
            src: secondaryWeaponShieldHeraldryImage,
        },
        {
            value: 2,
            label: "SPARTAN SHIELD",
            src: secondaryWeaponSpartanShieldImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 3,
            label: "NET",
            src: secondaryWeaponNetImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 4,
            label: "SWORD BREAKER",
            src: weaponSwordBreakerImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 5,
            label: "TARGET SHIELD",
            src: secondaryWeaponTargetShieldImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CHASSIS RIBBONS",
            src: accessoryChassisRibbonsImage,
        },
        {
            value: 1,
            label: "SHOULDER LAUNCHERS",
            src: accessoryShoulderLaunchersImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
        {
            value: 2,
            label: "GLOW - BLADE",
            frontSrc: glowBladeImage,
        },
        {
            value: 3,
            label: "GLOW - BLADE + SHIELD",
            frontSrc: glowBladeShieldImage,
        },
        {
            value: 4,
            label: "GLOW - DRAGONSLAYER",
            frontSrc: glowDragonslayerImage,
            originalArtCredit: contributorList.PINKHYENA,
            isPlusTwo: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES / GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "SHIELD",
            value: "secondaryWeapon",
        },
        {
            label: "REAR MOUNT",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "SHIELD",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
