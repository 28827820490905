import React, { useState } from "react";
import styled from "styled-components";

import LayeredUnitImage from "./LayeredUnitImage";
import Loader from "../Category/components/Loader";

export const MechUnitContainer = styled.div`
    transition: ${props => props.isZoomable ? "transform .5s ease" : "unset"};
    height: ${props => props.isZoomable ? props.isMegadeusPlus || props.isMegadeusPlusPlus || props.isMegadeusOmega ? "360px" : "310px" : "unset"};
    width: ${props => props.isZoomable ? props.isMegadeusPlus || props.isMegadeusPlusPlus || props.isMegadeusOmega ? "360px" : "310px" : "unset"};
    cursor: ${props => props.isZoomable ? "zoom-in" : "pointer"};
    overflow: hidden;
    display: inline-block;
    position: ${props => props.isZoomable ? "relative" : "initial"};
`;

function MechUnitImage(args) {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <>
        {isLoading ? <Loader /> : null}
        <LayeredUnitImage
            {...args}
            onLoad={() => setIsLoading(false)}
        />
        </>
    );
}

export default MechUnitImage;
