import styled from "styled-components";

export const shadowTypes = {
    SUPREMELY_LARGE: "supremely-large",
    LARGE: "large",
    MEDIUM: "medium",
    SMALL: "small",
    NONE: "none",
};

export const shadowDetailsNone = {
    size: shadowTypes.NONE,
    bottom: "0px",
    right: "0px",
};

const UnitShadow = styled.div`
    background: ${props => props.theme.unitShadowColor};
    border-radius: 50%;
    opacity: 0.7;
    position: absolute;
    align-self: flex-end;
    bottom: ${props => props.shadowBottom};
    right: ${props => props.shadowRight};
    z-index: -1;

    &.supremely-large {
        width: 75%;
        height: 30%;
    }

    &.large {
        width: 55%;
        height: 20%;
    }

    &.medium {
        width: 45%;
        height: 15%;
    }

    &.small {
        width: 35%;
        height: 12%;
    }

    &.none {
        width: 0%;
        height: 0%;
    }

    @media only screen and (max-width: ${props => props.theme.tabletCutoffWidth}px) {
        display: none;
    }
`;

UnitShadow.displayName = "UnitShadow";

export default UnitShadow;
