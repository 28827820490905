import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Typhoon`;

// BODY
const bodyTyphoonImage = `${imagePath}/Chassis.png`;

// ARMS
// LEFT
const weaponLeftAutocannonImage = `${imagePath}/arms/Left Arm - Autocannon.png`;
const weaponLeftHeavyLauncherImage = `${imagePath}/arms/Left Arm - Heavy Launcher.png`;

// RIGHT
const weaponRightAutocannonImage = `${imagePath}/arms/Right Arm - Autocannon.png`;
const weaponRightHeavyLauncherImage = `${imagePath}/arms/Right Arm - Heavy Launcher.png`;
const weaponRightHeavyLauncherFeedImage = `${imagePath}/arms/Right Arm - Heavy Launcher Feed.png`;

// REAR MOUNT
const bladesAtRestImage = `${imagePath}/rear/Blades - At Rest.png`;
const bladesInMotionImage = `${imagePath}/rear/Blades - In Motion.png`;

// GLOW
const glowVentsImage = `${imagePath}/glows/Glow - Vents.png`;

// ACCESSORY
const accWingPodsImage = `${imagePath}/accessory/Missiles - Wing Pods.png`;
const acc22RacksImage = `${imagePath}/accessory/Missiles - 22 Racks.png`;
const accQuadRacksImage = `${imagePath}/accessory/Missiles - Quad Racks.png`;
const accAllImage = `${imagePath}/accessory/Missiles - All.png`;

const previewImage = `${imagePath}/preview.png`;
const restrictedPreviewImage = `${imagePath}/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "-58px",
    right: "-55px",
};

export default {
    previewImg: previewImage,
    restrictedPreviewImg: restrictedPreviewImage,
    isMegadeusPlus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "1",
        "weaponValue": "1",
        "secondaryWeaponValue": "0",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "2",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "VENTS",
            src: glowVentsImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TYPHOON",
            src: bodyTyphoonImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "AUTOCANNON",
                src: weaponLeftAutocannonImage,
            },
            {
                value: 1,
                label: "HEAVY LAUNCHER",
                src: weaponLeftHeavyLauncherImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "AUTOCANNON",
            backSrc: weaponRightAutocannonImage,
        },
        {
            value: 1,
            label: "HEAVY LAUNCHER",
            backSrc: weaponRightHeavyLauncherImage,
        },
        {
            value: 2,
            label: "HEAVY LAUNCHER + FEED",
            backSrc: weaponRightHeavyLauncherFeedImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "AT REST",
            foremostSrc: bladesAtRestImage,
        },
        {
            value: 1,
            label: "IN MOTION",
            foremostSrc: bladesInMotionImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "22 RACKS",
            src: acc22RacksImage,
        },
        {
            value: 1,
            label: "QUAD RACKS",
            src: accQuadRacksImage,
        },
        {
            value: 2,
            label: "WING PODS",
            src: accWingPodsImage,
        },
        {
            value: 3,
            label: "ALL",
            src: accAllImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "ACCESSORIES",
            value: "ACCESSORIES",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "L ARM",
            value: "weapon",
        },
        {
            label: "R ARM",
            value: "secondaryWeapon",
        },
        {
            label: "BLADES",
            value: "rearMount",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "CHASSIS",
        "rearMount": "BLADES",
        "weapon": "L ARM",
        "secondaryWeapon": "R ARM",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
