import BaseURL from "./images/Hosting-BaseURL";
import { shadowDetailsNone as shadowDetails } from "../styles/UnitShadow";

const body1Image =  `${BaseURL}/Mechs/Misc/Biggy Smolls/Biggy Smolls.png`;

// WEAPONS
const weaponsSwordImage = `${BaseURL}/Mechs/Misc/Biggy Smolls/weapons/Weapon - Sword.png`;

// ACCESSORIES
const accessoryNecklaceImage = `${BaseURL}/Mechs/Misc/Biggy Smolls/accessories/Accessory - Necklace.png`;
const accessoryArmorImage = `${BaseURL}/Mechs/Misc/Biggy Smolls/accessories/Accessory - Armor.png`;

const coreImage = `${BaseURL}/Mechs/ChopShop/units/2021-06-15/Steve - Paroxysms.png`;
const previewImage = `${BaseURL}/Mechs/Misc/Biggy Smolls/preview.png`;

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Steve_From_Accounting",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
    "chassisValue": "0",
    "headValue": "NONE",
    "rearMountValue": "NONE",
    "weaponValue": "0",
    "secondaryWeaponValue": "NONE",
    "accessory1Value": "0",
    "accessory2Value": "1",
    "accessory3Value": "NONE",
    "accessory4Value": "NONE",
    "stanceValue": "1H",

    "chassisTintValue": "none",
    "headTintValue": "none",
    "rearMountTintValue": "none",
    "weaponTintValue": "none",
    "secondaryWeaponTintValue": "none",
    "accessory1TintValue": "none",
    "accessory2TintValue": "none",
    "accessory3TintValue": "none",
    "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "BIGGY SMOLLS",
            src: body1Image,
            shadowDetails: shadowDetails,
            originalArtCredit: {
                title: "Steve_From_Accounting",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "SWORD",
                frontSrc: weaponsSwordImage,
            },
        ],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "NECKLACE",
            src: accessoryNecklaceImage,
        },
        {
            value: 1,
            label: "ARMOR",
            src: accessoryArmorImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "BODY",
        "rearMount": "REAR MOUNT",
        "weapon": "WEAPON",
        "secondaryWeapon": "AUX WEAPON",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
