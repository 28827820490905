import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

// BODY
const bodyRoninImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/Ronin.png`;

// MAIN WEAPON
const bladeImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/weapons/Weapon - Blade.png`;

// GLOWS
const eyeGlowImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/glows/Glow - Eye.png`;
const bladeGlowImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/glows/Glow - Blade.png`;
const eyeBladeGlowImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/glows/Glow - Eye Blade.png`;
const chassisBladeGlowImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/glows/Glow - Chassis Blade.png`;
const chassisGlowImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/glows/Glow - Chassis.png`;

// CAMO
const chitinCamo1Image = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Chitin 1.png`;
const chitinCamo2Image = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Chitin 2.png`;
const forestCamo1Image = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Forest 1.png`;
const forestCamo2Image = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Forest 2.png`;
const stingerCamo1Image = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Stinger 1.png`;
const stingerCamo2Image = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Stinger 2.png`;
const eliteCamoImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Elite.png`;
const underbellyCamoImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Underbelly.png`;
const halftoneCamoImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/camo/Camo - Halftone.png`;

const previewImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/preview.png`;
const restrictedPreviewImage = `${BaseURL}/Mechs/NPC/Biomecha/Ronin/preview-restricted.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "67px",
    right: "59px",
};

export default {
    restrictedPreviewImg: restrictedPreviewImage,
    previewImg: previewImage,
    defaultValues: `{
        "chassisValue": "0",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "5",
        "camo1Value": "NONE",
        "camo2Value": "NONE",
        "camo3Value": "NONE",
        "camo4Value": "NONE",

        "chassisTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "camo1TintValue": "none",
        "camo2TintValue": "none",
        "camo3TintValue": "none",
        "camo4TintValue": "none"
    }`,
    chassis: [
        {
            value: 0,
            label: "RONIN",
            src: bodyRoninImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                title: "Mykell Pledger",
                url: "https://twitter.com/mykedsgn/status/1346269163963211776",
            },
        },
    ],
    weapon: [
        {
            value: 0,
            label: "BLADE - COLOR",
            src: bladeImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "EYE - GLOW",
            src: eyeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "CHASSIS - GLOW",
            src: chassisGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "BLADE - GLOW",
            src: bladeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "EYE + BLADE - GLOW",
            src: eyeBladeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "CHASSIS + BLADE - GLOW",
            src: chassisBladeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    secondaryWeapon: [
        {
            value: 0,
            label: "BLADE - COLOR",
            src: bladeImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "EYE - GLOW",
            src: eyeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "CHASSIS - GLOW",
            src: chassisGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "BLADE - GLOW",
            src: bladeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "EYE + BLADE - GLOW",
            src: eyeBladeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "CHASSIS + BLADE - GLOW",
            src: chassisBladeGlowImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    camo: [
        {
            value: 0,
            label: "ELITE",
            src: eliteCamoImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 1,
            label: "CHITIN 1",
            src: chitinCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 2,
            label: "CHITIN 2",
            src: chitinCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 3,
            label: "FOREST 1",
            src: forestCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 4,
            label: "FOREST 2",
            src: forestCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 5,
            label: "STINGER 1",
            src: stingerCamo1Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 6,
            label: "STINGER 2",
            src: stingerCamo2Image,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 7,
            label: "UNDERBELLY",
            src: underbellyCamoImage,
            isPremium: true,
            isLegendary: true,
        },
        {
            value: 8,
            label: "HALFTONE",
            src: halftoneCamoImage,
            isPremium: true,
            isLegendary: true,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON",
            value: "weapon",
        },
        {
            label: "GLOW",
            value: "secondaryWeapon",
            isGlow: true,
        },
        {
            label: "CAMO 1",
            value: "camo1",
        },
        {
            label: "CAMO 2",
            value: "camo2",
        },
        {
            label: "CAMO 3",
            value: "camo3",
        },
        {
            label: "CAMO 4",
            value: "camo4",
        },
    ],
    labels: {
        "chassis": "CHASSIS",
        "weapon": "WEAPON",
        "secondaryWeapon": "GLOW",
        "camo1": "CAMO 1",
        "camo2": "CAMO 2",
        "camo3": "CAMO 3",
        "camo4": "CAMO 4",
    },
};
